import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import {Route, Switch, useParams} from "react-router-dom";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {makeStyles} from '@mui/styles';

import DotsLoader from "../../../components/DotsLoader";
import Breadcrumb from "../../../components/Layout/Admin/Breadcrumb";

// import UserNetworkTable from "./components/UserNetworkTable";
import UserAccount from "./components/UserAccount";
import UserActionsCard from "./components/UserActionsCard";
import UserGiftCardsCard from "./components/UserGiftCardsCard";
import UserOrdersCard from "./components/UserOrdersCard";
import UserShippingAddresses from "./components/UserShippingAddresses";
import UserStats from "./components/UserStats";
import UserUpdateAuthorizations from "./components/UserUpdateAuthorizations";
import UserUpdateCard from "./components/UserUpdateCard";
import { userService } from "./store/services";

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(2)
  },
}));

const UserLayout = () => {

  const classes = useStyles();
  const [user, setUser] = useState(null);
  const {userId} = useParams();

  useEffect(() => {
    if(!user || user.id !== userId) {
      loadUser();
    }
  }, [userId]);

  const loadUser = () => {
    userService.getUserById(userId).then(user => setUser(user));
  };

  if(!user || !user.id) {
    return (
      <Grid container justify={"center"} spacing={4}>
        <Grid item xs={12}>
          <DotsLoader/>
        </Grid>
      </Grid>
    );
  }

  return (
    <div>
      <Helmet>
        <title>{`${user.firstName} ${user.lastName}`}</title>
      </Helmet>
      <Container fixed maxWidth="xl">
        <div className={classes.title}>
          <Breadcrumb parents={[
            {name: "Utilisateurs"},
            {name: `${user.firstName} ${user.lastName}`}
          ]}/>
          <Typography component="h1" variant="h4">
            {user.firstName} {user.lastName.toUpperCase()}
          </Typography>
        </div>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
            <UserActionsCard user={user} onChange={loadUser}/>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
            <Switch>
              <Route exact path="/admin/users/:userId/update" component={() => UserUpdateCard({user, onChange: loadUser})} />
              {/* eslint-disable-next-line max-len */}
              <Route exact path="/admin/users/:userId/authorizations" component={() => UserUpdateAuthorizations({user, onChange: loadUser})} />
              <Route exact path="/admin/users/:userId/shipping" component={() => UserShippingAddresses({user, onChange: loadUser})} />
              <Route exact path="/admin/users/:userId/stats" component={() => UserStats({user})} />
              {/*<Route exact path="/admin/users/:userId/network" component={() => UserNetworkTable({user})} />*/}
              <Route exact path="/admin/users/:userId/orders" component={() => UserOrdersCard({user})} />
              <Route exact path="/admin/users/:userId/gifts" component={() => UserGiftCardsCard({user})} />
              <Route exact path="/admin/users/:userId" component={() => UserAccount({user})} />
            </Switch>
          </Grid>
        </Grid>
      </Container>

    </div>
  );
};
export default UserLayout;
