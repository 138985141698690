import React from "react";
import {Route, Switch} from 'react-router-dom';
import loadable from "@loadable/component";

const LogisticRoutes = () => {

  const Deferred = loadable(() => import("../containers/Logistic/Order/DeferredList"));
  const TodoList = loadable(() => import("../containers/Logistic/Order/TodoList"));
  const BackOrdersTodoList = loadable(() => import("../containers/Logistic/Order/BackOrderList"));
  const InTransitList = loadable(() => import("../containers/Logistic/Order/InTransitList"));
  const ProcessingList = loadable(() => import("../containers/Logistic/Order/ProcessingList"));
  const DeliveredList = loadable(() => import("../containers/Logistic/Order/DeliveredList"));
  const InPickupPointList = loadable(() => import("../containers/Logistic/Order/InPickupPointList"));
  const BackToSenderList = loadable(() => import("../containers/Logistic/Order/BackToSenderList"));
  const DoneList = loadable(() => import("../containers/Logistic/Order/DoneList"));
  const ErroredList = loadable(() => import("../containers/Logistic/Order/ErroredList"));
  const Emergencies = loadable(() => import("../containers/Logistic/Order/Emergencies"));
  const Order = loadable(() => import("../containers/Logistic/Order/Order"));
  const StarterKitsTodo = loadable(() => import("../containers/Logistic/StarterKit/StarterKitsTodo"));
  const StarterKitsDone = loadable(() => import("../containers/Logistic/StarterKit/StarterKitsDone"));
  const CertificatesTodo = loadable(() => import("../containers/Logistic/Certificates/CertificatesTodo"));
  const CertificatesDone = loadable(() => import("../containers/Logistic/Certificates/CertificatesDone"));
  const Error = loadable(() => import("../containers/Pro/Error"));

  return (
    <Switch>
      <Route exact path="/logistic/orders/deferredTodo" component={Deferred} />
      <Route exact path="/logistic/orders/todo" component={TodoList} />
      <Route exact path="/logistic/orders/backOrdersTodo" component={BackOrdersTodoList} />
      <Route exact path="/logistic/order/:orderId" component={Order} />
      <Route exact path="/logistic/orders/processing" component={ProcessingList} />
      <Route exact path="/logistic/orders/inTransit" component={InTransitList} />
      <Route exact path="/logistic/orders/inPickupPoint" component={InPickupPointList} />
      <Route exact path="/logistic/orders/backToSender" component={BackToSenderList} />
      <Route exact path="/logistic/orders/delivered" component={DeliveredList} />
      <Route exact path="/logistic/orders/done" component={DoneList} />
      <Route exact path="/logistic/orders/errored" component={ErroredList} />
      <Route exact path="/logistic/orders/emergencies" component={Emergencies} />
      <Route exact path="/logistic/starterKits/todo" component={StarterKitsTodo} />
      <Route exact path="/logistic/starterKits/done" component={StarterKitsDone} />
      <Route exact path="/logistic/certificates/todo" component={CertificatesTodo} />
      <Route exact path="/logistic/certificates/done" component={CertificatesDone} />
      <Route path="*" component={Error}/>
    </Switch>
  );
};

export default LogisticRoutes;
