import React, { useState } from "react";
import { Trans } from "react-i18next";
import {useHistory} from "react-router-dom";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import {PaymentElement, useElements, useStripe} from "@stripe/react-stripe-js";

import PageLoader from "../../../../../components/PageLoader";
import {getLangPath} from "../../../../../helpers/multilingual";

const CheckoutForm = ({className, order, redirect, hardReload}) => {

  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();

  const [elementFormReady, setElementFormReady] = useState(false);
  const [processing, setProcessing] = useState(false);

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    setProcessing(true);

    const {error} = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${process.env.REACT_APP_WEB_URL}${getLangPath()}${redirect}?checkout_oid=${order.id}&reload=${hardReload}`,
      },
      redirect: 'if_required'
    });

    if (error) {
      //SnackbarError(`${error.message}`);
      history.push(`${getLangPath()}${redirect}?checkout_oid=${order.id}&reload=${hardReload}`);
      setProcessing(false);
    }
    else {
      history.push(`${getLangPath()}${redirect}?checkout_oid=${order.id}&reload=${hardReload}`);
    }
  };

  return (
    <form className={className} onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <PaymentElement
            onReady={() => {
              setElementFormReady(true);
            }}
          />
        </Grid>
      </Grid>
      {!elementFormReady && (
        <PageLoader height={150}/>
      )}
      {elementFormReady && (
        <Button
          type="submit"
          variant={"contained"}
          color={"primary"}
        >
          {processing && (
            <Trans i18nKey={"processing"}>Processing ...</Trans>
          )}
          {!processing && (
            <Trans
              i18nKey={"payButton"}
              values={{amount:`${(Math.round((order.totalPrice + order.taxes - order.walletAmount) * 100) / 100).toFixed(2)} ${order.currency}`}}
            >
                            Pay {(Math.round((order.totalPrice + order.taxes - order.walletAmount) * 100) / 100).toFixed(2)} {order.currency}
            </Trans>
          )}

        </Button>
      )}
    </form>
  );
};
export default CheckoutForm;
