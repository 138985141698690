import React from "react";
import {useTranslation} from "react-i18next";
import Button from "@mui/material/Button";

import theme from "../../../../../../assets/styles/theme/theme";
import Cookie from "../../../../../../helpers/cookie";

const OrderProforma = ({proforma}) => {

  const { t } = useTranslation();

  if(!proforma) {
    return null;
  }

  return (
    <Button
      component={"a"}
      color={"primary"}
      target={"_blank"}
      href={`${process.env.REACT_APP_API_URL}/storage/order/${proforma.orderId}/proforma-invoice.pdf?t=${Cookie.get('storage-token')}`}
      rel="noreferrer"
      sx={{
        background: theme => theme.palette.primary.main,
        fontSize: '0.8rem',
        fontFamily: 'Montserrat',
        textTransform: 'capitalize',
        borderRadius: '30px',
        color: '#fff',
        lineHeight: '1.3',
        padding: '8px 8px',
        boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
        '&:hover': {
          background: '#fff',
          color: theme.palette.primary.main,
        }
      }}
    >
      {t("downloadInvoice")}
    </Button>
  );
};
export default OrderProforma;
