import produce from "immer";

import * as actionsType from './constants';

const initialState = {
  isOpen: false
};

export const menuReducerKey = 'menu';

export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {

      case actionsType.TOGGLE_MENU:
        draft.isOpen = !state.isOpen;
        break;

      default:

    }
  });
