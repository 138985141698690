import React from 'react';
import {Grid, Stack, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import useMediaQuery from "@mui/material/useMediaQuery";

const section = {
  "name": "AboutSection1",
  "type": "banner",
  "bannerTitle1": "Une équipe,",
  "bannerTitle2": "Au service de",
  "bannerTitle3": "Vos Patients.",
  "bannerText1": "Chez Nutri-Logics, vous trouverez en un seul endroit, en une seule prescription, la totalité des compléments alimentaires bénéfiques au traitement de vos patients.",
  "bannerBackgroundImage": `${process.env.REACT_APP_API_RESOURCES_URL}/images/Photo_ThumbsUp.png`
};
export default function ProsSection1({setContactModalOpen}) {

  const isDownSm = useMediaQuery(theme => theme.breakpoints.down('sm'));

  return (
    <Container maxWidth={'xxl'}
      sx={{
        pl: '0 !important',
        pr: '0 !important'
      }}
    >
      <Grid
        container
        spacing={2}
        justifyContent={"space-between"}
        flexDirection={"row"}
        alignItems={"center"}
        alignContent={"center"}
        sx={{
          width: '100%',
          background: isDownSm ? '#FFFFFF' : `url(${section.bannerBackgroundImage})`,
          backgroundPosition: 'left top',
          backgroundSize: {xs: '500px', sm: '50%'},
          backgroundRepeat: 'no-repeat',
          margin: '0px 0 60px',
          padding: '1px 0',
          height: {xs: 'auto', sm: '55vw', md: '55vw', lg: '55vw', xl: '55vw', xxl: '1060px'},
          overflowX: 'clip',

        }}>
        <Grid item xs={12} sm={6}>

        </Grid>

        <Grid item xs={12} sm={6}>
          <Stack gap={2} sx={{pt: '3vw'}} alignItems={'flex-start'}>
            <Typography
              sx={{
                fontFamily: 'Raleway',
                fontWeight: '600',
                padding: '8px 8px',
                fontSize: {xs: '2.5rem', sm: '2rem', md: '2.5rem', lg: '3.5rem', xl: '3.5rem', xxl: '4.5rem'},
                lineHeight: '1',
                overflow: 'hidden',
                color: 'green',
                textAlign: {xs: 'left', sm: 'left'},
                zIndex: 1,
                '& p': {
                  margin: 0,
                },
                '& span': {
                  lineHeight: '1',
                  fontFamily: 'Raleway',
                  fontWeight: '600',
                  fontSize: {xs: '2.5rem', sm: '2rem', md: '2.5rem', lg: '3.5rem', xl: '3.5rem', xxl: '4.5rem'},
                }
              }}>
              {section.bannerTitle1} <br/>
              <Typography component={"span"} sx={{color: 'primary.main'}}>{section.bannerTitle2}</Typography> <br/>
              <Typography component={"span"} sx={{color: "#006400"}}>
                {section.bannerTitle3}
              </Typography>
            </Typography>
            <Button
              variant="contained"
              color={"primary"}
              onClick={() => setContactModalOpen(true)}
              sx={{
                fontSize: '0.9rem',
                borderRadius: '30px',
                color: '#fff',
                lineHeight: '1.3',
                padding: '12px',
              }}>
                Je suis professionnel de la santé et je souhaite rejoindre la communauté des pros chez Nutri-Logics
            </Button>
            <Typography
              sx={{
                fontSize: {xs: '1rem', sm: '0.9rem', md: '1rem', lg: '1.1rem', xl: '1.2rem', xxl: '1.3rem'},
                padding: '0 8px 8px 8px',
                fontFamily: 'Open Sans',
                maxWidth: {xs: '100%', sm: '100%'},
                lineHeight: '1.4',
                overflow: 'hidden',
                textAlign: 'left',
                color: 'primary.light',
                zIndex: 1,
                '& p': {
                  margin: 0,
                }
              }}>
              {section.bannerText1}
            </Typography>
            <Typography sx={{
              fontSize: {xs: '1rem', sm: '0.9rem', md: '1rem', lg: '1.1rem', xl: '1.2rem', xxl: '1.3rem'},
              fontFamily: 'Open Sans',
              lineHeight: '1.4',
              color: 'primary.light',
            }}>
                Nutri-Logics c’est :
              <ul>
                <li>Une équipe au service des thérapeutes et de leurs patients</li>
                <li>Des conseillers à votre écoute</li>
                <li>Un accueil personnalisé, chaque contact est une expérience unique</li>
                <li>Des réponses à vos questions</li>
                <li>Une assistance continue</li>
                <li>Un département scientifique à la pointe</li>
                <li>La passion de la nature et de ses plantes</li>
                <li>Une sélection optimale des meilleurs compléments alimentaires du marché issue des recherches scientifiques les plus récentes</li>
              </ul>
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
}
