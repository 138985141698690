import React from "react";
import {Box} from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import NoSsr from "@mui/material/NoSsr";
import Toolbar from "@mui/material/Toolbar";
import {LocalizationProvider} from "@mui/x-date-pickers";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import Header from "../components/Layout/Statistics/Header";
import statsReducer, {statsReducerKey} from "../containers/Admin/Stats/store/reducer";
import statsSaga, {statsSagaKey} from "../containers/Admin/Stats/store/saga";
import {useInjectReducers} from "../helpers/injectors/injectReducer";
import {useInjectSagas} from "../helpers/injectors/injectSaga";
import {initLanguage} from "../helpers/multilingual";
import StatisticsRoutes from "../routes/StatisticsRoutes";

const AdminReducers = [
  {key: statsReducerKey, reducer: statsReducer},
];
const AdminSagas = [
  {key: statsSagaKey, saga: statsSaga},
];

const drawerWidth = 240;
function StatisticsLayout() {

  initLanguage('fr');

  useInjectReducers({key: 'adminState', reducers: AdminReducers});
  useInjectSagas({key: 'adminSaga', sagas: AdminSagas});

  return (
    <NoSsr>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <Header/>

        <Box
          component="main"
          sx={{ flexGrow: 1, p: 3, width: { md: `calc(100% - ${drawerWidth}px)` } }}
        >
          <Toolbar />
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <StatisticsRoutes/>
          </LocalizationProvider>
        </Box>
      </Box>
    </NoSsr>
  );

}

export default StatisticsLayout;
