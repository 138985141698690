import React from "react";
import {Helmet} from "react-helmet-async";
import {useTranslation} from "react-i18next";
import NoSsr from "@mui/material/NoSsr";
import {makeStyles} from '@mui/styles';
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";

import Header from "../components/Layout/Pro/Header";
import MenuDrawer from "../components/Layout/Pro/MenuDrawer";
import careerReducer, {careerReducerKey} from "../containers/Pro/Career/store/reducer";
import careerSaga, {careerSagaKey} from "../containers/Pro/Career/store/saga";
import commissionsReducer, {commissionsReducerKey} from "../containers/Pro/Commissions/store/reducer";
import commissionsSaga, {commissionsSagaKey} from "../containers/Pro/Commissions/store/saga";
import customersReducer, {customersReducerKey} from "../containers/Pro/Customers/store/reducer";
import customersSaga, {customersSagaKey} from "../containers/Pro/Customers/store/saga";
import networkReducer, {networkReducerKey} from "../containers/Pro/Network/store/reducer";
import networkSaga, {networkSagaKey} from "../containers/Pro/Network/store/saga";
import proShopReducer, {proShopReducerKey} from "../containers/Pro/Shop/store/reducer";
import proShopSaga, {proShopSagaKey} from "../containers/Pro/Shop/store/saga";
import proStatsReducer, {proStatsReducerKey} from "../containers/Pro/Stats/store/reducer";
import proStatsSaga, {proStatsSagaKey} from "../containers/Pro/Stats/store/saga";
import {useInjectReducers} from "../helpers/injectors/injectReducer";
import {useInjectSagas} from "../helpers/injectors/injectSaga";
import {initLanguage} from "../helpers/multilingual";
import ProRoutes from "../routes/ProRoutes";

const useStylesLayout = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  container: {
    flexGrow: 1,
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingRight: 0
    },
  },
  view: {
    minHeight: '70vh'
  }
}));

const ProReducers = [
  {key: proStatsReducerKey, reducer: proStatsReducer},
  {key: commissionsReducerKey, reducer: commissionsReducer},
  {key: customersReducerKey, reducer: customersReducer},
  {key: networkReducerKey, reducer: networkReducer},
  {key: careerReducerKey, reducer: careerReducer},
  {key: proShopReducerKey, reducer: proShopReducer},
];

const ProSagas = [
  {key: proStatsSagaKey, saga: proStatsSaga},
  {key: commissionsSagaKey, saga: commissionsSaga},
  {key: customersSagaKey, saga: customersSaga},
  {key: networkSagaKey, saga: networkSaga},
  {key: careerSagaKey, saga: careerSaga},
  {key: proShopSagaKey, saga: proShopSaga},
];

function ProLayout(props) {

  initLanguage(props.match.params.lang || 'fr');

  const classes = useStylesLayout();
  const {i18n} = useTranslation();


  useInjectReducers({key: 'proState', reducers: ProReducers});
  useInjectSagas({key: 'proSaga', sagas: ProSagas});

  return (
    <NoSsr>
      <Helmet>
        <html lang={i18n.language}/>
      </Helmet>
      <Header/>
      <div className={classes.root}>
        <MenuDrawer/>
        <main className={classes.container}>
          {/*
            <div className={classes.view}>
              <ProRoutes/>
            </div>
            <TransferModal/>
            <CheckoutDrawer/>
            <CheckoutResponseDrawer/>
            <Footer/>
            <BottomBar/>
          </LocalizationProvider>*/}
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <div className={classes.view}>
              <ProRoutes/>
            </div>
          </LocalizationProvider>
        </main>
      </div>
    </NoSsr>
  );

}

export default ProLayout;
