import produce from "immer";

import * as actions from './constants';
export const initialState = {
  sales : {},
  monthPoints : {},
  isLoadingSales : false,
  walletHistories: []
};

export const commissionsReducerKey = 'commissions';

export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case actions.GET_SALES_REQUEST:
        draft.isLoadingSales = true;
        break;
      case actions.GET_SALES_SUCCESS:
        // eslint-disable-next-line no-case-declarations
        let salesKey = `${action.year}-${action.month}`;
        draft.monthPoints[salesKey] = draft.monthPoints[salesKey] ? draft.monthPoints[salesKey] : { data: 0 };
        draft.monthPoints[salesKey].data = action.data.pop();
        draft.sales[salesKey] = draft.sales[salesKey] ? draft.sales[salesKey] : { data: [], endOfSales: false };
        draft.sales[salesKey].data = [...draft.sales[salesKey].data, ...action.data];
        draft.isLoadingSales = false;
        if(action.data.length < 30) {
          draft.sales[salesKey].endOfSales = true;
        }
        break;
      case actions.GET_SALES_ERROR:
        draft.error = action.error;
        draft.sales = initialState.sales;
        draft.isLoadingSales = false;
        break;
      default:
    }
  }
  );
