import React, {useEffect} from "react";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import AddShoppingCart from '@mui/icons-material/AddShoppingCart';
import {Box, Stack} from "@mui/material";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import NoSsr from "@mui/material/NoSsr";
import Typography from "@mui/material/Typography";

import LazyLoadCardMedia from "../../../../components/LazyLoad/LazyLoadCardMedia";
import { Link } from "../../../../helpers/multilingual";
import {SnackbarConfirm, SnackbarInfo} from "../../../../helpers/snackbar";
import {getProductPriceWithTaxes, NRDID} from "../../../../helpers/utils";
import {userSelector} from "../../../Authentication/store/selectors";
import { getFormalizedName } from "../helpers/helper";
import AddToFavorites from "../Product/components/AddToFavorites";
import { shopActions } from "../store/actions";

import ItemPrice from "./ItemPrice";
const Item = ({product, session}) => {

  const {t} = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const [mask, setMask] = React.useState(1);
  const isNRD = product.productBrandId === NRDID;

  useEffect(() => {
    setMask(maskRand());
  }, []);

  const addToCart = (product) => {
    if(user) {
      if(!user.isMetagenicsAllowed && product.productBrandId === "132cdc9d-c6b0-4c03-8eb6-906e9a9ed441" ) {
        SnackbarInfo(t("metagenicsNotAllowed"));
        return;
      }
      const priceWithDiscount = getProductPriceWithTaxes(product, user, user?.shippingAddress, 1, true);
      const priceWithoutDiscount = getProductPriceWithTaxes(product, user, user?.shippingAddress, 1, false);
      const discount = (priceWithoutDiscount - priceWithDiscount).toFixed(2);

      if (product.outOfStock) {
        SnackbarConfirm(t('outOfStockWarning'), () => {

          dispatch(shopActions.addToCart(product, 1));

          ReactGA.event("add_to_cart", {
            user_id: user?.id,
            currency: "EUR",
            value: priceWithDiscount,
            items: [
              {
                item_id: product.id,
                item_name: product.reference,
                discount: discount,
                index: 0,
                price: priceWithDiscount,
                quantity: 1
              }
            ]
          });
        },
        () => {},
        "Commander");
      } else {

        dispatch(shopActions.addToCart(product, 1));

        ReactGA.event("add_to_cart", {
          user_id: user?.id,
          currency: "EUR",
          value: priceWithDiscount,
          items: [
            {
              item_id: product.id,
              item_name: product.reference,
              discount: discount,
              index: 0,
              price: priceWithDiscount,
              quantity: 1
            }
          ]
        });
      }
    } else {
      dispatch(shopActions.SetDrawersOpen("account", true));
    }
  };

  const handleClick = () => {
    //dispatch(shopActions.setFromInternalNavigation(true));
    sessionStorage.setItem("fromInternalNavigation", "true");
  };

  function maskRand(max = 7) {
    return Math.floor(Math.random() * max + 1);
  }

  return (
    <Card sx={{
      boxShadow: '6px 6px 12px #00000021',
      borderRadius: '12px',
    }} key={product?.id}>
      <CardActionArea>
        <Link to={`/shop/product/${getFormalizedName(product.reference)}/${product.id}`} onClick={() => handleClick()}>
          <Box
            sx={{
              position: 'absolute',
              height: '100%',
              width: '100%',
              maskRepeat: 'no-repeat',
              backgroundRepeat: 'no-repeat',
              backgroundSize: '100% !important',
              maskSize: '100%',
              maskImage: `url(${process.env.REACT_APP_API_RESOURCES_URL}/images/products/mask/Background-${mask}.svg)`,
              background: isNRD ? `url(${process.env.REACT_APP_API_RESOURCES_URL}/images/products/background/${product.background})` : product.backgroundColor,
            }}
          >
          </Box>
          <LazyLoadCardMedia
            component="img"
            alt={product.reference}
            height="auto"
            image={product.image[400]}
            title={product.reference}
            skeletonSrc={product.image[200]}
            sx={{
              height: { xs: 'auto', sm: 'auto' },
              position: 'relative',
              left: { xs: 0, sm: 0},
            }}
          />

        </Link>
        <CardContent>
          <Stack gap={2}>
            <Link to={`/shop/product/${getFormalizedName(product.reference)}/${product.id}`}>
              <Stack sx={{
                height: {xs: '156px', sm: '156px', md: '156px', lg: '156px', xl: '156px', xxl: '156px'},
              }}>
                <Typography
                  gutterBottom
                  component="h3"
                  sx={{
                    fontFamily: 'Montserrat',
                    fontSize: {xs: '1.3rem', sm: '1.3rem', md: '1.3rem', lg: '1.3rem', xl: '1.3rem', xxl: '1.4rem'},
                    overflow: 'hidden',
                    display: '-webkit-box',
                    WebkitLineClamp: '2',
                    WebkitBoxOrient: 'vertical',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {product.reference}
                </Typography>
                <Typography
                  component="div"
                  dangerouslySetInnerHTML={{__html: product.detail.shortDescription}}
                  variant="body2"
                  color="textSecondary"
                  sx={{
                    fontSize: {xs: '1rem', sm: '1rem', md: '1rem', lg: '1rem', xl: '1rem', xxl: '1rem'},
                    overflow: 'hidden',
                    display: '-webkit-box',
                    WebkitLineClamp: '3',
                    WebkitBoxOrient: 'vertical',
                    textOverflow: 'ellipsis',
                    color: '#7C909B',
                    fontFamily: 'Open Sans',
                    mb: '16px',
                    '& p': {
                      margin: '0',
                      color: '#7C909B',
                      fontFamily: 'Open Sans',
                    }
                  }}
                />

              </Stack>
            </Link>
            <Link to={`/shop/product/${getFormalizedName(product.reference)}/${product.id}`}>
              <Stack direction={"row"} gap={2} justifyContent={"flex-end"}>
                <ItemPrice
                  item={product}
                  component={"span"}
                  session={session}
                  withDiscount
                  sx={{
                    fontFamily: 'Montserrat',
                    fontSize: {xs: '1.4rem', sm: '1.4rem', md: '1.4rem', lg: '1.4rem', xl: '1.4rem', xxl: '1.4rem'},
                    fontWeight: 'bold',
                  }}
                />
                <ItemPrice
                  item={product}
                  component={"span"}
                  session={session}
                  total
                  sx={{
                    fontFamily: 'Montserrat',
                    fontSize: {xs: '1.4rem', sm: '1.4rem', md: '1.4rem', lg: '1.4rem', xl: '1.4rem', xxl: '1.4rem'},
                    fontWeight: 'bold',
                  }}
                />
              </Stack>
            </Link>
            {product.allowInCart && (
              <NoSsr>
                <Button
                  title={t("addToCart")}
                  onClick={() => addToCart(product)}
                  endIcon={<AddShoppingCart/>}
                  variant={"contained"}
                  sx={{
                    color: "white",
                    height: '48px',
                    width: "100%",
                    margin: "auto",
                    textTransform: 'uppercase',
                    letterSpacing: '0.64px',
                    fontWeight: 'bold',
                    fontFamily: 'Montserrat',
                    fontSize: '16px',
                    borderRadius: '24px'
                  }}
                >
                  Commander
                </Button>
              </NoSsr>
            )}
            <NoSsr>
              <AddToFavorites
                sx={{
                  position: 'absolute',
                  top: 8,
                  right: 8,
                  zIndex: 10,
                  backgroundColor: 'transparent',
                  '&:hover': {
                    backgroundColor: 'transparent', // Ensures the background stays transparent on hover
                  },
                  '& .MuiIconButton-label': {
                    color: '#000', // Adjust the icon color if needed
                  }
                }}
                product={product}
              />
            </NoSsr>
          </Stack>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default Item;
