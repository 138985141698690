import { createSelector } from 'reselect';

import { customersReducerKey, initialState } from "./reducer";

const customersSelector = (state) => state[customersReducerKey] || initialState;

export const customersListSelector = () => createSelector(
  [customersSelector],
  (customersSelector) => {
    if(!customersSelector.list) {
      return [];
    }
    return customersSelector.list;
  });

export const customerSelector = () => createSelector(
  [customersSelector],
  (customersSelector) => customersSelector.customer);

export const customerAnamenesisSelector = () => createSelector(
  [customersSelector],
  (customersSelector) => customersSelector.anamnesis);

export const loadingSelector = () => createSelector(
  [customersSelector],
  (customersSelector) => customersSelector.loading);

export const customerRedirectionsSelector = () => createSelector(
  [customersSelector],
  (customersSelector) => customersSelector.redirections || []);

export const customerRedirectionsCountSelector = () => createSelector(
  [customersSelector],
  (customersSelector) => {
    if(!Array.isArray(customersSelector.redirections)) {
      return null;
    }
    return customersSelector.redirections.length;
  });