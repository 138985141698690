import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import SearchIcon from '@mui/icons-material/Search';
import {Autocomplete} from "@mui/material";
import {createFilterOptions, InputAdornment} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import {SnackbarFormattedError} from "../../../../../helpers/snackbar";
import {shopService} from "../../store/services";

const ProductsAutocomplete = ({
  onSelect,
  autoFocus,
  onChange,
  country,
  variant = "filled",
  label,
  withPrice,
  withoutRestriction,
  sx
}) => {

  const {t, i18n} = useTranslation();
  const [searchAutocompleteOpen, setSearchAutocompleteOpen] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [products, setProducts] = useState([]);

  useEffect(() => {
    if (search.length === 2) {
      setSearchLoading(true);
      shopService.searchProducts(i18n.language, country, search, withoutRestriction)
        .then((products) => {
          //Sort products by search relevance starting with product.reference then product.detail.composition
          //product.keywords is an array of strings
          products.sort((a, b) => {
            const aRef = a.reference?.toLowerCase();
            const bRef = b.reference?.toLowerCase();
            const aComp = a.detail?.composition?.toLowerCase();
            const bComp = b.detail?.composition?.toLowerCase();
            const aSearch = a.searchText?.toLowerCase();
            const bSearch = a.searchText?.toLowerCase();

            if (aRef?.startsWith(search.toLowerCase()) && !bRef?.startsWith(search.toLowerCase())) {
              return -1;
            } else if (!aRef?.startsWith(search.toLowerCase()) && bRef?.startsWith(search.toLowerCase())) {
              return 1;
            } else if (aComp?.includes(search.toLowerCase()) && !bComp?.includes(search.toLowerCase())) {
              return -1;
            } else if (!aComp?.includes(search.toLowerCase()) && bComp?.includes(search.toLowerCase())) {
              return 1;
            } else if (aSearch?.includes(search.toLowerCase()) && !bSearch?.includes(search.toLowerCase())) {
              return -1;
            } else if (!aSearch?.includes(search.toLowerCase()) && bSearch?.includes(search.toLowerCase())) {
              return 1;
            } else {
              return 0;
            }
          });

          setProducts(products);
          setSearchLoading(false);
        })
        .catch(e => {
          console.log(e);
          SnackbarFormattedError(e.response.data.error);
          setSearchLoading(false);
        });
    }
  }, [search]);

  const handleSearchChange = (e) => {

    setSearch(e.target.value);
    onChange && onChange(e);
  };

  const handleSelectProduct = (e, value) => {

    if (value) {
      onSelect && onSelect(value);
    }
  };

  const filterOptions = createFilterOptions({
    stringify: option => `${option.reference} ${option.searchText} ${option.detail.composition} ${option.keywords?.map(keyword => keyword.name)}`
  });

  return (
    <Autocomplete
      id="asynchronous-search"
      fullWidth
      open={searchAutocompleteOpen}
      filterOptions={filterOptions}
      onOpen={() => {
        setSearchAutocompleteOpen(true);
      }}
      onClose={() => {
        setSearchAutocompleteOpen(false);
      }}
      onChange={handleSelectProduct}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => ""}
      options={products}
      noOptionsText={t('searchByNameOrComposition')}
      loading={searchLoading}
      sx={{...sx}}
      renderOption={(props, option) => (
        //li must be ordered by last product selected first
        <li {...props} key={option?.id}>
          <Grid container spacing={2} alignItems={"center"}>
            <Grid item xs={2}>
              <Typography align={"center"}>
                <img
                  width={50}
                  height={50}
                  src={option.image[200]}
                  alt={option.reference}
                />
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <Grid container>
                <Grid item xs={12}>
                  {option.reference} {withPrice ? ` - ${option[`price`]} ${option.currency}` : ''}
                </Grid>
                <Grid item xs={12} style={{height: 28, overflow: "hidden"}}>
                  {option.categories.map((category) => (
                    <Typography key={category.id} variant={"caption"} color={"textSecondary"}>
                      {category.name}
                    </Typography>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          autoFocus={autoFocus}
          fullWidth
          label={label}
          placeholder={t("searchProduct")}
          variant={variant}
          onChange={handleSearchChange}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <InputAdornment position="end" sx={{margin: '0px -16px 5px 0px', color: '#7C909B'}}>
                {searchLoading ? <CircularProgress color="inherit" size={20}/> : null}
                <SearchIcon/>
              </InputAdornment>
            )
          }}
        />
      )}
    />
  );
};
export default ProductsAutocomplete;
