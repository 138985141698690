import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { grey } from "@mui/material/colors";
import Dialog from "@mui/material/Dialog";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {makeStyles} from '@mui/styles';

import NetworkRequestTest from "./NetworkRequestTest";

const useStyle = makeStyles((theme) => ({

  root: {
    '& .MuiPaper-root': {
      overflow: 'auto',
      minWidth: 400,
      '& .MuiCardContent-root': {
        padding: 30,
      },
    }
  },
  closeButton: {
    top: 10,
    right: 15,
    cursor: 'pointer',
    position: 'absolute',
    fontSize: 36,
    color: grey[500],
    [theme.breakpoints.down('xs')]: {
      top: 10,
      right: 20,
      fontSize: 40,
    },
  }
}));

const ProfilingTestModal = ({test, handleClose, open}) => {

  const classes = useStyle();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Dialog className={classes.root} onClose={handleClose} open={open} fullScreen={fullScreen}>
      <CloseIcon className={classes.closeButton} onClick={handleClose}/>
      <NetworkRequestTest test={test}/>
    </Dialog>
  );
};
export default ProfilingTestModal;
