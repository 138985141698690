import React, { useEffect, useState } from 'react';
import { Helmet } from "react-helmet-async";
import { Trans, useTranslation } from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import { useHistory } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import {Box, Stack} from "@mui/material";
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import theme from "../../assets/styles/theme/theme";
import FormValidator from "../../components/Validators/FormValidator";
import TextFieldValidator from "../../components/Validators/TextFieldValidator";
import { getLangPath } from "../../helpers/multilingual";
import {SnackbarFormattedError, SnackbarSuccess} from "../../helpers/snackbar";
import {shopActions} from "../Customer/Shop/store/actions";

import HelpBox from "./components/HelpBox";
import { userSelector } from "./store/selectors";
import { authenticationService } from "./store/services";

const ForgotPassword = ({setSection}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const loggedUser = useSelector(userSelector);
  const [email, setEmail] = useState('');

  useEffect(() => {
    if (loggedUser) {
      history.push(`${getLangPath()}`);
    }
  }, [loggedUser]);

  const handleCloseDrawer = () => {
    dispatch(shopActions.SetDrawersOpen("account", false));
  };

  const handleSendPasswordRecoveryEmail = () => {
    authenticationService.passwordRecovery(email)
      .then(() => {
        SnackbarSuccess(t("updatePasswordEmailSent", {email: email}));
        setSection("signIn");
      })
      .catch(e => {
        SnackbarFormattedError(e.error);
      });
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{
        background: '#fff',
        minHeight: '100vh',
        p: '32px 16px',
        '& .MuiInputBase-root': {
          fontFamily: 'Open sans',
          fontSize: '0.8rem',
          color: '#7C909B',
          height: '48px',
          borderRadius: '30px'
        },
        '& .MuiFormLabel-root': {
          fontFamily: 'Open sans',
          fontSize: '0.8rem',
          color: '#7C909B',
          fontStyle: 'italic'
        }
      }}>
      <Helmet>
        <title>{t("forgotPassword")}</title>
        <meta name="description" content={t("forgotPasswordMetaDescription")}/>
        <meta property="og:title" content={t("forgotPassword")}/>
        <link rel="alternate" href={`${process.env.REACT_APP_WEB_URL}/fr/auth/forgot-password`} hrefLang="fr" key="fr"/>
        <link rel="alternate" href={`${process.env.REACT_APP_WEB_URL}/en/auth/forgot-password`} hrefLang="en" key="en"/>
      </Helmet>
      <Box component={'div'} sx={{
        position: 'relative',
      }}>
        <CloseIcon onClick={handleCloseDrawer} sx={{
          top: 0,
          right: 16,
          fontSize: 26,
          cursor: 'pointer',
          position: 'absolute',
          color: '#7C909B',
          background: '#ffffff',
          borderRadius: '50%',
          boxShadow: '2px 2px 8px #7C909B',
          padding: '4px',
        }}/>
      </Box>
      <Typography component="h1" variant="h5" sx={{
        color: theme.palette.primary.main,
        fontFamily: 'Open sans',
        mb: 2
      }}>
        <Trans i18nKey={"forgotPassword"}>
            Forgot password
        </Trans>
      </Typography>
      <FormValidator
        onSubmit={handleSendPasswordRecoveryEmail}
        onError={errors => console.log(errors)}
        noValidate
      >
        <Stack gap={2}>
          <TextFieldValidator
            required
            fullWidth
            type="email"
            name="email"
            label={t("email")}
            autoComplete="email"
            value={email}
            onChange={event => setEmail(event.target.value)}
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color={"primary"}
            sx={{
              fontSize: '1rem',
              textTransform: 'capitalize',
              borderRadius: '30px',
              color: '#fff',
              lineHeight: '1.3',
              padding: '8px 8px',
              minWidth: '200px',
              margin: 'auto'
            }}>
            <Trans i18nKey={"submitForgotPassword"}>Send recovery email</Trans>
          </Button>
          <Typography
            onClick={() => setSection("signIn")}
            variant="body2"
            sx={{
              textAlign: 'center',
              fontFamily: 'Open sans',
              fontSize: '0.8rem',
              color: '#7C909B',
              textDecoration: 'underline',
              cursor: 'pointer'
            }}>
            <Trans i18nKey={"signIn"}>Sign in</Trans>
          </Typography>
        </Stack>
        <HelpBox/>
      </FormValidator>
    </Container>
  );
};

export default ForgotPassword;
