import React, {useEffect, useState} from "react";
import { Trans, useTranslation } from "react-i18next";
import {Link} from "react-router-dom";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import {makeStyles} from '@mui/styles';

import SelectValidator from "../../../../../components/Validators/SelectValidator";
import {SnackbarFormattedError} from "../../../../../helpers/snackbar";
import {customersService} from "../../store/services";

const useStyles = makeStyles(() => ({

  link: {
    textDecoration: 'underline',
    cursor: 'pointer'
  },
}));

const ShippingAddressSelector = ({customer, onChange, selected}) => {

  const classes = useStyles();
  const { t } = useTranslation();
  const [shippingAddresses, setShippingAddresses] = useState([]);

  useEffect(() => {
    customersService.getCustomerShippingAddresses(customer.id).then(shippingAddresses => {
      setShippingAddresses(shippingAddresses);
    })
      .catch(e => SnackbarFormattedError(e.error));
  }, []);

  const handleChangeShippingAddress = (shippingAddress) => {
    onChange(shippingAddress);
  };

  return (
    <Grid container justify={"space-between"} alignItems={"center"}>
      <Grid item>
        <Typography component="h3" variant={"h6"}>
          <Trans i18nKey="shippedTo">Shipped to</Trans> :
        </Typography>
      </Grid>
      <Grid item>
        <Grid container alignItems={"center"}>
          <Grid item>
            <Typography component={Link} to={`/pro/customer/${customer.id}/update/shipping`} color={"inherit"} variant="body1" className={classes.link}>
              <Trans i18nKey="addNewShipping">Add new address</Trans>
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      {shippingAddresses.length > 1 && (
        <Grid item xs={12} sm={12}>
          <SelectValidator
            fullWidth
            name="shippingSelected"
            label={t("selectedShippingAddress")}
            value={selected?.id}
            onChange={e => {handleChangeShippingAddress(shippingAddresses.find(addr => addr.id === e.target.value))}}
            required
          >
            {shippingAddresses.map(shippingAddress => (
              <MenuItem key={shippingAddress.id} value={shippingAddress.id}>{shippingAddress.name ? shippingAddress.name : t('address')}</MenuItem>
            ))}
          </SelectValidator>
        </Grid>
      )}
      {selected && (
        <Grid item xs={12}>
          <Typography component={"p"}>
            {selected.firstName} {selected.lastName}
          </Typography>
          <Typography component={"p"}>
            {selected.address} {selected.additionalInfo && (`(${selected.additionalInfo})`)}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};
export default ShippingAddressSelector;
