import React, {useEffect} from "react";
import Carousel from "react-grid-carousel";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Box, Typography} from "@mui/material";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Container from "@mui/material/Container";
import useMediaQuery from "@mui/material/useMediaQuery";

import theme from "../../../../assets/styles/theme/theme";
import {Link} from "../../../../helpers/multilingual";
import {getFormalizedName} from "../helpers/helper";
import {shopActions} from "../store/actions";
import {shopBrandsSelector} from "../store/selectors";

const TopBrands = () => {

  const dispatch = useDispatch();
  const {i18n} = useTranslation();
  const brands = useSelector(shopBrandsSelector);
  const isUpXl = useMediaQuery(theme => theme.breakpoints.up("xl"));
  const isUpLg = useMediaQuery(theme => theme.breakpoints.up("lg"));
  const isUpMd = useMediaQuery(theme => theme.breakpoints.up("md"));

  useEffect(() => {
    dispatch(shopActions.getBrandsRequest());
  }, [i18n.language]);

  return (
    <Box sx={{
      mt: '40px',
      '& .bghjHX': {
        margin: '0px 0 0',
        padding: '10px 0'
      },
      '& .bVoXXg': {
        padding: '8px 0',
      },
      '& .MuiPaper-root': {
        boxShadow: '6px 6px 12px #00000021',
        height: '78px',
        borderRadius: '12px',
        display: "flex",
        alignItems: "center",
        padding: '4px'
      }
    }}>
      <Container maxWidth={'xxl'}>
        <Typography
          sx={{
            fontSize: '24px',
            fontWeight: 'bold',
            fontFamily: 'Montserrat',
            color: theme.palette.primary.main
          }}>
            LES PRINCIPAUX LABOS
        </Typography>
        <Carousel cols={isUpXl ? 7 : isUpLg ? 6 : isUpMd ? 5 : 4} rows={1} gap={20} loop={true}>
          {brands?.map((item) => item.status === "published" && (
            <Carousel.Item key={item.id}>
              <Card
                component={Link}
                to={`/shop/brand/${getFormalizedName(item.name)}/${item.id}`}
              >
                <CardMedia
                  component="img"
                  height="78"
                  image={item.image}
                  sx={{
                    maxHeight: '78px',
                    height: 'auto',
                    width: 'auto',
                    margin: '0 auto',
                    display: 'block',
                  }}
                />
              </Card>
            </Carousel.Item>
          ))}
        </Carousel>
      </Container>
    </Box>
  );
};

export default TopBrands;