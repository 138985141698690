import React from "react";
import { Trans } from "react-i18next";
import { useSelector } from "react-redux";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {makeStyles} from '@mui/styles';

import { userSelector } from "../../../Authentication/store/selectors";

const useStyles = makeStyles(() => ({
  root: {
    height: '100%'
  },
}));

const CustomerCard = ({customer}) => {

  const classes = useStyles();
  const user = useSelector(userSelector);

  return (
    <Card className={classes.root}>
      <CardContent>
        <Grid container justify="center" spacing={2}>
          <Grid item xs={12}>
            <Grid container justify={"space-between"} alignItems={"center"}>
              <Grid item xs={12}>
                <Typography component="h3" variant={"h6"} gutterBottom>
                  <Trans i18nKey={"generalInformation"}>General information</Trans> :
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography component={"p"} variant="body1">
                  <Typography component={"a"} color={"inherit"} href={`tel:${customer.phone}`}>{customer.phone}</Typography>
                </Typography>
                <Typography component={"p"} variant="body1">
                  <Typography component={"a"} color={"inherit"} href={`mailto:${customer.email}`}>{customer.email}</Typography>
                </Typography>
                <Typography component={"p"} variant="body1">
                  <Typography component={"a"} color={"inherit"} target={"_blank"} href={`https://www.google.fr/maps/place/${customer.address}`}>{customer.address} {customer.additionalInfo && (`(${customer.additionalInfo})`)}</Typography>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {customer.consultantId && customer.consultantId !== user.id && (
            <Grid item xs={12}>
              <Grid container justify={"space-between"} alignItems={"center"}>
                <Grid item xs={12}>
                  <Typography component="h3" variant={"h6"} gutterBottom>
                    <Trans i18nKey={"nutritionConsultant"}>Nutrition consultant</Trans> :
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography component={"p"} variant="body1">
                    <Typography>{customer.consultant.firstName} {customer.consultant.lastName}</Typography>
                  </Typography>
                  <Typography component={"p"} variant="body1">
                    <Typography component={"a"} color={"inherit"} href={`tel:${customer.consultant.phone}`}>{customer.consultant.phone}</Typography>
                  </Typography>
                  <Typography component={"p"} variant="body1">
                    <Typography component={"a"} color={"inherit"} href={`mailto:${customer.consultant.email}`}>{customer.consultant.email}</Typography>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          )}
          {customer.consultantId && customer.consultantId === user.id && (
            <Grid item xs={12}>
              <Grid container justify={"space-between"} alignItems={"center"}>
                <Grid item xs={12}>
                  <Typography component="h3" variant={"h6"} gutterBottom>
                    <Trans i18nKey={"delegate"}>Delegate</Trans> :
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography component={"p"} variant="body1">
                    <Typography>{customer.parent.firstName} {customer.parent.lastName}</Typography>
                  </Typography>
                  <Typography component={"p"} variant="body1">
                    <Typography component={"a"} color={"inherit"} href={`tel:${customer.parent.phone}`}>{customer.parent.phone}</Typography>
                  </Typography>
                  <Typography component={"p"} variant="body1">
                    <Typography component={"a"} color={"inherit"} href={`mailto:${customer.parent.email}`}>{customer.parent.email}</Typography>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          )}
          {customer.shippingAddress && (
            <Grid item xs={12}>
              <Grid container justify={"space-between"} alignItems={"center"}>
                <Grid item xs={12}>
                  <Typography component="h4" variant={"h6"} gutterBottom>
                    <Trans i18nKey="shippingAddress">Shipping address</Trans> :
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography component={"p"}>
                    {customer.shippingAddress.firstName} {customer.shippingAddress.lastName}
                  </Typography>
                  <Typography component={"p"}>
                    <Typography component={"a"} color={"inherit"} target={"_blank"} href={`https://www.google.fr/maps/place/${customer.shippingAddress.address}`}>{customer.shippingAddress.address} {customer.shippingAddress.additionalInfo && (`(${customer.shippingAddress.additionalInfo})`)}</Typography>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};
export default CustomerCard;
