import {
  CHOOSE_CAREER_ERROR,
  CHOOSE_CAREER_REQUEST,
  CHOOSE_CAREER_SUCCESS,
  GET_CAREER_CERTIFICATION_PROGRESSION_ERROR,
  GET_CAREER_CERTIFICATION_PROGRESSION_REQUEST,
  GET_CAREER_CERTIFICATION_PROGRESSION_SUCCESS,
  GET_CAREER_PROGRESSION_ERROR,
  GET_CAREER_PROGRESSION_REQUEST,
  GET_CAREER_PROGRESSION_SUCCESS,
  VALIDATE_CERTIFICATION_CHAPTER_ERROR,
  VALIDATE_CERTIFICATION_CHAPTER_REQUEST,
  VALIDATE_CERTIFICATION_CHAPTER_SUCCESS,
  VALIDATE_CERTIFICATION_TEST_ERROR,
  VALIDATE_CERTIFICATION_TEST_REQUEST,
  VALIDATE_CERTIFICATION_TEST_SUCCESS,
  VALIDATE_CHAPTER_ERROR,
  VALIDATE_CHAPTER_REQUEST,
  VALIDATE_CHAPTER_SUCCESS,
  VALIDATE_QUALIFICATION_ERROR,
  VALIDATE_QUALIFICATION_REQUEST,
  VALIDATE_QUALIFICATION_SUCCESS,
} from "./constants";

export const careerActions = {
  getCareerProgressionRequest,
  getCareerProgressionSuccess,
  getCareerProgressionError,
  getCareerCertificationProgressionRequest,
  getCareerCertificationProgressionSuccess,
  getCareerCertificationProgressionError,
  validateChapterRequest,
  validateChapterSuccess,
  validateChapterError,
  chooseCareerRequest,
  chooseCareerSuccess,
  chooseCareerError,
  validateCertificationChapterRequest,
  validateCertificationChapterSuccess,
  validateCertificationChapterError,
  validateCertificationTestRequest,
  validateCertificationTestSuccess,
  validateCertificationTestError,
  validateQualificationRequest,
  validateQualificationSuccess,
  validateQualificationError
};

function getCareerProgressionRequest() {
  return {
    type: GET_CAREER_PROGRESSION_REQUEST,
  };
}

function getCareerProgressionSuccess(data) {
  return {
    type: GET_CAREER_PROGRESSION_SUCCESS,
    data
  };
}

function getCareerProgressionError(error) {
  return {
    type: GET_CAREER_PROGRESSION_ERROR,
    error
  };
}

function getCareerCertificationProgressionRequest(id) {
  return {
    type: GET_CAREER_CERTIFICATION_PROGRESSION_REQUEST,
    id
  };
}

function getCareerCertificationProgressionSuccess(id, data) {
  return {
    type: GET_CAREER_CERTIFICATION_PROGRESSION_SUCCESS,
    id, data
  };
}

function getCareerCertificationProgressionError(error) {
  return {
    type: GET_CAREER_CERTIFICATION_PROGRESSION_ERROR,
    error
  };
}

function validateChapterRequest(stepKey, chapterKey) {
  return {
    type: VALIDATE_CHAPTER_REQUEST,
    stepKey, chapterKey
  };
}

function validateChapterSuccess(stepKey, chapterKey) {
  return {
    type: VALIDATE_CHAPTER_SUCCESS,
    stepKey, chapterKey
  };
}

function validateChapterError(error) {
  return {
    type: VALIDATE_CHAPTER_ERROR,
    error
  };
}

function chooseCareerRequest(stepKey, chapterKey, career) {
  return {
    type: CHOOSE_CAREER_REQUEST,
    stepKey, chapterKey, career
  };
}

function chooseCareerSuccess(stepKey, chapterKey, career) {
  return {
    type: CHOOSE_CAREER_SUCCESS,
    stepKey, chapterKey, career
  };
}

function chooseCareerError(error) {
  return {
    type: CHOOSE_CAREER_ERROR,
    error
  };
}

function validateCertificationChapterRequest(certificationId, chapterKey) {
  return {
    type: VALIDATE_CERTIFICATION_CHAPTER_REQUEST,
    certificationId, chapterKey
  };
}

function validateCertificationChapterSuccess(certificationId, chapterKey) {
  return {
    type: VALIDATE_CERTIFICATION_CHAPTER_SUCCESS,
    certificationId, chapterKey
  };
}

function validateCertificationChapterError(error) {
  return {
    type: VALIDATE_CERTIFICATION_CHAPTER_ERROR,
    error
  };
}

function validateCertificationTestRequest(certificationId, chapterKey, data) {
  return {
    type: VALIDATE_CERTIFICATION_TEST_REQUEST,
    certificationId, chapterKey, data
  };
}

function validateCertificationTestSuccess(certificationId, chapterKey, data) {
  return {
    type: VALIDATE_CERTIFICATION_TEST_SUCCESS,
    certificationId, chapterKey, data
  };
}

function validateCertificationTestError(error) {
  return {
    type: VALIDATE_CERTIFICATION_TEST_ERROR,
    error
  };
}

function validateQualificationRequest(stepKey, chapterKey) {
  return {
    type: VALIDATE_QUALIFICATION_REQUEST,
    stepKey, chapterKey
  };
}

function validateQualificationSuccess(stepKey, chapterKey) {
  return {
    type: VALIDATE_QUALIFICATION_SUCCESS,
    stepKey, chapterKey
  };
}

function validateQualificationError(error) {
  return {
    type: VALIDATE_QUALIFICATION_ERROR,
    error
  };
}
