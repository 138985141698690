import i18next from "i18next";
import { call, put, takeEvery } from 'redux-saga/effects';

import { getLangPath } from "../../../../helpers/multilingual";
import {SnackbarConfirm, SnackbarFormattedError, SnackbarInfo, SnackbarSuccess} from "../../../../helpers/snackbar";
import { history } from "../../../../helpers/store";

import { customersActions } from "./actions";
import * as actionsType from './constants';
import { customersService } from "./services";

export const customersSagaKey = 'customers';

function* getCustomers() {

  try {
    let data = yield call(customersService.getCustomers);
    yield put(customersActions.getCustomersSuccess(data));
  }
  catch (e) {
    yield put(customersActions.getCustomersError());
  }
}

function* getCustomer(action) {

  try {
    let customer = yield call(customersService.getCustomer, action.customerId);
    yield put(customersActions.getCustomerSuccess(customer));
  }
  catch (e) {
    yield call(SnackbarFormattedError, e.error);
    yield put(customersActions.getCustomerError());
  }
}

function* getCustomerOrders(action) {

  try {
    let customerOrders = yield call(customersService.getCustomerOrders, action.customerId);
    yield put(customersActions.getCustomerOrdersSuccess(customerOrders));
  }
  catch (e) {
    yield call(SnackbarFormattedError, e.error);
    yield put(customersActions.getCustomerOrdersError());
  }
}

function* getCustomerAnamneses(action) {

  try {
    let anamneses = yield call(customersService.getCustomerAnamneses, action.customerId);
    yield put(customersActions.getCustomerAnamnesesSuccess(anamneses));
  }
  catch (e) {
    yield call(SnackbarFormattedError, e.error);
    yield put(customersActions.getCustomerAnamnesesError(e.error));
  }
}

function* addCustomer(action) {

  try {
    let customer = yield call(customersService.addCustomer, action.customer);
    if(customer.alreadyExists) {
      yield call(SnackbarConfirm, i18next.t('importExistingCustomer'), () => {
        history.push(`${getLangPath()}/pro/customer/${customer.id}/import`);
      });
    }
    else {
      yield put(customersActions.addCustomerSuccess(customer));
      history.push(`${getLangPath()}/pro/customer/${customer.id}`);
    }
  }
  catch (e) {
    yield call(SnackbarFormattedError, e.error);
    yield put(customersActions.addCustomerError());
  }
}

function* addCustomerOrder(action) {

  try {
    let order = yield call(customersService.addCustomerOrder, action.customer.id, action.products);
    yield put(customersActions.addCustomerOrderSuccess(order));
    SnackbarSuccess(i18next.t('orderSent'));
  }
  catch (e) {
    yield call(SnackbarFormattedError, e.error);
    yield put(customersActions.addCustomerOrderError());
  }
}

function* updateCustomer(action) {

  try {
    let customer = yield call(customersService.updateCustomer, action.customer.id, action.data);
    yield put(customersActions.updateCustomerSuccess(customer));
    yield call(SnackbarSuccess, i18next.t("dataUpdated"));
    history.push(`${getLangPath()}/pro/customer/${customer.id}`);
  }
  catch (e) {
    yield call(SnackbarFormattedError, e.error);
    yield put(customersActions.updateCustomerError());
  }
}

function* updateCustomerShippingAddress(action) {

  try {
    let shippingAddress = yield call(customersService.updateCustomerShippingAddress, action.customer.id, action.data);
    yield put(customersActions.updateCustomerShippingAddressSuccess(shippingAddress));
    yield call(SnackbarSuccess, i18next.t("dataUpdated"));
    history.push(`${getLangPath()}/pro/customer/${action.customer.id}`);
  }
  catch (e) {
    yield call(SnackbarFormattedError, e.error);
    yield put(customersActions.updateCustomerShippingAddressError());
  }
}

function* getCustomerAnamnesis(action) {

  try {
    let anamnesis = yield call(customersService.getAnamnesis, action.customer.id);
    yield put(customersActions.getCustomerAnamnesisSuccess(anamnesis));
  }
  catch (e) {
    yield call(SnackbarFormattedError, e.error);
    yield put(customersActions.getCustomerAnamnesisError(e.error));
  }
}

function* addCustomerAnamnesisNote(action) {

  try {
    let note = yield call(customersService.addAnamnesisNote, action.customer.id, action.note);
    yield put(customersActions.addCustomerAnamnesisNoteSuccess(note));
  }
  catch (e) {
    yield call(SnackbarFormattedError, e.error);
    yield put(customersActions.addCustomerAnamnesisNoteError(e.error));
  }
}

function* deleteCustomerAnamnesisNote(action) {

  try {
    const note = yield call(customersService.removeAnamnesisNote, action.customer.id, action.note);
    yield put(customersActions.deleteCustomerAnamnesisNoteSuccess(note));
  }
  catch (e) {
    yield call(SnackbarFormattedError, e.error);
    yield put(customersActions.deleteCustomerAnamnesisNoteError(e.error));
  }
}

function* requestSupport(action) {

  try {
    yield call(customersService.requestSupport, action.customer.id);
    yield call(SnackbarInfo, i18next.t('anamnesisAgsSupportRequested'));
    yield put(customersActions.requestSupportSuccess());
  }
  catch (e) {
    yield call(SnackbarFormattedError, e.error);
    yield put(customersActions.requestSupportError(e.error));
  }
}

function* getCustomerRedirections() {

  try {
    let customers = yield call(customersService.getCustomerRedirections);
    yield put(customersActions.getCustomerRedirectionsSuccess(customers));
  }
  catch (e) {
    yield call(SnackbarFormattedError, e.error);
    yield put(customersActions.getCustomerRedirectionsError());
  }
}

export default function* watchDashboard() {

  yield takeEvery(actionsType.GET_CUSTOMERS_REQUEST, getCustomers);
  yield takeEvery(actionsType.GET_CUSTOMER_REQUEST, getCustomer);
  yield takeEvery(actionsType.GET_CUSTOMER_ORDERS_REQUEST, getCustomerOrders);
  yield takeEvery(actionsType.GET_CUSTOMER_ANAMNESES_REQUEST, getCustomerAnamneses);
  yield takeEvery(actionsType.ADD_CUSTOMER_REQUEST, addCustomer);
  yield takeEvery(actionsType.ADD_CUSTOMER_ORDER_REQUEST, addCustomerOrder);
  yield takeEvery(actionsType.UPDATE_CUSTOMER_REQUEST, updateCustomer);
  yield takeEvery(actionsType.UPDATE_CUSTOMER_SHIPPING_ADDRESS_REQUEST, updateCustomerShippingAddress);
  yield takeEvery(actionsType.GET_CUSTOMER_ANAMNESIS_REQUEST, getCustomerAnamnesis);
  yield takeEvery(actionsType.ADD_CUSTOMER_ANAMNESIS_NOTE_REQUEST, addCustomerAnamnesisNote);
  yield takeEvery(actionsType.DELETE_CUSTOMER_ANAMNESIS_NOTE_REQUEST, deleteCustomerAnamnesisNote);
  yield takeEvery(actionsType.REQUEST_SUPPORT_REQUEST, requestSupport);
  yield takeEvery(actionsType.GET_CUSTOMER_REDIRECTIONS_REQUEST, getCustomerRedirections);
}
