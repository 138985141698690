import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import BusinessTwoToneIcon from '@mui/icons-material/BusinessTwoTone';
import EuroIcon from '@mui/icons-material/Euro';
import ListAltTwoToneIcon from '@mui/icons-material/ListAltTwoTone';
import MenuBookTwoToneIcon from '@mui/icons-material/MenuBookTwoTone';
import StorefrontTwoToneIcon from '@mui/icons-material/StorefrontTwoTone';
import {Box} from "@mui/material";
import Divider from "@mui/material/Divider";
import Toolbar from "@mui/material/Toolbar";

import theme from "../../../assets/styles/theme/theme";
import {statsActions} from "../../../containers/Admin/Stats/store/actions";
import {statsDisplayedSelector} from "../../../containers/Admin/Stats/store/selectors";
import {Link} from "../../../helpers/multilingual";
import Menu from "../Menu/Menu";
import MenuItem from "../Menu/MenuItem";
import MenuSubItem from "../Menu/MenuSubItem";


const MenuDrawer = () => {

  const dispatch = useDispatch();
  const {sales, products, pros, delegates, countries, categories} = useSelector(statsDisplayedSelector());

  const options = (selected) => (
    {
      '&:hover': {
        backgroundColor: selected ? 'primary.main' : '',
      },
      backgroundColor: selected ? 'primary.main' : '',
      '& .MuiListItemText-primary': {
        color: selected ? 'white' : ''
      },
      '& .MuiListItemIcon-root': {
        color: selected ? 'white' : ''
      }
    }
  );
  return (
    <div>
      <Toolbar>
        <Link to="/admin">
          <Box component={'img'} width={50} src={`${process.env.REACT_APP_API_RESOURCES_URL}/images/logo/logo.svg`} alt="logo" sx={{width: '100%'}}/>
        </Link>
      </Toolbar>
      <Divider />
      <Menu sx={{
        '& .MuiListItemText-primary': {
          fontFamily: 'Open sans',
          color: 'black'
        },
        '& .MuiListItemIcon-root': {
          color: theme.palette.primary.main
        }
      }}>
        <MenuItem
          title={"Chiffre d'affaires"}
          expandTo={"/statistics/sales"}
          icon={<StorefrontTwoToneIcon/>}
        >
          <MenuSubItem
            title={"Par pays"}
            to={"/statistics/sales"}
            sx={options(sales.countries)}
            onClick={() => dispatch(statsActions.setStatsDisplayed('sales','countries',!sales.countries))}
            icon={<EuroIcon/>}
          />
          <MenuSubItem
            title={"Par délégués"}
            to={"/statistics/sales"}
            sx={options(sales.delegates)}
            onClick={() => dispatch(statsActions.setStatsDisplayed('sales','delegates',!sales.delegates))}
            icon={<BusinessTwoToneIcon/>}
          />
          <MenuSubItem
            title={"Par professionnels"}
            to={"/statistics/sales"}
            sx={options(sales.pros)}
            onClick={() => dispatch(statsActions.setStatsDisplayed('sales','pros',!sales.pros))}
            icon={<BusinessTwoToneIcon/>}
          />
          <MenuSubItem
            title={"Par laboratoires"}
            to={"/statistics/sales"}
            sx={options(sales.brands)}
            onClick={() => dispatch(statsActions.setStatsDisplayed('sales','brands',!sales.brands))}
            icon={<BusinessTwoToneIcon/>}
          />
          <MenuSubItem
            title={"Par catégorie"}
            to={"/statistics/sales"}
            sx={options(sales.categories)}
            onClick={() => dispatch(statsActions.setStatsDisplayed('sales','categories',!sales.categories))}
            icon={<MenuBookTwoToneIcon/>}
          />
          <MenuSubItem
            title={"Par produit"}
            to={"/statistics/sales"}
            sx={options(sales.products)}
            onClick={() => dispatch(statsActions.setStatsDisplayed('sales','products',!sales.products))}
            icon={<ListAltTwoToneIcon/>}
          />
        </MenuItem>
        {/*<MenuItem
          title={"Pays"}
          expandTo={"/statistics/countries"}
          icon={<StorefrontTwoToneIcon/>}
        >
          <MenuSubItem
            title={"Par délégués"}
            to={"/statistics/countries"}
            sx={options(sales.delegates)}
            onClick={() => dispatch(statsActions.setStatsDisplayed('countries','delegates',!countries.delegates))}
            icon={<BusinessTwoToneIcon/>}
          />
          <MenuSubItem
            title={"Par professionnels"}
            to={"/statistics/countries"}
            sx={options(sales.pros)}
            onClick={() => dispatch(statsActions.setStatsDisplayed('countries','pros',!countries.pros))}
            icon={<BusinessTwoToneIcon/>}
          />
          <MenuSubItem
            title={"Par laboratoires"}
            to={"/statistics/countries"}
            sx={options(sales.pros)}
            onClick={() => dispatch(statsActions.setStatsDisplayed('countries','pros',!countries.brands))}
            icon={<BusinessTwoToneIcon/>}
          />
          <MenuSubItem
            title={"Par catégorie"}
            to={"/statistics/countries"}
            sx={options(sales.categories)}
            onClick={() => dispatch(statsActions.setStatsDisplayed('countries','categories',!countries.categories))}
            icon={<MenuBookTwoToneIcon/>}
          />
          <MenuSubItem
            title={"Par produit"}
            to={"/statistics/countries"}
            sx={options(sales.products)}
            onClick={() => dispatch(statsActions.setStatsDisplayed('countries','products',!countries.products))}
            icon={<ListAltTwoToneIcon/>}
          />
        </MenuItem>*/}
      </Menu>
    </div>
  );
};

export default MenuDrawer;
