import produce from "immer";

import * as actionsType from './constants';
import initialState from './initialState';

export default (state = initialState.consultants, action) =>
  produce(state, draft => {
    switch (action.type) {

      case actionsType.SEARCH_OFFICES_FROM_PLACE_REQUEST:
      case actionsType.SEARCH_OFFICES_FROM_PLACE_AND_ZOOM_REQUEST:
        draft.map.search = action.place;
        draft.map.searchBarModalOpen = false;
        break;

      case actionsType.SEARCH_OFFICES_FROM_PLACE_SUCCESS:
      case actionsType.SEARCH_OFFICES_FROM_PLACE_AND_ZOOM_SUCCESS:
        draft.map.offices = action.offices;
        draft.map.zoom = action.zoom;
        break;

      case actionsType.SEARCH_OFFICES_FROM_PLACE_FAILED:
      case actionsType.SEARCH_OFFICES_FROM_PLACE_AND_ZOOM_FAILED:
        draft.map.search = null;
        draft.map.offices = null;
        draft.map.zoom = initialState.consultants.map.zoom;
        break;

      case actionsType.GET_OFFICE_REQUEST:
        draft.office = null;
        break;

      case actionsType.GET_OFFICE_SUCCESS:
        draft.office = action.office;
        break;

      case actionsType.GET_OFFICE_FAILED:
        draft.office = null;
        break;

      case actionsType.SET_MAP_DISPLAY_OPTION:
        draft.map.display = action.display;
        break;

      case actionsType.SET_SEARCH_BAR_OPEN:
        draft.map.searchBarModalOpen = action.open;
        break;

      default:
    }
  });
