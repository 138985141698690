import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {makeStyles} from '@mui/styles';

import LineChartByYearAndMonth from "../../../../components/Charts/LineChartByYearAndMonth";
import PageLoader from "../../../../components/PageLoader";
import {proStatsActions} from "../store/actions";
import {
  networkAverageTurnoverByActiveProsAmountLinesGraphDataSelector,
  networkAverageTurnoverByActiveProsAmountSelector
} from "../store/selectors";

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    '& .MuiCardHeader-root': {
      '& .MuiCardHeader-title': {
        fontSize: '1.5rem !important',
        lineHeight: '1.8rem',
        paddingBottom: 10,
        fontWeight: '400 !important'
      },
      '& .MuiCardHeader-content': {
        height: 64
      },
      '& .MuiInput-root': {
        '& .MuiInputBase-input': {
          textAlign: 'right'
        }
      }
    }
  }
}));

const NetworkAverageTurnoversByActiveProsByMonthChart = ({user}) => {

  const dispatch = useDispatch();
  const classes = useStyles();
  const {t} = useTranslation();
  const averageOrderAmountLinesGraphData = useSelector(networkAverageTurnoverByActiveProsAmountLinesGraphDataSelector(user.id));
  const averageOrderAmountByYear = useSelector(networkAverageTurnoverByActiveProsAmountSelector(user.id));

  useEffect(() => {
    if (!averageOrderAmountLinesGraphData) {
      dispatch(proStatsActions.getNetworkSalesRequest(user.id));
    }
    if (!averageOrderAmountLinesGraphData) {
      dispatch(proStatsActions.getNetworkActiveProsCountByMonthRequest(user.id));
    }
  }, []);

  return (
    <Card className={classes.root}>
      <CardHeader title={t('averageTurnoverByActivePro')}/>
      <Divider/>
      {!averageOrderAmountByYear && (
        <PageLoader height={200}/>
      )}
      {averageOrderAmountByYear && (
        <>
          <CardContent>
            <LineChartByYearAndMonth
              labels={"months"}
              data={averageOrderAmountLinesGraphData}
              heightLg={350}
              heightMd={300}
              heightSm={300}
              primaryColor={{
                border: 'rgb(89,80,236)',
                background: 'rgba(89,80,236, 0.04)'
              }}
            />
          </CardContent>
          <Grid container>

            {Object.keys(averageOrderAmountByYear).map((year, key) => (
              <Grid key={key} item xs={4}>
                <Typography component={"div"} align={"center"} gutterBottom>
                  <Typography>{year}</Typography>
                  <Typography
                    variant={"h6"}>{averageOrderAmountByYear[year].toFixed(2)} €</Typography>
                </Typography>
              </Grid>
            ))}
          </Grid>
        </>
      )}
    </Card>
  );
};
export default NetworkAverageTurnoversByActiveProsByMonthChart;
