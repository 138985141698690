import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";

import OrderItem from "./OrderItem";

const OrderListItems = ({className, items, handleRemoveProduct, setProductQuantity}) => {

  const {t} = useTranslation();
  const [totalPrice, setTotalPrice] = useState(0);
  const [currency, setCurrency] = useState('');

  useEffect(() => {
    let amount = 0;
    Object.keys(items).map(key => {
      amount += items[key].price * items[key].quantity;
      setCurrency(items[key].currency);
    });
    setTotalPrice(amount);
  }, [items]);

  return (
    <List subheader={<Typography variant={'h6'}>{t('Total')} : {totalPrice} {currency} {t('exclTaxes')}</Typography>} className={className}>
      {Object.keys(items).map(key => (
        <React.Fragment key={key}>
          <OrderItem item={items[key]} setProductQuantity={setProductQuantity} handleRemoveProduct={handleRemoveProduct}/>
          {items.length > 1 && (
            <Divider/>
          )}
        </React.Fragment>
      ))}
    </List>
  );
};
export default OrderListItems;
