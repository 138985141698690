import React, {useState} from "react";
import { Trans, useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { grey } from "@mui/material/colors";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {makeStyles} from '@mui/styles';

import NumberTextField from "../../../../components/NumberTextField";
import FormValidator from "../../../../components/Validators/FormValidator";
import TextFieldValidator from "../../../../components/Validators/TextFieldValidator";
import {SnackbarFormattedError, SnackbarSuccess} from "../../../../helpers/snackbar";
import { userService } from "../store/services";


const useStyle = makeStyles((theme) => ({

  root: {
    overflow: 'auto',
    minWidth: 400,
    '& .MuiContainer-root': {
      marginTop: 20,
      marginBottom: 20
    },
    '& .giftCardItem': {
      '& .MuiTypography-h6': {
        whiteSpace: 'nowrap'
      }
    }
  },
  closeButton: {
    top: 10,
    right: 15,
    cursor: 'pointer',
    position: 'absolute',
    fontSize: 36,
    color: grey[500],
    [theme.breakpoints.down('xs')]: {
      top: 10,
      right: 20,
      fontSize: 40,
    },
  },
  addProductButton: {
    marginBottom: 30
  }
}));

const UserOfferGiftCardModal = ({customer, setOpen, open, onChange}) => {

  const classes = useStyle();
  const theme = useTheme();
  const { t } = useTranslation();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const [values, setValues] = useState({
    name: '',
    amount: 0
  });

  const handleClose = () => {
    setOpen(false);
    onChange();
  };

  const handleChange = (event) => {

    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleChangeNumber = (event) => {

    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleSubmitGiftCard = () => {

    userService.sendUserGiftCard(customer.id, values).then(() => {
      SnackbarSuccess(t('giftCardSent'));
      handleClose();
    })
      .catch(e => {
        SnackbarFormattedError(e.error);
      });
  };

  return (
    <Dialog onClose={handleClose} open={open} fullScreen={fullScreen}>
      <CloseIcon className={classes.closeButton} onClick={handleClose}/>
      <DialogTitle id="responsive-dialog-title"><Trans i18nKey="offerGiftCard">Offer a gift card</Trans></DialogTitle>
      <DialogContent>
        <Card elevation={0} className={classes.root}>
          <CardContent>
            <FormValidator
              onSubmit={handleSubmitGiftCard}
              onError={errors => console.log(errors)}
              noValidate
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextFieldValidator
                    fullWidth
                    name="name"
                    label={"Nom"}
                    value={values.name}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <NumberTextField
                    fullWidth
                    name="amount"
                    label={"Montant"}
                    value={values.amount}
                    onChange={handleChangeNumber}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                  >
                                        Envoyer une carte cadeau
                  </Button>
                </Grid>
              </Grid>
            </FormValidator>
          </CardContent>
        </Card>
      </DialogContent>
    </Dialog>
  );
};
export default UserOfferGiftCardModal;
/*import React from "react";
import { Trans, useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import DialogTitle from "@mui/material/DialogTitle";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import {makeStyles} from '@mui/styles';
import { grey } from "@mui/material/colors";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Typography from "@mui/material/Typography";
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import {SnackbarConfirm, SnackbarFormattedError, SnackbarSuccess} from "../../../../helpers/snackbar";
import { userService } from "../store/services";
import DialogContent from "@mui/material/DialogContent";
import Divider from "@mui/material/Divider";


const useStyle = makeStyles((theme) => ({

    root: {
        overflow: 'auto',
        minWidth: 400,
        '& .MuiContainer-root': {
            marginTop: 20,
            marginBottom: 20
        },
        '& .giftCardItem': {
            '& .MuiTypography-h6': {
                whiteSpace: 'nowrap'
            }
        }
    },
    closeButton: {
        top: 10,
        right: 15,
        cursor: 'pointer',
        position: 'absolute',
        fontSize: 36,
        color: grey[500],
        [theme.breakpoints.down('xs')]: {
            top: 10,
            right: 20,
            fontSize: 40,
        },
    },
    addProductButton: {
        marginBottom: 30
    }
}));

const UserOfferGiftCardModal = ({customer, setOpen, open, onChange}) => {

    const classes = useStyle();
    const theme = useTheme();
    const { t } = useTranslation();
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

    const handleClose = () => {
        setOpen(false);
        onChange();
    }

    const handleConfirmAndSendGiftCardModal = (amount) => {

        SnackbarConfirm(t('sendTheGiftCard'), () => {
            handleSendGiftCardModal(amount);
        })
    }

    const handleSendGiftCardModal = (amount) => {

        userService.sendUserGiftCard(customer.id, amount).then(() => {
            SnackbarSuccess(t('giftCardSent'));
            handleClose();
        })
            .catch(e => {
                SnackbarFormattedError(e.error);
            })
    }

    return (
        <Dialog onClose={handleClose} open={open} fullScreen={fullScreen}>
            <CloseIcon className={classes.closeButton} onClick={handleClose}/>
            <DialogTitle id="responsive-dialog-title"><Trans i18nKey="offerGiftCard">Offer a gift card</Trans></DialogTitle>
            <DialogContent>
                <Card elevation={0} className={classes.root}>
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={6} sm={3}>
                                <Card className={"giftCardItem"}>
                                    <CardContent>
                                        <Typography component={"div"} align={"center"} gutterBottom>
                                            <Typography><Trans i18nKey={"amountGiftCard"} values={{count: 5, currency: customer.currency}}>5 gift cards</Trans></Typography>
                                            <Divider/>
                                            <Button color={"secondary"} fullWidth onClick={() => handleConfirmAndSendGiftCardModal(5)}>
                                                <EmailOutlinedIcon/>
                                            </Button>
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Card className={"giftCardItem"}>
                                    <CardContent>
                                        <Typography component={"div"} align={"center"} gutterBottom>
                                            <Typography><Trans i18nKey={"amountGiftCard"} values={{count: 10, currency: customer.currency}}>10 gift cards</Trans></Typography>
                                            <Divider/>
                                            <Button color={"secondary"} fullWidth onClick={() => handleConfirmAndSendGiftCardModal(10)}>
                                                <EmailOutlinedIcon/>
                                            </Button>
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Card className={"giftCardItem"}>
                                    <CardContent>
                                        <Typography component={"div"} align={"center"} gutterBottom>
                                            <Typography><Trans i18nKey={"amountGiftCard"} values={{count: 15, currency: customer.currency}}>15 gift cards</Trans></Typography>
                                            <Divider/>
                                            <Button color={"secondary"} fullWidth onClick={() => handleConfirmAndSendGiftCardModal(15)}>
                                                <EmailOutlinedIcon/>
                                            </Button>
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Card className={"giftCardItem"}>
                                    <CardContent>
                                        <Typography component={"div"} align={"center"} gutterBottom>
                                            <Typography><Trans i18nKey={"amountGiftCard"} values={{count: 20, currency: customer.currency}}>20 gift cards</Trans></Typography>
                                            <Divider/>
                                            <Button color={"secondary"} fullWidth onClick={() => handleConfirmAndSendGiftCardModal(20)}>
                                                <EmailOutlinedIcon/>
                                            </Button>
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </DialogContent>
        </Dialog>
    );
}
export default UserOfferGiftCardModal;*/
