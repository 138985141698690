import React from "react";
import {useDispatch, useSelector} from "react-redux";
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import {IconButton, Tooltip} from "@mui/material";

//import {SnackbarConfirm, SnackbarInfo} from "../../../../../helpers/snackbar";
import {shopActions} from "../../store/actions";
import {isProductFavoriteSelector} from "../../store/selectors";

const AddToFavorites = ({product, sx}) => {

  const dispatch = useDispatch();
  const isFavorite = useSelector(isProductFavoriteSelector(product?.id, product?.isFavorite));

  const addToFavorites = () => {

    dispatch(shopActions.addToFavoritesRequest(product?.id));
  };

  const removeFromFavorites = () => {

    dispatch(shopActions.removeFromFavoritesRequest(product?.id));
  };

  return (
    isFavorite ?
      <Tooltip title={"Retirer des favoris"}>
        <IconButton
          onClick={removeFromFavorites}
          sx={{
            ...sx,
            backgroundColor: 'transparent', // Ensure background is transparent
            '&:hover': {
              backgroundColor: 'transparent', // Ensure background stays transparent on hover
              transform: 'scale(1.2)', // Pop effect on hover
            },
            '& .MuiSvgIcon-root': {
              scale: '1.2',
              transition: 'transform 0.2s', // Smooth transition for the pop effect
            },
            color: '#ffffff'
          }}
        >
          <FavoriteIcon/>
        </IconButton>
      </Tooltip>
      : <Tooltip title={"Ajouter aux favoris"}>
        <IconButton
          onClick={addToFavorites}
          color={'#ffffff'}
          sx={{
            ...sx,
            backgroundColor: 'transparent', // Ensure background is transparent
            '&:hover': {
              backgroundColor: 'transparent', // Ensure background stays transparent on hover
              transform: 'scale(1.2)', // Pop effect on hover
            },
            '& .MuiSvgIcon-root': {
              transition: 'transform 0.2s', // Smooth transition for the pop effect
              scale: '1.2'
            },
            color: '#ffffff'
          }}
        >
          <FavoriteBorderIcon/>
        </IconButton>
      </Tooltip>
  );
};
export default AddToFavorites;
