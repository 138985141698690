import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {Box} from "@mui/material";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import LazyLoadCardMedia from "../../../components/LazyLoad/LazyLoadCardMedia";
import { Link } from "../../../helpers/multilingual";
import {sessionSelector} from "../Shop/store/selectors";

import { magActions } from "./store/actions";
import { getArticlesSelector } from "./store/selectors";

const Articles = () => {

  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();
  const session = useSelector(sessionSelector);
  const articles = useSelector(getArticlesSelector);

  useEffect(() => {

    if(!Array.isArray(articles) || articles.length === 0 || articles[0].lang !== i18n.language) {
      dispatch(magActions.getArticlesRequest(i18n.language));
    }
  }, [i18n.language, session]);

  return (
    <Box sx={{
      paddingBottom: 40,
      paddingTop: '11vw'
    }}>
      <Helmet>
        <title>{t('theMAG')}</title>
        <meta property="og:title" content={"Blog | Nutri-logics"}/>
        <meta name="description" content={t('magMetaDescription')}/>
      </Helmet>
      <Container fixed maxWidth="xxl">
        <Grid container spacing={1}>
          {articles.map((article, key) => (
            <Grid key={key} item xs={12} sm={6} md={4} lg={3} xl={3} xxl={3}>
              {key <= 3 && (
                <Helmet>
                  <link rel="preload" as="image" href={article.cover[400]} />
                </Helmet>
              )}
              <Link to={`/mag/${article.url}`}>
                <Card sx={{
                  boxShadow: '6px 6px 12px #00000021',
                  borderRadius: '12px',
                }}>
                  <CardActionArea>
                    <LazyLoadCardMedia
                      component="img"
                      alt={article.title}
                      height="300"
                      image={article.cover[600]}
                      skeletonSrc={key <= 3 ? article.cover[400] : `${process.env.REACT_APP_API_RESOURCES_URL}/mag/cover/default-400.jpg`}
                      title={article.title}
                      onError={e => {
                        e.target.src = `${process.env.REACT_APP_API_RESOURCES_URL}/mag/cover/default-600.jpg`;
                      }}
                    />
                    <CardContent sx={{
                      height: '150px'
                    }}>
                      <Typography gutterBottom variant="h5" component="h2" sx={{
                        fontSize: '1.3rem',
                        fontFamily: 'Raleway',
                        fontWeight: 400,
                        color: 'primary.light',
                        display: '-webkit-box',
                        WebkitLineClamp: '2',
                        WebkitBoxOrient: 'vertical',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}>
                        {article.title}
                      </Typography>
                      <Typography variant="body2" color="textSecondary" component="p" sx={{
                        fontFamily: 'Raleway',
                        fontSize: '1rem',
                        fontWeight: 300,
                        color: 'primary.light',
                        display: '-webkit-box',
                        WebkitLineClamp: '3',
                        WebkitBoxOrient: 'vertical',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}>
                        {article.metaDescription}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Link>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};
export default Articles;
