import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import AutocompletePlaces from "../../../../components/GoogleAutocomplete/AutocompletePlaces";
import FormValidator from "../../../../components/Validators/FormValidator";
import TextFieldValidator from "../../../../components/Validators/TextFieldValidator";
import { SnackbarError } from "../../../../helpers/snackbar";
import {userSelector} from "../../../Authentication/store/selectors";
import { networkActions } from "../store/actions";

const PartnerRegistrationCard = ({parent}) => {

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [sameAsBillingAddress, setSameAsBillingAddress] = useState(true);
  const [specialCharacters, setSpecialCharacters] = useState(false);
  const [userValues, setUserValues] = useState({
    parentId: parent.id
  });

  const handleChange = (e) => {

    setUserValues({
      ...userValues,
      [e.target.name]: e.target.value
    });
  };

  const handleCheckRadioFormBooleanValue = (e) => {

    setUserValues({
      ...userValues,
      [e.target.name]: e.target.checked
    });
  };

  const handleTextFieldChange = (e) => {

    const pattern = /^[a-zA-Z0-9-]*$/;
    setUserValues({
      ...userValues,
      directLink: e.target.value
    });
    setSpecialCharacters(!pattern.test(e.target.value));
  };

  const handleBillingAddressChanged = (place) => {

    setUserValues({
      ...userValues,
      ['billingAddress']: place
    });
  };

  const handleShippingAddressChanged = (place) => {

    setUserValues({
      ...userValues,
      ['shippingAddress']: place
    });
  };

  const handleSameAsBillingAddressChange = (event) => {
    setSameAsBillingAddress(event.target.checked);
  };

  const handleSubmit = () => {

    setFormSubmitted(true);
    if (!userValues.directLink || !userValues.billingAddress || (!sameAsBillingAddress && !userValues.shippingAddress)) {
      SnackbarError(t('errorMessageRequiredFields'));
      return false;
    }
    let data = userValues;
    if(sameAsBillingAddress) {
      handleShippingAddressChanged(userValues.billingAddress);
      data = {...userValues, shippingAddress: userValues.billingAddress};
    }

    dispatch(networkActions.addPartnerRequest({...data}));
  };

  return (
    <Card>
      <CardContent>
        <FormValidator
          onSubmit={handleSubmit}
          onError={errors => {
            setFormSubmitted(true);
            console.log('ERRORS', errors);
          }}
          noValidate
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {parent.id !== user.id && (
                <TextField
                  fullWidth
                  variant={"outlined"}
                  label={t('partnerOf')}
                  value={`${parent.firstName} ${parent.lastName}`}
                  disabled
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <TextFieldValidator
                fullWidth
                name="company"
                label={t("company")}
                value={userValues.company}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextFieldValidator
                fullWidth
                name="taxNumber"
                label={t("taxNumber")}
                value={userValues.taxNumber}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextFieldValidator
                fullWidth
                name="firstName"
                label={t("firstName")}
                value={userValues.firstName ? userValues.firstName : ""}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextFieldValidator
                fullWidth
                name="lastName"
                label={t("lastName")}
                value={userValues.lastName ? userValues.lastName : ""}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant={"outlined"}
                name="directLink"
                label={t("directLink")}
                value={userValues.directLink ? userValues.directLink : ""}
                onChange={handleTextFieldChange}
                error={formSubmitted && !userValues.directLink ? t("validatorFieldRequired") : specialCharacters}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <FormLabel>{t("clientsCanSearchYouBy")}</FormLabel>
                <FormGroup>
                  <FormControlLabel
                    control={<Switch checked={userValues.allowSearchByName} onChange={handleCheckRadioFormBooleanValue} name="allowSearchByName" />}
                    label={t("yourNameAndFirstName")}
                  />
                </FormGroup>
                <FormGroup>
                  <FormControlLabel
                    control={<Switch checked={userValues.company && userValues.allowSearchByCompany} onChange={handleCheckRadioFormBooleanValue} name="allowSearchByCompany" />}
                    label={<>{t("yourCompanyName")} {!userValues.company ? <Typography component={"i"}>{t('companyNeeded')}</Typography> : ``}</>}
                  />
                </FormGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextFieldValidator
                fullWidth
                id="email"
                type="email"
                name="email"
                label={t("email")}
                value={userValues.email ? userValues.email : ""}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextFieldValidator
                fullWidth
                required
                name="phone"
                label={t("phone")}
                autoComplete="phone"
                value={userValues.phone}
                onChange={handleChange}
              />
            </Grid>
            {/*<Grid item xs={12}>
              <SelectValidator
                fullWidth
                name="lang"
                label={t("mailingAndDataLanguage")}
                value={userValues.lang}
                onChange={handleChange}
                required
              >
                <MenuItem value="fr">
                  {t("french")}
                </MenuItem>
                <MenuItem value="en">
                  {t("english")}
                </MenuItem>
              </SelectValidator>
            </Grid>*/}
            <Grid item xs={12}>
              <AutocompletePlaces
                fullWidth
                variant="outlined"
                name="address"
                label={t("billingAddress")}
                onPlaceChanged={handleBillingAddressChanged}
                error={formSubmitted && !userValues.billingAddress}
                helperText={formSubmitted && !userValues.billingAddress && (t("validatorFieldRequired"))}
                placeholder=""
                preview
                required
                customFieldsEnabled
                requiredFields={['streetNumber', 'streetName', 'zip', 'city', 'country']}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={sameAsBillingAddress}
                    onChange={handleSameAsBillingAddressChange}
                    value="true" color="primary"/>
                }
                label={t("sameAsBillingAddress")}
              />
            </Grid>
            {!sameAsBillingAddress && (
              <>
                <Grid item xs={12}>
                  <FormLabel component="p">
                    <Trans i18nKey="shipping">Shipping information</Trans>
                  </FormLabel>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextFieldValidator
                    fullWidth
                    name="shippingFirstName"
                    label={t("firstName")}
                    value={userValues.shippingFirstName ? userValues.shippingFirstName : ""}
                    onChange={handleChange}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextFieldValidator
                    fullWidth
                    name="shippingLastName"
                    label={t("lastName")}
                    value={userValues.shippingLastName ? userValues.shippingLastName : ""}
                    onChange={handleChange}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <AutocompletePlaces
                    fullWidth
                    variant="outlined"
                    name="address"
                    label={t("shippingAddress")}
                    onPlaceChanged={handleShippingAddressChanged}
                    placeholder=""
                    preview
                    customFieldsEnabled
                    required={!sameAsBillingAddress}
                    requiredFields={['streetNumber', 'streetName', 'zip', 'city', 'country']}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
              >
                <Trans i18nKey={"createPartner"}>
                                    Create partner
                </Trans>
              </Button>
            </Grid>
          </Grid>
        </FormValidator>
      </CardContent>
    </Card>
  );
};
export default PartnerRegistrationCard;
