export const GET_WALLET_REQUEST  = 'WALLET/GET_WALLET_REQUEST';
export const GET_WALLET_SUCCESS  = 'WALLET/GET_WALLET_SUCCESS';
export const GET_WALLET_ERROR    = 'WALLET/GET_WALLET_ERROR';

export const GET_WALLET_HISTORIES_REQUEST  = 'WALLET/GET_WALLET_HISTORIES_REQUEST';
export const GET_WALLET_HISTORIES_SUCCESS  = 'WALLET/GET_WALLET_HISTORIES_SUCCESS';
export const GET_WALLET_HISTORIES_ERROR    = 'WALLET/GET_WALLET_HISTORIES_ERROR';

export const GET_WALLET_COMMISSIONS_CREDIT_NOTES_REQUEST = 'WALLET/GET_WALLET_COMMISSIONS_CREDIT_NOTES_REQUEST';
export const GET_WALLET_COMMISSIONS_CREDIT_NOTES_SUCCESS = 'WALLET/GET_WALLET_COMMISSIONS_CREDIT_NOTES_SUCCESS';
export const GET_WALLET_COMMISSIONS_CREDIT_NOTES_ERROR   = 'WALLET/GET_WALLET_COMMISSIONS_CREDIT_NOTES_ERROR';

export const OPEN_TRANSFER_MODAL   = 'WALLET/OPEN_TRANSFER_MODAL';
export const CLOSE_TRANSFER_MODAL  = 'WALLET/CLOSE_TRANSFER_MODAL';

export const TRANSFER_REQUEST   = 'WALLET/TRANSFER_REQUEST';
export const TRANSFER_SUCCESS   = 'WALLET/TRANSFER_SUCCESS';
export const TRANSFER_ERROR     = 'WALLET/TRANSFER_ERROR';
