import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import AddIcon from '@mui/icons-material/Add';
import BusinessIcon from '@mui/icons-material/Business';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import {Stack} from "@mui/material";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

import theme from "../../../../../assets/styles/theme/theme";
import DotsLoader from "../../../../../components/DotsLoader";
import {SnackbarConfirm} from "../../../../../helpers/snackbar";
import {userSelector} from "../../../../Authentication/store/selectors";
import { shopActions } from "../../../Shop/store/actions";
import {
  userShippingAddressesLoaderSelector,
  userShippingAddressesSelector
} from "../../../Shop/store/selectors";

import AddShippingAddressDialog from "./components/AddShippingAddressDialog";
import UpdateShippingAddressDialog from "./components/UpdateShippingAddressDialog";

const ShippingAddresses = () => {

  const {t} = useTranslation();
  const dispatch = useDispatch();

  const userShippingAddresses = useSelector(userShippingAddressesSelector);
  const user = useSelector(userSelector);
  const loading = useSelector(userShippingAddressesLoaderSelector);
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [updateShippingAddressSelected, setUpdateShippingAddressSelected] = useState(false);
  const [updateDialogOpen, setUpdateDialogOpen] = useState(false);

  useEffect(() => {

    if (!Array.isArray(userShippingAddresses)) {
      dispatch(shopActions.getShippingAddressesRequest());
    }
  }, []);

  const handleRemoveAddress = (address) => () => {
    SnackbarConfirm(<>{t('removeShippingAddress')} :<br/> {address.address}</>, () => {
      dispatch(shopActions.removeShippingAddressRequest(address));
    });
  };

  const handleOpenUpdateDialog = (address) => () => {
    setUpdateShippingAddressSelected(address);
    setUpdateDialogOpen(true);
  };

  const defaultAddress = (type, address) => {
    type === "shipping" ? dispatch(shopActions.setAsDefaultShippingAddressRequest(address)) : dispatch(shopActions.setAsDefaultBillingAddressRequest(address));
  };

  return (
    <React.Fragment key={user?.id}>
      {loading && (
        <Grid container justifyContent={"center"} spacing={4}>
          <DotsLoader/>
        </Grid>
      )}
      {!loading && (
        <Grid container spacing={2} justifyContent={"flex-end"}>
          {Array.isArray(userShippingAddresses) && userShippingAddresses.map((address) => (
            <Grid key={address?.id} item xs={12}>
              <Card
                sx={{
                  boxShadow: '6px 6px 12px #00000021',
                  borderRadius: '30px',
                  '& .MuiCardHeader-root': {
                    '& span': {
                      color: theme.palette.primary.main
                    }
                  }
                }}
              >
                <CardHeader
                  action={
                    <>
                      {!address.byDefaultBilling && (
                        <IconButton
                          aria-label="edit address"
                          onClick={handleOpenUpdateDialog(address)}
                          sx={{
                            color: '#7C909B',
                            background: '#ffffff',
                            borderRadius: '50%',
                            boxShadow: '12px 12px 24px #00000021',
                            padding: '4px',
                          }}
                        >
                          <EditOutlinedIcon/>
                        </IconButton>
                      )}
                      {(!address.byDefault && !address.byDefaultBilling) && (
                        <IconButton
                          aria-label="delete address"
                          onClick={handleRemoveAddress(address)}
                          sx={{
                            color: '#7C909B',
                            background: '#ffffff',
                            borderRadius: '50%',
                            boxShadow: '12px 12px 24px #00000021',
                            padding: '4px',
                            ml: 1
                          }}
                        >
                          <DeleteOutlineIcon/>
                        </IconButton>
                      )}
                    </>
                  }
                  title={
                    <Typography
                      component={"div"}
                      sx={{
                        fontFamily: 'Open sans',
                        fontSize: '0.9rem',
                        fontWeight: 'bold',
                        color: '#7C909B',
                      }}>
                      <Stack gap={1} direction={"row"} alignContent={"center"} alignItems={"center"}>
                        <FmdGoodIcon/>
                        <Typography>
                          {address.name ? address.name : t('address')}
                        </Typography>
                      </Stack>
                    </Typography>}
                  subheader={
                    address.company && (
                      <Typography
                        component={"div"}
                        sx={{
                          fontFamily: 'Open sans',
                          fontSize: '0.9rem',
                          fontWeight: 'bold',
                          color: '#7C909B',
                        }}>
                        <Stack gap={1} direction={"row"} alignContent={"center"} alignItems={"center"}>
                          <BusinessIcon/>
                          <Typography>
                            {address.company ? address.company : ''}
                          </Typography>
                        </Stack>

                      </Typography>
                    )}
                />
                <CardContent sx={{pt: 0}}>
                  <Typography
                    sx={{
                      fontFamily: 'Open sans',
                      fontSize: '0.875rem',
                      color: '#7C909B',
                    }}
                  >
                    {address.firstName} {address.lastName}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: 'Open sans',
                      fontSize: '0.875rem',
                      color: '#7C909B',
                    }}
                  >
                    {address.postalBox}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: 'Open sans',
                      fontSize: '0.875rem',
                      color: '#7C909B',
                    }}
                  >
                    {address.address} {address.additionalInfo && (`(${address.additionalInfo})`)}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      {address.byDefault && (
                        <Typography
                          sx={{
                            fontSize: '0.8rem',
                            fontStyle: 'italic',
                            background: 'rgba(84, 214, 44, 0.16)',
                            padding: '4px',
                            borderRadius: '8px',
                            color: 'rgb(34, 154, 22)',
                            textAlign: 'center',
                          }}
                        >
                          <Trans i18nKey={"defaultShippingAddress"}>
                            Default shipping address
                          </Trans>
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      {address.byDefaultBilling && (
                        <Typography
                          sx={{
                            fontSize: '0.8rem',
                            fontStyle: 'italic',
                            background: 'rgba(84, 214, 44, 0.16)',
                            padding: '4px',
                            borderRadius: '8px',
                            color: 'rgb(34, 154, 22)',
                            textAlign: 'center',
                          }}
                        >
                          <Trans i18nKey={"defaultBillingAddress"}>
                            Default billing address
                          </Trans>
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      {!address.byDefault && (
                        <Button variant={"contained"} onClick={() => defaultAddress("shipping",address)}
                          sx={{
                            fontSize: '0.8rem',
                            textTransform: 'capitalize',
                            borderRadius: '30px',
                            color: '#fff',
                            lineHeight: '1.3',
                            padding: '4px 8px',
                          }}>
                          <Trans i18nKey={"selectDefaultShippingAddressButton"}>
                            Set as default shipping address
                          </Trans>
                        </Button>
                      )}
                    </Grid>
                    {/*<Grid item xs={12}>
                      {!address.byDefaultBilling && (
                        <Button variant={"contained"} onClick={() => defaultAddress("billing",address)}
                          sx={{
                            fontSize: '0.8rem',
                            textTransform: 'capitalize',
                            borderRadius: '30px',
                            color: '#fff',
                            lineHeight: '1.3',
                            padding: '4px 8px',
                          }}>
                          <Trans i18nKey={"selectDefaultBillingAddressButton"}>
                            Set as default billing address
                          </Trans>
                        </Button>
                      )}
                    </Grid>*/}
                  </Grid>
                </CardActions>
                {address.byDefaultBilling && (
                  <Typography
                    sx={{
                      fontSize: '0.8rem',
                      fontStyle: 'italic',
                      padding: '4px',
                      borderRadius: '8px',
                      color: 'rgb(34, 154, 22)',
                      textAlign: 'center',
                    }}
                  >
                    Merci de nous contacter si vous souhaitez changer d’adresse de facturation
                  </Typography>
                )}
              </Card>
            </Grid>
          ))}
          <Grid item lg={3} md={6} xs={12}>
            <Stack direction={"row"} justifyContent={"flex-end"}>
              <IconButton
                onClick={() => setAddDialogOpen(true)}
                sx={{
                  color: '#7C909B',
                  background: '#ffffff',
                  borderRadius: '50%',
                  boxShadow: '12px 12px 24px #00000021',
                  padding: '4px',
                  '& .MuiSvgIcon-root': {
                    width: '2rem',
                    height: '2rem',
                  }
                }}
              >
                <AddIcon/>
              </IconButton>
            </Stack>
          </Grid>
        </Grid>
      )}
      {addDialogOpen && (
        <AddShippingAddressDialog dialogOpen={addDialogOpen} setDialogOpen={setAddDialogOpen}/>
      )}
      {updateDialogOpen && updateShippingAddressSelected && (
        <UpdateShippingAddressDialog shippingAddress={updateShippingAddressSelected} dialogOpen={updateDialogOpen} setDialogOpen={setUpdateDialogOpen}/>
      )}
    </React.Fragment>
  );
};
export default ShippingAddresses;
