import React from "react";
import {useDispatch} from "react-redux";
import {Box, Stack} from "@mui/material";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";

import theme from "../../../assets/styles/theme/theme";
import {shopActions} from "../../../containers/Customer/Shop/store/actions";


const BancontactPaymentMethod = ({ paymentMethod }) => {

  const dispatch = useDispatch();
  const handleCardClick = () => {
    if(paymentMethod?.type === 'MISTERCASH') return;

    dispatch(shopActions.setPaymentMethod({id:"",type:'MISTERCASH'}));
  };
  return (
    <Card sx={{
      boxShadow: (paymentMethod?.type === 'MISTERCASH') ? 'none' : '6px 6px 12px #00000021',
      backgroundColor: (paymentMethod?.type === 'MISTERCASH') ? '#F5F8FA' : '#fff',
      border: (paymentMethod?.type === 'MISTERCASH') ? '1px solid #8CBD23' : 'none',
      borderRadius: '12px',
      '& .MuiSvgIcon-root': {
        fontSize: '2rem',
        color: (paymentMethod?.type === 'MISTERCASH') ? theme.palette.primary.main : '#7C909B',
      },
      '& .MuiTypography-root': {
        fontSize: '0.9rem',
        textAlign: 'center',
        fontFamily: 'Montserrat',
        color: (paymentMethod?.type === 'MISTERCASH') ? theme.palette.primary.main : '#7C909B',
        '& p': {
          textAlign: 'center',
        }
      }
    }}>
      <CardActionArea
        onClick={() => handleCardClick()}>
        <CardContent>
          <Stack gap={1} alignItems={"center"}>
            <Box component={'img'} width={50} src={`${process.env.REACT_APP_API_RESOURCES_URL}/images/paymentMethods/bancontact.svg`} alt="logo" sx={{width: '100%'}}/>
          </Stack>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default BancontactPaymentMethod;