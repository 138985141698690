import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import {makeStyles} from '@mui/styles';
import {DatePicker} from "@mui/x-date-pickers";

import DataGrid from "../../../../components/DataGrid/DataGrid";
import {SnackbarFormattedError} from "../../../../helpers/snackbar";
import useMoment from "../../../../helpers/useMoment";
import {proStatsService} from "../store/services";

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    '& .MuiCardHeader-root': {
      '& .MuiCardHeader-content': {
        height: 64
      },
      '& .MuiInput-root': {
        '& .MuiInputBase-input': {
          textAlign: 'right'
        }
      }
    },
    '& .MuiTableCell-root:first-child': {
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline'
      }
    },
  }
}));

const NetworkPerformancesDataTable = ({user}) => {

  const moment = useMoment();
  const classes = useStyles();
  const {t} = useTranslation();
  const [isYearPickerOpen, setYearPickerOpen] = useState(false);
  const [isMonthPickerOpen, setMonthPickerOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(moment());
  const [data, setData] = useState(null);
  const [yearRange, setYearRange] = useState(false);

  useEffect(() => {
    setData(null);
    proStatsService.getNetworkPerformances(user.id, selectedDate.format('YYYY'), !yearRange && selectedDate.format('MM'))
      .then(users => setData(users.map(data => createData(data))))
      .catch(e => SnackbarFormattedError(e.error));
  }, [selectedDate, yearRange]);

  function createData(data) {

    return {
      ...data,
      name: `${data.firstName} ${data.lastName}`,
      role: `${t(data.role)}`,
    };
  }

  const handleYearChange = (e) => {
    setSelectedDate(e);
    setYearPickerOpen(false);
  };

  const handleDateChange = (e) => {
    setSelectedDate(e);
    setMonthPickerOpen(false);
  };

  const handleChangeRangeSelector = (e) => {
    setYearRange(e.target.checked);
  };

  const columns = [{
    field: "name",
    headerName: "name",
    flex: true,
    tooltip: true
  }, {
    field: "role",
    headerName: "role",
    flex: true,
    tooltip: true
  }];

  return (
    <Card className={classes.root}>
      <CardHeader title={
        <Grid container justify={"space-between"}>
          <Grid item>{t('networkPerformances')}</Grid>
          <Grid item xs={12}>
            <Grid container justify={"flex-end"}>
              <Grid item>
                <FormControlLabel
                  control={<Checkbox
                    checked={yearRange}
                    onChange={handleChangeRangeSelector}
                    name="range"
                    style={{paddingBottom: 0, paddingTop: 0}}
                  />}
                  label={t('byYear')}
                />
              </Grid>
              <Grid item>
                {yearRange && (
                  <DatePicker
                    variant="inline"
                    maxDate={moment()}
                    open={isYearPickerOpen}
                    onOpen={() => setYearPickerOpen(true)}
                    onYearChange={(e) => handleYearChange(e)}
                    openTo="year"
                    views={["year"]}
                    label=""
                    value={selectedDate}
                    onChange={() => {}}
                  />
                )}
                {!yearRange && (
                  <DatePicker
                    variant="inline"
                    maxDate={moment()}
                    open={isMonthPickerOpen}
                    onOpen={() => setMonthPickerOpen(true)}
                    onMonthChange={(e) => handleDateChange(e)}
                    openTo="month"
                    views={["year", "month"]}
                    label=""
                    value={selectedDate}
                    onChange={() => {}}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }/>
      <Divider/>
      <CardContent>
        <DataGrid
          autoHeight
          data={data}
          columns={columns}
          initialPageSize={100}
          toolbarColumns
          toolbarFilter
          toolbarDensity
          toolbarQuickSearch
          toolbarExportCSV
          sx={{'& .MuiDataGrid-row': {cursor: 'pointer'}}}
        />
      </CardContent>
    </Card>
  );
};
export default NetworkPerformancesDataTable;
