import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";

import AutocompletePlaces from "../../../../components/GoogleAutocomplete/AutocompletePlaces";
import FormValidator from "../../../../components/Validators/FormValidator";
import TextFieldValidator from "../../../../components/Validators/TextFieldValidator";
import { SnackbarError } from "../../../../helpers/snackbar";
import { networkActions } from "../store/actions";

const UpdateConsultantShippingAddressCard = ({partner}) => {

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [addressChanged, setAddressChanged] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [data, setData] = useState({
    firstName: partner.shippingAddress.firstName,
    lastName: partner.shippingAddress.lastName,
    shippingAddress: null
  });

  const handleChange = (event) => {

    setData({
      ...data,
      [event.target.name]: event.target.value
    });
  };

  const handleShippingAddressChanged = (place) => {

    setAddressChanged(true);
    setData({
      ...data,
      shippingAddress: place
    });
  };

  const handleSubmit = () => {

    if (addressChanged && typeof data.shippingAddress !== 'object') {
      SnackbarError(t('errorMessageRequiredFields'));
      return false;
    }
    setFormSubmitted(true);
    dispatch(networkActions.updatePartnerShippingAddressRequest(partner, data));
  };

  return (
    <Card>
      <CardContent>
        <FormValidator
          onSubmit={handleSubmit}
          onError={errors => console.log(errors)}
          noValidate
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextFieldValidator
                fullWidth
                name="firstName"
                label={t("firstName")}
                autoComplete="firstName"
                value={data.firstName}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextFieldValidator
                fullWidth
                name="lastName"
                label={t("lastName")}
                autoComplete="lastName"
                value={data.lastName}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <AutocompletePlaces
                fullWidth
                variant="outlined"
                name="address"
                label={t("shippingAddress")}
                initialValue={`${partner.shippingAddress.address} ${partner.shippingAddress.additionalInfo ? `(${partner.shippingAddress.additionalInfo})` : ''}`}
                onPlaceChanged={handleShippingAddressChanged}
                placeholder=""
                preview
                customFieldsEnabled
                requiredFields={['streetNumber', 'streetName', 'zip', 'city', 'country']}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
              >
                <Trans i18nKey={"updateShippingAddress"}>
                                    Update shipping address
                </Trans>
              </Button>
            </Grid>
          </Grid>
        </FormValidator>
      </CardContent>
    </Card>
  );
};
export default UpdateConsultantShippingAddressCard;
