import React from "react";
import {useTranslation} from "react-i18next";
import {Stack} from "@mui/material";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import {styled} from "@mui/material/styles";
import Toolbar from '@mui/material/Toolbar';

import theme from "../../../../../assets/styles/theme/theme";
import CartIcon from "../../../../../containers/Customer/Shop/components/Cart/CartIcon";
import SearchBarButton from "../../../../../containers/Customer/Shop/components/Search/SearchBarButton";
import {Link} from "../../../../../helpers/multilingual";
import FavoriteIcon from "../../FavoriteIcon";
import UserMenu from "../../UserMenu";

const HeaderLG = ({isScrolled}) => {

  const {t} = useTranslation();

  const StyledLink = styled(Link)({
    color: theme.palette.primary.light,
    fontFamily: 'Montserrat, Roboto, open-sans, sans-serif',
    textTransform: 'uppercase',
    fontWeight: 600,
    fontSize: '0.8rem',
    borderBottom: `2px solid ${theme.palette.common.white}`,
    [theme.breakpoints.down('md')]: {
      fontSize: '0.7rem',
    },
    '&:hover': {
      borderBottom: `2px solid ${theme.palette.primary.main}`,
      borderRadius: 0,
      boxShadow: 'none',
      background: theme.palette.common.white,
    },
  });

  //const [scrollPosition, setScrollPosition] = useState(0);
  //const [svgTopPosition, setSvgTopPosition] = useState(64);
  //const handleScroll = () => {
  //  const position = window.scrollY;
  //  setScrollPosition(position);
  //  setSvgTopPosition(70 * (1 - position/110));
  //};
  //
  //useEffect(() => {
  //  window.addEventListener('scroll', handleScroll, { passive: true });
  //
  //  return () => {
  //    window.removeEventListener('scroll', handleScroll);
  //  };
  //}, []);

  return (
    <Box>
      <Toolbar sx={{
        backgroundColor: theme.palette.common.white + ' !important',
        boxShadow: 'none',
        minHeight: '54px',
        height: '54px',
        zIndex: 1200,
      }}>
        <Stack
          direction={"row"}
          alignItems="center"
          alignContent={"center"}
          justifyContent={"space-between"}
          gap={4}
          sx={{
            width: '100%',
          }}>
          <Link to="" sx={{
            textDecoration: 'none',
            '& img': {
              maxWidth: isScrolled ? '224px' : '380px',
              width: isScrolled ? '60px' : '24%',
              height: 'auto',
              position: isScrolled ? 'unset' : 'absolute',
              top: isScrolled ? 'unset' : '62px',
              left: isScrolled ? 'unset' : '13%',
              zIndex: isScrolled ? 0 : 1,
            }
          }}>
            {isScrolled && (
              <img width={60} height={80}
                src={`${process.env.REACT_APP_API_RESOURCES_URL}/images/logo/miniLogo.png`}
                alt="logo"/>
            )}
            {!isScrolled && (
              <img width={414} height={80}
                src={`${process.env.REACT_APP_API_RESOURCES_URL}/images/logo/logo.svg`}
                alt="logo"/>
            )}
          </Link>

          <Stack direction={"row"} gap={2}>
            <Stack
              direction={"row"}
              alignItems={"center"}
              sx={{
                [theme.breakpoints.up('lg')]: {
                  gap: 4,
                },
                [theme.breakpoints.up('md')]: {
                  gap: 3,
                },
                [theme.breakpoints.down('md')]: {
                  gap: 2,
                },
              }}
            >
              <StyledLink to="/shop">
                {t("shop")}
              </StyledLink>
              <StyledLink to="/events">
                {t("events")}
              </StyledLink>
              <StyledLink to="/about">
                {t("aboutUs")}
              </StyledLink>
              <StyledLink to="/health-professional">
                {t("healthPro")}
              </StyledLink>
              <StyledLink to="/mag">
                Blog
              </StyledLink>
            </Stack>
            <Stack direction={"row"} gap={0} alignContent={"center"} alignItems={"center"}>
              <SearchBarButton/>
              <UserMenu/>
              <Divider orientation="vertical" flexItem/>
              <FavoriteIcon/>
              <Divider orientation="vertical" flexItem/>
              <CartIcon/>
            </Stack>
          </Stack>
        </Stack>
      </Toolbar>
      <Box sx={{
        display: isScrolled ? 'none' : 'block',
        background: '#ffffff',
        '& svg': {
          fill: '#ffffff',
          filter: 'drop-shadow(0px 12px 24px #00000026)',
          position: 'absolute',
          top: 63, // {sm: `calc(${svgTopPosition}px - 7px)`, xl: '63px'},
          left: 0,
          //scale: {sm: `1 ${1 - scrollPosition/110}`, xl: '1 1'}
        }
      }}>
        <svg viewBox="0 0 1280 122">
          <g id="a"/>
          <g id="b">
            <g id="c">
              <path id="d" className="e"
                d="M1280,62C1177.28,17.38,1080.17,2,990,2c-126.26,0-243.58,31.7-350,60-106.62,28.36-223.96,60-350,60-90.17,0-187.28-15.38-290-60V0H1280V62Z"/>
            </g>
          </g>
        </svg>
      </Box>
    </Box>
  );
};
export default HeaderLG;
