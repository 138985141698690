import produce from "immer";

import * as actionsType from './constants';

const initialState = {
  consent: true
};

export const cookieConsentReducerKey = 'cookieConsent';

export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {

      case actionsType.CONSENT:
        draft.consent = true;
        break;

      default:

    }
  });
