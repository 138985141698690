import React from "react";
import {Box} from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import NoSsr from "@mui/material/NoSsr";
import Toolbar from "@mui/material/Toolbar";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";

import Header from "../components/Layout/Logistic/Header";
import {initLanguage} from "../helpers/multilingual";
import LogisticRoutes from "../routes/LogisticRoutes";

const drawerWidth = 240;
function LogisticLayout() {

  initLanguage('fr');

  return (
    <NoSsr>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <Header/>

        <Box
          component="main"
          sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
        >
          <Toolbar />
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <LogisticRoutes/>
          </LocalizationProvider>
        </Box>
      </Box>
    </NoSsr>
  );

}

export default LogisticLayout;
