import produce from "immer";

import * as actionsType from './constants';
import { initialState } from "./initialState";

export const magReducerKey = 'mag';

export default (state = initialState.mag, action) =>
  produce(state, draft => {
    switch (action.type) {

      case actionsType.GET_ARTICLE_REQUEST:
        draft.loading = true;
        break;

      case actionsType.GET_ARTICLE_SUCCESS:
        draft.loading = false;
        draft.article = action.article;
        break;

      case actionsType.GET_ARTICLE_FAILED:
        draft.loading = false;
        draft.article = null;
        break;

      case actionsType.GET_ARTICLES_REQUEST:
        draft.loading = true;
        break;

      case actionsType.GET_ARTICLES_SUCCESS:
        draft.loading = false;
        draft.articles = action.articles;
        break;

      case actionsType.GET_ARTICLES_FAILED:
        draft.loading = false;
        draft.articles = [];
        break;

      default:

    }
  });
