export const GET_TREES_COUNT_REQUEST    = 'HOME/GET_TREES_COUNT_REQUEST';
export const GET_TREES_COUNT_SUCCESS    = 'HOME/GET_TREES_COUNT_SUCCESS';

export const GET_TOPBAR_REQUEST    = 'HOME/GET_TOPBAR_REQUEST';
export const GET_TOPBAR_SUCCESS    = 'HOME/GET_TOPBAR_SUCCESS';

export const GET_SLIDERS_REQUEST    = 'HOME/GET_SLIDERS_REQUEST';
export const GET_SLIDERS_SUCCESS    = 'HOME/GET_SLIDERS_SUCCESS';

export const GET_SECTIONS_REQUEST    = 'HOME/GET_SECTIONS_REQUEST';
export const GET_SECTIONS_SUCCESS    = 'HOME/GET_SECTIONS_SUCCESS';
