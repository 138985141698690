import produce from "immer";

import * as actions from './constants';
export const initialState = {
  progression : null,
  certification : {},
  testLoading: false
};

export const careerReducerKey = 'career';

export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case actions.GET_CAREER_PROGRESSION_REQUEST:
        draft.progression = initialState.progression;
        break;
      case actions.GET_CAREER_PROGRESSION_SUCCESS:
        draft.progression = action.data;
        break;
      case actions.GET_CAREER_PROGRESSION_ERROR:
        draft.error = action.error;
        draft.progression = initialState.progression;
        break;
      case actions.GET_CAREER_CERTIFICATION_PROGRESSION_REQUEST:
        draft.progression = null;
        draft.certification[action.id] = null;
        break;
      case actions.GET_CAREER_CERTIFICATION_PROGRESSION_SUCCESS:
        draft.certification[action.id] = action.data;
        break;
      case actions.GET_CAREER_CERTIFICATION_PROGRESSION_ERROR:
        draft.error = action.error;
        draft.certification[action.id] = null;
        break;
      case actions.VALIDATE_CHAPTER_REQUEST:
      case actions.CHOOSE_CAREER_REQUEST:
        break;
      case actions.VALIDATE_CHAPTER_SUCCESS:
      case actions.CHOOSE_CAREER_SUCCESS:
        draft.progression[action.stepKey].chapters[action.chapterKey].done = 1;
        break;
      case actions.VALIDATE_CHAPTER_ERROR:
      case actions.CHOOSE_CAREER_ERROR:
        draft.error = action.error;
        break;
      case actions.VALIDATE_CERTIFICATION_TEST_REQUEST:
        draft.testLoading = false;
        break;
      case actions.VALIDATE_CERTIFICATION_TEST_SUCCESS:
        draft.certification[action.certificationId][action.chapterKey] = action.data;
        draft.testLoading = true;
        break;
      case actions.VALIDATE_CERTIFICATION_TEST_ERROR:
        draft.error = action.error;
        draft.testLoading = false;
        break;
      case actions.VALIDATE_QUALIFICATION_REQUEST:
        break;
      case actions.VALIDATE_QUALIFICATION_SUCCESS:
        draft.progression[action.stepKey].chapters[action.chapterKey].done = 1;
        break;
      case actions.VALIDATE_QUALIFICATION_ERROR:
        draft.error = action.error;
        break;
      default:
    }
  }
  );
