import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {Trans, useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import TuneIcon from '@mui/icons-material/Tune';
import {Box, Hidden, Stack} from "@mui/material";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import useMediaQuery from "@mui/material/useMediaQuery";

import DotsLoader from "../../../components/DotsLoader";
import {Link} from "../../../helpers/multilingual";

import Filters from "./components/Filters";
import Item from "./components/Item";
import ShopTitle from "./components/ShopTitle";
import ShopToolbar from "./components/ShopToolbar";
import {shopActions} from "./store/actions";
import {
  currentPageSelector,
  endOfProductsSelector,
  sessionSelector, shopProductsLoaderSelector, shopProductsSelector
} from "./store/selectors";

const ShopPage = (props) => {

  const dispatch = useDispatch();
  const {i18n} = useTranslation();
  const section = props.match.params.section ? props.match.params.section : 'category';
  const [sectionName, setSectionName] = useState(props.match.params.section ? props.match.params.section : 'category');
  const sectionId = props.match.params.sectionId ? props.match.params.sectionId : 'all';
  const [filter, setFilter] = useState('nameAsc');
  const [view, setView] = useState('grid');
  const [open, setOpen] = useState(false);
  const isDownSm = useMediaQuery(theme => theme.breakpoints.down("sm"));
  const session = useSelector(sessionSelector);
  const loader = useSelector(shopProductsLoaderSelector);
  const products = useSelector(shopProductsSelector('product', section, sectionId, (session && session.country) || 'LU', i18n.language));
  const endOfProducts = useSelector(endOfProductsSelector('product', section, sectionId, (session && session.country) || 'LU', i18n.language));
  const currentPage = useSelector(currentPageSelector('product', section, sectionId, (session && session.country) || 'LU', i18n.language));
  const hasMore = !endOfProducts;
  const observer = useRef(null);

  useEffect(() => {
    (async () => {
      dispatch(shopActions.getCategoriesRequest(i18n.language));
      dispatch(shopActions.getThemesRequest(i18n.language));
      dispatch(shopActions.getBrandsRequest());
    })();
  }, [i18n.language]);

  useEffect(() => {
    getProducts();
  }, [i18n.language, section, sectionId, currentPage]);

  const getProducts = () => {
    dispatch(
      shopActions.getProductsRequest(
        i18n.language,
            session?.country || "LU",
            section,
            sectionId,
            currentPage,
            20
      )
    );
  };

  const lastProductElementRef = useCallback(
    (node) => {
      if (loader) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting && hasMore && section !== 'favorites') {
            dispatch(shopActions.setCurrentPage(
              'product',
              i18n.language,
                session?.country || "LU",
                section,
                sectionId,
                currentPage + 1
            ));
          }
        },
        { threshold: 0.6 }
      );
      if (node) observer.current.observe(node);
    },
    [products]
  );

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const productFiltered = useMemo(() => {
    if(products?.length > 0) switch (filter) {
      case 'nameAsc': return products?.sort((a, b) => a.reference.localeCompare(b.reference));
      case 'priceAsc': return products?.sort((a, b) => a.price - b.price);
      case 'priceDesc': return products?.sort((a, b) => b.price - a.price);
      default: return products;
    }
  }, [products, filter]);

  return (
    <Box sx={{
      paddingBottom: 40,
      paddingTop: {xs: '0', sm: '11vw'},
    }}
    >
      <ShopTitle/>
      {isDownSm && (
        <Box
          sx={{
            padding: '8px 16px',
            boxShadow: '0px 12px 23px #00000021',
            position: 'sticky',
            top: 0,
            left: 0,
            zIndex: 1200,
            background: '#fff',
          }}
        >
          <Stack direction={"row"} gap={2} justifyContent={"space-between"}>
            <Button
              key={'allCategory'}
              variant={"outlined"}
              component={Link}
              to={`/shop`}
              sx={{
                borderRadius: '12px',
                height: '24px',
                width: 'auto',
                fontFamily: 'Montserrat',
                fontSize: '12px',
                border: !sectionId ? '1px solid #7C909B' : '1px solid #FFFFFF',
                backgroundColor: !sectionId ? '#FFFFFF' : '#EEF1F2',
                color: !sectionId ? '#8CBD23' : '#3C515E'
              }}
            >
              <Trans i18nKey={"allProducts"}>All products</Trans>
            </Button>
            <Button
              onClick={toggleDrawer}
              startIcon={<TuneIcon/>}
              sx={{
                borderRadius: '30px',
                height: '28px',
                padding: '6px 14px',
                width: 'auto',
                fontFamily: 'Montserrat',
                fontSize: '12px',
                border: '1px solid #FFFFFF',
                backgroundColor: '#EEF1F2',
                color: '#3C515E'
              }}
            >
              {'Filtres'}
            </Button>
          </Stack>
        </Box>
      )}

      <Container fixed maxWidth={'xxl'}>
        <Hidden smDown implementation={"css"}>
          <ShopToolbar sectionName={sectionName} setSectionName={setSectionName} numbers={products?.length || 0} setView={setView} setFilter={setFilter}/>
        </Hidden>
        <Grid container spacing={2}>
          <Grid item sm={3}>
            <Filters sectionName={sectionName} open={open} onToggleDrawer={toggleDrawer}/>
          </Grid>
          <Grid item sm={9}>
            <Grid container spacing={2}>
              {products && productFiltered && productFiltered?.length > 0 && (
                productFiltered?.map((product, index) => {
                  if(index === product.length - 1) {
                    return (<Grid
                      item xs={12}
                      sm={view === 'grid' ? 6 : 12}
                      md={view === 'grid' ? 6 : 12}
                      lg={view === 'grid' ? 4 : 12}
                      xl={view === 'grid' ? 4 : 12}
                      xxl={view === 'grid' ? 3 : 12}
                      key={product.id}
                    >
                      <Item product={product} session={session}/>
                    </Grid>);
                  } else {
                    return (<Grid
                      item xs={12}
                      sm={view === 'grid' ? 6 : 12}
                      md={view === 'grid' ? 6 : 12}
                      lg={view === 'grid' ? 4 : 12}
                      xl={view === 'grid' ? 4 : 12}
                      xxl={view === 'grid' ? 3 : 12}
                      key={product.id}
                      ref={lastProductElementRef}
                    >
                      <Item product={product} session={session}/>
                    </Grid>);
                  }
                })
              )}
              <Grid item xs={12}>
                {loader  && <DotsLoader/>}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>

    </Box>
  );
};

export default ShopPage;

/*
import React, { useState, useEffect, useRef } from 'react';

function ProductList() {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const observer = useRef(null);

  useEffect(() => {
    // Initial fetch of products
    fetchProducts(currentPage);

    // Set up IntersectionObserver
    observer.current = new IntersectionObserver(
      (entries) => {
        const firstEntry = entries[0];
        if (firstEntry.isIntersecting && hasMore) {
          // Fetch more products
          fetchProducts(currentPage + 1);
        }
      },
      { threshold: 1 }
    );

    // Clean up IntersectionObserver
    return () => {
      observer.current.disconnect();
    };
  }, []);

  const fetchProducts = async (page) => {
    setLoading(true);
    const newProducts = await fetchingFunction(page);

    if (newProducts.length === 0) {
      setHasMore(false);
    } else {
      setProducts((prevProducts) => [...prevProducts, ...newProducts]);
      setCurrentPage(page);
    }

    setLoading(false);
  };

  return (
    <div>
      {products.map((product, index) => (
        <div key={index}>
          <h3>{product.name}</h3>
          <p>{product.description}</p>
        </div>
      ))}
      {loading && <p>Loading...</p>}
      {!loading && hasMore && (
        <div ref={observer}>
          <p>Scroll down to load more products</p>
        </div>
      )}
      {!loading && !hasMore && (
        <p>End of products list</p>
      )}
    </div>
  );
}

export default ProductList;

 */