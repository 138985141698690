import axios  from '../../../../helpers/axios';

export const customersService = {
  getCustomers,
  getCustomer,
  searchCustomers,
  addCustomer,
  updateCustomer,
  addCustomerShippingAddress,
  updateCustomerShippingAddress,
  removeCustomerShippingAddress,
  updateCustomerArchived,
  getCustomerOrders,
  addCustomerOrder,
  getCustomerCheckout,
  deleteRecommendation,
  getCustomerPickupPoints,
  getCustomerShippingAddresses,
  getCustomerNotes,
  addCustomerNote,
  removeCustomerNote,
  getCustomerAnamneses,
  sendOrderPaymentEmail,
  sendAnamnesisRequestEmail,
  sendSimplifiedAnamnesisRequestEmail,
  getSimplifiedAnamnesis,
  getAnamnesis,
  addAnamnesisNote,
  removeAnamnesisNote,
  requestSupport,
  certifiedConsultantRedirectionRequest,
  customerRedirection,
  importCustomer,
  getGiftCards,
  convertGiftCard,
  getCustomerGiftCards,
  sendCustomerGiftCard,
  dissociateGiftCard,
  deleteGiftCard,
  getCustomerRedirections,
  getCustomerRedirection,
};

function getCustomers() {

  return new Promise((resolve, reject) => {

    axios.get(`/pro/customers`)
      .then(({customers}) => {
        resolve(customers);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getCustomer(customerId) {

  return new Promise((resolve, reject) => {

    axios.get(`/pro/customer/${customerId}`)
      .then(({customer}) => {
        console.log(customer);
        resolve(customer);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function searchCustomers(search) {

  return new Promise((resolve, reject) => {

    axios.get(`/pro/customers/search/${search}`)
      .then(({customers}) => {
        resolve(customers);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function addCustomer(data) {

  return new Promise((resolve, reject) => {

    axios.post(`/pro/customer/add`, {data})
      .then(({customer}) => {
        resolve(customer);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function updateCustomer(customerId, data) {

  return new Promise((resolve, reject) => {

    axios.patch(`/user/customer/${customerId}`, {data})
      .then(({customer}) => {
        resolve(customer);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function addCustomerShippingAddress(customerId, data) {

  return new Promise((resolve, reject) => {

    axios.post(`/user/customer/${customerId}/shippingAddress`, {data})
      .then(({shippingAddress}) => {
        resolve(shippingAddress);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function updateCustomerShippingAddress(customerId, addressId, data) {

  return new Promise((resolve, reject) => {

    axios.patch(`/user/customer/${customerId}/shippingAddress`, {addressId, data})
      .then(({shippingAddress}) => {
        resolve(shippingAddress);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function removeCustomerShippingAddress(customerId, shippingAddressId) {

  return new Promise((resolve, reject) => {

    axios.delete(`/user/customer/${customerId}/shippingAddress/${shippingAddressId}`)
      .then(({shippingAddress}) => {
        resolve(shippingAddress);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function updateCustomerArchived(customerId, archived) {

  return new Promise((resolve, reject) => {

    axios.patch(`/user/customer/${customerId}/archived`, {archived})
      .then(() => {
        resolve();
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getCustomerOrders(customerId) {

  return new Promise((resolve, reject) => {

    axios.get(`/pro/customer/${customerId}/orders`)
      .then(({orders}) => {
        resolve(orders);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function addCustomerOrder(customerId, products) {

  return new Promise((resolve, reject) => {

    axios.post(`/pro/customer/${customerId}/order`, {products})
      .then(({order}) => {
        resolve(order);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getCustomerCheckout(customerId, shippingMethodId, shippingAddressId, shippingPickupPoint, items) {

  return new Promise((resolve, reject) => {

    axios.post(`/pro/customer/${customerId}/checkout`, {
      customerId,
      shippingMethodId,
      shippingAddressId,
      shippingPickupPoint,
      items,
    })
      .then(({checkout}) => {
        resolve(checkout);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function deleteRecommendation(orderId) {

  return new Promise((resolve, reject) => {

    axios.delete(`/pro/customer/order/${orderId}`)
      .then(() => {
        resolve();
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getCustomerShippingAddresses(customerId) {

  return new Promise((resolve, reject) => {

    axios.get(`/pro/customer/${customerId}/shippingAddresses`)
      .then(({shippingAddresses}) => {
        resolve(shippingAddresses);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getCustomerPickupPoints(customerId, shippingAddressId) {

  return new Promise((resolve, reject) => {

    axios.get(`/pro/customer/${customerId}/pickupPoints/${shippingAddressId}`)
      .then(({pickupPoints}) => {
        resolve(pickupPoints);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getCustomerNotes(customerId) {

  return new Promise((resolve, reject) => {

    axios.get(`/user/customer/${customerId}/notes`)
      .then(({notes}) => {
        resolve(notes);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function addCustomerNote(customerId, note) {

  return new Promise((resolve, reject) => {

    axios.post(`/user/customer/${customerId}/note`, {note})
      .then(() => {
        resolve();
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function removeCustomerNote(customerId, noteId) {

  return new Promise((resolve, reject) => {

    axios.delete(`/user/customer/${customerId}/note/${noteId}`)
      .then(() => {
        resolve();
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getCustomerAnamneses(customerId) {

  return new Promise((resolve, reject) => {

    axios.get(`/anamnesis/user/${customerId}/simplified/list`)
      .then(({anamneses}) => {
        resolve(anamneses);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function sendOrderPaymentEmail(orderId) {

  return new Promise((resolve, reject) => {

    axios.get(`/pro/customer/order/${orderId}/send`)
      .then(() => {
        resolve();
      })
      .catch((e) => {
        reject(e);
      });
  });
}

function sendAnamnesisRequestEmail(customerId) {

  return new Promise((resolve, reject) => {

    axios.get(`/anamnesis/customer/${customerId}/request`)
      .then(() => {
        resolve();
      })
      .catch((e) => {
        reject(e);
      });
  });
}

function sendSimplifiedAnamnesisRequestEmail(userId) {

  return new Promise((resolve, reject) => {

    axios.get(`/anamnesis/user/${userId}/simplified/request`)
      .then(() => {
        resolve();
      })
      .catch((e) => {
        reject(e);
      });
  });
}

function getAnamnesis(customerId) {

  return new Promise((resolve, reject) => {

    axios.get(`/anamnesis/customer/${customerId}`)
      .then(({formData, formValues, notes, support, uploadedFiles}) => {
        resolve({formData, formValues, notes, support, uploadedFiles});
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getSimplifiedAnamnesis(userId, anamnesisId) {

  return new Promise((resolve, reject) => {

    axios.get(`/anamnesis/user/${userId}/simplified/${anamnesisId}`)
      .then(({anamnesis, form, results, files, answers}) => {
        resolve({anamnesis, form, results, files, answers});
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function addAnamnesisNote(customerId, note) {

  return new Promise((resolve, reject) => {

    axios.post(`/anamnesis/customer/${customerId}/note`, note)
      .then(({note}) => {
        resolve(note);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function removeAnamnesisNote(customerId, note) {

  return new Promise((resolve, reject) => {

    axios.delete(`/anamnesis/customer/${customerId}/note/${note.id}`)
      .then(({note}) => {
        resolve(note);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function requestSupport(customerId) {

  return new Promise((resolve, reject) => {

    axios.get(`/anamnesis/customer/${customerId}/support/request`)
      .then(() => {
        resolve();
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function certifiedConsultantRedirectionRequest(consultantId, customerId, message) {

  return new Promise((resolve, reject) => {

    axios.post(`/pro/customer/${customerId}/redirection/consultant/${consultantId}/request`, {message})
      .then(() => {
        resolve();
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function customerRedirection(customerId, decision) {

  return new Promise((resolve, reject) => {

    axios.post(`/pro/customer/${customerId}/redirection/${decision}`)
      .then(({request}) => {
        resolve(request);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getCustomerRedirections() {

  return new Promise((resolve, reject) => {

    axios.get(`/pro/customers/redirections/requests`)
      .then(({requests}) => {
        resolve(requests);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getCustomerRedirection(customerId) {

  return new Promise((resolve, reject) => {

    axios.get(`/pro/customer/${customerId}/redirection/request`)
      .then(({request}) => {
        resolve(request);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function importCustomer(customerId) {

  return new Promise((resolve, reject) => {

    axios.get(`/user/customer/${customerId}/import`)
      .then(({customer}) => {
        resolve(customer);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getGiftCards(usedGiftCardView) {

  return new Promise((resolve, reject) => {

    axios.get(`/pro/gifts${usedGiftCardView ? '/used' : ''}`)
      .then(({giftCards}) => {
        resolve(giftCards);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function convertGiftCard(amount) {

  return new Promise((resolve, reject) => {

    axios.post(`/pro/gift/convert/${amount}`)
      .then(({giftCard}) => {
        resolve(giftCard);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getCustomerGiftCards(customerId) {

  return new Promise((resolve, reject) => {

    axios.get(`/user/customer/${customerId}/gifts`)
      .then(({giftCards}) => {
        resolve(giftCards);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function sendCustomerGiftCard(customerId, giftId) {

  return new Promise((resolve, reject) => {

    axios.post(`/user/customer/${customerId}/gift/${giftId}`)
      .then(() => {
        resolve();
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function dissociateGiftCard(giftId) {

  return new Promise((resolve, reject) => {

    axios.post(`/admin/user/gift/dissociate`, {giftCardId: giftId})
      .then(() => {
        resolve();
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function deleteGiftCard(giftId) {

  return new Promise((resolve, reject) => {

    axios.delete(`/admin/user/${giftId}`)
      .then(() => {
        resolve();
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}
