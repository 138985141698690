import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import FmdGoodSharpIcon from '@mui/icons-material/FmdGoodSharp';
import HomeWorkOutlinedIcon from '@mui/icons-material/HomeWorkOutlined';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import {Stack} from "@mui/material";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import theme from "../../../../../assets/styles/theme/theme";
import PageLoader from "../../../../../components/PageLoader";
import {shopActions} from "../../store/actions";
import {
  bpostShippingMethodsSelector,
  dhlShippingMethodsSelector, fedexPriorityShippingMethodsSelector,
  fedexStandardShippingMethodsSelector,
  shippingAddressSelectedSelector,
  shippingMethodSelectedSelector,
  shippingMethodsSelector,
  shippingPickupPointSelectedSelector,
} from "../../store/selectors";
import {shopService} from "../../store/services";

import SelectPickupPointModal from "./SelectPickupPointModal";

const ShippingMethodSelector = () => {

  const {i18n} = useTranslation();
  const dispatch = useDispatch();
  const [pickupPointModalOpen, setPickupPointModalOpen] = useState(false);
  const [pickupPointIdSelection, setPickupPointIdSelection] = useState(null);
  const methodSelected = useSelector(shippingMethodSelectedSelector);
  const shippingAddress = useSelector(shippingAddressSelectedSelector);
  const shippingPickupPoint = useSelector(shippingPickupPointSelectedSelector);
  const shippingMethods = useSelector(shippingMethodsSelector);
  const bpost = useSelector(bpostShippingMethodsSelector);
  const dhl = useSelector(dhlShippingMethodsSelector);
  const fedexPriority = useSelector(fedexPriorityShippingMethodsSelector);
  const fedexStandard = useSelector(fedexStandardShippingMethodsSelector);
  const [type, setType] = useState(0);

  const handleCardClick = (id) => {

    const shippingMethod = shippingMethods.find(method => method.id === id);

    if (shippingMethod?.pickupPoint || shippingMethod?.packetDelivery) {
      setType(shippingMethod.pickupPoint ? 3 : 4);
      setPickupPointIdSelection(shippingMethod);
      setPickupPointModalOpen(true);
    } else {
      dispatch(shopActions.selectShippingMethod(shippingMethod));
      shopService.setCartLog("Le client choisis sa méthode de livraison",{from:"checkoutDrawer : shippingMethodSelector", methode: shippingMethod?.nameFR}).then().catch();
    }
  };

  const handleSelectPickupPoint = (location) => {

    dispatch(shopActions.selectShippingPickupPointMethod(pickupPointIdSelection, {
      ...location,
      country: shippingAddress?.country
    }));
    setPickupPointIdSelection(null);
    setPickupPointModalOpen(false);
    shopService.setCartLog("Le client a choisi son point relais",{from:"checkoutDrawer : shippingMethodSelector", relais: location?.name}).then().catch();
  };

  const handleCloseModal = () => {
    if (!shippingPickupPoint) {
      dispatch(shopActions.selectShippingMethod({id: null}));
      shopService.setCartLog("Le client ferme le popup des points relais sans en avoir choisis un",{from:"checkoutDrawer : shippingMethodSelector"}).then().catch();
    }
    setPickupPointModalOpen(false);
  };

  if (shippingMethods.length === 0) {
    return (
      <Grid container justify={"center"} spacing={4}>
        <PageLoader height={150}/>
      </Grid>
    );
  }

  return (
    <Grid container spacing={1} sx={{
      pl: 1,
      pr: 0,
      '& .MuiSvgIcon-root': {
        fontSize: '2rem',
        color: '#7C909B',
      },
      '& .MuiTypography-root': {
        fontSize: '0.9rem',
        color: '#7C909B',
      }
    }}>
      {bpost?.length > 0 && (
        <>
          <Grid item xs={12} sx={{
            '& img': {
              width: '120px',
            }
          }}>
            <img src={`${process.env.REACT_APP_API_RESOURCES_URL}/images/bpost-logo.png`} alt="bpost"/>
          </Grid>
          {bpost?.find(method => method.atHome === 1) && (
            <Grid item xs={6}>
              <Card sx={{
                boxShadow: (methodSelected?.atHome === 1 && methodSelected?.carrier === 'bpost') ? 'none' : '6px 6px 12px #00000021',
                backgroundColor: (methodSelected?.atHome === 1 && methodSelected?.carrier === 'bpost') ? '#F5F8FA' : '#fff',
                border: (methodSelected?.atHome === 1 && methodSelected?.carrier === 'bpost') ? '1px solid #8CBD23' : 'none',
                borderRadius: '12px',
                '& .MuiSvgIcon-root': {
                  fontSize: '2rem',
                  color: (methodSelected?.atHome === 1 && methodSelected?.carrier === 'bpost') ? theme.palette.primary.main : '#7C909B',
                },
                '& .MuiTypography-root': {
                  fontSize: '0.9rem',
                  textAlign: 'center',
                  fontFamily: 'Montserrat',
                  color: (methodSelected?.atHome === 1 && methodSelected?.carrier === 'bpost') ? theme.palette.primary.main : '#7C909B',
                  '& p': {
                    textAlign: 'center',
                  }
                }
              }}>
                <CardActionArea
                  onClick={() => handleCardClick(bpost.find(method => method.atHome === 1).id)}>
                  <CardContent>
                    <Stack gap={1} alignItems={"center"}>
                      <FmdGoodSharpIcon/>
                      <Typography>
                        {bpost?.find(method => method.atHome === 1)[`name${i18n.language.toUpperCase()}`]}
                      </Typography>
                    </Stack>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          )}
          {bpost?.find(method => method.pickupPoint === 1) && (
            <Grid item xs={6}>
              <Card sx={{
                boxShadow: (methodSelected?.pickupPoint === 1 && methodSelected?.carrier === 'bpost') ? 'none' : '6px 6px 12px #00000021',
                backgroundColor: (methodSelected?.pickupPoint === 1 && methodSelected?.carrier === 'bpost') ? '#F5F8FA' : '#fff',
                border: (methodSelected?.pickupPoint === 1 && methodSelected?.carrier === 'bpost') ? '1px solid #8CBD23' : 'none',
                borderRadius: '12px',
                '& .MuiSvgIcon-root': {
                  fontSize: '2rem',
                  color: (methodSelected?.pickupPoint === 1 && methodSelected?.carrier === 'bpost') ? theme.palette.primary.main : '#7C909B',
                },
                '& .MuiTypography-root': {
                  fontSize: '0.9rem',
                  textAlign: 'center',
                  fontFamily: 'Montserrat',
                  color: (methodSelected?.pickupPoint === 1 && methodSelected?.carrier === 'bpost') ? theme.palette.primary.main : '#7C909B',
                  '& p': {
                    textAlign: 'center',
                  }
                }
              }}>
                <CardActionArea
                  onClick={() => handleCardClick(bpost.find(method => method.pickupPoint === 1).id)}>
                  <CardContent>
                    <Stack gap={1} alignItems={"center"}>
                      <StorefrontOutlinedIcon/>

                      <Typography>
                        {bpost?.find(method => method.pickupPoint === 1)[`name${i18n.language.toUpperCase()}`]}
                      </Typography>
                    </Stack>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          )}
          {bpost?.find(method => method.packetDelivery === 1) && (
            <Grid item xs={6}>
              <Card sx={{
                boxShadow: (methodSelected?.packetDelivery === 1 && methodSelected?.carrier === 'bpost') ? 'none' : '6px 6px 12px #00000021',
                backgroundColor: (methodSelected?.packetDelivery === 1 && methodSelected?.carrier === 'bpost') ? '#F5F8FA' : '#fff',
                border: (methodSelected?.packetDelivery === 1 && methodSelected?.carrier === 'bpost') ? '1px solid #8CBD23' : 'none',
                borderRadius: '12px',
                '& .MuiSvgIcon-root': {
                  fontSize: '2rem',
                  color: (methodSelected?.packetDelivery === 1 && methodSelected?.carrier === 'bpost') ? theme.palette.primary.main : '#7C909B',
                },
                '& .MuiTypography-root': {
                  fontSize: '0.9rem',
                  textAlign: 'center',
                  fontFamily: 'Montserrat',
                  color: (methodSelected?.packetDelivery === 1 && methodSelected?.carrier === 'bpost') ? theme.palette.primary.main : '#7C909B',
                  '& p': {
                    textAlign: 'center',
                  }
                }
              }}>
                <CardActionArea
                  onClick={() => handleCardClick(bpost.find(method => method.packetDelivery === 1).id)}>
                  <CardContent>
                    <Stack gap={1} alignItems={"center"}>
                      <HomeWorkOutlinedIcon/>
                      <Typography>
                        {bpost?.find(method => method.packetDelivery === 1)[`name${i18n.language.toUpperCase()}`]}
                      </Typography>
                    </Stack>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          )}
        </>
      )}
      {dhl?.length > 0 && (
        <>
          <Grid item xs={12} sx={{
            mt: '16px',
            '& img': {
              width: '120px',
            }
          }}>
            <img src={`${process.env.REACT_APP_API_RESOURCES_URL}/images/dhl-logo.png`} alt="DHL"/>
          </Grid>
          {dhl?.find(method => method.atHome === 1) && (
            <Grid item xs={6}>
              <Card sx={{
                boxShadow: (methodSelected?.atHome === 1 && methodSelected?.carrier === 'DHL Express') ? 'none' : '6px 6px 12px #00000021',
                backgroundColor: (methodSelected?.atHome === 1 && methodSelected?.carrier === 'DHL Express') ? '#F5F8FA' : '#fff',
                border: (methodSelected?.atHome === 1 && methodSelected?.carrier === 'DHL Express') ? '1px solid #8CBD23' : 'none',
                borderRadius: '12px',
                '& .MuiSvgIcon-root': {
                  fontSize: '2rem',
                  color: (methodSelected?.atHome === 1 && methodSelected?.carrier === 'DHL Express') ? theme.palette.primary.main : '#7C909B',
                },
                '& .MuiTypography-root': {
                  fontSize: '0.9rem',
                  textAlign: 'center',
                  fontFamily: 'Montserrat',
                  color: (methodSelected?.atHome === 1 && methodSelected?.carrier === 'DHL Express') ? theme.palette.primary.main : '#7C909B',
                  '& p': {
                    textAlign: 'center',
                  }
                }
              }}>
                <CardActionArea
                  onClick={() => handleCardClick(dhl.find(method => method.atHome === 1).id)}>
                  <CardContent>
                    <Stack gap={1} alignItems={"center"}>
                      <FmdGoodSharpIcon/>

                      <Typography>
                        {dhl?.find(method => method.atHome === 1)[`name${i18n.language.toUpperCase()}`]}
                      </Typography>
                    </Stack>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          )}
        </>
      )}
      {fedexStandard?.length > 0 && (
        <>
          <Grid item xs={12} sx={{
            mt: '16px',
            '& img': {
              width: '120px',
            }
          }}>
            <img src={`${process.env.REACT_APP_API_RESOURCES_URL}/images/fedex-logo.png`} alt="FedEx"/>
          </Grid>
          {fedexStandard?.find(method => method.atHome === 1) && (
            <Grid item xs={6}>
              <Card sx={{
                boxShadow: (methodSelected?.atHome === 1 && methodSelected?.carrier === 'FedEx - Standard') ? 'none' : '6px 6px 12px #00000021',
                backgroundColor: (methodSelected?.atHome === 1 && methodSelected?.carrier === 'FedEx - Standard') ? '#F5F8FA' : '#fff',
                border: (methodSelected?.atHome === 1 && methodSelected?.carrier === 'FedEx - Standard') ? '1px solid #8CBD23' : 'none',
                borderRadius: '12px',
                '& .MuiSvgIcon-root': {
                  fontSize: '2rem',
                  color: (methodSelected?.atHome === 1 && methodSelected?.carrier === 'FedEx - Standard') ? theme.palette.primary.main : '#7C909B',
                },
                '& .MuiTypography-root': {
                  fontSize: '0.9rem',
                  textAlign: 'center',
                  fontFamily: 'Montserrat',
                  color: (methodSelected?.atHome === 1 && methodSelected?.carrier === 'FedEx - Standard') ? theme.palette.primary.main : '#7C909B',
                  '& p': {
                    textAlign: 'center',
                  }
                }
              }}>
                <CardActionArea
                  onClick={() => handleCardClick(fedexStandard.find(method => method.atHome === 1).id)}>
                  <CardContent>
                    <Stack gap={1} alignItems={"center"}>
                      <FmdGoodSharpIcon/>

                      <Typography>
                        {fedexStandard?.find(method => method.atHome === 1)[`name${i18n.language.toUpperCase()}`]}
                      </Typography>
                    </Stack>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          )}
        </>
      )}
      {fedexPriority?.length > 0 && (
        <>
          <Grid item xs={12} sx={{
            mt: '16px',
            '& img': {
              width: '120px',
            }
          }}>
            <img src={`${process.env.REACT_APP_API_RESOURCES_URL}/images/fedex-logo.png`} alt="FedEx"/>
          </Grid>
          {fedexPriority?.find(method => method.atHome === 1) && (
            <Grid item xs={6}>
              <Card sx={{
                boxShadow: (methodSelected?.atHome === 1 && methodSelected?.carrier === 'FedEx - Priority') ? 'none' : '6px 6px 12px #00000021',
                backgroundColor: (methodSelected?.atHome === 1 && methodSelected?.carrier === 'FedEx - Priority') ? '#F5F8FA' : '#fff',
                border: (methodSelected?.atHome === 1 && methodSelected?.carrier === 'FedEx - Priority') ? '1px solid #8CBD23' : 'none',
                borderRadius: '12px',
                '& .MuiSvgIcon-root': {
                  fontSize: '2rem',
                  color: (methodSelected?.atHome === 1 && methodSelected?.carrier === 'FedEx - Priority') ? theme.palette.primary.main : '#7C909B',
                },
                '& .MuiTypography-root': {
                  fontSize: '0.9rem',
                  textAlign: 'center',
                  fontFamily: 'Montserrat',
                  color: (methodSelected?.atHome === 1 && methodSelected?.carrier === 'FedEx - Priority') ? theme.palette.primary.main : '#7C909B',
                  '& p': {
                    textAlign: 'center',
                  }
                }
              }}>
                <CardActionArea
                  onClick={() => handleCardClick(fedexPriority.find(method => method.atHome === 1).id)}>
                  <CardContent>
                    <Stack gap={1} alignItems={"center"}>
                      <FmdGoodSharpIcon/>

                      <Typography>
                        {fedexPriority?.find(method => method.atHome === 1)[`name${i18n.language.toUpperCase()}`]}
                      </Typography>
                    </Stack>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          )}
        </>
      )}

      {pickupPointModalOpen && (
        <SelectPickupPointModal modalOpen={pickupPointModalOpen} handleCloseModal={handleCloseModal}
          onSelect={handleSelectPickupPoint} type={type}/>
      )}
    </Grid>
  );
};

export default ShippingMethodSelector;