import React, {useEffect, useState} from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import {
  DialogContentText,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip
} from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormGroup from "@mui/material/FormGroup";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Typography from "@mui/material/Typography";

import {shopService} from "../containers/Customer/Shop/store/services";
import {SnackbarSuccess} from "../helpers/snackbar";

const PaymentMethodsList = ({paymentMethods, setPaymentMethod, paymentMethod}) => {

  const [paymentMethodDeleteDialogOpen, setPaymentMethodDeleteDialogOpen] = useState(false);
  const [paymentMethodDialogValue, setPaymentMethodDialogValue] = useState({});
  const [paymentMethodsState, setPaymentMethodsState] = useState([]);

  useEffect(() => {
    let formatedValues = [];
    Object.keys(paymentMethods).map(paymentMethod => {
      paymentMethods[paymentMethod].map(method => {
        formatedValues.push({
          ...method,
          last4: method[paymentMethod].last4
        });
      });
    });
    setPaymentMethodsState(formatedValues);
  }, [paymentMethods]);

  const handleToggle = (value) => () => {
    setPaymentMethod({id:value?.id, type: value?.type});
  };

  const handleClosePaymentMethodDeleteValidationDialog = () => {
    setPaymentMethodDeleteDialogOpen(false);
    setPaymentMethodDialogValue({});
    setPaymentMethod({id: "", type: ""});
  };

  const handleOpenPaymentMethodDeleteValidationDialog = (value, i) => {
    setPaymentMethodDeleteDialogOpen(true);
    setPaymentMethodDialogValue({...value, index: i});
    setPaymentMethod({id: "", type: ""});
  };

  const handlePaymentMethodDelete = async () => {
    await shopService.deletePaymentMethod(paymentMethodDialogValue.id);
    //Updating the paymentMethods Array
    let newPaymentMethodsArray = [...paymentMethodsState];
    newPaymentMethodsArray.splice(paymentMethodDialogValue.index, 1);
    setPaymentMethodsState(newPaymentMethodsArray);

    switch (paymentMethodDialogValue.type) {
      case "sepa_debit":
      case "card":
      case "bancontact":
        SnackbarSuccess(`Le moyen de paiment se terminant par ${paymentMethodDialogValue?.last4} a été supprimé`);
        break;
      default:
        SnackbarSuccess(`Le moyen de paiment a été supprimé`);

    }
    handleClosePaymentMethodDeleteValidationDialog();
  };

  return (
    <>
      <List>
        <FormGroup>
          <RadioGroup value={paymentMethod?.id}>
            {paymentMethodsState?.map((value, i) => (
              <ListItemButton  key={value.id} role={undefined} onClick={handleToggle(value)}>
                <Radio value={value.id}/>
                <ListItemIcon>
                  <Typography>{value?.type.toUpperCase()} </Typography>
                </ListItemIcon>
                <ListItemText id={`itemText-${value.id}`} primary={`**** **** **** ${value?.last4}`}/>
                <ListItemSecondaryAction button
                  onClick={() => handleOpenPaymentMethodDeleteValidationDialog(value, i)}>
                  <Tooltip title={"Supprimer la carte"}>
                    <IconButton edge="end" aria-label="delete">
                      <DeleteIcon/>
                    </IconButton>
                  </Tooltip>
                </ListItemSecondaryAction>
              </ListItemButton>
            ))}
          </RadioGroup>
        </FormGroup>
      </List>
      <Dialog
        open={paymentMethodDeleteDialogOpen}
        onClose={handleClosePaymentMethodDeleteValidationDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title">{`Vous êtes sur le point de supprimer la carte **** **** **** ${paymentMethodDialogValue?.last4}`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
                        En cliquant sur confirmer, la carte sera supprimée de votre compte.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePaymentMethodDeleteValidationDialog} color="primary">
                        Annuler
          </Button>
          <Button onClick={() => handlePaymentMethodDelete()} color="primary" autoFocus>
                        Confirmer
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PaymentMethodsList;