import React from "react";
import { Trans } from "react-i18next";
import Typography from "@mui/material/Typography";

const OrderStatus = ({order}) => {

  if(order.refunded && order.status === 'published') {
    return (
      <Typography component={"i"}><Trans i18nKey={"orderRefunded"}>Order refunded</Trans></Typography>
    );
  }
  if(order.refunded) {
    return (
      <Typography component={"i"}><Trans i18nKey={"orderCanceled"}>Order canceled</Trans></Typography>
    );
  }
  if(order.status === 'deferred') {
    return (
      <Typography component={"i"}><Trans i18nKey={"delayedSending"}>Delayed sending</Trans></Typography>
    );
  }
  if(order.status === 'gotBack') {
    return (
      <Typography component={"i"}><Trans i18nKey={"gotBackToSender"}>Got back to sender</Trans></Typography>
    );
  }
  if(order.status === 'recommendation') {
    return (
      <Typography color={"secondary"} component={"i"}><Trans i18nKey={"recommendationOrder"}>Recommendation order</Trans></Typography>
    );
  }
  switch (order.shippingStatus) {
    case 'waiting':
      return (
        <Typography component={"i"}><Trans i18nKey={"orderAwaitingPreparation"}>Order awaiting preparation</Trans></Typography>
      );
    case 'processing':
      return (
        <Typography component={"i"}><Trans i18nKey={"orderPreparedAwaitingDelivery"}>Order prepared awaiting delivery</Trans></Typography>
      );
    case 'inTransit':
      return (
        <Typography component={"i"}><Trans i18nKey={"orderInTransit"}>Order in transit</Trans></Typography>
      );
    case 'inPickupPoint':
      return (
        <Typography component={"i"}><Trans i18nKey={"orderInPickupPoint"}>In pickup-point</Trans></Typography>
      );
    case 'backToSender':
      return (
        <Typography component={"i"}><Trans i18nKey={"orderBackToSender"}>Back to sender</Trans></Typography>
      );
    case 'delivered':
      return (
        <Typography component={"i"}><Trans i18nKey={"orderDelivered"}>Order delivered</Trans></Typography>
      );
    case 'noShipping':
      return (
        <Typography component={"i"}><Trans i18nKey={"orderCompleted"}>Order completed</Trans></Typography>
      );
    default:
      return null;
  }

};
export default OrderStatus;
