import React from 'react';
import {Grid, Stack, Typography} from "@mui/material";
import Container from "@mui/material/Container";
import useMediaQuery from "@mui/material/useMediaQuery";

const section = {
  "name": "AboutSection1",
  "type": "banner",
  "bannerTitle1": "NR&D,",
  "bannerTitle2": "Notre laboratoire",
  "bannerText1": "Nous n’avons pas trouvé toutes les solutions santé qui nous semblaient primordiales, donc nous avons décidé de les mettre au point nous-mêmes en créant NR&D, notre propre laboratoire.",
  // eslint-disable-next-line max-len
  "bannerText2": "Et nous avons mis la barre haut en prenant des engagements forts : uniquement des ingrédients 100 % naturels, des actifs de qualité hautement concentrés, zéro additif (excipient) et aucun OGM. Et surtout, jamais de fausses promesses ni de « pseudo-sciences » : uniquement du résultat, avec des dosages dont l’impact sur la santé serait scientifiquement prouvé.",
  "bannerText3": "Nous nous sommes engagés à concevoir les formules les plus cleans, honnêtes et efficaces du marché. Et nous avons tenu notre promesse.",
  "bannerText4": "Ensemble, révolutionnons la santé.",
  "bannerBackgroundImage": `${process.env.REACT_APP_API_RESOURCES_URL}/images/NRD_LogoMainNature-1.png`
};
export default function ProsSection5() {

  const isDownSm = useMediaQuery(theme => theme.breakpoints.down('sm'));

  return (
    <Container maxWidth={'xxl'}
      sx={{
        pl: '0 !important',
        pr: '0 !important'
      }}
    >
      <Grid
        container
        spacing={2}
        justifyContent={"space-between"}
        flexDirection={"row"}
        alignItems={"center"}
        alignContent={"center"}
        sx={{
          width: '100%',
          background: isDownSm ? '#FFFFFF' : `url(${section.bannerBackgroundImage})`,
          backgroundPosition: 'left bottom',
          backgroundSize: {xs: '500px', sm: '50%'},
          backgroundRepeat: 'no-repeat',
          margin: '0px 0 60px',
          padding: '1px 0',
          height: {xs: 'auto', sm: '40vw', md: '40vw', lg: '40vw', xl: '40vw', xxl: '760px'},
          overflowX: 'clip',

        }}>
        <Grid item xs={12} sm={6}>

        </Grid>

        <Grid item xs={12} sm={6}>
          <Stack gap={2} sx={{pt: '3vw'}} alignItems={'flex-start'}>
            <Typography
              sx={{
                fontFamily: 'Raleway',
                fontWeight: '600',
                padding: '8px 8px',
                fontSize: {xs: '2.5rem', sm: '2rem', md: '2.5rem', lg: '3.5rem', xl: '3.5rem', xxl: '4.5rem'},
                lineHeight: '1',
                overflow: 'hidden',
                color: 'green',
                textAlign: {xs: 'left', sm: 'left'},
                zIndex: 1,
                '& p': {
                  margin: 0,
                },
                '& span': {
                  lineHeight: '1',
                  fontFamily: 'Raleway',
                  fontWeight: '600',
                  fontSize: {xs: '2.5rem', sm: '2rem', md: '2.5rem', lg: '3.5rem', xl: '3.5rem', xxl: '4.5rem'},
                }
              }}>
              {section.bannerTitle1} <br/>
              <Typography component={"span"} sx={{color: 'primary.main'}}>
                {section.bannerTitle2}
              </Typography>
            </Typography>

            <Typography
              sx={{
                fontSize: {xs: '1rem', sm: '0.9rem', md: '1rem', lg: '1.1rem', xl: '1.2rem', xxl: '1.3rem'},
                padding: '0 8px 8px 8px',
                fontFamily: 'Open Sans',
                maxWidth: {xs: '100%', sm: '100%'},
                lineHeight: '1.4',
                overflow: 'hidden',
                textAlign: 'left',
                color: 'primary.light',
                zIndex: 1,
                '& p': {
                  margin: 0,
                }
              }}>
              {section.bannerText1}
            </Typography>
            <Typography
              sx={{
                fontSize: {xs: '1rem', sm: '0.9rem', md: '1rem', lg: '1.1rem', xl: '1.2rem', xxl: '1.3rem'},
                padding: '0 8px 8px 8px',
                fontFamily: 'Open Sans',
                maxWidth: {xs: '100%', sm: '100%'},
                lineHeight: '1.4',
                overflow: 'hidden',
                textAlign: 'left',
                color: 'primary.light',
                zIndex: 1,
                '& p': {
                  margin: 0,
                }
              }}>
              {section.bannerText2}
            </Typography>
            <Typography
              sx={{
                fontSize: {xs: '1rem', sm: '0.9rem', md: '1rem', lg: '1.1rem', xl: '1.2rem', xxl: '1.3rem'},
                padding: '0 8px 8px 8px',
                fontFamily: 'Open Sans',
                maxWidth: {xs: '100%', sm: '100%'},
                lineHeight: '1.4',
                overflow: 'hidden',
                textAlign: 'left',
                color: 'primary.light',
                zIndex: 1,
                '& p': {
                  margin: 0,
                }
              }}>
              {section.bannerText3}
            </Typography>
            <Typography
              sx={{
                fontSize: {xs: '1rem', sm: '0.9rem', md: '1rem', lg: '1.1rem', xl: '1.2rem', xxl: '1.3rem'},
                padding: '0 8px 8px 8px',
                fontFamily: 'Open Sans',
                maxWidth: {xs: '100%', sm: '100%'},
                lineHeight: '1.4',
                overflow: 'hidden',
                textAlign: 'left',
                color: 'primary.light',
                zIndex: 1,
                '& p': {
                  margin: 0,
                }
              }}>
              {section.bannerText4}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
}
