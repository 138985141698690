export const SEARCH_OFFICES_FROM_PLACE_REQUEST            = 'CONSULTANTS/SEARCH_OFFICES_FROM_PLACE_REQUEST';
export const SEARCH_OFFICES_FROM_PLACE_SUCCESS            = 'CONSULTANTS/SEARCH_OFFICES_FROM_PLACE_SUCCESS';
export const SEARCH_OFFICES_FROM_PLACE_FAILED             = 'CONSULTANTS/SEARCH_OFFICES_FROM_PLACE_FAILED';

export const SEARCH_OFFICES_FROM_PLACE_AND_ZOOM_REQUEST   = 'CONSULTANTS/SEARCH_OFFICES_FROM_PLACE_AND_ZOOM_REQUEST';
export const SEARCH_OFFICES_FROM_PLACE_AND_ZOOM_SUCCESS   = 'CONSULTANTS/SEARCH_OFFICES_FROM_PLACE_AND_ZOOM_SUCCESS';
export const SEARCH_OFFICES_FROM_PLACE_AND_ZOOM_FAILED    = 'CONSULTANTS/SEARCH_OFFICES_FROM_PLACE_AND_ZOOM_FAILED';

export const GET_OFFICE_REQUEST     = 'CONSULTANTS/GET_OFFICE_REQUEST';
export const GET_OFFICE_SUCCESS     = 'CONSULTANTS/GET_OFFICE_SUCCESS';
export const GET_OFFICE_FAILED      = 'CONSULTANTS/GET_OFFICE_FAILED';

export const SET_MAP_DISPLAY_OPTION     = 'CONSULTANTS/SET_MAP_DISPLAY_OPTION';
export const SET_SEARCH_BAR_OPEN        = 'CONSULTANTS/SET_SEARCH_BAR_OPEN';
