import React from "react";
import { useTranslation } from "react-i18next";
import ReduxToastr, {toastr} from 'react-redux-toastr';

import { format } from "./formatError";

import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';

export const SnackbarSuccess = toastr.success;

export const SnackbarError = toastr.error;

export const SnackbarConfirm = (message, onOk, onCancel = () => {}, okText = 'Ok') => {
  toastr.confirm(message, {onOk:onOk, onCancel:onCancel, okText: okText});
};

export const SnackbarContent = (component, callback, size, okText = 'Ok') => {
  toastr.confirm(null, {
    onOk:callback,
    disableCancel: true,
    okText: okText,
    component: () => component,
    id: size
  });
};

export const SnackbarFormattedError = (error) => toastr.error(format(error));

export const SnackbarWarning = toastr.warning;

export const SnackbarInfo = toastr.info;

const SnackbarProvider = () => {

  const {t} = useTranslation();
  return (
    <ReduxToastr
      timeOut={12000}
      newestOnTop={true}
      preventDuplicates
      position="top-left"
      confirmOptions={{
        okText: 'Ok',
        cancelText: t("cancelButton")
      }}
      getState={(state) => state.toastr}
      transitionIn="bounceIn"
      transitionOut="bounceOut"
      progressBar
      closeOnToastrClick
    />
  );
};

export default SnackbarProvider;