import React from "react";
import { useTranslation } from "react-i18next";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import ListItem from "@mui/material/ListItem";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";

const OrderItem = ({item, setProductQuantity}) => {

  const {t} = useTranslation();

  const handleChangeItemQuantity = (item, quantity) => {

    setProductQuantity(item, quantity);
  };

  const quantityOptions = () => {

    let quantityOptions = [];
    for (let i = 0; i <= 100; ++i) {
      quantityOptions.push(
        <MenuItem key={i} value={i}>{i}</MenuItem>
      );
    }
    return quantityOptions;
  };

  return (
    <ListItem>
      <Grid container justify={"space-between"} alignItems={"center"}>
        <Grid item>
          <Grid container alignItems={"center"} spacing={2}>
            <Grid item>
              <img
                width={80}
                height={80}
                src={item.image[200]}
                alt={item.detail.name}
              />
            </Grid>
            <Grid item zeroMinWidth>
              <FormControl
                variant={"outlined"}
              >
                <InputLabel>{t('qtyCart')}</InputLabel>
                <Select
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  name={"quantity"}
                  value={item.quantity}
                  onChange={(e) => handleChangeItemQuantity(item, e.target.value)}
                  label={t('qtyCart')}
                >
                  {quantityOptions()}
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <Typography variant="subtitle1" color={"inherit"}>
                {item.detail.name} - {item[`price`]} {item.currency}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item zeroMinWidth>
          <IconButton color="primary" onClick={() => handleChangeItemQuantity(item, 0)}>
            <DeleteOutlineOutlinedIcon/>
          </IconButton>
        </Grid>
      </Grid>

    </ListItem>
  );
};
export default OrderItem;
