import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import LinearProgress from "@mui/material/LinearProgress";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import {makeStyles} from '@mui/styles';

import LineChartByYearAndMonth from "../../../../components/Charts/LineChartByYearAndMonth";
import { proStatsActions } from "../store/actions";
import {
  directRecruitmentDataSelector,
  directRecruitmentLinesGraphDataSelector,
  directRecurringSalesLinesGraphDataSelector,
  directRecurringTurnoverDataSelector,
  directSalesLinesGraphDataSelector,
  directTurnoverDataSelector
} from "../store/selectors";

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiSelect-select': {
      fontSize: '1.5rem',
      lineHeight: '1.8rem',
      paddingTop: 10,
      paddingBottom: 10,
      maxWidth: '70vw'
    },
    height: '100%',
    '& .MuiCardHeader-root': {
      '& .MuiCardHeader-content': {
        height: 81
      },
      '& .MuiInput-root': {
        '& .MuiInputBase-input': {
          textAlign: 'right'
        }
      }
    }
  },
  menuItem: {
    fontSize: '1.5rem',
    lineHeight: '1.8rem',
  },
  loader: {
    height: 290
  }
}));
const DirectCharts = ({user}) => {

  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();
  const [view, setView] = useState("directSales");
  const directSalesLinesGraphData = useSelector(directSalesLinesGraphDataSelector(user.id));
  const directRecurringSalesLinesGraphData = useSelector(directRecurringSalesLinesGraphDataSelector(user.id));
  const directRecruitmentLinesGraphData = useSelector(directRecruitmentLinesGraphDataSelector(user.id));
  const directTurnoverData = useSelector(directTurnoverDataSelector(user.id));
  const directRecurringTurnoverData = useSelector(directRecurringTurnoverDataSelector(user.id));
  const directRecruitmentData = useSelector(directRecruitmentDataSelector(user.id));

  useEffect(() => {
    switch (view) {
      case "directRecruitments":
        if(!directRecruitmentLinesGraphData) {
          dispatch(proStatsActions.getDirectRecruitmentRequest(user.id));
        }
        break;
      case "directRecurringSales":
        if(!directRecurringSalesLinesGraphData) {
          dispatch(proStatsActions.getDirectRecurringSalesRequest(user.id));
        }
        break;
      case "directSales":
      default:
        if(!directSalesLinesGraphData) {
          dispatch(proStatsActions.getDirectSalesRequest(user.id));
        }
    }
  }, [view]);

  const handleChangeView = (e) => {
    setView(e.target.value);
  };

  const getValues = () => {
    switch (view) {
      case "directRecruitments":
        if(!directRecruitmentLinesGraphData) {
          return null;
        }
        return {
          data: directRecruitmentLinesGraphData,
          year: `${Math.round(directRecruitmentData.year*100)/100}`,
          month: `${Math.round(directRecruitmentData.month*100)/100}`,
        };
      case "directRecurringSales":
        if(!directRecurringSalesLinesGraphData) {
          return null;
        }
        return {
          data: directRecurringSalesLinesGraphData,
          year: `€ ${Math.round(directRecurringTurnoverData.year*100)/100}`,
          month: `€ ${Math.round(directRecurringTurnoverData.month*100)/100}`,
        };
      case "directSales":
      default:
        if(!directSalesLinesGraphData) {
          return null;
        }
        return {
          data: directSalesLinesGraphData,
          year: `€ ${Math.round(directTurnoverData.year*100)/100}`,
          month: `€ ${Math.round(directTurnoverData.month*100)/100}`,
        };
    }
  };

  return (
    <Card className={classes.root}>
      <CardHeader title={
        <Select defaultValue={view} variant={"outlined"} onChange={handleChangeView}>
          <MenuItem className={classes.menuItem} value="directSales">
            {t("directSales")}
          </MenuItem>
          <MenuItem className={classes.menuItem} value="directRecurringSales">
            {t("directRecurringSales")}
          </MenuItem>
          <MenuItem className={classes.menuItem} value="directRecruitments">
            {t("directRecruitments")}
          </MenuItem>
        </Select>
      }/>
      <Divider/>
      {!getValues() && (
        <Grid className={classes.loader} container alignItems={"center"} justify={"center"}>
          <Grid item xs={6} sm={4}>
            <Typography align={"center"}><Trans i18nKey={"loading"}>Loading</Trans> ...</Typography>
            <LinearProgress color="secondary" />
          </Grid>
        </Grid>
      )}
      {getValues() && (
        <>
          <CardContent>
            <LineChartByYearAndMonth
              data={getValues().data}
              heightLg={350}
              heightMd={300}
              heightSm={300}
              primaryColor={{
                border: 'rgb(89,80,236)',
                background: 'rgba(89,80,236, 0.04)'
              }}
            />
          </CardContent>
          <Grid container>
            <Grid item xs={6}>
              <Typography component={"div"} align={"center"} gutterBottom>
                <Trans i18nKey={"thisYear"}>This year</Trans>
                <Typography variant={"h6"}>{getValues().year}</Typography>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography component={"div"} align={"center"} gutterBottom>
                <Trans i18nKey={"thisMonth"}>This month</Trans>
                <Typography variant={"h6"}>{getValues().month}</Typography>
              </Typography>
            </Grid>
          </Grid>
        </>
      )}

    </Card>
  );
};
export default DirectCharts;
