import React from "react";
import {Trans, useTranslation} from "react-i18next";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import {makeStyles} from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiDivider-root': {
      margin: -1,
      [theme.breakpoints.down('xs')]: {
        display: 'none'
      },
    },
    [theme.breakpoints.down('xs')]: {
      '& .MuiCard-root': {
        overflow: 'auto'
      }
    },
    '& .MuiTypography-subtitle1': {
      maxHeight: 42,
      lineHeight: '20px',
      overflow: 'hidden'
    },
    '& img': {
      maxWidth: '100%',
      maxHeight: 80
    },
    '& a': {
      textDecoration: 'none'
    }
  },
  listItems: {
    [theme.breakpoints.up('sm')]: {
      minHeight: '30vh',
      overflow: 'auto'
    }
  },
  item: {
    [theme.breakpoints.up('sm')]: {
      '& .MuiListItem-root': {
        borderRight: '1px solid #DDD'
      }
    },
  },
  detailsRowPrice: {
    paddingTop: 20
  },
}));

const OrderHiddenDetails = ({order}) => {

  const classes = useStyles();
  const {t} = useTranslation();

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <Grid container justify={"space-between"}>
          <Grid item>
            <Grid container spacing={2} alignItems={"center"}>
              {order.shippingTrackingLink && (
                <Grid item>
                  <Button>
                    <Typography variant={"button"} target={"_blank"} component={"a"}
                      href={order.shippingTrackingLink} color={"secondary"}>
                      <Trans i18nKey={"orderTracking"}>Order tracking</Trans>
                    </Typography>
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={5}>
        <List className={classes.listItems}>
          {order.products.map((item, key) => (
            <React.Fragment key={key}>
              <ListItem className={classes.item}>
                <Grid container justify={"center"} alignItems={"center"}>
                  <Grid item xs={4}>
                    <Grid container justify={"center"} alignItems={"center"}>
                      <img
                        width={80}
                        height={80}
                        src={item.originalProduct.image[200]}
                        alt={item.originalProduct.detail.name}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={8}>
                    <Grid container justify={"center"} alignItems={"center"}>
                      <Grid item xs={8}>
                        <Typography variant="subtitle1">
                          <b>{item.quantity} x</b> {item.originalProduct.detail.name}
                        </Typography>
                        {item.deferredQuantity ? <i>{t('deferredShipping')} ({item.deferredQuantity}/{item.quantity})</i> : ''}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </ListItem>
              {order.products.length > 1 && (
                <Divider/>
              )}
            </React.Fragment>
          ))}
        </List>
      </Grid>
      <Divider orientation="vertical" flexItem/>
    </Grid>
  );
};

export default OrderHiddenDetails;
