import {
  GET_SALES_ERROR,
  GET_SALES_REQUEST,
  GET_SALES_SUCCESS
} from "./constants";

export const commissionsActions = {
  getSalesRequest,
  getSalesSuccess,
  getSalesError,
};

function getSalesRequest(year, month, offset = 0) {
  return {
    type: GET_SALES_REQUEST,
    year, month,
    offset
  };
}

function getSalesSuccess(data, year, month) {
  return {
    type: GET_SALES_SUCCESS,
    data, year, month
  };
}

function getSalesError(error) {
  return {
    type: GET_SALES_ERROR,
    error
  };
}
