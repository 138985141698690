import {
  ACCEPT_PROFILING_TEST_ERROR,
  ACCEPT_PROFILING_TEST_REQUEST,
  ACCEPT_PROFILING_TEST_SUCCESS,
  ADD_PARTNER_ERROR,
  ADD_PARTNER_REQUEST,
  ADD_PARTNER_SUCCESS,
  DECLINE_PROFILING_TEST_ERROR,
  DECLINE_PROFILING_TEST_REQUEST,
  DECLINE_PROFILING_TEST_SUCCESS,
  GET_CONSULTANT_ERROR,
  GET_CONSULTANT_REQUEST,
  GET_CONSULTANT_SUCCESS,
  GET_CONSULTANTS_ERROR,
  GET_CONSULTANTS_REQUEST,
  GET_CONSULTANTS_SUCCESS,
  GET_EVOLUTIONS_ERROR,
  GET_EVOLUTIONS_REQUEST,
  GET_EVOLUTIONS_SUCCESS,
  GET_PARTNERS_ERROR,
  GET_PARTNERS_REQUEST,
  GET_PARTNERS_SUCCESS,
  GET_PROFILING_TEST_ERROR,
  GET_PROFILING_TEST_REQUEST,
  GET_PROFILING_TEST_SUCCESS,
  GET_PROFILING_TESTS_ERROR,
  GET_PROFILING_TESTS_REQUEST,
  GET_PROFILING_TESTS_SUCCESS,
  UPDATE_PARTNER_ERROR,
  UPDATE_PARTNER_REQUEST,
  UPDATE_PARTNER_SHIPPING_ADDRESS_ERROR,
  UPDATE_PARTNER_SHIPPING_ADDRESS_REQUEST,
  UPDATE_PARTNER_SHIPPING_ADDRESS_SUCCESS,
  UPDATE_PARTNER_SUCCESS} from "./constants";

export const networkActions = {
  getProfilingTestsRequest,
  getProfilingTestsSuccess,
  getProfilingTestsError,
  getProfilingTestRequest,
  getProfilingTestSuccess,
  getProfilingTestError,
  acceptProfilingTestRequest,
  acceptProfilingTestSuccess,
  acceptProfilingTestError,
  declineProfilingTestRequest,
  declineProfilingTestSuccess,
  declineProfilingTestError,
  getEvolutionsRequest,
  getEvolutionsSuccess,
  getEvolutionsError,
  getConsultantsRequest,
  getConsultantsSuccess,
  getConsultantsError,
  getConsultantRequest,
  getConsultantSuccess,
  getConsultantError,
  getPartnersRequest,
  getPartnersSuccess,
  getPartnersError,
  addPartnerRequest,
  addPartnerSuccess,
  addPartnerError,
  updatePartnerRequest,
  updatePartnerSuccess,
  updatePartnerError,
  updatePartnerShippingAddressRequest,
  updatePartnerShippingAddressSuccess,
  updatePartnerShippingAddressError
};

function getProfilingTestsRequest() {
  return {
    type: GET_PROFILING_TESTS_REQUEST
  };
}

function getProfilingTestsSuccess(requests) {
  return {
    type: GET_PROFILING_TESTS_SUCCESS,
    requests
  };
}

function getProfilingTestsError(error) {
  return {
    type: GET_PROFILING_TESTS_ERROR,
    error
  };
}

function getProfilingTestRequest(requestId) {

  return {
    type: GET_PROFILING_TEST_REQUEST,
    requestId
  };
}

function getProfilingTestSuccess(request) {
  return {
    type: GET_PROFILING_TEST_SUCCESS,
    request
  };
}

function getProfilingTestError(error) {
  return {
    type: GET_PROFILING_TEST_ERROR,
    error
  };
}

function acceptProfilingTestRequest(requestId) {

  return {
    type: ACCEPT_PROFILING_TEST_REQUEST,
    requestId
  };
}

function acceptProfilingTestSuccess() {
  return {
    type: ACCEPT_PROFILING_TEST_SUCCESS
  };
}

function acceptProfilingTestError(error) {
  return {
    type: ACCEPT_PROFILING_TEST_ERROR,
    error
  };
}

function declineProfilingTestRequest(requestId) {

  return {
    type: DECLINE_PROFILING_TEST_REQUEST,
    requestId
  };
}

function declineProfilingTestSuccess() {
  return {
    type: DECLINE_PROFILING_TEST_SUCCESS
  };
}

function declineProfilingTestError(error) {
  return {
    type: DECLINE_PROFILING_TEST_ERROR,
    error
  };
}

function getEvolutionsRequest(role) {
  return {
    type: GET_EVOLUTIONS_REQUEST,
    role
  };
}

function getEvolutionsSuccess(role, pros) {
  return {
    type: GET_EVOLUTIONS_SUCCESS,
    role, pros
  };
}

function getEvolutionsError(error) {
  return {
    type: GET_EVOLUTIONS_ERROR,
    error
  };
}

function getConsultantsRequest() {
  return {
    type: GET_CONSULTANTS_REQUEST
  };
}

function getConsultantsSuccess(consultants) {
  return {
    type: GET_CONSULTANTS_SUCCESS,
    consultants
  };
}

function getConsultantsError(error) {
  return {
    type: GET_CONSULTANTS_ERROR,
    error
  };
}

function getConsultantRequest(consultantId) {
  return {
    type: GET_CONSULTANT_REQUEST,
    consultantId
  };
}

function getConsultantSuccess(consultant) {
  return {
    type: GET_CONSULTANT_SUCCESS,
    consultant
  };
}

function getConsultantError(error) {
  return {
    type: GET_CONSULTANT_ERROR,
    error
  };
}

function getPartnersRequest() {
  return {
    type: GET_PARTNERS_REQUEST
  };
}
function getPartnersSuccess(partners) {
  return {
    type: GET_PARTNERS_SUCCESS,
    partners
  };
}
function getPartnersError(error) {
  return {
    type: GET_PARTNERS_ERROR,
    error
  };
}

function addPartnerRequest(partner) {
  return {
    type: ADD_PARTNER_REQUEST,
    partner
  };
}
function addPartnerSuccess(partner) {
  return {
    type: ADD_PARTNER_SUCCESS,
    partner
  };
}
function addPartnerError(error) {
  return {
    type: ADD_PARTNER_ERROR,
    error
  };
}

function updatePartnerRequest(partner, data) {
  return {
    type: UPDATE_PARTNER_REQUEST,
    partner, data
  };
}
function updatePartnerSuccess(partner) {
  return {
    type: UPDATE_PARTNER_SUCCESS,
    partner
  };
}
function updatePartnerError(error) {
  return {
    type: UPDATE_PARTNER_ERROR,
    error
  };
}

function updatePartnerShippingAddressRequest(partner, data) {
  return {
    type: UPDATE_PARTNER_SHIPPING_ADDRESS_REQUEST,
    partner,
    data
  };
}

function updatePartnerShippingAddressSuccess(shippingAddress) {
  return {
    type: UPDATE_PARTNER_SHIPPING_ADDRESS_SUCCESS,
    shippingAddress
  };
}

function updatePartnerShippingAddressError(error) {
  return {
    type: UPDATE_PARTNER_SHIPPING_ADDRESS_ERROR,
    error
  };
}