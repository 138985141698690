import React, {useEffect} from "react";
import CloseIcon from "@mui/icons-material/Close";
import Skeleton from "@mui/lab/Skeleton";
import {Box} from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Typography from "@mui/material/Typography";

import theme from "../../../../assets/styles/theme/theme";
import AutocompletePlaces from "../../../../components/GoogleAutocomplete/AutocompletePlaces";
import FormValidator from "../../../../components/Validators/FormValidator";
import SelectValidator from "../../../../components/Validators/SelectValidator";
import TextFieldValidator from "../../../../components/Validators/TextFieldValidator";
import {SnackbarSuccess} from "../../../../helpers/snackbar";
import {mailingService} from "../../Mailing/store/services";

const ContactModal = ({open, setOpen}) => {

  const [data, setData] = React.useState({
    form13: 'non',
    form16: 'non',
    form21: 'Je ne conseille pas encore',
    form22: 'non',
    form23: 'non',
    form24: 'non'
  });
  const [place, setPlace] = React.useState({});
  const [formSubmitted, setFormSubmitted] = React.useState(false);

  useEffect(() => {
    setData({
      ...data,
      form5: place?.address,
      form7: place?.components?.city,
      form9: place?.components?.zip,
      form10: place?.components?.country
    });
  }, [place]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    const {name, value} = e.target;
    setData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleAddressChange = (place) => {
    if(!place) return;
    setPlace(place);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormSubmitted(true);
    mailingService.contact(data).then(() => {}).catch(() => {});
    SnackbarSuccess('Votre formulaire a bien été envoyé ! Notre équipe vous contactera dans les plus brefs délais.');
    handleClose();
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <Box component={'div'} sx={{
        position: 'relative',
      }}>
        <CloseIcon onClick={handleClose} sx={{
          top: 16,
          right: 16,
          fontSize: 20,
          cursor: 'pointer',
          position: 'absolute',
          color: '#7C909B',
          background: '#ffffff',
          borderRadius: '50%',
          border: '1px solid #7C909B',
          padding: '4px',
          '&:hover': {
            color: '#000',
            border: '1px solid #000',
          }
        }}/>
      </Box>
      <DialogTitle id="responsive-dialog-title" sx={{
        color: theme.palette.primary.main,
        fontFamily: 'Open sans',
        pl: 4,
        boxShadow: '0px 0px 5px 1px #dadada',
      }}>
        <RadioGroup
          value={data.formType}
          key={"formType"}
          name={"formType"}
          onChange={handleChange}
        >
          <FormControlLabel value="Je souhaite passer une commande personnelle" control={<Radio />} label="Je souhaite passer une commande personnelle" />
          <FormControlLabel value="Je souhaite rejoindre la communauté des pros, conseiller Nutri-Logics et/ou rencontrer le délégué de ma région" control={<Radio />} label="Je souhaite rejoindre la communauté des pros, conseiller Nutri-Logics et/ou rencontrer le délégué de ma région" />
        </RadioGroup>
      </DialogTitle>
      <DialogContent component="main"
        maxWidth="xs"
        sx={{
          position: 'relative',
          pl: 1,
          pt: '20px !important',
          '& .MuiInputBase-root': {
            fontFamily: 'Open sans',
            fontSize: '0.9rem',
            color: '#7C909B',
            //height: '48px',
            borderRadius: '30px'
          },
          '& .MuiFormLabel-root': {
            fontFamily: 'Open sans',
            fontSize: '0.9rem',
            lineHeight: '1.1rem',
            color: '#7C909B'
          },
          '& .MuiFormHelperText-root': {
            position: 'unset !important',
          }
        }}>
        {!data.formType ? (
          <Box>
            <Grid container spacing={2} alignItems={"center"} sx={{
              ml: '0px !important',
              width: '100% !important',
            }}>
              <Grid item xs={12}>
                <Skeleton/>
              </Grid>
              <Grid item xs={6}>
                <Skeleton animation="wave"/>
              </Grid>
              <Grid item xs={6}>
                <Skeleton animation="wave"/>
              </Grid>
              <Grid item xs={12}>
                <Skeleton animation="wave" />
              </Grid>
              <Grid item xs={12}>
                <Skeleton animation="wave" />
              </Grid>
              <Grid item xs={12}>
                <Skeleton animation={false} />
              </Grid>
            </Grid>
          </Box>
        ) : (
          <FormValidator
            onSubmit={handleSubmit}
            onError={errors => console.log(errors)}
            noValidate
          >
            <Grid container spacing={2} alignItems={"center"} sx={{
              ml: '0px !important',
              width: '100% !important',
            }}>
              <Grid item xs={12}>
                <SelectValidator
                  required
                  fullWidth
                  autoFocus={true}
                  label={"Mr. / Mme. / Non genré *"}
                  id="form1"
                  name="form1"
                  value={data.form1 || ""}
                  onChange={handleChange}
                >
                  <MenuItem value="monsieur">
                        Monsieur
                  </MenuItem>
                  <MenuItem value="madame">
                        Madame
                  </MenuItem>
                  <MenuItem value="Non genré">
                        Non genré
                  </MenuItem>
                </SelectValidator>
              </Grid>
              <Grid item xs={6}>
                <TextFieldValidator
                  required
                  fullWidth
                  label={"Nom"}
                  id="form2"
                  name="form2"
                  value={data.form2 || ""}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={6}>
                <TextFieldValidator
                  required
                  fullWidth
                  label={"Prénom"}
                  id="form3"
                  name="form3"
                  value={data.form3 || ""}
                  onChange={handleChange}
                />
              </Grid>
              {/* <Grid item xs={12}>
              <TextFieldValidator
                fullWidth
                label={"Date de naissance"}
                id="form4"
                name="form4"
                type="date"
                value={data.form4 || ""}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid> */}
              <Grid item xs={12}>
                <AutocompletePlaces
                  fullWidth
                  label={"Adresse postale"}
                  variant="outlined"
                  name="form5"
                  onPlaceChanged={handleAddressChange}
                  error={formSubmitted && !place}
                  helperText={formSubmitted && !place}
                  placeholder=""
                  preview
                  required
                  customFieldsEnabled
                  withAdditionalInfo
                  requiredFields={['streetNumber', 'streetName', 'zip', 'city', 'country']}
                />
              </Grid>
              <Grid item xs={12}>
                <TextFieldValidator
                  fullWidth
                  label={"N° de boite (facultatif)"}
                  id="form8"
                  name="form8"
                  value={data.form8 || ""}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextFieldValidator
                  required
                  fullWidth
                  label={"Adresse mail"}
                  id="form11"
                  name="form11"
                  value={data.form11 || ""}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextFieldValidator
                  required
                  fullWidth
                  label={"N° de téléphone"}
                  id="form12"
                  name="form12"
                  value={data.form12 || ""}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant={"body1"}>
                      Possédez-vous un n° Siret ou n°BCE/TVA/INAMI ?
                </Typography>
                <RadioGroup
                  value={data.form13}
                  key={"form13"}
                  name={"form13"}
                  onChange={handleChange}
                >
                  <FormControlLabel value="oui" control={<Radio />} label="Oui" />
                  <FormControlLabel value="non" control={<Radio />} label="Non" />
                </RadioGroup>
              </Grid>
              <Grid item xs={12}>
                <TextFieldValidator
                  required
                  fullWidth
                  label={"Profession"}
                  id="form14"
                  name="form14"
                  value={data.form14 || ""}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextFieldValidator
                  fullWidth
                  label={"Spécialité (facultatif)"}
                  id="form15"
                  name="form15"
                  value={data.form15 || ""}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant={"body1"}>
                      Exercez-vous cette activité à titre principal ? *
                </Typography>
                <RadioGroup
                  required
                  value={data.form16}
                  key={"form16"}
                  name={"form16"}
                  onChange={handleChange}
                >
                  <FormControlLabel value="oui" control={<Radio />} label="Oui" />
                  <FormControlLabel value="non" control={<Radio />} label="Non" />
                </RadioGroup>
              </Grid>
              {data.form16 === 'non' && (
                <Grid item xs={12}>
                  <TextFieldValidator
                    fullWidth
                    id="form16bis"
                    name="form16bis"
                    value={data.form16bis || ""}
                    label={"Pourriez-vous nous expliquer, en quelques mots, votre activité et le temps que vous y consacrez ? (facultatif)"}
                    placeholder={"Pourriez-vous nous expliquer, en quelques mots, votre activité et le temps que vous y consacrez ? (facultatif)"}
                    onChange={handleChange}
                    multiline={true}
                    rows={3}
                  />
                </Grid>
              )}
              <Grid item xs={6}>
                <TextFieldValidator
                  fullWidth
                  label={"Site web (facultatif)"}
                  id="form17"
                  name="form17"
                  value={data.form17 || ""}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={6}>
                <TextFieldValidator
                  fullWidth
                  label={"Facebook (facultatif)"}
                  id="form18"
                  name="form18"
                  value={data.form18 || ""}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={6}>
                <TextFieldValidator
                  fullWidth
                  label={"LinkedIn (facultatif)"}
                  id="form19"
                  name="form19"
                  value={data.form19 || ""}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={6}>
                <TextFieldValidator
                  fullWidth
                  label={"Instagram (facultatif)"}
                  id="form20"
                  name="form20"
                  value={data.form20 || ""}
                  onChange={handleChange}
                />
              </Grid>
              {data.formType === 'Je souhaite rejoindre la communauté des pros, conseiller Nutri-Logics et/ou rencontrer le délégué de ma région' && (
                <>
                  <Grid item xs={12}>
                    <Typography variant={"body1"}>
                            Vous conseillez : *
                    </Typography>
                    <RadioGroup
                      required
                      value={data.form21}
                      key={"form21"}
                      name={"form21"}
                      onChange={handleChange}
                    >
                      <FormControlLabel value="Je ne conseille pas encore" control={<Radio />} label="Je ne conseille pas encore" />
                      <FormControlLabel value="Moins d’1 patient par semaine" control={<Radio />} label="Moins d’1 patient par semaine" />
                      <FormControlLabel value="Entre 1 et 5 patients par semaine" control={<Radio />} label="Entre 1 et 5 patients par semaine" />
                      <FormControlLabel value="Entre 5 et 10 patients par semaine" control={<Radio />} label="Entre 5 et 10 patients par semaine" />
                      <FormControlLabel value="Plus de 10 patients par semaine" control={<Radio />} label="Plus de 10 patients par semaine" />
                    </RadioGroup>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant={"body1"}>
                            Souhaitez-vous recevoir des bons de commande personnalisés ?
                    </Typography>
                    <RadioGroup
                      value={data.form22}
                      key={"form22"}
                      name={"form22"}
                      onChange={handleChange}
                    >
                      <FormControlLabel value="oui" control={<Radio />} label="Oui" />
                      <FormControlLabel value="non" control={<Radio />} label="Non" />
                    </RadioGroup>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant={"body1"}>
                            Souhaitez-vous recevoir un catalogue produit (version papier) ?
                    </Typography>
                    <RadioGroup
                      value={data.form23}
                      key={"form23"}
                      name={"form23"}
                      onChange={handleChange}
                    >
                      <FormControlLabel value="oui" control={<Radio />} label="Oui" />
                      <FormControlLabel value="non" control={<Radio />} label="Non" />
                    </RadioGroup>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant={"body1"}>
                            Souhaitez-vous rencontrer le délégué de votre région ?
                    </Typography>
                    <RadioGroup
                      value={data.form24}
                      key={"form24"}
                      name={"form24"}
                      onChange={handleChange}
                    >
                      <FormControlLabel value="oui" control={<Radio />} label="Oui" />
                      <FormControlLabel value="non" control={<Radio />} label="Non" />
                    </RadioGroup>
                  </Grid>
                </>
              )}
              <Grid item xs={12}>
                <TextFieldValidator
                  fullWidth
                  id="formFinal"
                  name="formFinal"
                  value={data.formFinal || ""}
                  label={"Je souhaite ajouter ceci : (facultatif)"}
                  placeholder={"Je souhaite ajouter ceci : (facultatif)"}
                  onChange={handleChange}
                  multiline={true}
                  rows={3}
                />
              </Grid>
              <Grid item xs={12} sx={{pl:"0px !important"}}>
                <Button
                  variant="contained"
                  color={"primary"}
                  type={"submit"}
                  sx={{
                    fontSize: '1rem',
                    textTransform: 'capitalize',
                    borderRadius: '30px',
                    color: '#fff',
                    lineHeight: '1.3',
                    padding: '8px 8px',
                    width: '200px',
                    margin: '0 auto 16px',
                    display: 'block'
                  }}>
                      Envoyer le formulaire
                </Button>
              </Grid>
            </Grid>
          </FormValidator>
        )}
      </DialogContent>
      <DialogActions>
      </DialogActions>
    </Dialog>
  );
};

export default ContactModal;