import React from "react";
import {Trans, useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Box} from "@mui/material";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import {makeStyles} from '@mui/styles';

import theme from "../../../../../../assets/styles/theme/theme";
import {userSelector} from "../../../../../Authentication/store/selectors";
import {shopActions} from "../../../../Shop/store/actions";
import {shippingAddressSelectedSelector} from "../../../../Shop/store/selectors";

import OrderCreditNote from "./OrderCreditNote";
import OrderProforma from "./OrderProforma";

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiDivider-root': {
      margin: -1,
      [theme.breakpoints.down('xs')]: {
        display: 'none'
      },
    },
    [theme.breakpoints.down('xs')]: {
      '& .MuiCard-root': {
        overflow: 'auto'
      }
    },
    '& .MuiTypography-subtitle1': {
      maxHeight: 42,
      lineHeight: '20px',
      overflow: 'hidden'
    },
    '& img': {
      maxWidth: '100%',
      maxHeight: 80,
      height: 'auto',
    },
    '& a': {
      textDecoration: 'none'
    },
    '& .MuiButtonBase-root': {
      fontSize: '0.8rem',
      fontFamily: 'Montserrat',
      textTransform: 'capitalize',
      borderRadius: '30px',
      color: '#fff',
      lineHeight: '1.3',
      padding: '8px 8px',
      '&:hover': {
        background: '#fff',
        color: theme.palette.primary.main,
      }
    },
    '& .MuiTypography-root': {
      fontFamily: 'Open sans',
      fontSize: '0.8rem',
      color: '#7C909B',
    }
  },
  listItems: {
    [theme.breakpoints.up('sm')]: {
      minHeight: '30vh',
      overflow: 'auto'
    }
  },
  item: {
    [theme.breakpoints.up('sm')]: {
      '& .MuiListItem-root': {
        borderRight: '1px solid #DDD'
      }
    },
  },
  detailsRowPrice: {
    paddingTop: 20
  },
}));

const OrderFreeDetails = ({order}) => {

  const classes = useStyles();
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const user = useSelector(userSelector);
  const shippingAddressSelected = useSelector(shippingAddressSelectedSelector);

  const setCartByOrder = (orderId) => {
    dispatch(shopActions.setCartFromOrderRequest(orderId, shippingAddressSelected.country));
  };

  return (
    <Grid container spacing={2} className={classes.root}>
      <Grid item xs={12}>
        <Grid container justify={"space-between"}>
          <Grid item>
            <Grid container spacing={2} alignItems={"center"}>
              {/*<Grid item>
                <ShareCartButton order={order} variant={"contained"} color={"primary"}>
                  <Trans i18nKey={"shareThisOrder"}>Share this order</Trans>
                </ShareCartButton>
              </Grid>*/}
              {order.shippingTrackingLink && (
                <Grid item>
                  <Button
                    component={"a"}
                    target={"_blank"}
                    href={order.shippingTrackingLink}
                    color={"primary"}
                    sx={{
                      background: theme => theme.palette.primary.main,
                      fontSize: '0.8rem',
                      fontFamily: 'Montserrat',
                      textTransform: 'capitalize',
                      borderRadius: '30px',
                      color: '#fff',
                      lineHeight: '1.3',
                      padding: '8px 8px',
                      boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
                      '&:hover': {
                        background: '#fff',
                        color: theme.palette.primary.main,
                      }
                    }}>
                    <Trans i18nKey={"orderTracking"}>Order tracking</Trans>
                  </Button>
                </Grid>
              )}
              {user.id === order.userId && (
                <Grid item>
                  <Button variant={"contained"} onClick={() => setCartByOrder(order.id)}
                    color={"primary"}>
                    <Trans i18nKey={"orderAgain"}>Order again</Trans>
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item>
            {order.creditNotes.map((creditNote, i) => (
              <Box key={i}>
                <OrderCreditNote creditNote={creditNote}/>
                <br/>
              </Box>
            ))}
            <OrderProforma proforma={order.proformaInvoice}/>
          </Grid>
        </Grid>
      </Grid>
      {order.shippingStatus !== 'noShipping' && (
        <Grid item xs={12}>
          <Typography component={"i"}><Trans i18nKey={"shipping"}>Shipping</Trans>: {order.shippingAddress}</Typography>
        </Grid>
      )}
      <Grid item xs={12} sm={5}>
        <List className={classes.listItems}>
          {order.products.map((item, key) => (
            <React.Fragment key={key}>
              <ListItem className={classes.item}>
                <Grid container justify={"center"} alignItems={"center"}>
                  <Grid item xs={4}>
                    <Grid container justify={"center"} alignItems={"center"}>
                      <img
                        width={80}
                        height={80}
                        src={item.originalProduct.image[200]}
                        alt={item.originalProduct.detail.name}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={8}>
                    <Grid container justify={"center"} alignItems={"center"}>
                      <Grid item xs={8}>
                        <Typography variant="subtitle1">
                          {item.originalProduct.detail.name}
                        </Typography>
                        <Typography variant="body1">
                          <b>{item.quantity} x</b> <Trans i18nKey={"free"}>Free</Trans>
                        </Typography>
                        {item.deferredQuantity ? <i>{t('deferredShipping')} ({item.deferredQuantity}/{item.quantity})</i> : ''}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </ListItem>
              {order.products.length > 1 && (
                <Divider/>
              )}
            </React.Fragment>
          ))}
        </List>
      </Grid>
      <Divider orientation="vertical" flexItem/>
      <Grid item xs={12} sm={7}>
        <List>
          <ListItem className={classes.detailsRowPrice}>
            <Grid container justify={"center"} spacing={4}>
              <Grid item xs={12} md={8} className={classes.divider}>
                <Grid container justify={"center"} alignItems={"center"} spacing={4}>
                  <Grid item xs={7}>
                    <Grid container justify={"flex-end"} alignItems={"center"}>
                      <Typography variant="subtitle1"><Trans i18nKey={"shippingPrice"}>Shipping
                                                price</Trans></Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography align="right" variant="subtitle1">
                      <Trans i18nKey={"free"}>Free</Trans>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Divider/>
            </Grid>
          </ListItem>
          <ListItem className={classes.detailsRowPrice}>
            <Grid container justify={"center"} spacing={4}>
              <Grid item xs={12} md={8} className={classes.divider}>
                <Grid container justify={"center"} alignItems={"center"} spacing={4}>
                  <Grid item xs={7}>
                    <Grid container justify={"flex-end"} alignItems={"center"}>
                      <Typography variant="subtitle1">
                        <Trans i18nKey={"subTotal"}>
                                                    Sub total
                        </Trans>
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography align="right" variant="subtitle1">
                      <Trans i18nKey={"free"}>Free</Trans>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </ListItem>
          {Object.keys(order.taxesDetails).map(taxesRate => taxesRate > 0 && (
            <ListItem key={taxesRate} className={classes.detailsRowPrice}>
              <Grid container justify={"center"} spacing={4}>
                <Grid item xs={12} md={8} className={classes.divider}>
                  <Grid container justify={"center"} alignItems={"center"} spacing={4}>
                    <Grid item xs={7}>
                      <Grid container justify={"flex-end"} alignItems={"center"}>
                        <Typography variant="subtitle1"><Trans
                          i18nKey={"taxes"}>Taxes</Trans> {taxesRate}%</Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={5}>
                      <Typography align="right" variant="subtitle1">
                        <Trans i18nKey={"free"}>Free</Trans>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </ListItem>
          ))}
          <ListItem className={classes.detailsRowPrice}>
            <Grid container justify={"center"} spacing={4}>
              <Grid item xs={12} md={8}>
                <Grid container justify={"center"} alignItems={"center"} spacing={4}>
                  <Grid item xs={7}>
                    <Grid container justify={"flex-end"} alignItems={"center"}>
                      <Typography variant="subtitle1"><b><Trans i18nKey={"totalPrice"}>Total
                                                price</Trans></b></Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography align="right" variant="subtitle1">
                      <b><Trans i18nKey={"free"}>Free</Trans></b>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </ListItem>
        </List>
      </Grid>
    </Grid>
  );
};

export default OrderFreeDetails;
