export const GET_CAREER_PROGRESSION_REQUEST  = 'CAREER/GET_CAREER_PROGRESSION_REQUEST';
export const GET_CAREER_PROGRESSION_SUCCESS  = 'CAREER/GET_CAREER_PROGRESSION_SUCCESS';
export const GET_CAREER_PROGRESSION_ERROR    = 'CAREER/GET_CAREER_PROGRESSION_ERROR';

export const GET_CAREER_CERTIFICATION_PROGRESSION_REQUEST  = 'CAREER/GET_CAREER_CERTIFICATION_PROGRESSION_REQUEST';
export const GET_CAREER_CERTIFICATION_PROGRESSION_SUCCESS  = 'CAREER/GET_CAREER_CERTIFICATION_PROGRESSION_SUCCESS';
export const GET_CAREER_CERTIFICATION_PROGRESSION_ERROR    = 'CAREER/GET_CAREER_CERTIFICATION_PROGRESSION_ERROR';

export const VALIDATE_CERTIFICATION_CHAPTER_REQUEST   = 'CAREER/VALIDATE_CERTIFICATION_CHAPTER_REQUEST';
export const VALIDATE_CERTIFICATION_CHAPTER_SUCCESS   = 'CAREER/VALIDATE_CERTIFICATION_CHAPTER_SUCCESS';
export const VALIDATE_CERTIFICATION_CHAPTER_ERROR     = 'CAREER/VALIDATE_CERTIFICATION_CHAPTER_ERROR';

export const VALIDATE_CHAPTER_REQUEST   = 'CAREER/VALIDATE_CHAPTER_REQUEST';
export const VALIDATE_CHAPTER_SUCCESS   = 'CAREER/VALIDATE_CHAPTER_SUCCESS';
export const VALIDATE_CHAPTER_ERROR     = 'CAREER/VALIDATE_CHAPTER_ERROR';

export const CHOOSE_CAREER_REQUEST  = 'CAREER/CHOOSE_CAREER_REQUEST';
export const CHOOSE_CAREER_SUCCESS  = 'CAREER/CHOOSE_CAREER_SUCCESS';
export const CHOOSE_CAREER_ERROR    = 'CAREER/CHOOSE_CAREER_ERROR';

export const VALIDATE_CERTIFICATION_TEST_REQUEST   = 'CAREER/VALIDATE_CERTIFICATION_TEST_REQUEST';
export const VALIDATE_CERTIFICATION_TEST_SUCCESS   = 'CAREER/VALIDATE_CERTIFICATION_TEST_SUCCESS';
export const VALIDATE_CERTIFICATION_TEST_ERROR     = 'CAREER/VALIDATE_CERTIFICATION_TEST_ERROR';

export const VALIDATE_QUALIFICATION_REQUEST   = 'CAREER/VALIDATE_QUALIFICATION_REQUEST';
export const VALIDATE_QUALIFICATION_SUCCESS   = 'CAREER/VALIDATE_QUALIFICATION_SUCCESS';
export const VALIDATE_QUALIFICATION_ERROR     = 'CAREER/VALIDATE_QUALIFICATION_ERROR';
