import React, { forwardRef, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import {makeStyles} from '@mui/styles';
import classnames from "classnames";

import { FormValidatorContext } from "./FormValidator";

const useStyle = makeStyles(() => ({

  root: {
    '& .MuiFormHelperText-root': {
      color: '#f44336'
    }
  },
  fullWidth: {
    width: '100%',
  }
}));

const SelectValidator = forwardRef(({className, fullWidth, variant, id, name, label, value, onChange, required, children, sx}, ref) => {

  const {t} = useTranslation();
  const classes = useStyle();
  const { submitted, setValidationFields } = useContext(FormValidatorContext);
  const [error, setError] = useState(null);
  const [errorMessage, setErrorMessage] = useState(t("validatorFieldRequired"));

  useEffect(() => {

    validate(value);
  }, [value]);

  useEffect(() => {

    setValidationFields(fields => ({...fields, [name]:error}));
  }, [error]);

  useEffect(() =>

  //COMPONENT WILL UNMOUNT
    () => {
      setValidationFields(fields => {
        delete fields[name];
        return fields;
      });
    }
  , []);

  const validate = (targetValue) => {

    let hasError = false;
    if(required) {
      hasError = !targetValue;
      setError(hasError);
      setErrorMessage(t("validatorFieldRequired"));
    }
  };

  const handleChange = (e) => {

    validate(e.target.value);
    onChange(e);
  };

  return (
    <FormControl
      ref={ref}
      variant={variant ? variant : "outlined"}
      className={classnames(classes.root, fullWidth && classes.fullWidth, className)}
      error={submitted && error}
      sx={{...sx}}
    >
      <InputLabel>{label}</InputLabel>
      <Select
        id={id}
        name={name}
        value={value}
        onChange={handleChange}
        required={required}
        label={label}
        MenuProps={{
          disableScrollLock: true,
        }}
      >
        {children}
      </Select>
      <FormHelperText>{submitted && error && errorMessage}</FormHelperText>
    </FormControl>
  );
});

export default SelectValidator;
