import React from "react";
import {Route, Switch} from 'react-router-dom';
import loadable from "@loadable/component";

import UserLayout from "../containers/Admin/Users/UserLayout";
import {RouteSuperAdmin} from "../containers/Authentication/store/helper";

const AdminRoutes = () => {

  const Dashboard = loadable(() => import("../containers/Admin/Dashboard/Dashboard"));
  const Invoices = loadable(() => import("../containers/Admin/Accounting/Invoices"));
  const OrderForms = loadable(() => import("../containers/Admin/Accounting/OrderForms"));
  const Proformas = loadable(() => import("../containers/Admin/Accounting/Proformas"));
  const CreditNotes = loadable(() => import("../containers/Admin/Accounting/CreditNotes"));
  const Order = loadable(() => import("../containers/Admin/Accounting/Order"));
  //const CommissionsCreditNotes = loadable(() => import("../containers/Admin/Accounting/CommissionsCreditNotes"));
  const TherapistsCommissions = loadable(() => import("../containers/Admin/Accounting/Points/TherapistsPoints"));
  const DoctorsCommissions = loadable(() => import("../containers/Admin/Accounting/Points/DoctorsPoints"));
  const DelegatesCommissions = loadable(() => import("../containers/Admin/Accounting/Points/DelegatesPoints"));
  const TherapistsCommissionsArchives = loadable(() => import("../containers/Admin/Accounting/Points/TherapistsPointsArchives"));
  const DoctorsCommissionsArchives = loadable(() => import("../containers/Admin/Accounting/Points/DoctorsPointsArchives"));
  const DelegatesCommissionsArchives = loadable(() => import("../containers/Admin/Accounting/Points/DelegatesPointsArchives"));
  const BankTransfers = loadable(() => import("../containers/Admin/Accounting/BankTransfers"));
  const Checks = loadable(() => import("../containers/Admin/Accounting/Checks"));
  const CashOnDeliveries = loadable(() => import("../containers/Admin/Accounting/CashOnDeliveries"));
  const Products = loadable(() => import("../containers/Admin/Shop/Products/Products"));
  const Product = loadable(() => import("../containers/Admin/Shop/Products/Product"));
  const AddProduct = loadable(() => import("../containers/Admin/Shop/Products/AddProduct"));
  const Events = loadable(() => import("../containers/Admin/Shop/Events/Events"));
  const Event = loadable(() => import("../containers/Admin/Shop/Events/Event"));
  const AddEvent = loadable(() => import("../containers/Admin/Shop/Events/AddEvent"));
  const AddUserOrder = loadable(() => import("../containers/Admin/Users/AddUserOrder"));
  const PayUserOrder = loadable(() => import("../containers/Admin/Users/components/AddUserOrder/PayUserOrder"));
  const CheckUserOrderStatus = loadable(() => import("../containers/Admin/Users/UserOrderResponse"));
  const ProductDetails = loadable(() => import("../containers/Admin/Shop/Products/ProductDetails"));
  const EventDetails = loadable(() => import("../containers/Admin/Shop/Events/EventDetails"));
  const Categories = loadable(() => import("../containers/Admin/Shop/Categories/Categories"));
  const Category = loadable(() => import("../containers/Admin/Shop/Categories/Category"));
  const AddCategory = loadable(() => import("../containers/Admin/Shop/Categories/AddCategory"));
  const Keywords = loadable(() => import("../containers/Admin/Shop/Keywords/Keywords"));
  const Keyword = loadable(() => import("../containers/Admin/Shop/Keywords/Keyword"));
  const AddKeyword = loadable(() => import("../containers/Admin/Shop/Keywords/AddKeyword"));
  const Promotions = loadable(() => import("../containers/Admin/Shop/Promotions/Promotions"));
  const Promotion = loadable(() => import("../containers/Admin/Shop/Promotions/Promotion"));
  const AddPackaging = loadable(() => import("../containers/Admin/Shop/Packagings/AddPackaging"));
  const Packagings = loadable(() => import("../containers/Admin/Shop/Packagings/Packagings"));
  const Packaging = loadable(() => import("../containers/Admin/Shop/Packagings/Packaging"));
  const AddPromotion = loadable(() => import("../containers/Admin/Shop/Promotions/AddPromotion"));
  const Brands = loadable(() => import("../containers/Admin/Shop/Brands/Brands"));
  const Brand = loadable(() => import("../containers/Admin/Shop/Brands/Brand"));
  const AddBrand = loadable(() => import("../containers/Admin/Shop/Brands/AddBrand"));
  const Themes = loadable(() => import("../containers/Admin/Shop/Themes/Themes"));
  const Theme = loadable(() => import("../containers/Admin/Shop/Themes/Theme"));
  const AddTheme = loadable(() => import("../containers/Admin/Shop/Themes/AddTheme"));
  const Discounts = loadable(() => import("../containers/Admin/Shop/Discounts/Discounts"));
  const Discount = loadable(() => import("../containers/Admin/Shop/Discounts/Discount"));
  const AddDiscount = loadable(() => import("../containers/Admin/Shop/Discounts/AddDiscount"));
  const PostalBags = loadable(() => import("../containers/Admin/Shop/PostalBags/PostalBags"));
  const PostalBag = loadable(() => import("../containers/Admin/Shop/PostalBags/PostalBag"));
  const AddPostalBag = loadable(() => import("../containers/Admin/Shop/PostalBags/AddPostalBag"));
  const VATs = loadable(() => import("../containers/Admin/Shop/VATs/VATs"));
  const VAT = loadable(() => import("../containers/Admin/Shop/VATs/VAT"));
  const AddVAT = loadable(() => import("../containers/Admin/Shop/VATs/AddVAT"));
  const Shippings = loadable(() => import("../containers/Admin/Shop/Shipping/ShippingMethods"));
  const Shipping = loadable(() => import("../containers/Admin/Shop/Shipping/ShippingMethod"));
  const AddShipping = loadable(() => import("../containers/Admin/Shop/Shipping/AddShippingMethod"));
  const Stocks = loadable(() => import("../containers/Admin/Shop/Stocks/Stocks"));
  const CommissionCategories = loadable(() => import("../containers/Admin/Shop/CommissionCategory/CommissionCategories"));
  const CommissionCategory = loadable(() => import("../containers/Admin/Shop/CommissionCategory/CommissionCategory"));
  const AddCommissionCategory = loadable(() => import("../containers/Admin/Shop/CommissionCategory/AddCommissionCategory"));
  //const UsersMap = loadable(() => import("../containers/Admin/Users/UsersMap"));
  const AddUser = loadable(() => import("../containers/Admin/Users/AddUser"));
  // const NetworkTree = loadable(() => import("../containers/Admin/Users/NetworkTree"));
  // const NetworkTable = loadable(() => import("../containers/Admin/Users/NetworkTable"));
  //const ShopStats = loadable(() => import("../containers/Admin/Stats/Shop"));
  // const NetworkStats = loadable(() => import("../containers/Admin/Stats/Network"));
  //const StocksStats = loadable(() => import("../containers/Admin/Stats/Stocks"));
  //const SalesStats = loadable(() => import("../containers/Admin/Stats/Sales"));
  const MailingCreator = loadable(() => import("../containers/Admin/Mailings/MailingCreator"));
  const MailingDraft = loadable(() => import("../containers/Admin/Mailings/MailingDraft"));
  const MailingsDraft = loadable(() => import("../containers/Admin/Mailings/MailingsDraft"));
  const MailingQueued = loadable(() => import("../containers/Admin/Mailings/MailingQueued"));
  const MailingsQueued = loadable(() => import("../containers/Admin/Mailings/MailingsQueued"));
  const MailingCompleted = loadable(() => import("../containers/Admin/Mailings/MailingCompleted"));
  const MailingsCompleted = loadable(() => import("../containers/Admin/Mailings/MailingsCompleted"));
  const ReportUsers = loadable(() => import("../containers/Admin/Reports/Users"));
  const ReportOrders = loadable(() => import("../containers/Admin/Reports/Orders"));
  const ReportStocks = loadable(() => import("../containers/Admin/Stats/Stocks"));
  const CaCharts = loadable(() => import("../containers/Admin/Reports/CaCharts"));
  const Errors = loadable(() => import("../containers/Admin/Error/Errors"));
  const Logs = loadable(() => import("../containers/Admin/Logs/Logs"));
  const Error404 = loadable(() => import("../containers/Pro/Error"));

  return (
    <Switch>
      <Route exact path="/admin" component={Dashboard}/>
      <RouteSuperAdmin exact path="/admin/accounting/points/therapists" component={TherapistsCommissions}/>
      <RouteSuperAdmin exact path="/admin/accounting/points/doctors" component={DoctorsCommissions}/>
      <RouteSuperAdmin exact path="/admin/accounting/points/delegates" component={DelegatesCommissions}/>
      <RouteSuperAdmin exact path="/admin/accounting/points/therapists/archives" component={TherapistsCommissionsArchives}/>
      <RouteSuperAdmin exact path="/admin/accounting/points/doctors/archives" component={DoctorsCommissionsArchives}/>
      <RouteSuperAdmin exact path="/admin/accounting/points/delegates/archives" component={DelegatesCommissionsArchives}/>
      <RouteSuperAdmin exact path="/admin/accounting/invoices" component={Invoices}/>
      <RouteSuperAdmin exact path="/admin/accounting/orderForms" component={OrderForms}/>
      <RouteSuperAdmin exact path="/admin/accounting/proformas" component={Proformas}/>
      <RouteSuperAdmin exact path="/admin/accounting/creditNotes" component={CreditNotes}/>
      <RouteSuperAdmin exact path="/admin/accounting/order/:orderId" component={Order}/>
      <RouteSuperAdmin exact path="/admin/accounting/transfers/:type" component={BankTransfers}/>
      <RouteSuperAdmin exact path="/admin/accounting/checks" component={Checks}/>
      <RouteSuperAdmin exact path="/admin/accounting/cash-on-delivery" component={CashOnDeliveries}/>
      <Route exact path="/admin/shop/categories" component={Categories}/>
      <Route exact path="/admin/shop/category/add" component={AddCategory}/>
      <Route exact path="/admin/shop/category/:categoryId" component={Category}/>
      <Route exact path="/admin/shop/keywords" component={Keywords}/>
      <Route exact path="/admin/shop/keyword/add" component={AddKeyword}/>
      <Route exact path="/admin/shop/keyword/:keywordId" component={Keyword}/>
      <Route exact path="/admin/shop/brands" component={Brands}/>
      <Route exact path="/admin/shop/brand/add" component={AddBrand}/>
      <Route exact path="/admin/shop/brand/:brandId" component={Brand}/>
      <Route exact path="/admin/shop/themes" component={Themes}/>
      <Route exact path="/admin/shop/theme/add" component={AddTheme}/>
      <Route exact path="/admin/shop/theme/:themeId" component={Theme}/>
      <Route exact path="/admin/shop/discounts" component={Discounts}/>
      <Route exact path="/admin/shop/discount/add" component={AddDiscount}/>
      <Route exact path="/admin/shop/discount/:discountId" component={Discount}/>
      <Route exact path="/admin/shop/vats" component={VATs}/>
      <Route exact path="/admin/shop/vat/add" component={AddVAT}/>
      <Route exact path="/admin/shop/vat/:vatId" component={VAT}/>
      <Route exact path="/admin/systems/discounts" component={Discounts}/>
      <Route exact path="/admin/systems/discount/add" component={AddDiscount}/>
      <Route exact path="/admin/systems/discount/:discountId" component={Discount}/>
      <Route exact path="/admin/systems/bags" component={PostalBags}/>
      <Route exact path="/admin/systems/bag/add" component={AddPostalBag}/>
      <Route exact path="/admin/systems/bag/:bagId" component={PostalBag}/>
      <Route exact path="/admin/systems/vats" component={VATs}/>
      <Route exact path="/admin/systems/vat/add" component={AddVAT}/>
      <Route exact path="/admin/systems/vat/:vatId" component={VAT}/>
      <Route exact path="/admin/shop/packagings" component={Packagings}/>
      <Route exact path="/admin/shop/packaging/add" component={AddPackaging}/>
      <Route exact path="/admin/shop/packaging/:packagingId" component={Packaging}/>
      <Route exact path="/admin/systems/shippings" component={Shippings}/>
      <Route exact path="/admin/systems/shipping/add" component={AddShipping}/>
      <Route exact path="/admin/systems/shipping/:methodId" component={Shipping}/>
      <Route exact path="/admin/shop/products" component={Products}/>
      <Route exact path="/admin/shop/product/add" component={AddProduct}/>
      <Route exact path="/admin/shop/events" component={Events}/>
      <Route exact path="/admin/shop/event/add" component={AddEvent}/>
      <Route exact path="/admin/shop/stocks" component={Stocks}/>
      <Route exact path="/admin/shop/product/:productId/details/:productLang" component={ProductDetails}/>
      <Route exact path="/admin/shop/event/:productId/details/:productLang" component={EventDetails}/>
      <Route exact path="/admin/shop/product/:productId" component={Product}/>
      <Route exact path="/admin/shop/event/:productId" component={Event}/>
      <Route exact path="/admin/systems/promotions" component={Promotions}/>
      <Route exact path="/admin/systems/promotion/add" component={AddPromotion}/>
      <Route exact path="/admin/systems/promotion/:promotionId" component={Promotion}/>
      <Route exact path="/admin/systems/commissions" component={CommissionCategories}/>
      <Route exact path="/admin/systems/commission/add" component={AddCommissionCategory}/>
      <Route exact path="/admin/systems/commission/:commissionCategoryId" component={CommissionCategory}/>
      <RouteSuperAdmin exact path="/admin/users/add" component={AddUser}/>
      <RouteSuperAdmin exact path="/admin/users/order/add" component={AddUserOrder}/>
      <RouteSuperAdmin exact path="/admin/users/order/add/:userId" component={AddUserOrder}/>
      <RouteSuperAdmin exact path="/admin/users/order/:orderId/pay" component={PayUserOrder}/>
      <RouteSuperAdmin exact path="/admin/users/order/:orderId/verify" component={CheckUserOrderStatus}/>
      <RouteSuperAdmin exact path="/admin/users/:userId" component={UserLayout}/>
      <RouteSuperAdmin exact path="/admin/users/:userId/*" component={UserLayout}/>
      {/* <Route exact path="/admin/stats/shop" component={ShopStats}/>
      <Route exact path="/admin/stats/stocks" component={StocksStats}/>
      <Route exact path="/admin/stats/sales" component={SalesStats}/>*/}
      <Route exact path="/admin/reports/users" component={ReportUsers}/>
      <Route exact path="/admin/reports/orders" component={ReportOrders}/>
      <Route exact path="/admin/reports/cahtva" component={CaCharts}/>
      <Route exact path="/admin/reports/stocks" component={ReportStocks}/>
      <RouteSuperAdmin exact path="/admin/mailings/creator" component={MailingCreator}/>
      <RouteSuperAdmin exact path="/admin/mailings/draft" component={MailingsDraft}/>
      <RouteSuperAdmin exact path="/admin/mailings/draft/:mailingId" component={MailingDraft}/>
      <RouteSuperAdmin exact path="/admin/mailings/queued" component={MailingsQueued}/>
      <RouteSuperAdmin exact path="/admin/mailings/queued/:mailingId" component={MailingQueued}/>
      <RouteSuperAdmin exact path="/admin/mailings/completed" component={MailingsCompleted}/>
      <RouteSuperAdmin exact path="/admin/mailings/completed/:mailingId" component={MailingCompleted}/>
      <Route exact path="/admin/errors" component={Errors}/>
      <RouteSuperAdmin exact path="/admin/logs/:level" component={Logs}/>
      <Route path="*" component={Error404}/>
    </Switch>
  );
};

export default AdminRoutes;
