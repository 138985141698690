import React  from "react";
import ReactGA from "react-ga4";
import {useDispatch, useSelector} from "react-redux";
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import {Box} from "@mui/material";
import Badge from "@mui/material/Badge";
import IconButton from "@mui/material/IconButton";
import NoSsr from "@mui/material/NoSsr";

import { Link } from "../../../../../helpers/multilingual";
import {getProductPriceWithTaxes} from "../../../../../helpers/utils";
import {userSelector} from "../../../../Authentication/store/selectors";
import { shopActions } from "../../store/actions";
import {
  countItemsFromCartSelector,
  itemsFromCartSelector,
  sessionSelector
} from "../../store/selectors";

import CartModal from "./CartModal";

const CartIcon = () => {
  const session = useSelector(sessionSelector);
  const count = useSelector(countItemsFromCartSelector);
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const itemsFromCart = useSelector(itemsFromCartSelector);

  const handleOpenCart = () => {
    dispatch(shopActions.SetDrawersOpen("checkout", true));

    if(Object.keys(itemsFromCart).length > 0) {
      ReactGA.event("view_cart", {
        user_id: user?.id,
        items: Object.keys(itemsFromCart).map(item =>
          ({
            item_id: itemsFromCart[item]?.id,
            item_name: itemsFromCart[item]?.reference,
            price: getProductPriceWithTaxes(itemsFromCart[item], user, user?.shippingAddress, 1, true),
            quantity: itemsFromCart[item]?.quantity
          })
        )
      });
    }
  };

  return (
    <Box>
      {(session && count > 0) && (
        <NoSsr>
          <Badge
            color="primary"
            sx={{
              cursor:"pointer",
              '& .MuiBadge-badge': {
                color: 'white',
              }
            }}
            badgeContent={count}
            onClick={handleOpenCart}
          >
            <ShoppingCartOutlinedIcon fontSize={"small"}/>
          </Badge>

          <CartModal/>
        </NoSsr>
      )}
      {(!session || count === 0) && (
        <IconButton component={Link} to={'/shop'}>
          <ShoppingCartOutlinedIcon fontSize={"small"}/>
        </IconButton>
      )}
    </Box>
  );
};

export default CartIcon;
