import * as actionsType from './constants';

export const magActions = {
  getArticleRequest,
  getArticleSuccess,
  getArticleFail,
  getArticlesRequest,
  getArticlesSuccess,
  getArticlesFail,
};

export function getArticleRequest(url, lang) {

  return {
    type: actionsType.GET_ARTICLE_REQUEST,
    url, lang
  };
}

export function getArticleSuccess(article) {

  return {
    type: actionsType.GET_ARTICLE_SUCCESS,
    article
  };
}

export function getArticleFail() {

  return {
    type: actionsType.GET_ARTICLES_FAILED
  };
}

export function getArticlesRequest(lang) {

  return {
    type: actionsType.GET_ARTICLES_REQUEST,
    lang
  };
}

export function getArticlesSuccess(articles) {

  return {
    type: actionsType.GET_ARTICLES_SUCCESS,
    articles
  };
}

export function getArticlesFail() {

  return {
    type: actionsType.GET_ARTICLES_FAILED
  };
}
