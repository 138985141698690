import produce from "immer";

import * as actions from './constants';
export const initialState = {
  directTurnoverByMonth : {},
  directRecurringTurnoverByMonth : {},
  networkTurnoverByMonth : {},
  networkRecurringSales : {},
  directRecruitment : {},
  networkRecruitment : {},
  directBestSellersAmount : {},
  networkBestSellersAmount : {},
  directBestRecruitersAmount : {},
  networkBestRecruitersAmount : {},
  directPacksAmount : {},
  networkPacksAmount : {},
  directProductsAmount : {},
  networkProductsAmount : {},
  networkTurnoverByCareer : {},
  networkActiveProsCountByMonth : {},
};

export const proStatsReducerKey = 'proStats';

export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case actions.GET_DIRECT_SALES_REQUEST:
        break;
      case actions.GET_DIRECT_SALES_SUCCESS:
        draft.directTurnoverByMonth[action.userId] = action.data;
        break;
      case actions.GET_DIRECT_SALES_ERROR:
        draft.error = action.error;
        break;
      case actions.GET_DIRECT_RECURRING_SALES_REQUEST:
        break;
      case actions.GET_DIRECT_RECURRING_SALES_SUCCESS:
        draft.directRecurringTurnoverByMonth[action.userId] = action.data;
        break;
      case actions.GET_DIRECT_RECURRING_SALES_ERROR:
        draft.error = action.error;
        break;
      case actions.GET_NETWORK_SALES_REQUEST:
        break;
      case actions.GET_NETWORK_SALES_SUCCESS:
        draft.networkTurnoverByMonth[action.userId] = action.data;
        break;
      case actions.GET_NETWORK_SALES_ERROR:
        draft.error = action.error;
        break;
      case actions.GET_NETWORK_RECURRING_SALES_REQUEST:
        break;
      case actions.GET_NETWORK_RECURRING_SALES_SUCCESS:
        draft.networkRecurringSales[action.userId] = action.data;
        break;
      case actions.GET_NETWORK_RECURRING_SALES_ERROR:
        draft.error = action.error;
        break;
      case actions.GET_DIRECT_RECRUITMENT_REQUEST:
        break;
      case actions.GET_DIRECT_RECRUITMENT_SUCCESS:
        draft.directRecruitment[action.userId] = action.data;
        break;
      case actions.GET_DIRECT_RECRUITMENT_ERROR:
        draft.error = action.error;
        break;
      case actions.GET_NETWORK_RECRUITMENT_REQUEST:
        break;
      case actions.GET_NETWORK_RECRUITMENT_SUCCESS:
        draft.networkRecruitment[action.userId] = action.data;
        break;
      case actions.GET_NETWORK_RECRUITMENT_ERROR:
        draft.error = action.error;
        break;
      case actions.GET_DIRECT_BESTSELLERS_AMOUNT_REQUEST:
        if(!state.directBestSellersAmount[action.userId]) {
          draft.directBestSellersAmount[action.userId] = {
            [action.year]: {
              [action.month]: null
            }
          };
        }
        else if(!state.directBestSellersAmount[action.userId][action.year]) {
          draft.directBestSellersAmount[action.userId][action.year] = {
            [action.month]: null
          };
        }
        break;
      case actions.GET_DIRECT_BESTSELLERS_AMOUNT_SUCCESS:
        draft.directBestSellersAmount[action.userId][action.year][action.month] = action.data;
        break;
      case actions.GET_DIRECT_BESTSELLERS_AMOUNT_ERROR:
        draft.error = action.error;
        break;
      case actions.GET_DIRECT_BEST_RECRUITERS_AMOUNT_REQUEST:
        if(!state.directBestRecruitersAmount[action.userId]) {
          draft.directBestRecruitersAmount[action.userId] = {
            [action.year]: {
              [action.month]: null
            }
          };
        }
        else if(!state.directBestRecruitersAmount[action.userId][action.year]) {
          draft.directBestRecruitersAmount[action.userId][action.year] = {
            [action.month]: null
          };
        }
        break;
      case actions.GET_DIRECT_BEST_RECRUITERS_AMOUNT_SUCCESS:
        draft.directBestRecruitersAmount[action.userId][action.year][action.month] = action.data;
        break;
      case actions.GET_DIRECT_BEST_RECRUITERS_AMOUNT_ERROR:
        draft.error = action.error;
        break;
      case actions.GET_NETWORK_BESTSELLERS_AMOUNT_REQUEST:
        if(!state.networkBestSellersAmount[action.userId]) {
          draft.networkBestSellersAmount[action.userId] = {
            [action.year]: {
              [action.month]: null
            }
          };
        }
        else if(!state.networkBestSellersAmount[action.userId][action.year]) {
          draft.networkBestSellersAmount[action.userId][action.year] = {
            [action.month]: null
          };
        }
        break;
      case actions.GET_NETWORK_BESTSELLERS_AMOUNT_SUCCESS:
        draft.networkBestSellersAmount[action.userId][action.year][action.month] = action.data;
        break;
      case actions.GET_NETWORK_BESTSELLERS_AMOUNT_ERROR:
        draft.error = action.error;
        break;
      case actions.GET_NETWORK_BEST_RECRUITERS_AMOUNT_REQUEST:
        if(!state.networkBestRecruitersAmount[action.userId]) {
          draft.networkBestRecruitersAmount[action.userId] = {
            [action.year]: {
              [action.month]: null
            }
          };
        }
        else if(!state.networkBestRecruitersAmount[action.userId][action.year]) {
          draft.networkBestRecruitersAmount[action.userId][action.year] = {
            [action.month]: null
          };
        }
        break;
      case actions.GET_NETWORK_BEST_RECRUITERS_AMOUNT_SUCCESS:
        draft.networkBestRecruitersAmount[action.userId][action.year][action.month] = action.data;
        break;
      case actions.GET_NETWORK_BEST_RECRUITERS_AMOUNT_ERROR:
        draft.error = action.error;
        break;
      case actions.GET_DIRECT_PACKS_AMOUNT_REQUEST:
        if(!state.directPacksAmount[action.userId]) {
          draft.directPacksAmount[action.userId] = {
            [action.year]: {
              [action.month]: null
            }
          };
        }
        else if(!state.directPacksAmount[action.userId][action.year]) {
          draft.directPacksAmount[action.userId][action.year] = {
            [action.month]: null
          };
        }
        break;
      case actions.GET_DIRECT_PACKS_AMOUNT_SUCCESS:
        draft.directPacksAmount[action.userId][action.year][action.month] = action.data;
        break;
      case actions.GET_DIRECT_PACKS_AMOUNT_ERROR:
        draft.error = action.error;
        break;
      case actions.GET_NETWORK_PACKS_AMOUNT_REQUEST:
        if(!state.networkPacksAmount[action.userId]) {
          draft.networkPacksAmount[action.userId] = {
            [action.year]: {
              [action.month]: null
            }
          };
        }
        else if(!state.networkPacksAmount[action.userId][action.year]) {
          draft.networkPacksAmount[action.userId][action.year] = {
            [action.month]: null
          };
        }
        break;
      case actions.GET_NETWORK_PACKS_AMOUNT_SUCCESS:
        draft.networkPacksAmount[action.userId][action.year][action.month] = action.data;
        break;
      case actions.GET_NETWORK_PACKS_AMOUNT_ERROR:
        draft.error = action.error;
        break;
      case actions.GET_DIRECT_PRODUCTS_AMOUNT_REQUEST:
        if(!state.directProductsAmount[action.userId]) {
          draft.directProductsAmount[action.userId] = {
            [action.year]: {
              [action.month]: null
            }
          };
        }
        else if(!state.directProductsAmount[action.userId][action.year]) {
          draft.directProductsAmount[action.userId][action.year] = {
            [action.month]: null
          };
        }
        break;
      case actions.GET_DIRECT_PRODUCTS_AMOUNT_SUCCESS:
        draft.directProductsAmount[action.userId][action.year][action.month] = action.data;
        break;
      case actions.GET_DIRECT_PRODUCTS_AMOUNT_ERROR:
        draft.error = action.error;
        break;
      case actions.GET_NETWORK_PRODUCTS_AMOUNT_REQUEST:
        if(!state.networkProductsAmount[action.userId]) {
          draft.networkProductsAmount[action.userId] = {
            [action.year]: {
              [action.month]: null
            }
          };
        }
        else if(!state.networkProductsAmount[action.userId][action.year]) {
          draft.networkProductsAmount[action.userId][action.year] = {
            [action.month]: null
          };
        }
        break;
      case actions.GET_NETWORK_PRODUCTS_AMOUNT_SUCCESS:
        draft.networkProductsAmount[action.userId][action.year][action.month] = action.data;
        break;
      case actions.GET_NETWORK_PRODUCTS_AMOUNT_ERROR:
        draft.error = action.error;
        break;
      case actions.GET_NETWORK_TURNOVER_BY_CAREER_REQUEST:
        if(!state.networkTurnoverByCareer[action.userId]) {
          draft.networkTurnoverByCareer[action.userId] = {
            [action.year]: {
              [action.month]: null
            }
          };
        }
        else if(!state.networkTurnoverByCareer[action.userId][action.year]) {
          draft.networkTurnoverByCareer[action.userId][action.year] = {
            [action.month]: null
          };
        }
        break;
      case actions.GET_NETWORK_TURNOVER_BY_CAREER_SUCCESS:
        draft.networkTurnoverByCareer[action.userId][action.year][action.month] = action.data;
        break;
      case actions.GET_NETWORK_TURNOVER_BY_CAREER_ERROR:
        draft.error = action.error;
        break;
      case actions.GET_NETWORK_ACTIVE_PROS_COUNT_BY_MONTH_REQUEST:
        if(!state.networkActiveProsCountByMonth[action.userId]) {
          draft.networkActiveProsCountByMonth[action.userId] = null;
        }
        break;
      case actions.GET_NETWORK_ACTIVE_PROS_COUNT_BY_MONTH_SUCCESS:
        draft.networkActiveProsCountByMonth[action.userId] = action.data;
        break;
      case actions.GET_NETWORK_ACTIVE_PROS_COUNT_BY_MONTH_ERROR:
        draft.error = action.error;
        break;
      default:
    }
  }
  );
