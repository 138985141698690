import React from 'react';
import { Link } from "react-router-dom";
import HomeIcon from '@mui/icons-material/Home';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import {makeStyles} from '@mui/styles';

const useStyleBreadcrumb = makeStyles(() => ({
  root: {
    '& a': {
      color: 'inherit',
      textDecoration: 'none'
    }
  },
}));

const Breadcrumb = ({parents}) => {
  const classes = useStyleBreadcrumb();

  const links = parents.map((link, key) => {
    if(link.uri) return (
      <Link key={key} to={link.uri}>
        {link.name}
      </Link>
    ); else return (
      <Typography key={key} color="textPrimary">{link.name}</Typography>
    );
  });

  return (
    <Breadcrumbs aria-label="breadcrumb" className={classes.root}>
      <Link to="/admin">
        <HomeIcon className={classes.icon} />
      </Link>
      {links}
    </Breadcrumbs>
  );
};

export default Breadcrumb;
