import React, { useContext } from "react";
import Badge from "@mui/material/Badge";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import { Link } from "../../../helpers/multilingual";

import { MenuContext } from "./Menu";

const MenuSubItem = ({title, icon, notificationsCount = 0, to, expandTo, hidden, setMobileOpen, sx, disabled, onClick}) => {

  const { pathname, onItemSelected } = useContext(MenuContext);

  const isActive = pathname === to || (expandTo && pathname.includes(expandTo));

  const handleClick = () => {
    if(setMobileOpen) setMobileOpen(false);
    if(onItemSelected) onItemSelected();
    if(onClick) onClick();
  };

  if(hidden) {
    return null;
  }

  return (
    <ListItemButton className={isActive ? 'active' : 'inactive'} onClick={handleClick} component={Link} to={to} sx={sx} disabled={disabled}>
      <ListItemIcon>
        {!!notificationsCount && (
          <Badge
            color="error"
            badgeContent={notificationsCount}
          >
            {icon}
          </Badge>
        )}
        {!notificationsCount && icon}
      </ListItemIcon>
      <ListItemText primary={title} />
    </ListItemButton>
  );
};
export default MenuSubItem;
