import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {Route, Switch} from "react-router-dom";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {makeStyles} from '@mui/styles';

import Breadcrumb from "../../../components/Layout/Pro/Breadcrumb";
import PageLoader from "../../../components/PageLoader";

import CustomerActionsCard from "./components/CustomerActionsCard";
import { customersActions } from "./store/actions";
import { customerSelector } from "./store/selectors";
import Customer from "./Customer";
import CustomerGiftCards from "./CustomerGiftCards";
import CustomerOrderCheckout from "./CustomerOrderCheckout";
import CustomerOrders from "./CustomerOrders";
import UpdateCustomer from "./UpdateCustomer";
import UpdateCustomerShippingAddresses from "./UpdateCustomerShippingAddresses";

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(2)
  },
}));

const CustomerLayout = (props) => {

  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const customer = useSelector(customerSelector());
  console.log(customer);
  useEffect(() => {
    if(!customer || customer.id !== props.match.params.customerId) {
      dispatch(customersActions.getCustomerRequest(props.match.params.customerId));
    }
  }, [props.match.params.customerId]);

  if(!customer || customer.id !== props.match.params.customerId) {
    return (
      <PageLoader/>
    );
  }

  return (
    <div>
      <Helmet>
        <title>{`${customer.firstName} ${customer.lastName}`}</title>
      </Helmet>
      <Container fixed maxWidth="xl">
        <div className={classes.title}>
          <Breadcrumb parents={[
            {name: t("customers"), uri: '/customers'},
            {name: `${customer.firstName} ${customer.lastName}`}
          ]}/>
          <Typography component="h1" variant="h4">
            {customer.firstName} {customer.lastName}
          </Typography>
        </div>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
            <CustomerActionsCard customer={customer}/>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
            <Switch>
              <Route exact path="/:lang(en|fr|nl)?/pro/customer/:customerId/update/shipping" component={UpdateCustomerShippingAddresses}/>
              <Route exact path="/:lang(en|fr|nl)?/pro/customer/:customerId/update" component={UpdateCustomer}/>
              <Route exact path="/:lang(en|fr|nl)?/pro/customer/:customerId/orders" component={CustomerOrders}/>
              <Route exact path="/:lang(en|fr|nl)?/pro/customer/:customerId/order/:recommendationId" component={CustomerOrderCheckout}/>
              <Route exact path="/:lang(en|fr|nl)?/pro/customer/:customerId/gifts" component={CustomerGiftCards}/>
              <Route exact path="/:lang(en|fr|nl)?/pro/customer/:customerId" component={Customer}/>
            </Switch>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};
export default CustomerLayout;
