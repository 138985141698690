import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {makeStyles} from '@mui/styles';

const useStyles = makeStyles(() => ({
  root: {
    minHeight: 300
  },
}));

const NetworkRequestTest = ({test}) => {

  const classes = useStyles();
  const { t } = useTranslation();
  const [formattedTest, setFormattedTest] = useState([]);

  useEffect(() => {
    const data = JSON.parse(test);
    let profilingAnswers = [];
    Object.keys(data).map(datum => {

      let answer = data[datum];
      if(typeof answer === 'object') {
        let answers = [];
        answer = Object.keys(answer).map(key => {
          if(answer[key]) {
            answers.push(t(key));
          }
        });
        answer = answers.join(', ');
      }

      profilingAnswers.push({
        label: datum,
        answer: answer
      });
    });
    setFormattedTest(profilingAnswers);
  }, [test]);

  if(formattedTest.length === 0) {
    return null;
  }
  return (
    <Card className={classes.root}>
      <CardContent>
        <Grid container justify="center" spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Grid container justify={"space-between"} alignItems={"center"}>
              <Grid item>
                <Typography component="h3" variant={"h6"} gutterBottom>
                  <Trans i18nKey={"profilingTitle"}>Profiling</Trans> :
                </Typography>
              </Grid>
            </Grid>
            {formattedTest.map(data => (
              <Typography component={"p"} variant="body1" gutterBottom>
                <Typography component={"b"} variant={"b"}>{t(data.label)}</Typography>
                <Typography>{t(data.answer)}</Typography>
              </Typography>
            ))}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
export default NetworkRequestTest;
