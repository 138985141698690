import React from 'react';
import {Grid, Link as MuiLink, Popper, Stack, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";

const section = {
  "name": "Support Team",
  "type": "banner",
  "bannerTitle1": "Une question",
  "bannerTitle2": "un conseil ?",
  "bannerText": "Nous sommes à votre écoute",
  "bannerLink": "/",
  "bannerImage": `${process.env.REACT_APP_API_RESOURCES_URL}/sections/images/Illu_AvatarSupport.png`
};
export default function CustomSectionSupportBanner() {

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    if(event.currentTarget === anchorEl) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  return (
    <Box
      sx={{
        width: '100%',
        margin: '40px 0 60px',
        padding: '1px 0',
        overflowX: 'clip'
      }}>
      <Container
        maxWidth={'xxl'}>
        <Grid container spacing={2} flexDirection={"row"} alignItems={"center"} alignContent={"center"}
          sx={{
            ml: '-8px !important',
            '& img': {
              width: '100%',
              height: 'auto',
              borderRadius: '50%',
            }
          }}>
          <Grid item xs={12} sm={6}>
            <Grid container justifyContent={"center"}>
              <Grid item xs={4} sm={3} md={3}>
                <Stack gap={1} alignItems={"center"} alignContent={"center"}>
                  <img src={`${process.env.REACT_APP_API_RESOURCES_URL}/images/avatars/Avatar_Renaud.png`} alt={"Renaud"}/>
                  <Typography sx={{
                    color: 'primary.light',
                    fontFamily: 'Montserrat',
                    fontWeight: 'bold',
                    fontSize: {xs: '0.8rem', sm: '0.9rem', md: '1rem', lg: '1.1rem', xl: '1.2rem', xxl: '1.2rem'}
                  }}>
                      Renaud
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={4} sm={3} md={3}>
                <Stack gap={1} alignItems={"center"} alignContent={"center"}>
                  <img src={`${process.env.REACT_APP_API_RESOURCES_URL}/images/avatars/Avatar_ThibaultKaiser.png`} alt={"Thibault"}/>
                  <Typography sx={{
                    color: 'primary.light',
                    fontFamily: 'Montserrat',
                    fontWeight: 'bold',
                    fontSize: {xs: '0.8rem', sm: '0.9rem', md: '1rem', lg: '1.1rem', xl: '1.2rem', xxl: '1.2rem'}
                  }}>
                      Thibault
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={4} sm={3} md={3}>
                <Stack gap={1} alignItems={"center"} alignContent={"center"}>
                  <img src={`${process.env.REACT_APP_API_RESOURCES_URL}/images/avatars/Avatar_Eric.png`} alt={"Eric"}/>
                  <Typography sx={{
                    color: 'primary.light',
                    fontFamily: 'Montserrat',
                    fontWeight: 'bold',
                    fontSize: {xs: '0.8rem', sm: '0.9rem', md: '1rem', lg: '1.1rem', xl: '1.2rem', xxl: '1.2rem'}
                  }}>
                      Eric
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={4} sm={3} md={3}>
                <Stack gap={1} alignItems={"center"} alignContent={"center"}>
                  <img src={`${process.env.REACT_APP_API_RESOURCES_URL}/images/avatars/Avatar_Stephanie.png`} alt={"Stéphanie"}/>
                  <Typography sx={{
                    color: 'primary.light',
                    fontFamily: 'Montserrat',
                    fontWeight: 'bold',
                    fontSize: {xs: '0.8rem', sm: '0.9rem', md: '1rem', lg: '1.1rem', xl: '1.2rem', xxl: '1.2rem'}
                  }}>
                      Stéphanie
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={4} sm={3} md={3}>
                <Stack gap={1} alignItems={"center"} alignContent={"center"}>
                  <img src={`${process.env.REACT_APP_API_RESOURCES_URL}/images/avatars/Avatar_Alex.png`} alt={"Alexandra"}/>
                  <Typography sx={{
                    color: 'primary.light',
                    fontFamily: 'Montserrat',
                    fontWeight: 'bold',
                    fontSize: {xs: '0.8rem', sm: '0.9rem', md: '1rem', lg: '1.1rem', xl: '1.2rem', xxl: '1.2rem'}
                  }}>
                      Alexandra
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={4} sm={3} md={3}>
                <Stack gap={1} alignItems={"center"} alignContent={"center"}>
                  <img src={`${process.env.REACT_APP_API_RESOURCES_URL}/images/avatars/Avatar_Anne.png`} alt={"Anne"}/>
                  <Typography sx={{
                    color: 'primary.light',
                    fontFamily: 'Montserrat',
                    fontWeight: 'bold',
                    fontSize: {xs: '0.8rem', sm: '0.9rem', md: '1rem', lg: '1.1rem', xl: '1.2rem', xxl: '1.2rem'}
                  }}>
                      Anne
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={4} sm={3} md={3}>
                <Stack gap={1} alignItems={"center"} alignContent={"center"}>
                  <img src={`${process.env.REACT_APP_API_RESOURCES_URL}/images/avatars/Avatar_Samuel.png`} alt={"Samuel"}/>
                  <Typography sx={{
                    color: 'primary.light',
                    fontFamily: 'Montserrat',
                    fontWeight: 'bold',
                    fontSize: {xs: '0.8rem', sm: '0.9rem', md: '1rem', lg: '1.1rem', xl: '1.2rem', xxl: '1.2rem'}
                  }}>
                      Samuel
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} sx={{pl: '24px', pr: '24px'}}>
            <Stack gap={2} sx={{pt: '3vw', pb: '3vw'}} alignItems={'center'}>
              <Typography
                sx={{
                  fontFamily: 'Raleway',
                  fontWeight: '600',
                  padding: '8px 8px',
                  fontSize: {xs: '2.5rem', sm: '2rem', md: '2.5rem', lg: '3.5rem', xl: '3.5rem', xxl: '4.5rem'},
                  lineHeight: '0.7',
                  overflow: 'hidden',
                  color: 'green',
                  textAlign: {xs: 'center', sm: 'center'},
                  zIndex: 1,
                  '& p': {
                    margin: 0,
                  },
                  '& span': {
                    fontFamily: 'Raleway',
                    fontWeight: '600',
                    fontSize: {xs: '2.5rem', sm: '2rem', md: '2.5rem', lg: '3.5rem', xl: '3.5rem', xxl: '4.5rem'},
                  }
                }}>
                {section.bannerTitle1} <br/> <Typography component={"span"} sx={{color: 'primary.main'}}>{section.bannerTitle2}</Typography>
              </Typography>

              <Typography
                sx={{
                  fontSize: {xs: '1rem', sm: '0.9rem', md: '1rem', lg: '1.1rem', xl: '1.2rem', xxl: '1.3rem'},
                  padding: '0 8px 8px 8px',
                  fontFamily: 'Open Sans',
                  maxWidth: {xs: '100%', sm: '100%'},
                  lineHeight: '1.4',
                  overflow: 'hidden',
                  textAlign: 'center',
                  color: 'primary.light',
                  zIndex: 1,
                  '& p': {
                    margin: 0,
                  }
                }}>
                {section.bannerText}
              </Typography>
              <Stack direction={"row"} gap={2} justifyContent={"center"} sx={{width: '100%'}}>
                <Button
                  component={"a"}
                  href={'mailto:info@nutri-logics.com'}
                  color={'primary'}
                  variant={'contained'}
                  sx={{
                    width: '120px',
                    borderRadius: '30px',
                    color: '#FFFFFF',
                  }}
                >
                    E-MAIL
                </Button>
                <Button
                  onClick={handleClick}
                  color={'primary'}
                  variant={'contained'}
                  sx={{
                    width: '120px',
                    borderRadius: '30px',
                    color: '#FFFFFF',
                  }}
                >
                    TELEPHONE
                </Button>
                <Popper
                  anchorEl={anchorEl}
                  open={open}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  sx={{
                    p: 2
                  }}
                >
                  <Card sx={{
                    boxShadow: '6px 6px 12px #00000021',
                    p: 1
                  }}>
                    <CardContent>
                      <Stack gap={2} alignContent={"flex-start"} alignItems={"flex-start"}>
                        <Typography component="p" variant="body2">
                          <MuiLink href="tel:+35228339898" color={"primary.light"}>
                          +352 28 33 98 98
                          </MuiLink>
                        </Typography>
                        <Typography component="p" variant="body2">
                          <MuiLink href="tel:+3278709020" color={"primary.light"}>
                          +32 (0) 78 70 90 20
                          </MuiLink>
                        </Typography>
                        <Typography component="p" variant="body2">
                          <MuiLink href="tel:+33970441645" color={"primary.light"}>
                          +33 (0)9 70 44 16 45
                          </MuiLink>
                        </Typography>
                      </Stack>
                    </CardContent>
                  </Card>
                </Popper>
              </Stack>
            </Stack>
          </Grid>

        </Grid>
      </Container>
    </Box>
  );
}
