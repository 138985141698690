import React from 'react';
import {Grid, Stack, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";

const section = {
  "name": "About section 4",
  "type": "banner",
  "bannerTitle1": "Une équipe",
  "bannerTitle2": "Au service",
  "bannerTitle3": "Des patients",
  "bannerLink": "/",
  "bannerImage": `${process.env.REACT_APP_API_RESOURCES_URL}/images/Visu_Labo.png`
};

const team = [
  {
    "name": "Alexandra",
    "title": "Conseillère patientèle & logistique",
    "image": `${process.env.REACT_APP_API_RESOURCES_URL}/images/avatars/Avatar_Alex.png`
  },
  {
    "name": "Anne",
    "title": "Conseillère patientèle, thérapeute & logistique",
    "image": `${process.env.REACT_APP_API_RESOURCES_URL}/images/avatars/Avatar_Anne.png`
  },
  {
    "name": "Edwine",
    "title": "Conseillère patientèle & logistique",
    "image": `${process.env.REACT_APP_API_RESOURCES_URL}/images/avatars/Avatar__Edwine.png`
  },
  {
    "name": "Eric",
    "title": "Conseiller patientèle & logistique",
    "image": `${process.env.REACT_APP_API_RESOURCES_URL}/images/avatars/Avatar_Eric.png`
  },
  {
    "name": "Lola",
    "title": "Logistique",
    "image": `${process.env.REACT_APP_API_RESOURCES_URL}/images/avatars/Avatar_Lola.png`
  },
  {
    "name": "Renaud",
    "title": "Conseiller patientèle",
    "image": `${process.env.REACT_APP_API_RESOURCES_URL}/images/avatars/Avatar_Renaud.png`
  },
  {
    "name": "Sam",
    "title": "Conseiller patientèle & logistique",
    "image": `${process.env.REACT_APP_API_RESOURCES_URL}/images/avatars/Avatar_Samuel.png`
  },
  {
    "name": "Stéphanie",
    "title": "Conseillère patientèle",
    "image": `${process.env.REACT_APP_API_RESOURCES_URL}/images/avatars/Avatar_Stephanie.png`
  },
  {
    "name": "Thibault",
    "title": "Conseiller patientèle & Marketing",
    "image": `${process.env.REACT_APP_API_RESOURCES_URL}/images/avatars/Avatar_ThibaultKaiser.png`
  }
];

const team2 = [
  {
    "name": "Aline",
    "title": "Supply Chain",
    "image": `${process.env.REACT_APP_API_RESOURCES_URL}/images/avatars/Avatar_Aline.png`
  },
  {
    "name": "Alain",
    "title": "Production",
    "image": `${process.env.REACT_APP_API_RESOURCES_URL}/images/avatars/Avatar_Alain.png`
  },
  {
    "name": "Albert",
    "title": "Production",
    "image": `${process.env.REACT_APP_API_RESOURCES_URL}/images/avatars/Avatar_Albert.png`
  },
  {
    "name": "Davina",
    "title": "Production",
    "image": `${process.env.REACT_APP_API_RESOURCES_URL}/images/avatars/Avatar_Davina.png`
  },
  {
    "name": "Marc",
    "title": "Production",
    "image": `${process.env.REACT_APP_API_RESOURCES_URL}/images/avatars/Avatar_Marc.png`
  },
  {
    "name": "Noëlla",
    "title": "Production",
    "image": `${process.env.REACT_APP_API_RESOURCES_URL}/images/avatars/Avatar_Noella.png`
  },
  {
    "name": "Pavlos",
    "title": "Production",
    "image": `${process.env.REACT_APP_API_RESOURCES_URL}/images/avatars/Avatar_Pavlos.png`
  },
  {
    "name": "Philippe",
    "title": "Production",
    "image": `${process.env.REACT_APP_API_RESOURCES_URL}/images/avatars/Avatar_PhilippeBeaupain.png`
  },
  {
    "name": "Séverine",
    "title": "Production",
    "image": `${process.env.REACT_APP_API_RESOURCES_URL}/images/avatars/Avatar_Severine.png`
  },
];

const team3 = [
  {
    "name": "Lionel",
    "title": "Doctorant en biologie médicale",
    "image": `${process.env.REACT_APP_API_RESOURCES_URL}/images/avatars/Avatar_Lionel.png`
  },
  {
    "name": "Valery",
    "title": "Nutrithérapeute et Biologiste",
    "image": `${process.env.REACT_APP_API_RESOURCES_URL}/images/avatars/Avatar_Valery.png`
  },
  {
    "name": "Amin",
    "title": "Doctorant",
    "image": `${process.env.REACT_APP_API_RESOURCES_URL}/images/avatars/Avatar_Amin.png`
  },
  {
    "name": "Christian",
    "title": "PhD - Nutrition Micronutrition",
    "image": `${process.env.REACT_APP_API_RESOURCES_URL}/images/avatars/Avatar_ChrisBoyer.png`
  },
  {
    "name": "Xavier",
    "title": "Conseiller scientifique",
    "image": `${process.env.REACT_APP_API_RESOURCES_URL}/images/avatars/Avatar_Xavier.png`
  },
  {
    "name": "Julie",
    "title": "Nutrithérapeute",
    "image": `${process.env.REACT_APP_API_RESOURCES_URL}/images/avatars/Avatar_Julie.png`
  }
];

const team4 = [
  {
    "name": "Emilie",
    "title": "Responsable Marketing & Communications",
    "image": `${process.env.REACT_APP_API_RESOURCES_URL}/images/avatars/Avatar_Emilie.png`
  },
  {
    "name": "Nico",
    "title": "Graphiste",
    "image": `${process.env.REACT_APP_API_RESOURCES_URL}/images/avatars/Avatar_Nico.png`
  },
  {
    "name": "Thibault",
    "title": "Graphiste",
    "image": `${process.env.REACT_APP_API_RESOURCES_URL}/images/avatars/Avatar_ThibaultRousseau.png`
  }
];

const team5 = [

  {
    "name": "Christophe",
    "title": "Développeur IT",
    "image": `${process.env.REACT_APP_API_RESOURCES_URL}/images/avatars/Avatar_ChrisCamurac.png`
  },
  {
    "name": "Philippe",
    "title": "Développeur IT",
    "image": `${process.env.REACT_APP_API_RESOURCES_URL}/images/avatars/Avatar_PhilippeCamurac.png`
  }
];

const team6 = [
  {
    "name": "Cedric",
    "title": "Conseiller (Belgique et autres pays)",
    "image": `${process.env.REACT_APP_API_RESOURCES_URL}/images/avatars/Avatar_Cedric.png`
  },
  {
    "name": "Perrine",
    "title": "Conseillère (France SUD)",
    "image": `${process.env.REACT_APP_API_RESOURCES_URL}/images/avatars/Avatar_Perrine.png`
  },
  {
    "name": "Zakaria",
    "title": "Conseiller (France)",
    "image": `${process.env.REACT_APP_API_RESOURCES_URL}/images/avatars/Avatar_Zakaria.png`
  }
];

const team7 = [
  {
    "name": "Nikki",
    "title": "Assistante de Direction",
    "image": `${process.env.REACT_APP_API_RESOURCES_URL}/images/avatars/Avatar_AnnickSappada.png`
  },
  {
    "name": "Marie",
    "title": "Comptable",
    "image": `${process.env.REACT_APP_API_RESOURCES_URL}/images/avatars/Avatar_Marie.png`
  },
  {
    "name": "Leena",
    "title": "Assistante Comptable",
    "image": `${process.env.REACT_APP_API_RESOURCES_URL}/images/avatars/Avatar_Leena.png`
  }
];

const team8 = [
  {
    "name": "Thierry",
    "title": "Directeur Financier & Exécutif",
    "image": `${process.env.REACT_APP_API_RESOURCES_URL}/images/avatars/Avatar_Thierry.png`
  },
  {
    "name": "Raphaël",
    "title": "C.O.O. (Chief Operating Officer)",
    "image": `${process.env.REACT_APP_API_RESOURCES_URL}/images/avatars/Avatar_Raphy.png`
  },
  {
    "name": "Salva",
    "title": "C.E.O. (Chief Executive Officer)",
    "image": `${process.env.REACT_APP_API_RESOURCES_URL}/images/avatars/Avatar_Salva.png`
  }
];

export const StyledTypography = (props) => (
  <Typography
    sx={{
      fontSize: {xs: '1rem', sm: '0.9rem', md: '1rem', lg: '1.1rem', xl: '1.2rem', xxl: '1.3rem'},
      padding: '0 8px 8px 8px',
      fontFamily: 'Open Sans',
      maxWidth: {xs: '100%', sm: '100%'},
      lineHeight: '1.4',
      fontWeight: '300',
      overflow: 'hidden',
      textAlign: 'left',
      color: 'primary.light',
      zIndex: 1,
      '& p': {
        margin: 0
      }
    }}>
    {props.children}
  </Typography>
);

// custom typography
export const TeamTitle = (props) => (
  <Typography
    sx={{
      fontFamily: 'Raleway',
      fontWeight: '600',
      padding: '8px 8px',
      fontSize: {xs: '2rem', sm: '2rem', md: '2rem', lg: '2rem', xl: '2rem', xxl: '2rem'},
      lineHeight: '1',
      overflow: 'hidden',
      color: 'primary.main',
      textAlign: {xs: 'left', sm: 'left'},
      zIndex: 1,
      '& p': {
        //margin: 0
      },
      '& span': {
        fontFamily: 'Raleway',
        fontWeight: '600',
        fontSize: {xs: '2.5rem', sm: '2rem', md: '2.5rem', lg: '3.5rem', xl: '3.5rem', xxl: '4.5rem'}
      }
    }}>
    {props.children}
  </Typography>
);

export default function AboutSection4() {

  return (
    <Box
      sx={{
        width: '100%',
        margin: '40px 0 60px',
        padding: '1px 0',
        overflowX: 'clip'
      }}>
      <Container
        maxWidth={'xxl'}>
        <Grid container spacing={2} justifyContent={"space-between"} flexDirection={"row"} sx={{
          ml: '-8px !important',
          position: 'relative'
        }}>
          <Grid item xs={12} sm={4} sx={{pl: '24px', pr: '24px'}}>
            <Stack gap={2} alignItems={'flex-start'}>
              <Typography
                sx={{
                  fontFamily: 'Raleway',
                  fontWeight: '600',
                  padding: '8px 8px',
                  fontSize: {xs: '2.5rem', sm: '2rem', md: '2.5rem', lg: '3.5rem', xl: '3.5rem', xxl: '4.5rem'},
                  lineHeight: '0.7',
                  overflow: 'hidden',
                  color: 'green',
                  textAlign: {xs: 'left', sm: 'left'},
                  zIndex: 1,
                  '& p': {
                    margin: 0
                  },
                  '& span': {
                    fontFamily: 'Raleway',
                    fontWeight: '600',
                    fontSize: {xs: '2.5rem', sm: '2rem', md: '2.5rem', lg: '3.5rem', xl: '3.5rem', xxl: '4.5rem'}
                  }
                }}>
                {section.bannerTitle1} <br/>
                <Typography component={"span"} sx={{color: 'primary.main'}}>{section.bannerTitle2}</Typography> <br/>
                {section.bannerTitle3}
              </Typography>
              <StyledTypography>
                                Nutri-Logics est une entreprise familiale qui compte des membres motivés et passionnés.
              </StyledTypography>
              <StyledTypography>
                                En tant que partenaires de confiance des thérapeutes et de leurs patients,
                                nous travaillons également en étroite collaboration avec de nombreux professionnels de la santé.
                                Médecins, scientifiques et nutrithérapeutes échangent en permanence avec nos équipes pour faire
                                part de leurs avis sur certains produits, réfléchir avec nous à des solutions plus
                                performantes pour aider leurs patients ou remonter des problématiques santé sur
                                lesquelles nous pouvons travailler ensemble.
              </StyledTypography>
              <StyledTypography>
                Au centre de toutes nos décisions réside une seule obsession : la satisfaction de nos utilisateurs, tant les
                thérapeutes que leurs patients.
              </StyledTypography>
              <StyledTypography>
                Conseils, écoute, service après-vente et emballage avec soin et avec cœur de vos colis : nous mettons un point
                d’honneur à vous aider avec chaleur, sympathie et bienveillance.
              </StyledTypography>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TeamTitle>
                  Conseillers patientèle et logistique
                </TeamTitle>
              </Grid>
              {team.map((member, i) => (
                <Grid item xs={6} sm={3} key={`${member.name}${i}`}>
                  <Stack gap={1} alignItems={"center"} alignContent={"center"} sx={{
                    '& img': {
                      width: '100%'
                    }
                  }}>
                    <img src={member.image} alt={member.name}/>
                    <Typography sx={{
                      color: 'primary.light',
                      fontFamily: 'Montserrat',
                      fontWeight: 'bold',
                      fontSize: {xs: '0.8rem', sm: '0.9rem', md: '1rem', lg: '1.1rem', xl: '1.2rem', xxl: '1.2rem'}
                    }}>
                      {member.name}
                    </Typography>
                    <Typography sx={{
                      color: 'primary.light',
                      fontFamily: 'Montserrat',
                      textAlign: 'center',
                      fontSize: {xs: '0.8rem', sm: '0.9rem', md: '1rem', lg: '1.1rem', xl: '1.2rem', xxl: '1.2rem'}
                    }}>
                      {member.title}
                    </Typography>
                  </Stack>
                </Grid>
              ))}

              <Grid item xs={12} sx={{mt: 4}}/>

              <Grid item xs={12}>
                <TeamTitle>
                  Production
                </TeamTitle>
              </Grid>
              {team2.map((member, i) => (
                <Grid item xs={6} sm={3} key={`${member.name}${i}`}>
                  <Stack gap={1} alignItems={"center"} alignContent={"center"} sx={{
                    '& img': {
                      width: '100%'
                    }
                  }}>
                    <img src={member.image} alt={member.name}/>
                    <Typography sx={{
                      color: 'primary.light',
                      fontFamily: 'Montserrat',
                      fontWeight: 'bold',
                      fontSize: {xs: '0.8rem', sm: '0.9rem', md: '1rem', lg: '1.1rem', xl: '1.2rem', xxl: '1.2rem'}
                    }}>
                      {member.name}
                    </Typography>
                    <Typography sx={{
                      color: 'primary.light',
                      fontFamily: 'Montserrat',
                      textAlign: 'center',
                      fontSize: {xs: '0.8rem', sm: '0.9rem', md: '1rem', lg: '1.1rem', xl: '1.2rem', xxl: '1.2rem'}
                    }}>
                      {member.title}
                    </Typography>
                  </Stack>
                </Grid>
              ))}

              <Grid item xs={12} sx={{mt: 4}}/>

              <Grid item xs={12}>
                <TeamTitle>
                  Science, Recherche & Développement
                </TeamTitle>
              </Grid>
              {team3.map((member, i) => (
                <Grid item xs={6} sm={3} key={`${member.name}${i}`}>
                  <Stack gap={1} alignItems={"center"} alignContent={"center"} sx={{
                    '& img': {
                      width: '100%'
                    }
                  }}>
                    <img src={member.image} alt={member.name}/>
                    <Typography sx={{
                      color: 'primary.light',
                      fontFamily: 'Montserrat',
                      fontWeight: 'bold',
                      fontSize: {xs: '0.8rem', sm: '0.9rem', md: '1rem', lg: '1.1rem', xl: '1.2rem', xxl: '1.2rem'}
                    }}>
                      {member.name}
                    </Typography>
                    <Typography sx={{
                      color: 'primary.light',
                      fontFamily: 'Montserrat',
                      textAlign: 'center',
                      fontSize: {xs: '0.8rem', sm: '0.9rem', md: '1rem', lg: '1.1rem', xl: '1.2rem', xxl: '1.2rem'}
                    }}>
                      {member.title}
                    </Typography>
                  </Stack>
                </Grid>
              ))}

              <Grid item xs={12} sx={{mt: 4}}/>

              <Grid item xs={12}>
                <TeamTitle>
                  Marketing & Communication
                </TeamTitle>
              </Grid>
              {team4.map((member, i) => (
                <Grid item xs={6} sm={3} key={`${member.name}${i}`}>
                  <Stack gap={1} alignItems={"center"} alignContent={"center"} sx={{
                    '& img': {
                      width: '100%'
                    }
                  }}>
                    <img src={member.image} alt={member.name}/>
                    <Typography sx={{
                      color: 'primary.light',
                      fontFamily: 'Montserrat',
                      fontWeight: 'bold',
                      fontSize: {xs: '0.8rem', sm: '0.9rem', md: '1rem', lg: '1.1rem', xl: '1.2rem', xxl: '1.2rem'}
                    }}>
                      {member.name}
                    </Typography>
                    <Typography sx={{
                      color: 'primary.light',
                      fontFamily: 'Montserrat',
                      textAlign: 'center',
                      fontSize: {xs: '0.8rem', sm: '0.9rem', md: '1rem', lg: '1.1rem', xl: '1.2rem', xxl: '1.2rem'}
                    }}>
                      {member.title}
                    </Typography>
                  </Stack>
                </Grid>
              ))}

              <Grid item xs={12} sx={{mt: 4}}/>

              <Grid item xs={12}>
                <TeamTitle>
                  IT & développement
                </TeamTitle>
              </Grid>
              {team5.map((member, i) => (
                <Grid item xs={6} sm={3} key={`${member.name}${i}`}>
                  <Stack gap={1} alignItems={"center"} alignContent={"center"} sx={{
                    '& img': {
                      width: '100%'
                    }
                  }}>
                    <img src={member.image} alt={member.name}/>
                    <Typography sx={{
                      color: 'primary.light',
                      fontFamily: 'Montserrat',
                      fontWeight: 'bold',
                      fontSize: {xs: '0.8rem', sm: '0.9rem', md: '1rem', lg: '1.1rem', xl: '1.2rem', xxl: '1.2rem'}
                    }}>
                      {member.name}
                    </Typography>
                    <Typography sx={{
                      color: 'primary.light',
                      fontFamily: 'Montserrat',
                      textAlign: 'center',
                      fontSize: {xs: '0.8rem', sm: '0.9rem', md: '1rem', lg: '1.1rem', xl: '1.2rem', xxl: '1.2rem'}
                    }}>
                      {member.title}
                    </Typography>
                  </Stack>
                </Grid>
              ))}

              <Grid item xs={12} sx={{mt: 4}}/>

              <Grid item xs={12}>
                <TeamTitle>
                  Conseillers au service des
                  professionnels de la santé
                </TeamTitle>
              </Grid>
              {team6.map((member, i) => (
                <Grid item xs={6} sm={3} key={`${member.name}${i}`}>
                  <Stack gap={1} alignItems={"center"} alignContent={"center"} sx={{
                    '& img': {
                      width: '100%'
                    }
                  }}>
                    <img src={member.image} alt={member.name}/>
                    <Typography sx={{
                      color: 'primary.light',
                      fontFamily: 'Montserrat',
                      fontWeight: 'bold',
                      fontSize: {xs: '0.8rem', sm: '0.9rem', md: '1rem', lg: '1.1rem', xl: '1.2rem', xxl: '1.2rem'}
                    }}>
                      {member.name}
                    </Typography>
                    <Typography sx={{
                      color: 'primary.light',
                      fontFamily: 'Montserrat',
                      textAlign: 'center',
                      fontSize: {xs: '0.8rem', sm: '0.9rem', md: '1rem', lg: '1.1rem', xl: '1.2rem', xxl: '1.2rem'}
                    }}>
                      {member.title}
                    </Typography>
                  </Stack>
                </Grid>
              ))}

              <Grid item xs={12} sx={{mt: 4}}/>

              <Grid item xs={12}>
                <TeamTitle>
                  Administration & comptabilité
                </TeamTitle>
              </Grid>
              {team7.map((member, i) => (
                <Grid item xs={6} sm={3} key={`${member.name}${i}`}>
                  <Stack gap={1} alignItems={"center"} alignContent={"center"} sx={{
                    '& img': {
                      width: '100%'
                    }
                  }}>
                    <img src={member.image} alt={member.name}/>
                    <Typography sx={{
                      color: 'primary.light',
                      fontFamily: 'Montserrat',
                      fontWeight: 'bold',
                      fontSize: {xs: '0.8rem', sm: '0.9rem', md: '1rem', lg: '1.1rem', xl: '1.2rem', xxl: '1.2rem'}
                    }}>
                      {member.name}
                    </Typography>
                    <Typography sx={{
                      color: 'primary.light',
                      fontFamily: 'Montserrat',
                      textAlign: 'center',
                      fontSize: {xs: '0.8rem', sm: '0.9rem', md: '1rem', lg: '1.1rem', xl: '1.2rem', xxl: '1.2rem'}
                    }}>
                      {member.title}
                    </Typography>
                  </Stack>
                </Grid>
              ))}

              <Grid item xs={12} sx={{mt: 4}}/>

              <Grid item xs={12}>
                <TeamTitle>
                  Direction
                </TeamTitle>
              </Grid>
              {team8.map((member, i) => (
                <Grid item xs={6} sm={3} key={`${member.name}${i}`}>
                  <Stack gap={1} alignItems={"center"} alignContent={"center"} sx={{
                    '& img': {
                      width: '100%'
                    }
                  }}>
                    <img src={member.image} alt={member.name}/>
                    <Typography sx={{
                      color: 'primary.light',
                      fontFamily: 'Montserrat',
                      fontWeight: 'bold',
                      fontSize: {xs: '0.8rem', sm: '0.9rem', md: '1rem', lg: '1.1rem', xl: '1.2rem', xxl: '1.2rem'}
                    }}>
                      {member.name}
                    </Typography>
                    <Typography sx={{
                      color: 'primary.light',
                      fontFamily: 'Montserrat',
                      textAlign: 'center',
                      fontSize: {xs: '0.8rem', sm: '0.9rem', md: '1rem', lg: '1.1rem', xl: '1.2rem', xxl: '1.2rem'}
                    }}>
                      {member.title}
                    </Typography>
                  </Stack>
                </Grid>
              ))}



            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
