import React from "react";
import AccountBalanceWalletTwoToneIcon from "@mui/icons-material/AccountBalanceWalletTwoTone";
import MonetizationOnTwoToneIcon from "@mui/icons-material/MonetizationOnTwoTone";
import SpeedTwoToneIcon from "@mui/icons-material/SpeedTwoTone";
import {Box} from "@mui/material";
import Divider from "@mui/material/Divider";
import Toolbar from "@mui/material/Toolbar";

import theme from "../../../assets/styles/theme/theme";
import {Link} from "../../../helpers/multilingual";
import Menu from "../Menu/Menu";
import MenuItem from "../Menu/MenuItem";

const MenuDrawer = ({setMobileOpen}) => (
  <div>
    <Toolbar>
      <Link to="/admin">
        <Box component={'img'} width={50} src={`${process.env.REACT_APP_API_RESOURCES_URL}/images/logo/logo.svg`} alt="logo" sx={{width: '100%'}}/>
      </Link>
    </Toolbar>
    <Divider />
    <Menu sx={{
      '& .MuiListItemText-primary': {
        fontFamily: 'Open sans',
        color: 'black'
      },
      '& .MuiListItemIcon-root': {
        color: theme.palette.primary.main
      }
    }}>
      <MenuItem
        title={"Dashboard"}
        to={"/accounting"}
        icon={<SpeedTwoToneIcon sx={{color: '#f07a13'}}/>}
        setMobileOpen={setMobileOpen}
      />
      <MenuItem
        title={"Factures"}
        to={"/accounting/invoices"}
        icon={<AccountBalanceWalletTwoToneIcon sx={{color: '#f07a13'}}/>}
        setMobileOpen={setMobileOpen}
      />
      <MenuItem
        title={"Notes de crédit"}
        to={"/accounting/creditNotes"}
        icon={<AccountBalanceWalletTwoToneIcon sx={{color: '#f07a13'}}/>}
        setMobileOpen={setMobileOpen}
      />
      <MenuItem
        title={"Commissions"}
        to={"/accounting/commissions"}
        icon={<MonetizationOnTwoToneIcon sx={{color: '#f07a13'}}/>}
        setMobileOpen={setMobileOpen}
      />
    </Menu>
  </div>
);

export default MenuDrawer;
