import React, { useEffect, useState } from "react";
import {useTranslation} from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {makeStyles} from '@mui/styles';
import { DatePicker } from "@mui/x-date-pickers";

import BarChart from "../../../../components/Charts/BarChart";
import useMoment from "../../../../helpers/useMoment";
import {proStatsActions} from "../store/actions";
import {networkTurnoverByCareerDataSelector, networkTurnoverByCareerGraphDataSelector} from "../store/selectors";

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    '& .MuiCardHeader-root': {
      '& .MuiCardHeader-title': {
        fontSize: '1.5rem !important',
        lineHeight: '1.8rem',
        paddingBottom: 10,
        fontWeight: '400 !important'
      },
      '& .MuiCardHeader-content': {
        height: 64
      },
      '& .MuiInput-root': {
        '& .MuiInputBase-input': {
          textAlign: 'right'
        }
      }
    }
  }
}));

const NetworkTurnoverByCareerChart = ({user}) => {

  const moment = useMoment();
  const dispatch = useDispatch();
  const classes = useStyles();
  const {t} = useTranslation();
  const [isYearPickerOpen, setYearPickerOpen] = useState(false);
  const [isMonthPickerOpen, setMonthPickerOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(moment());
  const [yearRange, setYearRange] = useState(false);
  const {data, labels} = useSelector(networkTurnoverByCareerGraphDataSelector(user.id, selectedDate.format('YYYY'), !yearRange && selectedDate.format('MM')));
  const turnoverByCareer = useSelector(networkTurnoverByCareerDataSelector(user.id, selectedDate.format('YYYY'), !yearRange && selectedDate.format('MM')));

  useEffect(() => {
    dispatch(proStatsActions.getNetworkTurnoverByCareerRequest(user.id, selectedDate.format('YYYY'), !yearRange && selectedDate.format('MM')));
  }, [selectedDate, yearRange]);

  const handleYearChange = (e) => {
    setSelectedDate(e);
    setYearPickerOpen(false);
  };

  const handleDateChange = (e) => {
    setSelectedDate(e);
    setMonthPickerOpen(false);
  };

  const handleChangeRangeSelector = (e) => {
    setYearRange(e.target.checked);
  };

  return (
    <Card className={classes.root}>
      <CardHeader title={
        <Grid container justify={"space-between"}>
          <Grid item>{t('turnoverByCareer')}</Grid>
          <Grid item xs={12}>
            <Grid container justify={"flex-end"}>
              <Grid item>
                <FormControlLabel
                  control={<Checkbox
                    checked={yearRange}
                    onChange={handleChangeRangeSelector}
                    name="range"
                    style={{paddingBottom: 0, paddingTop: 0}}
                  />}
                  label={t('byYear')}
                />
              </Grid>
              <Grid item>
                {yearRange && (
                  <DatePicker
                    variant="inline"
                    maxDate={moment()}
                    open={isYearPickerOpen}
                    onOpen={() => setYearPickerOpen(true)}
                    onYearChange={(e) => handleYearChange(e)}
                    openTo="year"
                    views={["year"]}
                    label=""
                    value={selectedDate}
                    onChange={() => {}}
                  />
                )}
                {!yearRange && (
                  <DatePicker
                    variant="inline"
                    maxDate={moment()}
                    open={isMonthPickerOpen}
                    onOpen={() => setMonthPickerOpen(true)}
                    onMonthChange={(e) => handleDateChange(e)}
                    openTo="month"
                    views={["year", "month"]}
                    label=""
                    value={selectedDate}
                    onChange={() => {}}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }/>
      <Divider/>
      <CardContent>
        <BarChart
          title={t('turnoverByCareer')}
          data={data}
          labels={labels}
          heightLg={350}
          heightMd={300}
          heightSm={300}
          displayLegend={false}
          color={{
            border: 'rgb(89,80,236)',
            background: 'rgba(89,80,236, 0.04)'
          }}
        />
      </CardContent>
      <Grid container>
        {turnoverByCareer.map((career, key) => (
          <Grid key={key} item xs={3}>
            <Typography component={"div"} align={"center"} gutterBottom>
              <Typography>{career.name}</Typography>
              <Typography variant={"h6"}>{career.amount || 0} €</Typography>
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Card>
  );
};
export default NetworkTurnoverByCareerChart;
