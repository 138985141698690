import * as actionsType from './constants';

export const consultantsActions = {
  searchFromPlaceRequest,
  searchFromPlaceSuccess,
  searchFromPlaceFailed,
  searchFromPlaceAndZoomRequest,
  searchFromPlaceAndZoomSuccess,
  searchFromPlaceAndZoomFailed,
  getOfficeRequest,
  getOfficeSuccess,
  getOfficeFailed,
  setMapDisplayOption,
  setSearchBarOpen,
};

export function searchFromPlaceRequest(place) {

  return {
    type: actionsType.SEARCH_OFFICES_FROM_PLACE_REQUEST,
    place
  };
}

export function searchFromPlaceSuccess(offices, zoom) {

  return {
    type: actionsType.SEARCH_OFFICES_FROM_PLACE_SUCCESS,
    offices,
    zoom
  };
}

export function searchFromPlaceFailed() {

  return {
    type: actionsType.SEARCH_OFFICES_FROM_PLACE_FAILED
  };
}

export function searchFromPlaceAndZoomRequest(place, zoom) {

  return {
    type: actionsType.SEARCH_OFFICES_FROM_PLACE_AND_ZOOM_REQUEST,
    place,
    zoom
  };
}

export function searchFromPlaceAndZoomSuccess(offices, zoom) {

  return {
    type: actionsType.SEARCH_OFFICES_FROM_PLACE_AND_ZOOM_SUCCESS,
    offices,
    zoom
  };
}

export function searchFromPlaceAndZoomFailed() {

  return {
    type: actionsType.SEARCH_OFFICES_FROM_PLACE_AND_ZOOM_FAILED
  };
}

export function getOfficeRequest(id) {

  return {
    type: actionsType.GET_OFFICE_REQUEST,
    id
  };
}

export function getOfficeSuccess(office) {

  return {
    type: actionsType.GET_OFFICE_SUCCESS,
    office
  };
}

export function getOfficeFailed() {

  return {
    type: actionsType.GET_OFFICE_FAILED
  };
}

export function setMapDisplayOption(display) {

  return {
    type: actionsType.SET_MAP_DISPLAY_OPTION,
    display
  };
}

export function setSearchBarOpen(open) {

  return {
    type: actionsType.SET_SEARCH_BAR_OPEN,
    open
  };
}
