import * as actionsType from './constants';

export const homeActions = {
  getTreesCountRequest,
  getTreesCountSuccess,
  getTopbarRequest,
  getTopbarSuccess,
  getSlidersRequest,
  getSlidersSuccess,
  getSectionsRequest,
  getSectionsSuccess,
};

export function getTreesCountRequest() {

  return {
    type: actionsType.GET_TREES_COUNT_REQUEST,
  };
}

export function getTreesCountSuccess(treesCount) {

  return {
    type: actionsType.GET_TREES_COUNT_SUCCESS,
    treesCount
  };
}

export function getTopbarRequest(lang) {

  return {
    type: actionsType.GET_TOPBAR_REQUEST,
    lang
  };
}

export function getTopbarSuccess(topbar) {

  return {
    type: actionsType.GET_TOPBAR_SUCCESS,
    topbar
  };
}

export function getSlidersRequest(lang) {

  return {
    type: actionsType.GET_SLIDERS_REQUEST,
    lang
  };
}

export function getSlidersSuccess(sliders) {

  return {
    type: actionsType.GET_SLIDERS_SUCCESS,
    sliders
  };
}

export function getSectionsRequest(lang) {

  return {
    type: actionsType.GET_SECTIONS_REQUEST,
    lang
  };
}

export function getSectionsSuccess(sections) {

  return {
    type: actionsType.GET_SECTIONS_SUCCESS,
    sections
  };
}
