import React from 'react';
import {Grid, Stack, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import useMediaQuery from "@mui/material/useMediaQuery";

const section = {
  "name": "About section 3",
  "type": "banner",
  "bannerTitle1": "Nos engagements",
  "bannerLink": "/",
  "bannerImage": `${process.env.REACT_APP_API_RESOURCES_URL}/images/Visu_Labo.png`
};

const StyledTypography = (props) => (
  <Typography
    sx={{
      fontSize: {xs: '1rem', sm: '0.9rem', md: '1rem', lg: '1.1rem', xl: '1.2rem', xxl: '1.3rem'},
      padding: '0 8px 8px 8px',
      fontFamily: 'Open Sans',
      maxWidth: {xs: '100%', sm: '100%'},
      lineHeight: '1.4',
      fontWeight: '300',
      overflow: 'hidden',
      textAlign: 'left',
      color: 'primary.light',
      zIndex: 1,
      '& p': {
        margin: 0
      }
    }}>
    {props.children}
  </Typography>
);
export default function AboutSection3() {

  const isDownMd = useMediaQuery(theme => theme.breakpoints.down('md'));

  return (
    <Box
      sx={{
        width: '100%',
        margin: '40px 0 60px',
        padding: '1px 0',
        overflowX: 'clip'
      }}>
      <Container
        maxWidth={'xxl'}>
        <Grid container spacing={2} justifyContent={"space-between"} flexDirection={"row"} sx={{
          ml: '-8px !important',
          position: 'relative',
          py: '4vw'
        }}>
          <Grid item xs={12} sm={6}>
          </Grid>
          <Grid item xs={12} sm={6} sx={{pl: '24px', pr: '24px'}}>
            <Stack gap={2} alignItems={'flex-start'}>
              <Typography
                sx={{
                  fontFamily: 'Raleway',
                  fontWeight: '600',
                  padding: '8px 8px',
                  fontSize: {xs: '2.5rem', sm: '2rem', md: '2.5rem', lg: '3.5rem', xl: '3.5rem', xxl: '4.5rem'},
                  lineHeight: '1.5',
                  overflow: 'hidden',
                  color: 'green',
                  textAlign: {xs: 'left', sm: 'left'},
                  zIndex: 1,
                  '& p': {
                    margin: 0
                  },
                  '& span': {
                    fontFamily: 'Raleway',
                    fontWeight: '600',
                    fontSize: {xs: '2.5rem', sm: '2rem', md: '2.5rem', lg: '3.5rem', xl: '3.5rem', xxl: '4.5rem'}
                  }
                }}>
                {section.bannerTitle1}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <Stack direction={isDownMd ? "column" : "row"} gap={2} alignItems={"center"} alignContent={"center"}>
                  <img src={`${process.env.REACT_APP_API_RESOURCES_URL}/images/Ico_Team-2.png`} alt="Team"
                    style={{width: '150px'}}/>
                  <StyledTypography>
                    Une équipe animée par l’envie d’avoir un impact positif sur votre bien-être et sur le monde.
                  </StyledTypography>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Stack direction={isDownMd ? "column" : "row"} gap={2} alignItems={"center"} alignContent={"center"}>
                  <img src={`${process.env.REACT_APP_API_RESOURCES_URL}/images/Ico_SpeechBubbles.png`} alt="Speech"
                    style={{width: '150px'}}/>
                  <StyledTypography>
                    Révolutionner la santé avec la meilleure sélection de compléments alimentaires du marché.
                  </StyledTypography>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Stack direction={isDownMd ? "column" : "row"} gap={2} alignItems={"center"} alignContent={"center"}>
                  <img src={`${process.env.REACT_APP_API_RESOURCES_URL}/images/Ico_Shipping.png`} alt="Shipping"
                    style={{width: '150px'}}/>
                  <StyledTypography>
                    Des conseillers chaleureux à votre écoute qui font tout leur possible pour vous garantir la plus grande
                    satisfaction.
                  </StyledTypography>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Stack direction={isDownMd ? "column" : "row"} gap={2} alignItems={"center"} alignContent={"center"}>
                  <img src={`${process.env.REACT_APP_API_RESOURCES_URL}/images/Ico_LeafHert.png`} alt="LeafHeart"
                    style={{width: '150px'}}/>
                  <StyledTypography>
                      Notre objectif : carbone 0. L’envoi de votre colis ne polluera plus la planète.
                  </StyledTypography>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Stack direction={isDownMd ? "column" : "row"} gap={2} alignItems={"center"} alignContent={"center"}>
                  <img src={`${process.env.REACT_APP_API_RESOURCES_URL}/images/Ico_Responsible.png`} alt="Responsible"
                    style={{width: '150px'}}/>
                  <StyledTypography>
                    Une rémunération juste pour nos producteurs responsables à un prix indépendant du cours du marché international et
                    bien supérieur à celui-ci.
                  </StyledTypography>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Stack direction={isDownMd ? "column" : "row"} gap={2} alignItems={"center"} alignContent={"center"}>
                  <img src={`${process.env.REACT_APP_API_RESOURCES_URL}/images/Ico_Iso.png`} alt="Iso"
                    style={{width: '150px'}}/>
                  <StyledTypography>
                    Un gage d’excellence grâce à notre certification ISO 9001, la norme mondiale en termes d’exigence et de qualité.
                  </StyledTypography>
                </Stack>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Stack direction={isDownMd ? "column" : "row"} gap={2} alignItems={"center"} alignContent={"center"}>
                <img src={`${process.env.REACT_APP_API_RESOURCES_URL}/images/Ico_Humanity.png`} alt="Humanity"
                  style={{width: '150px'}}/>
                <StyledTypography>
                  Des projets humanitaires et écologiques au cœur de toutes les ambitions avec la plantation d’arbres de
                  Moringa, une ressource bourrée de nutriment.
                  Par arbre planté, vous offrez 600 repas par an et réduisez l’empreinte carbone de 22kg.
                </StyledTypography>
              </Stack>
            </Grid>
          </Grid>

        </Grid>
      </Container>
    </Box>
  );
}
