import React from 'react';
import {Grid, Slide, Stack, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";

import {Link} from "../../../../helpers/multilingual";

export default function CustomSectionBanner({section}) {

  const containerRef = React.useRef(null);

  if(!section?.name) {
    return null;
  }

  return (
    <Box
      sx={{
        width: '100%',
        background: `url(${section.bannerBackgroundImage})`,
        backgroundPositionX: {xs: '70%', sm: '50%'},
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        margin: '40px 0 60px',
        padding: '1px 0',
        height: {xs: 'auto', sm: '380px'},
        minHeight: {xs: 'auto', sm: '380px'},
        overflowX: 'clip'
      }}>
      <Container
        component={Link}
        to={section.bannerLink}
        maxWidth={'xxl'}>
        <Grid container spacing={2} justifyContent={"space-between"} flexDirection={"row"} sx={{ml: '-8px !important'}}>
          <Grid item xs={12} sm={6} sx={{pl: '24px', pr: '24px'}}>
            <Stack gap={2} sx={{pt: '3vw'}} alignItems={'flex-start'} ref={containerRef}>
              <Slide direction="down" in={true} container={containerRef.current}>
                <Typography dangerouslySetInnerHTML={{ __html: section.bannerTitle }}
                  sx={{
                    fontFamily: 'Montserrat',
                    padding: '8px 8px',
                    fontSize: {xs: '2.5rem', sm: '2rem', md: '2.5rem', lg: '3rem'},
                    lineHeight: '1.1',
                    fontWeight: 'bold',
                    overflow: 'hidden',
                    textAlign: {xs: 'center', sm: 'left'},
                    zIndex: 1,
                    '& p': {
                      margin: 0,
                    }
                  }}/>
              </Slide>
              <Slide
                direction="left"
                in={true}
                container={containerRef.current}
              >
                <Typography
                  dangerouslySetInnerHTML={{ __html: section.bannerText }}
                  sx={{
                    fontSize: {xs: '1rem', sm: '0.9rem', md: '1rem', lg: '1.13rem'},
                    padding: '0 8px 8px 8px',
                    fontFamily: 'Open Sans',
                    maxWidth: {xs: '100%', sm: '100%'},
                    lineHeight: '1.4',
                    overflow: 'hidden',
                    textAlign: 'left',
                    zIndex: 1,
                    color: '#333',
                    '& p': {
                      margin: 0,

                    }
                  }}/>
              </Slide>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box component={'img'} src={section.bannerImage} alt={section.bannerTitle}
              sx={{
                width: {xs: 'auto', sm: '480px'},
                maxWidth: {xs: '100%', sm: '100%'},
                position: {xs: 'inherit', sm: 'relative', md: 'relative'},
                bottom: {xs: '0px', sm: '-40px', md: '-40px'},
                right: {xs: 'unset', sm: 'unset', md: 'unset'},
                left: {xs: 'unset', sm: 'unset', md: 'unset'},
                display: 'block',
                margin: 'auto'
              }}/>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
