import { Cookies } from 'react-cookie';

const NativeCookie = new Cookies();

let Cookie = {};

Cookie.get = (name) => NativeCookie.get(`${process.env.REACT_APP_NAME}-${name}`);

Cookie.set = (name, data, maxAge = 60*60*24*365*10) => {
  NativeCookie.set(`${process.env.REACT_APP_NAME}-${name}`, data, {path:'/', maxAge: maxAge, domain: process.env.REACT_APP_COOKIE_DOMAIN, secure: process.env.NODE_ENV === 'production', sameSite: 'lax', httpOnly: false});
};

Cookie.remove = (name) => {
  NativeCookie.remove(`${process.env.REACT_APP_NAME}-${name}`, {path:'/', domain: process.env.REACT_APP_COOKIE_DOMAIN, secure: process.env.NODE_ENV === 'production', sameSite: 'lax', httpOnly: false});
};

export default Cookie;