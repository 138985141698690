import axios  from '../../../../helpers/axios';

export const careerService = {
  getCareerProgression,
  getCareerCertificationProgression,
  validateChapter,
  chooseCareer,
  validateCertificationChapter,
  validateCertificationTest,
  validateQualification,
  getUserObjectives,
  getNetworkObjectives,
  addUserObjectives,
  addUserObjectivesPinned,
};

function getCareerProgression() {

  return new Promise((resolve, reject) => {

    axios.get(`/pro/career/progression/`)
      .then(({data}) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getCareerCertificationProgression(id) {

  return new Promise((resolve, reject) => {

    axios.get(`/pro/career/certification/progression/${id}`)
      .then(({data}) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function validateChapter(step, chapter) {

  return new Promise((resolve, reject) => {

    axios.get(`/pro/career/validate/${step}/${chapter}`)
      .then(({nextStep, nextChapter}) => {
        resolve({nextStep, nextChapter});
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function chooseCareer(career) {

  return new Promise((resolve, reject) => {

    axios.get(`/pro/career/choose/${career}`)
      .then(({nextStep, nextChapter}) => {
        resolve({nextStep, nextChapter});
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function validateCertificationChapter(certificationId, chapter) {

  return new Promise((resolve, reject) => {

    axios.get(`/pro/career/certification/${certificationId}/validate/${chapter}`)
      .then(() => {
        resolve();
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function validateCertificationTest(certificationId, chapter, data) {

  return new Promise((resolve, reject) => {

    axios.post(`/pro/career/certification/${certificationId}/validate/${chapter}`, data)
      .then((data) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function validateQualification(step, chapter) {

  return new Promise((resolve, reject) => {

    axios.get(`/pro/career/validate/${step}/${chapter}`)
      .then(({nextStep, nextChapter}) => {
        resolve({nextStep, nextChapter});
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getUserObjectives(date) {

  return new Promise((resolve, reject) => {

    axios.get(`/pro/objective/${date}`)
      .then((data) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getNetworkObjectives(date) {

  return new Promise((resolve, reject) => {

    axios.get(`/pro/objective/${date}/network`)
      .then(({data}) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function addUserObjectives(date, data) {

  return new Promise((resolve, reject) => {

    axios.post(`/pro/objective/${date}`, {data})
      .then((data) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function addUserObjectivesPinned(date, pinned) {

  return new Promise((resolve, reject) => {

    axios.post(`/pro/objective/${date}/pinned`, {pinned})
      .then((data) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}
