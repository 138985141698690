import i18n from "i18next";

export const format = (error) => {

  switch (error?.type) {

    case 'validation-error':
      switch (error.key) {

        case 'any.only':
          return i18n.t('errorMessageFieldDoesNotMatch', {
            field: i18n.t(error.field)
          });

        case 'order.min':
          return i18n.t('errorMessageOrderPriceMin', {
            field: i18n.t(error.field)
          });

        case 'string.alphanum':
          return i18n.t('errorMessageFieldNotAlphaNum', {
            field: i18n.t(error.field)
          });

        case 'string.pattern.base':
          return i18n.t('errorMessageFieldNotMatchPattern', {
            field: i18n.t(error.field)
          });

        case 'string.min':
          return i18n.t('errorMessageFieldNeedToBeLonger', {
            field: i18n.t(error.field)
          });

        case 'string.max':
          return i18n.t('errorMessageFieldNeedToBeShorter', {
            field: i18n.t(error.field)
          });

        case 'number.min':
          return i18n.t('errorMessageFieldNeedToBeLarger', {
            field: i18n.t(error.field)
          });

        case 'number.max':
          return i18n.t('errorMessageFieldNeedToBeLess', {
            field: i18n.t(error.field)
          });

        case 'string.empty':
          return i18n.t('errorMessageEmptyField', {
            field: i18n.t(error.field)
          });

        case 'number.base':
          return i18n.t('errorMessageFieldNeedToBeANumber', {
            field: i18n.t(error.field)
          });

        case 'any.required':
          return i18n.t('errorMessageIsRequired', {
            field: i18n.t(error.field)
          });

        case 'string.email':
          return i18n.t('errorMessageMustBeAValidEmail', {
            value: error.value
          });

        case 'phoneNumber.invalid':
          return i18n.t('errorMessageMustBeAValidPhoneNumber', {
            value: error.value
          });

        case 'string.duplicate':
          return i18n.t('errorMessageAlreadyTaken', {
            value: error.value
          });

        case 'string.changed':
          return i18n.t('errorMessageCannotBeChanged', {
            field: i18n.t(error.field)
          });
        case 'proCode.invalid':
          return i18n.t('invalidProCode', {
            field: i18n.t(error.field)
          });
        case 'date.base':
          return `${error.message}`;
      }
      break;

    case 'database-error':
      switch (error.key) {

        case 'not_unique':
          return i18n.t('errorMessageAnotherExists', {
            field: error.field,
            value: error.value
          });
      }
      break;

    case 'passport-error':
      switch (error.key) {

        case 'local':
          return i18n.t('errorMessageIncorrectEmailOrPassword');
      }
      break;

    case 'token-error':
      switch (error.key) {

        case 'password-update-token-disabled':
          return i18n.t('errorMessagePasswordUpdateLinkDisabled');
      }
      break;

    case 'unknown-consultant':
      return i18n.t('errorMessageNutritionConsultantDoesNotExist');

    case 'network-error':
      return i18n.t('errorMessageNetworkError');

    case 'invalid-accesstoken':
      return i18n.t('errorMessageYouAreNotConnected');

    case 'unauthorized-error':
      return i18n.t('errorMessageUnauthorized');

    case 'vat-error':
      return i18n.t('errorVATNumber');

    case 'not-found-error':
      return i18n.t('errorMessageNotFound');

    case 'gift-card-not-found-error':
      return i18n.t('errorMessageGiftCardNotFound');

    case 'gift-card-malformed-error':
      return i18n.t('errorMessageGiftCardMalformed');

    case 'gift-card-amount-error':
      return i18n.t('errorMessageGiftCardAmount');
  }
  console.log(error);
  return i18n.t('errorMessageAnErrorOccurred');
};
