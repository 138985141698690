import React, {useEffect, useState} from "react";
import {Trans, useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {useLocation} from "react-router-dom";
import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone';
import AccountTreeTwoToneIcon from "@mui/icons-material/AccountTreeTwoTone";
import AssessmentTwoToneIcon from "@mui/icons-material/AssessmentTwoTone";
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import FeaturedPlayListTwoToneIcon from '@mui/icons-material/FeaturedPlayListTwoTone';
import LocalShippingTwoToneIcon from "@mui/icons-material/LocalShippingTwoTone";
import PersonAddTwoToneIcon from "@mui/icons-material/PersonAddTwoTone";
import PhoneTwoToneIcon from '@mui/icons-material/PhoneTwoTone';
import SendTwoToneIcon from '@mui/icons-material/SendTwoTone';
import {ListItemButton} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { red } from "@mui/material/colors";
import { green } from "@mui/material/colors";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import {makeStyles} from '@mui/styles';

import {getLangPath, Link} from "../../../../helpers/multilingual";
import { SnackbarError, SnackbarSuccess } from "../../../../helpers/snackbar";
import useMoment from "../../../../helpers/useMoment";
import {DELEGATE_ROLE, NUTRITIONIST_CAREER, PARTNER_CAREER} from "../../../Authentication/store/constants";
import {hasAuthConsultant, userSelector} from "../../../Authentication/store/selectors";
import { authenticationService } from "../../../Authentication/store/services";
import {getConsultantLabels} from "../helpers/helpers";

import ProfilingTestModal from "./ProfilingTestModal";

const useStyles = makeStyles(() => ({
  root: {
    overflow: 'auto',
    '& a': {
      color: 'inherit',
      textDecoration: 'none',
    },
    '& .MuiCollapse-container': {

      '& .MuiListItem-button': {
        height: 50,
        paddingLeft: 50
      }
    },
    '& .MuiListItemIcon-root': {
      minWidth: 40
    },
    '& .MuiListItem-button': {
      height: 60,
      '& .MuiSvgIcon-root': {
        fontSize: '2em',
        fill: red[900]
      }
    },
  },
  cardProfile: {
    '& .membership,& .createdAt': {
      fontStyle: 'italic'
    },
    '& .greenColor': {
      color: green[800]
    },
    '& .MuiAvatar-colorDefault': {
      margin: 'auto',
      width: 150,
      height: 150,
      marginTop: 20,
      marginBottom: 20,
      position: 'relative',
      '& img': {
        width: 150,
        height: 150,
      },
      '& button': {
        position: 'absolute',
        top: 45,
        left: 0,
        display: 'none'
      },
      '& p': {
        position: 'absolute',
        top: 65,
        left: 65,
      },
      '&:hover': {
        '& button': {
          display: 'inline'
        }
      }
    }
  },
}));

const ConsultantActionsCard = ({consultant}) => {

  const classes = useStyles();
  const moment = useMoment();
  const location = useLocation();
  const { t } = useTranslation();
  const user = useSelector(userSelector);
  const userHasAuthConsultant = useSelector(hasAuthConsultant);
  const [profilingModalOpen, setProfilingModalOpen] = useState(false);
  const {primary, level} = getConsultantLabels(consultant.role, consultant.career);
  const [selected, setSelected] = useState(null);

  useEffect(() => {

    const match = location.pathname.replace(getLangPath(), '').split(`/${consultant.id}`);
    setSelected(match[1] || '');
  }, [location.pathname]);

  const handleCloseProfilingModal = () => {
    setProfilingModalOpen(false);
  };

  const handleSendWelcomeEmail = () => {

    authenticationService.sendWelcomeEmail(consultant.id)
      .then(() => {
        SnackbarSuccess(t('emailSent'));
      })
      .catch(() => {
        SnackbarError(t('errorMessageAnErrorOccurred'));
      });
  };

  return (
    <Card className={classes.cardProfile}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Avatar>
              <img src={consultant.profilePicture} alt=""/>
            </Avatar>
            <Typography variant={"h5"} align={"center"} gutterBottom>{consultant.firstName} {consultant.lastName}</Typography>
            {!!consultant.company && (
              <Typography variant={"h5"} align={"center"} gutterBottom>{consultant.company}</Typography>
            )}
            <Typography variant={"h6"} align={"center"}>{t(primary).toUpperCase()}{level ? ` - ${t('level', {lvl: level})}` : ''}</Typography>

            <Typography className={"createdAt"} align={"center"}>
              <Trans i18nKey={"createdAt"} values={{date: moment(consultant.createdAt).format('LL')}}>Created at {moment(consultant.createdAt).format('LL')}</Trans>
            </Typography>
            {consultant.career !== PARTNER_CAREER && moment(consultant.membershipEndAt).diff(moment(), 'days') > 0 && (
              <Typography className={"membership greenColor"} align={"center"}>
                <Trans i18nKey={"membershipEndAt"} values={{date:moment(consultant.membershipEndAt).format('LL')}}>Active membership until {moment(consultant.membershipEndAt).format('LL')}</Trans>
              </Typography>
            )}
            {consultant.career !== PARTNER_CAREER && moment(consultant.membershipEndAt).diff(moment(), 'days') < 0 && (
              <Typography className={"membership"} color={"error"} align={"center"}>
                <Trans i18nKey={"membershipExpired"}>Membership expired</Trans>
              </Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <Typography component="h3" variant={"h6"}>
                            Actions :
            </Typography>
            <List>
              <ListItemButton component={Link} to={`/pro/network/${consultant.id}`} selected={selected === ""}>
                <ListItemIcon>
                  <AccountCircleTwoToneIcon />
                </ListItemIcon>
                <ListItemText primary={t("generalInfo")} />
              </ListItemButton>
              <Divider/>
              <ListItemButton component={"a"} href={`tel:${consultant.phone}`}>
                <ListItemIcon>
                  <PhoneTwoToneIcon />
                </ListItemIcon>
                <ListItemText primary={t("call")} />
              </ListItemButton>
              <Divider/>
              <ListItemButton component={"a"} href={`mailto:${consultant.email}`}>
                <ListItemIcon>
                  <SendTwoToneIcon />
                </ListItemIcon>
                <ListItemText primary={t("sendEmail")} />
              </ListItemButton>
              <Divider/>
              {(consultant.career !== NUTRITIONIST_CAREER && consultant.career !== PARTNER_CAREER) && (
                <>
                  <ListItemButton component={Link} to={`/pro/network/${consultant.id}/network`} selected={selected === "/network"}>
                    <ListItemIcon>
                      <AccountTreeTwoToneIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("structure")} />
                  </ListItemButton>
                  <Divider/>
                </>
              )}
              <ListItemButton component={Link} to={`/pro/network/${consultant.id}/stats`} selected={selected === "/stats"}>
                <ListItemIcon>
                  <AssessmentTwoToneIcon />
                </ListItemIcon>
                <ListItemText primary={t("statistics")} />
              </ListItemButton>
              <Divider/>
              {consultant.profiling && (
                <>
                  <ListItemButton onClick={() => setProfilingModalOpen(true)}>
                    <ListItemIcon>
                      <FeaturedPlayListTwoToneIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("watchProfilingTitle")} />
                  </ListItemButton>
                  <Divider/>
                </>
              )}
              {consultant.career === PARTNER_CAREER && consultant.parent?.id === user.id && (
                <>
                  <ListItemButton component={Link} to={`/pro/network/${consultant.id}/office`} selected={selected === "/office"}>
                    <ListItemIcon>
                      <BusinessCenterIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("office")} />
                  </ListItemButton>
                  <Divider/>
                  <ListItemButton component={Link} to={`/pro/network/${consultant.id}/update`} selected={selected === "/update"}>
                    <ListItemIcon>
                      <EditTwoToneIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("updateInformation")} />
                  </ListItemButton>
                  <Divider/>
                  <ListItemButton component={Link} to={`/pro/network/${consultant.id}/update/shipping`} selected={selected === "/update/shipping"}>
                    <ListItemIcon>
                      <LocalShippingTwoToneIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("updateShippingAddress")} />
                  </ListItemButton>
                  <Divider/>
                </>
              )}
              {userHasAuthConsultant && (consultant.career !== NUTRITIONIST_CAREER && consultant.career !== PARTNER_CAREER && consultant.role === DELEGATE_ROLE) && (
                <>
                  <ListItemButton component={Link} to={`/pro/network/${consultant.id}/partner/add`} selected={selected === "/partner/add"}>
                    <ListItemIcon>
                      <PersonAddTwoToneIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("addPartner")} />
                  </ListItemButton>
                  <Divider/>
                </>
              )}
              {consultant.role === DELEGATE_ROLE && consultant.parent?.id === user.id && (
                <>
                  <ListItemButton onClick={handleSendWelcomeEmail}>
                    <ListItemIcon>
                      <SendTwoToneIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("resendIdentifiers")} />
                  </ListItemButton>
                  <Divider/>
                </>
              )}
            </List>
          </Grid>
        </Grid>
      </CardContent>
      {consultant.profiling && (
        <ProfilingTestModal open={profilingModalOpen} handleClose={handleCloseProfilingModal} test={consultant.profiling.test}/>
      )}
    </Card>
  );
};
export default ConsultantActionsCard;
