import axios  from '../../../../helpers/axios';

export const userService = {
  getChildren,
  searchUsers,
  getUserById,
  getUserByIds,
  addFreeUserOrder,
  addUserOrder,
  getUserUnpaidIntent,
  transferUser,
  getShippingMethods,
  getShippingMethodsWithoutRestriction,
  creditUser,
  findUsers,
  addUser,
  updateUser,
  updateUserAllowInstantTransfers,
  getUserOrders,
  getUserShippingAddresses,
  addUserShippingAddress,
  updateUserShippingAddress,
  removeUserShippingAddress,
  setAsDefaultShippingAddress,
  setAsDefaultBillingAddress,
  updateMembership,
  getUserGiftCards,
  getUserAvailableGiftCards,
  getUserGiftCardByCode,
  sendUserGiftCard,
  updateUserAuthorizations,
  getUserLastDraftOrder,
  getNotes,
  addNoteToUser,
  updateUserNote,
  getPointsDetails,
  getProsDetails,
  removeNoteFromUser,
  removeUser,
};

function getChildren(userId) {

  return new Promise((resolve, reject) => {

    axios.get(`/user/${userId}/tree/children`)
      .then(({children}) => {
        resolve(children);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function searchUsers(search, target = 'all') {

  return new Promise((resolve, reject) => {

    axios.get(`/user/search/${target}/${search}`)
      .then(({users}) => {
        resolve(users);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getUserById(id) {

  return new Promise((resolve, reject) => {

    axios.get(`/user/${id}`)
      .then(({user}) => {
        resolve(user);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getUserByIds(userIds) {

  return new Promise((resolve, reject) => {

    axios.post(`/user/userIds`, {userIds})
      .then(({users}) => {
        resolve(users);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function addFreeUserOrder(user, products, withShipping, shippingMethodId, shippingAddressId) {

  return new Promise((resolve, reject) => {

    axios.post(`/admin/user/${user.id}/order/free`, {products, withShipping, shippingMethodId, shippingAddressId})
      .then(({order}) => {
        resolve(order);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function addUserOrder(user, products, shippingMethodId, shippingAddressId, shippingPickupPoint, paymentMethod, useWallet, giftCardCodes, withShipping, promotionCode, freeShipping, comment, stripeId) {

  return new Promise((resolve, reject) => {

    axios.post(`/admin/user/${user.id}/order`, {products, shippingMethodId, shippingAddressId, shippingPickupPoint, paymentMethod, useWallet, giftCardCodes, withShipping, promotionCode, freeShipping, comment, stripeId})
      .then(({order}) => {
        resolve(order);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getUserUnpaidIntent(orderId) {

  return new Promise((resolve, reject) => {
    axios.get(`/admin/user/${orderId}/pay`)
      .then(({order}) => {
        resolve(order);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}


function getShippingMethods(country, zip, weight, price = 0) {

  return new Promise((resolve, reject) => {

    axios.get(`/shop/shipping/methods/${country}/${zip}/weight/${weight}/price/${price}`)
      .then(({shippingMethods}) => {
        resolve(shippingMethods);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getShippingMethodsWithoutRestriction(country, zip, weight, price = 0) {

  return new Promise((resolve, reject) => {

    axios.get(`/shop/admin/shipping/methods/${country}/${zip}/weight/${weight}/price/${price}`)
      .then(({shippingMethods}) => {
        resolve(shippingMethods);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function transferUser(user, pro) {

  return new Promise((resolve, reject) => {

    axios.get(`/admin/user/${user.id}/transfer/${pro.id}`)
      .then(() => {
        resolve();
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function creditUser(user, data) {

  return new Promise((resolve, reject) => {

    axios.post(`/admin/user/wallet/${user.id}`, data)
      .then(() => {
        resolve();
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function setAsDefaultShippingAddress(userId, shippingAddress) {

  return new Promise((resolve, reject) => {

    axios.patch(`/admin/user/${userId}/shippingAddress/${shippingAddress.id}/default`)
      .then(() => {
        resolve({shippingAddress});
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function setAsDefaultBillingAddress(userId, shippingAddress) {

  return new Promise((resolve, reject) => {

    axios.patch(`/admin/user/${userId}/shippingAddress/${shippingAddress.id}/defaultBilling`)
      .then(() => {
        resolve({shippingAddress});
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}
function findUsers(searchQuery) {

  return new Promise((resolve, reject) => {

    axios.get(`/admin/users/${searchQuery ? searchQuery : ''}`)
      .then(({users}) => {
        resolve(users);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function addUser(data) {

  return new Promise((resolve, reject) => {

    axios.post(`/user`, data)
      .then(({user}) => {
        resolve(user);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function updateUser(id, data) {

  return new Promise((resolve, reject) => {

    axios.patch(`/user/${id}`, data)
      .then(({user}) => {
        resolve(user);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getUserOrders(userId) {

  return new Promise((resolve, reject) => {

    axios.get(`/admin/user/${userId}/orders`)
      .then(({orders}) => {
        resolve(orders);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getUserShippingAddresses(userId) {

  return new Promise((resolve, reject) => {

    axios.get(`/admin/user/${userId}/shippingAddresses`)
      .then(({shippingAddresses}) => {
        resolve(shippingAddresses);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function addUserShippingAddress(userId, data) {

  return new Promise((resolve, reject) => {

    axios.post(`/admin/user/${userId}/shippingAddress`, data)
      .then(({shippingAddress}) => {
        resolve(shippingAddress);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function updateUserShippingAddress(userId, shippingAddressId, data) {

  return new Promise((resolve, reject) => {

    axios.patch(`/admin/user/${userId}/shippingAddress/${shippingAddressId}`, data)
      .then(({shippingAddress}) => {
        resolve(shippingAddress);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function removeUserShippingAddress(userId, shippingAddressId) {

  return new Promise((resolve, reject) => {

    axios.delete(`/admin/user/${userId}/shippingAddress/${shippingAddressId}`)
      .then(({shippingAddress}) => {
        resolve(shippingAddress);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function updateMembership(userId, membershipEndAt) {

  return new Promise((resolve, reject) => {

    axios.patch(`/admin/user/${userId}/membership`, {
      membershipEndAt
    })
      .then(() => {
        resolve();
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}
//
//function updateCertification(userId, isCertified) {
//
//    return new Promise((resolve, reject) => {
//
//        axios.patch(`/admin/user/${userId}/certification`, {
//            isCertified
//        })
//            .then(() => {
//                resolve();
//            })
//            .catch((e) => {
//                reject(e.response.data);
//            });
//    });
//}

function getUserGiftCards(userId) {

  return new Promise((resolve, reject) => {
    axios.get(`/admin/user/${userId}/gifts`)
      .then(({giftCards}) => {
        resolve(giftCards);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}
function getUserAvailableGiftCards(userId) {

  return new Promise((resolve, reject) => {
    axios.get(`/admin/user/${userId}/gifts/available`)
      .then(({giftCards}) => {
        resolve(giftCards);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getUserGiftCardByCode(userId, code) {

  return new Promise((resolve, reject) => {
    axios.get(`/admin/user/${userId}/gift/${code}`)
      .then(({giftCard}) => {
        resolve(giftCard);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getUserLastDraftOrder(userId) {

  return new Promise((resolve, reject) => {
    axios.get(`/admin/user/${userId}/lastDraftOrder`)
      .then(({order}) => {
        resolve(order);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function sendUserGiftCard(userId, values) {

  return new Promise((resolve, reject) => {

    axios.post(`/admin/user/${userId}/gift`, {values})
      .then(() => {
        resolve();
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

//function sendPartnershipRequest(userId) {
//
//    return new Promise((resolve, reject) => {
//
//        axios.post(`/admin/user/${userId}/partnership/request`)
//            .then(() => {
//                resolve();
//            })
//            .catch((e) => {
//                reject(e.response.data);
//            });
//    });
//}


function updateUserAuthorizations(id, data) {

  return new Promise((resolve, reject) => {

    axios.patch(`admin/user/${id}/authorizations/`, {data})
      .then(({user}) => {
        resolve(user);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getNotes(id) {

  return new Promise((resolve, reject) => {

    axios.get(`/admin/user/${id}/notes`)
      .then(({notes}) => {
        resolve(notes);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getPointsDetails(id, year, month) {

  return new Promise((resolve, reject) => {

    axios.get(`/admin/user/${id}/pointsDetails/${year}/${month}`)
      .then(({points}) => {
        resolve(points);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getProsDetails(id, year, month) {

  return new Promise((resolve, reject) => {

    axios.get(`/admin/user/${id}/prosDetails/${year}/${month}`)
      .then(({pros}) => {
        resolve(pros);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });

}

function addNoteToUser(userId, data) {

  return new Promise((resolve, reject) => {

    axios.post(`/admin/user/${userId}/notes`, {data})
      .then(({note}) => {
        resolve(note);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function updateUserNote(noteId, data) {

  return new Promise((resolve, reject) => {

    axios.patch(`/admin/user/notes/${noteId}`, {data})
      .then(() => {
        resolve();
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function updateUserAllowInstantTransfers(userId, allow) {

  return new Promise((resolve, reject) => {

    axios.patch(`/admin/user/${userId}/allowTransfers/${allow}`)
      .then(() => {
        resolve();
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function removeNoteFromUser(noteId) {

  return new Promise((resolve, reject) => {

    axios.delete(`/admin/user/notes/${noteId}`)
      .then(() => {
        resolve();
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function removeUser(userId, targetUser) {

  return new Promise((resolve, reject) => {

    axios.delete(`/admin/user/remove/${userId}/${targetUser?.id ?? 'none'}`)
      .then(() => {
        resolve();
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}