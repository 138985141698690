/* eslint-disable no-use-before-define */

import React from 'react';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {Autocomplete} from "@mui/material";
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';

const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
const checkedIcon = <CheckBoxIcon fontSize="small"/>;

const SearchCheckboxAutocomplete = ({handleChange, options, selected, renderOption = false, label, ...rest}) => (
  <Autocomplete
    id="checkboxAutocomplete"
    options={options}
    disableCloseOnSelect
    getOptionLabel={() => ""}
    isOptionEqualToValue={() => true}
    onChange={handleChange}
    value={""}
    renderOption={(props, option) => (
      !renderOption
        ? <li {...props} key={option?.id}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{marginRight: 8}}
            checked={!!selected.find(data => data === option.id)}
          />
          {option?.name}
        </li>
        : renderOption
    )}
    renderInput={(params) => (
      <TextField {...params} variant="outlined" label={label} placeholder={label}/>
    )}
    {...rest}
  />
);
export default SearchCheckboxAutocomplete;
