import {createSelector} from "reselect";

import {homeReducerKey} from "./reducer";

const home = globalState => globalState[homeReducerKey];

export const treesCountSelector = createSelector(
  [home],
  (home) => home.treesCount);

export const topbarSelector = createSelector(
  [home],
  (home) => home.topbar);

export const slidersSelector = createSelector(
  [home],
  (home) => home.sliders);

export const sectionsSelector = createSelector(
  [home],
  (home) => {

    let obj = {};
    home.sections.map(section => {
      obj[section.place] = section;
    });

    return obj;
  });