import { createSelector } from 'reselect';

import { bankReducerKey,initialState } from "./reducer";

const bankSelector = (state) => state[bankReducerKey] || initialState.bank;

export const walletSelector = () => createSelector(
  [bankSelector],
  (bank) => bank.wallet);

export const walletTransferRequestedSelector = () => createSelector(
  [bankSelector],
  (bank) => bank.transferRequested);

export const walletHistoriesSelector = () => createSelector(
  [bankSelector],
  (bank) => {
    if(!bank.histories) {
      return initialState.bank.histories;
    }
    return bank.histories;
  });


export const walletTransferModalOpenSelector = () => createSelector(
  [bankSelector],
  (bank) => bank.modalOpen);

export const walletCommissionsCreditNotesSelector = () => createSelector(
  [bankSelector],
  (bank) => {
    if(!bank.commissionsCreditNotes) {
      return initialState.commissionsCreditNotes;
    }
    return bank.commissionsCreditNotes;
  });
