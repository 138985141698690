import React, {useState} from "react";
import { Trans } from "react-i18next";
import FormatListNumberedTwoToneIcon from "@mui/icons-material/FormatListNumberedTwoTone";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import {makeStyles} from '@mui/styles';

import CustomerOrderActionsModal from "./CustomerOrderActionsModal";

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiDivider-root': {
      margin: -1,
      [theme.breakpoints.down('xs')]: {
        display: 'none'
      },
    },
    [theme.breakpoints.down('xs')]: {
      '& .MuiCard-root': {
        overflow: 'auto'
      }
    },
    '& .MuiTypography-subtitle1': {
      maxHeight: 42,
      lineHeight: '20px',
      overflow: 'hidden'
    },
    '& img': {
      maxWidth: '100%',
      maxHeight: 80
    }
  },
  listItems: {
    [theme.breakpoints.up('sm')]: {
      minHeight: '30vh',
      overflow: 'auto'
    }
  },
  item: {
    [theme.breakpoints.up('sm')]: {
      '& .MuiListItem-root': {
        borderRight: '1px solid #DDD'
      }
    },
  },
  detailsRowPrice: {
    paddingTop: 20
  },
  divider: {
    //borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
  }
}));

const CustomerOrderDetails = ({order}) => {

  const classes = useStyles();
  const [actionsModalIsOpen, setActionsModalIsOpen] = useState(false);

  const handleOpenOrderActions = () => {

    setActionsModalIsOpen(true);
  };

  const handleCloseOrderActions = () => {

    setActionsModalIsOpen(false);
  };

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <Typography align={"left"}>
          <Button startIcon={<FormatListNumberedTwoToneIcon/>} color={"primary"} variant={"outlined"} onClick={handleOpenOrderActions}>
            <Trans i18nKey={"orderActions"}>Order actions</Trans>
          </Button>
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <List className={classes.listItems}>
          {order.products.map((item, key) => (
            <React.Fragment key={key}>
              <ListItem className={classes.item}>
                <Grid container justify={"center"} alignItems={"center"}>
                  <Grid item xs={4}>
                    <Grid container justify={"center"} alignItems={"center"}>
                      <img
                        width={80}
                        height={80}
                        src={item.originalProduct.image[200]}
                        alt={item.originalProduct.detail.name}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={8}>
                    <Grid container justify={"center"} alignItems={"center"}>
                      <Grid item xs={8}>
                        <Typography variant="subtitle1">
                          {item.originalProduct.detail.name}
                        </Typography>
                        <Typography variant="body1">
                          <b>{item.quantity} x</b> {(parseFloat(item.price)/parseFloat(item.quantity)).toFixed(2)} {item.currency}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </ListItem>
              {order.products.length > 1 && (
                <Divider/>
              )}
            </React.Fragment>
          ))}
        </List>
      </Grid>
      <Divider orientation="vertical" flexItem/>
      <Grid item xs={12} sm={6}>
        <List>
          <ListItem className={classes.detailsRowPrice}>
            <Grid container justify={"center"} spacing={4}>
              <Grid item xs={12} className={classes.divider}>
                <Grid container justify={"center"} alignItems={"center"} spacing={4}>
                  <Grid item xs={7}>
                    <Grid container justify={"flex-end"} alignItems={"center"}>
                      <Typography variant="subtitle1"><Trans i18nKey={"shippingPrice"}>Shipping price</Trans></Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography align="right" variant="subtitle1">{order.shippingPrice.toFixed(2)} {order.currency}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Divider/>
            </Grid>
          </ListItem>
          <ListItem className={classes.detailsRowPrice}>
            <Grid container justify={"center"} spacing={4}>
              <Grid item xs={12} className={classes.divider}>
                <Grid container justify={"center"} alignItems={"center"} spacing={4}>
                  <Grid item xs={7}>
                    <Grid container justify={"flex-end"} alignItems={"center"}>
                      <Typography variant="subtitle1"><Trans i18nKey={"subTotal"}>Sub total</Trans></Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography align="right" variant="subtitle1">{order.totalPrice.toFixed(2)} {order.currency}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </ListItem>
          {Object.keys(order.taxesDetails).map(taxesRate => taxesRate > 0 && (
            <ListItem key={taxesRate} className={classes.detailsRowPrice}>
              <Grid container justify={"center"} spacing={4}>
                <Grid item xs={12} className={classes.divider}>
                  <Grid container justify={"center"} alignItems={"center"} spacing={4}>
                    <Grid item xs={7}>
                      <Grid container justify={"flex-end"} alignItems={"center"}>
                        <Typography variant="subtitle1"><Trans i18nKey={"taxes"}>Taxes</Trans> {taxesRate}%</Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={5}>
                      <Typography align="right" variant="subtitle1">{parseFloat(order.taxesDetails[taxesRate]).toFixed(2)} {order.currency}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </ListItem>
          ))}
          <ListItem className={classes.detailsRowPrice}>
            <Grid container justify={"center"} spacing={4}>
              <Grid item xs={12}>
                <Grid container justify={"center"} alignItems={"center"} spacing={4}>
                  <Grid item xs={7}>
                    <Grid container justify={"flex-end"} alignItems={"center"}>
                      <Typography variant="subtitle1"><b><Trans i18nKey={"totalPrice"}>Total price</Trans></b></Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography align="right" variant="subtitle1"><b>{(parseFloat(order.totalPrice) + parseFloat(order.taxes)).toFixed(2)} {order.currency}</b></Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </ListItem>
        </List>
      </Grid>
      <CustomerOrderActionsModal order={order} open={actionsModalIsOpen} onClose={handleCloseOrderActions}/>
    </Grid>
  );
};

export default CustomerOrderDetails;
