import React from 'react';
import {Box, Grid, Stack, Typography} from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import useMediaQuery from "@mui/material/useMediaQuery";

import theme from "../../../../assets/styles/theme/theme";

export default function CustomSectionColumn3({section}) {

  const isDownSm = useMediaQuery(theme.breakpoints.down('sm'));

  if(!section?.name) {
    return null;
  }

  return (
    <Box
      sx={{
        margin: '40px 0 0',
      }}
    >
      <Container maxWidth={'xxl'}>
        <Card elevation={0}>
          <CardContent>
            <Typography
              dangerouslySetInnerHTML={{ __html: section.bannerTitle}}
              sx={{
                fontFamily: 'Raleway',
                fontWeight: '600',
                padding: '8px 8px',
                fontSize: {xs: '2.5rem', sm: '2rem', md: '2.5rem', lg: '3.5rem', xl: '3.5rem', xxl: '4.5rem'},
                lineHeight: '1',
                overflow: 'hidden',
                color: 'green',
                textAlign: {xs: 'left', sm: 'left'},
                zIndex: 1,
                '& p': {
                  margin: 0,
                },
                '& span': {
                  fontFamily: 'Raleway',
                  fontWeight: '600',
                  fontSize: {xs: '2.5rem', sm: '2rem', md: '2.5rem', lg: '3.5rem', xl: '3.5rem', xxl: '4.5rem'},
                }
              }}/>
            <Grid container spacing={2} alignItems={"center"}>
              {[1, 2, 3, 4].map((i) => (
                <Grid item xs={12} sm={3} md={3} key={`section-${section.id}-${i}`}>
                  <Stack gap={0} alignItems={"center"} sx={{
                    borderRight: !isDownSm && (i < 4) ? '1px dashed #7C909B' : 'none',
                    p: '32px 16px 32px 0',
                  }}>
                    <Box component={'img'} alt={""} src={section[`image${i}`]} sx={{
                      maxHeight: 90,
                      height: 90,
                      width: 'auto',
                    }}/>
                    <Typography
                      dangerouslySetInnerHTML={{ __html: section[`text${i}`]}}
                      sx={{
                        fontSize: {xs: '1rem', sm: '0.875rem', md:'1.1rem', lg: '1.1rem', xl: '1.2rem', xxl: '1.2rem'},
                        textAlign: 'center',
                        color: 'primary.light',
                        fontFamily: 'Open sans',
                        '& p': {
                          margin: 0,
                          textAlign: 'center',
                        }
                      }}
                    />
                  </Stack>
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
}
