import React, {useState} from "react";
import {Trans, useTranslation} from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import GetAppIcon from '@mui/icons-material/GetApp';
import PaymentOutlinedIcon from '@mui/icons-material/PaymentOutlined';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import SendTwoToneIcon from '@mui/icons-material/SendTwoTone';
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { grey } from "@mui/material/colors";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import {makeStyles} from '@mui/styles';

import Cookie from "../../../../helpers/cookie";
import {Link} from "../../../../helpers/multilingual";
import {SnackbarConfirm, SnackbarFormattedError, SnackbarSuccess} from "../../../../helpers/snackbar";
import useMoment from "../../../../helpers/useMoment";
import {customersService} from "../store/services";

const useStyle = makeStyles((theme) => ({

  root: {
    overflow: 'auto',
    minWidth: 400,
    '& .MuiContainer-root': {
      marginTop: 20,
      marginBottom: 20
    },
    '& img': {
      maxWidth: '100%',
      maxHeight: 80
    }
  },
  closeButton: {
    top: 10,
    right: 15,
    cursor: 'pointer',
    position: 'absolute',
    fontSize: 36,
    color: grey[500],
    [theme.breakpoints.down('xs')]: {
      top: 10,
      right: 20,
      fontSize: 40,
    },
  },
  addProductButton: {
    marginBottom: 30
  }
}));

const CustomerOrderActionsModal = ({order, onClose, open, onChange}) => {

  const classes = useStyle();
  const moment = useMoment();
  const theme = useTheme();
  const {t} = useTranslation();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const [emailSent, setEmailSent] = useState(false);
  const [orderDeleted, setOrderDeleted] = useState(false);

  const handleSendPaymentEmail = () => {

    setEmailSent(true);
    customersService.sendOrderPaymentEmail(order.id)
      .then(() => SnackbarSuccess(t('customerPaymentEmailSent')))
      .catch(e => {
        SnackbarFormattedError(e.error);
        setEmailSent(false);
      });
  };

  const handleDeleteRecommendation = () => {

    SnackbarConfirm(t('orderRecommendationDeleteDescription'), () => {
      customersService.deleteRecommendation(order.id)
        .then(() => {
          onClose();
          onChange();
          setOrderDeleted(true);
        })
        .catch(e => SnackbarFormattedError(e.error));
    });
  };

  return (
    <Dialog onClose={onClose} open={open} fullScreen={fullScreen}>
      <CloseIcon className={classes.closeButton} onClick={onClose}/>
      <DialogTitle id="responsive-dialog-title"><Trans i18nKey="orderActions">Order actions</Trans></DialogTitle>
      <DialogContent>
        <Card className={classes.root} elevation={0}>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography>
                  <b><Trans i18nKey={"orderHistory"}>Order history</Trans>:</b>
                </Typography>
              </Grid>
              {order.status === 'recommendation' && (
                <Grid item xs={12}>
                  <Typography component={"i"}>
                    <Trans i18nKey={"recommendationOrderFromNutritionConsultant"}>Recommendation order from nutrition consultant</Trans>
                  </Typography>
                </Grid>
              )}
              {order.status === 'free' && (
                <Grid item xs={12}>
                  <Typography component={"i"}>
                    <Trans i18nKey={"freeOrderFromAGS"}>Free order from AGS</Trans>
                  </Typography>
                </Grid>
              )}
              {order.status === 'deferred' && (
                <Grid item xs={12}>
                  <Typography component={"i"}>
                    <Trans i18nKey={"deferredOrderDueToOutOfStock"}>Deferred order due to out of stocks on previous order</Trans>
                  </Typography>
                </Grid>
              )}
              <Grid item xs={12}>
                <Typography component={"i"}>
                  {moment(order.createdAt).format('LLL')}: <Trans i18nKey={"orderCreated"}>Order created</Trans>
                </Typography>
              </Grid>
              {order.invoice && (
                <Grid item xs={12}>
                  <Typography component={"i"}>
                    {moment(order.invoice.createdAt).format('LLL')}: <Trans i18nKey={"invoiceCreated"}>Invoice created</Trans>
                  </Typography>
                </Grid>
              )}
              {order.creditNote && (
                <Grid item xs={12}>
                  <Typography component={"i"}>
                    {moment(order.creditNote.createdAt).format('LLL')}: <Trans i18nKey={"orderRefunded"}>Order refunded</Trans>
                  </Typography>
                </Grid>
              )}
              {order.status !== 'recommendation' && (
                <>
                  {order.shippingStatus === 'noShipping' && (
                    <Grid item xs={12}>
                      <Typography component={"i"}>
                        <Trans i18nKey={"thereIsNotShippingForThisOrder"}>There is no shipping for this order</Trans>
                      </Typography>
                    </Grid>
                  )}
                  {order.shippingStatus !== 'noShipping' && (
                    <>
                      {!order.sent && (
                        <Grid item xs={12}>
                          <Typography component={"i"}>
                            <Trans i18nKey={"orderAwaitingPreparation"}>Order awaiting preparation</Trans>
                          </Typography>
                        </Grid>
                      )}
                      {order.sent && (
                        <>
                          <Grid item xs={12}>
                            <Typography component={"i"}>
                              {moment(order.sent).format('LLL')}: <Trans i18nKey={"orderSent"}>Order sent</Trans>
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography component={"i"}>
                              <Trans i18nKey={"orderStatus"}>Order status</Trans>: <b>{t(`${order.shippingStatus}DeliveryStatus`)}</b>
                            </Typography>
                          </Grid>
                        </>
                      )}
                    </>
                  )}
                </>
              )}
              <Grid item xs={12}>
                <Divider/>
              </Grid>
              {order.invoice && (
                <Grid item xs={12}>
                  <Button fullWidth startIcon={<GetAppIcon/>} component={"a"} variant={"outlined"} target={"_blank"} href={`${process.env.REACT_APP_API_URL}/storage/order/${order.invoice.orderId}/invoice.pdf?t=${Cookie.get('storage-token')}`}>
                    <Trans i18nKey={"downloadInvoice"}>Download the invoice</Trans>
                  </Button>
                </Grid>
              )}
              {order.creditNote && (
                <Grid item xs={12}>
                  <Button fullWidth component={"a"} variant={"outlined"} target={"_blank"} href={`${process.env.REACT_APP_API_URL}/storage/order/${order.creditNote.orderId}/credit-note.pdf?t=${Cookie.get('storage-token')}`}>
                    <Trans i18nKey={"downloadCreditNote"}>Download the credit note</Trans>
                  </Button>
                </Grid>
              )}
              {order.shippingTrackingLink && (
                <Grid item xs={12}>
                  <Button fullWidth startIcon={<SearchTwoToneIcon/>} component={"a"} variant={"outlined"} target={"_blank"} href={`${order.shippingTrackingLink}`}>
                    <Trans i18nKey={"orderTracking"}>Order tracking</Trans>
                  </Button>
                </Grid>
              )}
              {order.status === 'recommendation' && (
                <>
                  <Grid item xs={12}>
                    <Button fullWidth startIcon={<PaymentOutlinedIcon/>} variant={"outlined"} component={Link} to={`/pro/customer/${order.userId}/order/${order.id}`}>
                      <Trans i18nKey={"payTheOrder"}>Pay the order</Trans>
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Button disabled={emailSent || orderDeleted} fullWidth startIcon={<SendTwoToneIcon/>} variant={"outlined"} onClick={handleSendPaymentEmail}>
                      <Trans i18nKey={"sentPaymentEmail"}>Send again the payment link</Trans>
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Button disabled={orderDeleted} fullWidth startIcon={<DeleteOutlineIcon/>} variant={"outlined"} onClick={handleDeleteRecommendation}>
                      <Trans i18nKey={"deleteRecommendation"}>Delete recommendation</Trans>
                    </Button>
                  </Grid>
                  {orderDeleted && (
                    <Grid item xs={12}>
                      <Typography color={"error"} align={"center"}>
                        <Trans i18nKey={"deletedRecommendationDescription"}>The recommendation was successfully deleted</Trans>
                      </Typography>
                    </Grid>
                  )}
                </>
              )}
            </Grid>
          </CardContent>
        </Card>
      </DialogContent>
    </Dialog>
  );
};
export default CustomerOrderActionsModal;
