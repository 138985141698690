import { call, put, takeEvery } from 'redux-saga/effects';

import { homeActions } from "./actions";
import * as actionsType from './constants';
import { homeService } from "./services";

export const homeSagaKey = 'home';

function* getTreesCount() {

  const count = yield call(homeService.getTreesCount());
  yield put(homeActions.getTreesCountSuccess(count));
}

function* getTopbar(action) {

  const topbar = yield call(homeService.getTopbar, action.lang);
  yield put(homeActions.getTopbarSuccess(topbar));
}

function* getSliders(action) {
  const sliders = yield call(homeService.getSliders, action.lang);
  yield put(homeActions.getSlidersSuccess(sliders));
}

function* getSections(action) {

  const sections = yield call(homeService.getDisplayedSections, action.lang);
  yield put(homeActions.getSectionsSuccess(sections));
}

export default function* watchHome() {

  yield takeEvery(actionsType.GET_TREES_COUNT_REQUEST, getTreesCount);
  yield takeEvery(actionsType.GET_TOPBAR_REQUEST, getTopbar);
  yield takeEvery(actionsType.GET_SLIDERS_REQUEST, getSliders);
  yield takeEvery(actionsType.GET_SECTIONS_REQUEST, getSections);
}
