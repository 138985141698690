import React, { useState } from "react";
import { Trans } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { grey } from "@mui/material/colors";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import {makeStyles} from '@mui/styles';

import ProductsAutocomplete from "../../../../Customer/Shop/components/Search/ProductsAutocomplete";
import { customersActions } from "../../store/actions";
import { customerSelector, loadingSelector } from "../../store/selectors";

import OrderListItems from "./OrderListItems";

const useStyle = makeStyles((theme) => ({

  root: {
    overflow: 'auto',
    minWidth: 400,
    '& .MuiContainer-root': {
      marginTop: 20,
      marginBottom: 20
    },
    '& img': {
      maxWidth: '100%',
      maxHeight: 80
    }
  },
  closeButton: {
    top: 10,
    right: 15,
    cursor: 'pointer',
    position: 'absolute',
    fontSize: 36,
    color: grey[500],
    [theme.breakpoints.down('xs')]: {
      top: 10,
      right: 20,
      fontSize: 40,
    },
  },
  addProductButton: {
    marginBottom: 30
  }
}));

const AddCustomerOrderModal = ({handleClose, open}) => {

  const classes = useStyle();
  const theme = useTheme();
  const dispatch = useDispatch();
  const customer = useSelector(customerSelector());
  const loading = useSelector(loadingSelector());
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const [selecting, setSelecting] = useState(false);
  const [products, setProducts] = useState({});

  const handleSelectProduct = (product) => {

    setSelecting(false);
    if(!products[product.id]) {
      setProducts({...products, [product.id]: {...product, quantity: 1}});
    }
  };

  const handleRemoveProduct = (productId) => {

    let oldProducts = products;
    delete oldProducts[productId];
    setProducts({...oldProducts});
  };

  const setProductQuantity = (product, quantity) => {

    if(quantity === 0) {
      handleRemoveProduct(product.id);
      return;
    }
    setProducts({...products, [product.id]: {...product, quantity: quantity}});
  };

  const handleSubmit = () => {

    dispatch(customersActions.addCustomerOrderRequest(customer, Object.keys(products).map(productId => ({id: productId, quantity: products[productId].quantity}))));
    setProducts({});
    handleClose();
  };

  return (
    <Dialog onClose={handleClose} open={open} fullScreen={fullScreen}>
      <CloseIcon className={classes.closeButton} onClick={handleClose}/>
      <DialogTitle id="responsive-dialog-title"><Trans i18nKey="createOrder">Create order</Trans></DialogTitle>
      <DialogContent>
        <Card elevation={0} className={classes.root}>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container justify={"center"}>
                  <Grid item xs={12}>
                    <OrderListItems items={products} setProductQuantity={setProductQuantity} handleRemoveProduct={handleRemoveProduct}/>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Typography component={"i"}><Trans i18nKey={"pricesExclTaxes"}>The prices displayed are taxes excluded</Trans></Typography>
              </Grid>
              {selecting && (
                <Grid item xs={12}>
                  <ProductsAutocomplete country={customer.shippingAddress?.country || 'LU'} autoFocus={true} variant={"outlined"} onSelect={handleSelectProduct} withPrice/>
                </Grid>
              )}
              {!selecting && (
                <Grid className={classes.addProductButton} item xs={12}>
                  <Button fullWidth variant={"outlined"} onClick={() => setSelecting(true)}>
                    <Trans i18nKey={"addProductToOrder"}>Add product to the order</Trans>
                  </Button>
                </Grid>
              )}
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item>
                    <Button
                      type="button"
                      fullWidth
                      variant="outlined"
                      color="primary"
                      className={classes.cancel}
                      onClick={handleClose}
                    >
                      <Trans i18nKey={"cancelButton"}>
                                                Cancel
                      </Trans>
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      onClick={handleSubmit}
                      disabled={Object.keys(products).length === 0 || loading}
                    >
                      <Trans i18nKey={"sendOrderToClient"}>
                                                Send the order to client
                      </Trans>
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </DialogContent>
    </Dialog>
  );
};
export default AddCustomerOrderModal;
