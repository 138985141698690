import React, {useRef} from "react";
import {getElementAtEvent, Line} from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import {makeStyles} from '@mui/styles';
import { Chart, registerables } from 'chart.js';

import useMoment from "../../helpers/useMoment";
if(Array.isArray(registerables)) {
  Chart.register(...registerables);
}

const LineChartByYearAndMonth = ({
  data,
  heightLg,
  heightMd,
  heightSm,
  primaryColor,
  secondaryColor= {border: 'rgb(160,160,160)', background: 'rgba(160,160,160, 0.04)'},
  tertiaryColor= {border: 'rgb(230,230,230)', background: 'rgba(230,230,230, 0.04)'},
  onEventClick = () => {}
}) => {

  const useStyles = makeStyles(theme => ({
    root: {
      '& canvas': {
        width: '100% !important',
        height: `${heightLg}px !important`,
        [theme.breakpoints.down('md')]: {
          height: `${heightMd}px !important`
        },
        [theme.breakpoints.down('sm')]: {
          height: `${heightSm}px !important`,
        }
      }
    }
  }));

  const classes = useStyles();
  const chartRef = useRef();
  const { t } = useTranslation();
  const moment = useMoment();

  const labels = [t('Jan'), t('Feb'), t('Mar'), t('Apr'), t('May'), t('Jun'), t('Jul'), t('Aug'), t('Sep'), t('Oct'), t('Nov'), t('Dec')];

  let datasetsDefaultValues = {
    fill: true,
    borderWidth: 2,
    hoverBorderWidth: 4,
    lineTension: 0.3,
    pointBorderColor: '#FFF',
    pointBorderWidth: 1,
  };

  let directSalesData = {
    labels: labels,
    datasets: [],
  };

  let options = {
    maintainAspectRatio: false,
    plugins: {
      responsive: true,
    },
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          drawBorder: false,
        },
      },
      x: {
        grid: {
          display: false,
        },
      },
    }
  };

  const handleEventClick = (event) => {
    const elems = getElementAtEvent(chartRef.current, event);
    if(!elems[0]) {
      return;
    }

    onEventClick(elems.map(elem => {
      const month = `${elem.index + 1}`.padStart(2, '0');
      const year = moment().subtract(2, 'years').add(elem.datasetIndex, 'year').year();
      return {year, month};
    }));
  };

  const getColor = (year) => {
    switch (year) {
      case moment().year().toString():
        return primaryColor.border;
      case moment().subtract(1, 'year').year().toString():
        return secondaryColor.border;
      default:
        return tertiaryColor.border;
    }
  };
  const getBackgroundColor = (year) => {
    switch (year) {
      case moment().year().toString():
        return primaryColor.background;
      case moment().subtract(1, 'year').year().toString():
        return secondaryColor.background;
      default:
        return tertiaryColor.background;
    }
  };

  if(!data) {
    return null;
  }

  const skipped = (ctx, value) => ctx.p0.skip || ctx.p1.skip ? value : undefined;
  const down = (ctx, value) => ctx.p0.parsed.y > ctx.p1.parsed.y ? value : undefined;
  const last = (ctx, value) => ctx.datasetIndex === data.length - 1 && ctx.p0DataIndex > moment().month() - 2 ? value : [6, 0];

  data.map(rawData => {
    directSalesData.datasets.push({
      ...datasetsDefaultValues,
      label: rawData.label,
      data: rawData.data,
      backgroundColor: getBackgroundColor(rawData.label),
      pointBackgroundColor: getColor(rawData.label),
      borderColor: getColor(rawData.label),
      segment: {
        //borderColor: ctx => skipped(ctx, 'rgb(0,0,0,0.2)') || down(ctx, 'rgb(192,75,75)'),
        borderDash: ctx => last(ctx, [6, 6]),
      },
      hoverBackgroundColor: getColor(rawData.label),
      hoverBorderColor: getColor(rawData.label),
    });
  });

  return (
    <div className={classes.root}>
      <Line
        ref={chartRef}
        data={directSalesData}
        options={options}
        onClick={handleEventClick}
      />
    </div>
  );
};
export default LineChartByYearAndMonth;
