import React from "react";
import {InfoWindow} from "@react-google-maps/api";

const MapInfoWindow = ({ position, children, zIndex, pixelOffsetX, pixelOffsetY, disableAutoPan = false }) => {

  const options = { disableAutoPan: disableAutoPan, pixelOffset: new window.google.maps.Size(pixelOffsetX, pixelOffsetY) };

  return <>
    <InfoWindow
      position={position}
      zIndex={zIndex}
      options={options}
    >
      {children}
    </InfoWindow>
  </>;
};

export default MapInfoWindow;