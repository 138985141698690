import React from "react";
import { useSelector } from "react-redux";

import UpdateConsultantShippingAddressCard from "./components/UpdateConsultantShippingAddressCard";
import {consultantSelector} from "./store/selectors";

const UpdateConsultantShippingAddress = () => {

  const consultant = useSelector(consultantSelector());

  return (
    <UpdateConsultantShippingAddressCard partner={consultant} />
  );
};
export default UpdateConsultantShippingAddress;
