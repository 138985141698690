import NativeAxios from 'axios';

import Cookie from "./cookie";
import { getLangPath } from "./multilingual";
import { history } from "./store";

function Axios() {}

Axios.prototype = NativeAxios.create();

Axios.prototype.token = null;
Axios.prototype.isRefreshingToken = false;
Axios.prototype.refreshingTokenError = false;

Axios.prototype.init = function () {

  this.setBaseURL(process.env.REACT_APP_API_URL);
  this.setToken(Cookie.get('token'));
  this.setInterceptors();
  return this;
};

Axios.prototype.getToken = function () {

  return this.token;
};

Axios.prototype.setBaseURL = function (baseURL) {

  this.defaults.baseURL = baseURL;
};

Axios.prototype.setToken = function (token) {

  if(token) {
    this.token = token;
    this.defaults.headers.common['Authorization'] = `Bearer ${this.getToken()}`;
  }
};

Axios.prototype.destroyToken = function () {

  this.token = null;
  this.defaults.headers.common['Authorization'] = null;
};

Axios.prototype.refreshToken = function (id) {

  return new Promise((resolve, reject) => {

    if(this.isRefreshingToken) {
      const interval = setInterval(() => {
        if(!this.isRefreshingToken) {
          clearInterval(interval);
          resolve();
        }
        if(this.refreshingTokenError) {
          clearInterval(interval);
          reject({response: {data: {error: {type: "invalid-accesstoken"}}}});
        }
      }, 200);
      return;
    }

    this.isRefreshingToken = id;
    let token = Cookie.get('refresh-token');
    if (token === undefined) {
      history.push(`${getLangPath()}/auth/signout/unauthorized`);
      return;
    }

    NativeAxios.post(`${process.env.REACT_APP_API_URL}/auth/refreshtoken`, {
      token
    })
      .then(({data}) => {
        this.setToken(data.token);
        Cookie.set('token', data.token);
        Cookie.set('storage-token', data.storageToken);
        this.isRefreshingToken = false;
        resolve();
      })
      .catch((e) => {
        if (e.response.status === 401) {
          this.refreshingTokenError = true;
          reject({response: {data: {error: {type: "invalid-accesstoken"}}}});
          history.push(`${getLangPath()}/auth/signout/unauthorized`);
        } else {
          reject({response: {data: {error: {type: "network-error"}}}});
        }
      });
  });
};

Axios.prototype.setInterceptors = function () {

  const axios = this;
  this.interceptors.response.use(function ({ status, data, config }) {
    return {status, ...data};
  }, async function (error) {
    const {config} = error;
    if(error.response && error.response.status === 401 && !axios.refreshingTokenError && axios.isRefreshingToken !== config.url) {
      await axios.refreshToken(config.url);
      return await axios[config.method](config.url, config.data, {
        headers: {
          ...axios.defaults.headers,
          'Content-Type': error.config.headers['Content-Type'],
          'Accept': error.config.headers['Accept']
        }
      });
    }
    else if(!error.response) {
      return Promise.reject({response: {data: {error: {type: "network-error"}}}});
    }
    else {
      return Promise.reject(error);
    }
  });
};

export default new Axios().init();
