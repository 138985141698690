import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

import TextFieldValidator from "../../../../../components/Validators/TextFieldValidator";
import {SnackbarError, SnackbarSuccess} from "../../../../../helpers/snackbar";
import {shopActions} from "../../store/actions";
import {itemsFromCartSelector, promotionCodeSelector} from "../../store/selectors";
import {shopService} from "../../store/services";

const PromotionCodeSelector = () => {

  const dispatch = useDispatch();
  const itemsFromCart = useSelector(itemsFromCartSelector);
  const code = useSelector(promotionCodeSelector);
  const [promotionCode, setPromotionCode] = useState('');

  const handleChangePromotionCode = (e) => {
    if(code && code !== "") {
      dispatch(shopActions.setPromotionCode(null));
    }
    if (e.target.value === "") {
      setPromotionCode(null);
    } else {
      setPromotionCode(e.target.value);
    }
  };


  const findAvailablePromotion = async () => {
    try {
      let promotion = await shopService.findAvailablePromotion(
        Object.keys(itemsFromCart).map(productId => ({
          id: productId,
          quantity: itemsFromCart[productId].quantity,
        })),
        promotionCode
      );
      if (!promotion && !!promotionCode && promotionCode !== "") {
        setPromotionCode("");
        SnackbarError("Aucune promotion ne correspond");
        return;
      }
      if (promotion?.code) {
        dispatch(dispatch(shopActions.setPromotionCode(promotionCode)));
        SnackbarSuccess(`Code promotionnel validé`);
      } else {
        SnackbarError("Le code est invalide");
      }
      shopService.setCartLog("Le client valide un code promo",{from:"promotionCodeSelector"}).then().catch();
    } catch (e) {
      SnackbarError("Promotion invalide");
    }

  };

  return (
    <Grid container spacing={2} alignItems={"center"} alignContent={"center"} sx={{
      background: '#fff',
      p: 0,
      '& .MuiInputBase-root': {
        fontFamily: 'Open sans',
        fontSize: '0.9rem',
        color: '#7C909B',
        height: '48px',
        borderRadius: '30px'
      },
      '& .MuiFormLabel-root': {
        fontFamily: 'Open sans',
        fontSize: '0.9rem',
        lineHeight: '1.1rem',
        color: '#7C909B'
      },
      '& .MuiFormHelperText-root': {
        position: 'unset !important',
      }
    }}>
      <Grid item xs={12} sm={8}>
        <TextFieldValidator
          fullWidth
          name="promotionCode"

          label={"Coupon (optionnel)"}

          value={code && code !== "" ? code : promotionCode}
          onChange={handleChangePromotionCode}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Button
          sx={{
            fontSize: '1rem',
            fontFamily: 'Montserrat',
            textTransform: 'capitalize',
            borderRadius: '30px',
            color: '#fff',
            lineHeight: '1.3',
            padding: '8px 8px',
            width: '150px'
          }}
          variant="contained"
          color="primary"
          onClick={findAvailablePromotion}
        >
           Valider le coupon
        </Button>
      </Grid>
    </Grid>
  );
};
export default PromotionCodeSelector;

//if(!checkout || checkout.order.totalPrice + checkout.order.taxes < giftCard.amount) {
//    setValidGiftCardCode(false);
//    SnackbarError(t('errorMessageGiftCardAmount'))
//    return;
//}

//useEffect(() => {
//    if (userGiftCard) {
//        setValidGiftCardCode(true);
//        SnackbarSuccess(t('giftCardAddedDescription'));
//    }
//}, [userGiftCard]);
