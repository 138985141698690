import React from 'react';
import {Link} from "react-router-dom";
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import {Box, Stack} from "@mui/material";
import Container from '@mui/material/Container';
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#8CBD23',
    color: "#FFFFFF",
    '& .MuiGrid-container': {
      '& .MuiGrid-item': {
        width: '100%',
        '& .MuiTypography-h6': {},
        '& .MuiTypography-body2': {
          padding: '6px 0',
          '& .MuiSvgIcon-fontSizeSmall': {
            marginRight: '4px',
            marginBottom: '-5px',
            marginLeft: '-24px'
          },
          '& a': {
            color: 'inherit',
            textDecoration: 'none'
          }
        },
        [theme.breakpoints.down('sm')]: {
          '& .MuiTypography-h6': {
            textAlign: 'center'
          },
          '& .MuiTypography-body1': {
            textAlign: 'center'
          }
        }
      }
    },
    '& .MuiTypography-body2': {
      fontFamily: 'Raleway',
      fontSize: '1rem',
      [theme.breakpoints.down('sm')]: {
        fontSize: '0.9'
      }
    },
    '& .MuiTypography-body1': {
      fontFamily: 'Open sans, sans-serif',
      fontSize: '1.1rem',
      marginBottom: '0',
      [theme.breakpoints.down('sm')]: {
        fontSize: '1rem'
      }
    }
  },
  footerLogos: {
    paddingTop: '50px',
    marginBottom: '20px'
  },
  bottomLogo: {
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  leftDivider: {
    borderLeft: '1px dashed #FFFFFF',
    paddingLeft: '8px'
  },
  textField: {
    '& .MuiOutlinedInput-root': {
      border: '1px solid #FFFFFF',
      height: '48px',
      backgroundColor: '#FFFFFF40'
    }
  },
  copyright: {
    backgroundColor: '#8DBD22',
    marginTop: '20px',
    marginBottom: '20px',
    textAlign: 'right',
    '& a': {
      color: 'inherit',
      textDecoration: 'none'
    }
  }
}));


const Footer = () => {
  const classes = useStyles();
  return (
    <footer className={classes.root}>
      <Container fixed maxWidth={'xxl'}>
        <Grid container className={classes.footerLogos} spacing={2}>
          <Grid item xs={12} sm={8}>
            <Grid container spacing={2} alignItems={"center"}>
              <Grid item xs={12} sm={12} md={4}>
                <Box
                  component={"img"}
                  width={300}
                  src={`${process.env.REACT_APP_API_RESOURCES_URL}/images/logo/NutriLogics_Logo-White.svg`} alt="Nutri-logics"
                  sx={{
                    width: {xs: '100%', sm: '100%', md: 300},
                    maxWidth: '100%'
                  }}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={4}>
                <Box
                  component={"img"}
                  width={80}
                  src={`${process.env.REACT_APP_API_RESOURCES_URL}/images/logo/Logo_MadeInLux.svg`}
                  alt="Made in luxembourg"
                  sx={{
                    width: {xs: '100%', sm: '100%', md: 80},
                    maxWidth: '80px',
                    margin: '0 auto',
                    display: 'block'
                  }}/>
              </Grid>
              <Grid item xs={6} sm={6} md={4}>
                <Box
                  component={"img"}
                  width={80}
                  src={`${process.env.REACT_APP_API_RESOURCES_URL}/images/logo/Logo_Iso.svg`}
                  alt="Iso"
                  sx={{
                    width: {xs: '100%', sm: '100%', md: 80},
                    maxWidth: '90px',
                    margin: '0 auto',
                    display: 'block'
                  }}/>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container alignItems={"flex-start"} justifyContent={"center"} spacing={2} sx={{
          fontFamily: 'Raleway',
          fontSize: '1rem',
          lineHeight: '1.5rem',
        }}>
          <Grid item xs={6} sm={3} className={classes.leftDivider}>
            <Typography variant={"h6"}>
                  NOS PRODUITS
            </Typography>
            <Stack sx={{
              '& a': {
                color: '#ffffff',
                textDecoration: 'none'
              },
              '& a:hover': {
                color: '#ffffff',
                textDecoration: 'underline'
              }
            }}>
              <Link to={"/shop"}>Tous nos produits</Link>
              <Link to={"/shop/category/articulations---os---muscles/875fe8eb-553e-4167-8e22-b725a9bf37da"}>Articulations</Link>
              <Link to={"/shop/category/anti-age---antioxydants/11bf3f3a-b768-4014-8b3e-f736ef7f9a07"}>Anti-âge</Link>
              <Link to={"/shop/category/detoxication/411a526b-beec-4c27-8b43-e26c88a7c640"}>Détox</Link>
              <Link to={"/shop/category/digestion/06cc3f7e-8853-46be-8063-3fbae9aedba5"}>Digestion</Link>
              <Link to={"/shop/category/immunite/247d7cb0-2598-4de9-86a1-9c5e52c0f27b"}>Immunité</Link>
              <Link to={"/shop/category/esthetique/3a460d44-a827-4008-a443-ddc499b2cf72"}>Peau, ongles & cheveux</Link>
              <Link to={"/shop/category/esthetique---beaute--amincissement/b0767971-84f7-45d4-8c02-22e70779a484"}>Perte de poids</Link>
              <Link to={"/shop/category/equilibre-hormonal--general/98023eb0-427a-459e-90e2-8c078d425fde"}>Santé de l’homme</Link>
              <Link to={"/shop/category/equilibre-hormonal--femme/97992892-b867-48f9-b010-c5be7985edf7"}>Santé de la femme</Link>
              <Link to={"/shop/category/neuromediateurs--sommeil/aa707e0f-91f5-4a1d-b071-b2bfc7fa2dbd"}>Sommeil</Link>
              <Link to={"/shop/category/sport---endurance/508f831e-ee44-4d7e-b817-7984dd2e079e"}>Sport</Link>
              <Link to={"/shop/category/energie---magnesium/39def8a5-5436-4d79-8b2b-c8e9c8bb0e34"}>Vitalité & énergie</Link>
            </Stack>
          </Grid>
          <Grid item xs={6} sm={3} className={classes.leftDivider}>
            <Typography variant={"h6"}>
                NUTRI-LOGICS
            </Typography>
            <Stack sx={{
              '& a': {
                color: '#ffffff',
                textDecoration: 'none'
              },
              '& a:hover': {
                color: '#ffffff',
                textDecoration: 'underline'
              }
            }}>
              31 Duarrefstrooss <br/>
              L-9990 Weiswampach <br/>
              +32 (0) 78 70 90 20 <br/>
              +33 (0)9 70 44 16 45 <br/>
              +352 28 33 98 98 <br/>
              <Link to={"/mag"}>Le blog</Link>
              <Link to={"/about"}>Qui sommes-nous ?</Link>
              <Link to={"/shop/brand/nrd/083632d8-fa53-4a21-8a95-c46b3a4c4e88"}>Les laboratoires</Link>
              <Typography component={"a"} target={"_blank"} href={"https://www.nrd.lu"}>NR&D, notre laboratoire</Typography>
            </Stack>

          </Grid>
          <Grid item xs={6} sm={3} className={classes.leftDivider}>
            <Typography variant={"h6"}>
                BESOIN D’AIDE ?
            </Typography>
            <Box component={"ul"} sx={{
              pl: 0,
              mt: 0,
              listStyleType: 'none',
              '& a': {
                color: '#ffffff',
                textDecoration: 'none'
              },
              '& a:hover': {
                color: '#ffffff',
                textDecoration: 'underline'
              }
            }}>
              <li><Typography component={"a"} href={"mailto:info@nutri-logics.com"}>info@nutri-logics.com</Typography></li>
              <li><Link to={"/contact"}>Contactez-nous</Link></li>
              <li><Link to={"/health-professional"}>Professionnel de la santé</Link></li>
              <li>Livraison Belgique J+1</li>
              <li>Livraison France J+3</li>
              <li>Livraison Luxembourg J+3</li>
              <li>Livraison mondiale</li>
              <li>Paiement sécurisé</li>
              <li><Link to={"/terms-of-sales"}>Conditions générales de ventes</Link></li>
              <li><Link to={'/mentions-legales'}>Mentions légales</Link></li>
              <li><Link to={'/protection-donnees'}>Protection des données</Link></li>
              <li><Link to={'/droits-retractation'}>Droits de rétraction</Link></li>
            </Box>
          </Grid>
          <Grid item xs={6} sm={3} className={classes.leftDivider} sx={{
            '& a': {
              color: '#ffffff',
              textDecoration: 'none',
              mb: '0 !important',
              fontFamily: 'Raleway !important',
              fontSize: '1.1rem !important'
            },
            '& a:hover': {
              color: '#ffffff',
              textDecoration: 'underline'
            }
          }}>
            <Typography variant={"h6"}>
                NOS RÉSEAUX SOCIAUX
            </Typography>
            <Stack>
              <Stack direction={"row"} spacing={1} alignItems={"center"} alignContent={"center"}>
                <InstagramIcon/>
                <Typography component={"a"} target="_blank" href={"https://www.instagram.com/nutri_logics"}>
                  Instagram
                </Typography>
              </Stack>
              <Stack direction={"row"} spacing={1} alignItems={"center"} alignContent={"center"}>
                <FacebookIcon/>
                <Typography component={"a"} target="_blank" href={"https://www.facebook.com/nutrilogics.sante"}>
                  Facebook Nutri-Logics
                </Typography>
              </Stack>
              <Stack direction={"row"} spacing={1} alignItems={"center"} alignContent={"center"}>
                <FacebookIcon/>
                <Typography component={"a"} target="_blank" href={"https://www.facebook.com/Sciencesetnutrition"}>
                  Facebook NR&D
                </Typography>
              </Stack>
              <Stack direction={"row"} spacing={1} alignItems={"center"} alignContent={"center"}>
                <YouTubeIcon/>
                <Typography component={"a"} target="_blank" href={"https://www.youtube.com/@NutriLogicsCedricLapauw/videos"}>
                  YouTube
                </Typography>
              </Stack>
              <Stack direction={"row"} spacing={1} alignItems={"center"} alignContent={"center"}>
                <LinkedInIcon/>
                <Typography component={"a"} target="_blank" href={"https://linkedin.com/company/nutrilogics"}>
                  LinkedIn
                </Typography>
              </Stack>
            </Stack>
          </Grid>
        </Grid>

      </Container>
    </footer>
  );
};

export default Footer;
