import React from "react";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import {makeStyles} from '@mui/styles';

import CartDetails from "./CartDetails";
import CartListItems from "./CartListItems";

const useStylesCart = makeStyles((theme) => ({
  listItems: {
    [theme.breakpoints.up('sm')]: {
      height: '60vh',
      overflow: 'auto'
    }
  },
  details: {
    '& .MuiListItem-root': {
      paddingTop: 20,
      paddingBottom: 20
    }
  },
}));

const CartContent = ({session, cart}) => {

  const classes = useStylesCart();
    
  return (
    <Card elevation={0}>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <CartListItems className={classes.listItems} items={cart.items} session={session}/>
        </Grid>
        <Divider orientation="vertical" flexItem/>
        <Grid item xs={10} sm={6}>
          <CartDetails className={classes.details} subTotal={cart.subTotal} discount={cart.discount} taxes={cart.taxes} total={cart.total} currency={cart.currency} isFreeShipping={cart.isFreeShipping} allowFreeShipping={session.allowFreeShipping} cartView />
        </Grid>
      </Grid>
    </Card>
  );
};
export default CartContent;
