import React from "react";
import {useDispatch} from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import MenuIcon from '@mui/icons-material/Menu';
import {AppBar, Box, Stack, Toolbar,} from "@mui/material";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";

import {statsActions} from "../../../containers/Admin/Stats/store/actions";

import MenuDrawer from "./MenuDrawer";

const drawerWidth = 240;

export default function Header() {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const dispatch = useDispatch();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          width: { md: `calc(100% - ${drawerWidth}px)` },
          ml: { md: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'block', md: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Stack direction={"row"} gap={2}>
            <Button
              onClick={() => dispatch(statsActions.setAddCardOpen(true))}
              sx={{
                color: '#7C909B',
                background: '#ffffff',
                boxShadow: '12px 12px 24px #00000021',
                '&:hover': {
                  background: '#ffffff',
                }
              }}
              endIcon={<AddIcon/>}
            >
                  Ajouter une carte
            </Button>
            <Button
              onClick={() => dispatch(statsActions.setProNumberDisplayed(true))}
              sx={{
                color: '#7C909B',
                background: '#ffffff',
                boxShadow: '12px 12px 24px #00000021',
                '&:hover': {
                  background: '#ffffff',
                }
              }}
              endIcon={<AddIcon/>}
            >
                  Afficher plus de pros
            </Button>
          </Stack>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'block', md: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          <MenuDrawer setMobileOpen={setMobileOpen}/>
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'none', md: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          <MenuDrawer/>
        </Drawer>
      </Box>
    </>
  );
}