import moment from "moment";
import { createSelector } from 'reselect';

import { initialState, proStatsReducerKey } from "./reducer";

const proStatsSelector = (state) => state[proStatsReducerKey] || initialState;

export const directSalesLinesGraphDataSelector = (userId) => createSelector(
  [proStatsSelector],
  (stats) => {
    if(!stats.directTurnoverByMonth[userId]) {
      return null;
    }
    let data = [];
    Object.keys(stats.directTurnoverByMonth[userId]).map(year => {
      data.push({
        label: year,
        data: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(month => {
          if(moment().diff(`${year}-${`${month+1}`.padStart(2, '0')}-01`) > 0) {
            return stats.directTurnoverByMonth[userId][year][month] ? stats.directTurnoverByMonth[userId][year][month] : 0;
          }
        })
      });
    });
    return data;
  });

export const directRecurringSalesLinesGraphDataSelector = (userId) => createSelector(
  [proStatsSelector],
  (stats) => {
    if(!stats.directRecurringTurnoverByMonth[userId]) {
      return null;
    }
    let data = [];
    Object.keys(stats.directRecurringTurnoverByMonth[userId]).map(year => {
      data.push({
        label: year,
        data: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(month => {
          if(moment().diff(`${year}-${`${month+1}`.padStart(2, '0')}-01`) > 0) {
            return stats.directRecurringTurnoverByMonth[userId][year][month] ? stats.directRecurringTurnoverByMonth[userId][year][month] : 0;
          }
        })
      });
    });
    return data;
  });

export const directTurnoverDataSelector = (userId) => createSelector(
  [proStatsSelector],
  (stats) => {
    let year = 0;
    let month = 0;
    if(stats.directTurnoverByMonth[userId]) {

      const currentYear = moment().year();
      if(stats.directTurnoverByMonth[userId][currentYear]) {
        stats.directTurnoverByMonth[userId][currentYear].map((amount, key) => {
          year += amount;
          if(moment().month() === key) {
            month += amount;
          }
        });
      }
    }
    return {year, month};
  });

export const directRecurringTurnoverDataSelector = (userId) => createSelector(
  [proStatsSelector],
  (stats) => {
    let year = 0;
    let month = 0;
    if(stats.directRecurringTurnoverByMonth[userId]) {

      const currentYear = moment().year();
      if(stats.directRecurringTurnoverByMonth[userId][currentYear]) {
        stats.directRecurringTurnoverByMonth[userId][currentYear].map((amount, key) => {
          year += amount;
          if(moment().month() === key) {
            month += amount;
          }
        });
      }
    }
    return {year, month};
  });

export const directRecruitmentDataSelector = (userId) => createSelector(
  [proStatsSelector],
  (stats) => {
    let year = 0;
    let month = 0;
    if(stats.directRecruitment[userId]) {

      const currentYear = moment().year();
      if(stats.directRecruitment[userId][currentYear]) {
        stats.directRecruitment[userId][currentYear].map((amount, key) => {
          year += amount;
          if(moment().month() === key) {
            month += amount;
          }
        });
      }
    }
    return {year, month};
  });

export const networkTurnoverDataSelector = (userId) => createSelector(
  [proStatsSelector],
  (stats) => {
    let year = 0;
    let month = 0;
    if(stats.networkTurnoverByMonth[userId]) {

      const currentYear = moment().year();
      if(stats.networkTurnoverByMonth[userId][currentYear]) {
        stats.networkTurnoverByMonth[userId][currentYear].map((amount, key) => {
          year += amount;
          if(moment().month() === key) {
            month += amount;
          }
        });
      }
    }
    return {year, month};
  });

export const networkRecurringTurnoverDataSelector = (userId) => createSelector(
  [proStatsSelector],
  (stats) => {
    let year = 0;
    let month = 0;
    if(stats.networkRecurringSales[userId]) {

      const currentYear = moment().year();
      if(stats.networkRecurringSales[userId][currentYear]) {
        stats.networkRecurringSales[userId][currentYear].map((amount, key) => {
          year += amount;
          if(moment().month() === key) {
            month += amount;
          }
        });
      }
    }
    return {year, month};
  });

export const networkRecruitmentDataSelector = (userId) => createSelector(
  [proStatsSelector],
  (stats) => {
    let year = 0;
    let month = 0;
    if(stats.networkRecruitment[userId]) {

      const currentYear = moment().year();
      if(stats.networkRecruitment[userId][currentYear]) {
        stats.networkRecruitment[userId][currentYear].map((amount, key) => {
          year += amount;
          if(moment().month() === key) {
            month += amount;
          }
        });
      }
    }
    return {year, month};
  });

export const networkSalesLinesGraphDataSelector = (userId) => createSelector(
  [proStatsSelector],
  (stats) => {
    if(!stats.networkTurnoverByMonth[userId]) {
      return null;
    }
    let data = [];
    Object.keys(stats.networkTurnoverByMonth[userId]).map(year => {
      data.push({
        label: year,
        data: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(month => {
          if(moment().diff(`${year}-${`${month+1}`.padStart(2, '0')}-01`) > 0) {
            return stats.networkTurnoverByMonth[userId][year][month] ? stats.networkTurnoverByMonth[userId][year][month] : 0;
          }
        })
      });
    });
    return data;
  });

export const networkRecurringSalesLinesGraphDataSelector = (userId) => createSelector(
  [proStatsSelector],
  (stats) => {
    if(!stats.networkRecurringSales[userId]) {
      return null;
    }
    let data = [];
    Object.keys(stats.networkRecurringSales[userId]).map(year => {
      data.push({
        label: year,
        data: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(month => {
          if(moment().diff(`${year}-${`${month+1}`.padStart(2, '0')}-01`) > 0) {
            return stats.networkRecurringSales[userId][year][month] ? stats.networkRecurringSales[userId][year][month] : 0;
          }
        })
      });
    });
    return data;
  });

export const directRecruitmentLinesGraphDataSelector = (userId) => createSelector(
  [proStatsSelector],
  (stats) => {
    if(!stats.directRecruitment[userId]) {
      return null;
    }
    let data = [];
    Object.keys(stats.directRecruitment[userId]).map(year => {
      data.push({
        label: year,
        data: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(month => {
          if(moment().diff(`${year}-${month+1}-01`) > 0) {
            return stats.directRecruitment[userId][year][month] ? stats.directRecruitment[userId][year][month] : 0;
          }
        })
      });
    });
    return data;
  });

export const networkRecruitmentLinesGraphDataSelector = (userId) => createSelector(
  [proStatsSelector],
  (stats) => {
    if(!stats.networkRecruitment[userId]) {
      return null;
    }
    let data = [];
    Object.keys(stats.networkRecruitment[userId]).map(year => {
      data.push({
        label: year,
        data: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(month => {
          if(moment().diff(`${year}-${month+1}-01`) > 0) {
            return stats.networkRecruitment[userId][year][month] ? stats.networkRecruitment[userId][year][month] : 0;
          }
        })
      });
    });
    return data;
  });

export const directBestSellersBarGraphDataSelector = (userId, year, month) => createSelector(
  [proStatsSelector],
  (stats) => {
    if(!stats.directBestSellersAmount[userId] || !stats.directBestSellersAmount[userId][year] || !stats.directBestSellersAmount[userId][year][month]) {
      return null;
    }
    let labels = [];
    let data = [];
    stats.directBestSellersAmount[userId][year][month].map(user => {
      labels.push(`${user.firstName} ${user.lastName}`);
      data.push(user.amount);
    });
    return {labels, data};
  });

export const directBestRecruitersBarGraphDataSelector = (userId, year, month) => createSelector(
  [proStatsSelector],
  (stats) => {
    if(!stats.directBestRecruitersAmount[userId] || !stats.directBestRecruitersAmount[userId][year] || !stats.directBestRecruitersAmount[userId][year][month]) {
      return null;
    }
    let labels = [];
    let data = [];
    stats.directBestRecruitersAmount[userId][year][month].map(user => {
      labels.push(`${user.firstName} ${user.lastName}`);
      data.push(user.amount);
    });
    return {labels, data};
  });


export const networkBestSellersBarGraphDataSelector = (userId, year, month) => createSelector(
  [proStatsSelector],
  (stats) => {
    if(!stats.networkBestSellersAmount[userId] || !stats.networkBestSellersAmount[userId][year] || !stats.networkBestSellersAmount[userId][year][month]) {
      return null;
    }
    let labels = [];
    let data = [];
    stats.networkBestSellersAmount[userId][year][month].map(user => {
      labels.push(`${user.firstName} ${user.lastName}`);
      data.push(user.amount);
    });
    return {labels, data};
  });

export const networkBestRecruitersBarGraphDataSelector = (userId, year, month) => createSelector(
  [proStatsSelector],
  (stats) => {
    if(!stats.networkBestRecruitersAmount[userId] || !stats.networkBestRecruitersAmount[userId][year] || !stats.networkBestRecruitersAmount[userId][year][month]) {
      return null;
    }
    let labels = [];
    let data = [];
    stats.networkBestRecruitersAmount[userId][year][month].map(user => {
      labels.push(`${user.firstName} ${user.lastName}`);
      data.push(user.amount);
    });
    return {labels, data};
  });

export const directPacksAmountBarGraphDataSelector = (userId, year, month) => createSelector(
  [proStatsSelector],
  (stats) => {
    if(!stats.directPacksAmount[userId] || !stats.directPacksAmount[userId][year] || !stats.directPacksAmount[userId][year][month]) {
      return null;
    }
    let labels = [];
    let data = [];
    stats.directPacksAmount[userId][year][month].map(pack => {
      labels.push(pack.name);
      data.push(pack.amount);
    });
    return {labels, data};
  });

export const directProductsAmountBarGraphDataSelector = (userId, year, month) => createSelector(
  [proStatsSelector],
  (stats) => {
    if(!stats.directProductsAmount[userId] || !stats.directProductsAmount[userId][year] || !stats.directProductsAmount[userId][year][month]) {
      return null;
    }
    let labels = [];
    let data = [];
    stats.directProductsAmount[userId][year][month].map(pack => {
      labels.push(pack.name);
      data.push(pack.amount);
    });
    return {labels, data};
  });

export const networkPacksAmountBarGraphDataSelector = (userId, year, month) => createSelector(
  [proStatsSelector],
  (stats) => {
    if(!stats.networkPacksAmount[userId] || !stats.networkPacksAmount[userId][year] || !stats.networkPacksAmount[userId][year][month]) {
      return null;
    }
    let labels = [];
    let data = [];
    stats.networkPacksAmount[userId][year][month].map(pack => {
      labels.push(pack.name);
      data.push(pack.amount);
    });
    return {labels, data};
  });

export const networkProductsAmountBarGraphDataSelector = (userId, year, month) => createSelector(
  [proStatsSelector],
  (stats) => {
    if(!stats.networkProductsAmount[userId] || !stats.networkProductsAmount[userId][year] || !stats.networkProductsAmount[userId][year][month]) {
      return null;
    }
    let labels = [];
    let data = [];
    stats.networkProductsAmount[userId][year][month].map(pack => {
      labels.push(pack.name);
      data.push(pack.amount);
    });
    return {labels, data};
  });

export const networkTurnoverByCareerDataSelector = (userId, year, month) => createSelector(
  [proStatsSelector],
  (stats) => {
    if(!stats.networkTurnoverByCareer[userId] || !stats.networkTurnoverByCareer[userId][year] || !stats.networkTurnoverByCareer[userId][year][month]) {
      return [];
    }
    return stats.networkTurnoverByCareer[userId][year][month];
  });

export const networkTurnoverByCareerGraphDataSelector = (userId, year, month) => createSelector(
  [proStatsSelector],
  (stats) => {
    if(!stats.networkTurnoverByCareer[userId] || !stats.networkTurnoverByCareer[userId][year] || !stats.networkTurnoverByCareer[userId][year][month]) {
      return [];
    }
    let labels = [];
    let data = [];
    stats.networkTurnoverByCareer[userId][year][month].map(career => {
      labels.push(`${career.name}`);
      data.push(career.amount);
    });
    return {labels, data};
  });

export const networkAverageTurnoverByActiveProsAmountLinesGraphDataSelector = (userId) => createSelector(
  [proStatsSelector],
  (stats) => {
    if(!stats.networkTurnoverByMonth[userId] || !stats.networkActiveProsCountByMonth[userId]) {
      return null;
    }
    let data = [];
    Object.keys(stats.networkTurnoverByMonth[userId]).map(year => {
      data.push({
        label: year,
        data: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(month => {
          if(moment().diff(`${year}-${`${month+1}`.padStart(2, '0')}-01`) > 0) {
            return stats.networkTurnoverByMonth[userId][year][month] && stats.networkActiveProsCountByMonth[userId][year] && stats.networkActiveProsCountByMonth[userId][year][month] ? Math.round(stats.networkTurnoverByMonth[userId][year][month] / stats.networkActiveProsCountByMonth[userId][year][month]*100)/100 : 0;
          }
        })
      });
    });
    return data;
  });

export const networkAverageTurnoverByActiveProsAmountSelector = (userId) => createSelector(
  [proStatsSelector],
  (stats) => {
    let averageAmountByYear = {};
    if(!stats.networkTurnoverByMonth[userId] || !stats.networkActiveProsCountByMonth[userId]) {
      return null;
    }
    let activeMonths = {};
    Object.keys(stats.networkTurnoverByMonth[userId]).map(year => {
      [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(month => {
        if(moment().diff(`${year}-${`${month+1}`.padStart(2, '0')}-01`) > 0) {
          activeMonths[year] = activeMonths[year] ? activeMonths[year] : 0;
          averageAmountByYear[year] = averageAmountByYear[year] ? averageAmountByYear[year] : 0;
          if(stats.networkTurnoverByMonth[userId][year][month] && stats.networkActiveProsCountByMonth[userId][year] && stats.networkActiveProsCountByMonth[userId][year][month]) {
            if (parseInt(year) === moment().year() && month === moment().month()) {
              activeMonths[year] += moment().date() / moment().daysInMonth();
            } else {
              activeMonths[year] += 1;
            }
            averageAmountByYear[year] += Math.round(stats.networkTurnoverByMonth[userId][year][month] / stats.networkActiveProsCountByMonth[userId][year][month] * 100) / 100;
          }
        }
      });
    });
    Object.keys(averageAmountByYear).map(year => {
      if(activeMonths[year] > 0) {
        averageAmountByYear[year] = averageAmountByYear[year] / activeMonths[year];
      }
    });
    return averageAmountByYear;
  });