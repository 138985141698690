import i18next from "i18next";
import moment from "moment";
import { call, put, select,takeEvery } from 'redux-saga/effects';

import { getLangPath } from "../../../../helpers/multilingual";
import {SnackbarError, SnackbarFormattedError, SnackbarSuccess} from "../../../../helpers/snackbar";
import { history } from "../../../../helpers/store";
import { authenticationActions } from "../../../Authentication/store/actions";

import { careerActions } from "./actions";
import * as actionsType from './constants';
import { careerService } from "./services";

export const careerSagaKey = 'career';

function* getCareerProgression(action) {

  try {
    let data = yield call(careerService.getCareerProgression);
    yield put(careerActions.getCareerProgressionSuccess(data));
  }
  catch (e) {
    yield call(SnackbarFormattedError, e.error);
    yield put(careerActions.getCareerProgressionError());
  }
}

function* getCareerCertificationProgression(action) {

  try {
    let data = yield call(careerService.getCareerCertificationProgression, action.id);
    yield put(careerActions.getCareerCertificationProgressionSuccess(action.id, data));
  }
  catch (e) {
    yield call(SnackbarFormattedError, e.error);
    yield put(careerActions.getCareerCertificationProgressionError());
  }
}

function* validateChapter(action) {

  try {
    let {nextStep, nextChapter} = yield call(careerService.validateChapter, action.stepKey, action.chapterKey);
    yield put(careerActions.validateChapterSuccess(action.stepKey, action.chapterKey));
    history.push(`${getLangPath()}/pro/career/${nextStep}/${nextChapter}`);
  }
  catch (e) {
    yield call(SnackbarFormattedError, e.error);
    yield put(careerActions.validateChapterError(e));
  }
}

function* chooseCareer(action) {

  try {
    let {nextStep, nextChapter} = yield call(careerService.chooseCareer, action.career);
    yield put(careerActions.chooseCareerSuccess(action.stepKey, action.chapterKey));
    history.push(`${getLangPath()}/pro/career/${nextStep}/${nextChapter}`);
  }
  catch (e) {
    yield call(SnackbarFormattedError, e.error);
    yield put(careerActions.chooseCareerError(e));
  }
}

function* validateCertificationChapter(action) {

  try {
    yield call(careerService.validateCertificationChapter, action.certificationId, action.chapterKey);
    yield put(careerActions.validateCertificationChapterSuccess(action.certificationId, action.chapterKey));
    history.push(`${getLangPath()}/pro/career/certification/${action.certificationId}/${parseInt(action.chapterKey)+1}`);
  }
  catch (e) {
    yield call(SnackbarFormattedError, e.error);
    yield put(careerActions.validateCertificationChapterError(e));
  }
}

function* validateQualification(action) {

  try {
    let {nextStep, nextChapter} = yield call(careerService.validateQualification, action.stepKey, action.chapterKey);
    yield put(careerActions.validateQualificationSuccess(action.stepKey, action.chapterKey));
    yield put(authenticationActions.reloadUserRequest());
    history.push(`${getLangPath()}/pro/career/${nextStep}/${nextChapter}`);
  }
  catch (e) {
    yield call(SnackbarFormattedError, e.error);
    yield put(careerActions.validateQualificationError(e));
  }
}

function* validateCertificationTest(action) {

  try {
    const data = yield call(careerService.validateCertificationTest, action.certificationId, action.chapterKey, action.data);
    localStorage.setItem('careerAttempt', `0`);
    yield call(SnackbarSuccess, i18next.t('youPassedTheTest'));
    yield put(careerActions.validateCertificationTestSuccess(action.certificationId, action.chapterKey, data));
  }
  catch (e) {
    let attempt = parseInt(localStorage.getItem('careerAttempt'));
    if(attempt >= 1) {
      localStorage.setItem('careerAttempt', `0`);
      localStorage.setItem('careerLocked', moment().add(e.error.minutesLocked, 'minutes').format());
    }
    else {
      localStorage.setItem('careerAttempt', `${attempt ? ++attempt : 1}`);
    }
    yield call(SnackbarError, i18next.t('youFailedTheTest', {ratio: `${e.error.data.questionsDone.length} / ${Object.keys(e.error.data.questions).length}`}));
    window.scrollTo(0, 0);
    yield put(careerActions.validateCertificationTestSuccess(action.certificationId, action.chapterKey, e.error.data));
    yield put(careerActions.validateCertificationTestError(e));
  }
}

export default function* watchCareer() {

  yield takeEvery(actionsType.GET_CAREER_PROGRESSION_REQUEST, getCareerProgression);
  yield takeEvery(actionsType.GET_CAREER_CERTIFICATION_PROGRESSION_REQUEST, getCareerCertificationProgression);
  yield takeEvery(actionsType.VALIDATE_CHAPTER_REQUEST, validateChapter);
  yield takeEvery(actionsType.CHOOSE_CAREER_REQUEST, chooseCareer);
  yield takeEvery(actionsType.VALIDATE_CERTIFICATION_CHAPTER_REQUEST, validateCertificationChapter);
  yield takeEvery(actionsType.VALIDATE_CERTIFICATION_TEST_REQUEST, validateCertificationTest);
  yield takeEvery(actionsType.VALIDATE_QUALIFICATION_REQUEST, validateQualification);
}
