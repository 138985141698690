import React, {useEffect, useState} from 'react';
import ReactGA from "react-ga4";
import {Trans, useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from 'react-router-dom';
import CloseIcon from "@mui/icons-material/Close";
import {Box, TextField} from "@mui/material";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";

import theme from "../../assets/styles/theme/theme";
import AutocompletePlaces from "../../components/GoogleAutocomplete/AutocompletePlaces";
import FormValidator from "../../components/Validators/FormValidator";
import SelectValidator from "../../components/Validators/SelectValidator";
import TextFieldValidator from "../../components/Validators/TextFieldValidator";
import {getLangPath} from "../../helpers/multilingual";
import {SnackbarError} from "../../helpers/snackbar";
import {shopActions} from "../Customer/Shop/store/actions";

import {authenticationActions} from "./store/actions";
import {userSelector} from "./store/selectors";
import {authenticationService} from "./store/services";

const SignUp = ({setSection}) => {
  const {t} = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const loggedUser = useSelector(userSelector);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [rememberMe, setRememberMe] = useState(true);
  const [user, setUser] = useState({
    firstName: '',
    lastName: '',
    gender: 'none',
    lang: 'fr',
    email: '',
    birthDate: null,
    noBirthDate: false,
    phone: '',
    billingAddress: '',
    shippingAddress: '',
    password: '',
    confirmPassword: '',
    company: '',
    sameAsBillingAddress: true,
    proName: '',
  });

  useEffect(() => {
    if (loggedUser) {
      history.push(`${getLangPath()}/user/account`);
    }
  }, [loggedUser]);

  const handleCloseDrawer = () => {
    dispatch(shopActions.SetDrawersOpen("account", false));
  };

  const handleChange = (event) => {
    setUser({
      ...user,
      [event.target.name]: event.target.value
    });
  };

  const handleChangeBillingInfos = (event) => {
    setUser({
      ...user,
      billingAddress: {
        ...user.billingAddress,
        [event.target.name]: event.target.value
      }
    });
  };

  const handleChangeShippingInfos = (event) => {
    setUser({
      ...user,
      shippingAddress: {
        ...user.shippingAddress,
        [event.target.name]: event.target.value
      }
    });
  };

  const handleChangeNoBirthDate = (value) => {
    setUser({
      ...user,
      noBirthDate: value,
      birthDate: "2000-01-01",
    });
  };

  const handleBillingAddressChanged = (place) => {

    setUser({
      ...user,
      billingAddress: {
        ...user.billingAddress,
        ...place
      }
    });
  };

  const handleShippingAddressChanged = (place) => {

    setUser({
      ...user,
      shippingAddress: {
        ...user.shippingAddress,
        ...place
      }
    });
  };

  const handleSameAsBillingAddressChange = (event) => {
    setUser({
      ...user,
      sameAsBillingAddress: event.target.checked
    });
  };

  const handleRememberMeChange = (event) => {
    setRememberMe(event.target.checked);
  };

  const handleCheckProCode = async () => {
    try {
      return await authenticationService.checkProCode(user?.proCode);
    } catch (error) {
      SnackbarError(t("errorMessageProCode"));
    }
  };
  const handleSubmit = async () => {

    try {
      setFormSubmitted(true);
      let pro = await handleCheckProCode();
      if(!pro){
        SnackbarError(t('errorMessageProCode'));
        return false;
      }
      if (!user.billingAddress || (!user.sameAsBillingAddress && !user.shippingAddress)) {
        SnackbarError(t('errorMessageRequiredFields'));
        return false;
      }
      if (user.password !== user.confirmPassword) {
        SnackbarError(t('errorMessagePassword'));
        return false;
      }
      if(!user.noBirthDate && !user.birthDate) {
        SnackbarError(t('errorMessageRequiredBirthDate'));
        return false;
      }

      let data = user;
      if (user.sameAsBillingAddress) {
        handleShippingAddressChanged(user.billingAddress);
        data = {
          ...data,
          shippingAddress: {
            ...user.billingAddress,
            company: user.company,
            firstName: user.firstName,
            lastName: user.lastName,
          }
        };
      }
      dispatch(authenticationActions.signUpCredentialsRequest(data, rememberMe));
      ReactGA.event("account_create");
    } catch (error) {
      SnackbarError(t('errorMessageSignUp'));
      console.log(error);
    }
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{
        background: '#fff',
        //minHeight: '100vh',
        p: '32px 16px',
        '& .MuiInputBase-root': {
          fontFamily: 'Open sans',
          fontSize: '0.9rem',
          color: '#7C909B',
          height: '48px',
          borderRadius: '30px'
        },
        '& .MuiFormLabel-root': {
          fontFamily: 'Open sans',
          fontSize: '0.9rem',
          lineHeight: '1.1rem',
          color: '#7C909B'
        },
        '& .MuiFormHelperText-root': {
          position: 'unset !important',
        }
      }}>
      <Box component={'div'} sx={{
        position: 'relative',
      }}>
        <CloseIcon onClick={handleCloseDrawer} sx={{
          top: 0,
          right: 16,
          fontSize: 26,
          cursor: 'pointer',
          position: 'absolute',
          color: '#7C909B',
          background: '#ffffff',
          borderRadius: '50%',
          boxShadow: '2px 2px 8px #7C909B',
          padding: '4px',
        }}/>
      </Box>
      <Typography
        component="h1"
        variant="h5"
        sx={{
          color: theme.palette.primary.main,
          fontFamily: 'Open sans',
          mb: 2
        }}>
        <Trans i18nKey={"signUp"}>
                    Sign up
        </Trans>
      </Typography>
      <FormValidator
        onSubmit={handleSubmit}
        onError={errors => console.log(errors)}
        noValidate
      >
        <Grid container spacing={2} alignItems={"center"}>
          <Grid item xs={12}>
            <SelectValidator
              fullWidth
              id={"select-gender"}
              name="gender"
              label={t('genderLabel')}
              value={user.gender || "none"}
              onChange={handleChange}
              required
            >
              <MenuItem value="mister">
                {t('mister')}
              </MenuItem>
              <MenuItem value="miss">
                {t('miss')}
              </MenuItem>
              <MenuItem value="none">
                {t('noGender')}
              </MenuItem>
            </SelectValidator>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextFieldValidator
              required
              fullWidth
              autoFocus={true}
              id="lastName"
              name="lastName"
              label={t("lastName")}
              autoComplete="lname"
              value={user.lastName || ""}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextFieldValidator
              required
              fullWidth
              name="firstName"
              label={t("firstName")}
              autoComplete="fname"
              value={user.firstName || ""}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextFieldValidator
              fullWidth
              name="company"
              label={t("company")}
              value={user?.company || ""}
              inputProps={{ maxLength: 17 }}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography
              sx={{
                fontSize: '0.8rem',
                fontStyle: 'italic',
                padding: '4px',
                borderRadius: '8px',
                color: 'rgb(34, 154, 22)',
                textAlign: 'center',
              }}
            >
              Si vous êtes assujetti, veuillez nous contacter pour nous communiquer votre numéro de TVA
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextFieldValidator
              fullWidth
              name="name"
              label={t("addressAlias")}
              value={user?.billingAddress?.name || ""}
              onChange={handleChangeBillingInfos}
            />
          </Grid>
          <Grid item xs={12}>
            <AutocompletePlaces
              fullWidth
              variant="outlined"
              name="address"
              label={t("billingAddress")}
              onPlaceChanged={handleBillingAddressChanged}
              error={formSubmitted && !user.billingAddress}
              helperText={formSubmitted && !user.billingAddress && (t("validatorFieldRequired"))}
              placeholder=""
              preview
              required
              customFieldsEnabled
              withAdditionalInfo
              requiredFields={['streetNumber', 'streetName', 'zip', 'city', 'country']}
            />
          </Grid>
          <Grid item xs={12}>
            <TextFieldValidator
              fullWidth
              name="postalBox"
              label={t("postalBox")}
              value={user?.billingAddress?.postalBox || ""}
              inputProps={{ maxLength: 8 }}
              onChange={handleChangeBillingInfos}
            />
          </Grid>
          <Grid item xs={12}>
            <TextFieldValidator
              fullWidth
              name="additionalInfo"
              label={t("additionalInfo")}
              value={user?.billingAddress?.additionalInfo || ""}
              inputProps={{ maxLength: 19 }}
              onChange={handleChangeBillingInfos}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={user.sameAsBillingAddress}
                  onChange={handleSameAsBillingAddressChange}
                  value="true" color="primary"/>
              }
              label={t("sameAsBillingAddress")}
              sx={{
                '& .MuiFormControlLabel-label': {
                  fontFamily: 'Open sans',
                  fontSize: '0.8rem',
                  color: '#7C909B',
                }
              }}
            />
          </Grid>
          {!user.sameAsBillingAddress && (
            <>
              <Grid item xs={12}>
                <FormLabel component="p">
                  <Trans i18nKey="shipping">Shipping information</Trans>
                </FormLabel>
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextFieldValidator
                  fullWidth
                  name="name"
                  label={t("addressAliasRequired")}
                  value={user?.shippingAddress?.name ? user.shippingAddress.name : ""}
                  onChange={handleChangeShippingInfos}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextFieldValidator
                  fullWidth
                  name="company"
                  label={t("company")}
                  value={user?.shippingAddress?.company ? user.shippingAddress.company : ""}
                  onChange={handleChangeShippingInfos}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextFieldValidator
                  fullWidth
                  name="lastName"
                  label={t("lastName")}
                  value={user?.shippingAddress?.lastName ? user.shippingAddress.lastName : ""}
                  onChange={handleChangeShippingInfos}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextFieldValidator
                  fullWidth
                  name="firstName"
                  label={t("firstName")}
                  value={user?.shippingAddress?.firstName ? user.shippingAddress.firstName : ""}
                  onChange={handleChangeShippingInfos}
                />
              </Grid>
              <Grid item xs={12}>
                <AutocompletePlaces
                  fullWidth
                  variant="outlined"
                  name="address"
                  label={t("shippingAddress")}
                  onPlaceChanged={handleShippingAddressChanged}
                  placeholder=""
                  preview
                  customFieldsEnabled
                  withAdditionalInfo
                  required={!user.sameAsBillingAddress}
                  requiredFields={['streetNumber', 'streetName', 'zip', 'city', 'country']}
                />
              </Grid>
              <Grid item xs={12}>
                <TextFieldValidator
                  fullWidth
                  name="additionalInfo"
                  label={t("additionalInfo")}
                  value={user?.shippingAddress?.additionalInfo}
                  onChange={handleChangeShippingInfos}
                />
              </Grid>
            </>
          )}

          <Grid item xs={12}>
            <TextFieldValidator
              id={"phone"}
              fullWidth
              required
              name="phone"
              type="tel"
              label={t("phone")}
              value={user?.phone || ""}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            {!user.noBirthDate ?
              <TextFieldValidator
                fullWidth
                required
                name="birthDate"
                label={t("birthDate")}
                type="date"
                value={user?.birthDate || ""}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              :
              <TextField
                disabled
                fullWidth
                label={t("birthDate")}
                defaultValue={'**/**/****'}
              />
            }
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={user?.noBirthDate || false}
                  onChange={() => {
                    handleChangeNoBirthDate(!user.noBirthDate);
                  }}
                  value="true" color="primary"/>
              }
              label={"Ne souhaite pas la communiquer"}
              sx={{
                '& .MuiFormControlLabel-label': {
                  fontFamily: 'Open sans',
                  fontSize: '0.8rem',
                  color: '#7C909B',
                }
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextFieldValidator
              fullWidth
              id="email"
              type="email"
              name="email"
              label={t("emailLabel")}
              value={user.email}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextFieldValidator
              fullWidth
              name="password"
              label={t("password")}
              type="password"
              value={user.password}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextFieldValidator
              fullWidth
              name="confirmPassword"
              label={t("confirmPassword")}
              type="password"
              value={user.confirmPassword}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Divider sx={{
              borderColor: 'primary.main',
              borderWidth: '2px',
              my: 1,
            }}/>
          </Grid>
          <Grid item xs={12}>
            <FormLabel component="div" sx={{mb: 1}}>
              <Typography
                component={"p"}
                sx={{textDecoration: "underline"}}
              >Nom et prénom de votre thérapeute
              </Typography>
              <Typography
                component={"span"}
              >Professionnel de la santé qui vous a conseillé nos produits*
              </Typography>
            </FormLabel>
            <TextFieldValidator
              required
              fullWidth
              name="proName"
              label={t("proName")}
              value={user?.proName || ""}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={11} sm={11}>
            <TextFieldValidator
              required
              fullWidth
              name="proCode"
              label={t("proCode")}
              value={user?.proCode || ""}
              onChange={handleChange}
            />
            <FormLabel component="div" sx={{mt: 1}}>
              <Typography
                component={"p"}
                sx={{textDecoration: "underline"}}
              >{t('codeLabel1')}
              </Typography>
              <Typography
                component={"span"}
              >{t('codeLabel2')}
              </Typography>
            </FormLabel>
            <FormLabel component="div" sx={{mb: 1}}>
              <Typography
                component={"p"}
                sx={{textDecoration: "underline"}}
              >{t('codeLabel3')}
              </Typography>
              <Typography
                component={"span"}
              >
                {t('codeLabel4')} <Typography component={"span"}
                  color={"primary"}> NUTRILOGICS </Typography>{t('codeLabel5')}
                <br/>
                {t('codeLabel6')}
              </Typography>
            </FormLabel>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={rememberMe}
                  onChange={handleRememberMeChange}
                  value="true" color="primary"/>
              }
              label={t("rememberMe")}
            />
            <Button
              variant="contained"
              color={"primary"}
              type={"submit"}
              sx={{
                fontSize: '1rem',
                textTransform: 'capitalize',
                borderRadius: '30px',
                color: '#fff',
                lineHeight: '1.3',
                padding: '8px 8px',
                width: '200px',
                margin: '0 auto 16px'
              }}>
              {t("createMyAccount")}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Typography
              onClick={() => setSection("signIn")}
              variant="body2"
              sx={{
                mb: 2,
                textAlign: 'center',
                fontFamily: 'Open sans',
                fontSize: '0.8rem',
                color: '#7C909B',
                textDecoration: 'underline',
                cursor: 'pointer'
              }}>
              {t("alreadyRegistered")}
            </Typography>
          </Grid>
        </Grid>
      </FormValidator>
    </Container>
  );
};

export default SignUp;
