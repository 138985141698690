import React from "react";
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import {makeStyles} from '@mui/styles';

const useStyles = makeStyles(theme => ({
  icon : {
    background: theme.palette.common.white,
    borderRadius: 50,
    border: `1px solid ${theme.palette.common.black}`,
    padding: 4,
    cursor: "pointer",
    height: 35,
    width: 35,
    '& > svg': {
      width: 25,
      height: 25,
      fill: theme.palette.common.black
    },
    '&:hover': {
      background: theme.palette.common.black,
      border: `1px solid ${theme.palette.common.white}`,
      '& > svg': {
        fill: theme.palette.common.white
      }
    }
  },
  iconHovered : {
    background: theme.palette.common.black,
    borderRadius: 50,
    border: `1px solid ${theme.palette.common.white}`,
    padding: 4,
    cursor: "pointer",
    height: 35,
    width: 35,
    '& > svg': {
      width: 25,
      height: 25,
      fill: theme.palette.common.white
    }
  }
}));

const PickupPointMarker = ({ pickupPoint, markerSelected, setMarkerSelected }) => {

  const classes = useStyles();
  return (
    <div className={markerSelected.id === pickupPoint.id ? classes.iconHovered : classes.icon} onClick={() => setMarkerSelected(pickupPoint)}>
      <HomeWorkIcon />
    </div>
  );
};

export default PickupPointMarker;
