import React, {useState} from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import ButtonWithLoader from "../../../../../../components/ButtonWithLoader";
import AutocompletePlaces from "../../../../../../components/GoogleAutocomplete/AutocompletePlaces";
import FormValidator from "../../../../../../components/Validators/FormValidator";
import TextFieldValidator from "../../../../../../components/Validators/TextFieldValidator";
import { SnackbarError } from "../../../../../../helpers/snackbar";
import { shopActions } from "../../../../Shop/store/actions";
import { addShippingAddressLoaderSelector } from "../../../../Shop/store/selectors";

const UpdateShippingAddressDialog = ({shippingAddress, dialogOpen, setDialogOpen}) => {

  const {t} = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const loading = useSelector(addShippingAddressLoaderSelector);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [data, setData] = useState({
    shippingAddress: {
      ...shippingAddress,
      address: shippingAddress.address,
      components: {
        city: shippingAddress.city,
        country: shippingAddress.country,
        streetNumber: shippingAddress.streetNumber,
        streetName: shippingAddress.streetName,
        zip: shippingAddress.zip
      },
      coordinates: {
        lat: shippingAddress.latitude,
        lng: shippingAddress.longitude
      }
    }
  });

  const handleSubmit = () => {

    setFormSubmitted(true);
    if (!data.shippingAddress) {
      SnackbarError(t('errorMessageRequiredFields'));
      return false;
    }
    dispatch(shopActions.updateShippingAddressRequest(shippingAddress.id, data));
    setDialogOpen(false);
  };

  const handleChange = (event) => {

    setData({
      ...data,
      shippingAddress: {
        ...data.shippingAddress,
        [event.target.name]: event.target.value
      }
    });
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  const handleShippingAddressChanged = (place) => {

    setData({
      ...data,
      shippingAddress: {
        ...data.shippingAddress,
        ...place
      }
    });
  };

  return (
    <Dialog disableScrollLock onClose={handleClose} open={dialogOpen || false} fullScreen={fullScreen}>
      <CloseIcon onClick={handleClose}/>
      <DialogTitle
        id="update-address-dialog-title"
        sx={{
          color: theme.palette.primary.main,
          fontFamily: 'Open sans',
        }}>
        <Trans i18nKey="updateShippingAddress">Update shipping address</Trans>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{
          mt: 1,
          '& .MuiInputBase-root': {
            fontFamily: 'Open sans',
            fontSize: '0.8rem',
            color: '#7C909B',
            height: '48px',
            borderRadius: '30px'
          },
          '& .MuiFormLabel-root': {
            fontFamily: 'Open sans',
            fontSize: '0.8rem',
            color: '#7C909B',
            fontStyle: 'italic'
          }
        }}>
          <Grid item lg={12}>
            <FormValidator
              onSubmit={handleSubmit}
              onError={errors => console.log(errors)}
              noValidate
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextFieldValidator
                    fullWidth
                    name="name"
                    placeholder={t('home')}
                    label={t("giveNameToYourAddress")}
                    autoComplete="shippingAddressName"
                    value={data?.shippingAddress?.name || ""}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextFieldValidator
                    fullWidth
                    name="company"
                    placeholder={t('company')}
                    label={t("company")}
                    autoComplete="company"
                    value={data?.shippingAddress?.company || ""}
                    inputProps={{ maxLength: 17 }}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextFieldValidator
                    fullWidth
                    name="firstName"
                    label={t("firstName")}
                    autoComplete="firstName"
                    value={data?.shippingAddress?.firstName || ""}
                    onChange={handleChange}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextFieldValidator
                    fullWidth
                    name="lastName"
                    label={t("lastName")}
                    autoComplete="lastName"
                    value={data?.shippingAddress?.lastName || ""}
                    onChange={handleChange}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <AutocompletePlaces
                    fullWidth
                    variant="outlined"
                    name="address"
                    label={t("shippingAddress")}
                    initialValue={data?.shippingAddress?.address}
                    onPlaceChanged={handleShippingAddressChanged}
                    error={formSubmitted && !data?.shippingAddress?.address}
                    helperText={formSubmitted && !data?.shippingAddress?.address && (t("validatorFieldRequired"))}
                    placeholder=""
                    preview
                    customFieldsEnabled
                    requiredFields={['streetNumber', 'streetName', 'zip', 'city', 'country']}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextFieldValidator
                    fullWidth
                    name="postalBox"
                    label={t("postalBox")}
                    value={data?.shippingAddress?.postalBox || ""}
                    inputProps={{ maxLength: 8 }}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextFieldValidator
                    fullWidth
                    name="additionalInfo"
                    label={t("additionalInfo")}
                    value={data?.shippingAddress?.additionalInfo || ""}
                    inputProps={{ maxLength: 19 }}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ButtonWithLoader
                    type="submit"
                    isLoading={loading}
                    variant="contained"
                    color={"primary"}
                    sx={{
                      fontSize: '0.8rem',
                      textTransform: 'capitalize',
                      borderRadius: '30px',
                      color: '#fff',
                      lineHeight: '1.3',
                      padding: '4px 8px',
                    }}>
                    <Trans i18nKey={"updateShippingAddressButton"}>
                      Update shipping address
                    </Trans>
                  </ButtonWithLoader>
                </Grid>
              </Grid>
            </FormValidator>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
export default UpdateShippingAddressDialog;
