import React from "react";
import {Trans} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import {makeStyles} from '@mui/styles';

import {userSelector} from "../../../../../Authentication/store/selectors";
import ShareCartButton from "../../../../Shop/Checkout/components/ShareCartButton";
import {shopActions} from "../../../../Shop/store/actions";
import {shippingAddressSelectedSelector} from "../../../../Shop/store/selectors";

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiDivider-root': {
      margin: -1,
      [theme.breakpoints.down('xs')]: {
        display: 'none'
      },
    },
    [theme.breakpoints.down('xs')]: {
      '& .MuiCard-root': {
        overflow: 'auto'
      }
    },
    '& .MuiTypography-subtitle1': {
      maxHeight: 42,
      lineHeight: '20px',
      overflow: 'hidden'
    },
    '& img': {
      maxWidth: '100%',
      maxHeight: 80
    }
  },
  listItems: {
    [theme.breakpoints.up('sm')]: {
      overflow: 'auto'
    }
  },
  item: {
    [theme.breakpoints.up('sm')]: {
      '& .MuiListItem-root': {
        borderRight: '1px solid #DDD'
      }
    },
  },
  detailsRowPrice: {
    paddingTop: 20
  },
  divider: {
    //borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
  }
}));

const OrderRecommendationDetails = ({order}) => {

  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const shippingAddressSelected = useSelector(shippingAddressSelectedSelector);

  const setCartByOrder = (orderId) => {

    dispatch(shopActions.setCartFromOrderRequest(orderId, shippingAddressSelected.country));
  };

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <Grid container spacing={2} alignItems={"center"}>
          <Grid item>
            <ShareCartButton order={order} variant={"outlined"} color={"primary"}>
              <Trans i18nKey={"shareThisOrder"}>Share this order</Trans>
            </ShareCartButton>
          </Grid>
          {user.id === order.userId && (
            <Grid item>
              <Button>
                <Typography variant={"button"} onClick={() => setCartByOrder(order.id)}
                  color={"secondary"}>
                  <Trans i18nKey={"orderThisRecommendation"}>Order this recommendation</Trans>
                </Typography>
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12} sm={5}>
        <List className={classes.listItems}>
          {order.products.map((item, key) => (
            <React.Fragment key={key}>
              <ListItem className={classes.item}>
                <Grid container justify={"center"} alignItems={"center"}>
                  <Grid item xs={4}>
                    <Grid container justify={"center"} alignItems={"center"}>
                      <img
                        width={80}
                        height={80}
                        src={item.originalProduct.image[200]}
                        alt={item.originalProduct.detail.name}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={8}>
                    <Grid container justify={"center"} alignItems={"center"}>
                      <Grid item xs={8}>
                        <Typography variant="subtitle1">
                          <b>{item.quantity} x</b> {item.originalProduct.detail.name}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </ListItem>
              {order.products.length > 1 && (
                <Divider/>
              )}
            </React.Fragment>
          ))}
        </List>
      </Grid>
    </Grid>
  );
};

export default OrderRecommendationDetails;
