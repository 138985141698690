import React, { useEffect, useState } from "react";
import {useTranslation} from "react-i18next";
import { useLocation } from "react-router-dom";
import {AppBar, Box, Grid, Hidden, Typography, useScrollTrigger} from "@mui/material";
import Drawer from "@mui/material/Drawer";
import useMediaQuery from "@mui/material/useMediaQuery";

import {homeService} from "../../../../containers/Customer/Home/store/services";
import {getLangPath, Link} from "../../../../helpers/multilingual";

import HeaderLG from "./components/HeaderLG";
import HeaderXS from "./components/HeaderXS";
import MenuDrawer from "./MenuDrawer";

const drawerWidth = 240;
const Header = () => {

  const {i18n} = useTranslation();
  const [location, setLocation] = useState('index');
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [subLocation, setSubLocation] = useState(null);
  const domLocation = useLocation();
  const [topbar, setTopbar] = useState(null);
  const isUpSm = useMediaQuery(theme => theme.breakpoints.up("sm"));

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const isSearchBarShowed = useScrollTrigger({
    disableHysteresis: true,
    threshold: 150
  });
  const isScrolled = useScrollTrigger({
    disableHysteresis: true,
    threshold: 110
  });

  useEffect(() => {

    const match = domLocation.pathname.replace(getLangPath(), '').split('/');
    setLocation(match[1]);
    setSubLocation(match[2]);
  }, [domLocation]);

  useEffect(() => {
    homeService.getTopbar(i18n.language).then(response => {
      setTopbar(response.topbar);
    });
  }, [i18n.language]);

  return (
    <>
      {topbar && (
        <Hidden smDown implementation={"css"}>
          <AppBar
            component={"div"}
            elevation={0}
            position={"sticky"}
            sx={{
              backgroundColor: '#e8e8e8',
              padding: '0',
              height: 'auto',
              '& .MuiLink-root': {
                color: 'unset'
              }
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={4}>
                {topbar.link1
                  ?
                  <Box component={Link} to={topbar.link1}>
                    <Typography
                      variant={"body2"}
                      align="center"
                      dangerouslySetInnerHTML={{ __html: topbar.text1 }}
                      sx={{
                        fontFamily: 'Montserrat',
                        '& p': {
                          m: '8px 0'
                        }
                      }}/>
                  </Box>
                  :
                  <Typography
                    variant={"body2"}
                    align="center"
                    dangerouslySetInnerHTML={{ __html: topbar.text1 }}
                    sx={{
                      fontFamily: 'Montserrat',
                      '& p': {
                        m: '8px 0'
                      }
                    }}/>
                }
              </Grid>
              <Grid item xs={4}>
                {topbar.link2
                  ?
                  <Box component={Link} to={topbar.link2}>
                    <Typography
                      variant={"body2"}
                      align="center"
                      dangerouslySetInnerHTML={{ __html: topbar.text2 }}
                      sx={{
                        fontFamily: 'Montserrat',
                        '& p': {
                          m: '8px 0'
                        }
                      }}/>
                  </Box>
                  :
                  <Typography
                    variant={"body2"}
                    align="center"
                    dangerouslySetInnerHTML={{ __html: topbar.text2 }}
                    sx={{
                      fontFamily: 'Montserrat',
                      '& p': {
                        m: '8px 0'
                      }
                    }}/>
                }
              </Grid>
              <Grid item xs={4}>
                {topbar.link3
                  ?
                  <Box component={Link} to={topbar.link3}>
                    <Typography
                      variant={"body2"}
                      align="center"
                      dangerouslySetInnerHTML={{ __html: topbar.text3 }}
                      sx={{
                        fontFamily: 'Montserrat',
                        '& p': {
                          m: '8px 0'
                        }
                      }}/>
                  </Box>
                  :
                  <Typography
                    variant={"body2"}
                    align="center"
                    dangerouslySetInnerHTML={{ __html: topbar.text3 }}
                    sx={{
                      fontFamily: 'Montserrat',
                      '& p': {
                        m: '8px 0'
                      }
                    }}/>
                }
              </Grid>
            </Grid>
          </AppBar>
        </Hidden>
      )}
      <AppBar elevation={0} position={isUpSm ? "sticky" : "static"}>
        {isUpSm && (
          <HeaderLG isScrolled={isScrolled} isSearchBarShowed={isSearchBarShowed} location={location} subLocation={subLocation}/>
        )}
        {!isUpSm && (
          <HeaderXS mobileOpen={mobileOpen} setMobileOpen={setMobileOpen}/>
        )}
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="nutri-logics menu"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          <MenuDrawer setMobileOpen={setMobileOpen}/>
        </Drawer>
      </Box>
    </>
  );
};
export default Header;
