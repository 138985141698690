import {
  ADD_CUSTOMER_ANAMNESIS_NOTE_ERROR,
  ADD_CUSTOMER_ANAMNESIS_NOTE_REQUEST,
  ADD_CUSTOMER_ANAMNESIS_NOTE_SUCCESS,
  ADD_CUSTOMER_ERROR,
  ADD_CUSTOMER_ORDER_ERROR,
  ADD_CUSTOMER_ORDER_REQUEST,
  ADD_CUSTOMER_ORDER_SUCCESS,
  ADD_CUSTOMER_REQUEST,
  ADD_CUSTOMER_SUCCESS,
  DELETE_CUSTOMER_ANAMNESIS_NOTE_ERROR,
  DELETE_CUSTOMER_ANAMNESIS_NOTE_REQUEST,
  DELETE_CUSTOMER_ANAMNESIS_NOTE_SUCCESS,
  GET_CUSTOMER_ANAMNESES_ERROR,
  GET_CUSTOMER_ANAMNESES_REQUEST,
  GET_CUSTOMER_ANAMNESES_SUCCESS,
  GET_CUSTOMER_ANAMNESIS_ERROR,
  GET_CUSTOMER_ANAMNESIS_REQUEST,
  GET_CUSTOMER_ANAMNESIS_SUCCESS,
  GET_CUSTOMER_ERROR,
  GET_CUSTOMER_ORDERS_ERROR,
  GET_CUSTOMER_ORDERS_REQUEST,
  GET_CUSTOMER_ORDERS_SUCCESS,
  GET_CUSTOMER_REDIRECTIONS_ERROR,
  GET_CUSTOMER_REDIRECTIONS_REQUEST,
  GET_CUSTOMER_REDIRECTIONS_SUCCESS,
  GET_CUSTOMER_REQUEST,
  GET_CUSTOMER_SUCCESS,
  GET_CUSTOMERS_ERROR,
  GET_CUSTOMERS_REQUEST,
  GET_CUSTOMERS_SUCCESS,
  REQUEST_SUPPORT_ERROR,
  REQUEST_SUPPORT_REQUEST,
  REQUEST_SUPPORT_SUCCESS,
  UPDATE_CUSTOMER_ERROR,
  UPDATE_CUSTOMER_REQUEST,
  UPDATE_CUSTOMER_SHIPPING_ADDRESS_ERROR,
  UPDATE_CUSTOMER_SHIPPING_ADDRESS_REQUEST,
  UPDATE_CUSTOMER_SHIPPING_ADDRESS_SUCCESS,
  UPDATE_CUSTOMER_SUCCESS,
} from "./constants";

export const customersActions = {
  getCustomersRequest,
  getCustomersSuccess,
  getCustomersError,
  getCustomerRequest,
  getCustomerSuccess,
  getCustomerError,
  getCustomerOrdersRequest,
  getCustomerOrdersSuccess,
  getCustomerOrdersError,
  getCustomerAnamnesesRequest,
  getCustomerAnamnesesSuccess,
  getCustomerAnamnesesError,
  addCustomerRequest,
  addCustomerSuccess,
  addCustomerError,
  addCustomerOrderRequest,
  addCustomerOrderSuccess,
  addCustomerOrderError,
  updateCustomerRequest,
  updateCustomerSuccess,
  updateCustomerError,
  updateCustomerShippingAddressRequest,
  updateCustomerShippingAddressSuccess,
  updateCustomerShippingAddressError,
  getCustomerAnamnesisRequest,
  getCustomerAnamnesisSuccess,
  getCustomerAnamnesisError,
  addCustomerAnamnesisNoteRequest,
  addCustomerAnamnesisNoteSuccess,
  addCustomerAnamnesisNoteError,
  deleteCustomerAnamnesisNoteRequest,
  deleteCustomerAnamnesisNoteSuccess,
  deleteCustomerAnamnesisNoteError,
  requestSupportRequest,
  requestSupportSuccess,
  requestSupportError,
  getCustomerRedirectionsRequest,
  getCustomerRedirectionsSuccess,
  getCustomerRedirectionsError
};

function getCustomersRequest() {
  return {
    type: GET_CUSTOMERS_REQUEST,
  };
}

function getCustomersSuccess(customers) {
  return {
    type: GET_CUSTOMERS_SUCCESS,
    customers
  };
}

function getCustomersError(error) {
  return {
    type: GET_CUSTOMERS_ERROR,
    error
  };
}

function getCustomerRequest(customerId) {
  return {
    type: GET_CUSTOMER_REQUEST,
    customerId
  };
}

function getCustomerSuccess(customer) {
  return {
    type: GET_CUSTOMER_SUCCESS,
    customer
  };
}

function getCustomerError(error) {
  return {
    type: GET_CUSTOMER_ERROR,
    error
  };
}

function getCustomerOrdersRequest(customerId) {
  return {
    type: GET_CUSTOMER_ORDERS_REQUEST,
    customerId
  };
}

function getCustomerOrdersSuccess(customerOrders) {
  return {
    type: GET_CUSTOMER_ORDERS_SUCCESS,
    customerOrders
  };
}

function getCustomerOrdersError(error) {
  return {
    type: GET_CUSTOMER_ORDERS_ERROR,
    error
  };
}

function getCustomerAnamnesesRequest(customerId) {
  return {
    type: GET_CUSTOMER_ANAMNESES_REQUEST,
    customerId
  };
}

function getCustomerAnamnesesSuccess(anamneses) {
  return {
    type: GET_CUSTOMER_ANAMNESES_SUCCESS,
    anamneses
  };
}

function getCustomerAnamnesesError(error) {
  return {
    type: GET_CUSTOMER_ANAMNESES_ERROR,
    error
  };
}

function addCustomerRequest(customer) {
  return {
    type: ADD_CUSTOMER_REQUEST,
    customer
  };
}

function addCustomerSuccess(customer) {
  return {
    type: ADD_CUSTOMER_SUCCESS,
    customer
  };
}

function addCustomerError(error) {
  return {
    type: ADD_CUSTOMER_ERROR,
    error
  };
}

function addCustomerOrderRequest(customer, products) {
  return {
    type: ADD_CUSTOMER_ORDER_REQUEST,
    customer,
    products
  };
}

function addCustomerOrderSuccess(customerOrder) {
  return {
    type: ADD_CUSTOMER_ORDER_SUCCESS,
    customerOrder
  };
}

function addCustomerOrderError(error) {
  return {
    type: ADD_CUSTOMER_ORDER_ERROR,
    error
  };
}

function updateCustomerRequest(customer, data) {
  return {
    type: UPDATE_CUSTOMER_REQUEST,
    customer,
    data
  };
}

function updateCustomerSuccess(customer) {
  return {
    type: UPDATE_CUSTOMER_SUCCESS,
    customer
  };
}

function updateCustomerError(error) {
  return {
    type: UPDATE_CUSTOMER_ERROR,
    error
  };
}

function updateCustomerShippingAddressRequest(customer, data) {
  return {
    type: UPDATE_CUSTOMER_SHIPPING_ADDRESS_REQUEST,
    customer,
    data
  };
}

function updateCustomerShippingAddressSuccess(shippingAddress) {
  return {
    type: UPDATE_CUSTOMER_SHIPPING_ADDRESS_SUCCESS,
    shippingAddress
  };
}

function updateCustomerShippingAddressError(error) {
  return {
    type: UPDATE_CUSTOMER_SHIPPING_ADDRESS_ERROR,
    error
  };
}

function getCustomerAnamnesisRequest(customer) {
  return {
    type: GET_CUSTOMER_ANAMNESIS_REQUEST,
    customer
  };
}

function getCustomerAnamnesisSuccess(anamnesis) {
  return {
    type: GET_CUSTOMER_ANAMNESIS_SUCCESS,
    anamnesis
  };
}

function getCustomerAnamnesisError(error) {
  return {
    type: GET_CUSTOMER_ANAMNESIS_ERROR,
    error
  };
}

function addCustomerAnamnesisNoteRequest(customer, note) {
  return {
    type: ADD_CUSTOMER_ANAMNESIS_NOTE_REQUEST,
    customer,
    note
  };
}

function addCustomerAnamnesisNoteSuccess(note) {
  return {
    type: ADD_CUSTOMER_ANAMNESIS_NOTE_SUCCESS,
    note
  };
}

function addCustomerAnamnesisNoteError(error) {
  return {
    type: ADD_CUSTOMER_ANAMNESIS_NOTE_ERROR,
    error
  };
}

function deleteCustomerAnamnesisNoteRequest(customer, note) {
  return {
    type: DELETE_CUSTOMER_ANAMNESIS_NOTE_REQUEST,
    customer, note
  };
}

function deleteCustomerAnamnesisNoteSuccess(note) {
  return {
    type: DELETE_CUSTOMER_ANAMNESIS_NOTE_SUCCESS,
    note
  };
}

function deleteCustomerAnamnesisNoteError(error) {
  return {
    type: DELETE_CUSTOMER_ANAMNESIS_NOTE_ERROR,
    error
  };
}

function requestSupportRequest(customer) {
  return {
    type: REQUEST_SUPPORT_REQUEST,
    customer
  };
}

function requestSupportSuccess() {
  return {
    type: REQUEST_SUPPORT_SUCCESS
  };
}

function requestSupportError(error) {
  return {
    type: REQUEST_SUPPORT_ERROR,
    error
  };
}

function getCustomerRedirectionsRequest() {
  return {
    type: GET_CUSTOMER_REDIRECTIONS_REQUEST
  };
}

function getCustomerRedirectionsSuccess(customers) {
  return {
    type: GET_CUSTOMER_REDIRECTIONS_SUCCESS,
    customers
  };
}

function getCustomerRedirectionsError(error) {
  return {
    type: GET_CUSTOMER_REDIRECTIONS_ERROR,
    error
  };
}
