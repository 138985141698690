import {
  COACH_ROLE,
  CONSULTANT_ROLE,
  DELEGATE_ROLE,
  MANAGER_ROLE,
  NUTRITIONIST_CAREER,
  PARTNER_CAREER
} from "../../../Authentication/store/constants";

export const getConsultantLabels = (role, career) => {

  let primary;
  let level = null;
  switch (career) {
    case NUTRITIONIST_CAREER:
      primary = NUTRITIONIST_CAREER;
      switch (role) {
        case COACH_ROLE:
          level = 1;
          break;
        case MANAGER_ROLE:
          level = 2;
          break;
      }
      break;
    case PARTNER_CAREER:
      primary = PARTNER_CAREER;
      switch (role) {
        case DELEGATE_ROLE:
          level = 1;
          break;
        case CONSULTANT_ROLE:
          level = 2;
          break;
        case COACH_ROLE:
          level = 3;
          break;
        case MANAGER_ROLE:
          level = 4;
          break;
      }
      break;
    default:
      primary = role;
  }
    
  return ({primary, level});
};