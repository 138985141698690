import React from "react";
import {useDispatch} from "react-redux";
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import {IconButton} from "@mui/material";

import {shopActions} from "../../../containers/Customer/Shop/store/actions";

const UserMenu = () => {

  const dispatch = useDispatch();

  const handleOpenAccountDrawer = () => {
    dispatch(shopActions.SetDrawersOpen("account", true));
  };

  return (
    <IconButton
      onClick={handleOpenAccountDrawer}
      variant={"text"}
    >
      <PersonOutlineIcon/>
    </IconButton>
  );
};
export default UserMenu;
