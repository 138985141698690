import React from "react";
import Grid from "@mui/material/Grid";

import UserCard from "./UserCard";
import UserNotes from "./UserNotes";

const UserAccount = ({user}) => (
  <Grid container spacing={1}>
    <Grid item xs={12}>
      <UserNotes user={user}/>
    </Grid>
    <Grid item xs={12}>
      <UserCard user={user}/>
    </Grid>
  </Grid>
);
export default UserAccount;
