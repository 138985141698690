import React from 'react';
import {Box, Grid, Stack, Typography} from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";

export default function CustomSectionColumn3({section}) {

  if(!section?.name) {
    return null;
  }

  return (
    <Box
      sx={{
        margin: '40px 0 0',
      }}
    >
      <Container maxWidth={'xxl'}>
        <Grid container spacing={2} alignItems={"center"} sx={{pt: '24px'}}>
          {[1, 2, 3].map((i) => (
            <Grid item xs={12} sm={4} md={4} key={`section-${section.id}-${i}`}>
              <Card sx={{boxShadow: '6px 6px 12px #00000021', borderRadius: '12px'}}>
                <CardContent>
                  <Stack gap={2} alignItems={"center"}>
                    <Box component={'img'} alt={""} src={section[`image${i}`]} width={160}/>
                    <Typography
                      variant={"body1"}
                      dangerouslySetInnerHTML={{ __html: section[`text${i}`] }}
                      sx={{
                        textAlign: 'center',
                        fontSize: {xs: '1rem !important', sm: '0.875rem !important', md:'1.1rem !important'},
                        '& p': {
                          margin: 0,
                        },
                        '& p:first-of-type': {
                          fontFamily: 'Open sans',
                          color: 'primary.light',
                          fontWeight: '400'
                        },
                        '& p:last-child': {
                          fontFamily: 'Montserrat',
                          color: 'primary.main',
                          fontWeight: '600'
                        }
                      }}
                    />
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>

      </Container>
    </Box>
  );
}
