import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RemoveIcon from "@mui/icons-material/Remove";
import {ListItemButton} from "@mui/material";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Collapse from "@mui/material/Collapse";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import {PaymentElement, useElements, useStripe} from "@stripe/react-stripe-js";

import DotsLoader from "../../../../../components/DotsLoader";
import PaymentMethodsList from "../../../../../components/PaymentMethodsList";
import {getLangPath} from "../../../../../helpers/multilingual";
import {SnackbarError} from "../../../../../helpers/snackbar";
import {userSelector} from "../../../../Authentication/store/selectors";
import {shopActions} from "../../store/actions";
import {elementFormReadySelector, paymentMethodSelector, processingCheckoutSelector} from "../../store/selectors";
import {shopService} from "../../store/services";

const CheckoutForm = ({className, order, paymentMethods, paymentRef}) => {

  const stripe = useStripe();
  const elements = useElements();
  const {t} = useTranslation();
  const processing = useSelector(processingCheckoutSelector);
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const [paymentMethodsListOpen, setPaymentMethodsListOpen] = useState(false);
  const [paymentElementOpen, setPaymentElementOpen] = useState(false);
  const paymentMethod = useSelector(paymentMethodSelector);
  const elementFormReady = useSelector(elementFormReadySelector);
  const [saveCard, setSaveCard] = useState(false);

  useEffect(() => {
    if (Object.keys(paymentMethods)?.length > 0) {
      setPaymentMethodsListOpen(true);
    } else {
      setPaymentElementOpen(true);
    }
  }, [paymentMethods]);

  useEffect(() => {
    if (elements) elements.addEventListener("error", () => {
    });
    return (() => elements?.removeEventListener("error"));
  }, [elements]);
  const handleChangePaymentMethod = (paymentMethod) => {
    dispatch(shopActions.setPaymentMethod({id: paymentMethod?.id, type: paymentMethod?.type}));
  };

  const togglePaymentMethodsListOpen = () => {
    if (paymentElementOpen) togglePaymentElementOpen();
    setPaymentMethodsListOpen(!paymentMethodsListOpen);
  };

  const handleChangeSaveCard = async () => {
    setSaveCard(!saveCard);
    dispatch(shopActions.setCheckoutProcessing(true));
    await shopService.updateOrderSavePaymentMethod(order.id, !saveCard);
    dispatch(shopActions.setCheckoutProcessing(false));
  };

  const togglePaymentElementOpen = () => {
    if (paymentMethodsListOpen) {
      dispatch(shopActions.setPaymentMethod({id: "", type: ""}));
      togglePaymentMethodsListOpen();
    }
    setPaymentElementOpen(!paymentElementOpen);
  };

  const handleSubmit = async (ev) => {
    ev.preventDefault();

    dispatch(shopActions.setCheckoutProcessing(true));

    shopService.setCartLog("Le client clique sur valider le paiement", {from: "checkoutForm", userId: user?.id}).then().catch();

    let error,
      payment;
    switch (paymentMethod?.type) {
      case "card":
        //We update order.savePaymentMethod to true if the user use a known card so it is not deleted
        await handleChangeSaveCard(order.id, true);
        payment = await stripe.confirmCardPayment(elements?._commonOptions.clientSecret.clientSecret, {
          payment_method: paymentMethod.id
        });
        error = payment?.error;
        //history.push(`${getLangPath()}/shop/?checkout_oid=${order.id}&reload=true`);
        window.location.href = `${process.env.REACT_APP_WEB_URL}${getLangPath()}/shop/?checkout_oid=${order.id}`;
        break;
      default:
        payment = await stripe.confirmPayment({
          elements,
          confirmParams: {
            return_url: `${process.env.REACT_APP_WEB_URL}${getLangPath()}/shop/?checkout_oid=${order.id}`
          },
          redirect: 'always'
        });
        error = payment?.error;
    }
    if (error) {
      SnackbarError(`${error.message}`);

      if (error.type === "validation_error") {
        dispatch(shopActions.setCheckoutProcessing(false));
      } else {
        window.location.href = `${process.env.REACT_APP_WEB_URL}${getLangPath()}/shop/?checkout_oid=${order.id}`;
        dispatch(shopActions.setCheckoutProcessing(false));
      }
    } else {
      dispatch(shopActions.setCheckoutProcessing(false));
    }
  };

  return (
    <form className={className} onSubmit={handleSubmit} ref={paymentRef}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <List>
            <ListItemButton onClick={togglePaymentMethodsListOpen}>
              <ListItemIcon>
                {paymentMethodsListOpen
                  ? <RemoveIcon/>
                  : <AddIcon/>
                }
              </ListItemIcon>
              <ListItemText primary={t("useKnownPaymentMethod")}/>
              {paymentMethodsListOpen ? <ExpandLess/> : <ExpandMore/>}
            </ListItemButton>
            <Collapse in={paymentMethodsListOpen} timeout="auto" unmountOnExit
              onExit={() => dispatch(shopActions.setPaymentMethod({id: "", type: ""}))}>
              <PaymentMethodsList paymentMethods={paymentMethods}
                setPaymentMethod={handleChangePaymentMethod}
                paymentMethod={paymentMethod}/>
            </Collapse>
            <ListItemButton onClick={togglePaymentElementOpen}>
              <ListItemIcon>
                {paymentElementOpen
                  ? <RemoveIcon/>
                  : <AddIcon/>
                }
              </ListItemIcon>
              <ListItemText primary={t("addNewPaymentMethod")}/>
              {paymentElementOpen ? <ExpandLess/> : <ExpandMore/>}
            </ListItemButton>
            <Collapse in={paymentElementOpen} timeout="auto" unmountOnExit
              onExit={() => dispatch(shopActions.setElementFormReady(false))}>
              {!elementFormReady && (<DotsLoader/>)}
              <PaymentElement
                onReady={() => {
                  dispatch(shopActions.setElementFormReady(true));
                }}
              />
            </Collapse>
          </List>
        </Grid>
      </Grid>
      {
        elementFormReady || paymentMethod?.id !== ""
          ? <Grid container spacing={2}>
            <Grid item>
              <Button
                type="submit"
                variant={"contained"}
                color={"primary"}
                disabled={processing}
                sx={{
                  borderRadius: '30px',
                  color: '#ffffff'
                }}
              >
                {processing && (
                  `${t("loading")} ...`
                )}
                {!processing && (
                  `${t("pay")} ${(Math.round((order.totalPrice + order.taxes - order.walletAmount - order.giftCardAmount) * 100) / 100).toFixed(2)} ${order.currency}`
                )}
              </Button>
            </Grid>
            {paymentMethod?.id === "" && (
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      icon={<RadioButtonUncheckedIcon fontSize="small"/>}
                      checkedIcon={<RadioButtonCheckedIcon fontSize="small"/>}
                      checked={saveCard}
                      onChange={handleChangeSaveCard}
                      value="true" color="primary"/>
                  }
                  label={"Sauvegarder cette carte de paiement pour vos prochaines commandes"}
                  name={"saveCard"}
                />
              </Grid>
            )}
          </Grid>
          : null
      }
    </form>
  );
};
export default CheckoutForm;



