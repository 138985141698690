import React, {useState} from "react";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import { grey } from "@mui/material/colors";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import {useTheme} from '@mui/material/styles';
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import {makeStyles} from '@mui/styles';

import FormValidator from "../../../../components/Validators/FormValidator";
import {SnackbarConfirm} from "../../../../helpers/snackbar";
import SearchUserAutocomplete from "../../Users/components/SearchUserAutocomplete";
import {userService} from "../store/services";

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiDialog-paper': {
      [theme.breakpoints.up('sm')]: {
        width: '80vw',
        minWidth: 760,
        '& .MuiListItem-root': {
          borderRight: '1px solid #DDD'
        },
        '& .MuiDivider-root': {
          margin: -1
        }
      },
      [theme.breakpoints.down('xs')]: {
        '& .MuiCard-root': {
          overflow: 'auto'
        }
      },
      '& .MuiTypography-subtitle1': {
        maxHeight: 42,
        lineHeight: '20px',
        overflow: 'hidden'
      },
      '& h4': {
        marginBottom: 20
      },
      '& .MuiInputBase-input': {
        backgroundColor: theme.palette.common.white
      }
    },
    '& img': {
      maxWidth: '100%',
      maxHeight: 80
    }
  },
  form: {
    '& .enterBtn': {
      marginTop: 42,
    },
    '& .secondaryLink': {
      marginTop: 15,
      '& .MuiButton-label': {
        lineHeight: 1.5,
        fontSize: '1rem'
      }
    },
  },
  closeButton: {
    top: 10,
    right: 15,
    cursor: 'pointer',
    position: 'absolute',
    fontSize: 36,
    color: grey[500],
    [theme.breakpoints.down('xs')]: {
      top: 10,
      right: 20,
      fontSize: 40,
    },
  },
}));

const UserTransferModal = ({user, open, setOpen, onSuccess}) => {
  const theme = useTheme();
  const classes = useStyles();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const [pro, setPro] = useState(null);

  const handleClose = () => {
    setOpen(false);
  };

  const handleUserChange = (e, pro) => {
    setPro(pro);
  };

  const handleSubmit = () => {

    if(pro) {
      SnackbarConfirm(`Tansférer l'utilisateur ?`, () => {
        userService.transferUser(user, pro).then(() => {
          onSuccess();
          setOpen(false);
        });
      });
    }
  };

  return (
    <Dialog onClose={handleClose} open={open} fullScreen={fullScreen} className={classes.root}>
      <CloseIcon className={classes.closeButton} onClick={handleClose}/>
      <DialogTitle id="responsive-dialog-title">Transfert de vente</DialogTitle>
      <DialogContent>
        <Card elevation={0}>
          <CardHeader
            subheader={"Vous êtes sur le point de transférer la vente à un " +
                            "autre pro, attention vous devrez également tranférer le client " +
                            "pour les futures ventes"}
          />
          <CardContent>
            <FormValidator
              className={classes.form}
              onSubmit={handleSubmit}
              onError={errors => console.log(errors)}
              noValidate
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <SearchUserAutocomplete target={"pro"} onChange={handleUserChange}/>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                  >
                                        Effectuer le tranfert
                  </Button>
                </Grid>
              </Grid>
            </FormValidator>
          </CardContent>
        </Card>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          <Typography>
                        Annuler
          </Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UserTransferModal;
