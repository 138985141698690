import React  from "react";
import { useSelector } from "react-redux";

import CustomerGiftCardsCard from "./components(old)/CustomerGiftCardsCard";
import { customerSelector } from "./store/selectors";

const CustomerGiftCards = () => {

  const customer = useSelector(customerSelector());

  return (
    <CustomerGiftCardsCard customer={customer} />
  );
};
export default CustomerGiftCards;
