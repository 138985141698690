import React from "react";
import { useSelector } from "react-redux";

import CustomerShippingAddresses from "./components(old)/CustomerShippingAddresses";
import { customerSelector } from "./store/selectors";

const UpdateCustomerShippingAddresses = () => {

  const customer = useSelector(customerSelector());

  return (
    <CustomerShippingAddresses customer={customer} />
  );
};
export default UpdateCustomerShippingAddresses;
