import { initReactI18next } from "react-i18next";
import i18n from 'i18next';

import translationsEN from '../assets/locales/en';
import translationsFR from '../assets/locales/fr';
import translationsNL from '../assets/locales/nl';

i18n
  .use(initReactI18next)
  .init({
    lng: 'fr',
    supportedLngs: ['fr', 'en', 'nl'],
    load: 'languageOnly',
    resources: {
      fr: {
        translations: translationsFR
      },
      nl: {
        translations: translationsNL
      },
      en: {
        translations: translationsEN
      }
    },
    fallbackLng: "fr",
    debug: process.env.NODE_ENV === "development",

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
