import React, { useEffect, useState } from "react";
import {Trans, useTranslation} from "react-i18next";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { grey } from "@mui/material/colors";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import {makeStyles} from '@mui/styles';

import PageLoader from "../../../../components/PageLoader";
import FormValidator from "../../../../components/Validators/FormValidator";
import TextFieldValidator from "../../../../components/Validators/TextFieldValidator";
import {SnackbarConfirm, SnackbarFormattedError, SnackbarSuccess} from "../../../../helpers/snackbar";
import useMoment from "../../../../helpers/useMoment";
import { customersService } from "../store/services";

const useStyles = makeStyles((theme) => ({
  root: {
    '& .headerNote': {
      borderBottom: `1px solid ${grey[400]}`,
    },
    '& .date': {
      fontSize: 14,
    },
    '& .note': {
      display: 'inline-block',
      minWidth: '40vw',
      borderRadius: 8,
      whiteSpace: 'pre-wrap',
      padding: theme.spacing(1, 2, 0),
    },
    '& .contentInput': {
      marginTop: theme.spacing(2),
    },
  }
}));

const CustomerNotesCard = ({customer}) => {

  const classes = useStyles();
  const moment = useMoment();
  const { t } = useTranslation();
  const [notes, setNotes] = useState(null);
  const [showOlderNotes, setShowOlderNotes] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formValues, setFormValues] = useState({
    content: ''
  });

  useEffect(() => {
    loadNotes();
  }, []);

  const loadNotes = () => {
    customersService.getCustomerNotes(customer.id)
      .then(notes => setNotes(notes))
      .catch(e => SnackbarFormattedError(e.error));
  };

  const handleChangeFormValue = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = () => {

    if(!!formValues.content && formValues.content !== '') {
      setFormSubmitted(true);
      customersService.addCustomerNote(customer.id, formValues)
        .then(() => {
          setFormSubmitted(false);
          setFormValues({...formValues, content: ''});
          loadNotes();
          SnackbarSuccess(t('noteAdded'));
          return true;
        })
        .catch(e => {
          setFormSubmitted(false);
          SnackbarFormattedError(e.error);
        });
    }
  };

  const handleRemoveNote = (noteId) => () => {
    SnackbarConfirm(t('delete'), () => {
      customersService.removeCustomerNote(customer.id, noteId)
        .then(() => {
          loadNotes();
          SnackbarSuccess(t('noteRemoved'));
        })
        .catch(e => {
          SnackbarFormattedError(e.error);
        });
    });
  };

  const handleShowOlderNotes = () => {
    setShowOlderNotes(true);
  };

  return (
    <Card className={classes.root} elevation={1} rounded="true" square={false}>
      <CardContent>
        <Grid container justify="center" spacing={1}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Grid container justify={"space-between"} alignItems={"center"}>
              <Grid item>
                <Typography component="h3" variant={"h6"}>
                  <Trans i18nKey={"notes"}>Notes</Trans> :
                </Typography>
              </Grid>
              <Grid item>
                {notes && notes.length > 3 && !showOlderNotes && (
                  <Button
                    size={"small"}
                    color={"primary"}
                    variant={"outlined"}
                    onClick={handleShowOlderNotes}
                  >
                    <Trans i18nKey={"showOlderNotes"}>Show older notes</Trans>
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
          {!notes && (
            <PageLoader height={100}/>
          )}
          {notes && notes.slice((!showOlderNotes && notes.length > 3) ? Math.max(notes.length - 3, 1) : 0).map(note => (
            <Grid item xs={12} key={note.id}>
              <Grid className={"headerNote"} container justify={"space-between"} alignItems={"flex-end"}>
                <Grid item>
                  <Typography className={'date'}>{moment(note.createdAt).format('LL')}</Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    <IconButton
                      size={"small"}
                      onClick={handleRemoveNote(note.id)}
                    >
                      <DeleteOutlineIcon/>
                    </IconButton>
                  </Typography>
                </Grid>
              </Grid>
              <Typography className={'note'}>
                {note.content}
              </Typography>
            </Grid>
          ))}
          <Grid item xs={12}>
            <FormValidator
              noValidate
              onSubmit={handleSubmit}
              onError={console.log}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextFieldValidator
                    fullWidth
                    name="content"
                    className={"contentInput"}
                    label={t("writeNote")}
                    value={formValues.content ? formValues.content : ""}
                    onChange={handleChangeFormValue}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Grid container justify={"space-between"} alignItems={"flex-start"} spacing={2}>
                    <Grid item>
                      <Button
                        variant='contained'
                        disableElevation
                        type="submit"
                        color="primary"
                        disabled={formSubmitted}
                      >
                        {formSubmitted && `${t('sending')} ...`}
                        {!formSubmitted && t('submit')}
                      </Button>
                    </Grid>
                    <Grid item>
                      <Typography component={"i"}>
                        <Trans i18nKey={"notesArePrivate"}>Notes are private</Trans>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </FormValidator>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default CustomerNotesCard;
