import * as actionsType from './constants';

const signUpCredentialsRequest = (user, persistent) => ({
  type: actionsType.SIGNUP_CREDENTIALS_REQUEST,
  persistent,
  user
});

const signUpCredentialsSuccess = (user) => ({
  type: actionsType.SIGNIN_CREDENTIALS_SUCCESS,
  user
});

const signUpCredentialsFailed = () => ({
  type: actionsType.SIGNIN_CREDENTIALS_FAILED
});

const signUpProfilingRequest = (user, persistent) => ({
  type: actionsType.SIGNUP_PROFILING_REQUEST,
  persistent,
  user
});

export function signInJwtRequest(jwt) {

  return {
    type: actionsType.SIGNIN_JWT_REQUEST,
    jwt
  };
}

export function signInJwtSuccess(user) {

  return {
    type: actionsType.SIGNIN_JWT_SUCCESS,
    user
  };
}

export function signInJwtFailed() {

  return {
    type: actionsType.SIGNIN_JWT_FAILED
  };
}

export function signInCredentialsRequest(email, password, persistent) {

  return {
    type: actionsType.SIGNIN_CREDENTIALS_REQUEST,
    email,
    password,
    persistent
  };
}

export function signInCredentialsSuccess(user) {

  return {
    type: actionsType.SIGNIN_CREDENTIALS_SUCCESS,
    user
  };
}

export function signInCredentialsFailed() {

  return {
    type: actionsType.SIGNIN_CREDENTIALS_FAILED,
  };
}

function signOut() {

  return {
    type: actionsType.SIGNOUT
  };
}

function signOutInvalidAccessToken() {

  return {
    type: actionsType.SIGNOUT_INVALID_ACCESS_TOKEN
  };
}

function signOutAllDevices() {

  return {
    type: actionsType.SIGNOUT_ALL_DEVICES
  };
}

function reloadUserRequest() {

  return {
    type: actionsType.RELOAD_USER_REQUEST
  };
}

function reloadUserSuccess(user) {

  return {
    type: actionsType.RELOAD_USER_SUCCESS,
    user
  };
}

function reloadUserFailed() {

  return {
    type: actionsType.RELOAD_USER_FAILED
  };
}


function updateUserRequest(user) {

  return {
    type: actionsType.UPDATE_USER_REQUEST,
    user
  };
}
function updateUserSuccess(user) {

  return {
    type: actionsType.UPDATE_USER_SUCCESS,
    user
  };
}
function updateUserFailed() {

  return {
    type: actionsType.UPDATE_USER_FAILED
  };
}

function getUserContactRequest(id) {

  return {
    type: actionsType.GET_USER_CONTACT_REQUEST,
    id
  };
}
function getUserContactSuccess(contact) {

  return {
    type: actionsType.GET_USER_CONTACT_SUCCESS,
    contact
  };
}
function getUserContactFailed() {

  return {
    type: actionsType.GET_USER_CONTACT_FAILED
  };
}
function setMembershipModalOpen(open) {

  return {
    type: actionsType.SET_MEMBERSHIP_MODAL_OPEN,
    open
  };
}
function takeControlRequest(userId) {

  return {
    type: actionsType.TAKE_CONTROL_REQUEST,
    userId
  };
}
function takeControlSuccess(user) {

  return {
    type: actionsType.TAKE_CONTROL_SUCCESS,
    user
  };
}
function takeControlFailed(error) {

  return {
    type: actionsType.TAKE_CONTROL_FAILED,
    error
  };
}
function restoreControlRequest() {

  return {
    type: actionsType.RESTORE_CONTROL_REQUEST,
  };
}
function restoreControlSuccess(user) {

  return {
    type: actionsType.RESTORE_CONTROL_SUCCESS,
    user
  };
}
function restoreControlFailed(error) {

  return {
    type: actionsType.RESTORE_CONTROL_FAILED,
    error
  };
}
function setMailingSubscription(subscribed) {

  return {
    type: actionsType.SET_MAILING_SUBSCRIPTION,
    subscribed
  };
}

export const authenticationActions = {
  signUpProfilingRequest,
  signUpCredentialsRequest,
  signUpCredentialsSuccess,
  signUpCredentialsFailed,
  signInJwtRequest,
  signInJwtSuccess,
  signInJwtFailed,
  signInCredentialsRequest,
  signInCredentialsSuccess,
  signInCredentialsFailed,
  signOut,
  signOutInvalidAccessToken,
  signOutAllDevices,
  reloadUserRequest,
  reloadUserSuccess,
  reloadUserFailed,
  updateUserRequest,
  updateUserSuccess,
  updateUserFailed,
  getUserContactRequest,
  getUserContactSuccess,
  getUserContactFailed,
  setMembershipModalOpen,
  takeControlRequest,
  takeControlSuccess,
  takeControlFailed,
  restoreControlRequest,
  restoreControlSuccess,
  restoreControlFailed,
  setMailingSubscription
};
