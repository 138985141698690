import axios  from '../../../helpers/axios';
import Cookie from "../../../helpers/cookie";

export const authenticationService = {
  signUpCredentials,
  signInCredentials,
  signInJwt,
  signOut,
  hardSignOut,
  signOutAllDevices,
  passwordRecovery,
  updatePassword,
  updateEmail,
  updateUser,
  sendProfilingTest,
  sendContactMessage,
  sendWelcomeEmail,
  getUserContact,
  getUserSessionControl,
  getUserBackFromSessionControl,
  getMe,
  checkProCode
};

function signUpCredentials(user, persistent = false) {

  return new Promise((resolve, reject) => {

    axios.post('/auth/signup/credentials', {
      user
    })
      .then(({user, token, refreshToken, storageToken}) => {
        axios.setToken(token);
        setCookies({token, refreshToken: persistent ? refreshToken : null, storageToken});
        if(process.env.NODE_ENV === "development") {
          localStorage.setItem("user", JSON.stringify(user));
        }
        resolve({user, token, refreshToken});
      })
      .catch((e) => {
        reject(e.response && e.response.data);
      });
  });
}

function signInCredentials(email, password, persistent = false) {

  return new Promise((resolve, reject) => {

    axios.post('/auth/signin/credentials', {
      email, password
    })
      .then(({user, token, refreshToken, storageToken}) => {
        axios.setToken(token);
        setCookies({token, refreshToken: persistent ? refreshToken : null, storageToken});
        if(process.env.NODE_ENV === "development") {
          localStorage.setItem("user", JSON.stringify(user));
        }
        resolve({user, token, refreshToken});
      })
      .catch((e) => {
        reject(e.response && e.response.data);
      });
  });
}

function signInJwt(jwt) {

  return new Promise((resolve, reject) => {

    axios.setToken(jwt);
    axios.post('/auth/signin/jwt')
      .then(({user, token, refreshToken, storageToken}) => {
        axios.setToken(token);
        setCookies({token, refreshToken, storageToken});
        if(process.env.NODE_ENV === "development") {
          localStorage.setItem("user", JSON.stringify(user));
        }
        resolve(user);
      })
      .catch((e) => {
        axios.destroyToken();
        reject(e.response && e.response.data);
      });
  });
}

function getMe() {

  return new Promise((resolve, reject) => {

    axios.get('/user/me')
      .then(({user}) => {
        if(process.env.NODE_ENV === "development") {
          localStorage.setItem("user", JSON.stringify(user));
        }
        resolve(user);
      })
      .catch((e) => {
        reject(e.response && e.response.data);
      });
  });
}

function getUserSessionControl(id) {

  return new Promise((resolve, reject) => {

    axios.get(`/auth/session/${id}/control`)
      .then(({user, token}) => {
        axios.setToken(token);
        Cookie.set('session-token', Cookie.get('token'));
        Cookie.set('token', token);
        if(process.env.NODE_ENV === "development") {
          localStorage.setItem("user", JSON.stringify(user));
        }
        resolve({user, token});
      })
      .catch((e) => {
        reject(e.response && e.response.data);
      });
  });
}

function getUserBackFromSessionControl() {

  return new Promise((resolve, reject) => {

    const token = Cookie.get('session-token');
    axios.setToken(token);
    axios.get(`/user/me`)
      .then(({user}) => {
        Cookie.set("token", token);
        Cookie.remove('session-token');
        if(process.env.NODE_ENV === "development") {
          localStorage.setItem("user", JSON.stringify(user));
        }
        resolve({user, token});
      })
      .catch((e) => {
        reject(e.response && e.response.data);
      });
  });
}

function signOut() {

  axios.destroyToken();
  localStorage.removeItem("cart");
  if(process.env.NODE_ENV === "development") {
    localStorage.removeItem("user");
  }
  let token = Cookie.get('refresh-token');
  removeCookies();
  return axios.post('/auth/signout', {token});
}

function hardSignOut() {

  axios.destroyToken();
  if(process.env.NODE_ENV === "development") {
    localStorage.removeItem("user");
  }
  removeCookies();
  return true;
}

function signOutAllDevices() {

  return new Promise((resolve, reject) => {

    axios.delete('/auth/refreshtokens')
      .then(() => {
        resolve();
      })
      .catch((e) => {
        reject(e.response && e.response.data);
      });
  });
}

function passwordRecovery(email) {

  return new Promise((resolve, reject) => {

    axios.get(`/user/recovery/${email}/password`)
      .then(() => {
        resolve();
      })
      .catch((e) => {
        reject(e.response && e.response.data);
      });
  });
}

function updatePassword(password, confirmPassword, token) {

  return new Promise((resolve, reject) => {

    axios.put('/user/password', {password, confirmPassword, token})
      .then(() => {
        resolve();
      })
      .catch((e) => {
        reject(e.response && e.response.data);
      });
  });
}

function updateEmail(token) {

  return new Promise((resolve, reject) => {

    axios.put('/user/email', {token})
      .then(() => {
        resolve();
      })
      .catch((e) => {
        reject(e.response && e.response.data);
      });
  });
}

function updateUser(user) {

  return new Promise((resolve, reject) => {

    axios.patch('/user/me', user)
      .then(({user}) => {
        resolve(user);
      })
      .catch((e) => {
        reject(e.response && e.response.data);
      });
  });
}

//function getConsultantsBySearch(search) {
//
//    return new Promise((resolve, reject) => {
//
//        axios.get(`/user/consultants/search/${search}`)
//            .then(({users}) => {
//                resolve(users);
//            })
//            .catch((e) => {
//                reject(e);
//            });
//    });
//}

function sendProfilingTest(data) {

  return new Promise((resolve, reject) => {

    axios.post(`/pro/profiling/request`, data)
      .then(() => {
        resolve();
      })
      .catch((e) => {
        reject(e);
      });
  });
}

function sendContactMessage(data) {

  return new Promise((resolve, reject) => {

    axios.post(`/user/contact`, data)
      .then(() => {
        resolve();
      })
      .catch((e) => {
        reject(e);
      });
  });
}

function sendWelcomeEmail(userId) {

  return new Promise((resolve, reject) => {

    axios.get(`/user/${userId}/welcome`)
      .then(() => {
        resolve();
      })
      .catch((e) => {
        reject(e);
      });
  });
}


function getUserContact(id) {

  return new Promise((resolve, reject) => {

    axios.get(`/user/contact/${id}`)
      .then(({contact}) => {
        resolve(contact);
      })
      .catch((e) => {
        reject(e);
      });
  });
}

function checkProCode(proCode) {

  return new Promise((resolve, reject) => {

    axios.get(`/user/proCode/${proCode}`)
      .then(({pro}) => {
        resolve(pro);
      })
      .catch((e) => {
        reject(e);
      });
  });
}

function setCookies({token, refreshToken = null, storageToken = null, sessionToken = null}) {

  Cookie.set('token', token);
  if(refreshToken) {
    Cookie.set('refresh-token', refreshToken);
  }
  if(storageToken) {
    Cookie.set('storage-token', storageToken);
  }
  if(sessionToken) {
    Cookie.set('session-token', sessionToken);
  }
}

function removeCookies() {

  Cookie.remove('token');
  Cookie.remove('refresh-token');
  Cookie.remove('storage-token');
  Cookie.remove('session-token');
  Cookie.remove('shipping-address');
  Cookie.remove('shipping-method');
}
