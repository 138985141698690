import React from "react";
import {useSelector} from "react-redux";
import AllOutIcon from '@mui/icons-material/AllOut';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import EventNoteOutlinedIcon from '@mui/icons-material/EventNoteOutlined';
import LocalShippingTwoToneIcon from '@mui/icons-material/LocalShippingTwoTone';
import ReplayTwoToneIcon from '@mui/icons-material/ReplayTwoTone';
import ScheduleIcon from '@mui/icons-material/Schedule';
import StoreTwoToneIcon from "@mui/icons-material/StoreTwoTone";
import {Box} from "@mui/material";
import Divider from "@mui/material/Divider";
import Toolbar from "@mui/material/Toolbar";

import theme from "../../../assets/styles/theme/theme";
import {isSuperAdmin} from "../../../containers/Authentication/store/selectors";
import {Link} from "../../../helpers/multilingual";
import Menu from "../Menu/Menu";
import MenuItem from "../Menu/MenuItem";

const MenuDrawer = ({setMobileOpen}) => {

  const userIsSuperAdmin = useSelector(isSuperAdmin);

  return (
    <div>
      <Toolbar>
        <Link to="/admin">
          <Box component={'img'} width={50} src={`${process.env.REACT_APP_API_RESOURCES_URL}/images/logo/logo.svg`} alt="logo" sx={{width: '100%'}}/>
        </Link>
      </Toolbar>
      <Divider />
      <Menu sx={{
        '& .MuiListItemText-primary': {
          fontFamily: 'Open sans',
          color: 'black'
        },
        '& .MuiListItemIcon-root': {
          color: theme.palette.primary.main
        }
      }}>
        <MenuItem
          title={"Commandes à préparer"}
          expandTo={"/logistic/orders/todo"}
          to={"/logistic/orders/todo"}
          icon={<EventNoteOutlinedIcon sx={{color: '#4a148c'}}/>}
          setMobileOpen={setMobileOpen}
        />
        <MenuItem
          title={"Départs différés"}
          expandTo={"/logistic/orders/deferredTodo"}
          to={"/logistic/orders/deferredTodo"}
          icon={<EventNoteOutlinedIcon sx={{color: '#4a148c'}}/>}
          setMobileOpen={setMobileOpen}
        />
        <MenuItem
          title={"Back orders"}
          expandTo={"/logistic/orders/backOrdersTodo"}
          to={"/logistic/orders/backOrdersTodo"}
          icon={<EventNoteOutlinedIcon sx={{color: '#4a148c'}}/>}
          setMobileOpen={setMobileOpen}
        />
        <MenuItem
          title={"Préparées en attente de livraison"}
          expandTo={"/logistic/orders/processing"}
          to={"/logistic/orders/processing"}
          icon={<ScheduleIcon sx={{color: '#4a148c'}}/>}
          setMobileOpen={setMobileOpen}
        />
        <MenuItem
          title={"En cours de livraison"}
          expandTo={"/logistic/orders/inTransit"}
          to={"/logistic/orders/inTransit"}
          icon={<LocalShippingTwoToneIcon sx={{color: '#4a148c'}}/>}
          setMobileOpen={setMobileOpen}
        />
        <MenuItem
          title={"En attente au point relais"}
          expandTo={"/logistic/orders/inPickupPoint"}
          to={"/logistic/orders/inPickupPoint"}
          icon={<StoreTwoToneIcon sx={{color: '#4a148c'}}/>}
          setMobileOpen={setMobileOpen}
        />
        <MenuItem
          title={"Retour à l'expéditeur"}
          expandTo={"/logistic/orders/backToSender"}
          to={"/logistic/orders/backToSender"}
          icon={<ReplayTwoToneIcon sx={{color: '#4a148c'}}/>}
          setMobileOpen={setMobileOpen}
        />
        <MenuItem
          title={"Urgences"}
          expandTo={"/logistic/orders/emergencies"}
          to={"/logistic/orders/emergencies"}
          icon={<ErrorOutlineOutlinedIcon sx={{color: '#f44336'}}/>}
          setMobileOpen={setMobileOpen}
        />
        <MenuItem
          hidden={!userIsSuperAdmin}
          title={"Erreurs"}
          expandTo={"/logistic/orders/errored"}
          to={"/logistic/orders/errored"}
          icon={<ErrorOutlineOutlinedIcon sx={{color: '#f44336'}} />}
          setMobileOpen={setMobileOpen}
        />
        <MenuItem
          title={"Tous les envois"}
          expandTo={"/logistic/orders/done"}
          to={"/logistic/orders/done"}
          icon={<AllOutIcon sx={{color: '#4a148c'}}/>}
          setMobileOpen={setMobileOpen}
        />
      </Menu>
    </div>
  );
};

export default MenuDrawer;
