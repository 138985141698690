import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import {Box, Stack} from "@mui/material";
import { grey } from "@mui/material/colors";
import Container from "@mui/material/Container";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import {useTheme} from '@mui/material/styles';
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import {makeStyles} from '@mui/styles';
import moment from 'moment';

import DataGrid from "../../../../components/DataGrid/DataGrid";
import DotsLoader from "../../../../components/DotsLoader";
import {SnackbarInfo} from "../../../../helpers/snackbar";
import {DELEGATE_ROLE} from "../../../Authentication/store/constants";
import SaleDetailsModal from "../../../Pro/Commissions/Sales/components/SaleDetailsModal";
import {userService} from "../store/services";


const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiDialog-paper': {
      [theme.breakpoints.up('sm')]: {
        width: '80vw',
        minWidth: 760,
        '& .MuiListItem-root': {
          borderRight: '1px solid #DDD'
        },
        '& .MuiDivider-root': {
          margin: -1
        }
      },
      [theme.breakpoints.down('xs')]: {
        '& .MuiCard-root': {
          overflow: 'auto'
        }
      },
      '& .MuiTypography-subtitle1': {
        maxHeight: 42,
        lineHeight: '20px',
        overflow: 'hidden'
      },
      '& h4': {
        marginBottom: 20
      },
      '& .MuiInputBase-input': {
        backgroundColor: theme.palette.common.white
      }
    },
    '& img': {
      maxWidth: '100%',
      maxHeight: 80
    }
  },
  form: {
    '& .enterBtn': {
      marginTop: 42,
    },
    '& .secondaryLink': {
      marginTop: 15,
      '& .MuiButton-label': {
        lineHeight: 1.5,
        fontSize: '1rem'
      }
    },
  },
  closeButton: {
    top: 10,
    right: 15,
    cursor: 'pointer',
    position: 'absolute',
    fontSize: 36,
    color: grey[500],
    [theme.breakpoints.down('xs')]: {
      top: 10,
      right: 20,
      fontSize: 40,
    },
  },
}));

function a11yProps(index) {
  return {
    id: `points-details-vertical-tab-${index}`,
    'aria-controls': `points-details-vertical-tabpanel-${index}`,
  };
}

const UserPointsModal = ({user, open, setOpen}) => {
  const theme = useTheme();
  const classes = useStyles();
  const [points, setPoints] = useState([]);
  const months = [
    {name:"Janvier", value: 1},
    {name:"Février", value: 2},
    {name:"Mars", value: 3},
    {name:"Avril", value: 4},
    {name:"Mai", value: 5},
    {name:"Juin", value: 6},
    {name:"Juillet", value: 7},
    {name:"Août", value: 8},
    {name:"Septembre", value: 9},
    {name:"Octobre", value: 10},
    {name:"Novembre", value: 11},
    {name:"Décembre", value: 12},
  ];
  const [month, setMonth] = useState({name:months[moment().month()].name,value:months[moment().month()].value});
  const years = [
    {name:"2023", value: 0},
    {name:"2024", value: 1},
  ];
  const [year, setYear] = useState({name:years[1].name,value:years[1].value});
  const [isLoading, setIsLoading] = useState(false);
  const {userId} = useParams();
  const [totalPoints, setTotalPoints] = useState(0);
  const [totalProPoints, setTotalProPoints] = useState(0);
  const [totalOrders, setTotalOrders] = useState(0);
  const [clientNumber, setClientNumber] = useState(0);
  const [orderSelected, setOrderSelected] = useState(null);
  const [isSaleDetailsModalOpen, setIsSaleDetailsModalOpen] = useState(false);

  useEffect(() => {
    (async() => {
      setIsLoading(true);
      let fetchedPoints = await userService.getPointsDetails(userId, year.name, month.value);
      setPoints(fetchedPoints);
      // setting total points for the period based on points.amount - points.fees
      setTotalPoints(Math.floor(fetchedPoints.reduce((acc, point) => acc + (point.amount), 0)));
      setTotalProPoints(Math.floor(fetchedPoints
        .filter(point => ['commission', 'commissionRefund'].includes(point.object))
        .reduce((acc, point) => acc + point.amount, 0)));
      // setting total orders for the period based on points.commission, if points.commission is not null
      setTotalOrders(fetchedPoints.reduce((acc, point) => acc + (point.object === 'commission' ? 1 : 0), 0));
      // setting unique client count for the period based on points.order.client.id
      let clients = [];
      fetchedPoints.forEach(data => {
        if (data.commission && !clients.includes(data.commission.order.client.id)) {
          clients.push(data.commission.order.client.id);
        }
      });
      setClientNumber(clients.length);
      setIsLoading(false);
    })();

  }, [month, year]);

  const handleShowSaleDetails = (order) => {
    setOrderSelected(order);
    setIsSaleDetailsModalOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeMonth = (e, value) => {
    setMonth({...months[value]});
  };

  const handleChangeYear = (e, value) => {
    setYear({...years[value]});
  };

  const handleRowClick = (e) => {
    if(e.row?.commission?.order){
      handleShowSaleDetails(e.row?.commission?.order);
    } else {
      SnackbarInfo("Cette ligne ne correspond pas à une commande");
    }
  };

  const columns = [{
    field: "date",
    headerName: "Date",
    flex: true,
    tooltip: true,
    valueGetter: (params) =>
      `${moment(params.row.createdAt).format("DD/MM/YYYY")}`,
  },{
    field: "lastName",
    headerName: user.role === DELEGATE_ROLE ? "Therapeute" : "Client",
    flex: true,
    tooltip: true,
    valueGetter: (params) => user.role === DELEGATE_ROLE ? `${params.row.commission?.order?.pro?.lastName} ${params.row.commission?.order?.pro?.firstName}` : `${params.row.commission?.order?.client?.lastName} ${params.row.commission?.order?.client?.firstName}`
  },{
    field: "amountHTVA",
    headerName: "Montant HTVA",
    flex: true,
    tooltip: true,
    type: 'number',
    valueGetter: (params) =>
      `${params.row.commission ? parseFloat((params.row.commission?.order?.totalPrice)).toFixed(2) : ""}`,
  },{
    field: "amountTVAC",
    headerName: "Montant TVAC",
    flex: true,
    tooltip: true,
    type: 'number',
    valueGetter: (params) =>
      `${params.row.commission ? (parseFloat(params.row.commission?.order?.totalPrice) + parseFloat(params.row.commission?.order?.taxes)).toFixed(2) : 0}`,
  },{
    field: "points",
    headerName: "Points",
    flex: true,
    tooltip: true,
    type: 'number',
    valueGetter: (params) =>
      `${(parseFloat(params.row.amount)).toFixed(2)}`,
  },{
    field: "reason",
    headerName: "Raison",
    flex: true,
    tooltip: true,
    valueGetter: (params) =>
      `${params.row.object === "commission" ? "Vente" : params.row?.commission?.order?.status === "points" ? "achat perso" : params.row.object}`,
  },{
    field: "createdBy",
    headerName: "Opérateur",
    flex: true,
    tooltip: true,
    valueGetter: (params) => {
      if(params.row.commission){
        return `${params.row.commission?.order?.createdByUser?.firstName} ${params.row.commission?.order?.createdByUser?.lastName}`;
      } else if(params.row.createdByUser){
        return `${params.row.createdByUser?.firstName} ${params.row.createdByUser?.lastName}`;
      } else {
        return '';
      }
    }
  }];

  return (
    <Dialog onClose={handleClose} open={open} className={classes.root} fullScreen={true}>
      <CloseIcon sx={{
        top: 10,
        right: 15,
        cursor: 'pointer',
        position: 'absolute',
        fontSize: 36,
        color: grey[500],
        [theme.breakpoints.down('xs')]: {
          top: 10,
          right: 20,
          fontSize: 40,
        },
      }} onClick={handleClose}/>
      <DialogTitle id="responsive-dialog-title">Détail des points</DialogTitle>

      <Tabs
        orientation="horizontal"
        variant="scrollable"
        value={month.value -1}
        onChange={handleChangeMonth}
        aria-label="points-details-vertical-tabs"
        sx={{
          backgroundColor: '#EEF1F2'
        }}
      >
        {months.map((month, index) => (
          <Tab
            key={index}
            label={month.name}
            sx={{
              color: '#7C909B',
              fontSize: "10px",
              fontFamily: "Montserrat"
            }}
            {...a11yProps(index)} />
        ))}
      </Tabs>

      <DialogContent>
        <Container
          maxWidth="xl"
          sx={{
            height: "100%",
            paddingLeft: '0px !important',
            paddingRight: '0px !important',
            overflowX: 'hidden',
          }}>
          <Box sx={{width: '100%', display: 'flex'}}>
            <Box
              sx={{
                background: '#EEF1F2',
                height: '100%',
                pt: '0 !important',
                width: '90px',
                '& .MuiButtonBase-root.MuiTab-root.MuiTab-labelIcon': {
                  width: '90px',
                  padding: 0
                }
              }}>
              <Tabs
                orientation="vertical"
                variant="scrollable"
                value={year.value}
                onChange={handleChangeYear}
                aria-label="points-details-vertical-tabs"
                sx={{
                  backgroundColor: '#EEF1F2'
                }}
              >
                <Tab
                  key={0}
                  label={"2023"}
                  sx={{
                    color: '#7C909B',
                    fontSize: "10px",
                    fontFamily: "Montserrat"
                  }}
                  {...a11yProps(0)} />
                <Tab
                  key={1}
                  label={"2024"}
                  sx={{
                    color: '#7C909B',
                    fontSize: "10px",
                    fontFamily: "Montserrat"
                  }}
                  {...a11yProps(0)} />
              </Tabs>
            </Box>
            {isLoading && (
              <Box sx={{justifyContent:"center",width: '100%'}}>
                <DotsLoader />
              </Box>
            )}
            {!isLoading && (
              <Box
                sx={{
                  background: '#fff',
                  maxWidth: {mw: '100%'},
                  width: '100%',
                  pl: 1,
                  minHeight: '100vh',
                  overflowY: 'auto',
                }}>
                <Grid container justify="space-between">
                  <Grid item xs={4}>
                    <Typography component={"div"} variant="subtitle1">
                        Nombre de commandes : {totalOrders}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography component={"div"} variant="subtitle1">
                        Nombre de patients ayant commandé: {clientNumber}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Stack>
                      <Typography component={"div"} variant="subtitle1">
                        Nombre de points générés : {totalProPoints}
                      </Typography>
                      <Typography component={"div"} variant="subtitle1">
                        Nombre de points réel : {totalPoints}
                      </Typography>
                    </Stack>
                  </Grid>
                </Grid>
                <Box sx={{width:'100%'}}>
                  <DataGrid
                    autoHeight
                    data={points}
                    columns={columns}
                    disableSelectionOnClick
                    onRowClick={handleRowClick}
                    initialPageSize={100}
                    toolbarColumns
                    toolbarFilter
                    toolbarDensity
                    toolbarExportCSV
                    toolbarQuickSearch
                    experimentalFeatures={{ newEditingApi: true }}
                    toolbarExportCSVOptions={{fileName: `${user?.lastName}-points-${month.name}-${year.name}`}}
                    sx={{'& .MuiDataGrid-row': {cursor: 'pointer'}}}
                  />
                </Box>
              </Box>
            )}
          </Box>
        </Container>
        {orderSelected && (
          <SaleDetailsModal order={orderSelected} setIsModalOpen={setIsSaleDetailsModalOpen} isModalOpen={isSaleDetailsModalOpen}/>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default UserPointsModal;
