import React, {useEffect} from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";

import { currencyConvert } from "../../../../../helpers/utils";
import {userSelector, walletAllowedSelector} from "../../../../Authentication/store/selectors";
import {walletActions} from "../../../Wallet/store/actions";
import { walletSelector } from "../../../Wallet/store/selectors";
import { shopActions } from "../../store/actions";
import { checkoutSelector, useWalletSelector } from "../../store/selectors";
import {shopService} from "../../store/services";

const WalletAmountSelector = ({className}) => {

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const checkout = useSelector(checkoutSelector);
  const wallet = useSelector(walletSelector());
  const useWallet = useSelector(useWalletSelector);
  const isUserWalletAllowed = useSelector(walletAllowedSelector);

  useEffect(() => {
    dispatch(walletActions.getWalletRequest());
  }, [user]);

  const handleUseWallet = (e) => {
    dispatch(shopActions.useWalletAmount(e.target.checked));
    shopService.setCartLog("Le client choisis d'utiliser des points",{from:"walletAmountSelector"}).then().catch();
  };

  if(!checkout || !wallet || wallet.amount <= 0 || !isUserWalletAllowed) {
    return null;
  }

  return (
    <Grid item xs={12}>
      <Grid container justify={"center"} className={className}>
        <Grid item xs={12}>
          <Typography component="h4" variant={"h6"}>
            <Trans i18nKey="yourNLAccount">Your nutri-logics account</Trans> :
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormControl component="fieldset">
            <FormGroup>
              <FormControlLabel
                control={<Switch checked={useWallet} onChange={handleUseWallet} name="useWallet" />}
                label={t("useFromWallet", {
                  amount:
                                        checkout.order.walletAmount
                                          ? checkout.order.walletAmount.toFixed(2)
                                          : currencyConvert(wallet.amount, wallet.currency, checkout.order.currency) < parseFloat(checkout.order.totalPrice) + parseFloat(checkout.order.taxes)
                                            ? currencyConvert(wallet.amount, wallet.currency, checkout.order.currency).toFixed(2)
                                            : (parseFloat(checkout.order.totalPrice) + parseFloat(checkout.order.taxes)).toFixed(2),
                  currency: checkout.order.currency
                })}
              />
            </FormGroup>
          </FormControl>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default WalletAmountSelector;
