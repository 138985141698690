import {useTranslation} from "react-i18next";
import moment from "moment";

import "moment/locale/fr";

const useMoment = () => {

  const {i18n} = useTranslation();
  moment.locale(i18n.language);

  return moment;
};
export default useMoment;