import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {InputBase} from "@mui/material";
import Grid from "@mui/material/Grid";
import ListItem from "@mui/material/ListItem";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import { Link } from "../../../../../helpers/multilingual";
import {getProductPriceWithTaxes} from "../../../../../helpers/utils";
import {userSelector} from "../../../../Authentication/store/selectors";
import { getFormalizedName } from "../../helpers/helper";

const ReadOnlyCartItem = ({item, sx, gifted = false}) => {


  const user = useSelector(userSelector);
  const [price, setPrice] = useState(0);

  useEffect(() => {
    setPrice(getProductPriceWithTaxes(item, user, user?.shippingAddress, 1, true));
  }, [item, item.quantity]);

  return (

    <ListItem
      sx={{
        borderRadius: '12px',
        '&:hover': {
          backgroundColor: '#F5F5F5',
        },
        ...sx
      }}
    >
      <Grid container justify={"center"} alignItems={"center"}>
        <Grid item xs={2} sm={2}>
          <Grid container justify={"center"} alignItems={"center"}>
            <img
              width={60}
              height={60}
              src={item.originalProduct.image && item.originalProduct.image[200]}
              alt={item.originalProduct.reference}
            />
          </Grid>
        </Grid>
        <Grid item xs={10} sm={10}>
          <Grid container justify={"center"} alignItems={"center"}>
            <Grid item xs={10} sx={{pl: '0px !important'}}>
              <Typography
                component={Link} to={`/shop/product/${getFormalizedName(item.originalProduct.reference)}/${item.originalProduct.id}`}
                sx={{
                  fontSize: '0.875rem',
                  fontWeight: 'bold',
                  fontFamily: 'Montserrat',
                  color: '#7C909B',
                }}>
                {item.originalProduct.reference}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              {gifted && (
                <Typography
                  sx={{
                    textAlign: 'center',
                    fontSize: '0.8rem',
                    fontFamily: 'Montserrat',
                    color: 'rgb(34, 154, 22)',
                    backgroundColor: 'rgba(84, 214, 44, 0.16)',
                  }}
                >
                  {`Offert(s)`}
                </Typography>
              )}
            </Grid>
          </Grid>
          <Grid container justifyContent={"space-between"} alignItems={"center"} spacing={2}>
            <Grid item sx={{pl: '12px !important'}}>
              <Paper
                component="form"
                sx={{
                  boxShadow: 'none',
                  display: 'flex',
                  alignItems: 'center',
                  backgroundColor: '#7C909B1A',
                  height: '24px',
                  borderRadius: '12px',
                  width: '82px'
                }}>
                <InputBase
                  name={"quantity"}
                  value={item.quantity}
                  disabled
                  sx={{
                    ml: 0,
                    flex: 1,
                    color: '#7C909B',
                    fontSize: '16px',
                    fontFamily: 'Open sans',
                    '& .MuiInputBase-input': {
                      textAlign: 'center',
                    }
                  }}/>
              </Paper>
            </Grid>
            <Grid item>
              {!gifted && (
                <Typography sx={{
                  textAlign: 'right',
                  '& > span': {
                    fontSize: '16px',
                  }
                }}>
                  <>
                    <Typography
                      component={"span"}
                      sx={{
                        color: '#7C909B',
                        fontFamily: 'Open sans',
                        fontWeight: 'bold',
                        fontSize: '19px',
                        textAlign: 'right',
                      }}
                    >
                      {price + ' €'}
                    </Typography>
                  </>
                </Typography>)}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ListItem>
  );
};
export default ReadOnlyCartItem;
