let user;
try { user = JSON.parse(localStorage.getItem("user")) }
catch (e) { user = null }

export default {
  authentication  : {
    authenticationRequested: !!user,
    authenticated: !!user,
    user : user,
    contact: null,
    reloadUserRequested: false,
    updateUserRequested: false,
    structureMembershipModalOpen: false,
    partnerMembershipModalOpen: false,
    membershipAlerted: false
  }
};
