import axios  from '../../../../helpers/axios';

export const mailingService = {
  subscribe,
  unsubscribe,
  findDistinctCountries,
  contact
};

function subscribe() {

  return new Promise((resolve, reject) => {

    axios.get(`user/mailing/subscribe`)
      .then(() => {
        resolve();
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function contact(data) {

  return new Promise((resolve, reject) => {

    axios.post(`user/mailing/contact`, {data})
      .then(() => {
        resolve();
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function unsubscribe(email, userId) {

  return new Promise((resolve, reject) => {

    axios.get(`user/mailing/unsubscribe/${email}/${userId}`)
      .then(() => {
        resolve();
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function findDistinctCountries() {

  return new Promise((resolve, reject) => {

    axios.get(`user/countries/distinct`)
      .then(({countries}) => {
        resolve(countries);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}