let search = null;
try { search = JSON.parse(localStorage.getItem('map_search')) } catch (e) { search = null }

export default {
  consultants  : {
    map: {
      zoom: null,
      search: search,
      offices: null,
      display: 'list',
      searchBarModalOpen: false
    },
    office: null
  }
};
