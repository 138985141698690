import React from "react";
import {Route, Switch} from 'react-router-dom';
import loadable from "@loadable/component";

const ManagerRoutes = () => {

  const Dashboard = loadable(() => import("../containers/Manager/Dashboard/Dashboard"));
  const Topbars = loadable(() => import("../containers/Manager/Home/topbar/Topbars"));
  const AddTopbar = loadable(() => import("../containers/Manager/Home/topbar/AddTopbar"));
  const Topbar = loadable(() => import("../containers/Manager/Home/topbar/Topbar"));
  const Sliders = loadable(() => import("../containers/Manager/Home/slider/Sliders"));
  const AddSlider = loadable(() => import("../containers/Manager/Home/slider/AddSlider"));
  const Slider = loadable(() => import("../containers/Manager/Home/slider/Slider"));
  const Sections = loadable(() => import("../containers/Manager/Home/section/Sections"));
  const AddSection = loadable(() => import("../containers/Manager/Home/section/AddSection"));
  const Section = loadable(() => import("../containers/Manager/Home/section/Section"));
  //const Protocols = loadable(() => import("../containers/Manager/Protocol/Protocols"));
  //const EmptiesProtocols = loadable(() => import("../containers/Manager/Protocol/EmptiesProtocols"));
  //const AddProtocol = loadable(() => import("../containers/Manager/Protocol/AddProtocol"));
  //const Documents = loadable(() => import("../containers/Manager/Documents/Documents"));
  //const Protocol = loadable(() => import("../containers/Manager/Protocol/Protocol"));
  //const Meetings = loadable(() => import("../containers/Manager/Meeting/Meetings"));
  //const AddMeeting = loadable(() => import("../containers/Manager/Meeting/AddMeeting"));
  //const Meeting = loadable(() => import("../containers/Manager/Meeting/Meeting"));
  //const MeetingCategories = loadable(() => import("../containers/Manager/Meeting/Categories"));
  //const AddMeetingCategories = loadable(() => import("../containers/Manager/Meeting/AddCategory"));
  //const MeetingCategory = loadable(() => import("../containers/Manager/Meeting/Category"));
  //const Questions = loadable(() => import("../containers/Manager/Faq/Questions"));
  //const WaitingQuestions = loadable(() => import("../containers/Manager/Faq/WaitingQuestions"));
  //const Question = loadable(() => import("../containers/Manager/Faq/Question"));
  const Articles = loadable(() => import("../containers/Manager/Mag/Articles"));
  const AddArticle = loadable(() => import("../containers/Manager/Mag/AddArticle"));
  const Article = loadable(() => import("../containers/Manager/Mag/Article"));
  //const ReviewsWaiting = loadable(() => import("../containers/Manager/Shop/ReviewsWaiting"));
  //const ReviewsVerified = loadable(() => import("../containers/Manager/Shop/ReviewsVerified"));
  //const Review = loadable(() => import("../containers/Manager/Shop/Review"));
  //const CustomerSimplifiedAnamnesis = loadable(() => import("../containers/Manager/Customers/CustomerSimplifiedAnamnesis"));
  const Error = loadable(() => import("../containers/Pro/Error"));

  return (
    <Switch>
      <Route exact path="/manager" component={Dashboard} />
      <Route exact path="/manager/home/topbars" component={Topbars} />
      <Route exact path="/manager/home/topbar/add" component={AddTopbar} />
      <Route exact path="/manager/home/topbar/:topbarId" component={Topbar} />
      <Route exact path="/manager/home/sliders" component={Sliders} />
      <Route exact path="/manager/home/slider/add" component={AddSlider} />
      <Route exact path="/manager/home/slider/:sliderId" component={Slider} />
      <Route exact path="/manager/home/sections" component={Sections} />
      <Route exact path="/manager/home/section/add" component={AddSection} />
      <Route exact path="/manager/home/section/:sectionId" component={Section} />
      <Route exact path="/manager/mag/articles" component={Articles} />
      <Route exact path="/manager/mag/article/add" component={AddArticle} />
      <Route exact path="/manager/mag/article/:articleId" component={Article} />
      <Route path="*" component={Error}/>
    </Switch>
  );
};

export default ManagerRoutes;
