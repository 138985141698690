import React from "react";
import {Trans, useTranslation} from "react-i18next";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import {blue, green,red} from "@mui/material/colors";
import Grid from "@mui/material/Grid";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import {makeStyles} from '@mui/styles';

import theme from "../../../../../assets/styles/theme/theme";
import DotsLoader from "../../../../../components/DotsLoader";

const useStyles = makeStyles((theme) => ({

  root: {
    paddingTop: theme.spacing(4),
  },
  avatar: {
    margin: theme.spacing(2, 'auto'),
    width: 100,
    height: 100,
    backgroundColor: 'unset',
    '& img': {
      width: 100,
      height: 100
    },
    '& svg': {
      width: 120,
      height: 120,
      '&.success': {
        fill: green[600]
      },
      '&.info': {
        fill: blue[800]
      },
      '&.error': {
        fill: red[600]
      }
    }
  },
}));

const PaymentResponseInfo = ({status, url, onCancel}) => {

  const classes = useStyles();
  const {t} = useTranslation();

  return (
    <Grid container direction={"column"} justify={"center"} spacing={2} className={classes.root}>
      {(status === "checking") && (
        <>
          <Grid item>
            <Grid container direction={"column"} alignItems={"center"} justify={"center"} spacing={2}>
              <Grid item xs={12} md={8} lg={6}>
                <Typography gutterBottom align={"center"} sx={{
                  fontFamily: 'Open sans',
                  color: '#7C909B',
                }}>{t('paymentInProgress')} ...</Typography>
                <DotsLoader/>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
      {status === "processing" && (
        <>
          <Grid item>
            <Avatar className={classes.avatar}>
              <InfoOutlinedIcon className={"info"}/>
            </Avatar>
          </Grid>
          <Grid item>
            <Grid container spacing={2} direction={"column"} alignItems={"center"} justify={"center"}>
              <Grid item>
                <Typography component="h1" variant="h6" align={"center"} gutterBottom sx={{
                  color: theme.palette.primary.main,
                  fontFamily: 'Open sans',
                  mb: 2
                }}>
                  <Trans i18nKey={"yourPaymentIsBeingValidated"}>
                    Your payment is being validated
                  </Trans>
                </Typography>
                <LinearProgress color="secondary" />
              </Grid>
              <Grid item>
                <Typography align={"center"} sx={{
                  fontFamily: 'Open sans',
                  color: '#7C909B',
                }}>
                  <Trans i18nKey={"paymentOperationMayTakeAWhile"}>
                    You can close your browser. This operation may take a while. An email will be sent to you as soon as your payment is finalized.
                  </Trans>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
      {status === "requiresAction" && (
        <>
          <Grid item>
            <Avatar className={classes.avatar}>
              <InfoOutlinedIcon className={"info"}/>
            </Avatar>
          </Grid>
          <Grid item>
            <Grid container spacing={2} direction={"column"} alignItems={"center"} justify={"center"}>
              <Grid item>
                <Typography component="h1" variant="h6" align={"center"} gutterBottom sx={{
                  color: theme.palette.primary.main,
                  fontFamily: 'Open sans',
                  mb: 2
                }}>
                  <Trans i18nKey={"paymentMethodNeedAction"}>
                    Your payment need action
                  </Trans>
                </Typography>
              </Grid>
              {!!url && (
                <Grid item>
                  <Button
                    component={"a"}
                    href={url}
                    variant={"contained"}
                    color={"primary"}
                  >
                    <Trans i18nKey={"continue"}>Continue</Trans>
                  </Button>
                </Grid>
              )}
              {!url && (
                <>
                  <Grid item>
                    <Typography align={"center"} sx={{
                      color: theme.palette.primary.main,
                      fontFamily: 'Open sans',
                      mb: 2
                    }}>
                      <Trans i18nKey={"paymentMethodNeedActionDescription"}>Your bank needs another action to process the payment (Ex: 3D secure). After validation click on the button below.</Trans>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Grid container spacing={2} justify={"center"} alignItems={"center"}>
                      <Grid item>
                        <Button
                          variant={"outlined"}
                          color={"primary"}
                          onClick={onCancel}
                        >
                          <Trans i18nKey={"chooseAnotherPaymentMethod"}>Choose another payment method</Trans>
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          variant={"contained"}
                          color={"primary"}
                          onClick={() => window.location.reload()}
                        >
                          <Trans i18nKey={"verifyAgain"}>Verify again</Trans>
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </>
      )}
      {(status === "failed" || status === 'canceled' || status === 'requiresPaymentMethod') && (
        <>
          <Grid item>
            <Avatar className={classes.avatar}>
              <HighlightOffIcon className={"error"}/>
            </Avatar>
          </Grid>
          <Grid item>
            <Grid container spacing={2} direction={"column"} alignItems={"center"} justify={"center"}>
              <Grid item>
                <Typography align={"center"} component="h1" variant="h5" gutterBottom sx={{
                  color: theme.palette.primary.main,
                  fontFamily: 'Open sans',
                  mb: 2
                }}>
                  <Trans i18nKey={"paymentError"}>
                    Payment was not processed, please try again or choose another payment method
                  </Trans>
                </Typography>
              </Grid>
              <Grid item xs={12} md={8} lg={6}>
                <Typography align={"center"} sx={{
                  fontFamily: 'Open sans',
                  color: '#7C909B',
                }}>
                  <Trans i18nKey={"youWillBeRedirectedInFewSeconds"}>You will be redirected automatically in a few seconds.</Trans>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
      {status === "succeeded" && (
        <>
          <Grid item>
            <Avatar className={classes.avatar}>
              <CheckCircleOutlineIcon className={"success"}/>
            </Avatar>
          </Grid>
          <Grid item>
            <Grid container spacing={2} direction={"column"} alignItems={"center"} justify={"center"}>
              <Grid item>
                <Typography align={"center"} component="h1" variant="h5" gutterBottom sx={{
                  color: theme.palette.primary.main,
                  fontFamily: 'Open sans',
                  mb: 2
                }}>
                  <Trans i18nKey={"congratulationNewOrder"}>
                    Congratulations, your order has been confirmed
                  </Trans>
                </Typography>
              </Grid>
              <Grid item xs={12} md={8} lg={6}>
                <Typography align={"center"} sx={{
                  fontFamily: 'Open sans',
                  color: '#7C909B',
                }}>
                  <Trans i18nKey={"youWillBeRedirectedInFewSeconds"}>You will be redirected automatically in a few seconds.</Trans>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default PaymentResponseInfo;