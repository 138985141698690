import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import AutocompletePlaces from "../../../../components/GoogleAutocomplete/AutocompletePlaces";
import FormValidator from "../../../../components/Validators/FormValidator";
import TextFieldValidator from "../../../../components/Validators/TextFieldValidator";
import { SnackbarError } from "../../../../helpers/snackbar";
import { networkActions } from "../store/actions";

const PartnerUpdateCard = ({partner}) => {

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [addressChanged, setAddressChanged] = useState(false);
  const [specialCharacters, setSpecialCharacters] = useState(false);
  const [userValues, setUserValues] = useState({...partner, billingAddress: partner.address});

  const handleChange = (e) => {

    setUserValues({
      ...userValues,
      [e.target.name]: e.target.value
    });
  };

  const handleCheckRadioFormBooleanValue = (e) => {

    setUserValues({
      ...userValues,
      [e.target.name]: e.target.checked
    });
  };

  const handleTextFieldChange = (e) => {

    const pattern = /^[a-zA-Z0-9-]*$/;
    setUserValues({
      ...userValues,
      directLink: e.target.value
    });
    setSpecialCharacters(!pattern.test(e.target.value));
  };

  const handleBillingAddressChanged = (place) => {

    setAddressChanged(true);
    setUserValues({
      ...userValues,
      ['billingAddress']: place
    });
  };

  const handleSubmit = () => {

    setFormSubmitted(true);
    if (addressChanged && typeof userValues.billingAddress !== 'object') {
      SnackbarError(t('errorMessageRequiredFields'));
      return false;
    }
    dispatch(networkActions.updatePartnerRequest(partner, {...userValues}));
  };

  return (
    <Card>
      <CardContent>
        <FormValidator
          onSubmit={handleSubmit}
          onError={errors => {
            setFormSubmitted(true);
            console.log('ERRORS', errors);
          }}
          noValidate
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextFieldValidator
                fullWidth
                name="company"
                label={t("company")}
                value={userValues.company}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextFieldValidator
                fullWidth
                name="taxNumber"
                label={t("taxNumber")}
                value={userValues.taxNumber}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextFieldValidator
                fullWidth
                name="firstName"
                label={t("firstName")}
                value={userValues.firstName ? userValues.firstName : ""}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextFieldValidator
                fullWidth
                name="lastName"
                label={t("lastName")}
                value={userValues.lastName ? userValues.lastName : ""}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant={"outlined"}
                name="directLink"
                label={t("directLink")}
                value={userValues.directLink ? userValues.directLink : ""}
                onChange={handleTextFieldChange}
                error={specialCharacters}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <FormLabel>{t("clientsCanSearchYouBy")}</FormLabel>
                <FormGroup>
                  <FormControlLabel
                    control={<Switch checked={userValues.allowSearchByName} onChange={handleCheckRadioFormBooleanValue} name="allowSearchByName" />}
                    label={t("yourNameAndFirstName")}
                  />
                </FormGroup>
                <FormGroup>
                  <FormControlLabel
                    control={<Switch checked={userValues.company && userValues.allowSearchByCompany} onChange={handleCheckRadioFormBooleanValue} name="allowSearchByCompany" />}
                    label={<>{t("yourCompanyName")} {!userValues.company ? <Typography component={"i"}>{t('companyNeeded')}</Typography> : ``}</>}
                  />
                </FormGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextFieldValidator
                fullWidth
                id="email"
                type="email"
                name="email"
                label={t("email")}
                value={userValues.email ? userValues.email : ""}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextFieldValidator
                fullWidth
                required
                name="phone"
                label={t("phone")}
                autoComplete="phone"
                value={userValues.phone}
                onChange={handleChange}
              />
            </Grid>
            {/*<Grid item xs={12}>
              <SelectValidator
                fullWidth
                name="lang"
                label={t("mailingAndDataLanguage")}
                value={userValues.lang}
                onChange={handleChange}
                required
              >
                <MenuItem value="fr">
                  {t("french")}
                </MenuItem>
                <MenuItem value="en">
                  {t("english")}
                </MenuItem>
              </SelectValidator>
            </Grid>*/}
            <Grid item xs={12}>
              <AutocompletePlaces
                fullWidth
                variant="outlined"
                name="address"
                label={t("billingAddress")}
                onPlaceChanged={handleBillingAddressChanged}
                initialValue={`${userValues.billingAddress} ${userValues.additionalInfo ? `(${userValues.additionalInfo})` : ''}`}
                error={addressChanged && typeof userValues.billingAddress !== 'object'}
                helperText={addressChanged && typeof userValues.billingAddress !== 'object' && (t("validatorFieldRequired"))}
                placeholder=""
                preview
                required={addressChanged}
                customFieldsEnabled
                requiredFields={['streetNumber', 'streetName', 'zip', 'city', 'country']}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
              >
                <Trans i18nKey={"updatePartner"}>
                                    Update partner
                </Trans>
              </Button>
            </Grid>
          </Grid>
        </FormValidator>
      </CardContent>
    </Card>
  );
};
export default PartnerUpdateCard;
