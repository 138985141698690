import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Route } from "react-router-dom";

import { getLangPath, Redirect } from "../../../helpers/multilingual";
import { SnackbarError } from "../../../helpers/snackbar";
import { history } from "../../../helpers/store";
import { sessionSelector } from "../../Customer/Shop/store/selectors";

import { authenticationActions } from "./actions";
import {PARTNER_CAREER} from "./constants";
import {
  hasMembershipSelector, hasRoleAdmin, isCustomer, isEmployee, isPro, isRegistered, reloadUserRequested, userSelector
} from "./selectors";

export const ReloadUser = () => {

  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const userHasBeenReloaded = useSelector(reloadUserRequested);

  useEffect(() => {
    if(user && !userHasBeenReloaded) {
      dispatch(authenticationActions.reloadUserRequest());
    }
  }, []);
};

export const LockWithoutMembership = ({redirect, exception = false}) => {

  const {t} = useTranslation();
  const dispatch = useDispatch();
  const hasMembership = useSelector(hasMembershipSelector);

  useEffect(() => {
    if(!hasMembership && !exception) {
      SnackbarError(t("needMembership"));
      dispatch(authenticationActions.setMembershipModalOpen(true));
      history.push(`${getLangPath()}${redirect}`);
    }
  }, [exception]);
};

export const ExcludePartner = ({redirect}) => {

  const {t} = useTranslation();
  const {career} = useSelector(userSelector);

  useEffect(() => {
    if(career === PARTNER_CAREER) {
      SnackbarError(t("permissionDenied"));
      history.push(`${getLangPath()}${redirect}`);
    }
  }, []);
};

export const RouteSuperAdmin = ({component: Component, ...rest}) => {

  const hasRoleEmployee = useSelector(isEmployee);
  return <Route
    {...rest}
    render={(props) => hasRoleEmployee
      ? <Component {...props} />
      : <Redirect to={'/'} params={{from: props.location}}/>}
  />;
};

export const RouteStatistics = ({component: Component, ...rest}) => {

  const isAdmin = useSelector(hasRoleAdmin);
  return <Route
    {...rest}
    render={(props) => isAdmin
      ? <Component {...props} />
      : <Redirect to={'/'} params={{from: props.location}}/>}
  />;
};

export const RouteAdmin = ({component: Component, ...rest}) => {

  const hasRoleEmployee = useSelector(isEmployee);
  return <Route
    {...rest}
    render={(props) => hasRoleEmployee
      ? <Component {...props} />
      : <Redirect to={'/'} params={{from: props.location}}/>}
  />;
};

export const RoutePro = ({component: Component, ...rest}) => {

  const hasRolePro = useSelector(isPro);
  return <Route
    {...rest}
    render={(props) => hasRolePro
      ? <Component {...props} />
      : <Redirect to={'/'} params={{from: props.location}}/>}
  />;

};

export const RouteLogistic = ({component: Component, ...rest}) => {

  const hasRoleLogistic = useSelector(isEmployee);
  return <Route
    {...rest}
    render={(props) => hasRoleLogistic
      ? <Component {...props} />
      : <Redirect to={'/'} params={{from: props.location}}/>}
  />;
};

export const RouteEditor = ({component: Component, ...rest}) => {

  const hasRoleEditor = useSelector(isEmployee);
  return <Route
    {...rest}
    render={(props) => hasRoleEditor
      ? <Component {...props} />
      : <Redirect to={'/'} params={{from: props.location}}/>}
  />;
};

export const RouteAccounting = ({component: Component, ...rest}) => {

  const hasRoleAccountant = useSelector(isEmployee);
  return <Route
    {...rest}
    render={(props) => hasRoleAccountant
      ? <Component {...props} />
      : <Redirect to={'/'} params={{from: props.location}}/>}
  />;
};

export const RouteCustomer = ({component: Component, ...rest}) => {

  const hasRoleCustomer = useSelector(isCustomer);
  return <Route
    {...rest}
    render={(props) => hasRoleCustomer
      ? <Component {...props} />
      : <Redirect to={'/'} params={{from: props.location}}/>}
  />;

};

export const RouteRegistered = ({component: Component, ...rest}) => {

  const hasBeenRegistered = useSelector(isRegistered);
  return <Route
    {...rest}
    render={(props) => hasBeenRegistered
      ? <Component {...props} />
      : <Redirect to={'/auth/signin'} params={{from: props.location}}/>}
  />;

};

export const RouteShopSession = ({component: Component, ...rest}) => {

  const session = useSelector(sessionSelector);
  return <Route
    {...rest}
    render={(props) => session
      ? <Component {...props} />
      : <Redirect to={'/shop'} params={{from: props.location}}/>}
  />;

};
