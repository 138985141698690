import React from "react";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import AddShoppingCart from '@mui/icons-material/AddShoppingCart';
import {Box, Stack} from "@mui/material";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import NoSsr from "@mui/material/NoSsr";
import Typography from "@mui/material/Typography";
import moment from "moment";

import LazyLoadCardMedia from "../../../../components/LazyLoad/LazyLoadCardMedia";
import { Link } from "../../../../helpers/multilingual";
import {SnackbarInfo} from "../../../../helpers/snackbar";
import {getProductPriceWithTaxes} from "../../../../helpers/utils";
import {userSelector} from "../../../Authentication/store/selectors";
import { getFormalizedName } from "../helpers/helper";
import { shopActions } from "../store/actions";

import ItemPrice from "./ItemPrice";
const ItemEvent = ({product, session}) => {

  const {t} = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(userSelector);

  const addToCart = (product) => {
    if(user) {
      const priceWithDiscount = getProductPriceWithTaxes(product, user, user?.shippingAddress, 1, true);
      const priceWithoutDiscount = getProductPriceWithTaxes(product, user, user?.shippingAddress, 1, false);
      const discount = (priceWithoutDiscount - priceWithDiscount).toFixed(2);

      if (product.outOfStock) {
        SnackbarInfo('Il n\'y a plus de place disponible pour cet événement');
      } else {

        dispatch(shopActions.addToCart(product, 1));

        ReactGA.event("add_to_cart", {
          user_id: user?.id,
          currency: "EUR",
          value: priceWithDiscount,
          items: [
            {
              item_id: product.id,
              item_name: product.reference,
              discount: discount,
              index: 0,
              price: priceWithDiscount,
              quantity: 1
            }
          ]
        });
      }
    } else {
      dispatch(shopActions.SetDrawersOpen("account", true));
    }
  };

  return (
    <Card sx={{
      boxShadow: '6px 6px 12px #00000021',
      borderRadius: '12px',
    }} key={product?.id}>
      <CardActionArea>
        <Link to={`/event/${getFormalizedName(product.reference)}/${product.id}`}>
          <Box
            sx={{
              position: 'relative',
              height: '250px',
              width: '100%',
              backgroundRepeat: 'no-repeat',
              backgroundSize: '100% !important',
              background: `url(${process.env.REACT_APP_API_RESOURCES_URL}/images/products/${product.id}.png)`,
            }}
          >
            <LazyLoadCardMedia
              component="img"
              alt={product.reference}
              height="auto"
              image={product.layer}
              title={product.reference}
              skeletonSrc={product.layer}
              sx={{
                height: { xs: '250px', sm: '250px' },
              }}
            />
            <Box sx={{
              position: 'absolute',
              width: '80%',
              top: '212px',
              left: 0,
              border: '1px solid #a29c9c',
              borderRadius: '40px',
              padding: '30px 10px',
              background: '#ede6df',
              color: '#333',
              textAlign: 'center',
              margin: '0 6.5%',
              display: 'block',
              fontWeight: 'bold'
            }}>
              {moment(product.eventDate).format('LLLL')} à {moment(product.eventEndTime).format('HH:mm')}
            </Box>
            <LazyLoadCardMedia
              component="img"
              alt={'Intervenants'}
              height="auto"
              image={product.speakers}
              title={'Intervenants'}
              skeletonSrc={product.speakers}
              sx={{
                height: { xs: '160px', sm: '160px' },
              }}
            />
          </Box>
        </Link>
        <CardContent sx ={{
          background: '#ede6df',
          pt: '172px'
        }}>
          <Stack gap={2}>
            <Link to={`/event/${getFormalizedName(product.reference)}/${product.id}`}>
              <Stack sx={{
                height: {xs: '140px', sm: '140px', md: '140px', lg: '140px', xl: '140px', xxl: '140px'},
              }}>
                <Typography>
                  {product.reference}
                </Typography>
                <Typography
                  component="div"
                  variant="body2"
                  color="textSecondary"
                >
                  {product.eventCity}
                </Typography>
                <Typography
                  component="div"
                  dangerouslySetInnerHTML={{__html: product.detail.proInfo}}
                  variant="body2"
                  color="textSecondary"
                  sx={{
                    fontSize: {xs: '1rem', sm: '1rem', md: '1rem', lg: '1rem', xl: '1rem', xxl: '1rem'},
                    overflow: 'hidden',
                    display: '-webkit-box',
                    WebkitLineClamp: '3',
                    WebkitBoxOrient: 'vertical',
                    textOverflow: 'ellipsis',
                    color: '#7C909B',
                    fontFamily: 'Open Sans',
                    mb: '16px',
                    '& p': {
                      margin: '0',
                      color: '#7C909B',
                      fontFamily: 'Open Sans',
                    }
                  }}
                />

              </Stack>
            </Link>
            <Link to={`/event/${getFormalizedName(product.reference)}/${product.id}`}>
              <Stack direction={"row"} gap={2} justifyContent={"flex-end"}>
                <ItemPrice
                  item={product}
                  component={"span"}
                  session={session}
                  withDiscount
                  sx={{
                    fontFamily: 'Montserrat',
                    fontSize: {xs: '1.4rem', sm: '1.4rem', md: '1.4rem', lg: '1.4rem', xl: '1.4rem', xxl: '1.4rem'},
                    fontWeight: 'bold',
                  }}
                />
                <ItemPrice
                  item={product}
                  component={"span"}
                  session={session}
                  total
                  sx={{
                    fontFamily: 'Montserrat',
                    fontSize: {xs: '1.4rem', sm: '1.4rem', md: '1.4rem', lg: '1.4rem', xl: '1.4rem', xxl: '1.4rem'},
                    fontWeight: 'bold',
                  }}
                />
              </Stack>
            </Link>
            {product.allowInCart && (
              <NoSsr>
                <Button
                  title={t("addToCart")}
                  onClick={() => addToCart(product)}
                  endIcon={<AddShoppingCart/>}
                  variant={"contained"}
                  sx={{
                    color: "white",
                    height: '48px',
                    width: "100%",
                    margin: "auto",
                    textTransform: 'uppercase',
                    letterSpacing: '0.64px',
                    fontWeight: 'bold',
                    fontFamily: 'Montserrat',
                    fontSize: '16px',
                    borderRadius: '24px'
                  }}
                >
                  Inscription
                </Button>
              </NoSsr>
            )}
          </Stack>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default ItemEvent;
