import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import LinearProgress from "@mui/material/LinearProgress";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import {makeStyles} from '@mui/styles';
import { DatePicker } from "@mui/x-date-pickers";

import BarChart from "../../../../components/Charts/BarChart";
import useMoment from "../../../../helpers/useMoment";
import { proStatsActions } from "../store/actions";
import {
  networkPacksAmountBarGraphDataSelector,
  networkProductsAmountBarGraphDataSelector,
} from "../store/selectors";

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiSelect-select': {
      fontSize: '1.5rem',
      lineHeight: '1.8rem',
      paddingTop: 10,
      paddingBottom: 10,
      maxWidth: '70vw'
    },
    height: '100%',
    '& .MuiCardHeader-root': {
      '& .MuiCardHeader-content': {
        height: 81
      },
      '& .MuiInput-root': {
        '& .MuiInputBase-input': {
          textAlign: 'right'
        }
      }
    }
  },
  menuItem: {
    fontSize: '1.5rem',
    lineHeight: '1.8rem',
  },
  loader: {
    height: 340
  }
}));
const NetworkProductsCharts = ({user}) => {

  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();
  const moment = useMoment();
  const [view, setView] = useState("networkPacksSold");
  const [isYearPickerOpen, setYearPickerOpen] = useState(false);
  const [isMonthPickerOpen, setMonthPickerOpen] = useState(false);
  const [yearRange, setYearRange] = useState(false);
  const [selectedDate, setSelectedDate] = useState(moment());
  const networkPacksAmountBarGraphData = useSelector(networkPacksAmountBarGraphDataSelector(user.id, selectedDate.format('YYYY'), !yearRange && selectedDate.format('MM')));
  const networkProductsAmountBarGraphData = useSelector(networkProductsAmountBarGraphDataSelector(user.id, selectedDate.format('YYYY'), !yearRange && selectedDate.format('MM')));

  useEffect(() => {
    switch (view) {
      case "networkProductsSold":
        if(!networkProductsAmountBarGraphData) {
          dispatch(proStatsActions.getNetworkProductsAmountRequest(user.id, selectedDate.format('YYYY'), !yearRange && selectedDate.format('MM')));
        }
        break;
      case "networkPacksSold":
      default:
        if(!networkPacksAmountBarGraphData) {
          dispatch(proStatsActions.getNetworkPacksAmountRequest(user.id, selectedDate.format('YYYY'), !yearRange && selectedDate.format('MM')));
        }
    }
  }, [view, selectedDate, yearRange]);

  const handleYearChange = (e) => {
    setSelectedDate(e);
    setYearPickerOpen(false);
  };

  const handleDateChange = (e) => {
    setSelectedDate(e);
    setMonthPickerOpen(false);
  };

  const handleChangeRangeSelector = (e) => {
    setYearRange(e.target.checked);
  };

  const handleChangeView = (e) => {
    setView(e.target.value);
  };

  const getValues = () => {
    switch (view) {
      case "networkProductsSold":
        return networkProductsAmountBarGraphData;
      case "networkPacksSold":
      default:
        return networkPacksAmountBarGraphData;
    }
  };

  return (
    <Card className={classes.root}>
      <CardHeader title={
        <Grid container justify={"space-between"}>
          <Grid item>
            <Select defaultValue={view} variant={"outlined"} onChange={handleChangeView}>
              <MenuItem className={classes.menuItem} value="networkPacksSold">
                {t("networkPacksSold")}
              </MenuItem>
              <MenuItem className={classes.menuItem} value="networkProductsSold">
                {t("networkProductsSold")}
              </MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12}>
            <Grid container justify={"flex-end"} alignItems={"center"}>
              <Grid item>
                <FormControlLabel
                  control={<Checkbox
                    checked={yearRange}
                    onChange={handleChangeRangeSelector}
                    name="range"
                    style={{paddingBottom: 0, paddingTop: 0}}
                  />}
                  label={t('byYear')}
                />
              </Grid>
              <Grid item>
                {yearRange && (
                  <DatePicker
                    variant="inline"
                    maxDate={moment()}
                    open={isYearPickerOpen}
                    onOpen={() => setYearPickerOpen(true)}
                    onYearChange={(e) => handleYearChange(e)}
                    openTo="year"
                    views={["year"]}
                    label=""
                    value={selectedDate}
                    onChange={() => {}}
                  />
                )}
                {!yearRange && (
                  <DatePicker
                    variant="inline"
                    maxDate={moment()}
                    open={isMonthPickerOpen}
                    onOpen={() => setMonthPickerOpen(true)}
                    onMonthChange={(e) => handleDateChange(e)}
                    openTo="month"
                    views={["year", "month"]}
                    label=""
                    value={selectedDate}
                    onChange={() => {}}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }/>
      <Divider/>
      {!getValues() && (
        <Grid className={classes.loader} container alignItems={"center"} justify={"center"}>
          <Grid item xs={6} sm={4}>
            <Typography align={"center"}><Trans i18nKey={"loading"}>Loading</Trans> ...</Typography>
            <LinearProgress color="secondary" />
          </Grid>
        </Grid>
      )}
      {getValues() && (
        <CardContent>
          <BarChart
            title={t(view)}
            data={getValues().data}
            labels={getValues().labels}
            heightLg={350}
            heightMd={300}
            heightSm={300}
            displayLegend={false}
            color={{
              border: 'rgb(89,80,236)',
              background: 'rgba(89,80,236, 0.04)'
            }}
          />
        </CardContent>
      )}
    </Card>
  );
};
export default NetworkProductsCharts;
