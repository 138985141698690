import React from 'react';
import {Grid, Stack, Typography} from "@mui/material";
import Container from "@mui/material/Container";
import useMediaQuery from "@mui/material/useMediaQuery";

const section = {
  "name": "AboutSection1",
  "type": "banner",
  "bannerTitle1": "Ensemble,",
  "bannerTitle2": "Révolutionnons la santé",
  "bannerText1": "Nous croyons en un monde où la santé est révolutionnée grâce à des solutions naturelles et efficaces et où le bien-être sert de moteur à l’Homme pour vivre en harmonie avec les siens et la planète qu’il occupe.",
  "bannerLink": "/scientific-team",
  "bannerBackgroundImage": `${process.env.REACT_APP_API_RESOURCES_URL}/images/Photo_About.png`
};
export default function AboutSection1() {

  const isDownSm = useMediaQuery(theme => theme.breakpoints.down('sm'));

  return (
    <Container maxWidth={'xxl'}
      sx={{
        pl: '0 !important',
        pr: '0 !important'
      }}
    >
      <Grid
        container
        spacing={2}
        justifyContent={"space-between"}
        flexDirection={"row"}
        alignItems={"center"}
        alignContent={"center"}
        sx={{
          width: '100%',
          background: isDownSm ? '#FFFFFF' : `url(${section.bannerBackgroundImage})`,
          backgroundPosition: 'right top',
          backgroundSize: {xs: '500px', sm: '50%'},
          backgroundRepeat: 'no-repeat',
          margin: '0px 0 60px',
          padding: '1px 0',
          height: {xs: 'auto', sm: '48vw', md: '48vw', lg: '48vw', xl: '48vw', xxl: '920px'},
          overflowX: 'clip',

        }}>
        <Grid item xs={12} sm={6}>
          <Stack gap={2} sx={{pt: '3vw'}} alignItems={'flex-start'}>
            <Typography
              sx={{
                fontFamily: 'Raleway',
                fontWeight: '600',
                padding: '8px 8px',
                fontSize: {xs: '2.5rem', sm: '2rem', md: '2.5rem', lg: '3.5rem', xl: '3.5rem', xxl: '4.5rem'},
                lineHeight: '0.7',
                overflow: 'hidden',
                color: 'green',
                textAlign: {xs: 'left', sm: 'left'},
                zIndex: 1,
                '& p': {
                  margin: 0,
                },
                '& span': {
                  fontFamily: 'Raleway',
                  fontWeight: '600',
                  fontSize: {xs: '2.5rem', sm: '2rem', md: '2.5rem', lg: '3.5rem', xl: '3.5rem', xxl: '4.5rem'},
                }
              }}>
              {section.bannerTitle1} <br/> <Typography component={"span"} sx={{color: 'primary.main'}}>{section.bannerTitle2}</Typography>
            </Typography>
            <Typography
              sx={{
                fontSize: {xs: '1rem', sm: '0.9rem', md: '1rem', lg: '1.1rem', xl: '1.2rem', xxl: '1.3rem'},
                padding: '0 8px 8px 8px',
                fontFamily: 'Open Sans',
                maxWidth: {xs: '100%', sm: '100%'},
                lineHeight: '1.4',
                overflow: 'hidden',
                textAlign: 'left',
                color: 'primary.light',
                zIndex: 1,
                '& p': {
                  margin: 0,
                }
              }}>
              {section.bannerText1}
            </Typography>
          </Stack>
        </Grid>

        <Grid item xs={12} sm={6}>

        </Grid>

      </Grid>

    </Container>
  );
}
