import { call, put, select,takeEvery } from 'redux-saga/effects';

import { commissionsActions } from "./actions";
import * as actionsType from './constants';
import { commissionsService } from "./services";

export const commissionsSagaKey = 'commissions';

const commissionsSelector = (state) => state.commissions;

function* getSales(action) {

  try {
    let {sales} = yield select(commissionsSelector);
    const salesKey = `${action.year}-${action.month}`;
    if (!sales[salesKey]) {
      sales = yield call(commissionsService.getSales, action.year, action.month, sales[salesKey]?.data?.length);
      yield put(commissionsActions.getSalesSuccess(sales, action.year, action.month));
    }
  }
  catch (e) {
    yield put(commissionsActions.getSalesError());
  }
}

export default function* watchCommissions() {

  yield takeEvery(actionsType.GET_SALES_REQUEST, getSales);
}
