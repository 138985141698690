import axios  from '../../../../helpers/axios';

export const commissionsService = {
  getSales,
  getSaleDetails
};

function getSales(year, month, offset = 0) {

  return new Promise((resolve, reject) => {

    axios.get(`/pro/commissions/sales/${year}/${month}${offset ? `/offset/${offset}` : ''}`)
      .then(({data}) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getSaleDetails(commissionId) {

  return new Promise((resolve, reject) => {

    axios.get(`/pro/commissions/sale/${commissionId}`)
      .then(({commission}) => {
        resolve(commission);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}
