import React from "react";
import DashboardCustomizeTwoToneIcon from '@mui/icons-material/DashboardCustomizeTwoTone';
import FilterTwoToneIcon from '@mui/icons-material/FilterTwoTone';
import MenuBookTwoToneIcon from "@mui/icons-material/MenuBookTwoTone";
import SpeedTwoToneIcon from "@mui/icons-material/SpeedTwoTone";
import VideoStableTwoToneIcon from '@mui/icons-material/VideoStableTwoTone';
import WbIridescentTwoToneIcon from '@mui/icons-material/WbIridescentTwoTone';
import {Box} from "@mui/material";
import Divider from "@mui/material/Divider";
import Toolbar from "@mui/material/Toolbar";

import theme from "../../../assets/styles/theme/theme";
import {Link} from "../../../helpers/multilingual";
import Menu from "../Menu/Menu";
import MenuItem from "../Menu/MenuItem";
import MenuSubItem from "../Menu/MenuSubItem";

const MenuDrawer = () => (
  <div>
    <Toolbar>
      <Link to="/admin">
        <Box component={'img'} width={50} src={`${process.env.REACT_APP_API_RESOURCES_URL}/images/logo/logo.svg`} alt="logo" sx={{width: '100%'}}/>
      </Link>
    </Toolbar>
    <Divider />
    <Menu sx={{
      '& .MuiListItemText-primary': {
        fontFamily: 'Open sans',
        color: 'black'
      },
      '& .MuiListItemIcon-root': {
        color: theme.palette.primary.main
      }
    }}>
      <MenuItem
        title={"Dashboard"}
        to={"/manager"}
        icon={<SpeedTwoToneIcon />}
      />
      <MenuItem
        title={"Accueil"}
        expandTo={'/manager/home/'}
        icon={<DashboardCustomizeTwoToneIcon />}
      >
        <MenuSubItem
          title={"Bandeau"}
          to={'/manager/home/topbars'}
          icon={<WbIridescentTwoToneIcon />}
        />
        <MenuSubItem
          title={"Header"}
          to={"/manager/home/sliders"}
          icon={<FilterTwoToneIcon />}
        />
        <MenuSubItem
          title={"Sections"}
          to={"/manager/home/sections"}
          icon={<VideoStableTwoToneIcon />}
        />
      </MenuItem>
      <MenuItem
        title={"MAG"}
        expandTo={"/manager/mag"}
        to={"/manager/mag/articles"}
        icon={<MenuBookTwoToneIcon />}
      />
    </Menu>
  </div>
);

export default MenuDrawer;
