import { call, put, select,takeEvery } from 'redux-saga/effects';

import { SnackbarFormattedError } from "../../../../helpers/snackbar";

import { consultantsActions } from "./actions";
import * as actionsType from './constants';
import { consultantsService } from "./services";

function* searchOfficeFromPlace(action) {

  try {
    let {offices, zoom} = yield call(consultantsService.findOfficesByPlace, action.place);
    localStorage.setItem('map_search', JSON.stringify(action.place));
    yield put(consultantsActions.searchFromPlaceSuccess(offices, zoom));
  }
  catch (e) {
    SnackbarFormattedError(e.error);
    yield put(consultantsActions.searchFromPlaceFailed());
  }
}

function* searchOfficeFromPlaceAndZoom(action) {

  try {
    let {offices, zoom} = yield call(consultantsService.findOfficesByPlaceAndZoom, action.place, action.zoom);
    yield put(consultantsActions.searchFromPlaceAndZoomSuccess(offices, zoom));
  }
  catch (e) {
    SnackbarFormattedError(e.error);
    yield put(consultantsActions.searchFromPlaceAndZoomFailed());
  }
}

function* getOffice(action) {

  try {
    let office = yield call(consultantsService.getOfficeById, action.id);
    yield put(consultantsActions.getOfficeSuccess(office));
  }
  catch (e) {
    SnackbarFormattedError(e.error);
    yield put(consultantsActions.getOfficeFailed());
  }
}

export default function* watchConsultants() {

  yield takeEvery(actionsType.SEARCH_OFFICES_FROM_PLACE_REQUEST, searchOfficeFromPlace);
  yield takeEvery(actionsType.SEARCH_OFFICES_FROM_PLACE_AND_ZOOM_REQUEST, searchOfficeFromPlaceAndZoom);
  yield takeEvery(actionsType.GET_OFFICE_REQUEST, getOffice);
}
