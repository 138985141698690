import React from "react";
import { Trans } from "react-i18next";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";

const CartDetails = ({className, subTotal, shippingPrice, taxes, total, currency, allowFreeShipping, isFreeShipping, discount, proFee, walletAmount, giftCardAmount, cartView}) => (
  <List className={className}>
    {((allowFreeShipping && isFreeShipping) || shippingPrice > 0) && (
      <ListItem>
        <Grid container justifyContent={"center"} spacing={4}>
          <Grid item xs={12}>
            <Grid container justifyContent={"center"} alignItems={"center"} spacing={4}>
              <Grid item xs={7}>
                <Grid container justifyContent={"flex-end"} alignItems={"center"}>
                  <Typography variant="subtitle1"><Trans i18nKey={"shippingPrice"}>Shipping price</Trans></Typography>
                </Grid>
              </Grid>
              <Grid item xs={5}>
                {(allowFreeShipping && isFreeShipping) && (
                  <Typography align="right" variant="subtitle1"><Trans i18nKey="free">Free</Trans></Typography>
                )}
                {(!allowFreeShipping || !isFreeShipping) && shippingPrice > 0 && (
                  <Typography align="right" variant="subtitle1">{shippingPrice.toFixed(2)} {currency}</Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Divider/>
        </Grid>
      </ListItem>
    )}
    {!!proFee && proFee > 0 && (
      <ListItem>
        <Grid container justifyContent={"center"} spacing={4}>
          <Grid item xs={12}>
            <Grid container justifyContent={"center"} alignItems={"center"} spacing={4}>
              <Grid item xs={7}>
                <Grid container justifyContent={"flex-end"} alignItems={"center"}>
                  <Typography variant="subtitle1"><Trans i18nKey={"proFee"}>Pro fee</Trans></Typography>
                </Grid>
              </Grid>
              <Grid item xs={5}>
                <Typography align="right" variant="subtitle1">{proFee.toFixed(2)} {currency}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ListItem>
    )}
    {discount > 0 && (
      <ListItem>
        <Grid container justifyContent={"center"} spacing={4}>
          <Grid item xs={12}>
            <Grid container justifyContent={"center"} alignItems={"center"} spacing={4}>
              <Grid item xs={7}>
                <Grid container justifyContent={"flex-end"} alignItems={"center"}>
                  <Typography className={"highlight"} variant="subtitle1"><b><Trans i18nKey={"discount"}>Discount</Trans></b></Typography>
                </Grid>
              </Grid>
              <Grid item xs={5}>
                <Typography className={"highlight"} align="right" variant="subtitle1"><b>-{discount.toFixed(2)} {currency}</b></Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ListItem>
    )}
    <ListItem>
      <Grid container justifyContent={"center"} spacing={4}>
        <Grid item xs={12}>
          <Grid container justifyContent={"center"} alignItems={"center"} spacing={4}>
            <Grid item xs={7}>
              <Grid container justifyContent={"flex-end"} alignItems={"center"}>
                <Typography variant="subtitle1"><Trans i18nKey={"subTotal"}>Sub total</Trans></Typography>
              </Grid>
            </Grid>
            <Grid item xs={5}>
              <Typography align="right" variant="subtitle1">{subTotal.toFixed(2)} {currency}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ListItem>
    {Object.keys(taxes).map(taxesRate => taxesRate > 0 && (
      <ListItem key={taxesRate}>
        <Grid container justifyContent={"center"} spacing={4}>
          <Grid item xs={12}>
            <Grid container justifyContent={"center"} alignItems={"center"} spacing={4}>
              <Grid item xs={7}>
                <Grid container justifyContent={"flex-end"} alignItems={"center"}>
                  <Typography variant="subtitle1"><Trans i18nKey={"taxes"}>Taxes</Trans> {taxesRate}%</Typography>
                </Grid>
              </Grid>
              <Grid item xs={5}>
                <Typography align="right" variant="subtitle1">{taxes[taxesRate].toFixed(2)} {currency}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ListItem>
    ))}
    <ListItem>
      <Grid container justifyContent={"center"} spacing={4}>
        <Grid item xs={12}>
          <Grid container justifyContent={"center"} alignItems={"center"} spacing={4}>
            <Grid item xs={7}>
              <Grid container justifyContent={"flex-end"} alignItems={"center"}>
                <Typography variant="subtitle1"><b><Trans i18nKey={"totalPrice"}>Total price</Trans></b></Typography>
              </Grid>
            </Grid>
            <Grid item xs={5}>
              <Typography align="right" variant="subtitle1"><b>{total.toFixed(2)} {currency}</b></Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ListItem>
    {cartView && (!allowFreeShipping || !isFreeShipping) && (
      <ListItem>
        <Grid container justifyContent={"center"} spacing={4}>
          <Grid item xs={12}>
            <Typography variant="subtitle1" align={"right"}><Trans i18nKey={"shippingCalculationOnCheckout"}>The shipping price will be calculated on checkout</Trans></Typography>
          </Grid>
        </Grid>
      </ListItem>
    )}
    {walletAmount > 0 && (
      <ListItem>
        <Grid container justifyContent={"center"} spacing={4}>
          <Grid item xs={12}>
            <Grid container justifyContent={"center"} alignItems={"center"} spacing={4}>
              <Grid item xs={7}>
                <Grid container justifyContent={"flex-end"} alignItems={"center"}>
                  <Typography className={"highlight"} variant="subtitle1"><Trans i18nKey={"agsCredit"}>AGS credit</Trans></Typography>
                </Grid>
              </Grid>
              <Grid item xs={5}>
                <Typography className={"highlight"} align="right" variant="subtitle1">- {walletAmount.toFixed(2)} {currency}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ListItem>
    )}
    {giftCardAmount > 0 && (
      <ListItem>
        <Grid container justifyContent={"center"} spacing={4}>
          <Grid item xs={12}>
            <Grid container justifyContent={"center"} alignItems={"center"} spacing={4}>
              <Grid item xs={7}>
                <Grid container justifyContent={"flex-end"} alignItems={"center"}>
                  <Typography className={"highlight"} variant="subtitle1"><Trans i18nKey={"NLGiftCard"}>Nutri-logics gift card</Trans></Typography>
                </Grid>
              </Grid>
              <Grid item xs={5}>
                <Typography className={"highlight"} align="right" variant="subtitle1">- {giftCardAmount.toFixed(2)} {currency}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ListItem>
    )}
    {(walletAmount > 0 || giftCardAmount > 0) && (
      <ListItem>
        <Grid container justifyContent={"center"} spacing={4}>
          <Grid item xs={12}>
            <Grid container justifyContent={"center"} alignItems={"center"} spacing={4}>
              <Grid item xs={7}>
                <Grid container justifyContent={"flex-end"} alignItems={"center"}>
                  <Typography variant="subtitle1"><Trans i18nKey={"leftToPay"}>Left to pay</Trans></Typography>
                </Grid>
              </Grid>
              <Grid item xs={5}>
                <Typography align="right" variant="subtitle1">{(total - walletAmount - giftCardAmount).toFixed(2)} {currency}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ListItem>
    )}
  </List>
);
export default CartDetails;
