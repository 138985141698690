import React, {useEffect} from "react";
import {Trans, useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import {ListItem} from "@mui/material";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import moment from "moment/moment";

import theme from "../../../../../assets/styles/theme/theme";
import SelectValidator from "../../../../../components/Validators/SelectValidator";
import {SnackbarError, SnackbarFormattedError} from "../../../../../helpers/snackbar";
import {userSelector} from "../../../../Authentication/store/selectors";
import {shopActions} from "../../store/actions";
import {checkoutSelector, giftCardSelector, giftCardsSelector} from "../../store/selectors";
import {shopService} from "../../store/services";

const GiftCardSelector = ({className}) => {

  const {t} = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const userGiftCards = useSelector(giftCardsSelector);
  const userGiftCard = useSelector(giftCardSelector);
  const checkout = useSelector(checkoutSelector);

  useEffect(() => {
    dispatch(shopActions.getGiftCardsRequest());
  }, [user]);

  function handleValidateAndSubmitGiftCardByCode (code) {

    if(userGiftCard.find(giftCard => giftCard.code === code.split("-")[1])){
      return SnackbarError(t('errorMessageGiftCardAlreadyChosen'));
    }
    shopService.setCartLog("Le client choisis une carte cadeau ou un bon d'achat",{from:"promotionCodeSelector"}).then().catch();
    shopService.getGiftCardByCode(code).then(giftCard => {

      if (!checkout) {
        SnackbarError(t('errorMessageGiftCardAmount'));
        return;
      }
      dispatch(shopActions.selectGiftCard(giftCard));
    })
      .catch(e => {
        SnackbarFormattedError(e.error);
      });
  }

  const handleDeleteGiftCard = (code) => {
    dispatch(shopActions.deleteGiftCard(code));
    shopService.setCartLog("Le client supprime une carte cadeau ou un bon d'achat du panier",{from:"promotionCodeSelector"}).then().catch();
  };

  const handleSelectGiftCardByCodeSelector = (e) => {
    handleValidateAndSubmitGiftCardByCode(e.target.value);
  };

  if (userGiftCards.length === 0) {
    return null;
  }

  return (
    <Grid
      item
      xs={12}
      sx={{
        background: '#fff',
        p: '32px 16px',
        '& .MuiInputBase-root': {
          fontFamily: 'Open sans',
          fontSize: '0.9rem',
          color: '#7C909B',
          height: '48px',
          borderRadius: '30px'
        },
        '& .MuiFormLabel-root': {
          fontFamily: 'Open sans',
          fontSize: '0.9rem',
          lineHeight: '1.1rem',
          color: '#7C909B'
        },
        '& .MuiFormHelperText-root': {
          position: 'unset !important',
        }
      }}>
      <Grid container justify={"flex-start"} className={className} spacing={1}>
        <Grid item xs={12} sx={{
          color: theme.palette.primary.main,
          fontFamily: "Open sans",
          fontSize: "1.3rem",
          p: 1
        }}>
          <Typography component="h4" variant={"h6"}>
            <Trans i18nKey="NLGiftCardsAndCoupons">Nutri-Logics gift card</Trans> :
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {userGiftCards.length > 0 && (
            <Grid container spacing={1} alignItems={"center"}>
              <Grid item xs={12}>
                <SelectValidator
                  label={t('selectTheGiftCard')}
                  fullWidth
                  value={userGiftCard}
                  onChange={handleSelectGiftCardByCodeSelector}
                >
                  {userGiftCards.map(giftCard => (
                    <MenuItem
                      value={`${giftCard.id}-${giftCard.code}`}
                      key={giftCard.id}
                    >
                      {moment(giftCard.createdAt).format("YYYY-MM-DD")} | {giftCard.name} | {giftCard.amount}
                    </MenuItem>
                  ))}
                </SelectValidator>
              </Grid>
              <Grid item xs={12}>
                <List>
                  {userGiftCard?.length > 0 && userGiftCard.map((giftCard, i) => (

                    <ListItem key={giftCard.id}>
                      <Typography>
                        {moment(giftCard.createdAt).format("YYYY-MM-DD")} | {giftCard.name} | {giftCard.amount}
                      </Typography>
                      <IconButton onClick={() => handleDeleteGiftCard(`${giftCard.id}-${giftCard.code}`, i)}>
                        <DeleteIcon/>
                      </IconButton>
                    </ListItem>
                  ))}
                </List>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
export default GiftCardSelector;

//if(!checkout || checkout.order.totalPrice + checkout.order.taxes < giftCard.amount) {
//    setValidGiftCardCode(false);
//    SnackbarError(t('errorMessageGiftCardAmount'))
//    return;
//}

//useEffect(() => {
//    if (userGiftCard) {
//        setValidGiftCardCode(true);
//        SnackbarSuccess(t('giftCardAddedDescription'));
//    }
//}, [userGiftCard]);
