import produce from "immer";

import * as actions from './constants';
export const initialState = {
  profilingTests : null,
  profilingTest : null,
  consultants : null,
  consultant : null,
  evolutions : {},
  partners : null,
};

export const networkReducerKey = 'network';

export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case actions.GET_PROFILING_TESTS_REQUEST:
        draft.profilingTests = initialState.profilingTests;
        break;
      case actions.GET_PROFILING_TESTS_SUCCESS:
        draft.profilingTests = action.requests;
        break;
      case actions.GET_PROFILING_TESTS_ERROR:
        draft.error = action.error;
        break;
      case actions.GET_PROFILING_TEST_REQUEST:
        draft.profilingTest = initialState.profilingTest;
        break;
      case actions.GET_PROFILING_TEST_SUCCESS:
        draft.profilingTest = action.request;
        break;
      case actions.GET_PROFILING_TEST_ERROR:
        draft.error = action.error;
        break;
      case actions.ACCEPT_PROFILING_TEST_REQUEST:
        break;
      case actions.ACCEPT_PROFILING_TEST_SUCCESS:
        draft.profilingTest.approved = true;
        break;
      case actions.ACCEPT_PROFILING_TEST_ERROR:
        draft.error = action.error;
        break;
      case actions.DECLINE_PROFILING_TEST_REQUEST:
        break;
      case actions.DECLINE_PROFILING_TEST_SUCCESS:
        draft.profilingTest.approved = false;
        break;
      case actions.DECLINE_PROFILING_TEST_ERROR:
        draft.error = action.error;
        break;
      case actions.GET_EVOLUTIONS_REQUEST:
        draft.evolutions[action.role] = null;
        break;
      case actions.GET_EVOLUTIONS_SUCCESS:
        draft.evolutions[action.role] = action.pros;
        break;
      case actions.GET_EVOLUTIONS_ERROR:
        draft.error = action.error;
        break;
      case actions.GET_CONSULTANTS_REQUEST:
        draft.consultants = initialState.consultants;
        break;
      case actions.GET_CONSULTANTS_SUCCESS:
        draft.consultants = action.consultants;
        break;
      case actions.GET_CONSULTANTS_ERROR:
        draft.error = action.error;
        break;
      case actions.GET_CONSULTANT_REQUEST:
        draft.consultant = initialState.consultant;
        break;
      case actions.GET_CONSULTANT_SUCCESS:
        draft.consultant = action.consultant;
        break;
      case actions.GET_CONSULTANT_ERROR:
        draft.error = action.error;
        break;
      case actions.GET_PARTNERS_REQUEST:
        draft.partners = initialState.partners;
        break;
      case actions.GET_PARTNERS_SUCCESS:
        draft.partners = action.partners;
        break;
      case actions.GET_PARTNERS_ERROR:
        draft.error = action.error;
        break;
      case actions.ADD_PARTNER_REQUEST:
        draft.consultant = initialState.consultant;
        break;
      case actions.ADD_PARTNER_SUCCESS:
        draft.consultant = action.partner;
        break;
      case actions.ADD_PARTNER_ERROR:
        draft.error = action.error;
        draft.consultant = initialState.consultant;
        break;
      case actions.UPDATE_PARTNER_REQUEST:
        draft.loading = true;
        break;
      case actions.UPDATE_PARTNER_SUCCESS:
        draft.loading = false;
        draft.consultant = action.partner;
        break;
      case actions.UPDATE_PARTNER_ERROR:
        draft.loading = false;
        draft.error = action.error;
        break;
      case actions.UPDATE_PARTNER_SHIPPING_ADDRESS_REQUEST:
        draft.loading = true;
        break;
      case actions.UPDATE_PARTNER_SHIPPING_ADDRESS_SUCCESS:
        draft.loading = false;
        draft.consultant.shippingAddress = action.shippingAddress;
        break;
      case actions.UPDATE_PARTNER_SHIPPING_ADDRESS_ERROR:
        draft.loading = false;
        draft.error = action.error;
        break;
      default:
    }
  }
  );
