import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { DropzoneDialog } from "react-mui-dropzone";
import {useDispatch, useSelector} from "react-redux";
import PhoneIcon from '@mui/icons-material/Phone';
import StorefrontIcon from '@mui/icons-material/Storefront';
import TelegramIcon from '@mui/icons-material/Telegram';
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import {makeStyles} from '@mui/styles';

import PageLoader from "../../../components/PageLoader";
import { Link } from "../../../helpers/multilingual";
import { SnackbarFormattedError } from "../../../helpers/snackbar";

import PartnerOfficeVisibility from "./components/PartnerOfficeVisibility";
import {networkActions} from "./store/actions";
import {consultantSelector} from "./store/selectors";
import { networkService } from "./store/services";

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(2)
  },
  root: {
    padding: 0,
    position: 'relative',
  },
  marginTop20: {
    marginTop: 20
  },
  cover: {
    height: 370,
    [theme.breakpoints.down('xs')]: {
      height: 260,
    }
  },
  coverImage: {
    width: '100%',
    height: 300,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    margin: 'auto',
    [theme.breakpoints.down('xs')]: {
      height: 200,
    }
  },
  profileImage: {
    position: 'absolute',
    top: 180,
    left: 40,
    height: 180,
    width: 180,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    border: '4px solid white',
    borderRadius: '50%',
    '& img': {
      width: '100%'
    },
    [theme.breakpoints.down('xs')]: {
      left: 'calc(50% - 75px)',
      top: 100,
      height: 150,
      width: 150,
    }
  },
  content: {
    position: 'relative',
    padding: '0 30px 30px',
    '& h2': {
      fontSize: '1.6rem',
      fontWeight: 500,
      [theme.breakpoints.down('xs')]: {
        textAlign: 'center'
      }
    },
    '& h4': {
      fontSize: '1.2rem',
      marginBottom: 10,
      fontWeight: 500
    },
    '& a.website': {
      display: 'block',
      fontSize: '1.2rem',
      [theme.breakpoints.down('xs')]: {
        textAlign: 'center'
      }
    },
    '& p': {
      fontSize: '1.2rem',
      [theme.breakpoints.down('xs')]: {
        textAlign: 'center'
      }
    },
    '& i': {
      [theme.breakpoints.down('xs')]: {
        display: 'block',
        textAlign: 'center'
      }
    },
    '& .MuiListItemText-primary': {
      fontSize: '1.2rem',
    },
    '& ul': {
      padding: 0,
      '& li': {
        paddingTop: 0,
        paddingBottom: 0,
        [theme.breakpoints.down('xs')]: {
          padding: 0,
          textAlign: 'center'
        }
      }
    },
  },
  buttons: {
    margin: theme.spacing(3, 0, 2),
    '& button': {
      fontSize: 16,
      height: 46,
      padding: '8px 20px',
    },
    '& a': {
      fontSize: 16,
      height: 46,
      padding: '8px 20px',
    },
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    }
  },
  consultantButton: {
    '& .MuiButton-label': {
      lineHeight: 'normal'
    },
  },
  updateCoverPictureButton: {
    position: 'absolute',
    top: 10,
    right: 10,
    zIndex: 10,
  },
  updateProfilePictureButton: {
    position: 'absolute',
    left: 230,
    top: 250,
    zIndex: 10,
    [theme.breakpoints.down('xs')]: {
      top: 50,
      left: 'auto',
      right: 10
    }
  },
  updateInfoButton: {
    position: 'absolute',
    left: 230,
    top: -40,
    zIndex: 10,
    [theme.breakpoints.down('xs')]: {
      top: -30,
      left: 10,
    }
  }
}));

const Office = (props) => {

  const classes = useStyles();
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(consultantSelector());
  const [office, setOffice] = useState(null);
  const [dialogOpenCoverDropzone, setDialogOpenCoverDropzone] = useState(false);
  const [dialogOpenProfileDropzone, setDialogOpenProfileDropzone] = useState(false);
  const [coverImage, setCoverImage] = useState('');
  const [coverLoading, setCoverLoading] = useState(false);
  const [profileImage, setProfileImage] = useState('');
  const [profileLoading, setProfileLoading] = useState(false);


  useEffect(() => {
    if(user) {
      networkService.getOffice(user.id)
        .then(office => {
          setCoverImage(office.coverPicture);
          setProfileImage(office.profilePicture);
          setOffice(office);
        })
        .catch(e => {
          SnackbarFormattedError(e.error);
        });
    }
  }, [user]);

  const handleChangeVisibility = () => {
    dispatch(networkActions.getConsultantRequest(props.match.params.consultantId));
  };

  const handleCloseCoverDropzone = () => {
    setDialogOpenCoverDropzone(false);
  };

  const handleOpenCoverDropzone = () => {
    setDialogOpenCoverDropzone(true);
  };

  const handleCoverImageSave = (images) => {

    setCoverLoading(true);
    const reader = new FileReader();
    reader.onload = (event) => {

      networkService.uploadCoverImage(user.id, event.target.result)
        .then((image) => {
          setCoverImage(`${image}?t=${Date.now()}`);
          setCoverLoading(false);
        })
        .catch(() => {
          setCoverLoading(false);
        });
    };
    reader.readAsDataURL(images[0]);
    setDialogOpenCoverDropzone(false);
  };

  const handleCloseProfileDropzone = () => {
    setDialogOpenProfileDropzone(false);
  };

  const handleOpenProfileDropzone = () => {
    setDialogOpenProfileDropzone(true);
  };

  const handleProfileImageSave = (images) => {

    setProfileLoading(true);
    const reader = new FileReader();
    reader.onload = (event) => {

      networkService.uploadProfileImage(user.id, event.target.result)
        .then((image) => {
          setProfileImage(`${image}?t=${Date.now()}`);
          setProfileLoading(false);
        })
        .catch(() => {
          setProfileLoading(false);
        });
    };
    reader.readAsDataURL(images[0]);
    setDialogOpenProfileDropzone(false);
  };

  if(!office) {
    return (
      <PageLoader/>
    );
  }
  return (
    <>
      <PartnerOfficeVisibility user={user} office={office} onChange={handleChangeVisibility}/>
      <Card className={classes.root}>
        <div className={classes.cover}>
          <div className={classes.updateCoverPictureButton}>
            {coverLoading && (
              <Typography align={"center"}>
                <CircularProgress color="inherit" size={20} />
              </Typography>
            )}
            {!coverLoading && (
              <Button onClick={handleOpenCoverDropzone} variant={"contained"} color={"primary"}>
                <Trans i18nKey={"updateCoverPictureButton"}>Update cover picture</Trans>
              </Button>
            )}
          </div>
          <div className={classes.updateProfilePictureButton}>
            {profileLoading && (
              <Typography align={"center"}>
                <CircularProgress color="inherit" size={20} />
              </Typography>
            )}
            {!profileLoading && (
              <Button onClick={handleOpenProfileDropzone} variant={"contained"} color={"primary"}>
                <Trans i18nKey={"updateProfilePictureButton"}>Update profile picture</Trans>
              </Button>
            )}
          </div>
          <div className={classes.coverImage} style={{backgroundImage:`url(${coverImage})`}}/>
          <img alt="profile picture" className={classes.profileImage} src={profileImage}/>
        </div>
        <div className={classes.content}>
          <Button
            variant={"contained"}
            color={"primary"}
            className={classes.updateInfoButton}
            component={Link}
            to={`/pro/network/${user.id}/office/update`}
          >
            <Trans i18nKey={"updateInfoButton"}>Update info</Trans>
          </Button>
          <Grid container spacing={2} justify={"space-between"}>
            <Grid item xs={12}>
              <Typography gutterBottom component={"h2"}>{office.name}</Typography>
              <Typography gutterBottom>{office.specialities}</Typography>
              <Typography gutterBottom>{office.address}</Typography>
              {!!office.website && (
                <Typography
                  className={"website"}
                  component={"a"}
                  target={"_blank"}
                  href={office.website.startsWith('http') ? office.website : `//${office.website}`}
                  gutterBottom
                >
                  {office.website}
                </Typography>
              )}
              <Typography component={"i"} gutterBottom>
                {office.description}
              </Typography>
            </Grid>
          </Grid>
          <Grid className={classes.buttons} container spacing={2}>
            {!!office.phone && (
              <Grid item>
                <Button component={"a"} href={`tel:${office.phone}`} variant={"outlined"} color={"secondary"} startIcon={<PhoneIcon />}>
                  {office.phone}
                </Button>
              </Grid>
            )}
            {!!office.email && (
              <Grid item>
                <Button component={"a"} href={`mailto:${office.email}`} variant={"outlined"} color={"secondary"} startIcon={<TelegramIcon />}>
                  {office.email}
                </Button>
              </Grid>
            )}
            <Grid item>
              <Button className={classes.consultantButton} variant={"contained"} color={"secondary"} startIcon={<StorefrontIcon />}>
                <Trans i18nKey={"visitTheShop"}>Visit the shop</Trans>
              </Button>
            </Grid>
          </Grid>
          <Grid className={classes.marginTop20} container spacing={2} justify={"space-between"}>
            <Grid item xs={12} md={6}>
              <Typography component={"h4"} variant={"h4"}>
                <Trans i18nKey={"services"}>Services</Trans> :
              </Typography>
              {!!office.services && (
                <List dense>
                  {office.services.map(service => (
                    <ListItem key={service.id}>
                      <ListItemText primary={`${service.name} ${service.price ? `- ${service.price} ${office.currency}` : ''}`} />
                    </ListItem>
                  ))}
                </List>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography component={"h4"} variant={"h4"}>
                <Trans i18nKey={"openingTime"}>Opening time</Trans> :
              </Typography>
              {!!office.openingTime && (
                <Typography>
                  {office.openingTime}
                </Typography>
              )}
            </Grid>
          </Grid>
        </div>
      </Card>
      <DropzoneDialog
        filesLimit={1}
        dialogTitle={t('coverPicture')}
        dropzoneText={t('dropImageInfo')}
        cancelButtonText={t('cancel')}
        submitButtonText={t('validate')}
        previewText="Aperçu"
        getFileRemovedMessage={fileName => t('fileRemoved', {fileName})}
        getFileAddedMessage={fileName => t('fileAdded', {fileName})}
        getDropRejectMessage={fileName => t('fileCannotBeenImported', {fileName})}
        open={dialogOpenCoverDropzone}
        onSave={handleCoverImageSave.bind(this)}
        acceptedFiles={['image/jpeg', 'image/png']}
        showPreviews={true}
        maxFileSize={5000000}
        onClose={handleCloseCoverDropzone.bind(this)}
      />
      <DropzoneDialog
        filesLimit={1}
        dialogTitle={t('profilePicture')}
        dropzoneText={t('dropImageInfo')}
        cancelButtonText={t('cancel')}
        submitButtonText={t('validate')}
        previewText="Aperçu"
        getFileRemovedMessage={fileName => t('fileRemoved', {fileName})}
        getFileAddedMessage={fileName => t('fileAdded', {fileName})}
        getDropRejectMessage={fileName => t('fileCannotBeenImported', {fileName})}
        open={dialogOpenProfileDropzone}
        onSave={handleProfileImageSave.bind(this)}
        acceptedFiles={['image/jpeg', 'image/png']}
        showPreviews={true}
        maxFileSize={5000000}
        onClose={handleCloseProfileDropzone.bind(this)}
      />
    </>
  );
};
export default Office;
