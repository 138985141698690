import React, {useState} from "react";
import {useSelector} from "react-redux";
import AddIcon from '@mui/icons-material/Add';
import CreateIcon from '@mui/icons-material/Create';
import DeleteForeverTwoToneIcon from '@mui/icons-material/DeleteForeverTwoTone';
import DoneIcon from '@mui/icons-material/Done';
import {IconButton} from "@mui/material";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import moment from "moment";

import {SnackbarError} from "../../../../helpers/snackbar";
import {userSelector} from "../../../Authentication/store/selectors";
import {userService} from "../store/services";

const UserNotes = ({user}) => {

  const admin = useSelector(userSelector);
  const [selectedNote, setSelectedNote] = useState({id: null, content: ""});
  const [isCreatingNewNote, setIsCreatingNewNote] = useState(false);
  const [newNote, setNewNote] = useState({id: null, content: ""});

  const handleUpdateNote = async () => {
    await userService.updateUserNote(selectedNote.id, selectedNote);
    user.notes.find(note => note.id === selectedNote.id).content = selectedNote.content;
    setSelectedNote({id: null, content: ""});
  };

  const handleRemoveNote = async (noteId) => {
    try {
      await userService.removeNoteFromUser(noteId);
      window.location.reload();
    } catch (e) {
      SnackbarError(e);
      console.log(e);
    }
  };

  const handleValidateNewNote = async () => {
    let note = await userService.addNoteToUser(user.id, newNote);
    user.notes.push({...note, author: {firstName: admin.firstName, lastName: admin.lastName}});
    setIsCreatingNewNote(false);
  };

  return (
    <Card >
      <CardContent>
        <Grid container justify="center" spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography component="h3" variant={"h6"} gutterBottom>
                  Notes :
                </Typography>
              </Grid>
              {user?.notes?.length > 0 && user.notes.map(note => (
                <React.Fragment key={`${note.id}-main`}>
                  <Grid
                    item
                    xs={8}
                    key={note?.id}
                  >
                    {selectedNote && selectedNote?.id === note?.id ?
                      <TextField
                        value={selectedNote.content}
                        multiline
                        fullWidth
                        onChange={(e) => setSelectedNote({
                          ...selectedNote,
                          content: e.target.value
                        })}
                      >
                      </TextField>
                      : <Typography component="p" variant={"body2"} gutterBottom>
                        {note?.content.split('\n').map((line, index) => (
                          <React.Fragment key={`${note.id}-${index}`}>
                            {line}
                            <br/>
                          </React.Fragment>
                        ))}
                      </Typography>
                    }
                  </Grid>
                  {admin?.id === note?.proId && selectedNote?.id !== note?.id && (
                    <Grid item xs={2} key={`${note.id}-edit`}>
                      <IconButton
                        variant="contained"
                        color="primary"
                        onClick={() => setSelectedNote({id: note?.id, content: note?.content})}
                      >
                        <CreateIcon/>
                      </IconButton>
                    </Grid>
                  )}
                  {admin?.id === note?.proId && selectedNote?.id === note.id && (
                    <Grid item xs={2} key={`${note.id}-done`}>
                      <IconButton
                        variant="contained"
                        color="primary"
                        onClick={handleUpdateNote}
                      >
                        <DoneIcon/>
                      </IconButton>
                    </Grid>
                  )}
                  <Grid item xs={2} key={`${note.id}-remove`}>
                    <IconButton
                      variant="contained"
                      color="primary"
                      onClick={() => handleRemoveNote(note?.id)}
                    >
                      <DeleteForeverTwoToneIcon/>
                    </IconButton>
                  </Grid>
                  {/*admin?.id === note?.proId && (
                    <Grid item xs={2} key={`${note.id}-remove`}>
                      <IconButton
                        variant="contained"
                        color="primary"
                        onClick={() => handleRemoveNote(note?.id)}
                      >
                        <DeleteForeverTwoToneIcon/>
                      </IconButton>
                    </Grid>
                  )*/}
                  <Grid item xs={12}>
                    <Typography sx={{fontStyle: "italic"}}>
                      {note?.author?.firstName} {note?.author?.lastName} - {moment(note?.createdAt).format("DD/MM/YYYY")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider direction={"horizontal"}/>
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
          </Grid>
          {isCreatingNewNote && (
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant={"outlined"}
                multiline
                value={newNote.content}
                onChange={(e) => setNewNote({
                  ...newNote,
                  content: e.target.value
                })}
              >
              </TextField>
            </Grid>
          )}
          {!user?.notes?.some(note => note.proId === admin?.id) && !isCreatingNewNote && (
            <Grid item xs={4}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setIsCreatingNewNote(true)}
                startIcon={<AddIcon/>}
                sx={{color:"#FFFFFF"}}
              >
                                Ajouter une note
              </Button>
            </Grid>
          )}
          {!user?.notes?.some(note => note.proId === admin?.id) && isCreatingNewNote && (
            <Grid item xs={4}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleValidateNewNote}
                sx={{color:"#FFFFFF"}}
              >
                                Ajouter
              </Button>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};
export default UserNotes;
