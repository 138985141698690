import React from "react";
import { Bar } from "react-chartjs-2";
import useMediaQuery from "@mui/material/useMediaQuery";
import {makeStyles} from '@mui/styles';
import {useTheme} from "@mui/styles";
import { Chart, registerables } from 'chart.js';
if(Array.isArray(registerables)) {
  Chart.register(...registerables);
}

const BarChart = ({labels, data, title, heightLg, heightMd, heightSm, color, displayLegend = true}) => {

  const useStyles = makeStyles(theme => ({
    root: {
      '& canvas': {
        width: '100% !important',
        height: `${heightLg}px !important`,
        [theme.breakpoints.down('md')]: {
          height: `${heightMd}px !important`,
        },
        [theme.breakpoints.down('sm')]: {
          height: `${heightSm}px !important`,
        }
      }
    }
  }));

  const theme = useTheme();
  const breakpoints900 = useMediaQuery(theme.breakpoints.down(900));
  const classes = useStyles();
  const dataValues = {
    labels: labels,
    datasets: [
      {
        label: title,
        data: data,
        backgroundColor: data && data.map(() => color.background),
        borderColor: data && data.map(() => color.border),
        borderWidth: 1,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
      responsive: true,
      legend: {
        display: displayLegend,
      }
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          callback: function (value) {
            return this.getLabelForValue(value).substring(0,15);
          },
          font: {
            size: breakpoints900 ? 9 : 12
          }
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          drawBorder: false,
        },
        ticks: {
          min: 0
        },
      }
    }
  };

  return (
    <div className={classes.root}>
      <Bar data={dataValues} options={options}/>
    </div>
  );
};
export default BarChart;
