export const SIGNUP_CREDENTIALS_REQUEST = 'AUTHENTICATION/SIGNUP_CREDENTIALS_REQUEST';
export const SIGNUP_CREDENTIALS_SUCCESS = 'AUTHENTICATION/SIGNUP_CREDENTIALS_SUCCESS';
export const SIGNUP_CREDENTIALS_FAILED  = 'AUTHENTICATION/SIGNUP_CREDENTIALS_FAILED';

export const SIGNUP_PROFILING_REQUEST = 'AUTHENTICATION/SIGNUP_PROFILING_REQUEST';
export const SIGNUP_PROFILING_SUCCESS = 'AUTHENTICATION/SIGNUP_PROFILING_SUCCESS';
export const SIGNUP_PROFILING_FAILED  = 'AUTHENTICATION/SIGNUP_PROFILING_FAILED';

export const SIGNIN_CREDENTIALS_REQUEST = 'AUTHENTICATION/SIGNIN_CREDENTIALS_REQUEST';
export const SIGNIN_CREDENTIALS_SUCCESS = 'AUTHENTICATION/SIGNIN_CREDENTIALS_SUCCESS';
export const SIGNIN_CREDENTIALS_FAILED  = 'AUTHENTICATION/SIGNIN_CREDENTIALS_FAILED';

export const SIGNIN_JWT_REQUEST = 'AUTHENTICATION/SIGNIN_JWT_REQUEST';
export const SIGNIN_JWT_SUCCESS = 'AUTHENTICATION/SIGNIN_JWT_SUCCESS';
export const SIGNIN_JWT_FAILED  = 'AUTHENTICATION/SIGNIN_JWT_FAILED';

export const SIGNOUT                        = 'AUTHENTICATION/SIGNOUT';
export const SIGNOUT_ALL_DEVICES            = 'AUTHENTICATION/SIGNOUT_ALL_DEVICES';
export const SIGNOUT_INVALID_ACCESS_TOKEN   = 'AUTHENTICATION/SIGNOUT_INVALID_ACCESS_TOKEN';

export const RELOAD_USER_REQUEST    = 'AUTHENTICATION/RELOAD_USER_REQUEST';
export const RELOAD_USER_SUCCESS    = 'AUTHENTICATION/RELOAD_USER_SUCCESS';
export const RELOAD_USER_FAILED     = 'AUTHENTICATION/RELOAD_USER_FAILED';

export const UPDATE_USER_REQUEST = 'AUTHENTICATION/UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'AUTHENTICATION/UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILED  = 'AUTHENTICATION/UPDATE_USER_FAILED';

export const GET_USER_CONTACT_REQUEST = 'AUTHENTICATION/GET_USER_CONTACT_REQUEST';
export const GET_USER_CONTACT_SUCCESS = 'AUTHENTICATION/GET_USER_CONTACT_SUCCESS';
export const GET_USER_CONTACT_FAILED  = 'AUTHENTICATION/GET_USER_CONTACT_FAILED';

export const SET_MEMBERSHIP_MODAL_OPEN = 'AUTHENTICATION/SET_MEMBERSHIP_MODAL_OPEN';

export const TAKE_CONTROL_REQUEST   = 'AUTHENTICATION/TAKE_CONTROL_REQUEST';
export const TAKE_CONTROL_SUCCESS   = 'AUTHENTICATION/TAKE_CONTROL_SUCCESS';
export const TAKE_CONTROL_FAILED    = 'AUTHENTICATION/TAKE_CONTROL_FAILED';

export const RESTORE_CONTROL_REQUEST   = 'AUTHENTICATION/RESTORE_CONTROL_REQUEST';
export const RESTORE_CONTROL_SUCCESS   = 'AUTHENTICATION/RESTORE_CONTROL_SUCCESS';
export const RESTORE_CONTROL_FAILED    = 'AUTHENTICATION/RESTORE_CONTROL_FAILED';

export const SET_MAILING_SUBSCRIPTION  = 'AUTHENTICATION/SET_MAILING_SUBSCRIPTION';

//AUTHORIZATIONS
export const SUPER_ADMIN_AUTH       = 'super-admin';
export const LOGISTIC_AUTH          = 'logistic';
export const EDITOR_AUTH            = 'editor';
export const ACCOUNTANT_AUTH        = 'accounting';
export const STOCK_NRD_AUTH         = 'stock-nrd';

//ROLES
export const ADMIN_ROLE             = 'admin';
export const EMPLOYEE_ROLE          = 'employee';
export const PRO_ROLE               = 'pro';
export const DELEGATE_ROLE          = 'delegate';
export const THERAPIST_ROLE         = 'therapist';
export const DOCTOR_ROLE            = 'doctor';
export const CUSTOMER_ROLE          = 'customer';
export const PROSPECT_ROLE          = 'prospect';

export const DIRECTOR_ROLE          = 'director';
export const MANAGER_ROLE           = 'manager';
export const COACH_ROLE             = 'coach';
export const CONSULTANT_ROLE        = 'consultant';

export const NUTRITIONIST_CAREER    = 'nutritionist';
export const STRUCTURE_CAREER       = 'structure';
export const SELLER_CAREER          = 'seller';
export const PARTNER_CAREER         = 'partner';
