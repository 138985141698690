import { reducer as toastr } from 'react-redux-toastr';
import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';

import cookieConsent from '../../src/components/Layout/Customer/CookieConsent/store/reducer';
import menu from '../../src/components/Layout/Menu/store/reducer';
import consultants from '../../src/containers/Customer/Consultants/store/reducer';
import home from '../../src/containers/Customer/Home/store/reducer';
import mag from '../../src/containers/Customer/Mag/store/reducer';
import shop from '../../src/containers/Customer/Shop/store/reducer';
//import {SIGNOUT, SIGNOUT_ALL_DEVICES, SIGNOUT_INVALID_ACCESS_TOKEN} from "../containers/Authentication/store/constants";
import authentication from '../containers/Authentication/store/reducer';
import { history } from '../helpers/store';

import bank from './../../src/containers/Customer/Wallet/store/reducer';
import map from './../components/GoogleMap/store/reducer';

const appReducer = (injectedReducers = {}) => combineReducers({
  ...injectedReducers,
  authentication,
  cookieConsent,
  consultants,
  bank,
  home,
  shop,
  map,
  mag,
  menu,
  toastr,
  router: connectRouter(history),
});

export default function createReducer(injectedReducers = {}) {
  return (state, action) =>
  //if (
  //  action.type === SIGNOUT ||
  //        action.type === SIGNOUT_ALL_DEVICES ||
  //        action.type === SIGNOUT_INVALID_ACCESS_TOKEN
  //) {
  //  state = undefined;
  //}

    appReducer(injectedReducers)(state, action)
  ;
}
