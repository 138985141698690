import React from "react";
import {useSelector} from "react-redux";

import PartnerRegistrationCard from "./components/PartnerRegistrationCard";
import {consultantSelector} from "./store/selectors";

const AddConsultantPartner = () => {

  const user = useSelector(consultantSelector());

  return (
    <PartnerRegistrationCard parent={user} />
  );
};
export default AddConsultantPartner;
