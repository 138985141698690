import produce from "immer";

import * as actions from './constants';
export const initialState = {
  statisticsData: {},
  addCardOpen: false,
  sectionCompared: 'sales',
  proNumberDisplayed: 10,
  statsDisplayed: {
    sales : {
      keys: ["2023-8"],
      countries: false,
      products: false,
      categories: false,
      delegates: false,
      pros: false,
    },
    countries: {
      keys: ["2023-10"],
      sales: false,
      products: false,
      categories: false,
      brands: false,
      delegates: false,
      pros: false,
    },
    products: {
      keys: ["2023-10"],
      sales: false,
      countries: false,
      categories: false,
      brands: false,
      delegates: false,
      pros: false,
    },
    categories: {
      keys: ["2023-10"],
      sales: false,
      countries: false,
      products: false,
      brands: false,
      delegates: false,
      pros: false,
    },
    brands: {
      keys: ["2023-10"],
      sales: false,
      countries: false,
      products: false,
      categories: false,
      delegates: false,
      pros: false,
    },
    delegates: {
      keys: ["2023-10"],
      sales: false,
      countries: false,
      products: false,
      categories: false,
      brands: false,
      pros: false,
    },
    pros: {
      keys: ["2023-10"],
      sales: false,
      countries: false,
      products: false,
      categories: false,
      brands: false,
      delegates: false,
    },
  }
};

export const statsReducerKey = 'stats';

export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case actions.GET_STATISTICS_DATA_BY_MONTH_REQUEST:
        break;
      case actions.GET_STATISTICS_DATA_BY_MONTH_SUCCESS:
      case actions.GET_STATISTICS_DATA_BY_INTERVAL_SUCCESS:
        draft.statisticsData[action.key] = action.data;
        break;
      case actions.SET_STATS_DISPLAYED:
        draft.statsDisplayed[action.section][action.key] = action.value;
        break;
      case actions.SET_STATS_COMPARED:
        let statsDisplayed = draft.statsDisplayed[action.section]['keys'];
        if([...statsDisplayed].some((key) => key === action.key)) return;
        draft.statsDisplayed[action.section]['keys'] = [...statsDisplayed, action.key];
        break;
      case actions.SET_ADD_CARD_OPEN:
        draft.addCardOpen = action.value;
        break;
      case actions.SET_PRO_NUMBER_DISPLAYED:
        draft.proNumberDisplayed = draft.proNumberDisplayed + 20;
        break;
      case actions.DELETE_CARD:
        //Same as SET_STATS_DISPLAYED but we delete the key instead of adding it
        draft.statsDisplayed[action.section]['keys'] = [...draft.statsDisplayed[action.section]['keys'].filter((key) => key !== action.key)];
        break;
      default:
    }
  });
/*

  import produce from "immer";

import * as actions from './constants';
export const initialState = {
  statisticsData: {},
  turnoverByMonth : null,
  recruitmentByMonth : null,
  packsAmount : null,
  productsAmount : null,
  bestSellersAmount : null,
  bestRecruitersAmount : null,
  packsAmountByMonth : null,
  ordersCountByMonth : null,
  activeProsCountByMonth : null,
  turnoverByCareer : null,

  directTurnoverByMonth : {},
  directRecurringTurnoverByMonth : {},
  networkTurnoverByMonth : {},
  networkRecurringSales : {},
  directRecruitment : {},
  networkRecruitment : {},
  directBestSellersAmount : {},
  networkBestSellersAmount : {},
  directBestRecruitersAmount : {},
  networkBestRecruitersAmount : {},
  directPacksAmount : {},
  networkPacksAmount : {},
  directProductsAmount : {},
  networkProductsAmount : {},
  networkTurnoverByCareer : {},
  networkActiveProsCountByMonth : {},
};

export const statsReducerKey = 'stats';

export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case actions.GET_TURNOVER_BY_MONTH_REQUEST:
        draft.turnoverByMonth = null;
        break;
      case actions.GET_TURNOVER_BY_MONTH_SUCCESS:
        draft.turnoverByMonth = action.data;
        break;
      case actions.GET_TURNOVER_BY_MONTH_ERROR:
        draft.error = action.error;
        break;
      case actions.GET_RECURRING_TURNOVER_BY_MONTH_REQUEST:
        draft.recurringTurnoverByMonth = null;
        break;
      case actions.GET_RECURRING_TURNOVER_BY_MONTH_SUCCESS:
        draft.recurringTurnoverByMonth = action.data;
        break;
      case actions.GET_RECURRING_TURNOVER_BY_MONTH_ERROR:
        draft.error = action.error;
        break;
      case actions.GET_RECRUITMENT_BY_MONTH_REQUEST:
        break;
      case actions.GET_RECRUITMENT_BY_MONTH_SUCCESS:
        draft.recruitmentByMonth = action.data;
        break;
      case actions.GET_RECRUITMENT_BY_MONTH_ERROR:
        draft.error = action.error;
        break;
      case actions.GET_PACKS_AMOUNT_REQUEST:
        draft.packsAmount = null;
        break;
      case actions.GET_PACKS_AMOUNT_SUCCESS:
        draft.packsAmount = action.data;
        break;
      case actions.GET_PACKS_AMOUNT_ERROR:
        draft.error = action.error;
        break;
      case actions.GET_PRODUCTS_AMOUNT_REQUEST:
        draft.productsAmount = null;
        break;
      case actions.GET_PRODUCTS_AMOUNT_SUCCESS:
        draft.productsAmount = action.data;
        break;
      case actions.GET_PRODUCTS_AMOUNT_ERROR:
        draft.eroductr = action.error;
        break;
      case actions.GET_PACKS_AMOUNT_BY_MONTH_REQUEST:
        break;
      case actions.GET_PACKS_AMOUNT_BY_MONTH_SUCCESS:
        draft.packsAmountByMonth = action.data;
        break;
      case actions.GET_PACKS_AMOUNT_BY_MONTH_ERROR:
        draft.error = action.error;
        break;
      case actions.GET_BESTSELLERS_AMOUNT_REQUEST:
        draft.bestSellersAmount = null;
        break;
      case actions.GET_BESTSELLERS_AMOUNT_SUCCESS:
        draft.bestSellersAmount = action.data;
        break;
      case actions.GET_BESTSELLERS_AMOUNT_ERROR:
        draft.error = action.error;
        break;
      case actions.GET_BEST_RECRUITERS_AMOUNT_REQUEST:
        draft.bestRecruitersAmount = null;
        break;
      case actions.GET_BEST_RECRUITERS_AMOUNT_SUCCESS:
        draft.bestRecruitersAmount = action.data;
        break;
      case actions.GET_BEST_RECRUITERS_AMOUNT_ERROR:
        draft.error = action.error;
        break;
      case actions.GET_ORDERS_COUNT_BY_MONTH_REQUEST:
        draft.ordersCountByMonth = null;
        break;
      case actions.GET_ORDERS_COUNT_BY_MONTH_SUCCESS:
        draft.ordersCountByMonth = action.data;
        break;
      case actions.GET_ORDERS_COUNT_BY_MONTH_ERROR:
        draft.error = action.error;
        break;
      case actions.GET_PROS_COUNT_BY_MONTH_REQUEST:
        draft.prosCountByMonth = null;
        break;
      case actions.GET_PROS_COUNT_BY_MONTH_SUCCESS:
        draft.prosCountByMonth = action.data;
        break;
      case actions.GET_PROS_COUNT_BY_MONTH_ERROR:
        draft.error = action.error;
        break;
      case actions.GET_ACTIVE_PROS_COUNT_BY_MONTH_REQUEST:
        draft.activeProsCountByMonth = null;
        break;
      case actions.GET_ACTIVE_PROS_COUNT_BY_MONTH_SUCCESS:
        draft.activeProsCountByMonth = action.data;
        break;
      case actions.GET_ACTIVE_PROS_COUNT_BY_MONTH_ERROR:
        draft.error = action.error;
        break;
      case actions.GET_TURNOVER_BY_CAREER_REQUEST:
        draft.turnoverByCareer = null;
        break;
      case actions.GET_TURNOVER_BY_CAREER_SUCCESS:
        draft.turnoverByCareer = action.data;
        break;
      case actions.GET_TURNOVER_BY_CAREER_ERROR:
        draft.error = action.error;
        break;

      case actions.GET_DIRECT_SALES_REQUEST:
        if(!state.directTurnoverByMonth[action.userId]) {
          draft.directTurnoverByMonth[action.userId] = null;
        }
        break;
      case actions.GET_DIRECT_SALES_SUCCESS:
        draft.directTurnoverByMonth[action.userId] = action.data;
        break;
      case actions.GET_DIRECT_SALES_ERROR:
        draft.error = action.error;
        break;
      case actions.GET_DIRECT_RECURRING_SALES_REQUEST:
        if(!state.directRecurringTurnoverByMonth[action.userId]) {
          draft.directRecurringTurnoverByMonth[action.userId] = null;
        }
        break;
      case actions.GET_DIRECT_RECURRING_SALES_SUCCESS:
        draft.directRecurringTurnoverByMonth[action.userId] = action.data;
        break;
      case actions.GET_DIRECT_RECURRING_SALES_ERROR:
        draft.error = action.error;
        break;
      case actions.GET_NETWORK_SALES_REQUEST:
        if(!state.networkTurnoverByMonth[action.userId]) {
          draft.networkTurnoverByMonth[action.userId] = {
            [action.year]: {
              [action.month]: null
            }
          };
        }
        break;
      case actions.GET_NETWORK_SALES_SUCCESS:
        draft.networkTurnoverByMonth[action.userId] = action.data;
        break;
      case actions.GET_NETWORK_SALES_ERROR:
        draft.error = action.error;
        break;
      case actions.GET_NETWORK_RECURRING_SALES_REQUEST:
        if(!state.networkRecurringSales[action.userId]) {
          draft.networkRecurringSales[action.userId] = null;
        }
        break;
      case actions.GET_NETWORK_RECURRING_SALES_SUCCESS:
        draft.networkRecurringSales[action.userId] = action.data;
        break;
      case actions.GET_NETWORK_RECURRING_SALES_ERROR:
        draft.error = action.error;
        break;
      case actions.GET_DIRECT_RECRUITMENT_REQUEST:
        if(!state.directRecruitment[action.userId]) {
          draft.directRecruitment[action.userId] = null;
        }
        break;
      case actions.GET_DIRECT_RECRUITMENT_SUCCESS:
        draft.directRecruitment[action.userId] = action.data;
        break;
      case actions.GET_DIRECT_RECRUITMENT_ERROR:
        draft.error = action.error;
        break;
      case actions.GET_NETWORK_RECRUITMENT_REQUEST:
        if(!state.networkRecruitment[action.userId]) {
          draft.networkRecruitment[action.userId] = null;
        }
        break;
      case actions.GET_NETWORK_RECRUITMENT_SUCCESS:
        draft.networkRecruitment[action.userId] = action.data;
        break;
      case actions.GET_NETWORK_RECRUITMENT_ERROR:
        draft.error = action.error;
        break;
      case actions.GET_DIRECT_BESTSELLERS_AMOUNT_REQUEST:
        if(!state.directBestSellersAmount[action.userId]) {
          draft.directBestSellersAmount[action.userId] = {
            [action.year]: {
              [action.month]: null
            }
          };
        }
        else if(!state.directBestSellersAmount[action.userId][action.year]) {
          draft.directBestSellersAmount[action.userId][action.year] = {
            [action.month]: null
          };
        }
        break;
      case actions.GET_DIRECT_BESTSELLERS_AMOUNT_SUCCESS:
        draft.directBestSellersAmount[action.userId][action.year][action.month] = action.data;
        break;
      case actions.GET_DIRECT_BESTSELLERS_AMOUNT_ERROR:
        draft.error = action.error;
        break;
      case actions.GET_DIRECT_BEST_RECRUITERS_AMOUNT_REQUEST:
        if(!state.directBestRecruitersAmount[action.userId]) {
          draft.directBestRecruitersAmount[action.userId] = {
            [action.year]: {
              [action.month]: null
            }
          };
        }
        else if(!state.directBestRecruitersAmount[action.userId][action.year]) {
          draft.directBestRecruitersAmount[action.userId][action.year] = {
            [action.month]: null
          };
        }
        break;
      case actions.GET_DIRECT_BEST_RECRUITERS_AMOUNT_SUCCESS:
        draft.directBestRecruitersAmount[action.userId][action.year][action.month] = action.data;
        break;
      case actions.GET_DIRECT_BEST_RECRUITERS_AMOUNT_ERROR:
        draft.error = action.error;
        break;
      case actions.GET_NETWORK_BESTSELLERS_AMOUNT_REQUEST:
        if(!state.networkBestSellersAmount[action.userId]) {
          draft.networkBestSellersAmount[action.userId] = {
            [action.year]: {
              [action.month]: null
            }
          };
        }
        else if(!state.networkBestSellersAmount[action.userId][action.year]) {
          draft.networkBestSellersAmount[action.userId][action.year] = {
            [action.month]: null
          };
        }
        break;
      case actions.GET_NETWORK_BESTSELLERS_AMOUNT_SUCCESS:
        draft.networkBestSellersAmount[action.userId][action.year][action.month] = action.data;
        break;
      case actions.GET_NETWORK_BESTSELLERS_AMOUNT_ERROR:
        draft.error = action.error;
        break;
      case actions.GET_NETWORK_BEST_RECRUITERS_AMOUNT_REQUEST:
        if(!state.networkBestRecruitersAmount[action.userId]) {
          draft.networkBestRecruitersAmount[action.userId] = {
            [action.year]: {
              [action.month]: null
            }
          };
        }
        else if(!state.networkBestRecruitersAmount[action.userId][action.year]) {
          draft.networkBestRecruitersAmount[action.userId][action.year] = {
            [action.month]: null
          };
        }
        break;
      case actions.GET_NETWORK_BEST_RECRUITERS_AMOUNT_SUCCESS:
        draft.networkBestRecruitersAmount[action.userId][action.year][action.month] = action.data;
        break;
      case actions.GET_NETWORK_BEST_RECRUITERS_AMOUNT_ERROR:
        draft.error = action.error;
        break;
      case actions.GET_DIRECT_PACKS_AMOUNT_REQUEST:
        if(!state.directPacksAmount[action.userId]) {
          draft.directPacksAmount[action.userId] = {
            [action.year]: {
              [action.month]: null
            }
          };
        }
        else if(!state.directPacksAmount[action.userId][action.year]) {
          draft.directPacksAmount[action.userId][action.year] = {
            [action.month]: null
          };
        }
        break;
      case actions.GET_DIRECT_PACKS_AMOUNT_SUCCESS:
        draft.directPacksAmount[action.userId][action.year][action.month] = action.data;
        break;
      case actions.GET_DIRECT_PACKS_AMOUNT_ERROR:
        draft.error = action.error;
        break;
      case actions.GET_NETWORK_PACKS_AMOUNT_REQUEST:
        if(!state.networkPacksAmount[action.userId]) {
          draft.networkPacksAmount[action.userId] = {
            [action.year]: {
              [action.month]: null
            }
          };
        }
        else if(!state.networkPacksAmount[action.userId][action.year]) {
          draft.networkPacksAmount[action.userId][action.year] = {
            [action.month]: null
          };
        }
        break;
      case actions.GET_NETWORK_PACKS_AMOUNT_SUCCESS:
        draft.networkPacksAmount[action.userId][action.year][action.month] = action.data;
        break;
      case actions.GET_NETWORK_PACKS_AMOUNT_ERROR:
        draft.error = action.error;
        break;
      case actions.GET_DIRECT_PRODUCTS_AMOUNT_REQUEST:
        if(!state.directProductsAmount[action.userId]) {
          draft.directProductsAmount[action.userId] = {
            [action.year]: {
              [action.month]: null
            }
          };
        }
        else if(!state.directProductsAmount[action.userId][action.year]) {
          draft.directProductsAmount[action.userId][action.year] = {
            [action.month]: null
          };
        }
        break;
      case actions.GET_DIRECT_PRODUCTS_AMOUNT_SUCCESS:
        draft.directProductsAmount[action.userId][action.year][action.month] = action.data;
        break;
      case actions.GET_DIRECT_PRODUCTS_AMOUNT_ERROR:
        draft.error = action.error;
        break;
      case actions.GET_NETWORK_PRODUCTS_AMOUNT_REQUEST:
        if(!state.networkProductsAmount[action.userId]) {
          draft.networkProductsAmount[action.userId] = {
            [action.year]: {
              [action.month]: null
            }
          };
        }
        else if(!state.networkProductsAmount[action.userId][action.year]) {
          draft.networkProductsAmount[action.userId][action.year] = {
            [action.month]: null
          };
        }
        break;
      case actions.GET_NETWORK_PRODUCTS_AMOUNT_SUCCESS:
        draft.networkProductsAmount[action.userId][action.year][action.month] = action.data;
        break;
      case actions.GET_NETWORK_PRODUCTS_AMOUNT_ERROR:
        draft.error = action.error;
        break;
      case actions.GET_NETWORK_TURNOVER_BY_CAREER_REQUEST:
        if(!state.networkTurnoverByCareer[action.userId]) {
          draft.networkTurnoverByCareer[action.userId] = {
            [action.year]: {
              [action.month]: null
            }
          };
        }
        else if(!state.networkTurnoverByCareer[action.userId][action.year]) {
          draft.networkTurnoverByCareer[action.userId][action.year] = {
            [action.month]: null
          };
        }
        break;
      case actions.GET_NETWORK_TURNOVER_BY_CAREER_SUCCESS:
        draft.networkTurnoverByCareer[action.userId][action.year][action.month] = action.data;
        break;
      case actions.GET_NETWORK_TURNOVER_BY_CAREER_ERROR:
        draft.error = action.error;
        break;
      case actions.GET_NETWORK_ACTIVE_PROS_COUNT_BY_MONTH_REQUEST:
        if(!state.networkActiveProsCountByMonth[action.userId]) {
          draft.networkActiveProsCountByMonth[action.userId] = null;
        }
        break;
      case actions.GET_NETWORK_ACTIVE_PROS_COUNT_BY_MONTH_SUCCESS:
        draft.networkActiveProsCountByMonth[action.userId] = action.data;
        break;
      case actions.GET_NETWORK_ACTIVE_PROS_COUNT_BY_MONTH_ERROR:
        draft.error = action.error;
        break;
      default:
    }
  }
  );
*/
