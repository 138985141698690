export const OPEN_CHECKOUT_DRAWER           = 'PRO_SHOP/OPEN_CHECKOUT_DRAWER';
export const OPEN_CHECKOUT_RESPONSE_DRAWER  = 'PRO_SHOP/OPEN_CHECKOUT_RESPONSE_DRAWER';
export const CLOSE_CHECKOUT_DRAWER          = 'PRO_SHOP/CLOSE_CHECKOUT_DRAWER';
export const CLOSE_CHECKOUT_RESPONSE_DRAWER = 'PRO_SHOP/CLOSE_CHECKOUT_RESPONSE_DRAWER';

export const SET_ITEM_QUANTITY = 'PRO_SHOP/SET_ITEM_QUANTITY';

export const GET_CHECKOUT_REQUEST = 'PRO_SHOP/GET_CHECKOUT_REQUEST';
export const GET_CHECKOUT_SUCCESS = 'PRO_SHOP/GET_CHECKOUT_SUCCESS';
export const GET_CHECKOUT_FAIL    = 'PRO_SHOP/GET_CHECKOUT_FAIL';

export const CLEAN_CHECKOUT = 'PRO_SHOP/CLEAN_CHECKOUT';