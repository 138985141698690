import React, {useCallback, useEffect, useState} from "react";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import {Autocomplete} from "@mui/material";
import {createFilterOptions} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import { grey } from "@mui/material/colors";
import TextField from "@mui/material/TextField";
import {makeStyles} from '@mui/styles';

import {SnackbarFormattedError} from "../../../../helpers/snackbar";
import {customersService} from "../store/services";

const useStyle = makeStyles(() => ({
  textField: {},
  option: {
    cursor: "pointer",
    width: '100%',
    paddingLeft: 5,
    borderBottom: `1px dashed ${grey[600]}`,
    '& p': {
      margin: 0,
      padding: 0,
    },
    '& span': {
      marginRight: 5,
    },
    '& .identifier': {
      margin: 0,
      fontWeight: 'bold',
      fontSize: 12,
      textTransform: 'uppercase',
      color: grey[800]
    },
  },
}));
const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
const checkedIcon = <CheckBoxIcon fontSize="small"/>;

const SearchCustomerAutocomplete = ({
  autoFocus,
  onChange,
  defaultValue,
  target = 'all',
  label = 'Patients',
  multiple = false,
  displayRole,
  withCheckbox = false,
  ...rest
}) => {

  const classes = useStyle();
  const [userSearchOpen, setUserSearchOpen] = useState(false);
  const [userSearchLoading, setUserSearchLoading] = useState(false);
  const [userSearch, setUserSearch] = useState('');
  const [users, setUsers] = useState([]);
  const [debouncedUserSearch, setDebouncedUserSearch] = useState(userSearch);
  const [lastSearch, setLastSearch] = useState('');
  const [searchedOnce, setSearchedOnce] = useState(false);

  // Debounce
  useEffect(() => {
    if(userSearch.length < lastSearch.length) setSearchedOnce(false);
    const handler = setTimeout(() => {
      setDebouncedUserSearch(userSearch);
    }, 500); // 500 ms de debounce

    return () => {
      clearTimeout(handler);
    };
  }, [userSearch]);

  useEffect(() => {
    // Utilisation de debouncedUserSearch pour déclencher la recherche
    if (debouncedUserSearch.length > 2 && (!searchedOnce || debouncedUserSearch.length < lastSearch.length)) {
      setUserSearchLoading(true);
      setSearchedOnce(true);
      customersService.searchCustomers(userSearch, target).then(users => {
        setUsers(users);
        setUserSearchLoading(false);
        setLastSearch(debouncedUserSearch);
      })
        .catch(e => {
          SnackbarFormattedError(e.error);
          setUserSearchLoading(false);
        });
    } else if (debouncedUserSearch.length <= 2) {
      setUsers([]);
      setSearchedOnce(false);
    }
  }, [debouncedUserSearch]);
  /*

  useEffect(() => {
    if ((userSearch.length > 3 && (users.length === 0))) {
      setUserSearchLoading(true);
      userService.searchUsers(userSearch, target).then(users => {
        setUsers(users);
        setUserSearchLoading(false);
      })
        .catch(e => {
          SnackbarFormattedError(e.error);
          setUserSearchLoading(false);
        });
    } else if (userSearch.length < 2) {
      setUsers([]);
    }
  }, [userSearch]);
*/

  const filterOptions = createFilterOptions({
    stringify: option => `${option.lastName} ${option.firstName} ${option.lastName} ${option.email} ${option.phone} ${option.proCode}`,
  });

  // Utilisation de useCallback pour créer une version stable de handleUserSearchChange
  const handleUserSearchChange = useCallback((e) => {
    setUserSearch(e.target.value);
  }, []);

  return (
    <Autocomplete
      sx={{width: '100%'}}
      open={userSearchOpen}
      filterOptions={filterOptions}
      autoHighlight={true}
      multiple={multiple}
      onOpen={() => {
        setUserSearchOpen(true);
      }}
      onClose={() => {
        setUserSearchOpen(false);
      }}
      isOptionEqualToValue={(option, value) => multiple ? option.id === value.id : true}
      autoComplete
      filterSelectedOptions
      onChange={onChange}
      getOptionLabel={
        (option) => `${option.firstName && option.lastName ? `${option.lastName.toUpperCase()} ${option.firstName.toUpperCase()} - ` : ''} ${option.zip} ${option.city} ${displayRole ? `- ROLE : ${option.role.toUpperCase()}` : ''}`
      }
      renderOption={(props, option, {selected}) =>
        <li {...props} className={classes.option} key={option?.id}>
          {withCheckbox && (<Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            sx={{marginRight: 8}}
            checked={selected}
          />)}
          <p className="identifier">
                  [{option.role}]
          </p>
          {option.lastName && option.firstName
            ? <span>
              {option.lastName.toUpperCase()} {option.firstName} -
            </span>
            : ''}
          <span>
            {option.phone} {option.email} {option.zip} {option.city}
          </span>
        </li>
      }
      options={users}
      defaultValue={defaultValue}
      noOptionsText={"Rechercher par nom, email, code therap..."}
      loading={userSearchLoading}
      inputValue={userSearch}
      onInputChange={(event, newInputValue) => {
        setUserSearch(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          className={classes.textField}
          autoFocus={autoFocus}
          label={label}
          placeholder={"Rechercher un utilisateur"}
          variant="outlined"
          onChange={handleUserSearchChange}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {userSearchLoading ? <CircularProgress color="inherit" size={20}/> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
      {...rest}
    />
  );
};
export default SearchCustomerAutocomplete;
