import React from "react";
import {Route, Switch} from 'react-router-dom';
import loadable from "@loadable/component";

const AccountingRoutes = () => {

  const Dashboard = loadable(() => import("../containers/Accounting/Dashboard/Dashboard"));
  const Invoices = loadable(() => import("../containers/Accounting/Invoices"));
  const CreditNotes = loadable(() => import("../containers/Accounting/CreditNotes"));
  const CommissionsCreditNotes = loadable(() => import("../containers/Accounting/CommissionsCreditNotes"));
  const Error = loadable(() => import("../containers/Pro/Error"));

  return (
    <Switch>
      <Route exact path="/accounting" component={Dashboard} />
      <Route exact path="/accounting/invoices" component={Invoices} />
      <Route exact path="/accounting/creditNotes" component={CreditNotes} />
      <Route exact path="/accounting/commissions" component={CommissionsCreditNotes} />
      <Route path="*" component={Error}/>
    </Switch>
  );
};

export default AccountingRoutes;
