import React, {useEffect, useState} from "react";
import {Trans, useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import RoomIcon from '@mui/icons-material/Room';
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { grey } from "@mui/material/colors";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import ListItemText from "@mui/material/ListItemText";
import {useTheme} from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import {makeStyles} from '@mui/styles';

import useLoadGoogleMapApi from "../../../../../components/GoogleMap/useLoadGoogleMapApi";
import {userSelector} from "../../../../Authentication/store/selectors";
import {shippingAddressSelectedSelector} from "../../store/selectors";
import {shopService} from "../../store/services";

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: 1400,
    '& .MuiDialog-paper': {
      [theme.breakpoints.up('sm')]: {
        width: '80vw',
        minWidth: 760,
        height: '80vh',
        '& .MuiListItem-root': {
          borderRight: '1px solid #DDD'
        },
        '& .MuiDivider-root': {
          margin: -1
        }
      },
      [theme.breakpoints.down('xs')]: {
        '& .MuiCard-root': {
          overflow: 'auto'
        }
      },
      '& .MuiTypography-subtitle1': {
        maxHeight: 42,
        lineHeight: '20px',
        overflow: 'hidden'
      },
      '& h4': {
        marginBottom: 20
      }
    },
    '& #google-map': {
      height: 'calc(80vh - 160px) !important',
      [theme.breakpoints.down('xs')]: {
        height: 'calc(100vh - 180px) !important',
      }
    },
    '& #listItems': {
      '& liMuiListItem-container': {
        '&:hover': {
          background: grey[100],
          cursor: 'pointer'
        },
        padding: theme.spacing(1)
      },
      overflow: 'auto',
      height: 'calc(80vh - 160px) !important',
      '& .MuiListItemSecondaryAction-root': {
        top: 'auto',
        right: 'auto',
        position: 'inherit',
        transform: 'inherit'
      },
      [theme.breakpoints.down('xs')]: {
        height: 'auto !important',
        '& .MuiListItemAvatar-root': {
          display: 'none',
        },
      },
      '& .openingHours': {
        display: 'flex',
      },
      '& .day': {
        fontSize: 14,
        fontWeight: 'bold',
      },
      '& .hour': {
        fontSize: 14,
      },
    }
  },
  closeButton: {
    top: 10,
    right: 15,
    cursor: 'pointer',
    position: 'absolute',
    fontSize: 36,
    color: grey[500],
    [theme.breakpoints.down('xs')]: {
      top: 10,
      right: 20,
      fontSize: 40,
    },
  }
}));

const SelectPickupPointModal = ({modalOpen, handleCloseModal, onSelect, type}) => {

  useLoadGoogleMapApi();
  const theme = useTheme();
  const classes = useStyles();
  const {t} = useTranslation();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const [locations, setLocations] = useState([]);
  const [locationsSelected, setLocationsSelected] = useState({});
  const [mapView, setMapView] = useState(false);
  const user = useSelector(userSelector);
  const shippingAddress = useSelector(shippingAddressSelectedSelector);
  /*const zoom = 14;
  const coordinates = {
    lat: shippingAddress.latitude,
    lng: shippingAddress.longitude
  };
  const mapOptions = {
    fullscreenControl: false,
    mapTypeControl: false,
    panControl: false,
    streetViewControl: false,
    zoomControl: "true",
    gestureHandling: "greedy"
  };*/

  useEffect(() => {

    shopService.getPickupPoints(shippingAddress.id, user.id, false, type).then(locations => {
      setLocations(locations);
    });
  }, [shippingAddress]);

  const handleClose = () => {
    handleCloseModal();
  };

  const handleSeeDetails = (location) => () => {
    setLocationsSelected({...locationsSelected, [location.id]: !locationsSelected[location.id]});
  };

  const handleSelect = (location) => () => {
    onSelect(location);
  };

  return (
    <Dialog onClose={handleClose} open={modalOpen} fullScreen={fullScreen} className={classes.root}>
      <CloseIcon className={classes.closeButton} onClick={handleClose}/>
      <DialogTitle id="responsive-dialog-title"><Trans i18nKey={type && type === 3 ? 'pickupPoint' : 'packetDelivery'}>Pickup point</Trans></DialogTitle>
      <DialogContent>
        <Card elevation={0}>
          <CardContent>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Grid container spacing={2} alignItems={"center"} justify={"space-between"}>
                  <Grid item>
                    <Typography>
                      {type && type === 3
                        ?<Trans i18nKey={"choosePickupPoint"}>Choose your pickup point</Trans>
                        :<Trans i18nKey={"choosePacketDelivery"}>Choose your pickup point</Trans>
                      }
                    </Typography>
                  </Grid>
                  {/*<Grid item>
                    {!mapView && (
                        <Button onClick={handleViewChange} startIcon={<ExploreIcon/>} variant={"outlined"}
                                color={"primary"}
                                disabled={(!shippingAddress?.longitude || !shippingAddress?.latitude)}>
                          <Trans i18nKey={"viewOnMap"}>View on map</Trans>
                        </Button>
                    )}
                    {mapView && (
                        <Button onClick={handleViewChange} startIcon={<ExploreOffIcon/>} variant={"outlined"}
                                color={"primary"}>
                          <Trans i18nKey={"viewInList"}>View in list</Trans>
                        </Button>
                    )}
                  </Grid>*/}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                {/*mapView && (
                  <Typography component={"div"}>
                    <Map
                      zoom={zoom}
                      coordinates={coordinates}
                      onDragEnd={() => {}}
                      onZoomChanged={() => {}}
                      options={mapOptions}
                    >
                      <ClickAwayListener onClickAway={handleClickAway}>
                        <div>
                          <PickupPointInfoWindow
                            pickupPoint={markerSelected}
                            onSelect={onSelect}
                          />
                          {locations.map((location, index) => (
                            <MapInfoWindow
                              key={index}
                              zIndex={0}
                              disableAutoPan
                              position={{
                                lat:location.latitude,
                                lng:location.longitude
                              }}
                            >
                              <PickupPointMarker
                                pickupPoint={location}
                                markerSelected={markerSelected}
                                setMarkerSelected={setMarkerSelected}
                              />
                            </MapInfoWindow>
                          ))}
                        </div>
                      </ClickAwayListener>
                    </Map>
                  </Typography>
                )*/}
                {!mapView && (
                  <Typography component={"div"}>
                    <List id={"listItems"}>
                      {locations.map((location) => (
                        <ListItem key={location.id} disableGutters onClick={handleSeeDetails(location)} sx={{cursor: "pointer"}}>
                          <ListItemAvatar>
                            <Avatar>
                              <RoomIcon />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText primary={location.name} secondary={`${location.country === 'BE' ? `${location.street} ${location.number}` : `${location.number} ${location.street}`} ${location.zip} ${location.city}`} />
                          <ListItemSecondaryAction>
                            {locationsSelected[location.id] && (
                              <>
                                <Typography>
                                  <Trans i18nKey={"openingTime"}>Opening time</Trans> :
                                </Typography>
                                <Grid container spacing={2}>
                                  <Grid item xs={12}>
                                    {location.hours.map(day => (
                                      <Grid container className={"openingHours"} key={day.title}>
                                        <Grid item xs={4}>
                                          <Typography component={"span"} className={"day"}>{t(day.title)}</Typography>
                                        </Grid>
                                        {day.AMOpen && day.PMClose && !day.AMClose && !day.PMOpen && (
                                          <Grid item xs={4}>
                                            <Typography component={"span"} className={"hour"}>{day.AMOpen} - {day.PMClose}</Typography>
                                          </Grid>
                                        )}
                                        {day.AMOpen && day.AMClose && (
                                          <Grid item xs={4}>
                                            <Typography component={"span"} className={"hour"}>{day.AMOpen} - {day.AMClose}</Typography>
                                          </Grid>
                                        )}
                                        {day.PMOpen && day.PMClose && (
                                          <Grid item xs={4}>
                                            <Typography component={"span"} className={"hour"}>{day.PMOpen} - {day.PMClose}</Typography>
                                          </Grid>
                                        )}
                                      </Grid>
                                    ))}
                                  </Grid>
                                  {(location.closedFrom || location.closedTo) && (
                                    <Grid item xs={12}>
                                      <Typography color={"error"}>
                                        <Trans i18nKey={"closingFromTo"} values={{closedFrom: location.closedFrom, closedTo: location.closedTo}}>
                                                                                    Closed from {location.closedFrom} to {location.closedTo}
                                        </Trans>
                                      </Typography>
                                    </Grid>
                                  )}
                                  <Grid item>
                                    <Button fullWidth variant={"contained"} color={"primary"} onClick={handleSelect(location)}>
                                      <Trans i18nKey={"select"}>Select</Trans>
                                    </Button>
                                  </Grid>
                                </Grid>
                              </>
                            )}
                          </ListItemSecondaryAction>
                        </ListItem>
                      ))}
                    </List>
                  </Typography>
                )}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </DialogContent>
    </Dialog>
  );
};
export default SelectPickupPointModal;
