import React, {useState} from "react";
import {Trans} from "react-i18next";
import FormatListNumberedTwoToneIcon from '@mui/icons-material/FormatListNumberedTwoTone';
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import {makeStyles} from '@mui/styles';

import CustomerOrderActionsModal from "./CustomerOrderActionsModal";

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiDivider-root': {
      margin: -1,
      [theme.breakpoints.down('xs')]: {
        display: 'none'
      },
    },
    [theme.breakpoints.down('xs')]: {
      '& .MuiCard-root': {
        overflow: 'auto'
      }
    },
    '& .MuiTypography-subtitle1': {
      maxHeight: 42,
      lineHeight: '20px',
      overflow: 'hidden'
    },
    '& img': {
      maxWidth: '100%',
      maxHeight: 80
    }
  },
  listItems: {
    [theme.breakpoints.up('sm')]: {
      overflow: 'auto'
    }
  },
  item: {
    [theme.breakpoints.up('sm')]: {
      '& .MuiListItem-root': {
        borderRight: '1px solid #DDD'
      }
    },
  },
  detailsRowPrice: {
    paddingTop: 20
  },
  divider: {
    //borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
  }
}));

const CustomerOrderRecommendationDetails = ({order, onChange}) => {

  const classes = useStyles();
  const [actionsModalIsOpen, setActionsModalIsOpen] = useState(false);

  const handleOpenOrderActions = () => {

    setActionsModalIsOpen(true);
  };

  const handleCloseOrderActions = () => {

    setActionsModalIsOpen(false);
  };

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <Typography align={"left"}>
          <Button startIcon={<FormatListNumberedTwoToneIcon/>} color={"primary"} variant={"outlined"} onClick={handleOpenOrderActions}>
            <Trans i18nKey={"orderActions"}>Order actions</Trans>
          </Button>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <List className={classes.listItems}>
          {order.products.map((item, key) => (
            <React.Fragment key={key}>
              <ListItem className={classes.item}>
                <Grid container justify={"center"} alignItems={"center"}>
                  <Grid item xs={4}>
                    <Grid container justify={"center"} alignItems={"center"}>
                      <img
                        width={80}
                        height={80}
                        src={item.originalProduct.image[200]}
                        alt={item.originalProduct.detail.name}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={8}>
                    <Grid container alignItems={"center"}>
                      <Grid item xs={8}>
                        <Typography variant="subtitle1">
                          <b>{item.quantity} x</b> {item.originalProduct.detail.name}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </ListItem>
              {order.products.length > 1 && (
                <Divider/>
              )}
            </React.Fragment>
          ))}
        </List>
      </Grid>
      <CustomerOrderActionsModal order={order} open={actionsModalIsOpen} onClose={handleCloseOrderActions} onChange={onChange}/>
    </Grid>
  );
};

export default CustomerOrderRecommendationDetails;
