import React from "react";
import MenuIcon from "@mui/icons-material/Menu";
import {Box} from "@mui/material";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import NoSsr from "@mui/material/NoSsr";
import Toolbar from "@mui/material/Toolbar";

import CartIcon from "../../../../../containers/Customer/Shop/components/Cart/CartIcon";
import SearchBarHeaderButtons from "../../../../../containers/Customer/Shop/components/Search/SearchBarHeaderButtons";
import {Link} from "../../../../../helpers/multilingual";
import FavoriteIcon from "../../FavoriteIcon";
import UserMenu from "../../UserMenu";

const HeaderXS = ({mobileOpen, setMobileOpen}) => (
  <Toolbar sx={{backgroundColor: '#ffffff', pb: '12px', pt: '6px', boxShadow: '0px 5px 12px #00000021'}}>
    <Grid container alignItems="center" justifyContent={"space-between"}>
      <Grid item xs={6}>
        <Grid container alignItems={"center"} flexWrap={"nowrap"}>
          <Grid item>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={() => setMobileOpen(!mobileOpen)}
              sx={{ mr: 0, display: { sm: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
          </Grid>
          <Grid item>
            <Link to="/">
              <Box
                component={"img"}
                width={100}
                src={`${process.env.REACT_APP_API_RESOURCES_URL}/images/logo/logo.svg`}
                alt="logo"
                sx={{width: '130px'}}
              />
            </Link>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Grid container alignItems={"center"} justifyContent={"flex-end"} spacing={2}>
          <Grid item>
            <UserMenu/>
          </Grid>
          {/*<Grid item>
              <LangSelector/>
            </Grid>*/}
          <Grid item>
            <FavoriteIcon />
          </Grid>
          <Grid item>
            <CartIcon className={"cart-icon"} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <NoSsr>
          <SearchBarHeaderButtons/>
        </NoSsr>
      </Grid>
    </Grid>
  </Toolbar>
);
export default HeaderXS;
