import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {Route, Switch} from "react-router-dom";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {makeStyles} from '@mui/styles';

import Breadcrumb from "../../../components/Layout/Pro/Breadcrumb";
import PageLoader from "../../../components/PageLoader";
import {Redirect} from "../../../helpers/multilingual";
import {ExcludePartner} from "../../Authentication/store/helper";
import {userSelector} from "../../Authentication/store/selectors";

import ConsultantActionsCard from "./components/ConsultantActionsCard";
import { networkActions } from "./store/actions";
import { consultantSelector } from "./store/selectors";
import AddConsultantPartner from "./AddConsultantPartner";
import Consultant from "./Consultant";
// import ConsultantNetworkTable from "./ConsultantNetworkTable";
import ConsultantStats from "./ConsultantStats";
import PartnerOfficePreview from "./PartnerOfficePreview";
import PartnerOfficeUpdate from "./PartnerOfficeUpdate";
import UpdateConsultantShippingAddress from "./UpdateConsultantShippingAddress";
import UpdatePartner from "./UpdatePartner";

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(2)
  }
}));

const ConsultantLayout = (props) => {

  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const consultant = useSelector(consultantSelector());

  ExcludePartner({redirect: '/pro'});

  useEffect(() => {

    if((!consultant || consultant.id !== props.match.params.consultantId) && user.id !== props.match.params.consultantId) {
      dispatch(networkActions.getConsultantRequest(props.match.params.consultantId));
    }
  }, [props.match.params]);

  if(user.id === props.match.params.consultantId) {
    return (
      <Redirect to={'/pro/settings/account'}/>
    );
  }

  if(!consultant || consultant.id !== props.match.params.consultantId) {
    return (
      <PageLoader/>
    );
  }

  return (
    <div className={classes.root}>
      <Helmet>
        <title>{`${consultant.firstName} ${consultant.lastName}`}</title>
      </Helmet>
      <Container fixed maxWidth="xl">
        <div className={classes.title}>
          <Breadcrumb parents={[
            {name: t("consultants"), uri: '/network/tree'},
            {name: `${consultant.firstName} ${consultant.lastName}`}
          ]}/>
          <Typography component="h1" variant="h4">
            {consultant.firstName} {consultant.lastName}
          </Typography>
        </div>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
            <ConsultantActionsCard consultant={consultant}/>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
            <Switch>
              <Route exact path="/:lang(en|fr|nl)?/pro/network/:consultantId" component={Consultant}/>
              <Route exact path="/:lang(en|fr|nl)?/pro/network/:consultantId/update" component={UpdatePartner}/>
              <Route exact path="/:lang(en|fr|nl)?/pro/network/:consultantId/update/shipping" component={UpdateConsultantShippingAddress}/>
              {/*<Route exact path="/:lang(en|fr|nl)?/pro/network/:consultantId/network" component={ConsultantNetworkTable}/>*/}
              <Route exact path="/:lang(en|fr|nl)?/pro/network/:consultantId/stats" component={ConsultantStats}/>
              <Route exact path="/:lang(en|fr|nl)?/pro/network/:consultantId/partner/add" component={AddConsultantPartner}/>
              <Route exact path="/:lang(en|fr|nl)?/pro/network/:consultantId/office" component={PartnerOfficePreview}/>
              <Route exact path="/:lang(en|fr|nl)?/pro/network/:consultantId/office/update" component={PartnerOfficeUpdate}/>
            </Switch>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};
export default ConsultantLayout;
