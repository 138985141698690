import React from "react";
import {TextField} from "@mui/material";

/**
 * @param onChange
 * @param value<string>
 * @param rest
 * @returns {JSX.Element}
 * @constructor
 */
const NumberTextField = ({onChange, value, ...rest}) => {

  const handleChange = (e) => {
    const numbers = /^[0-9,.]*$/;

    let newValue = "";
    if(e.target.value.startsWith("0")){
      newValue = e.target.value.substring(1, e.target.value.length);
      e.target.value = newValue;
    }
    if(e.target.value.includes(",")){
      newValue = e.target.value.replace(",",".");
      e.target.value = newValue;
    }
    if(e.target.value.match(numbers) && e.target.value.split(".").length < 3){
      if(isNaN(parseFloat(e.target.value.split(".")[0]))) e.target.value = "0" + e.target.value;
      onChange(e);
    }
  };

  return (
    <TextField
      autoFocus={false}
      onChange={handleChange}
      value={value}
      {...rest}
    />
  );
};

export default NumberTextField;