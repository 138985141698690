import axios  from '../../../../helpers/axios';

export const statsService = {
  getStatisticsDataByMonth,
  getStatisticsDataByInterval,
  getDashboardData,
  getStockSalesHistoriesByDates,
  getCaByMonth,
  getPublishedOrders
};

function getStatisticsDataByMonth(year, month) {

  return new Promise((resolve, reject) => {

    axios.get(`/storage/statistics/month/${year}/${month}`)
      .then(({statistics}) => {
        resolve(statistics);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getStatisticsDataByInterval(start, end) {

  return new Promise((resolve, reject) => {

    axios.get(`/admin/statistics/interval/${start}/${end}`)
      .then(({statistics}) => {
        resolve(statistics);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getDashboardData(start, end) {

  return new Promise((resolve, reject) => {

    axios.get(`/admin/statistics/dashboard/${start}/${end}`)
      .then(({statistic}) => {
        resolve(statistic);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}
function getCaByMonth() {

  return new Promise((resolve, reject) => {

    axios.get(`/admin/statistics/cahtva/`)
      .then(({statistic}) => {
        resolve(statistic);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getStockSalesHistoriesByDates(id, start, end) {

  return new Promise((resolve, reject) => {

    axios.get(`/shop/admin/stocks/${id}/sales/histories/${start}/${end}`)
      .then(({histories}) => {
        resolve(histories);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getPublishedOrders(start, end) {

  return new Promise((resolve, reject) => {

    axios.get(`/admin/orders/published/${start}/${end}`)
      .then(({orders}) => {
        resolve(orders);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

/*export const statsService = {
  getTurnoverByMonth,
  getRecurringTurnoverByMonth,
  getRecruitmentByMonth,
  getTurnover,
  getRecruitment,
  getPacksAmount,
  getProductsAmount,
  getPacksAmountByMonth,
  getBestSellersAmount,
  getBestRecruitersAmount,
  getOrdersCountByMonth,
  getOrders,
  getPublishedOrders,
  getProsCountByMonth,
  getActiveProsCountByMonth,
  getTurnoverByCareer,
  getAccountingNumbers,
  getProsPerformances,
  getRecruitsProsPerformances,
  getActiveProsByCareerRequest,
  getStockSalesHistoriesByDates,

  getDirectSales,
  getNetworkSales,
  getDirectRecurringSales,
  getNetworkRecurringSales,
  getDirectRecruitment,
  getNetworkRecruitment,
  getDirectBestSellersAmount,
  getDirectBestRecruitersAmount,
  getNetworkBestSellersAmount,
  getNetworkBestRecruitersAmount,
  getNetworkPacksAmount,
  getNetworkProductsAmount,
  getDirectPacksAmount,
  getDirectProductsAmount,
  getNetworkTurnoverByCareer,
  getNetworkActiveProsCountByMonth,
  getNetworkPerformances,
  getNetSales,
  getGrossSales,
  getAverageDiscountPercent,
  getAverageDiscountAmount,
  getOfferedOrdersAmount,
  getOfferedOrdersCount,
  getSalesCount,
  getDashboardData

};

function getTurnoverByMonth() {

  return new Promise((resolve, reject) => {

    axios.get(`/admin/turnover/months`)
      .then(({data}) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getRecurringTurnoverByMonth() {

  return new Promise((resolve, reject) => {

    axios.get(`/admin/turnover/recurring/months`)
      .then(({data}) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getTurnover(date) {

  return new Promise((resolve, reject) => {

    axios.get(`/admin/turnover/${date}`)
      .then(({data}) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getRecruitmentByMonth() {

  return new Promise((resolve, reject) => {

    axios.get(`/admin/recruitment/months`)
      .then(({data}) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getRecruitment() {

  return new Promise((resolve, reject) => {

    axios.get(`/admin/recruitment`)
      .then(({data}) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getPacksAmount(year, month = null) {

  return new Promise((resolve, reject) => {

    axios.get(`/admin/products/packs/amount/${year}${month ? `/${month}`: ''}`)
      .then(({data}) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getProductsAmount(year, month = null) {

  return new Promise((resolve, reject) => {

    axios.get(`/admin/products/amount/${year}${month ? `/${month}`: ''}`)
      .then(({data}) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getPacksAmountByMonth(year) {

  return new Promise((resolve, reject) => {

    axios.get(`/admin/products/packs/amount/${year}/months`)
      .then(({data}) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getBestSellersAmount(year, month) {

  return new Promise((resolve, reject) => {

    axios.get(`/admin/bestsellers/turnover/amount/${year}${month ? `/${month}`: ''}`)
      .then(({data}) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getBestRecruitersAmount(year, month) {

  return new Promise((resolve, reject) => {

    axios.get(`/admin/bestRecruiters/amount/${year}${month ? `/${month}`: ''}`)
      .then(({data}) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getOrdersCountByMonth() {

  return new Promise((resolve, reject) => {

    axios.get(`/admin/orders/count/months`)
      .then(({data}) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getOrders() {

  return new Promise((resolve, reject) => {

    axios.get(`/admin/orders`)
      .then(({orders}) => {
        resolve(orders);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getProsCountByMonth() {

  return new Promise((resolve, reject) => {

    axios.get(`/admin/pros/all/count/months`)
      .then(({data}) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getActiveProsCountByMonth() {

  return new Promise((resolve, reject) => {

    axios.get(`/admin/pros/active/count/months`)
      .then(({data}) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getTurnoverByCareer(year, month) {

  return new Promise((resolve, reject) => {

    axios.get(`/admin/turnover/career/${year}${month ? `/${month}`: ''}`)
      .then(({data}) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getAccountingNumbers(companyBased = 'all', year, month) {

  return new Promise((resolve, reject) => {

    axios.get(`/admin/company/${companyBased}/accounting/${year}${month ? `/${month}`: ''}/numbers`)
      .then(({data}) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getProsPerformances(year, month) {

  return new Promise((resolve, reject) => {

    axios.get(`/admin/pros/performances/${year}${month ? `/${month}`: ''}`)
      .then(({users}) => {
        resolve(users);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getRecruitsProsPerformances(year, month) {

  return new Promise((resolve, reject) => {

    axios.get(`/admin/pros/recruits/${year}/${month}/performances/`)
      .then(({users}) => {
        resolve(users);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getActiveProsByCareerRequest(year, month) {

  return new Promise((resolve, reject) => {

    axios.get(`/admin/pros/activity/${year}/${month}`)
      .then(({data}) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getStockSalesHistoriesByDates(id, start, end) {

  return new Promise((resolve, reject) => {

    axios.get(`/shop/admin/stocks/${id}/sales/histories/${start}/${end}`)
      .then(({histories}) => {
        resolve(histories);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getDirectSales(userId) {

  return new Promise((resolve, reject) => {

    axios.get(`/pro/user/${userId}/direct/sales`)
      .then(({data}) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getNetworkSales(userId) {

  return new Promise((resolve, reject) => {

    axios.get(`/pro/user/${userId}/network/sales`)
      .then(({data}) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getDirectRecurringSales(userId) {

  return new Promise((resolve, reject) => {

    axios.get(`/pro/user/${userId}/direct/sales/recurring`)
      .then(({data}) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getNetworkRecurringSales(userId) {

  return new Promise((resolve, reject) => {

    axios.get(`/pro/user/${userId}/network/sales/recurring`)
      .then(({data}) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getDirectRecruitment(userId) {

  return new Promise((resolve, reject) => {

    axios.get(`/pro/user/${userId}/direct/recruitment`)
      .then(({data}) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getNetworkRecruitment(userId) {

  return new Promise((resolve, reject) => {

    axios.get(`/pro/user/${userId}/network/recruitment`)
      .then(({data}) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getDirectPacksAmount(userId, year, month) {

  return new Promise((resolve, reject) => {

    axios.get(`/pro/user/${userId}/direct/packs/amount/${year}${month ? `/${month}`: ''}`)
      .then(({data}) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getDirectProductsAmount(userId, year, month) {

  return new Promise((resolve, reject) => {

    axios.get(`/pro/user/${userId}/direct/products/amount/${year}${month ? `/${month}`: ''}`)
      .then(({data}) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getNetworkPacksAmount(userId, year, month) {

  return new Promise((resolve, reject) => {

    axios.get(`/pro/user/${userId}/network/packs/amount/${year}${month ? `/${month}`: ''}`)
      .then(({data}) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getNetworkProductsAmount(userId, year, month) {

  return new Promise((resolve, reject) => {

    axios.get(`/pro/user/${userId}/network/products/amount/${year}${month ? `/${month}`: ''}`)
      .then(({data}) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getDirectBestSellersAmount(userId, year, month) {

  return new Promise((resolve, reject) => {

    axios.get(`/pro/user/${userId}/direct/bestsellers/turnover/amount/${year}${month ? `/${month}`: ''}`)
      .then(({data}) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getDirectBestRecruitersAmount(userId, year, month) {

  return new Promise((resolve, reject) => {

    axios.get(`/pro/user/${userId}/direct/bestRecruiters/amount/${year}${month ? `/${month}`: ''}`)
      .then(({data}) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getNetworkBestSellersAmount(userId, year, month) {

  return new Promise((resolve, reject) => {

    axios.get(`/pro/user/${userId}/network/bestsellers/turnover/amount/${year}${month ? `/${month}`: ''}`)
      .then(({data}) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getNetworkBestRecruitersAmount(userId, year, month) {

  return new Promise((resolve, reject) => {

    axios.get(`/pro/user/${userId}/network/bestRecruiters/amount/${year}${month ? `/${month}`: ''}`)
      .then(({data}) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getNetworkTurnoverByCareer(userId, year, month) {

  return new Promise((resolve, reject) => {

    axios.get(`/pro/user/${userId}/network/turnover/career/${year}${month ? `/${month}`: ''}`)
      .then(({data}) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getNetworkActiveProsCountByMonth(userId) {

  return new Promise((resolve, reject) => {

    axios.get(`/pro/user/${userId}/network/active/count/months`)
      .then(({data}) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getNetworkPerformances(userId, year, month) {

  return new Promise((resolve, reject) => {

    axios.get(`/pro/user/${userId}/network/performances/${year}${month ? `/${month}`: ''}`)
      .then(({users}) => {
        resolve(users);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

//Statistics

function getNetSales(start, end, country) {

  return new Promise((resolve, reject) => {

    axios.get(`/admin/statistics/netSales/${start}/${end}/${country}`)
      .then(({statistic}) => {
        resolve(statistic);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getSalesCount(start, end, country) {

  return new Promise((resolve, reject) => {

    axios.get(`/admin/statistics/salesCount/${start}/${end}/${country}`)
      .then(({statistic}) => {
        resolve(statistic);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getGrossSales(start, end, country) {

  return new Promise((resolve, reject) => {

    axios.get(`/admin/statistics/grossSales/${start}/${end}/${country}`)
      .then(({statistic}) => {
        resolve(statistic);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getAverageDiscountAmount(start, end, country) {

  return new Promise((resolve, reject) => {

    axios.get(`/admin/statistics/averageDiscountAmount/${start}/${end}/${country}`)
      .then(({statistic}) => {
        resolve(statistic);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getAverageDiscountPercent(start, end, country) {

  return new Promise((resolve, reject) => {

    axios.get(`/admin/statistics/averageDiscountPercent/${start}/${end}/${country}`)
      .then(({statistic}) => {
        resolve(statistic);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getOfferedOrdersAmount(start, end, country) {

  return new Promise((resolve, reject) => {

    axios.get(`/admin/statistics/offeredOrdersAmount/${start}/${end}/${country}`)
      .then(({statistic}) => {
        resolve(statistic);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getOfferedOrdersCount(start, end, country) {

  return new Promise((resolve, reject) => {

    axios.get(`/admin/statistics/offeredOrdersCount/${start}/${end}/${country}`)
      .then(({statistic}) => {
        resolve(statistic);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}*/

