import { createSelector } from 'reselect';

import { initialState, statsReducerKey } from "./reducer";

const statsSelector = (state) => state[statsReducerKey] || initialState;

export const formattedStatsSelector = (options) => createSelector(
  [statsSelector],
  (stats) => {
    const {section, key} = options;
    const selectedStats = stats.statisticsData[key];

    if(!selectedStats) return {};
    let formattedStats = {};
    switch(section){
      case "sales":
        formattedStats = {
          cahtva: 0,
          brands: [],
          pros : [],
          countries : [],
          delegates : [],
          categories : [],
          products : [],
          nrd : {cahtva : 0, proportion: 0},
          otherBrands : {cahtva : 0, proportion: 0},
          brandsPositions: ["NR&D"],
          prosPositions: [],
          countriesPositions: [],
          delegatesPositions: [],
          categoriesPositions: [],
          productsPositions: [],
        };
        selectedStats.map(data => {
          if(data.products){
            data.products.map(product => {
              if(!product.p) product.p = 0;

              formattedStats.cahtva += parseFloat(product.p);
              if(product.bn === 'NR&D') formattedStats.nrd.cahtva += parseFloat(product.p);
              else formattedStats.otherBrands.cahtva += parseFloat(product.p);

              let prod = formattedStats.products.find(p => p.reference === product.r);
              if(prod) {
                prod.totalOrders ++;
                prod.totalProducts += parseFloat(product.q);
                prod.cahtva += parseFloat(product.p);
              } else {
                formattedStats.products.push({
                  reference: product.r,
                  cahtva: parseFloat(product.p),
                  totalProducts: parseFloat(product.q),
                  totalOrders: 1,
                });
                formattedStats.productsPositions.push(product.r);
              }
              product.originalProduct.categories.map(category => {
                let cat = formattedStats.categories.find(c => c.reference === category.cn);
                if(cat) {
                  cat.totalOrders ++;
                  cat.cahtva += parseFloat(product.p);
                  cat.totalProducts += parseFloat(product.q);
                } else {
                  formattedStats.categories.push({
                    reference: category.cn,
                    cahtva: parseFloat(product.p),
                    totalProducts: parseFloat(product.q),
                    totalOrders: 1,
                  });
                  formattedStats.categoriesPositions.push(category.cn);
                }
              });
              // Brand calculations
              let brand = formattedStats.brands.find(b => b.reference === product.bn);
              if (brand) {
                brand.cahtva += parseFloat(product.p);
                brand.totalOrders ++;
                brand.totalProducts = (brand.totalProducts || 0) + parseFloat(product.q); // Sum up total products
              } else {
                formattedStats.brands.push({
                  reference: product.bn,
                  totalOrders: 1,
                  cahtva: parseFloat(product.p),
                  totalProducts: parseFloat(product.q)
                });
                formattedStats.brandsPositions.push(product.bn);
              }
              // Country calculations
              let country = formattedStats.countries.find(c => c.reference === data.c);
              if (country) {
                country.totalOrders ++;
                country.cahtva += parseFloat(product.p); // Sum up total order amount
                if(product.bn === 'NR&D') country.nrd.cahtva += parseFloat(product.p);
                else country.otherBrands.cahtva += parseFloat(product.p);
                // Brand calculations per country
                let countryBrand = country.brands.find(b => b.reference === product.bn);
                if (countryBrand) {
                  countryBrand.cahtva += parseFloat(product.p);
                } else {
                  country.brands.push({
                    reference: product.bn,
                    cahtva: parseFloat(product.p)
                  });
                }
              } else {
                formattedStats.countries.push({
                  brands: [{reference: product.bn, cahtva: parseFloat(product.p)}],
                  reference: data.c,
                  cahtva: parseFloat(product.p),
                  totalOrders: 1,
                  nrd: {cahtva : product.bn === "NR&D" ? parseFloat(product.p) : 0, proportion: 0},
                  otherBrands: {cahtva : product.bn === "NR&D" ? parseFloat(product.p) : 0, proportion: 0},
                });
                formattedStats.countriesPositions.push(data.c);
              }

              if(data.pro) {
                let pro = formattedStats.pros.find(p => p.id === data.pro.id);
                if (pro) {
                  pro.totalOrders ++;
                  if(product.bn === 'NR&D') pro.nrd.cahtva += parseFloat(product.p);
                  else pro.otherBrands.cahtva += parseFloat(product.p);
                  let proBrand = pro.brands.find(b => b.reference === product.bn);
                  if (proBrand) {
                    proBrand.cahtva += parseFloat(product.p);
                  } else {
                    pro.brands.push({
                      reference: product.bn,
                      cahtva: parseFloat(product.p)
                    });
                  }
                  pro.cahtva += parseFloat(product.p);
                } else {
                  formattedStats.pros.push({
                    id: data.pro.id,
                    firstName: data.pro.fn,
                    lastName: data.pro.ln,
                    delegates: data.pro.delegates,
                    cahtva: parseFloat(product.p),
                    referentId: data.pro.referent?.id,
                    referentFirstName: data.pro.referent?.fn,
                    brands: [{reference: product.bn, cahtva: parseFloat(product.p)}],
                    totalOrders: 1,
                    referentLastName: data.pro.referent?.ln,
                    nrd: {cahtva : product.bn === "NR&D" ? parseFloat(product.p) : 0, proportion: 0},
                    otherBrands: {cahtva : product.bn === "NR&D" ? parseFloat(product.p) : 0, proportion: 0},
                  });
                }

                //Delegate calculation
                data.pro.delegates.map(d => {
                  let delegate = formattedStats.delegates.find(del => d?.id === del?.id);

                  if (delegate) {
                    delegate.totalOrders ++;
                    delegate.cahtva += parseFloat(product.p);
                    if(product.bn === 'NR&D') delegate.nrd.cahtva += parseFloat(product.p);
                    else delegate.otherBrands.cahtva += parseFloat(product.p);
                    if(!delegate.prosIds.find(pid => pid === data.pro.id)){
                      delegate.prosIds.push(data.pro.id);
                      delegate.prosActiveThisMonth ++;
                    }
                    let delegateBrand = delegate.brands.find(b => b.reference === product.bn);
                    if (delegateBrand) {
                      delegateBrand.cahtva += parseFloat(product.p);
                    } else {
                      delegate.brands.push({
                        reference: product.bn,
                        cahtva: parseFloat(product.p)
                      });
                    }
                  } else {
                    formattedStats.delegates.push({
                      id: d.id,
                      firstName: d.fn,
                      lastName: d.ln,
                      cahtva: parseFloat(product.p),
                      prosIds: [data.pro.id],
                      prosActiveThisMonth: 1,
                      totalOrders: 1,
                      brands: [{reference: product.bn, cahtva: parseFloat(product.p)}],
                      nrd: {cahtva : product.bn === "NR&D" ? parseFloat(product.p) : 0, proportion: 0},
                      otherBrands: {cahtva : product.bn === "NR&D" ? parseFloat(product.p) : 0, proportion: 0},
                    });
                  }
                });
              }
            });
          }
        });

        formattedStats.nrd.proportion = (formattedStats.nrd.cahtva / formattedStats.cahtva) * 100;
        formattedStats.otherBrands.proportion = (formattedStats.otherBrands.cahtva / formattedStats.cahtva) * 100;

        formattedStats.brands.forEach(brand => {
          brand.proportion = (brand.cahtva / formattedStats.cahtva) * 100;
        });

        formattedStats.countries.forEach(country => {
          country.proportion = (country.cahtva / formattedStats.cahtva) * 100;
          country.averageOrderAmount = country.cahtva / country.totalOrders;
          country.nrd.proportion = (country.nrd.cahtva / formattedStats.cahtva) * 100;
          country.otherBrands.proportion = (country.otherBrands.cahtva / formattedStats.cahtva) * 100;

          // Calculate proportions for each brand in the country
          country.brands.forEach(countryBrand => {
            countryBrand.proportion = (countryBrand.cahtva / country.cahtva) * 100;
          });
        });
        formattedStats.delegates.forEach(delegate => {
          delegate.proportion = (delegate.cahtva / formattedStats.cahtva) * 100;
          delegate.averageOrderAmount = delegate.cahtva / delegate.totalOrders;
          delegate.nrd.proportion = (delegate.nrd.cahtva / formattedStats.cahtva) * 100;
          delegate.otherBrands.proportion = (delegate.otherBrands.cahtva / formattedStats.cahtva) * 100;

          // Calculate proportions for each brand in the country
          delegate.brands.forEach(delegateBrand => {
            delegateBrand.proportion = (delegateBrand.cahtva / delegate.cahtva) * 100;
          });
        });
        formattedStats.pros.forEach(pro => {
          pro.proportion = (pro.cahtva / formattedStats.cahtva) * 100;
          pro.averageOrderAmount = pro.cahtva / pro.totalOrders;
          pro.nrd.proportion = (pro.nrd.cahtva / formattedStats.cahtva) * 100;
          pro.otherBrands.proportion = (pro.otherBrands.cahtva / formattedStats.cahtva) * 100;

          // Calculate proportions for each brand in the country
          pro.brands.forEach(proBrand => {
            proBrand.proportion = (proBrand.cahtva / pro.cahtva) * 100;
          });
        });
        formattedStats.categories.forEach(category => {
          category.proportion = (category.cahtva / formattedStats.cahtva) * 100;
        });
        formattedStats.products.forEach(product => {
          product.proportion = (product.cahtva / formattedStats.cahtva) * 100;
        });
        break;
      default:
    }


    return formattedStats;
  });

export const statsDisplayedSelector = (section) => createSelector(
  [statsSelector],
  (stats) => {
    if(!section) return {
      sales: stats.statsDisplayed.sales,
      products: stats.statsDisplayed.products,
      pros: stats.statsDisplayed.pros,
      delegates: stats.statsDisplayed.delegates,
      countries: stats.statsDisplayed.countries,
      categories: stats.statsDisplayed.categories
    };
    return stats.statsDisplayed[section];
  });

export const sectionComparedSelector = () => createSelector(
  [statsSelector],
  (stats) => stats.sectionCompared);

export const selectDateModalOpenSelector = () => createSelector(
  [statsSelector],
  (stats) => stats.addCardOpen);

export const proNumberDisplayedSelector = () => createSelector(
  [statsSelector],
  (stats) => stats.proNumberDisplayed);



//Old Stats
/*


export const turnoverLinesGraphDataSelector = () => createSelector(
  [statsSelector],
  (stats) => {
    if(!stats.turnoverByMonth) {
      return null;
    }
    let data = [];
    Object.keys(stats.turnoverByMonth).map(year => {
      data.push({
        label: year,
        data: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(month => {
          if(moment().diff(`${year}-${`${month+1}`.padStart(2, '0')}-01`) > 0) {
            return stats.turnoverByMonth[year][month] ? stats.turnoverByMonth[year][month] : 0;
          }
        })
      });
    });
    return data;
  });

export const recurringTurnoverLinesGraphDataSelector = () => createSelector(
  [statsSelector],
  (stats) => {
    if(!stats.recurringTurnoverByMonth) {
      return null;
    }
    let data = [];
    Object.keys(stats.recurringTurnoverByMonth).map(year => {
      data.push({
        label: year,
        data: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(month => {
          if(moment().diff(`${year}-${`${month+1}`.padStart(2, '0')}-01`) > 0) {
            return stats.recurringTurnoverByMonth[year][month] ? stats.recurringTurnoverByMonth[year][month] : 0;
          }
        })
      });
    });
    return data;
  });

export const recurringTurnoverDataSelector = () => createSelector(
  [statsSelector],
  (stats) => {
    if(!stats.recurringTurnoverByMonth) {
      return {};
    }
    let year = 0;
    let month = 0;
    const currentYear = moment().year();
    if(stats.recurringTurnoverByMonth[currentYear]) {
      stats.recurringTurnoverByMonth[currentYear].map((amount, key) => {
        year += amount;
        if(moment().month() === key) {
          month += amount;
        }
      });
    }
    return {year, month};
  });

export const averageOrderAmountLinesGraphDataSelector = () => createSelector(
  [statsSelector],
  (stats) => {
    if(!stats.turnoverByMonth || !stats.ordersCountByMonth) {
      return null;
    }
    let data = [];
    Object.keys(stats.turnoverByMonth).map(year => {
      data.push({
        label: year,
        data: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(month => {
          if(moment().diff(`${year}-${`${month+1}`.padStart(2, '0')}-01`) > 0) {
            return stats.turnoverByMonth[year][month] && stats.ordersCountByMonth[year][month] ? Math.round(stats.turnoverByMonth[year][month] / stats.ordersCountByMonth[year][month]*100)/100 : 0;
          }
        })
      });
    });
    return data;
  });

export const averageTurnoverByActiveProsAmountLinesGraphDataSelector = () => createSelector(
  [statsSelector],
  (stats) => {
    if(!stats.turnoverByMonth || !stats.activeProsCountByMonth) {
      return null;
    }
    let data = [];
    Object.keys(stats.turnoverByMonth).map(year => {
      data.push({
        label: year,
        data: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(month => {
          if(moment().diff(`${year}-${`${month+1}`.padStart(2, '0')}-01`) > 0) {
            return stats.turnoverByMonth[year][month] && stats.activeProsCountByMonth[year][month] ? Math.round(stats.turnoverByMonth[year][month] / stats.activeProsCountByMonth[year][month]*100)/100 : 0;
          }
        })
      });
    });
    return data;
  });

export const averageTurnoverByActiveProsAmountSelector = () => createSelector(
  [statsSelector],
  (stats) => {
    let averageAmountByYear = {};
    if(!stats.turnoverByMonth || !stats.activeProsCountByMonth) {
      return averageAmountByYear;
    }
    let activeMonths = {};
    Object.keys(stats.turnoverByMonth).map(year => {
      [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(month => {
        if(moment().diff(`${year}-${`${month+1}`.padStart(2, '0')}-01`) > 0) {
          activeMonths[year] = activeMonths[year] ? activeMonths[year] : 0;
          averageAmountByYear[year] = averageAmountByYear[year] ? averageAmountByYear[year] : 0;
          if(stats.turnoverByMonth[year][month] && stats.activeProsCountByMonth[year][month]) {
            if(parseInt(year) === moment().year() && month === moment().month()) {
              activeMonths[year] += moment().date() / moment().daysInMonth();
            }
            else {
              activeMonths[year] += 1;
            }
            averageAmountByYear[year] += Math.round(stats.turnoverByMonth[year][month] / stats.activeProsCountByMonth[year][month] * 100) / 100;
          }
        }
      });
    });
    Object.keys(averageAmountByYear).map(year => {
      averageAmountByYear[year] = averageAmountByYear[year] / activeMonths[year];
    });
    return averageAmountByYear;
  });

export const networkAverageTurnoverByActiveProsAmountLinesGraphDataSelector = (userId) => createSelector(
  [statsSelector],
  (stats) => {
    if(!stats.networkTurnoverByMonth[userId] || !stats.networkActiveProsCountByMonth[userId]) {
      return null;
    }
    let data = [];
    Object.keys(stats.networkTurnoverByMonth[userId]).map(year => {
      data.push({
        label: year,
        data: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(month => {
          if(moment().diff(`${year}-${`${month+1}`.padStart(2, '0')}-01`) > 0) {
            return stats.networkTurnoverByMonth[userId][year][month] && stats.networkActiveProsCountByMonth[userId][year][month] ? Math.round(stats.networkTurnoverByMonth[userId][year][month] / stats.networkActiveProsCountByMonth[userId][year][month]*100)/100 : 0;
          }
        })
      });
    });
    return data;
  });

export const networkAverageTurnoverByActiveProsAmountSelector = (userId) => createSelector(
  [statsSelector],
  (stats) => {
    let averageAmountByYear = {};
    if(!stats.networkTurnoverByMonth[userId] || !stats.networkActiveProsCountByMonth[userId]) {
      return null;
    }
    let activeMonths = {};
    Object.keys(stats.networkTurnoverByMonth[userId]).map(year => {
      [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(month => {
        if(moment().diff(`${year}-${`${month+1}`.padStart(2, '0')}-01`) > 0) {
          activeMonths[year] = activeMonths[year] ? activeMonths[year] : 0;
          averageAmountByYear[year] = averageAmountByYear[year] ? averageAmountByYear[year] : 0;
          if(stats.networkTurnoverByMonth[userId][year][month] && stats.networkActiveProsCountByMonth[userId][year][month]) {
            if (parseInt(year) === moment().year() && month === moment().month()) {
              activeMonths[year] += moment().date() / moment().daysInMonth();
            } else {
              activeMonths[year] += 1;
            }
            averageAmountByYear[year] += Math.round(stats.networkTurnoverByMonth[userId][year][month] / stats.networkActiveProsCountByMonth[userId][year][month] * 100) / 100;
          }
        }
      });
    });
    Object.keys(averageAmountByYear).map(year => {
      averageAmountByYear[year] = averageAmountByYear[year] / activeMonths[year];
    });
    return averageAmountByYear;
  });

export const percentageActiveProsLinesGraphDataSelector = () => createSelector(
  [statsSelector],
  (stats) => {
    if(!stats.prosCountByMonth || !stats.activeProsCountByMonth) {
      return null;
    }
    let data = [];
    Object.keys(stats.prosCountByMonth).map(year => {
      data.push({
        label: year,
        data: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(month => {
          if(moment().diff(`${year}-${`${month+1}`.padStart(2, '0')}-01`) > 0) {
            return stats.prosCountByMonth[year][month] && stats.activeProsCountByMonth[year][month] ? Math.round(stats.activeProsCountByMonth[year][month]/stats.prosCountByMonth[year][month]*100) : 0;
          }
        })
      });
    });
    return data;
  });

export const turnoverDataSelector = () => createSelector(
  [statsSelector],
  (stats) => {
    if(!stats.turnoverByMonth) {
      return {};
    }
    let year = 0;
    let month = 0;
    const currentYear = moment().year();
    if(stats.turnoverByMonth[currentYear]) {
      stats.turnoverByMonth[currentYear].map((amount, key) => {
        year += amount;
        if(moment().month() === key) {
          month += amount;
        }
      });
    }
    return {year, month};
  });

export const averageOrderAmountDataSelector = () => createSelector(
  [statsSelector, turnoverDataSelector()],
  (stats, turnoverData) => {
    if(!turnoverData.year || !turnoverData.month || !stats.ordersCountByMonth) {
      return {};
    }
    let year = 0;
    let month = 0;
    const currentYear = moment().year();
    if(stats.ordersCountByMonth[currentYear]) {
      stats.ordersCountByMonth[currentYear].map((amount, key) => {
        year += amount;
        if(moment().month() === key) {
          month += amount;
        }
      });
    }
    return {year: turnoverData.year / year, month: turnoverData.month / month};
  });

export const recruitmentLinesGraphDataSelector = () => createSelector(
  [statsSelector],
  (stats) => {
    if(!stats.recruitmentByMonth) {
      return null;
    }
    let data = [];
    Object.keys(stats.recruitmentByMonth).map(year => {
      data.push({
        label: year,
        data: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(month => {
          if(moment().diff(`${year}-${`${month+1}`.padStart(2, '0')}-01`) > 0) {
            return stats.recruitmentByMonth[year][month] ? stats.recruitmentByMonth[year][month] : 0;
          }
        })
      });
    });
    return data;
  });

export const packsLinesGraphDataSelector = (year) => createSelector(
  [statsSelector],
  (stats) => {
    if(!stats.packsAmountByMonth) {
      return null;
    }
    let packsAmountByMonth = {};
    stats.packsAmountByMonth.map(pack => {
      packsAmountByMonth[pack.name] =
                packsAmountByMonth[pack.name]
                  ? {...packsAmountByMonth[pack.name], [pack.month]: pack.amount}
                  : {[pack.month]: pack.amount};
    });
    let data = [];
    Object.keys(packsAmountByMonth).map(name => {
      data.push({
        label: name,
        data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(month => {
          if(moment().year() !== parseInt(year) || moment().diff(`${moment().year()}-${`${month+1}`.padStart(2, '0')}-01`) > 0) {
            return packsAmountByMonth[name][month] ? packsAmountByMonth[name][month] : 0;
          }
        })
      });
    });
    return data;
  });

export const packsBarGraphDataSelector = () => createSelector(
  [statsSelector],
  (stats) => {
    if(!stats.packsAmount) {
      return {};
    }
    let labels = [];
    let data = [];
    stats.packsAmount.map(pack => {
      labels.push(pack.name);
      data.push(pack.amount);
    });
    return {labels, data};
  });

export const productsBarGraphDataSelector = () => createSelector(
  [statsSelector],
  (stats) => {
    if(!stats.productsAmount) {
      return {};
    }
    let labels = [];
    let data = [];
    stats.productsAmount.map(product => {
      labels.push(product.name);
      data.push(product.amount);
    });
    return {labels, data};
  });

export const turnoverByCareerDataSelector = () => createSelector(
  [statsSelector],
  (stats) => {
    if(!stats.turnoverByCareer) {
      return [];
    }
    return stats.turnoverByCareer;
  });

export const turnoverByCareerGraphDataSelector = () => createSelector(
  [statsSelector],
  (stats) => {
    if(!stats.turnoverByCareer) {
      return {};
    }
    let labels = [];
    let data = [];
    stats.turnoverByCareer.map(career => {
      labels.push(`${career.name}`);
      data.push(career.amount);
    });
    return {labels, data};
  });

export const bestSellersBarGraphDataSelector = () => createSelector(
  [statsSelector],
  (stats) => {
    if(!stats.bestSellersAmount) {
      return {};
    }
    let labels = [];
    let data = [];
    stats.bestSellersAmount.map(user => {
      labels.push(`${user.firstName} ${user.lastName}`);
      data.push(user.amount);
    });
    return {labels, data};
  });

export const bestRecruitersBarGraphDataSelector = () => createSelector(
  [statsSelector],
  (stats) => {
    if(!stats.bestRecruitersAmount) {
      return {};
    }
    let labels = [];
    let data = [];
    stats.bestRecruitersAmount.map(user => {
      labels.push(`${user.firstName} ${user.lastName}`);
      data.push(user.amount);
    });
    return {labels, data};
  });

export const directSalesLinesGraphDataSelector = (userId) => createSelector(
  [statsSelector],
  (stats) => {
    if(!stats.directTurnoverByMonth[userId]) {
      return null;
    }
    let data = [];
    Object.keys(stats.directTurnoverByMonth[userId]).map(year => {
      data.push({
        label: year,
        data: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(month => {
          if(moment().diff(`${year}-${`${month+1}`.padStart(2, '0')}-01`) > 0) {
            return stats.directTurnoverByMonth[userId][year][month] ? stats.directTurnoverByMonth[userId][year][month] : 0;
          }
        })
      });
    });
    return data;
  });

export const directRecurringSalesLinesGraphDataSelector = (userId) => createSelector(
  [statsSelector],
  (stats) => {
    if(!stats.directRecurringTurnoverByMonth[userId]) {
      return null;
    }
    let data = [];
    Object.keys(stats.directRecurringTurnoverByMonth[userId]).map(year => {
      data.push({
        label: year,
        data: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(month => {
          if(moment().diff(`${year}-${`${month+1}`.padStart(2, '0')}-01`) > 0) {
            return stats.directRecurringTurnoverByMonth[userId][year][month] ? stats.directRecurringTurnoverByMonth[userId][year][month] : 0;
          }
        })
      });
    });
    return data;
  });

export const directTurnoverDataSelector = (userId) => createSelector(
  [statsSelector],
  (stats) => {
    let year = 0;
    let month = 0;
    if(stats.directTurnoverByMonth[userId]) {

      const currentYear = moment().year();
      if(stats.directTurnoverByMonth[userId][currentYear]) {
        stats.directTurnoverByMonth[userId][currentYear].map((amount, key) => {
          year += amount;
          if(moment().month() === key) {
            month += amount;
          }
        });
      }
    }
    return {year, month};
  });

export const directRecurringTurnoverDataSelector = (userId) => createSelector(
  [statsSelector],
  (stats) => {
    let year = 0;
    let month = 0;
    if(stats.directRecurringTurnoverByMonth[userId]) {

      const currentYear = moment().year();
      if(stats.directRecurringTurnoverByMonth[userId][currentYear]) {
        stats.directRecurringTurnoverByMonth[userId][currentYear].map((amount, key) => {
          year += amount;
          if(moment().month() === key) {
            month += amount;
          }
        });
      }
    }
    return {year, month};
  });

export const directRecruitmentDataSelector = (userId) => createSelector(
  [statsSelector],
  (stats) => {
    let year = 0;
    let month = 0;
    if(stats.directRecruitment[userId]) {

      const currentYear = moment().year();
      if(stats.directRecruitment[userId][currentYear]) {
        stats.directRecruitment[userId][currentYear].map((amount, key) => {
          year += amount;
          if(moment().month() === key) {
            month += amount;
          }
        });
      }
    }
    return {year, month};
  });

export const networkTurnoverDataSelector = (userId) => createSelector(
  [statsSelector],
  (stats) => {
    let year = 0;
    let month = 0;
    if(stats.networkTurnoverByMonth[userId]) {

      const currentYear = moment().year();
      if(stats.networkTurnoverByMonth[userId][currentYear]) {
        stats.networkTurnoverByMonth[userId][currentYear].map((amount, key) => {
          year += amount;
          if(moment().month() === key) {
            month += amount;
          }
        });
      }
    }
    return {year, month};
  });

export const networkRecurringTurnoverDataSelector = (userId) => createSelector(
  [statsSelector],
  (stats) => {
    let year = 0;
    let month = 0;
    if(stats.networkRecurringSales[userId]) {

      const currentYear = moment().year();
      if(stats.networkRecurringSales[userId][currentYear]) {
        stats.networkRecurringSales[userId][currentYear].map((amount, key) => {
          year += amount;
          if(moment().month() === key) {
            month += amount;
          }
        });
      }
    }
    return {year, month};
  });

export const networkRecruitmentDataSelector = (userId) => createSelector(
  [statsSelector],
  (stats) => {
    let year = 0;
    let month = 0;
    if(stats.networkRecruitment[userId]) {

      const currentYear = moment().year();
      if(stats.networkRecruitment[userId][currentYear]) {
        stats.networkRecruitment[userId][currentYear].map((amount, key) => {
          year += amount;
          if(moment().month() === key) {
            month += amount;
          }
        });
      }
    }
    return {year, month};
  });

export const networkSalesLinesGraphDataSelector = (userId) => createSelector(
  [statsSelector],
  (stats) => {
    if(!stats.networkTurnoverByMonth[userId]) {
      return null;
    }
    let data = [];
    Object.keys(stats.networkTurnoverByMonth[userId]).map(year => {
      data.push({
        label: year,
        data: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(month => {
          if(moment().diff(`${year}-${`${month+1}`.padStart(2, '0')}-01`) > 0) {
            return stats.networkTurnoverByMonth[userId][year][month] ? stats.networkTurnoverByMonth[userId][year][month] : 0;
          }
        })
      });
    });
    return data;
  });

export const networkRecurringSalesLinesGraphDataSelector = (userId) => createSelector(
  [statsSelector],
  (stats) => {
    if(!stats.networkRecurringSales[userId]) {
      return null;
    }
    let data = [];
    Object.keys(stats.networkRecurringSales[userId]).map(year => {
      data.push({
        label: year,
        data: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(month => {
          if(moment().diff(`${year}-${`${month+1}`.padStart(2, '0')}-01`) > 0) {
            return stats.networkRecurringSales[userId][year][month] ? stats.networkRecurringSales[userId][year][month] : 0;
          }
        })
      });
    });
    return data;
  });

export const directRecruitmentLinesGraphDataSelector = (userId) => createSelector(
  [statsSelector],
  (stats) => {
    if(!stats.directRecruitment[userId]) {
      return null;
    }
    let data = [];
    Object.keys(stats.directRecruitment[userId]).map(year => {
      data.push({
        label: year,
        data: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(month => {
          if(moment().diff(`${year}-${month+1}-01`) > 0) {
            return stats.directRecruitment[userId][year][month] ? stats.directRecruitment[userId][year][month] : 0;
          }
        })
      });
    });
    return data;
  });

export const networkRecruitmentLinesGraphDataSelector = (userId) => createSelector(
  [statsSelector],
  (stats) => {
    if(!stats.networkRecruitment[userId]) {
      return null;
    }
    let data = [];
    Object.keys(stats.networkRecruitment[userId]).map(year => {
      data.push({
        label: year,
        data: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(month => {
          if(moment().diff(`${year}-${month+1}-01`) > 0) {
            return stats.networkRecruitment[userId][year][month] ? stats.networkRecruitment[userId][year][month] : 0;
          }
        })
      });
    });
    return data;
  });

export const directBestSellersBarGraphDataSelector = (userId, year, month) => createSelector(
  [statsSelector],
  (stats) => {
    if(!stats.directBestSellersAmount[userId] || !stats.directBestSellersAmount[userId][year] || !stats.directBestSellersAmount[userId][year][month]) {
      return null;
    }
    let labels = [];
    let data = [];
    stats.directBestSellersAmount[userId][year][month].map(user => {
      labels.push(`${user.firstName} ${user.lastName}`);
      data.push(user.amount);
    });
    return {labels, data};
  });

export const directBestRecruitersBarGraphDataSelector = (userId, year, month) => createSelector(
  [statsSelector],
  (stats) => {
    if(!stats.directBestRecruitersAmount[userId] || !stats.directBestRecruitersAmount[userId][year] || !stats.directBestRecruitersAmount[userId][year][month]) {
      return null;
    }
    let labels = [];
    let data = [];
    stats.directBestRecruitersAmount[userId][year][month].map(user => {
      labels.push(`${user.firstName} ${user.lastName}`);
      data.push(user.amount);
    });
    return {labels, data};
  });


export const networkBestSellersBarGraphDataSelector = (userId, year, month) => createSelector(
  [statsSelector],
  (stats) => {
    if(!stats.networkBestSellersAmount[userId] || !stats.networkBestSellersAmount[userId][year] || !stats.networkBestSellersAmount[userId][year][month]) {
      return null;
    }
    let labels = [];
    let data = [];
    stats.networkBestSellersAmount[userId][year][month].map(user => {
      labels.push(`${user.firstName} ${user.lastName}`);
      data.push(user.amount);
    });
    return {labels, data};
  });

export const networkBestRecruitersBarGraphDataSelector = (userId, year, month) => createSelector(
  [statsSelector],
  (stats) => {
    if(!stats.networkBestRecruitersAmount[userId] || !stats.networkBestRecruitersAmount[userId][year] || !stats.networkBestRecruitersAmount[userId][year][month]) {
      return null;
    }
    let labels = [];
    let data = [];
    stats.networkBestRecruitersAmount[userId][year][month].map(user => {
      labels.push(`${user.firstName} ${user.lastName}`);
      data.push(user.amount);
    });
    return {labels, data};
  });

export const directPacksAmountBarGraphDataSelector = (userId, year, month) => createSelector(
  [statsSelector],
  (stats) => {
    if(!stats.directPacksAmount[userId] || !stats.directPacksAmount[userId][year] || !stats.directPacksAmount[userId][year][month]) {
      return null;
    }
    let labels = [];
    let data = [];
    stats.directPacksAmount[userId][year][month].map(pack => {
      labels.push(pack.name);
      data.push(pack.amount);
    });
    return {labels, data};
  });

export const directProductsAmountBarGraphDataSelector = (userId, year, month) => createSelector(
  [statsSelector],
  (stats) => {
    if(!stats.directProductsAmount[userId] || !stats.directProductsAmount[userId][year] || !stats.directProductsAmount[userId][year][month]) {
      return null;
    }
    let labels = [];
    let data = [];
    stats.directProductsAmount[userId][year][month].map(pack => {
      labels.push(pack.name);
      data.push(pack.amount);
    });
    return {labels, data};
  });

export const networkPacksAmountBarGraphDataSelector = (userId, year, month) => createSelector(
  [statsSelector],
  (stats) => {
    if(!stats.networkPacksAmount[userId] || !stats.networkPacksAmount[userId][year] || !stats.networkPacksAmount[userId][year][month]) {
      return null;
    }
    let labels = [];
    let data = [];
    stats.networkPacksAmount[userId][year][month].map(pack => {
      labels.push(pack.name);
      data.push(pack.amount);
    });
    return {labels, data};
  });

export const networkProductsAmountBarGraphDataSelector = (userId, year, month) => createSelector(
  [statsSelector],
  (stats) => {
    if(!stats.networkProductsAmount[userId] || !stats.networkProductsAmount[userId][year] || !stats.networkProductsAmount[userId][year][month]) {
      return null;
    }
    let labels = [];
    let data = [];
    stats.networkProductsAmount[userId][year][month].map(pack => {
      labels.push(pack.name);
      data.push(pack.amount);
    });
    return {labels, data};
  });

export const networkTurnoverByCareerDataSelector = (userId, year, month) => createSelector(
  [statsSelector],
  (stats) => {
    if(!stats.networkTurnoverByCareer[userId] || !stats.networkTurnoverByCareer[userId][year] || !stats.networkTurnoverByCareer[userId][year][month]) {
      return [];
    }
    return stats.networkTurnoverByCareer[userId][year][month];
  });

export const networkTurnoverByCareerGraphDataSelector = (userId, year, month) => createSelector(
  [statsSelector],
  (stats) => {
    if(!stats.networkTurnoverByCareer[userId] || !stats.networkTurnoverByCareer[userId][year] || !stats.networkTurnoverByCareer[userId][year][month]) {
      return [];
    }
    let labels = [];
    let data = [];
    stats.networkTurnoverByCareer[userId][year][month].map(career => {
      labels.push(`${career.name}`);
      data.push(career.amount);
    });
    return {labels, data};
  });*/
