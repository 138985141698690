import React, {useEffect} from 'react';
import ReactDOM from 'react-dom';
import {HelmetProvider} from 'react-helmet-async';
import {Provider} from "react-redux";
import loadable from "@loadable/component";
import {createGenerateClassName, StylesProvider} from "@mui/styles";
import {ConnectedRouter} from 'connected-react-router';

import configureStore, {history, hydrateState} from "./helpers/store";
import App from './App';
import * as serviceWorker from './serviceWorker';


const SnackbarProvider = loadable(() => import("./helpers/snackbar"));

const Root = () => {

  // Grab the state from a global variable injected into the server-generated HTML
  let preloadedState = window.__PRELOADED_STATE__;

  // Allow the passed state to be garbage-collected
  delete window.__PRELOADED_STATE__;

  const store = configureStore(hydrateState(preloadedState));

  const generateClassName = createGenerateClassName({
    disableGlobal: false,
    seed: 'jss'
  });

  useEffect(() => {
    const jssStyles = document.getElementById('jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  return (
    <React.StrictMode>
      <Provider store={store}>
        <SnackbarProvider/>
        <ConnectedRouter history={history}>
          <StylesProvider generateClassName={generateClassName}>
            <HelmetProvider>
              <App/>
            </HelmetProvider>
          </StylesProvider>
        </ConnectedRouter>
      </Provider>
    </React.StrictMode>
  );
};

ReactDOM.hydrate(
  <Root/>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
