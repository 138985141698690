import * as actionsType from './constants';

export const menuActions = {
  toggleMenu,
};

export function toggleMenu() {
  return {
    type: actionsType.TOGGLE_MENU,
  };
}
