import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import LinearProgress from "@mui/material/LinearProgress";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import {makeStyles} from '@mui/styles';

import LineChartByYearAndMonth from "../../../../components/Charts/LineChartByYearAndMonth";
import { proStatsActions } from "../store/actions";
import {
  networkRecruitmentDataSelector,
  networkRecruitmentLinesGraphDataSelector,
  networkRecurringSalesLinesGraphDataSelector,
  networkRecurringTurnoverDataSelector,
  networkSalesLinesGraphDataSelector,
  networkTurnoverDataSelector
} from "../store/selectors";

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiSelect-select': {
      fontSize: '1.5rem',
      lineHeight: '1.8rem',
      paddingTop: 10,
      paddingBottom: 10,
      maxWidth: '70vw'
    },
    height: '100%',
    '& .MuiCardHeader-root': {
      '& .MuiCardHeader-content': {
        height: 81
      },
      '& .MuiInput-root': {
        '& .MuiInputBase-input': {
          textAlign: 'right'
        }
      }
    }
  },
  menuItem: {
    fontSize: '1.5rem',
    lineHeight: '1.8rem',
  },
  loader: {
    height: 290
  }
}));
const NetworkCharts = ({user}) => {

  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();
  const [view, setView] = useState("networkSales");
  const networkSalesLinesGraphData = useSelector(networkSalesLinesGraphDataSelector(user.id));
  const networkRecurringSalesLinesGraphData = useSelector(networkRecurringSalesLinesGraphDataSelector(user.id));
  const networkRecruitmentLinesGraphData = useSelector(networkRecruitmentLinesGraphDataSelector(user.id));
  const networkTurnoverData = useSelector(networkTurnoverDataSelector(user.id));
  const networkRecurringTurnoverData = useSelector(networkRecurringTurnoverDataSelector(user.id));
  const networkRecruitmentData = useSelector(networkRecruitmentDataSelector(user.id));

  useEffect(() => {
    switch (view) {
      case "networkRecruitments":
        if(!networkRecruitmentLinesGraphData) {
          dispatch(proStatsActions.getNetworkRecruitmentRequest(user.id));
        }
        break;
      case "networkRecurringSales":
        if(!networkRecruitmentLinesGraphData) {
          dispatch(proStatsActions.getNetworkRecurringSalesRequest(user.id));
        }
        break;
      case "networkSales":
      default:
        if(!networkSalesLinesGraphData) {
          dispatch(proStatsActions.getNetworkSalesRequest(user.id));
        }
    }
  }, [view]);

  const handleChangeView = (e) => {
    setView(e.target.value);
  };

  const getValues = () => {
    switch (view) {
      case "networkRecruitments":
        if(!networkRecruitmentLinesGraphData) {
          return null;
        }
        return {
          data: networkRecruitmentLinesGraphData,
          year: `${Math.round(networkRecruitmentData.year*100)/100}`,
          month: `${Math.round(networkRecruitmentData.month*100)/100}`,
        };
      case "networkRecurringSales":
        if(!networkRecurringSalesLinesGraphData) {
          return null;
        }
        return {
          data: networkRecurringSalesLinesGraphData,
          year: `€ ${Math.round(networkRecurringTurnoverData.year*100)/100}`,
          month: `€ ${Math.round(networkRecurringTurnoverData.month*100)/100}`,
        };
      case "networkSales":
      default:
        if(!networkSalesLinesGraphData) {
          return null;
        }
        return {
          data: networkSalesLinesGraphData,
          year: `€ ${Math.round(networkTurnoverData.year*100)/100}`,
          month: `€ ${Math.round(networkTurnoverData.month*100)/100}`,
        };
    }
  };

  return (
    <Card className={classes.root}>
      <CardHeader title={
        <Select defaultValue={view} variant={"outlined"} onChange={handleChangeView}>
          <MenuItem className={classes.menuItem} value="networkSales">
            {t("networkSales")}
          </MenuItem>
          <MenuItem className={classes.menuItem} value="networkRecurringSales">
            {t("networkRecurringSales")}
          </MenuItem>
          <MenuItem className={classes.menuItem} value="networkRecruitments">
            {t("networkRecruitments")}
          </MenuItem>
        </Select>
      }/>
      <Divider/>
      {!getValues() && (
        <Grid className={classes.loader} container alignItems={"center"} justify={"center"}>
          <Grid item xs={6} sm={4}>
            <Typography align={"center"}><Trans i18nKey={"loading"}>Loading</Trans> ...</Typography>
            <LinearProgress color="secondary" />
          </Grid>
        </Grid>
      )}
      {getValues() && (
        <>
          <CardContent>
            <LineChartByYearAndMonth
              data={getValues().data}
              heightLg={350}
              heightMd={300}
              heightSm={300}
              primaryColor={{
                border: 'rgb(89,80,236)',
                background: 'rgba(89,80,236, 0.04)'
              }}
            />
          </CardContent>

          <Grid container>
            <Grid item xs={6}>
              <Typography component={"div"} align={"center"} gutterBottom>
                <Typography>
                  <Trans i18nKey={"thisYear"}>This year</Trans>
                </Typography>
                <Typography variant={"h6"}>{getValues().year}</Typography>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography component={"div"} align={"center"} gutterBottom>
                <Typography>
                  <Trans i18nKey={"thisMonth"}>This month</Trans>
                </Typography>
                <Typography variant={"h6"}>{getValues().month}</Typography>
              </Typography>
            </Grid>
          </Grid>
        </>
      )}
    </Card>
  );
};
export default NetworkCharts;
