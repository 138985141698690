import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useLocation} from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import Container from "@mui/material/Container";
import Drawer from "@mui/material/Drawer";
import {makeStyles} from '@mui/styles';

import {shopActions} from "../store/actions";
import {isResponseCheckoutOpenSelector} from "../store/selectors";

import PaymentResponse from "./components/PaymentResponse";

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: '100vh',
    paddingBottom: 40,
  },
  closeButton: {
    top: 8,
    right: 16,
    fontSize: 26,
    cursor: 'pointer',
    position: 'absolute',
    color: '#7C909B',
    background: '#ffffff',
    borderRadius: '50%',
    boxShadow: '12px 12px 24px #00000021',
    padding: '4px',
  },
  pageTitle: {
    paddingTop: 50,
    paddingBottom: 30,
    '& > h1': {
      fontWeight: 'bold',
      marginBottom: 5
    },
    [theme.breakpoints.down('sm')]: {
      //paddingTop: 0,
    },
  }
}));

const CheckoutResponseDrawer = () => {

  const classes = useStyles();
  const dispatch = useDispatch();
  const open = useSelector(isResponseCheckoutOpenSelector);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const orderId = searchParams.get('checkout_oid');

  useEffect(() => {
    if(orderId) dispatch(shopActions.SetDrawersOpen("response", true));
  }, [orderId]);

  const handleCloseDrawer = () => {
    dispatch(shopActions.SetDrawersOpen("response", false));
  };

  return (
    <Drawer className={classes.root} anchor={'right'} open={open} onClose={() => handleCloseDrawer()}>
      <CloseIcon className={classes.closeButton} onClick={handleCloseDrawer}/>
      <Container maxWidth="sm" sx={{
        maxWidth: {mw: '100%', sm: '520px', md: '520px'},
      }}>
        {orderId && open && (<PaymentResponse orderId={orderId}/>)}
      </Container>
    </Drawer>
  );
};

export default CheckoutResponseDrawer;
