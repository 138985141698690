import React from "react";
import {useTranslation} from "react-i18next";
import {Trans} from "react-i18next";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import {makeStyles} from '@mui/styles';

import MapInfoWindow from "../../../../../components/GoogleMap/MapInfoWindow";

const useStyles = makeStyles(theme => ({
  root: {
    maxHeight: 300,
    width: 260,
    '& .name': {

    },
    '& .address': {
      fontSize: 13,
      lineHeight: '16px'
    },
    '& .selectButton': {
      fontSize: 13,
      margin: theme.spacing(1, 0)
    },
    '& .openingHours': {
      display: 'flex',
    },
    '& .day': {
      fontSize: 12,
      fontWeight: 'bold',
      width: '33%'
    },
    '& .hour': {
      fontSize: 12,
      width: '33%'
    },
    '& .right': {
      textAlign: 'right'
    },
    '& .closedFrom': {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      fontSize: 14,
      fontWeight: 'bold'
    },
  },
}));

const PickupPointInfoWindow = ({pickupPoint, onSelect}) => {

  const {t} = useTranslation();
  const classes = useStyles();

  const handleSelect = (location) => () => {
    onSelect(location);
  };

  if(!pickupPoint) {
    return null;
  }


  return (
    <MapInfoWindow
      zIndex={100}
      pixelOffsetY={-35}
      position={{
        lat:pickupPoint.latitude,
        lng:pickupPoint.longitude
      }}
    >
      <Card className={classes.root}>
        <CardContent>
          <Typography className={"name"} gutterBottom variant="subtitle2" component="h2">
            {pickupPoint.name}
          </Typography>
          <Typography className={"address"} gutterBottom>
            {pickupPoint.country === 'BE' ? `${pickupPoint.street} ${pickupPoint.number}` : `${pickupPoint.number} ${pickupPoint.street}`},<br/>{pickupPoint.zip} {pickupPoint.city}
          </Typography>
          <Button onClick={handleSelect(pickupPoint)} className={"selectButton"} variant={"contained"} color={"primary"} size={"small"}>
            <Trans i18nKey={"select"}>Select</Trans>
          </Button>
          <div>
            {pickupPoint.hours.map(day => (
              <div className={"openingHours"}>
                <Typography component={"span"} className={"day"}>{t(day.title)}</Typography>
                {day.AMOpen && day.PMClose && !day.AMClose && !day.PMOpen && (
                  <Typography component={"span"} className={"hour"}>{day.AMOpen} - {day.PMClose}</Typography>
                )}
                {day.AMOpen && day.AMClose && (
                  <Typography component={"span"} className={"hour"}>{day.AMOpen} - {day.AMClose}</Typography>
                )}
                {day.PMOpen && day.PMClose && (
                  <Typography component={"span"} className={"hour right"}>{day.PMOpen} - {day.PMClose}</Typography>
                )}
              </div>
            ))}
          </div>
          {(pickupPoint.closedFrom || pickupPoint.closedTo) && (
            <Typography className={"closedFrom"} color={"error"}>
              <Trans i18nKey={"closingFromTo"} values={{closedFrom: pickupPoint.closedFrom, closedTo: pickupPoint.closedTo}}>
                                    Closed from {pickupPoint.closedFrom} to {pickupPoint.closedTo}
              </Trans>
            </Typography>
          )}
        </CardContent>
      </Card>
    </MapInfoWindow>
  );
};

export default PickupPointInfoWindow;
