import React, {useEffect} from "react";
import { Helmet } from "react-helmet-async";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {makeStyles} from "@mui/styles";

import TopBrands from "../Shop/components/TopBrands";
import TopCategories from "../Shop/components/TopCategories";
import TopSalesProducts from "../Shop/components/TopSalesProducts";

import CustomSection from "./components/CustomSection";
import CustomSectionProBanner from "./components/CustomSectionProBanner";
import CustomSectionSupportBanner from "./components/CustomSectionSupportBanner";
import CustomSectionTeamBanner from "./components/CustomSectionTeamBanner";
import NewsletterForm from "./components/NewsletterForm";
import TabsSlider from "./components/TabsSlider";
import {homeActions} from "./store/actions";
import {sectionsSelector} from "./store/selectors";

const useStyles = makeStyles(() => ({
  root: {
    paddingBottom: 40,
    '& .menuCard': {
      textDecoration: 'none',
      '&:hover': {
        '& .MuiCard-root': {
          boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)'
        }
      },
      '& .MuiCardContent-root': {
        height: '35%'
      },
      '& .MuiCard-root': {
        height: '100%',
        borderRadius: 10,
        '& button': {
          height: '100%',
        },
        '& .MuiTypography-h5': {},
        '& .MuiTypography-body2': {
          fontSize: 16
        }
      }
    },
  },
  title: {
    display: "block",
    paddingTop: 70,
    paddingBottom: 50,
    '& i': {
      fontSize: 26
    }
  },
}));

const HomePage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {i18n} = useTranslation();
  const { t } = useTranslation();
  const sections = useSelector(sectionsSelector);

  useEffect(() => {
    dispatch(homeActions.getSectionsRequest(i18n.language));
  }, [i18n.language]);

  return (
    <div className={classes.root}>
      <Helmet>
        <title itemProp={"name"}>Nutri-logics</title>
        <meta property="og:title" content={"Nutri-logics"} />
        <meta name="description" content={t("homeMetaDescription")} />
        <link rel="alternate" href={`${process.env.REACT_APP_WEB_URL}`} hrefLang="fr" key="fr"/>
        <link rel="alternate" href={`${process.env.REACT_APP_WEB_URL}/en`} hrefLang="en" key="en"/>
        <link rel="alternate" href={`${process.env.REACT_APP_WEB_URL}`} hrefLang="x-default"/>
      </Helmet>

      <TabsSlider/>

      {sections?.custom1?.name && (
        <CustomSection section={sections?.custom1}/>
      )}

      {sections?.custom2?.name && (
        <CustomSection section={sections?.custom2}/>
      )}

      <TopSalesProducts />

      {sections?.custom3?.name && (
        <CustomSection section={sections?.custom3}/>
      )}

      <TopCategories/>

      <TopBrands/>

      <CustomSectionTeamBanner />

      <CustomSectionProBanner />

      <CustomSectionSupportBanner />

      <NewsletterForm />

    </div>
  );
};

export default HomePage;
