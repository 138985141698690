import * as actionsType from './constants';

export const googleMapActions = {
  loadGoogleMapAPISuccess,
  loadGoogleMapAPIError,
};

export function loadGoogleMapAPISuccess() {

  return {
    type: actionsType.GOOGLE_MAP_LOADED_SUCCESS
  };
}

export function loadGoogleMapAPIError(error) {

  return {
    type: actionsType.GOOGLE_MAP_LOADED_ERROR,
    error
  };
}