import React from "react";
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import MenuIcon from '@mui/icons-material/Menu';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import SearchIcon from "@mui/icons-material/Search";
import {AppBar, Box, Stack, Toolbar} from "@mui/material";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";

import {Link} from "../../../helpers/multilingual";

import MenuDrawer from "./MenuDrawer";
import SearchDrawer from "./SearchDrawer";

const drawerWidth = 240;

export default function Header() {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [searchDrawerOpen, setSearchDrawerOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          width: { md: `calc(100% - ${drawerWidth}px)` },
          ml: { md: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'block', md: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Stack direction={"row"} gap={2}>
            <IconButton variant="text" onClick={() => setSearchDrawerOpen(true)}>
              <SearchIcon/>
            </IconButton>
            <IconButton variant="text" component={Link} to={'/admin/users/add'}>
              <PersonAddAlt1Icon/>
            </IconButton>
            <IconButton variant="text" component={Link} to={`/admin/users/order/add`}>
              <AddShoppingCartIcon/>
            </IconButton>
            <IconButton component={Link} to={`/`}>
              <ExitToAppIcon/>
            </IconButton>
          </Stack>
          <SearchDrawer searchDrawerOpen={searchDrawerOpen} setSearchDrawerOpen={setSearchDrawerOpen}/>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'block', md: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          <MenuDrawer setMobileOpen={setMobileOpen}/>
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'none', md: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          <MenuDrawer/>
        </Drawer>
      </Box>
    </>
  );
}