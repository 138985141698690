import React  from "react";
import {useSelector} from "react-redux";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import {Box} from "@mui/material";
import IconButton from "@mui/material/IconButton";

import {
  sessionSelector
} from "../../../containers/Customer/Shop/store/selectors";
import { Link } from "../../../helpers/multilingual";


const FavoriteIcon = () => {
  const session = useSelector(sessionSelector);

  return (
    <Box>
      {session && (
        <IconButton component={Link} to={`/shop/favorites`}>
          <FavoriteBorderIcon fontSize={"small"}/>
        </IconButton>
      )}
    </Box>
  );
};

export default FavoriteIcon;
