import axios  from '../../../../helpers/axios';

export const proStatsService = {
  getDirectSales,
  getNetworkSales,
  getDirectRecurringSales,
  getNetworkRecurringSales,
  getDirectRecruitment,
  getNetworkRecruitment,
  getNetworkPerformances,
  getTreeCountChallengeRequest,
  getChallengeRecruitmentData,
  getDirectBestSellersAmount,
  getDirectBestRecruitersAmount,
  getNetworkBestSellersAmount,
  getNetworkBestRecruitersAmount,
  getNetworkPacksAmount,
  getNetworkProductsAmount,
  getNetworkTurnoverByCareer,
  getDirectPacksAmount,
  getDirectProductsAmount,
  getNetworkActiveProsCountByMonth,
  getStarterBonus1,
  getStarterBonus1Validate,
  getStarterBonus2,
  getStarterBonus2Validate,
  getStarterBonus3,
  getStarterBonus3Validate,
};

function getDirectSales(userId) {

  return new Promise((resolve, reject) => {

    axios.get(`/pro/user/${userId}/direct/sales`)
      .then(({data}) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getNetworkSales(userId) {

  return new Promise((resolve, reject) => {

    axios.get(`/pro/user/${userId}/network/sales`)
      .then(({data}) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getDirectRecurringSales(userId) {

  return new Promise((resolve, reject) => {

    axios.get(`/pro/user/${userId}/direct/sales/recurring`)
      .then(({data}) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getNetworkRecurringSales(userId) {

  return new Promise((resolve, reject) => {

    axios.get(`/pro/user/${userId}/network/sales/recurring`)
      .then(({data}) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getDirectRecruitment(userId) {

  return new Promise((resolve, reject) => {

    axios.get(`/pro/user/${userId}/direct/recruitment`)
      .then(({data}) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getNetworkRecruitment(userId) {

  return new Promise((resolve, reject) => {

    axios.get(`/pro/user/${userId}/network/recruitment`)
      .then(({data}) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getNetworkPerformances(userId, year, month) {

  return new Promise((resolve, reject) => {

    axios.get(`/pro/user/${userId}/network/performances/${year}${month ? `/${month}`: ''}`)
      .then(({users}) => {
        resolve(users);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getTreeCountChallengeRequest() {

  return new Promise((resolve, reject) => {

    axios.get(`/pro/challenge/turnover/february`)
      .then(({data}) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getChallengeRecruitmentData(userId, start, end) {

  return new Promise((resolve, reject) => {

    axios.get(`/pro/challenge/recruitment/${start}/${end}`)
      .then(({data}) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getStarterBonus1() {

  return new Promise((resolve, reject) => {

    axios.get(`/pro/bonus/starter/1`)
      .then(({turnover, bonusClaimed}) => {
        resolve({turnover, bonusClaimed});
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getStarterBonus1Validate(shippingAddressId) {

  return new Promise((resolve, reject) => {

    axios.post(`/pro/bonus/starter/1/validate`, {shippingAddressId})
      .then(() => {
        resolve();
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getStarterBonus2() {

  return new Promise((resolve, reject) => {

    axios.get(`/pro/bonus/starter/2`)
      .then(({turnover, bonusClaimed}) => {
        resolve({turnover, bonusClaimed});
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getStarterBonus2Validate() {

  return new Promise((resolve, reject) => {

    axios.post(`/pro/bonus/starter/2/validate`)
      .then(() => {
        resolve();
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getStarterBonus3() {

  return new Promise((resolve, reject) => {

    axios.get(`/pro/bonus/starter/3`)
      .then(({packsBought, packProducts, order, bonusClaimed}) => {
        resolve({packsBought, packProducts, order, bonusClaimed});
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getStarterBonus3Validate(packs, shippingAddressId) {

  return new Promise((resolve, reject) => {

    axios.post(`/pro/bonus/starter/3/validate`, {
      packs, shippingAddressId
    })
      .then(() => {
        resolve();
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getDirectPacksAmount(userId, year, month) {

  return new Promise((resolve, reject) => {

    axios.get(`/pro/user/${userId}/direct/packs/amount/${year}${month ? `/${month}`: ''}`)
      .then(({data}) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getDirectProductsAmount(userId, year, month) {

  return new Promise((resolve, reject) => {

    axios.get(`/pro/user/${userId}/direct/products/amount/${year}${month ? `/${month}`: ''}`)
      .then(({data}) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getNetworkPacksAmount(userId, year, month) {

  return new Promise((resolve, reject) => {

    axios.get(`/pro/user/${userId}/network/packs/amount/${year}${month ? `/${month}`: ''}`)
      .then(({data}) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getNetworkProductsAmount(userId, year, month) {

  return new Promise((resolve, reject) => {

    axios.get(`/pro/user/${userId}/network/products/amount/${year}${month ? `/${month}`: ''}`)
      .then(({data}) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getDirectBestSellersAmount(userId, year, month) {

  return new Promise((resolve, reject) => {

    axios.get(`/pro/user/${userId}/direct/bestsellers/turnover/amount/${year}${month ? `/${month}`: ''}`)
      .then(({data}) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getDirectBestRecruitersAmount(userId, year, month) {

  return new Promise((resolve, reject) => {

    axios.get(`/pro/user/${userId}/direct/bestRecruiters/amount/${year}${month ? `/${month}`: ''}`)
      .then(({data}) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getNetworkBestSellersAmount(userId, year, month) {

  return new Promise((resolve, reject) => {

    axios.get(`/pro/user/${userId}/network/bestsellers/turnover/amount/${year}${month ? `/${month}`: ''}`)
      .then(({data}) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getNetworkBestRecruitersAmount(userId, year, month) {

  return new Promise((resolve, reject) => {

    axios.get(`/pro/user/${userId}/network/bestRecruiters/amount/${year}${month ? `/${month}`: ''}`)
      .then(({data}) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getNetworkTurnoverByCareer(userId, year, month) {

  return new Promise((resolve, reject) => {

    axios.get(`/pro/user/${userId}/network/turnover/career/${year}${month ? `/${month}`: ''}`)
      .then(({data}) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getNetworkActiveProsCountByMonth(userId) {

  return new Promise((resolve, reject) => {

    axios.get(`/pro/user/${userId}/network/active/count/months`)
      .then(({data}) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}
