import React, {useEffect} from "react";
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";

const GridToolbarQuickSearch = ({
  value,
  data,
  setRows,
  setSearchText,
}) => {

  useEffect(() => {
    requestSearch(value || '');
  }, []);

  function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
  }

  const requestSearch = (searchValue) => {
    if (data) {
      setSearchText(searchValue);
      const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
      let filteredRows = data;

      filteredRows = filteredRows.filter((row) => Object.keys(row).some((field) => searchRegex.test(row[field]?.toString())));
      setRows(filteredRows);
    }
  };

  return (
    <Box>
      <TextField
        fullWidth
        variant={"standard"}
        value={value}
        onChange={(e) => requestSearch(e.target.value)}
        placeholder="Chercher…"
        InputProps={{
          startAdornment: <SearchIcon/>,
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              style={{visibility: value ? 'visible' : 'hidden'}}
              onClick={() => requestSearch('')}
            >
              <ClearIcon fontSize="small"/>
            </IconButton>
          ),
        }}
        sx={theme => ({
          '& .MuiInputBase-input' : {
            p: theme.spacing(1),
            '&::placeholder': {
              color: theme.palette.common.black
            },
            fontWeight: 700,
            lineHeight: 1.7142857142857142,
            fontSize: '0.8125rem'
          },
        })}
      />
    </Box>
  );
};

export default GridToolbarQuickSearch;