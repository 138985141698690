import React from "react";
import { Route, Switch } from 'react-router-dom';
import loadable from "@loadable/component";

import CustomerLayout from "../containers/Pro/Customers/CustomerLayout";
import ConsultantLayout from "../containers/Pro/Network/ConsultantLayout";

const ProRoutes = () => {

  const Error = loadable(() => import("../containers/Pro/Error"));
  const Dashboard = loadable(() => import("../containers/Pro/Dashboard/Dashboard"));
  const NetworkTermsAndConditions = loadable(() => import("../containers/Pro/NetworkTermsAndConditions"));
  const Sales = loadable(() => import("../containers/Pro/Commissions/Sales/Sales"));
  const Wallet = loadable(() => import("../containers/Pro/Commissions/Wallet/Wallet"));
  const Transfers = loadable(() => import("../containers/Pro/Commissions/Transfers/Transfers"));
  const NetworkTree = loadable(() => import("../containers/Pro/Network/NetworkTree"));
  const NetworkMap = loadable(() => import("../containers/Pro/Network/NetworkMap"));
  const NetworkTable = loadable(() => import("../containers/Pro/Network/NetworkTable"));
  const NetworkEvolution = loadable(() => import("../containers/Pro/Network/NetworkEvolution"));
  const Customers = loadable(() => import("../containers/Pro/Customers/Customers"));
  const CustomerRequests = loadable(() => import("../containers/Pro/Customers/CustomerRequests"));
  const CustomerRequest = loadable(() => import("../containers/Pro/Customers/CustomerRequest"));
  const AddCustomer = loadable(() => import("../containers/Pro/Customers/AddCustomer"));
  const CustomerImportation = loadable(() => import("../containers/Pro/Customers/CustomerImportation"));
  const CustomersGiftCards = loadable(() => import("../containers/Pro/Customers/CustomersGiftCards"));
  const NetworkRequests = loadable(() => import("../containers/Pro/Network/NetworkRequests"));
  const NetworkRequest = loadable(() => import("../containers/Pro/Network/NetworkRequest"));
  const Partners = loadable(() => import("../containers/Pro/Network/Partners"));
  const AddPartner = loadable(() => import("../containers/Pro/Network/AddPartner"));
  const CustomerSimplifiedAnamnesis = loadable(() => import("../containers/Pro/Customers/CustomerSimplifiedAnamnesis"));
  //const CustomerAnamnesis = loadable(() => import("../containers/Pro/Customers/CustomerAnamnesis"));
  const Career = loadable(() => import("../containers/Pro/Career/Career"));
  const Objectives = loadable(() => import("../containers/Pro/Career/Objectives"));
  const Certification = loadable(() => import("../containers/Pro/Career/Certification"));
  const Documents = loadable(() => import("../containers/Pro/Documents/Documents"));
  const Protocols = loadable(() => import("../containers/Pro/Protocols/Protocols"));
  const BusinessCard = loadable(() => import("../containers/Pro/Documents/BusinessCard"));
  const Protocol = loadable(() => import("../containers/Pro/Protocols/Protocol"));
  const MeetingCategories = loadable(() => import("../containers/Pro/Meetings/MeetingCategories"));
  const Meetings = loadable(() => import("../containers/Pro/Meetings/Meetings"));
  const Meeting = loadable(() => import("../containers/Pro/Meetings/Meeting"));
  const Questions = loadable(() => import("../containers/Pro/Faq/Questions"));
  const MyQuestions = loadable(() => import("../containers/Pro/Faq/MyQuestions"));
  const Question = loadable(() => import("../containers/Pro/Faq/Question"));
  const OfficeUpdate = loadable(() => import("../containers/Pro/Office/OfficeUpdate"));
  const OfficePreview = loadable(() => import("../containers/Pro/Office/OfficePreview"));
  const ShopSettings = loadable(() => import("../containers/Pro/Settings/ShopSettings"));
  const GeneralSettings = loadable(() => import("../containers/Pro/Settings/GeneralSettings"));
  const IdentifierSettings = loadable(() => import("../containers/Pro/Settings/IdentifierSettings"));
  const UserSettings = loadable(() => import("../containers/Pro/Settings/UserSettings"));

  return (
    <Switch>
      <Route exact path="/:lang(en|fr|nl)?/pro" component={Dashboard}/>
      <Route exact path="/:lang(en|fr|nl)?/pro/commissions/bank" component={Wallet}/>
      <Route exact path="/:lang(en|fr|nl)?/pro/commissions/sales" component={Sales}/>
      <Route exact path="/:lang(en|fr|nl)?/pro/commissions/transfers" component={Transfers}/>
      <Route exact path="/:lang(en|fr|nl)?/pro/network/tree" component={NetworkTree}/>
      <Route exact path="/:lang(en|fr|nl)?/pro/network/table" component={NetworkTable}/>
      <Route exact path="/:lang(en|fr|nl)?/pro/network/map" component={NetworkMap}/>
      <Route exact path="/:lang(en|fr|nl)?/pro/network/evolution" component={NetworkEvolution}/>
      <Route exact path="/:lang(en|fr|nl)?/pro/network/requests" component={NetworkRequests}/>
      <Route exact path="/:lang(en|fr|nl)?/pro/network/request/:requestId" component={NetworkRequest}/>
      <Route exact path="/:lang(en|fr|nl)?/pro/network/partners" component={Partners}/>
      <Route exact path="/:lang(en|fr|nl)?/pro/network/partner/add" component={AddPartner}/>
      <Route exact path="/:lang(en|fr|nl)?/pro/network/:consultantId" component={ConsultantLayout}/>
      <Route exact path="/:lang(en|fr|nl)?/pro/network/:consultantId/*" component={ConsultantLayout}/>
      <Route exact path="/:lang(en|fr|nl)?/pro/career/objectives" component={Objectives}/>
      <Route exact path="/:lang(en|fr|nl)?/pro/career/certification/:certificationId" component={Certification}/>
      <Route exact path="/:lang(en|fr|nl)?/pro/career/certification/:certificationId/:chapter" component={Certification}/>
      <Route exact path="/:lang(en|fr|nl)?/pro/career/:step/:chapter" component={Career}/>
      <Route exact path="/:lang(en|fr|nl)?/pro/career" component={Career}/>
      <Route exact path="/:lang(en|fr|nl)?/pro/customers" component={Customers}/>
      <Route exact path="/:lang(en|fr|nl)?/pro/customers/requests" component={CustomerRequests}/>
      <Route exact path="/:lang(en|fr|nl)?/pro/customer/:customerId/request" component={CustomerRequest}/>
      <Route exact path="/:lang(en|fr|nl)?/pro/customer/add" component={AddCustomer}/>
      {/*<Route exact path="/:lang(en|fr)?/pro/customer/:customerId/anamnesis" component={CustomerAnamnesis}/>*/}
      <Route exact path="/:lang(en|fr|nl)?/pro/customer/:customerId/anamnesis/graph/:id" component={CustomerSimplifiedAnamnesis}/>
      <Route exact path="/:lang(en|fr|nl)?/pro/customer/:customerId/import" component={CustomerImportation}/>
      <Route exact path="/:lang(en|fr|nl)?/pro/customer/:customerId/*" component={CustomerLayout}/>
      <Route exact path="/:lang(en|fr|nl)?/pro/customer/:customerId" component={CustomerLayout}/>
      <Route exact path="/:lang(en|fr|nl)?/pro/customers/gifts" component={CustomersGiftCards}/>
      <Route exact path="/:lang(en|fr|nl)?/pro/network-terms-and-conditions" component={NetworkTermsAndConditions}/>
      <Route exact path="/:lang(en|fr|nl)?/pro/documents" component={Documents} />
      <Route exact path="/:lang(en|fr|nl)?/pro/documents/:prefix+" component={Documents} />
      <Route exact path="/:lang(en|fr|nl)?/pro/protocols" component={Protocols} />
      <Route exact path="/:lang(en|fr|nl)?/pro/protocols/:id" component={Protocol} />
      <Route exact path="/:lang(en|fr|nl)?/pro/businessCard" component={BusinessCard} />
      <Route exact path="/:lang(en|fr|nl)?/pro/meetings" component={MeetingCategories} />
      <Route exact path="/:lang(en|fr|nl)?/pro/meetings/:categoryId" component={Meetings} />
      <Route exact path="/:lang(en|fr|nl)?/pro/meetings/:categoryId/:id" component={Meeting} />
      <Route exact path="/:lang(en|fr|nl)?/pro/faq" component={Questions} />
      <Route exact path="/:lang(en|fr|nl)?/pro/faq/my-questions" component={MyQuestions} />
      <Route exact path="/:lang(en|fr|nl)?/pro/faq/question/:id" component={Question} />
      <Route exact path="/:lang(en|fr|nl)?/pro/office" component={OfficePreview} />
      <Route exact path="/:lang(en|fr|nl)?/pro/office/update" component={OfficeUpdate} />
      <Route exact path="/:lang(en|fr|nl)?/pro/settings/shop" component={ShopSettings}/>
      <Route exact path="/:lang(en|fr|nl)?/pro/settings/general" component={GeneralSettings}/>
      <Route exact path="/:lang(en|fr|nl)?/pro/settings/identifier" component={IdentifierSettings}/>
      <Route exact path="/:lang(en|fr|nl)?/pro/settings/account" component={UserSettings}/>
      <Route path="*" component={Error}/>
    </Switch>
  );
};

export default ProRoutes;
