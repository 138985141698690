import React from "react";
import { useSelector } from "react-redux";
import Grid from "@mui/material/Grid";

import CustomerNotesCard from "../Customers/components(old)/CustomerNotesCard";

import ConsultantCard from "./components/ConsultantCard";
import { consultantSelector } from "./store/selectors";

const Consultant = () => {

  const consultant = useSelector(consultantSelector());

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ConsultantCard consultant={consultant}/>
      </Grid>
      <Grid item xs={12}>
        <CustomerNotesCard customer={consultant} />
      </Grid>
    </Grid>
  );
};
export default Consultant;
