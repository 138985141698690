import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import Grid from "@mui/material/Grid";
import {makeStyles} from "@mui/styles";
import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js/pure";
import I18n from "i18next";

import PageLoader from "../../../../../components/PageLoader";
import {shippingAddressSelectedSelector} from "../../store/selectors";

import CheckoutForm from "./CheckoutForm";


const useStyles = makeStyles((theme) => ({

  link: {
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  payment: {
    '& .MuiTypography-root': {
      fontFamily: 'Open sans',
      fontSize: '0.9rem',
      color: '#7C909B',
    },
    marginTop: 10,
    '& .input-element': {
      padding: '18.5px 14px',
      border: '1px solid rgba(0, 0, 0, 0.23)',
      borderRadius: 5
    },
    '& .select-element': {
      padding: '7.5px 0',
      border: '1px solid rgba(0, 0, 0, 0.23)',
      borderRadius: 5
    },
    '& button': {
      borderRadius: 8,
      fontSize: 16,
      height: 46,
      padding: '8px 20px',
      margin: theme.spacing(3, 0, 2),
    },
    '& buttonMuiButton-contained.Mui-disabled': {
      color: theme.palette.common.white
    }
  },
}));

const PaymentForm = ({order, paymentMethods, paymentIntent, paymentRef}) => {

  const classes = useStyles();
  const [stripePromise, setStripePromise] = useState(null);
  const shippingAddressSelected = useSelector(shippingAddressSelectedSelector);

  const [options, setOptions] = useState(null);

  useEffect(() => {
    if (paymentIntent) {
      setOptions({
        clientSecret: paymentIntent.client_secret,
        locale: I18n.language,
        appearance: {
          theme: 'stripe',

          variables: {
            colorPrimary: '#007675',
            colorText: '#000000',
            colorDanger: '#df1b41',
            fontFamily: 'Ideal Sans, system-ui, sans-serif',
            spacingUnit: '5px 10px',
            borderRadius: "5px",
          },
          rules: {
            '.Tab': {
              border: '1px solid #c4c4c4',
            },
            '.Tab:hover': {
              boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 1px 1px rgba(18, 42, 66, 0.02), 0 0 0 1px rgba(0, 0, 0, 0.1)'
            },
            '.Tab:focus': {
              boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02), 0 0 0 2px var(--colorPrimary)'
            },
            '.Tab--selected:hover': {
              boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02), 0 0 0 2px var(--colorPrimary)'
            },
            '.Tab--selected:focus': {
              boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02), 0 0 0 2px var(--colorPrimary)'
            },
            '.Input': {
              border: '1px solid #c4c4c4',
            },
            '.Input:focus': {
              border: '1px solid #c4c4c4',
              boxShadow: 'none'
            },
          }
        }
      });
    }
  }, [paymentIntent]);


  useEffect(() => {
    setStripePromise(loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY));
  }, []);

  if (!order || !paymentIntent || !options) {
    return (
      <Grid container justifyContent={"center"} spacing={4} alignItems={"center"}>
        <PageLoader height={150} text={!shippingAddressSelected ? "missingShippingAddress" : "loading"}/>
      </Grid>
    );
  }


  return (
    <Elements stripe={stripePromise} options={options}>
      <CheckoutForm className={classes.payment} order={order} paymentMethods={paymentMethods} paymentRef={paymentRef}/>
    </Elements>
  );
};
export default PaymentForm;
