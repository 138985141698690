import React, {useEffect, useState} from "react";
import {Trans} from "react-i18next";
import {useSelector} from "react-redux";
import Typography from "@mui/material/Typography";

import {getProductPriceWithTaxes} from "../../../../helpers/utils";
import {userSelector} from "../../../Authentication/store/selectors";

const ItemPrice = ({item, quantity= 1, withDiscount = false, total = false, variant, sx, discountSx}) => {

  const [price, setPrice] = useState(0);
  const [priceWithDiscount, setPriceWithDiscount] = useState(0);
  const [discountPercent, setDiscountPercent] = useState(item.discountPercent);
  const user = useSelector(userSelector);

  useEffect(() => {
    setPriceWithDiscount(getProductPriceWithTaxes(item, user, user?.shippingAddress, quantity, false));
    setPrice(getProductPriceWithTaxes(item, user, user?.shippingAddress, quantity, true));
  }, []);

  if(!price) {
    return null;
  }

  return (
    <React.Fragment key={user?.userDiscountId}>
      {(discountPercent !== 0 && withDiscount) && (
        <Typography
          sx={{
            height: '19px',
            minWidth: '22px',
            lineHeight: '0',
            borderRadius: '6px',
            cursor: 'default',
            alignItems: 'center',
            whiteSpace: 'nowrap',
            display: 'inline-flex',
            justifyContent: 'center',
            padding: '0px 8px',
            fontSize: '0.75rem',
            fontWeight: 700,
            textTransform: 'uppercase',
            position: 'absolute',
            bottom: '114px',
            right: '16px',
            color: 'rgb(34, 154, 22)',
            backgroundColor: 'rgba(84, 214, 44, 0.16)',
            ...discountSx
          }}>
          <Trans i18nKey={"discount"}>Discount</Trans>: {discountPercent} %
        </Typography>
      )}
      <Typography
        variant={variant}
        sx={{
          color: '#7C909B',
          fontFamily: 'Open sans',
          fontWeight: 'bold',
          fontSize: {xs: '0.875rem', sm: '1rem'},
          textAlign: 'right',
          ...sx
        }}
      >

        {(discountPercent !== 0 && withDiscount) && (
          <span style={{textDecoration: 'line-through'}}>
            {priceWithDiscount + '€'}
          </span>
        )}
        {total && (
          <span>
            {price + '€'}
          </span>
        )}
      </Typography>
    </React.Fragment>
  );
};
export default ItemPrice;
