import React from "react";
import {Box} from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import NoSsr from "@mui/material/NoSsr";
import Toolbar from "@mui/material/Toolbar";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";

import Header from "../components/Layout/Manager/Header";
import {initLanguage} from "../helpers/multilingual";
import ManagerRoutes from "../routes/ManagerRoutes";

const drawerWidth = 240;

function ManagerLayout() {

  initLanguage('fr');

  return (
    <NoSsr>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <Header/>

        <Box
          component="main"
          sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
        >
          <Toolbar />
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <ManagerRoutes/>
          </LocalizationProvider>
        </Box>
      </Box>
    </NoSsr>
  );

}

export default ManagerLayout;
