import React, {useState} from "react";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";

import FormValidator from "../../../../components/Validators/FormValidator";
import {SnackbarFormattedError, SnackbarSuccess} from "../../../../helpers/snackbar";
import {userService} from "../store/services";

const UserUpdateAuthorizations = ({user, onChange}) => {

  const [userValues, setUserValues] = useState(user);


  const handleCheckAuthorization = (e) => {

    let newArray = user.authorizations;

    if (e.target.checked) {
      newArray.push(e.target.name);
    } else {
      newArray = newArray.filter(auth => auth !== e.target.name);
    }

    setUserValues({
      ...userValues,
      authorizations: newArray
    });

  };

  const handleSubmit = () => {
    const formattedAuthorizations = userValues.authorizations;
    userService.updateUserAuthorizations(user.id, formattedAuthorizations).then(async () => {
      onChange();
      SnackbarSuccess("Authorisations mises à jour");
    })
      .catch(e => {
        SnackbarFormattedError(e.error);
      });
  };

  return (
    <Card>
      <CardContent>
        <FormValidator
          onSubmit={handleSubmit}
          onError={errors => {
            console.log('ERRORS', errors);
          }}
          noValidate
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormLabel component="legend">Authorisations:</FormLabel>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox
                    onChange={handleCheckAuthorization}
                    name="accounting"
                    checked={!!user?.authorizations?.includes("accounting")}
                  />}
                  label="Accès comptabilité"
                />
                <FormControlLabel
                  control={<Checkbox
                    onChange={handleCheckAuthorization}
                    name="logistic"
                    checked={!!user?.authorizations?.includes("logistic")}
                  />}
                  label="Accès logistique"
                />
                <FormControlLabel
                  control={<Checkbox
                    onChange={handleCheckAuthorization}
                    name="editor"
                    checked={!!user?.authorizations?.includes("editor")}
                  />}
                  label="Editeur"
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
              >
                                Modifier l'utilisateur
              </Button>
            </Grid>
          </Grid>
        </FormValidator>
      </CardContent>
    </Card>
  )
  ;
};
export default UserUpdateAuthorizations;
