import React from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {Box, Chip, Divider, Hidden, ListItemButton, Typography} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Drawer from "@mui/material/Drawer";

import {Link} from "../../../../helpers/multilingual";
import {getFormalizedName} from "../helpers/helper";
import {shopBrandsSelector, shopCategoriesSelector, shopThemesSelector} from "../store/selectors";

const Filters = ({sectionName, open, onToggleDrawer}) => {
  const {i18n} = useTranslation();
  const categories = useSelector(shopCategoriesSelector);
  const themes = useSelector(shopThemesSelector);
  const brands = useSelector(shopBrandsSelector);
  const {sectionId} = useParams();

  if (!categories?.[i18n.language] || !themes?.[i18n.language] || brands.length === 0) {
    return null;
  }

  return (
    <Box sx={{
      '& .MuiDrawer-paper': {
        maxHeight: '90%',
      },
    }}>
      <Hidden smUp key={'bottom'} implementation={'css'}>
        <Drawer
          anchor={'bottom'}
          open={open}
          onClose={onToggleDrawer}
        >
          <Box
            sx={{
              p: '12px',
            }}>
            <Typography variant={"body1"} sx={{mt: 1, mb: 2}}>Catégories</Typography>
            {categories[i18n.language].filter(item => item.status === "published").map(item => (
              <Chip
                key={item.id}
                label={item.name}
                component={Link}
                variant={sectionId === item.id ? "outlined" : "default"}
                to={`/shop/category/${getFormalizedName(item.name)}/${item.id}`}
                onClick={onToggleDrawer}
                sx={{mr: 1, mb: 1}}
              />
            ))}
            {/*
            <Divider sx={{borderColor: '#e5e6e6'}}/>
            <Typography variant={"body1"} sx={{mt: 1, mb: 2}}>Thèmes</Typography>
            themes[i18n.language].filter(item => item.status === "published").map(item => (
              <Chip
                key={item.id}
                label={item.name}
                component={Link}
                variant={sectionId === item.id ? "outlined" : "default"}
                to={`/shop/theme/${getFormalizedName(item.name)}/${item.id}`}
                onClick={onToggleDrawer}
                sx={{mr: 1, mb: 1}}
              />
            ))*/}
            <Divider sx={{borderColor: '#e5e6e6'}}/>
            <Typography variant={"body1"} sx={{mt: 1, mb: 2}}>Marques</Typography>
            {brands?.filter(item => item.status === "published").map(item => (
              <Chip
                key={item.id}
                label={item.name}
                component={Link}
                variant={sectionId === item.id ? "outlined" : "default"}
                to={`/shop/brand/${getFormalizedName(item.name)}/${item.id}`}
                onClick={onToggleDrawer}
                sx={{mr: 1, mb: 1}}
              />
            ))}
          </Box>
        </Drawer>
      </Hidden>

      <Hidden smDown implementation={'css'}>

        {sectionName === 'category' && categories[i18n.language].filter(item => item.status === "published").map(item => (
          <ListItemButton
            key={`${item.id}`} color={"secondary"}
            variant={"contained"}
            component={Link}
            to={`/shop/category/${getFormalizedName(item.name)}/${item.id}`}
            sx={{
              color: sectionId === item.id ? '#8CBD23' : '#3C515E',
              fontSize: '12px',
              fontFamily: 'Montserrat',
              pl: 0
            }}
          >
            <Avatar alt="Icon" src={item.image} sx={{
              width: '16px',
              height: '16px',
              mr: 1,
            }}/>
            {item.name}
          </ListItemButton>
        ))}

        {sectionName === 'theme' && themes[i18n.language].filter(item => item.status === "published").map(item => (
          <ListItemButton
            key={`${item.id}`} color={"secondary"}
            variant={"contained"}
            component={Link}
            to={`/shop/theme/${getFormalizedName(item.name)}/${item.id}`}
            sx={{
              color: sectionId === item.id ? '#8CBD23' : '#3C515E',
              fontSize: '12px',
              fontFamily: 'Montserrat',
            }}
          >
            {item.name}
          </ListItemButton>
        ))}

        {sectionName === 'brand' && brands?.filter(item => item.status === "published").map(item => (
          <ListItemButton
            key={`${item.id}`} color={"secondary"}
            variant={"contained"}
            component={Link}
            to={`/shop/brand/${getFormalizedName(item.name)}/${item.id}`}
            sx={{
              color: sectionId === item.id ? '#8CBD23' : '#3C515E',
              fontSize: '12px',
              fontFamily: 'Montserrat',
            }}
          >
            {item.name}
          </ListItemButton>
        ))}
      </Hidden>
    </Box>
  );
};

export default Filters;
