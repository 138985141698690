import React from "react";
import { Helmet } from "react-helmet-async";
import { Trans, useTranslation } from "react-i18next";
import {Box} from "@mui/material";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

const TermsOfSales = () => {

  const {t} = useTranslation();

  return (
    <Box sx={{
      pt: {xs: '0', sm: '11vw'},
      '& iframe': {
        minHeight: '800px',
        width: '100%',
        mb: 4,
        mt: 2
      }
    }}>
      <Helmet>
        <title>{t("termsOfSales")}</title>
        <meta property="og:title" content={t("termsOfSales")} />
      </Helmet>
      <Container fixed maxWidth="xl">
        <Typography component="h1" variant="h4" color={"primary"}>
          <Trans i18nKey="termsOfSales">Terms of Sales</Trans>
        </Typography>
        <Grid container justify={"center"}>
          <Grid item xs={12}>
            <iframe src={`${process.env.REACT_APP_API_RESOURCES_URL}/documents/ConditionGeneralesVentes.pdf`}></iframe>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default TermsOfSales;
