import React from "react";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";

import CartItem from "./CartItem";

const CartListItems = ({className, items, session, sx}) => (
  <List className={className} sx={{...sx}}>
    {Object.keys(items).map(key => (
      <React.Fragment key={key}>
        <CartItem item={items[key]} session={session}/>
        {items.length > 1 && (
          <Divider/>
        )}
      </React.Fragment>
    ))}
  </List>
);
export default CartListItems;
