import React, { useContext, useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from "react-i18next";
import {useSelector} from "react-redux";
import Button  from "@mui/material/Button";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import {makeStyles} from '@mui/styles';
import { Autocomplete } from '@react-google-maps/api';

import {COLORS} from "../../assets/styles/theme/variables";
import { SnackbarError } from "../../helpers/snackbar";
import {isGoogleMapApiLoaded} from "../GoogleMap/store/selectors";
import useLoadGoogleMapApi from "../GoogleMap/useLoadGoogleMapApi";
import { FormValidatorContext } from "../Validators/FormValidator";
import TextFieldValidator from "../Validators/TextFieldValidator";

const useStylesAutocomplete = makeStyles(() => ({
  autocomplete: {
    width: '100%',
    '& > div': {
      //width: '100%',
      '& .pac-target-input': {
        animationName: 'inherit'
      },
    },
    '& .MuiFormHelperText-root': {
      position: 'absolute',
      fontSize: '0.75rem',
      color: "green",
      '&.Mui-error': {
        color: COLORS.error,
      }
    }
  },
}));

const AutocompletePlaces = ({
  className, label, required, placeholder, variant, shrink, error, initialValue,
  autoFocus, color, size, name, defaultValue, disabled, FormHelperTextProps, inputProps, InputProps,
  margin, multiline, rows,initialAdditionalInfoValue = "", initialNameValue = "", select, SelectProps, onPlaceChanged, preview, requiredFields,
  customFieldsEnabled, filter = null
}) => {

  const classes = useStylesAutocomplete();
  const {t} = useTranslation();
  const textFieldRef = useRef();
  const { submitted } = useContext(FormValidatorContext);
  const isGoogleMapAPILoaded = useSelector(isGoogleMapApiLoaded);
  const [hasRequiredError, setHasRequiredError] = useState(false);
  const [requiredErrorMessage, setRequiredErrorMessage] = useState(t("validatorFieldRequired"));
  const [value, setValue] = useState(initialValue);
  const [place, setPlace] = useState({ address: "", coordinates: {}, components: {}});
  const [customPlace, setCustomPlace] = useState(null);
  const [customFieldsSelected, switchToCustom] = useState(false);
  const [postalBox, setPostalBox] = useState("");
  const [addressName, setAddressName] = useState(initialNameValue);
  const [additionalInfo, setAdditionalInfo] = useState(initialAdditionalInfoValue);
  const [customFields, setCustomFields] = useState({
    streetNumber: "",
    streetName: "",
    zip: "",
    city: "",
    country: "",
  });

  useLoadGoogleMapApi();
  let autocompleteRef = useRef(null);

  useEffect(() => {
    setHasRequiredError(submitted && required && !place);
  }, [submitted, required, place]);

  const changeCustomPlace = () => {
    const placeFormatted = {
      address: `${customFields.streetNumber} ${customFields.streetName}, ${customFields.zip} ${customFields.city}, ${customFields.country}`,
      coordinates: { lat:null, lng:null },
      components: customFields
    };
    setCustomPlace(placeFormatted);
    onPlaceChanged(placeFormatted);
  };

  useEffect(() => {
    if(customFieldsSelected) {
      changeCustomPlace();
    }
  }, [customFields]);

  const handlePlaceChanged = () => {
    let place = autocompleteRef.current.state.autocomplete.getPlace();
    if(!place.geometry || !place.address_components || !place.formatted_address) return;
    let lat = place.geometry.location.lat();
    let lng = place.geometry.location.lng();
    let components = {
      country: null,
      city: null,
      zip: null,
      streetName: null,
      streetNumber: null,
    };
    place.address_components.map(component => {
      component.types.map(type => {
        switch (type) {
          case 'country':
            components.country = component.short_name;
            break;
          case 'locality':
            components.city = component.long_name;
            break;
          case 'postal_code':
            components.zip = component.long_name;
            break;
          case 'route':
            components.streetName = component.long_name;
            break;
          case 'street_number':
            components.streetNumber = component.long_name;
            break;
        }
      });
    });
    let requiredErrors = 0;
    requiredFields.map(field => {
      if(!components[field]) {
        SnackbarError(t("requiredFieldInAddressSnackbar", {field:t(field+'Field')}));
        ++requiredErrors;
      }
    });
    if(requiredErrors) {
      if(customFieldsEnabled) {
        switchToCustom(true);
        setCustomFields({
          streetNumber: components.streetNumber,
          streetName: components.streetName,
          name: addressName,
          postalBox: postalBox,
          additionalInfo: additionalInfo,
          zip: components.zip,
          city: components.city,
          country: components.country,
        });
      }
      return false;
    }
    let placeFormatted = { address: place.formatted_address, coordinates: { lat, lng }, components: components};
    setPlace(placeFormatted);
    setValue(placeFormatted.address);
    onPlaceChanged(placeFormatted);
  };
  const removeAutoComplete = () => {

    textFieldRef.current.setAttribute("autocomplete", 'disable-'+Date.now()+'disable');
    textFieldRef.current.setAttribute("id", 'disable'+Date.now()+'disable');
    textFieldRef.current.setAttribute("name", 'disable'+Date.now()+'disable');
  };

  const handleChange = (e) => {
    setValue(e.target.value);
    setPlace(null);
    onPlaceChanged(null);
  };

  const handleSwitchToCustom = () => {

    switchToCustom(!customFieldsSelected);
    setValue('');
    setPlace(null);
    setCustomPlace(null);
    onPlaceChanged(null);
  };

  const handleChangeCustomField = (field) => (e) => {
    let {value} = e.target;
    if(field === 'country') {
      value = value.substring(0, 2);
    }
    setCustomFields(fields => ({...fields, [field]: value}));
  };

  const handleKeyDown = (e) => {

    if(e.keyCode === 13) {
      e.preventDefault();
      let arrowDownEvent = new KeyboardEvent('keydown', {
        keyCode: 40,
        which: 40
      });
      textFieldRef.current.dispatchEvent(arrowDownEvent);
      let enterEvent = new KeyboardEvent('keydown', {
        keyCode: 13,
        which: 13
      });
      textFieldRef.current.dispatchEvent(enterEvent);
    }
  };

  let inputLabelProps = (shrink) ? {
    shrink: true,
    style: {
      color: COLORS.black
    }
  } : {};


  if(!isGoogleMapAPILoaded) {
    return null;
  }

  return (
    <div className={classes.autocomplete}>
      {!customFieldsSelected && (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Autocomplete
                ref={autocompleteRef}
                className="place-autocomplete"
                onPlaceChanged={handlePlaceChanged}
                types={filter ? [filter] : []}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      inputRef={textFieldRef}
                      //autoComplete="new-password"
                      //id="field1"
                      fullWidth
                      label={label}
                      className={className}
                      placeholder={placeholder}
                      onMouseEnter={removeAutoComplete}
                      onSelect={removeAutoComplete}
                      onChange={handleChange}
                      variant={variant}
                      value={value}
                      InputLabelProps={inputLabelProps}
                      autoFocus={autoFocus}
                      color={color}
                      size={size}
                      type="text"
                      name={name}
                      required={required}
                      defaultValue={defaultValue}
                      disabled={disabled}
                      onKeyDown={handleKeyDown}
                      FormHelperTextProps={FormHelperTextProps}
                      inputProps={inputProps}
                      InputProps={InputProps}
                      margin={margin}
                      multiline={multiline}
                      rows={rows}
                      select={select}
                      SelectProps={SelectProps}
                      error={error || hasRequiredError}
                      helperText={hasRequiredError && requiredErrorMessage}
                    />
                  </Grid>
                </Grid>
              </Autocomplete>
            </Grid>

          </Grid>
          <Grid container justify={"space-between"}>
            <Grid item>
              {preview && place && (
                <FormHelperText>{place.address}</FormHelperText>
              )}
            </Grid>
            <Grid item>
              {customFieldsEnabled && (
                <Button
                  sx={{mt: "15px"}}
                  size={"small"}
                  variant={'text'}
                  onClick={handleSwitchToCustom}
                >
                  <Trans i18nKey={"useCustomFields"}>Enter custom address</Trans>
                </Button>
              )}
            </Grid>
          </Grid>
        </>
      )}
      {customFieldsSelected && (
        <>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <TextFieldValidator
                fullWidth
                placeholder={t("streetNumber")}
                variant={"outlined"}
                value={customFields["streetNumber"]}
                type="text"
                name={"streetNumber"}
                onChange={handleChangeCustomField("streetNumber")}
                required={requiredFields.includes("streetNumber")}
              />
            </Grid>
            <Grid item xs={9}>
              <TextFieldValidator
                fullWidth
                placeholder={t("streetName")}
                variant={"outlined"}
                value={customFields["streetName"]}
                type="text"
                name={"streetName"}
                onChange={handleChangeCustomField("streetName")}
                required={requiredFields.includes("streetName")}
              />
            </Grid>
            <Grid item xs={4}>
              <TextFieldValidator
                fullWidth
                placeholder={t("zip")}
                variant={"outlined"}
                value={customFields["zip"]}
                type="text"
                name={"zip"}
                onChange={handleChangeCustomField("zip")}
                required={requiredFields.includes("zip")}
              />
            </Grid>
            <Grid item xs={4}>
              <TextFieldValidator
                fullWidth
                placeholder={t("city")}
                variant={"outlined"}
                value={customFields["city"]}
                type="text"
                name={"city"}
                onChange={handleChangeCustomField("city")}
                required={requiredFields.includes("city")}
              />
            </Grid>
            <Grid item xs={4}>
              <TextFieldValidator
                fullWidth
                placeholder={`${t("country")} (${t("countryFieldInfo")})`}
                variant={"outlined"}
                value={customFields["country"]}
                type="text"
                name={"country"}
                onChange={handleChangeCustomField("country")}
                required={requiredFields.includes("country")}
              />
            </Grid>
          </Grid>
          <Grid container justify={"space-between"}>
            <Grid item>
              {preview && customPlace && (
                <FormHelperText>{customPlace.address}</FormHelperText>
              )}
            </Grid>
            <Grid item>
              {customFieldsEnabled && (
                <Button
                  sx={{mt: "15px"}}
                  size={"small"}
                  variant={'text'}
                  onClick={handleSwitchToCustom}
                >
                  <Trans i18nKey={"useAutocompleteField"}>Use autocomplete field</Trans>
                </Button>
              )}
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
};
export default AutocompletePlaces;
