import React, { useState } from "react";
import i18n from "i18next";

import { SnackbarError } from "../../helpers/snackbar";

export const FormValidatorContext = React.createContext({
  submitted: false,
  setValidationFields: () => {}
});

const FormValidator = ({className, noValidate, autoComplete, onSubmit, errorAlert, onError, children, ...rest}) => {

  const [validationFields, setValidationFields] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const contextValue = { submitted, setValidationFields };

  const handleSubmit = (e) => {

    e.preventDefault();
    setSubmitted(true);
    let errors = [];
    Object.keys(validationFields).map(key => {
      if(validationFields[key]) {
        errors.push(key);
      }
    });
    if(errors.length > 0) {
      SnackbarError(i18n.t('errorMessageRequiredFields'));
      onError(errors);
    }
    else {
      onSubmit(e);
    }
    return false;
  };

  return (
    <form
      className={className}
      noValidate={noValidate}
      autoComplete={autoComplete}
      onSubmit={handleSubmit}
      {...rest}
    >
      <FormValidatorContext.Provider value={contextValue}>
        {children}
      </FormValidatorContext.Provider>
    </form>
  );
};

export default FormValidator;
