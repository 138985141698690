import React, { useState } from "react";
import { Trans } from "react-i18next";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { green,red } from "@mui/material/colors";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {makeStyles} from '@mui/styles';

import { SnackbarFormattedError } from "../../../../helpers/snackbar";
import { networkService } from "../store/services";

const useStyles = makeStyles(theme => ({
  displayed: {
    marginBottom: 20,
    padding: '10px 20px',
    color: theme.palette.common.white,
    backgroundColor: green[700],
    '& button': {
      borderColor: theme.palette.common.white,
      color: theme.palette.common.white
    },
    [theme.breakpoints.down('sm')]: {
      '& .MuiTypography-alignRight': {
        textAlign: 'center'
      }
    }
  },
  notDisplayed: {
    marginBottom: 20,
    padding: '10px 20px',
    color: theme.palette.common.white,
    backgroundColor: red[700],
    '& button': {
      borderColor: theme.palette.common.white,
      color: theme.palette.common.white
    },
    [theme.breakpoints.down('md')]: {
      '& .MuiTypography-alignRight': {
        textAlign: 'center'
      }
    }
  }
}));

const PartnerOfficeVisibility = ({office, user, onChange}) => {

  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const handleChangeVisibility = () => {

    setLoading(false);
    networkService.setDisplayedOnMap(user.id, !user.displayedOnMap)
      .then(() => {
        setLoading(false);
        onChange();
      })
      .catch((e) => {
        setLoading(false);
        SnackbarFormattedError(e.error);
      });
  };

  return (
    <Grid className={user.displayedOnMap ? classes.displayed : classes.notDisplayed} container
      justify={"space-between"} alignItems={"center"}>
      <Grid item xs={12} sm={10}>
        {user.displayedOnMap && (
          <Typography>
            <Trans i18nKey={"partnerOfficeDisplayedDescription"}>
                            The office is now displayed online
            </Trans>
          </Typography>
        )}
        {!user.displayedOnMap && !office.updated && (
          <Typography>
            <Trans i18nKey={"youNeedToUpdateYourProfile"}>
                            You need to update your office before display it online
            </Trans>
          </Typography>
        )}
        {!user.displayedOnMap && office.updated && (
          <Typography>
            <Trans i18nKey={"partnerOfficeNotDisplayedDescription"}>
                            The office is not displayed on the map and search engine.
            </Trans>
          </Typography>
        )}
      </Grid>
      <Grid item xs={12} sm={2}>
        {(office.updated) && (
          <Typography align={"right"}>
            {loading && (
              <Typography align={"center"}>
                <CircularProgress color="inherit" size={20} />
              </Typography>
            )}
            {!loading && (
              <Button variant={"outlined"} color={"primary"} onClick={handleChangeVisibility}>
                <Trans i18nKey={"changeVisibility"}>Change visibility</Trans>
              </Button>
            )}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};
export default PartnerOfficeVisibility;
