import React from "react";
import { Helmet } from "react-helmet-async";
import {Box} from "@mui/material";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

const MentionsLegales = () => (
  <Box sx={{
    pt: {xs: '0', sm: '11vw'},
    '& iframe': {
      minHeight: '800px',
      width: '100%',
      mb: 4,
      mt: 2
    }
  }}>
    <Helmet>
      <title>{"Mentions légales"}</title>
      <meta property="og:title" content={"Mentions légales"} />
    </Helmet>
    <Container fixed maxWidth="xl">
      <Typography component="h1" variant="h4" color={"primary"}>
            Mentions légales
      </Typography>
      <Grid container justify={"center"}>
        <Grid item xs={12}>
          <iframe src={`${process.env.REACT_APP_API_RESOURCES_URL}/documents/MentionsLegales.pdf`}></iframe>
        </Grid>
      </Grid>
    </Container>
  </Box>
);

export default MentionsLegales;
