import React, {useEffect, useRef} from "react";
import ReactGA from "react-ga4";
import {Trans, useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import CloseIcon from '@mui/icons-material/Close';
import EuroSharpIcon from '@mui/icons-material/EuroSharp';
import LocalMallOutlinedIcon from '@mui/icons-material/LocalMallOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import {Box} from "@mui/material";
import {grey} from "@mui/material/colors";
import Container from "@mui/material/Container";
import Drawer from "@mui/material/Drawer";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import {makeStyles} from "@mui/styles";

import theme from "../../../../assets/styles/theme/theme";
import MultisafepayPaymentMethodsSelector from "../../../../components/MultiSafePay/MultisafepayPaymentMethodsSelector";
import useDebouncedEffect from "../../../../helpers/useDebouncedEffect";
import {getProductPriceWithTaxes} from "../../../../helpers/utils";
import {userSelector} from "../../../Authentication/store/selectors";
import CartListItems from "../components/Cart/CartListItems";
import ReadOnlyCartItem from "../components/Cart/ReadOnlyCartItem";
import {shopActions} from "../store/actions";
import {
  checkoutSelector, forbiddenItemsFromCheckoutSelector,
  giftCardSelector, giftedItemsFromCheckoutSelector,
  isCheckoutOpenSelector, itemsFromCartSelector, itemsFromCheckoutSelector,
  needDeliverySelector, promotionCodeSelector,
  sessionSelector,
  shippingAddressSelectedSelector,
  shippingMethodSelectedSelector, shippingMethodsSelector,
  shippingPickupPointSelectedSelector,
  useWalletSelector, weightFromCartSelector
} from "../store/selectors";
import {shopService} from "../store/services";

import CheckoutFooter from "./components/CheckoutFooter";
import GiftCardSelector from "./components/GiftCardSelector";
import PaymentForm from "./components/PaymentForm";
import PromotionCodeSelector from "./components/PromotionCodeSelector";
import ShippingAddressSelector from "./components/ShippingAddressSelector";
import ShippingMethodSelector from "./components/ShippingMethodSelector";
import WalletAmountSelector from "./components/WalletAmountSelector";

const useStyle = makeStyles(theme => ({
  root: {
    '& .MuiDrawer-paper': {
      backgroundColor: grey[50],
      [theme.breakpoints.down('sm')]: {
        width: '100vw',
        flexShrink: 0,
      },
      '& img': {
        maxHeight: 120
      }
    }
  },
  title: {
    marginBottom: theme.spacing(2)
  },
  expandIcons: {
    position: "absolute",
    top: "50vh",
    left: 0,
    cursor: "pointer"
  }
}));

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="checkout-tabpanel"
      hidden={value !== index}
      id={`checkout-vertical-tabpanel-${index}`}
      aria-labelledby={`checkout-vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            p: {xs: '8px 8px 24px 8px', mw: '8px 16px 24px 16px'},
          }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `checkout-vertical-tab-${index}`,
    'aria-controls': `checkout-vertical-tabpanel-${index}`,
  };
}

const StyledCloseIcon = ({onClick}) => (
  <Box component={'div'} sx={{
    position: 'relative',
  }}>
    <CloseIcon onClick={onClick} sx={{
      top: 8,
      right: 16,
      fontSize: 26,
      cursor: 'pointer',
      position: 'absolute',
      color: '#7C909B',
      background: '#ffffff',
      borderRadius: '50%',
      boxShadow: '2px 2px 8px #7C909B',
      padding: '4px',
    }}/>
  </Box>
);

function getCategoriesObject(product) {
  const categories = {};
  product.categories.map((c, i) => {
    categories[`item_category${i ? i+1 : ''}`] = c.name;
  });
  return categories;
}

const CheckoutDrawer = () => {

  const classes = useStyle();
  const {t, i18n} = useTranslation();
  const dispatch = useDispatch();
  const open = useSelector(isCheckoutOpenSelector);
  const session = useSelector(sessionSelector);
  const needDelivery = useSelector(needDeliverySelector);
  const useWallet = useSelector(useWalletSelector);
  const giftCard = useSelector(giftCardSelector);
  const shippingAddressSelected = useSelector(shippingAddressSelectedSelector);
  const shippingPickupPointSelected = useSelector(shippingPickupPointSelectedSelector);
  const shippingMethodSelected = useSelector(shippingMethodSelectedSelector);
  const shippingMethods = useSelector(shippingMethodsSelector);
  const checkout = useSelector(checkoutSelector);
  const itemsFromCart = useSelector(itemsFromCartSelector);
  const totalWeight = useSelector(weightFromCartSelector);
  const promotionCode = useSelector(promotionCodeSelector);
  const itemsFromCheckout = useSelector(itemsFromCheckoutSelector);
  const forbiddenItemsFromCheckout = useSelector(forbiddenItemsFromCheckoutSelector);
  const giftedItemsFromCheckout = useSelector(giftedItemsFromCheckoutSelector);
  const [tabsIndex, setTabsIndex] = React.useState(0);
  const user = useSelector(userSelector);
  const paymentRef = useRef(null);

  //useEffect(() => {
  //  if ((!!shippingAddressSelected && shippingMethods.length === 0) || (!!shippingAddressSelected && !!checkout)) {
  //    let price = parseFloat((checkout?.order?.totalPrice + checkout?.order?.taxes).toFixed(2));
  //    price = isNaN(price) ? 0 : price;
  //    dispatch(shopActions.getShippingMethodsRequest(shippingAddressSelected.country, shippingAddressSelected.zip, totalWeight, price));
  //  }
  //}, [totalWeight, shippingAddressSelected]);

  //useDebouncedEffect(() => {
  //  if (session?.registered && Object.keys(itemsFromCart).length && shippingAddressSelected && (shippingMethodSelected || !needDelivery) && open) {
  //    dispatch(shopActions.getCheckoutRequest(i18n.language, shippingMethodSelected?.id, shippingAddressSelected.id, shippingPickupPointSelected, itemsFromCart, useWallet, giftCard, promotionCode));
  //  }
  //}, [session?.registered, itemsFromCart, shippingMethodSelected, shippingAddressSelected, useWallet, giftCard, open, promotionCode], 680);

  useEffect(() => {
    if ((!!shippingAddressSelected && shippingMethods.length === 0) || (!!shippingAddressSelected && !!checkout) || (!!shippingAddressSelected && !shippingMethodSelected)) {
      let price = parseFloat((checkout?.order?.totalPrice + checkout?.order?.taxes).toFixed(2));
      price = isNaN(price) ? 0 : price;
      dispatch(shopActions.getShippingMethodsRequest(shippingAddressSelected.country, shippingAddressSelected.zip, totalWeight, price));
    }
  }, [totalWeight, shippingAddressSelected]);

  useDebouncedEffect(() => {
    if (session?.registered && Object.keys(itemsFromCart).length && shippingAddressSelected && (shippingMethodSelected || !needDelivery) && open) {
      dispatch(shopActions.getCheckoutRequest(i18n.language, shippingMethodSelected?.id, shippingAddressSelected.id, shippingPickupPointSelected, itemsFromCart, useWallet, giftCard, promotionCode));
    }
  }, [session?.registered, itemsFromCart, shippingMethodSelected, shippingAddressSelected, useWallet, giftCard, open, promotionCode], 680);

  const handleChange = (event, newValue) => {
    setTabsIndex(newValue);
    let onglet;
    let gaEvent = null;
    switch(newValue) {
      case 0:
        onglet = "Produits";
        break;
      case 1:
        onglet = "Adresse";
        gaEvent = "add_shipping_address";
        break;
      case 2:
        onglet = "Paiement";
        gaEvent = "add_payment_method";
        break;
    }
    shopService.setCartLog("Le client change d'onglet",{from:"checkoutDrawer", userId:user?.id, onglet: onglet}).then().catch();

    if(onglet) {
      ReactGA.event("page_view_funnel", {
        user_id: user?.id,
        customer_type : user?.role === 'prospect' ? '1' : '0',
        page_name : onglet,
      });
    }

    if(onglet === "Adresse") {
      ReactGA.event("begin_checkout", {
        user_id: user?.id,
        currency: "EUR",
        value: Math.max(0, parseFloat((((checkout?.order?.totalPrice + checkout?.order?.taxes - checkout?.order?.walletAmount - checkout?.order?.giftCardAmount) * 100) / 100).toFixed(2))),
        items: checkout?.order?.products?.map(item =>
          ({
            item_id: item?.originalProduct?.id,
            item_name: item?.originalProduct?.reference,
            item_brand: item?.originalProduct?.brand?.name,
            ...getCategoriesObject(item?.originalProduct),
            price: getProductPriceWithTaxes(item?.originalProduct, user, user?.shippingAddress, 1, true),
            quantity: item.quantity
          })
        )
      });
    }

    if(gaEvent && checkout) {
      ReactGA.event(gaEvent, {
        user_id: user?.id,
        currency: "EUR",
        value: Math.max(0, parseFloat((((checkout?.order?.totalPrice + checkout?.order?.taxes - checkout?.order?.walletAmount - checkout?.order?.giftCardAmount) * 100) / 100).toFixed(2))),
        items: checkout?.order?.products?.map(item =>
          ({
            item_id: item?.originalProduct?.id,
            item_name: item?.originalProduct?.reference,
            item_brand: item?.originalProduct?.brand?.name,
            ...getCategoriesObject(item?.originalProduct),
            price: getProductPriceWithTaxes(item?.originalProduct, user, user?.shippingAddress, 1, true),
            quantity: item.quantity
          })
        )
      });
    }

  };

  const handleCloseDrawer = () => {
    dispatch(shopActions.SetDrawersOpen("checkout", false));
  };

  const handleSubmitPaymentForm = () => {
    if(paymentRef?.current) {
      (() => {
        if (typeof paymentRef.current.requestSubmit === 'function') {
          paymentRef.current.requestSubmit();
          shopService.setCartLog("Le client clique sur valider le paiement",{from:"checkoutDrawer", userId:user?.id, submit: 'requestSubmit'}).then().catch();
        } else {
          paymentRef.current.dispatchEvent(
            new Event('submit', {cancelable: true})
          );
          shopService.setCartLog("Le client clique sur valider le paiement",{from:"checkoutDrawer", userId:user?.id, submit: 'dispatchEvent'}).then().catch();
        }
      })();
    } else {
      shopService.setCartLog("Le client clique sur valider le paiement",{from:"checkoutDrawer", userId:user?.id, submit: 'no'}).then().catch();
    }
  };

  return (
    <Drawer className={classes.root} anchor={'right'} open={open} onClose={() => handleCloseDrawer()}>
      <Container
        maxWidth="sm"
        sx={{
          height: "100%",
          paddingLeft: '0px !important',
          paddingRight: '0px !important',
          overflowX: 'hidden',
          background: '#EEF1F2'
        }}>
        <Box sx={{width: '100%', display: 'flex'}}>
          <Box
            sx={{
              height: '100%',
              pt: '0 !important',
              width: '90px',
              '& .MuiButtonBase-root.MuiTab-root.MuiTab-labelIcon': {
                width: '90px',
                padding: 0
              }
            }}>
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={tabsIndex}
              onChange={handleChange}
              aria-label="checkout-drawer-vertical-tabs"
              sx={{
                width: '90px',
                height: "100%",
                backgroundColor: '#EEF1F2'
              }}
            >
              <Tab icon={<LocalMallOutlinedIcon/>}
                label={t("shoppingCart")}
                sx={{
                  color: '#7C909B',
                  fontSize: "10px",
                  fontFamily: "Montserrat"
                }}
                {...a11yProps(0)} />
              <Tab
                icon={<LocalShippingOutlinedIcon/>}
                label={t("delivery")}
                sx={{
                  color: '#7C909B',
                  fontSize: "10px",
                  fontFamily: "Montserrat"
                }}
                {...a11yProps(1)} />
              <Tab icon={<EuroSharpIcon/>}
                label={t("payment")}
                sx={{
                  color: '#7C909B',
                  fontSize: "10px",
                  fontFamily: "Montserrat"
                }}
                {...a11yProps(2)} />
            </Tabs>
          </Box>
          <Box
            sx={{
              p: '16px, 4px',
              background: '#fff',
              width: 'calc(100vw - 80px)',
              maxWidth: {mw: '100%', sm: '520px', md: '520px'},
              minHeight: '100vh',
              '& #checkout-vertical-tabpanel-0, #checkout-vertical-tabpanel-1, #checkout-vertical-tabpanel-2, #checkout-vertical-tabpanel-3': {
                height: 'calc(100vh - 138px)',
                overflowY: 'auto',
              }
            }}>
            <TabPanel
              value={tabsIndex}
              index={0}
            >
              <StyledCloseIcon onClick={() => handleCloseDrawer()} />
              <Typography sx={{
                color: theme.palette.primary.main,
                fontFamily: 'Open sans',
                fontSize: "1.3rem",
                p: 1
              }}>
                <Trans i18nKey="shoppingCart">Shopping Cart</Trans>
              </Typography>
              {checkout && itemsFromCheckout ?
                <>
                  <CartListItems session={session} items={itemsFromCheckout} sx={{
                    '& .MuiListItem-root': {
                      pr: 0,
                    },
                    '& .MuiListItem-root.MuiListItem-gutters': {
                      pl: 0,
                    }
                  }}
                  />
                  {giftedItemsFromCheckout && Object.keys(giftedItemsFromCheckout).length > 0 && (
                    <List sx={{
                      '& .MuiListItem-root': {
                        pr: 0,
                      },
                      '& .MuiListItem-root.MuiListItem-gutters': {
                        pl: 0,
                      }
                    }}>
                      {Object.keys(giftedItemsFromCheckout).map((key) => (
                        <React.Fragment key={key}>
                          <ReadOnlyCartItem
                            item={giftedItemsFromCheckout[key]}
                            gifted={true}
                            sx={{
                              '& .MuiListItem-root': {
                                pr: 0,
                              },
                              '& .MuiListItem-root.MuiListItem-gutters': {
                                pl: 0,
                              }
                            }}/>
                        </React.Fragment>
                      ))}
                    </List>
                  )}
                </>
                :<CartListItems session={session} items={itemsFromCart} sx={{
                  '& .MuiListItem-root': {
                    pr: 0,
                  },
                  '& .MuiListItem-root.MuiListItem-gutters': {
                    pl: 0,
                  }
                }}/>
              }
              {checkout && forbiddenItemsFromCheckout && !!Object.keys(forbiddenItemsFromCheckout).length && (
                <Box sx={{
                  p: '8px',
                  borderRadius: '8px',
                  backgroundColor: '#f8000026'
                }}>
                  <Typography sx={{
                    fontFamily: 'Montserrat',
                    fontSize: '0.9rem',
                    color: 'red',
                  }}>
                    {t("forbiddenItemsFromCheckout")} :
                  </Typography>

                  <CartListItems session={session} items={forbiddenItemsFromCheckout} sx={{
                    '& .MuiListItem-root': {
                      pr: 0,
                    },
                    '& .MuiListItem-root.MuiListItem-gutters': {
                      pl: 0,
                    },
                  }}
                  />
                </Box>
              )}
            </TabPanel>

            <TabPanel value={tabsIndex} index={1}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <StyledCloseIcon onClick={() => handleCloseDrawer()} />
                  <Typography
                    sx={{
                      color: theme.palette.primary.main,
                      fontFamily: "Open sans",
                      fontSize: "1.3rem",
                      p: 1
                    }}>
                    {t("selectShippingMethod")}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography sx={{
                    color: theme.palette.primary.main,
                    fontFamily: "Open sans",
                    fontSize: "1.3rem",
                    p: 1
                  }}>
                    <Trans i18nKey="shippedTo">Shipped to</Trans> :
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <ShippingAddressSelector/>
                </Grid>
                <Grid item xs={12}>
                  <ShippingMethodSelector/>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={tabsIndex} index={2}>
              <StyledCloseIcon onClick={() => handleCloseDrawer()} />
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <StyledCloseIcon onClick={() => handleCloseDrawer()} />
                  <Typography sx={{
                    color: theme.palette.primary.main,
                    fontFamily: 'Open sans',
                    fontSize: "1.3rem",
                    p: 1
                  }}>
                    Coupon
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <PromotionCodeSelector/>
                </Grid>
                <Grid item xs={12}>
                  <GiftCardSelector/>
                </Grid>
                <Grid item xs={12}>
                  <WalletAmountSelector/>
                </Grid>
              </Grid>
              {checkout?.order?.paymentProvider === "stripe"
                ?
                <Grid container spacing={1}>

                  <Typography sx={{
                    color: theme.palette.primary.main,
                    fontFamily: 'Open sans',
                    fontSize: "1.3rem",
                    p: 1
                  }}>
                    <Trans i18nKey="payment">Payment</Trans>
                    <PaymentForm order={checkout?.order} paymentIntent={checkout?.paymentIntent} paymentMethods={checkout?.paymentMethods} paymentRef={paymentRef}/>
                  </Typography>
                </Grid>
                //: <Checkout order={checkout?.order}/>
                :<Grid container spacing={1}>
                  <Grid item xs={12}>
                    <MultisafepayPaymentMethodsSelector/>
                  </Grid>
                </Grid>
              }
            </TabPanel>
            <CheckoutFooter
              order={checkout?.order}
              handleGoToNextTab={handleChange}
              currentIndex={tabsIndex}
              setIndex={handleChange}
              handleSubmitPaymentForm={handleSubmitPaymentForm}
            />
          </Box>
        </Box>
      </Container>
    </Drawer>
  );
};

export default CheckoutDrawer;