import React from "react";
import { useSelector } from "react-redux";

import PartnerUpdateCard from "./components/PartnerUpdateCard";
import {consultantSelector} from "./store/selectors";

const UpdatePartner = () => {

  const consultant = useSelector(consultantSelector());

  return (
    <PartnerUpdateCard partner={consultant} />
  );
};
export default UpdatePartner;
