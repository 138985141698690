import React from "react";
import {Trans, useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import AssignmentReturnOutlinedIcon from '@mui/icons-material/AssignmentReturnOutlined';
import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { green,red } from "@mui/material/colors";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import {makeStyles} from '@mui/styles';

import useMoment from "../../../../helpers/useMoment";
import {PARTNER_CAREER} from "../../../Authentication/store/constants";
import {getConsultantLabels} from "../helpers/helpers";

const useStyles = makeStyles(() => ({
  root: {
    overflow: 'auto',
    '& a': {
      color: 'inherit',
      textDecoration: 'none',
    },
    '& .MuiCollapse-container': {

      '& .MuiListItem-button': {
        height: 50,
        paddingLeft: 50
      }
    },
    '& .MuiListItemIcon-root': {
      minWidth: 40
    },
    '& .MuiListItem-button': {
      height: 60,
      '& .MuiSvgIcon-root': {
        fontSize: '2em',
        fill: red[900]
      }
    },
  },
  cardProfile: {
    '& .membership': {
      fontStyle: 'italic'
    },
    '& .greenColor': {
      color: green[800]
    },
    '& .MuiAvatar-colorDefault': {
      margin: 'auto',
      width: 150,
      height: 150,
      marginTop: 20,
      marginBottom: 20,
      position: 'relative',
      '& img': {
        width: 150,
        height: 150,
      },
      '& button': {
        position: 'absolute',
        top: 45,
        left: 0,
        display: 'none'
      },
      '& p': {
        position: 'absolute',
        top: 65,
        left: 65,
      },
      '&:hover': {
        '& button': {
          display: 'inline'
        }
      }
    }
  },
}));

const ConsultantReturnActionsCard = ({consultant}) => {

  const classes = useStyles();
  const moment = useMoment();
  const { t } = useTranslation();
  const {primary, level} = getConsultantLabels(consultant.role, consultant.career);

  return (
    <Card className={classes.cardProfile}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Avatar>
              <img src={consultant.profilePicture} alt=""/>
            </Avatar>
            <Typography variant={"h5"} align={"center"} gutterBottom>{consultant.firstName} {consultant.lastName}</Typography>
            {!!consultant.company && (
              <Typography variant={"h5"} align={"center"} gutterBottom>{consultant.company}</Typography>
            )}
            <Typography variant={"h6"} align={"center"}>{t(primary).toUpperCase()}{level ? ` - ${t('level', {lvl: level})}` : ''}</Typography>
            {consultant.career !== PARTNER_CAREER && moment(consultant.membershipEndAt).diff(moment(), 'days') > 0 && (
              <Typography className={"membership greenColor"} align={"center"}>
                <Trans i18nKey={"membershipEndAt"} values={{date:moment(consultant.membershipEndAt).format('LL')}}>Active membership until {moment(consultant.membershipEndAt).format('LL')}</Trans>
              </Typography>
            )}
            {consultant.career !== PARTNER_CAREER && moment(consultant.membershipEndAt).diff(moment(), 'days') < 0 && (
              <Typography className={"membership"} color={"error"} align={"center"}>
                <Trans i18nKey={"membershipExpired"}>Membership expired</Trans>
              </Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <Typography component="h3" variant={"h6"}>
                            Actions :
            </Typography>
            <List>
              <ListItem button component={Link} to={`/pro/network/${consultant.id}`}>
                <ListItemIcon>
                  <AssignmentReturnOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary={"Retour à la fiche"} />
              </ListItem>
              <Divider/>
            </List>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
export default ConsultantReturnActionsCard;
