import axios from "axios";

export const getFormalizedName = (name) => name.normalize("NFD").replace(/ /g, '-').replace(/\//g, '-').replace(/[^\-\w\s]/gi, '').toLowerCase();

export const getGeolocationCountryCode = (defaultCountryCode) => new Promise((resolve, reject) => {
  axios.get('https://ipapi.co/json/')
    .then(({data: {country_code}}) => {
      resolve(country_code || defaultCountryCode);
    })
    .catch((e) => {
      resolve(defaultCountryCode);
    });
});