import React from "react";
import Grid from "@mui/material/Grid";
//import DirectCharts from "../../Stats/components/DirectCharts";
//import NetworkCharts from "../../Stats/components/NetworkCharts";
//import BestLvl1Charts from "../../Stats/components/BestLvl1Charts";
//import BestLvl2AndMoreCharts from "../../Stats/components/BestLvl2AndMoreCharts";
//import DirectProductsCharts from "../../Stats/components/DirectProductsCharts";
//import NetworkProductsCharts from "../../Stats/components/NetworkProductsCharts";
//import NetworkPerformancesDataTable from "../../Stats/components/NetworkPerformancesDataTable";
//import NetworkTurnoverByCareerChart from "../../Stats/components/NetworkTurnoverByCareerChart";
//import NetworkAverageTurnoversByActiveProsByMonthChart
//    from "../../Stats/components/NetworkAverageTurnoversByActiveProsByMonthChart";

const UserStats = ({user}) => (
  <Grid container spacing={2}>
    {/*<Grid item xs={12}>
                <DirectCharts user={user}/>
            </Grid>
            <Grid item xs={12}>
                <NetworkCharts user={user}/>
            </Grid>
            <Grid item xs={12}>
                <BestLvl1Charts user={user}/>
            </Grid>
            <Grid item xs={12}>
                <BestLvl2AndMoreCharts user={user}/>
            </Grid>
            <Grid item xs={12}>
                <DirectProductsCharts user={user}/>
            </Grid>
            <Grid item xs={12}>
                <NetworkProductsCharts user={user}/>
            </Grid>
            <Grid item xs={12}>
                <NetworkAverageTurnoversByActiveProsByMonthChart user={user}/>
            </Grid>
            <Grid item xs={12}>
                <NetworkTurnoverByCareerChart user={user}/>
            </Grid>
            <Grid item xs={12}>
                <NetworkPerformancesDataTable user={user}/>
            </Grid>*/}
  </Grid>
);
export default UserStats;
