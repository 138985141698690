export const GET_PROFILING_TESTS_REQUEST    = 'NETWORK/GET_PROFILING_TESTS_REQUEST';
export const GET_PROFILING_TESTS_SUCCESS    = 'NETWORK/GET_PROFILING_TESTS_SUCCESS';
export const GET_PROFILING_TESTS_ERROR      = 'NETWORK/GET_PROFILING_TESTS_ERROR';

export const GET_PROFILING_TEST_REQUEST     = 'NETWORK/GET_PROFILING_TEST_REQUEST';
export const GET_PROFILING_TEST_SUCCESS     = 'NETWORK/GET_PROFILING_TEST_SUCCESS';
export const GET_PROFILING_TEST_ERROR       = 'NETWORK/GET_PROFILING_TEST_ERROR';

export const ACCEPT_PROFILING_TEST_REQUEST  = 'NETWORK/ACCEPT_PROFILING_TEST_REQUEST';
export const ACCEPT_PROFILING_TEST_SUCCESS  = 'NETWORK/ACCEPT_PROFILING_TEST_SUCCESS';
export const ACCEPT_PROFILING_TEST_ERROR    = 'NETWORK/ACCEPT_PROFILING_TEST_ERROR';

export const DECLINE_PROFILING_TEST_REQUEST = 'NETWORK/DECLINE_PROFILING_TEST_REQUEST';
export const DECLINE_PROFILING_TEST_SUCCESS = 'NETWORK/DECLINE_PROFILING_TEST_SUCCESS';
export const DECLINE_PROFILING_TEST_ERROR   = 'NETWORK/DECLINE_PROFILING_TEST_ERROR';

export const GET_EVOLUTIONS_REQUEST     = 'NETWORK/GET_EVOLUTIONS_REQUEST';
export const GET_EVOLUTIONS_SUCCESS     = 'NETWORK/GET_EVOLUTIONS_SUCCESS';
export const GET_EVOLUTIONS_ERROR       = 'NETWORK/GET_EVOLUTIONS_ERROR';

export const GET_CONSULTANTS_REQUEST    = 'NETWORK/GET_CONSULTANTS_REQUEST';
export const GET_CONSULTANTS_SUCCESS    = 'NETWORK/GET_CONSULTANTS_SUCCESS';
export const GET_CONSULTANTS_ERROR      = 'NETWORK/GET_CONSULTANTS_ERROR';

export const GET_CONSULTANT_REQUEST     = 'NETWORK/GET_CONSULTANT_REQUEST';
export const GET_CONSULTANT_SUCCESS     = 'NETWORK/GET_CONSULTANT_SUCCESS';
export const GET_CONSULTANT_ERROR       = 'NETWORK/GET_CONSULTANT_ERROR';

export const GET_PARTNERS_REQUEST       = 'NETWORK/GET_PARTNERS_REQUEST';
export const GET_PARTNERS_SUCCESS       = 'NETWORK/GET_PARTNERS_SUCCESS';
export const GET_PARTNERS_ERROR         = 'NETWORK/GET_PARTNERS_ERROR';

export const ADD_PARTNER_REQUEST        = 'NETWORK/ADD_PARTNER_REQUEST';
export const ADD_PARTNER_SUCCESS        = 'NETWORK/ADD_PARTNER_SUCCESS';
export const ADD_PARTNER_ERROR          = 'NETWORK/ADD_PARTNER_ERROR';

export const UPDATE_PARTNER_REQUEST        = 'NETWORK/UPDATE_PARTNER_REQUEST';
export const UPDATE_PARTNER_SUCCESS        = 'NETWORK/UPDATE_PARTNER_SUCCESS';
export const UPDATE_PARTNER_ERROR          = 'NETWORK/UPDATE_PARTNER_ERROR';

export const UPDATE_PARTNER_SHIPPING_ADDRESS_REQUEST    = 'NETWORK/UPDATE_PARTNER_SHIPPING_ADDRESS_REQUEST';
export const UPDATE_PARTNER_SHIPPING_ADDRESS_SUCCESS    = 'NETWORK/UPDATE_PARTNER_SHIPPING_ADDRESS_SUCCESS';
export const UPDATE_PARTNER_SHIPPING_ADDRESS_ERROR      = 'NETWORK/UPDATE_PARTNER_SHIPPING_ADDRESS_ERROR';
