import React, {useEffect} from "react";
import Carousel from "react-grid-carousel";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Box, Stack, Typography} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import useMediaQuery from "@mui/material/useMediaQuery";

import theme from "../../../../assets/styles/theme/theme";
import {Link} from "../../../../helpers/multilingual";
import {getFormalizedName} from "../helpers/helper";
import {shopActions} from "../store/actions";
import {shopCategoriesSelector} from "../store/selectors";

const TopCategories = () => {

  const dispatch = useDispatch();
  const {i18n} = useTranslation();
  const categories = useSelector(shopCategoriesSelector);
  const isUpXl = useMediaQuery(theme => theme.breakpoints.up("xl"));
  const isUpLg = useMediaQuery(theme => theme.breakpoints.up("lg"));
  const isUpMd = useMediaQuery(theme => theme.breakpoints.up("md"));

  useEffect(() => {
    dispatch(shopActions.getCategoriesRequest(i18n.language));
  }, [i18n.language]);

  if (categories && !categories[i18n.language]) {
    return null;
  }

  return (
    <Box sx={{
      mt: '40px',
      '& .bghjHX': {
        margin: '10px 0 0',
      },
      '& .bVoXXg': {
        padding: '8px 0',
      },
      '& .MuiPaper-root': {
        boxShadow: '6px 6px 12px #00000021',
        borderRadius: '12px',
        padding: '4px',
        height: '78px',
        display: "block",
      }
    }}>
      <Container maxWidth={'xxl'}>
        <Typography
          sx={{
            fontSize: '24px',
            fontWeight: 'bold',
            fontFamily: 'Montserrat',
            color: theme.palette.primary.main
          }}>
            LES PRINCIPALES CATEGORIES
        </Typography>
        <Carousel cols={isUpXl ? 6 : isUpLg ? 5 : isUpMd ? 4 : 3} rows={3} gap={20} loop={true}>
          {categories?.[i18n.language]?.map((item) => (
            <Carousel.Item key={item.id}>
              <Card
                component={Link}
                to={`/shop/category/${getFormalizedName(item.name)}/${item.id}`}
              >
                <CardContent>
                  <Stack direction={"row"} gap={1}>
                    <Avatar src={item.image} sx={{width: 40, height: 40}}/>
                    <Typography
                      sx={{
                        fontFamily: 'Montserrat',
                        fontSize: {xs: '12px', sm: '12px', md: '12px', lg: '12px', xl: '11px', xxl: '12px'},
                        color: '#3C515E',
                        textTransform: 'uppercase',
                      }}>
                      {item.name}
                    </Typography>
                  </Stack>
                </CardContent>
              </Card>
            </Carousel.Item>
          ))}
        </Carousel>
      </Container>
    </Box>
  );
};

export default TopCategories;