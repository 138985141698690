import React from "react";
import { Trans } from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {makeStyles} from '@mui/styles';

import Cookie from "../../../../helpers/cookie";

import {cookieConsentActions} from "./store/actions";
import {isCookieConsentedSelector} from "./store/selectors";

const useStyle = makeStyles(theme => ({

  root: {
    '& .MuiDrawer-paperAnchorBottom': {
      '& .MuiTypography-root': {
        margin: 14,
        fontSize: 16,
        [theme.breakpoints.down('sm')]: {
          margin: 8,
          fontSize: 15,
        }
      },
      '& button': {
        margin: 7,
        fontSize: 16,
        [theme.breakpoints.down('sm')]: {
          marginTop: 0,
          marginBottom: 8,
          fontSize: 15,
        }
      }
    }
  }
}));
const CookieConsent = () => {

  const classes = useStyle();
  const dispatch = useDispatch();
  const isCookieConsented = useSelector(isCookieConsentedSelector);

  const handleCookieConsent = () => {
    Cookie.set(`cookie-consent`, true);
    dispatch(cookieConsentActions.consent());
  };

  return (
    <Drawer anchor={'bottom'} open={!isCookieConsented} className={classes.root} variant="persistent">
      <Grid container justify={"center"} alignItems={"center"}>
        <Grid item>
          <Typography align={"center"}>
            <Trans i18nKey={"cookieConsentMessage"}>This website uses cookies to enhance the user experience.</Trans>
          </Typography>
        </Grid>
        <Grid item>
          <Button
            variant='contained'
            disableElevation
            type="submit"
            color="primary"
            onClick={handleCookieConsent}
            sx={{
              borderRadius: '30px',
              color: '#fff',
            }}
          >
            <Trans i18nKey={"iUnderstand"}>I understand</Trans>
          </Button>
        </Grid>
      </Grid>
    </Drawer>
  );
};
export default CookieConsent;
