import React from "react";
import {Trans, useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {Box, Hidden, Menu, MenuItem, Stack, Typography} from "@mui/material";
import Button from "@mui/material/Button";

import {Link} from "../../../../helpers/multilingual";
import {userSelector} from "../../../Authentication/store/selectors";
import {shopCategoriesSelector} from "../store/selectors";

const ShopToolbar = ({sectionName, setSectionName, numbers, setView, setFilter}) => {
  const {i18n} = useTranslation();
  const categories = useSelector(shopCategoriesSelector);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const user = useSelector(userSelector);
  const open = Boolean(anchorEl);
  const handleOpenFilter = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseFilter = (filter) => {
    setFilter(filter);
    setAnchorEl(null);
  };

  if (!categories[i18n.language]) {
    return null;
  }

  return (
    <Box sx={{mb:1}}>
      <Stack direction={{xs: "column", sm: "row"}} gap={1} alignItems={"center"} justifyContent={"space-between"}>
        <Hidden smDown implementation={'css'}>
          <Stack direction={"row"} gap={1}>
            <Button
              key={'all'}
              color={"secondary"}
              variant={"outlined"}
              component={Link}
              to={`/shop`}
              sx={{
                borderRadius: '12px',
                height: '24px',
                width: '160px',
                fontFamily: 'Montserrat',
                fontWeight: '400',
                fontSize: '12px',
                border: sectionName === 'category' ? '1px solid #7C909B' : '1px solid #FFFFFF',
                backgroundColor: '#FFFFFF',
                color: '#3C515E'
              }}
            >
              <Trans i18nKey={"allProducts"}>All products</Trans>
            </Button>
            <Button
              key={'categories'}
              color={"secondary"}
              variant={"outlined"}
              onClick={() => setSectionName('category')}
              sx={{
                borderRadius: '12px',
                height: '24px',
                width: '90px',
                fontWeight: '400',
                fontFamily: 'Montserrat',
                fontSize: '12px',
                border: sectionName === 'category' ? '1px solid #7C909B' : '1px solid #FFFFFF',
                backgroundColor: sectionName === 'category' ? '#FFFFFF' : '#EEF1F2',
                color: sectionName === 'category' ? '#8CBD23' : '#3C515E'
              }}
            >
              <Trans i18nKey={"categories"}>Categories</Trans>
            </Button>
            {/*<Button
              key={'themes'}
              color={"secondary"}
              variant={"outlined"}
              onClick={() => setSectionName('theme')}
              sx={{
                borderRadius: '12px',
                height: '24px',
                width: '90px',
                fontWeight: '400',
                fontFamily: 'Montserrat',
                fontSize: '12px',
                border: sectionName === 'theme' ? '1px solid #7C909B' : '1px solid #FFFFFF',
                backgroundColor: sectionName === 'theme' ? '#FFFFFF' : '#EEF1F2',
                color: sectionName === 'theme' ? '#8CBD23' : '#3C515E'
              }}
            >
              <Trans i18nKey={"themes"}>Themes</Trans>
            </Button>*/}
            <Button
              key={'labos'}
              color={"secondary"}
              variant={"outlined"}
              onClick={() => setSectionName('brand')}
              sx={{
                borderRadius: '12px',
                height: '24px',
                width: '90px',
                fontWeight: '400',
                fontFamily: 'Montserrat',
                fontSize: '12px',
                border: sectionName === 'brand' ? '1px solid #7C909B' : '1px solid #FFFFFF',
                backgroundColor: sectionName === 'brand' ? '#FFFFFF' : '#EEF1F2',
                color: sectionName === 'brand' ? '#8CBD23' : '#3C515E'
              }}
            >
              <Trans i18nKey={"labos"}>Labos</Trans>
            </Button>
            {user && (
              <Button
                key={'favorites'}
                color={"secondary"}
                variant={"outlined"}
                onClick={() => setSectionName('favorites')}
                component={Link}
                to={`/shop/favorites`}
                sx={{
                  borderRadius: '12px',
                  height: '24px',
                  width: '90px',
                  fontWeight: '400',
                  fontFamily: 'Montserrat',
                  fontSize: '12px',
                  border: sectionName === 'favorites' ? '1px solid #7C909B' : '1px solid #FFFFFF',
                  backgroundColor: sectionName === 'favorites' ? '#FFFFFF' : '#EEF1F2',
                  color: sectionName === 'favorites' ? '#8CBD23' : '#3C515E'
                }}
              >
                <Trans i18nKey={"Favorites"}>Favoris</Trans>
              </Button>
            )}
          </Stack>
        </Hidden>
        <Stack direction={"row"} gap={1} alignItems={"center"}>
          <Typography>{numbers} produits</Typography>
          {/*<IconButton onClick={() => setView('grid')}>
            <GridViewSharpIcon/>
          </IconButton>
          <IconButton onClick={() => setView('list')}>
            <ViewListIcon/>
          </IconButton>*/}
          <div>
            <Button
              color={"secondary"}
              variant={"outlined"}
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleOpenFilter}
              sx={{
                borderRadius: '12px',
                height: '24px',
                fontFamily: 'Montserrat',
                fontSize: '12px',
                border: '1px solid #7C909B',
                backgroundColor: '#FFFFFF',
                color: '#8CBD23',
              }}
            >
                    Trier par
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleCloseFilter}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem onClick={() => handleCloseFilter('nameAsc')}>Ordre alphabétique</MenuItem>
              <MenuItem onClick={() => handleCloseFilter('priceAsc')}>Prix croissant</MenuItem>
              <MenuItem onClick={() => handleCloseFilter('priceDesc')}>Prix décroissant</MenuItem>
            </Menu>
          </div>

        </Stack>
      </Stack>
    </Box>
  );
};

export default ShopToolbar;
