import React from "react";
import {Helmet} from "react-helmet-async";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";

const ShopTitle = () => {

  const {t} = useTranslation();
  const {section, sectionName} = useParams();

  if(section === "category") {
    return (
      <Helmet>
        <title itemProp={"name"}>{t("shopTitle")} - Categorie {sectionName}</title>
        <meta property="og:title" content={`${t("shopTitle")} - Categorie ${sectionName}`}/>
        <meta name="description" content={`${sectionName} - ${t("shopMetaDescription")}`}/>
      </Helmet>
    );
  }

  if(section === "brand") {
    return (
      <Helmet>
        <title itemProp={"name"}>{t("shopTitle")} - Laboratoire {sectionName}</title>
        <meta property="og:title" content={`${t("shopTitle")} - Laboratoire ${sectionName}`}/>
        <meta name="description" content={`${sectionName} - ${t("shopMetaDescription")}`}/>
      </Helmet>
    );
  }

  return (
    <Helmet>
      <title itemProp={"name"}>{t("shopTitle")}</title>
      <meta property="og:title" content={t("shopTitle")}/>
      <meta name="description" content={t("shopMetaDescription")}/>
    </Helmet>
  );
};

export default ShopTitle;