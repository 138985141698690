import React from "react";
import {Redirect, Route, Switch} from 'react-router-dom';
import loadable from "@loadable/component";

import {RouteStatistics} from "../containers/Authentication/store/helper";

const StatisticsRoutes = () => {

  const Main = loadable(() => import("../containers/Admin/Stats/Main"));
  const Error404 = loadable(() => import("../containers/Pro/Error"));

  return (
    <Switch>
      <RouteStatistics exact path="/statistics/:section" component={Main}/>
      <Route path="*" component={Error404}/>
    </Switch>
  );
};

export default StatisticsRoutes;
