import React, {useEffect, useState} from "react";
import {Trans, useTranslation} from "react-i18next";
import { useSelector } from "react-redux";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import {makeStyles} from '@mui/styles';

import PageLoader from "../../../components/PageLoader";
import {SnackbarFormattedError} from "../../../helpers/snackbar";
import {userSelector} from "../../Authentication/store/selectors";
import CartDetails from "../../Customer/Shop/components/Cart/CartDetails";
import {shopService} from "../../Customer/Shop/store/services";

import Payment from "./components(old)/CustomerOrderCheckout/Payment";
import SelectShippingMethod from "./components(old)/CustomerOrderCheckout/SelectShippingMethod";
import ShippingAddressSelector from "./components(old)/CustomerOrderCheckout/ShippingAddressSelector";
import { customerSelector } from "./store/selectors";
import {customersService} from "./store/services";

const useStyles = makeStyles((theme) => ({
  root: {
    '& .block': {
      marginBottom: 10
    }
  },
  listItems: {
    [theme.breakpoints.up('sm')]: {
      overflow: 'auto'
    },
    '& img': {
      maxWidth: '100%',
      maxHeight: 60
    }
  },
  item: {
    [theme.breakpoints.up('sm')]: {
      '& .MuiListItem-root': {
        borderRight: '1px solid #DDD'
      }
    },
  },
}));

const CustomerOrderCheckout = (props) => {

  const classes = useStyles();
  const {t} = useTranslation();
  const user = useSelector(userSelector);
  const {recommendationId} = props.match.params;
  const customer = useSelector(customerSelector());
  const [products, setProducts] = useState(null);
  const [totalWeight, setTotalWeight] = useState(0);
  const [checkout, setCheckout] = useState(null);
  const [shippingMethod, setShippingMethod] = useState(null);
  const [shippingPickupPoint, setShippingPickupPoint] = useState(null);
  const [shippingAddress, setShippingAddress] = useState(customer.shippingAddress);

  useEffect(() => {
    shopService.getProductsByOrderId(customer.lang, recommendationId, shippingAddress.country).then(products => {
      setProducts(products);
    })
      .catch(e => SnackbarFormattedError(e.error));
  }, [recommendationId]);

  useEffect(() => {
    let weight = 0;
    if(products) {
      products.map(product => {
        weight += product.weight * product.quantity;
      });
      setTotalWeight(weight);
    }
  }, [products]);

  useEffect(() => {
    setCheckout(null);
    if(shippingAddress && shippingMethod) {
      customersService.getCustomerCheckout(customer.id, shippingMethod.id, shippingAddress.id, shippingPickupPoint, products).then((checkout) => {
        setCheckout(checkout);
      })
        .catch(e => SnackbarFormattedError(e.error));
    }
  }, [shippingAddress, shippingMethod]);

  const handleShippingAddressChange = (shippingAddress) => {
    setShippingMethod(null);
    setShippingAddress(shippingAddress);
  };

  const handleShippingMethodChange = (shippingMethod) => {
    setShippingMethod(shippingMethod);
  };

  const handleShippingPickupPointChange = (shippingPickupPoint) => {
    setShippingPickupPoint({
      id: shippingPickupPoint.id,
      name: shippingPickupPoint.name,
      type: shippingPickupPoint.type,
      street: shippingPickupPoint.street,
      number: shippingPickupPoint.number,
      zip: shippingPickupPoint.zip,
      city: shippingPickupPoint.city,
      country: shippingPickupPoint.country,
    });
  };

  if(!products) {
    return (
      <PageLoader height={300}/>
    );
  }
  return (
    <Card className={classes.root}>
      <CardContent>
        <Grid container justify="center" spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Grid container justify={"space-between"}>
              <Grid className={"block"} item xs={12}>
                <Typography component="h3" variant={"h6"}>
                  <Trans i18nKey={"order"}>Order</Trans> :
                </Typography>
                <Grid container>
                  <Grid item xs={12} sm={6}>
                    <List className={classes.listItems}>
                      {products.map((item, key) => (
                        <React.Fragment key={key}>
                          <ListItem className={classes.item}>
                            <Grid container justify={"center"} alignItems={"center"}>
                              <Grid item xs={4} sm={2}>
                                <Grid container justify={"center"} alignItems={"center"}>
                                  <img
                                    width={60}
                                    height={60}
                                    src={item.image[200]}
                                    alt={item.detail.name}
                                  />
                                </Grid>
                              </Grid>
                              <Grid item xs={8} sm={10}>
                                <Grid container alignItems={"center"}>
                                  <Grid item xs={12}>
                                    <Typography variant="subtitle1">
                                      <b>{item.quantity} x</b> {item.detail.name}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </ListItem>
                          {products.length > 1 && (
                            <Divider/>
                          )}
                        </React.Fragment>
                      ))}
                    </List>
                  </Grid>
                  {checkout?.order && (
                    <Grid item xs={12} sm={6}>
                      <CartDetails
                        className={classes.listItems}
                        subTotal={checkout.order.totalPrice}
                        shippingPrice={checkout.order.shippingPrice || 0}
                        taxes={checkout.order.taxesDetails}
                        total={parseFloat(checkout.order.totalPrice) + parseFloat(checkout.order.taxes)}
                        currency={shippingAddress.currency}
                        allowFreeShipping={user.allowFreeShipping}
                        isFreeShipping={checkout.order.shippingPrice === 0}
                        discount={checkout.order.discount}
                        proFee={checkout.order.proFee}
                        walletAmount={checkout.order.walletAmount}
                        giftCardAmount={checkout.order.giftCardAmount}
                        cartView={false}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid className={"block"} item xs={12}>
                <Typography component="h3" variant={"h6"}>
                  <Trans i18nKey={"billedTo"}>Billed to</Trans> :
                </Typography>
                <Typography component={"p"} variant="body1">
                  {customer.firstName} {customer.lastName}
                </Typography>
                {customer.company != null && customer.company !== '' && (
                  <Typography component={"p"} variant="body1">
                    {customer.company} <br/>
                  </Typography>
                )}
                {customer.taxNumber != null && customer.taxNumber !== '' && (
                  <Typography component={"p"} variant="body1">
                    {t('vat')}: {customer.taxNumber} <br/>
                  </Typography>
                )}
                <Typography component={"p"} variant="body1">
                  {customer.address}
                </Typography>
              </Grid>
              {totalWeight > 0 && (
                <>
                  <Grid className={"block"} item xs={12}>
                    <ShippingAddressSelector customer={customer} onChange={handleShippingAddressChange} selected={shippingAddress}/>
                  </Grid>
                  {shippingAddress && (
                    <Grid className={"block"} item xs={12}>
                      <SelectShippingMethod customer={customer} freeShipping={checkout?.order?.shippingPrice === 0} weight={totalWeight} methodSelected={shippingMethod} shippingAddress={shippingAddress} shippingPickupPoint={shippingPickupPoint} onMethodChange={handleShippingMethodChange} onPickupPointChange={handleShippingPickupPointChange}/>
                    </Grid>
                  )}
                </>
              )}
              {checkout && (shippingMethod || totalWeight === 0) && (
                <Grid className={"block"} item xs={12}>
                  <Payment checkout={checkout}/>
                </Grid>
              )}

              {(!shippingAddress && totalWeight > 0) && (
                <Typography><Trans i18nKey={"shippingAddressRequired"}>You need to add a shipping address to access the payment form</Trans></Typography>
              )}
              {(!shippingMethod && totalWeight > 0) && (
                <Typography><Trans i18nKey={"deliveryMethodRequired"}>You need to select a delivery method to access the payment form</Trans></Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
export default CustomerOrderCheckout;
