import React from 'react';
import {useTranslation} from "react-i18next";
import {InputBase, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";

import theme from "../../../../assets/styles/theme/theme";
import {SnackbarError, SnackbarInfo, SnackbarSuccess} from "../../../../helpers/snackbar";
import {homeService} from "../store/services";

export default function NewsletterForm() {

  const {t} = useTranslation();
  const [formSubmitted, setFormSubmitted] = React.useState(false);
  const [email, setEmail] = React.useState("");

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const validateEmail = (email) => (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email));
  const handleSubmit = (event) => {


    event.preventDefault();
    if (!email) {
      SnackbarInfo(t('Please enter your email'));
      return;
    }
    if (!validateEmail(email)) {
      SnackbarInfo(t('Please enter a valid email'));
      return;
    }
    setFormSubmitted(true);
    homeService.subscribeToNewsletter(email).then(() => {
      SnackbarSuccess(t('You have successfully subscribed to our newsletter'));
      setFormSubmitted(false);
    }).catch((error) => {
      SnackbarError(t('Something went wrong'));
      console.log(error);
      setFormSubmitted(false);
    });
  };

  return (
    <Container maxWidth={"xxl"} sx={{mt: '40px'}}>
      <Card sx={{boxShadow: 'unset'}}>
        <CardContent>
          <Grid container spacing={2} justifyContent={"center"} alignContent={"center"} alignItems={"center"}>
            <Grid item xs={12} sm={6}>
              <Typography
                variant={"h5"}
                sx={{
                  fontSize: '24px',
                  fontWeight: 'bold',
                  fontFamily: 'Montserrat',
                  textTransform: 'uppercase',
                  color: theme.palette.primary.main,
                }}>
                {t('We have high standards for email too')}
              </Typography>
              <Typography sx={{
                color: 'primary.light',
                fontFamily: 'Open sans',
                fontWeight: '400',
                fontSize: {xs: '1rem', sm: '0.9rem', md: '1rem', lg: '1.1rem', xl: '1.2rem', xxl: '1.3rem'},
              }}>
                  En vous abonnant à notre newsletter, vous recevrez régulièrement des informations sur les nouveaux produits, les invitations à nos évènements, des conseils d'experts et des astuces pour optimiser votre santé et votre bien-être.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper
                component="form"
                sx={{
                  p: '2px 4px',
                  display: 'flex',
                  alignItems: 'center',
                  width: {xs: '100%', sm: 400},
                  boxShadow: 'unset',
                  borderRadius: '12px',
                  backgroundColor: '#F5F5F5',
                  margin: '0 auto',
                }}
              >
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  name="email"
                  label={t('Email')}
                  placeholder={t('Enter your email')}
                  value={email ? email : ""}
                  onChange={handleEmailChange}
                  required
                />
                <Button
                  onClick={handleSubmit}
                  disabled={formSubmitted}
                  type="button"
                  sx={{
                    p: '10px',
                    '&:hover': {
                      textDecoration: 'underline'
                    }
                  }}>
                  {t('Subscribe')}
                </Button>
              </Paper>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Container>
  );
}
