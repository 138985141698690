import React, {useEffect, useState} from "react";
import { Trans, useTranslation } from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useLocation} from "react-router-dom";
import AddShoppingCartOutlinedIcon from "@mui/icons-material/AddShoppingCartOutlined";
import CloseIcon from "@mui/icons-material/Close";
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { grey } from "@mui/material/colors";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import {makeStyles} from '@mui/styles';

import {getLangPath} from "../../../../helpers/multilingual";
import {SnackbarConfirm, SnackbarFormattedError, SnackbarSuccess} from "../../../../helpers/snackbar";
import {walletSelector} from "../../../Customer/Wallet/store/selectors";
import {proShopActions as shopProActions} from "../../Shop/store/actions";
import { customersService } from "../store/services";


const useStyle = makeStyles((theme) => ({

  root: {
    overflow: 'auto',
    minWidth: 400,
    '& .MuiContainer-root': {
      marginTop: 20,
      marginBottom: 20
    },
    '& .giftCardItem': {
      '& .MuiTypography-h6': {
        whiteSpace: 'nowrap'
      }
    }
  },
  closeButton: {
    top: 10,
    right: 15,
    cursor: 'pointer',
    position: 'absolute',
    fontSize: 36,
    color: grey[500],
    [theme.breakpoints.down('xs')]: {
      top: 10,
      right: 20,
      fontSize: 40,
    },
  },
  addProductButton: {
    marginBottom: 30
  }
}));

const CustomerOfferGiftCardModal = ({customer, setOpen, open, onChange}) => {

  const classes = useStyle();
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const checkoutResponse = searchParams.get('checkout_response');
  const userWallet = useSelector(walletSelector());
  const [amounts, setAmounts] = useState({});
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  useEffect(() => {
    if(['succeeded', 'canceled', 'errored'].includes(checkoutResponse)) {
      setOpen(true);
      window.history.pushState('', '', `${location.pathname}`);
    }
  }, [checkoutResponse]);

  useEffect(() => {
    loadGiftCards();
  }, []);

  const loadGiftCards = () => {
    customersService.getGiftCards().then(giftCards => {
      setAmounts({
        amount5: parseInt(giftCards.filter(gift => !gift.usedAt && gift.amount === 5).length),
        amount10: parseInt(giftCards.filter(gift => !gift.usedAt && gift.amount === 10).length),
        amount15: parseInt(giftCards.filter(gift => !gift.usedAt && gift.amount === 15).length),
        amount20: parseInt(giftCards.filter(gift => !gift.usedAt && gift.amount === 20).length)
      });
    });
  };

  const handleClose = () => {
    setOpen(false);
    onChange();
  };

  const handleConvertGiftCard = (amount) => () => {
    SnackbarConfirm(t('buyGiftCardForCustomerDescription'), () => {
      handleClose();
      let id = "";
      switch (amount) {
        case 5:
          id = "8f9f86bc-32e3-4d99-83e1-86d12ad1035d";
          break;
        case 10:
          id = "b143ff74-5363-4f1d-91f2-82f177664ec8";
          break;
        case 15:
          id = "d381b2d9-d126-45e9-b607-4de61942cdd0";
          break;
        case 20:
          id = "90d58af4-f961-4803-83f8-a67cb0626be5";
          break;
      }
      dispatch(shopProActions.openCheckout({
        items: [{
          id: id,
          quantity: 1
        }],
        redirect: `${getLangPath()}/pro/customer/${customer.id}/gifts`
      }));
    });
  };

  const handleConfirmAndSendGiftCardModal = (amount) => {

    SnackbarConfirm(t('sendTheGiftCard'), () => {
      handleSendGiftCardModal(amount);
    });
  };

  const handleSendGiftCardModal = (amount) => {

    customersService.getGiftCards().then(giftCards => {
      const giftCard = giftCards.filter(giftCard => giftCard.amount === amount)[0];
      customersService.sendCustomerGiftCard(customer.id, giftCard.id).then(() => {
        SnackbarSuccess(t('giftCardSent'));
        loadGiftCards();
        handleClose();
      })
        .catch(e => {
          SnackbarFormattedError(e.error);
        });
    });
  };

  return (
    <Dialog onClose={handleClose} open={open} fullScreen={fullScreen}>
      <CloseIcon className={classes.closeButton} onClick={handleClose}/>
      <DialogTitle id="responsive-dialog-title"><Trans i18nKey="offerGiftCard">Offer a gift card</Trans></DialogTitle>
      <DialogContent>
        <Card elevation={0} className={classes.root}>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={6} sm={3}>
                <Card className={"giftCardItem"}>
                  <CardContent>
                    <Typography component={"div"} align={"center"} gutterBottom>
                      <Typography>
                        <Trans
                          i18nKey={"amountGiftCard"}
                          values={{count: 5, currency: userWallet.currency}}
                        >
                                                    5 gift cards
                        </Trans>
                      </Typography>
                      <Typography
                        color={amounts.amount5 ? "secondary" : "error"}
                        variant={"h6"}
                      >
                        <Trans
                          i18nKey={"giftCardAvailable"}
                          values={{count: amounts.amount5}}
                        >
                          {amounts.amount5} available
                        </Trans>
                      </Typography>
                      <Divider/>
                      {amounts.amount5 > 0 && (
                        <Button color={"secondary"} fullWidth onClick={() => handleConfirmAndSendGiftCardModal(5)}>
                          <EmailOutlinedIcon/>
                        </Button>
                      )}
                      {amounts.amount5 === 0 && (
                        <Button fullWidth onClick={handleConvertGiftCard(5)}>
                          <AddShoppingCartOutlinedIcon/>
                        </Button>
                      )}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={6} sm={3}>
                <Card className={"giftCardItem"}>
                  <CardContent>
                    <Typography component={"div"} align={"center"} gutterBottom>
                      <Typography>
                        <Trans
                          i18nKey={"amountGiftCard"}
                          values={{count: 10, currency: userWallet.currency}}
                        >
                                                    10 gift cards
                        </Trans>
                      </Typography>
                      <Typography
                        color={amounts.amount10 ? "secondary" : "error"}
                        variant={"h6"}
                      >
                        <Trans
                          i18nKey={"giftCardAvailable"}
                          values={{count: amounts.amount10}}
                        >
                          {amounts.amount10} available
                        </Trans>
                      </Typography>
                      <Divider/>
                      {amounts.amount10 > 0 && (
                        <Button color={"secondary"} fullWidth onClick={() => handleConfirmAndSendGiftCardModal(10)}>
                          <EmailOutlinedIcon/>
                        </Button>
                      )}
                      {amounts.amount10 === 0 && (
                        <Button fullWidth onClick={handleConvertGiftCard(10)}>
                          <AddShoppingCartOutlinedIcon/>
                        </Button>
                      )}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={6} sm={3}>
                <Card className={"giftCardItem"}>
                  <CardContent>
                    <Typography component={"div"} align={"center"} gutterBottom>
                      <Typography>
                        <Trans
                          i18nKey={"amountGiftCard"}
                          values={{count: 15, currency: userWallet.currency}}
                        >
                                                    15 gift cards
                        </Trans>
                      </Typography>
                      <Typography
                        color={amounts.amount15 ? "secondary" : "error"}
                        variant={"h6"}
                      >
                        <Trans
                          i18nKey={"giftCardAvailable"}
                          values={{count: amounts.amount15}}
                        >
                          {amounts.amount15} available
                        </Trans>
                      </Typography>
                      <Divider/>
                      {amounts.amount15 > 0 && (
                        <Button color={"secondary"} fullWidth onClick={() => handleConfirmAndSendGiftCardModal(15)}>
                          <EmailOutlinedIcon/>
                        </Button>
                      )}
                      {amounts.amount15 === 0 && (
                        <Button fullWidth onClick={handleConvertGiftCard(15)}>
                          <AddShoppingCartOutlinedIcon/>
                        </Button>
                      )}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={6} sm={3}>
                <Card className={"giftCardItem"}>
                  <CardContent>
                    <Typography component={"div"} align={"center"} gutterBottom>
                      <Typography>
                        <Trans
                          i18nKey={"amountGiftCard"}
                          values={{count: 20, currency: userWallet.currency}}
                        >
                                                    20 gift cards
                        </Trans>
                      </Typography>
                      <Typography
                        color={amounts.amount20 ? "secondary" : "error"}
                        variant={"h6"}
                      >
                        <Trans
                          i18nKey={"giftCardAvailable"}
                          values={{count: amounts.amount20}}
                        >
                          {amounts.amount20} available
                        </Trans>
                      </Typography>
                      <Divider/>
                      {amounts.amount20 > 0 && (
                        <Button color={"secondary"} fullWidth onClick={() => handleConfirmAndSendGiftCardModal(20)}>
                          <EmailOutlinedIcon/>
                        </Button>
                      )}
                      {amounts.amount20 === 0 && (
                        <Button fullWidth onClick={handleConvertGiftCard(20)}>
                          <AddShoppingCartOutlinedIcon/>
                        </Button>
                      )}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </DialogContent>
    </Dialog>
  );
};
export default CustomerOfferGiftCardModal;
