import React from "react";
import ReactGA from "react-ga4";
import {Trans, useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import AddShoppingCart from "@mui/icons-material/AddShoppingCart";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import {makeStyles} from '@mui/styles';

import theme from "../../../../../assets/styles/theme/theme";
import {SnackbarConfirm, SnackbarInfo} from "../../../../../helpers/snackbar";
import {getProductPriceWithTaxes, METAGENICSID} from "../../../../../helpers/utils";
import {userSelector} from "../../../../Authentication/store/selectors";
import { shopActions } from "../../store/actions";

const useStyles = makeStyles(() => ({
  root: {
    paddingBottom: 20,
    '& .MuiOutlinedInput-root': {
      height: 38
    },
  }
}));

const AddToCart = ({product, sx}) => {

  const dispatch = useDispatch();
  const {t} = useTranslation();
  const classes = useStyles();
  const user = useSelector(userSelector);

  function getCategoriesObject() {
    const categories = {};
    product.categories.map((c, i) => {
      categories[`item_category${i ? i+1 : ''}`] = c.name;
    });
    return categories;
  }

  const addToCart = () => {
    if(user) {
      if(!user.isMetagenicsAllowed && product.productBrandId === METAGENICSID ) {
        SnackbarInfo(t("metagenicsNotAllowed"));
        return;
      }

      const priceWithDiscount = getProductPriceWithTaxes(product, user, user?.shippingAddress, 1, true);
      const priceWithoutDiscount = getProductPriceWithTaxes(product, user, user?.shippingAddress, 1, false);
      const discount = (priceWithoutDiscount - priceWithDiscount).toFixed(2);

      if (product.outOfStock) {
        SnackbarConfirm(t('outOfStockWarning'), () => {

          dispatch(shopActions.addToCart(product, 1));

          ReactGA.event("add_to_cart", {
            user_id: user?.id,
            currency: "EUR",
            value: priceWithDiscount,
            items: [
              {
                item_id: product.id,
                item_name: product.reference,
                discount: discount,
                index: 0,
                item_brand: product.brand.name,
                ...getCategoriesObject(),
                price: priceWithDiscount,
                quantity: 1
              }
            ]
          });
        },
        () => {return},
        "Commander");
      } else {

        dispatch(shopActions.addToCart(product, 1));

        ReactGA.event("add_to_cart", {
          user_id: user?.id,
          currency: "EUR",
          value: priceWithDiscount,
          items: [
            {
              item_id: product.id,
              item_name: product.reference,
              discount: discount,
              index: 0,
              item_brand: product.brand.name,
              ...getCategoriesObject(),
              price: priceWithDiscount,
              quantity: 1
            }
          ]
        });
      }
    } else {
      dispatch(shopActions.SetDrawersOpen("account", true));
    }
  };

  if(!product.allowInCart) {
    return (
      <Typography className={classes.root} align={"center"}>
        <Trans i18nKey={"productNoLongerInShop"}>This product is no longer in the shop</Trans>
      </Typography>
    );
  }

  let addToCartSelectOptions = [];
  for (let i = 1; i < 100; i++) {
    addToCartSelectOptions.push(<MenuItem key={i} value={i}>{i}</MenuItem>);
  }

  return (
    <Button
      variant={"contained"}
      onClick={addToCart}
      color={"primary"}
      sx={{
        borderRadius: '24px',
        color: '#fff',
        fontSize: '1rem',
        fontFamily: 'Montserrat',
        height: '48px',
        margin: 'auto',
        textTransform: 'capitalize',
        '&:hover': {
          backgroundColor: '#fff',
          color: theme.palette.primary.main,
        },
        ...sx
      }}
    >
      <Trans i18nKey="addToCart">Add to cart</Trans> <AddShoppingCart/>
    </Button>
  );
};
export default AddToCart;

{/*<FormControl variant="outlined">
        <Select
          MenuProps={{
            disableScrollLock: true,
          }}
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={addTocCartAmount}
          onChange={handleChange}
          sx={{width: '60px'}}
        >
          {addToCartSelectOptions}
        </Select>
      </FormControl>*/}
