import React from "react";
import {Helmet} from "react-helmet-async";
import {useTranslation} from "react-i18next";

import AboutSection1 from "./components/AboutSection1";
import AboutSection2 from "./components/AboutSection2";
import AboutSection3 from "./components/AboutSection3";
import AboutSection4 from "./components/AboutSection4";

const About = () => {

  const {t} = useTranslation();

  return (
    <div>
      <Helmet>
        <title itemProp={"name"}>A propos de nous | Nutri-logics</title>
        <meta property="og:title" content={"A propos de nous | Nutri-logics"}/>
        <meta name="description" content={t("aboutMetaDescription")}/>
        <link rel="alternate" href={`${process.env.REACT_APP_WEB_URL}`} hrefLang="fr" key="fr"/>
        <link rel="alternate" href={`${process.env.REACT_APP_WEB_URL}/en`} hrefLang="en" key="en"/>
        <link rel="alternate" href={`${process.env.REACT_APP_WEB_URL}`} hrefLang="x-default"/>
      </Helmet>

      <AboutSection1/>
      <AboutSection2/>
      <AboutSection3/>
      <AboutSection4/>
    </div>
  );
};

export default About;