import React from 'react';
import {Grid, Slide, Stack, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";

const section = {
  "name": "Scientific Team",
  "type": "banner",
  "bannerTitle1": "Notre équipe",
  "bannerTitle2": "scientifique",
  "bannerText": "Ensemble, ils ont décidé de réunir leurs compétences au service d’un objectif :",
  "bannerText2": "permettre à ceux qui souhaitent améliorer leur santé d’avoir accès, enfin, à des compléments alimentaires véritablement efficaces.",
  "bannerLink": "/",
  "bannerImage": `${process.env.REACT_APP_API_RESOURCES_URL}/sections/images/Photo_ScientificTeam-3.png`
};
export default function CustomSectionTeamBanner() {

  const containerRef = React.useRef(null);

  return (
    <Box
      sx={{
        width: '100%',
        margin: '40px 0 60px',
        padding: '1px 0',
        overflowX: 'clip'
      }}>
      <Container
        maxWidth={'xxl'}>
        <Grid container spacing={2} justifyContent={"space-between"} flexDirection={"row"} sx={{ml: '-8px !important'}}>
          <Grid item xs={12} sm={6}>
            <Box component={'img'} src={section.bannerImage} alt={section.bannerTitle}
              sx={{
                // width: {xs: 'auto', sm: '480px'},
                maxWidth: {xs: '100%', sm: '100%'},
                position: {xs: 'inherit', sm: 'relative', md: 'relative'},
                bottom: {xs: '0px', sm: '20px', md: '20px'},
                right: {xs: 'unset', sm: 'unset', md: 'unset'},
                left: {xs: 'unset', sm: 'unset', md: 'unset'},
                display: 'block',
                margin: 'auto'
              }}/>
          </Grid>
          <Grid item xs={12} sm={6} sx={{pl: '24px', pr: '24px'}}>
            <Stack gap={2} sx={{pt: '3vw'}} alignItems={'flex-start'} ref={containerRef}>
              <Slide direction="down" in={true} container={containerRef.current}>
                <Typography
                  sx={{
                    fontFamily: 'Raleway',
                    fontWeight: '600',
                    padding: '8px 8px',
                    fontSize: {xs: '2.5rem', sm: '2rem', md: '2.5rem', lg: '3.5rem', xl: '3.5rem', xxl: '4.5rem'},
                    lineHeight: '0.7',
                    overflow: 'hidden',
                    color: 'green',
                    textAlign: {xs: 'left', sm: 'left'},
                    zIndex: 1,
                    '& p': {
                      margin: 0,
                    },
                    '& span': {
                      fontFamily: 'Raleway',
                      fontWeight: '600',
                      fontSize: {xs: '2.5rem', sm: '2rem', md: '2.5rem', lg: '3.5rem', xl: '3.5rem', xxl: '4.5rem'},
                    }
                  }}>
                  {section.bannerTitle1} <br/> <Typography component={"span"} sx={{color: 'primary.main'}}>{section.bannerTitle2}</Typography>
                </Typography>
              </Slide>
              <Slide
                direction="left"
                in={true}
                container={containerRef.current}
              >
                <Typography
                  sx={{
                    fontSize: {xs: '1rem', sm: '0.9rem', md: '1rem', lg: '1.1rem', xl: '1.2rem', xxl: '1.3rem'},
                    padding: '0 8px 8px 8px',
                    fontFamily: 'Open Sans',
                    maxWidth: {xs: '100%', sm: '100%'},
                    lineHeight: '1.4',
                    overflow: 'hidden',
                    textAlign: 'left',
                    color: 'primary.light',
                    zIndex: 1,
                    '& p': {
                      margin: 0,
                    }
                  }}>
                  {section.bannerText} <br/> {section.bannerText2}
                </Typography>
              </Slide>
            </Stack>
          </Grid>

        </Grid>
      </Container>
    </Box>
  );
}
