import React, {useEffect, useState} from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {Box} from "@mui/material";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormHelperText from "@mui/material/FormHelperText";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";

import theme from "../../../../assets/styles/theme/theme";
import {SnackbarConfirm, SnackbarFormattedError, SnackbarInfo, SnackbarSuccess} from "../../../../helpers/snackbar";
import { authenticationActions } from "../../../Authentication/store/actions";
import { userSelector } from "../../../Authentication/store/selectors";
import { authenticationService } from "../../../Authentication/store/services";
import {mailingService} from "../../Mailing/store/services";

const Settings = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const [mailingSubscription, setMailingSubscription] = useState(false);

  useEffect(() => {
    setMailingSubscription(user.allowMailing);
  }, [user]);

  const handleSignOutAllDevices = () => {
    SnackbarConfirm(t("signOutAllDevices"), () => {
      dispatch(authenticationActions.signOutAllDevices());
    });
  };

  const handleSendPasswordRecoveryEmail = () => {
    authenticationService.passwordRecovery(user.email)
      .then(() => {
        SnackbarSuccess(t("updatePasswordEmailSent", {email: user.email}));
      });
  };

  const handleChangeMailingSubscription = (e) => {
    if(e.target.checked) {
      mailingService.subscribe()
        .then(() => {
          dispatch(authenticationActions.setMailingSubscription(true));
          SnackbarSuccess(t("emailAddedToMailingList"));
        })
        .catch(err => {
          SnackbarFormattedError(err.error);
        });
    }
    else {
      mailingService.unsubscribe(user.email, user.id)
        .then(() => {
          dispatch(authenticationActions.setMailingSubscription(false));
          SnackbarInfo(t("emailRemovedFromMailingList"));
        })
        .catch(err => {
          SnackbarFormattedError(err.error);
        });
    }
  };

  return (
    <Box sx={{
      '& .MuiFormLabel-root': {
        color: theme.palette.primary.main,
        fontFamily: 'Open sans',
        fontSize: '1.2rem',
        mb: 2
      },
      '& .MuiTypography-root': {
        fontFamily: 'Open sans',
        fontSize: '0.8rem',
        color: '#7C909B',
        mb: 2
      },
      '& .MuiButton-root': {
        fontSize: '0.8rem',
        textTransform: 'capitalize',
        borderRadius: '30px',
        color: '#fff',
        lineHeight: '1.3',
        padding: '4px 8px',
        textAlign: 'center',
        mb: 1
      }
    }}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <FormControl component="fieldset">
            <FormLabel component="legend">
              <Trans i18nKey={"emailNotifications"}>
                Email notifications
              </Trans>
            </FormLabel>
            <FormGroup>
              <FormControlLabel
                control={<Switch checked={mailingSubscription} onChange={handleChangeMailingSubscription} name="mailingSubscription" />}
                label={t("mailingCampaignFromNL")}
              />
            </FormGroup>
            <FormHelperText>
              <Trans i18nKey={"youCanChangeThisAtEveryMoment"}>
                You can change your choice at any time from this page
              </Trans>
            </FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <FormControl component="fieldset">
          <FormLabel component="legend">
            <Trans i18nKey={"security"}>
              Security
            </Trans>
          </FormLabel>
          <Button color={"primary"} variant={"contained"} component={"a"} onClick={() => handleSendPasswordRecoveryEmail()}>
            <Trans i18nKey="askForEmailPassword">
              Request a password change email
            </Trans>
          </Button>
          <Button color={"primary"} variant={"contained"} component={"a"} onClick={() => handleSignOutAllDevices()}>
            <Trans i18nKey="signOutAllDevices">
              Sign out from all devices
            </Trans>
          </Button>
          <Button color={"primary"} variant={"contained"} component={"a"} onClick={() => {dispatch(authenticationActions.signOut())}}>
            <Trans i18nKey="signOut">
              Sign out
            </Trans>
          </Button>
        </FormControl>
      </Grid>
    </Box>
  );
};
export default Settings;
