import i18next from "i18next";
import { call, put, select,takeEvery } from 'redux-saga/effects';

import { getLangPath } from "../../../../helpers/multilingual";
import { SnackbarError, SnackbarSuccess } from "../../../../helpers/snackbar";
import { history } from "../../../../helpers/store";

import { walletActions } from "./actions";
import * as actionsType from './constants';
import { walletService } from "./services";

export const bankSagaKey = 'bank';

const userSelector = (state) => state.authentication.user;

function* getWallet() {

  try {
    let wallet = yield call(walletService.getWallet);
    yield put(walletActions.getWalletSuccess(wallet));
  }
  catch (e) {
    yield put(walletActions.getWalletError());
  }
}

function* getWalletHistories(action) {

  try {
    let data = yield call(walletService.getWalletHistories, action.year, action.month);
    yield put(walletActions.getWalletHistoriesSuccess(data));
  }
  catch (e) {
    yield put(walletActions.getWalletHistoriesError());
  }
}

function* getWalletCommissionsCreditNotes(action) {

  try {
    let data = yield call(walletService.getWalletCommissionsCreditNotes);
    yield put(walletActions.getWalletCommissionsCreditNotesSuccess(data));
  }
  catch (e) {
    yield put(walletActions.getWalletCommissionsCreditNotesError());
  }
}

function* transfer(action) {

  try {
    const user = (yield select(userSelector));
    if(!user.bankAccountHolder || !user.iban) {
      yield put(walletActions.closeTransferModal());
      yield put(walletActions.transferError());
      SnackbarError(i18next.t('errorMessageCompleteBankDetails'));
      history.push(`${getLangPath()}/pro/settings/account`);
      return;
    }
    let wallet = yield call(walletService.transfer);
    yield put(walletActions.transferSuccess(wallet));
    yield put(walletActions.closeTransferModal());
    SnackbarSuccess(i18next.t('transferRegistered'));
    history.push(`${getLangPath()}/pro/commissions/transfers`);
  }
  catch (e) {
    SnackbarError(i18next.t('errorMessageAnErrorOccurred'));
    yield put(walletActions.transferError());
  }
}

export default function* watchWallet() {

  yield takeEvery(actionsType.GET_WALLET_REQUEST, getWallet);
  yield takeEvery(actionsType.GET_WALLET_HISTORIES_REQUEST, getWalletHistories);
  yield takeEvery(actionsType.GET_WALLET_COMMISSIONS_CREDIT_NOTES_REQUEST, getWalletCommissionsCreditNotes);
  yield takeEvery(actionsType.TRANSFER_REQUEST, transfer);
}
