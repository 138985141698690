import React from "react";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import EventNoteOutlinedIcon from "@mui/icons-material/EventNoteOutlined";
import LocalShippingTwoToneIcon from '@mui/icons-material/LocalShippingTwoTone';
import ReplayTwoToneIcon from '@mui/icons-material/ReplayTwoTone';
import ScheduleIcon from '@mui/icons-material/Schedule';
import StoreTwoToneIcon from '@mui/icons-material/StoreTwoTone';
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { blue, green, grey, orange, purple,red } from "@mui/material/colors";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {makeStyles} from '@mui/styles';

import useMoment from "../../../../helpers/useMoment";


const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiCard-root': {
      '& .MuiCardContent-root': {
        '& .MuiSvgIcon-root': {
          fill: purple[900],
          fontSize: '1.8em',
        },
        '&:hover': {
          backgroundColor: grey[50],
          cursor: 'pointer'
        }
      },
      height: '100%'
    },
    '& .MuiButtonBase-root': {
      padding: '0 10px'
    },
    '& .mention': {
      marginLeft: 20
    },
    '& .item': {
      '& .MuiGrid-container': {
        height: '100%'
      },
      '& .MuiCardContent-root': {
        height: '100%',
        padding: theme.spacing(1, 2)
      },
      '& .MuiCardContent-root:last-child': {
        paddingBottom: theme.spacing(1)
      },
      '& .headerNote': {
        borderBottom: `1px solid ${grey[400]}`,
      },
      '& .date': {
        fontSize: 14,
      },
      '& .note': {
        display: 'inline-block',
        borderRadius: 8,
        whiteSpace: 'pre-wrap',
      },
    },
  },
}));

const DeliveryList = ({orders, withoutDetails, onClick}) => {

  const classes = useStyles();
  const moment = useMoment();
  const getIcon = (order) => {

    let icon;
    let color = green[700];
    if(moment() > moment(order.sent).add('4', 'days')) {
      color = orange[700];
    }
    if(moment() > moment(order.sent).add('7', 'days')) {
      color = red[700];
    }

    switch (order.shippingStatus) {
      case 'waiting':
      case 'processing':
        icon = <ScheduleIcon style={{fill: color}}/>;
        break;
      case 'delivered':
      case 'noShipping':
        color = green[600];
        icon = <CheckCircleOutlineIcon style={{fill: color}}/>;
        break;
      case 'inTransit':
        icon = <LocalShippingTwoToneIcon style={{fill: color}}/>;
        break;
      case 'inPickupPoint':
        icon = <StoreTwoToneIcon style={{fill: color}}/>;
        break;
      case 'backToSender':
        color = red[600];
        if(order.status === 'gotBack' || order.refunded === "totally") {
          color = green[600];
        }
        icon = <ReplayTwoToneIcon style={{fill: color}}/>;
        break;
      default:
        color = grey[600];
        icon = <EventNoteOutlinedIcon style={{fill: color}}/>;
    }
    if(order.status === 'saved') {
      icon = <EventNoteOutlinedIcon style={{fill: blue[600]}}/>;
    }
    if(order.status !== 'gotBack' && (order.refunded === "totally" || order.deleted)) {
      icon = <EventBusyIcon style={{fill: red[600]}}/>;
    }
    return icon;
  };

  return (
    <Grid container spacing={1} className={classes.root}>
      {orders.map(order => (
        <Grid key={order.id} className={"item"} item xs={12} md={withoutDetails ? 12 : 6}>
          <Card elevation={1} rounded="true" square={false} onClick={() => onClick(order)}>
            <CardContent>
              <Grid container spacing={2} alignItems={"center"}>
                <Grid item xs={2} md={2} lg={1}>
                  <Typography align={"center"}>
                    {getIcon(order)}
                  </Typography>
                </Grid>
                <Grid item xs={10} md={10} lg={withoutDetails ? 10 : 5}>
                  <Typography>{moment(order.createdAt).format('LLL')} - <Typography component={"span"} sx={{fontWeight: "bold"}}>{(order.totalPrice + order.taxes).toFixed(2)}€</Typography></Typography>
                  {order.sent && (
                    <Grid container alignItems={"center"}>
                      <Grid item>
                        <ArrowRightAltIcon/>
                      </Grid>
                      <Grid item>
                        <Typography>{moment(order.sent).format('LLL')}</Typography>
                      </Grid>
                    </Grid>
                  )}
                  {order.status === 'saved' && (
                    <Typography>Recommandation</Typography>
                  )}
                  {!!order.invoice && (
                    <Typography>Facture n°{order.invoice.number}</Typography>
                  )}
                  {!!order.proformaInvoice && (
                    <Typography>Facture nulle n°{order.proformaInvoice.number}</Typography>
                  )}
                  {!!order.orderForm && (
                    <Typography>Bon de commande n°{order.orderForm.number}</Typography>
                  )}
                  {!withoutDetails && (
                    <Typography>
                      {order.client.firstName} {order.client.lastName}
                      {order.status === 'published' && order.userId === order.parentId && (
                        ` - Achat pro`
                      )}
                      {order.status === 'free' && (
                        ` - Commande offerte`
                      )}
                      {order.status === 'deferred' && (
                        ` - Commande différée`
                      )}
                    </Typography>
                  )}
                </Grid>
                {!withoutDetails && (
                  <Grid item xs={12} md={12} lg={6} className={"note"}>
                    {/* eslint-disable-next-line max-len */}
                    {order.notes && order.notes.slice((order.notes.length > 3) ? Math.max(order.notes.length - 3, 1) : 0).map(note => (
                      <Grid item xs={12} key={note.id}>
                        <Grid className={"headerNote"} container justify={"space-between"} alignItems={"flex-end"}>
                          <Grid item>
                            {/* eslint-disable-next-line max-len */}
                            <Typography className={'date'}>{moment(note.createdAt).format('DD/MM HH:mm')} - {note.author.firstName} {note.author.lastName}</Typography>
                          </Grid>
                        </Grid>
                        <Typography className={'note'} color={"error"}>
                          {note.content}
                        </Typography>
                      </Grid>
                    ))}
                  </Grid>
                )}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default DeliveryList;
