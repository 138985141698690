import React, { useEffect, useState } from "react";
import {Trans, useTranslation} from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {Box, Stack} from "@mui/material";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {makeStyles} from '@mui/styles';

import FormValidator from "../../components/Validators/FormValidator";
import TextFieldValidator from "../../components/Validators/TextFieldValidator";
import { getLangPath } from "../../helpers/multilingual";
import { SnackbarFormattedError, SnackbarSuccess } from "../../helpers/snackbar";
import { history } from "../../helpers/store";
import { authenticationActions } from "../Authentication/store/actions";
import { contactSelector, userSelector } from "../Authentication/store/selectors";
import { authenticationService } from "../Authentication/store/services";

import { sessionSelector } from "./Shop/store/selectors";

const useStylesContact = makeStyles((theme) => ({
  container: {
    paddingBottom: 40,
    paddingTop: {xs: '0', sm: '11vw'},
    '& p': {
      fontSize: 18
    },
    '& img': {
      maxWidth: '100%',
      borderRadius: '10px'
    },

    '& .MuiButton-root': {
      borderRadius: 8,
      fontSize: 16,
      height: 46,
      padding: '8px 20px',
      margin: theme.spacing(3, 0, 2),
      '& .MuiSvgIcon-root': {
        margin: '0px 5px 0px 0px !important',
        fontSize: '1.1rem'
      },
    },
  },
  pageTitle: {
    paddingTop: 50,
    paddingBottom: 30,
    '& > h1': {
      fontWeight: 'bold',
      marginBottom: 5
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: 0,
    },
  },
  form: {
    marginBottom: 30
  },
  phoneAndAddress: {
    '& > MuiGrid-container': {},
    '& .MuiSvgIcon-root': {
      fontSize: 60,
      border: '2px solid',
      borderRadius: '50%',
      padding: 10
    },
    '& a': {
      textDecoration: 'inherit',
      color: 'inherit',
      fontSize: 18
    }
  }
}));

const Contact = (props) => {

  const classes = useStylesContact();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const session = useSelector(sessionSelector);
  const contact = useSelector(contactSelector);
  const [firstName, setFirstName] = useState(user && user.firstName || '');
  const [lastName, setLastName] = useState(user && user.lastName || '');
  const [phone, setPhone] = useState(user && user.phone || '');
  const [email, setEmail] = useState(user && user.email || '');
  const [message, setMessage] = useState('');

  useEffect(() => {
    if(props.match.params.id || session?.parentId) {
      dispatch(authenticationActions.getUserContactRequest(props.match.params.id || session?.parentId));
    }
  }, []);

  const handleSubmit = () => {

    authenticationService.sendContactMessage({
      firstName,
      lastName,
      phone,
      email,
      message
    }).then(() => {
      SnackbarSuccess(t("messageSent"));
      history.push(`${getLangPath()}`);
    })
      .catch(e => {
        SnackbarFormattedError(e.error);
      });
  };

  return (
    <Box sx={{
      pt: {xs: '0', sm: '11vw'},
      pb: 10,
    }}>
      <Container fixed maxWidth="xl">
        <div className={classes.pageTitle}>
          <Typography component="h1" variant="h4" color={"primary"}>
            <Trans i18nKey="contactUsTitle">
              Contact us
            </Trans>
          </Typography>
        </div>
        <Grid container justifyContent={"center"}>
          <Grid item xs={12} sm={12} md={6} xl={6}>
            <FormValidator
              className={classes.form}
              noValidate
              onSubmit={handleSubmit}
              onError={console.log}
            >
              <Grid container spacing={2}>
                {!user && (
                  <>
                    <Grid item xs={12} sm={12}>
                      <TextFieldValidator
                        variant="outlined"
                        required
                        fullWidth
                        id="firstName"
                        label={t("firstName")}
                        name="firstName"
                        value={firstName}
                        onChange={e => setFirstName(e.target.value)}
                        autoFocus
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextFieldValidator
                        variant="outlined"
                        required
                        fullWidth
                        id="lastName"
                        label={t("lastName")}
                        name="lastName"
                        value={lastName}
                        onChange={e => setLastName(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextFieldValidator
                        required
                        variant="outlined"
                        fullWidth
                        id="phone"
                        label={t("phone")}
                        type="tel"
                        name="phone"
                        value={phone}
                        onChange={e => setPhone(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextFieldValidator
                        variant="outlined"
                        required
                        fullWidth
                        id="email"
                        label={t("email")}
                        type="email"
                        name="email"
                        autoComplete="email"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                      />
                    </Grid>
                  </>
                )}
                <Grid item xs={12} lg={12} md={12} sm={12}>
                  <TextFieldValidator
                    variant="outlined"
                    required
                    fullWidth
                    multiline
                    rows={8}
                    id="message"
                    label={t("message")}
                    name="message"
                    value={message}
                    onChange={e => setMessage(e.target.value)}
                  />
                </Grid>
              </Grid>

              <Button
                variant='contained'
                disableElevation
                onClick={() => {}}
                type="submit"
                color={"primary"}
                sx={{
                  mt: 1,
                  borderRadius: '35px',
                  color: 'white'
                }}>
                <Trans i18nKey="contactSendMessageButton">
                      Send the message
                </Trans>
              </Button>
            </FormValidator>
          </Grid>
          <Grid item xs={12} sm={12} md={6} xl={6} className={classes.phoneAndAddress} >
            <Grid container justifyContent={"flex-end"} spacing={2}>
              <Grid item xs={12} sm={6} md={8}>
                <Stack gap={1}>
                  <Typography component={'h3'} variant={'h6'}>
                    <Trans i18nKey={"phone"}>Phone</Trans>
                  </Typography>
                  <Typography component={'a'} href={`tel:${contact ? contact.phone : ''}`} variant={'body1'}>
                            +352 28 33 98 98 <br/>
                            +32 (0) 78 70 90 20 <br/>
                            +33 (0)9 70 44 16 45
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={6} md={8}>
                <Stack gap={1}>
                  <Typography component={'h3'} variant={'h6'}>
                    <Trans i18nKey={"email"}>Email</Trans>
                  </Typography>
                  <Typography component={'a'} href={`tel:${contact ? contact.email : 'mailto:info@nutri-logics.com'}`} variant={'body1'}>
                            info@nutri-logics.com
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={6} md={8}>
                <Stack gap={1}>
                  <Typography component={'h3'} variant={'h6'}>
                            Nutri-Logics
                  </Typography>
                  <Typography component={'p'} variant={'body1'}>
                            Duarrefstroos 31, <br/>
                            LU - 9990 Weiswampach
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Contact;
