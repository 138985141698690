import React, { forwardRef, useState } from "react";
import { Link as DomLink, Redirect as DomRedirect, useHistory } from "react-router-dom";
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import {Grid, IconButton, Typography} from "@mui/material";
import Fade from "@mui/material/Fade";
import MuiLink from '@mui/material/Link';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import I18n from "i18next";
import i18next from "i18next";

export const LANGUAGES = ['FR', 'EN', 'NL'];
export const LANGUAGES_JSON = {'FR': '', 'EN': '', 'NL': ''};

export const getLangPath = () => {
  switch (I18n.language) {
    case 'fr':
      return ``;
    case 'nl':
      return `/nl`;
    default: {
      return `/en`;
    }
  }
};

export const initLanguage = (lang) => {

  if(I18n.language !== lang) {
    I18n.changeLanguage(lang);
  }
};

export const Link = forwardRef(({className, color, onClick, to, children, title, target, onMouseEnter, onMouseLeave, onMouseOver, style, underline = 'none', sx}, ref) => (
  <MuiLink
    ref={ref}
    underline={underline}
    component={DomLink}
    className={className}
    to={`${getLangPath()}${to}`}
    onClick={onClick}
    title={title}
    target={target}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
    onMouseOver={onMouseOver}
    style={style}
    sx={sx}
  >
    {children}
  </MuiLink>
));

export const Redirect = ({to, params}) => (
  <DomRedirect to={{pathname: `${getLangPath()}${to}`, ...params}}/>
);

export const LangSelector = ({className, preview}) => {

  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleChange = (lang) => {
    if (i18next.language !== lang) {
      history.push(`${lang !== 'fr' ? `/${lang}` : ``}${history.location.pathname.replace(getLangPath(), '')}`);
      i18next.changeLanguage(lang);
    }
    setAnchorEl(null);
  };

  return (
    <div className={className}>
      <Grid container justifyContent={"center"} alignItems={"center"} onClick={handleClick}>
        <IconButton>
          <LanguageOutlinedIcon fontSize={"small"}/>
        </IconButton>
        {preview && (
          <Typography component={"i"}>{i18next.language.toUpperCase()}</Typography>
        )}
      </Grid>
      <Menu
        id="fade-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <MenuItem onClick={() => handleChange("en")}>EN</MenuItem>
        <MenuItem onClick={() => handleChange("fr")}>FR</MenuItem>
        <MenuItem onClick={() => handleChange("nl")}>NL</MenuItem>
      </Menu>
    </div>
  );
};
