import React, {useState} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {TextField} from "@mui/material";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import moment from "moment/moment";

import ButtonWithLoader from "../../../../components/ButtonWithLoader";
import FormValidator from "../../../../components/Validators/FormValidator";
import SelectValidator from "../../../../components/Validators/SelectValidator";
import TextFieldValidator from "../../../../components/Validators/TextFieldValidator";
import {SnackbarInfo} from "../../../../helpers/snackbar";
import { authenticationActions } from "../../../Authentication/store/actions";
import { updateUserLoading,userSelector } from "../../../Authentication/store/selectors";

const Account = () => {

  const {t} = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const [updateData, setUpdateData] = useState(false);
  const loading = useSelector(updateUserLoading);
  const [data, setData] = useState({
    firstName: user?.firstName,
    lastName: user?.lastName,
    email: user?.email,
    birthDate: moment(user.birthDate).format('YYYY-MM-DD'),
    noBirthDate: user?.noBirthDate,
    gender: user?.gender,
    phone: user?.phone,
    lang: 'fr',
    company: user?.company,
  });

  const handleSubmit = () => {

    if(data.email !== user?.email) {
      SnackbarInfo(t('emailUpdateRequested', {email: data.email}));
    }

    dispatch(authenticationActions.updateUserRequest({
      ...data,
    }));
  };

  const handleChange = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value
    });
  };

  const handleChangeNoBirthDate = (value) => {
    setData({
      ...data,
      noBirthDate: value,
      birthDate: "2000-01-01",
    });
  };

  return (
    <React.Fragment key={user?.id}>
      <FormValidator
        onSubmit={handleSubmit}
        onError={errors => console.log(errors)}
        noValidate
      >
        <Grid
          container
          spacing={2}
          sx={{
            '& .MuiInputBase-root': {
              fontFamily: 'Open sans',
              fontSize: '0.8rem',
              color: '#7C909B',
              height: '48px',
              borderRadius: '30px'
            },
            '& .MuiFormLabel-root': {
              fontFamily: 'Open sans',
              fontSize: '0.8rem',
              color: '#7C909B',
              fontStyle: 'italic'
            }
          }}>
          <Grid item xs={12} sm={6}>
            <TextFieldValidator
              required
              fullWidth
              name="firstName"
              label={t("firstName")}
              autoComplete="fname"
              disabled={!updateData}
              value={data.firstName || ""}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextFieldValidator
              required
              fullWidth
              id="lastName"
              name="lastName"
              label={t("lastName")}
              autoComplete="lname"
              disabled={!updateData}
              value={data.lastName || ""}
              onChange={handleChange}
            />
          </Grid>
          {updateData && (
            <Grid item xs={12}>
              <SelectValidator
                fullWidth
                name="gender"
                label={"Genre"}
                value={data.gender || "mister"}
                onChange={handleChange}
                required
              >
                <MenuItem value="mister">
                Monsieur
                </MenuItem>
                <MenuItem value="miss">
                Madame
                </MenuItem>
                <MenuItem value="none">
                Non genré
                </MenuItem>
              </SelectValidator>
            </Grid>
          )}
          <Grid item xs={12}>
            {!data.noBirthDate ?
              <TextFieldValidator
                fullWidth
                required
                name="birthDate"
                label={t("birthDate")}
                type="date"
                disabled={!updateData}
                value={data?.birthDate || ""}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              :
              <TextField
                disabled
                fullWidth
                label={t("birthDate")}
                defaultValue={'**/**/****'}
              />
            }
          </Grid>
          {updateData && (
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={data?.noBirthDate || false}
                    onChange={() => {
                      handleChangeNoBirthDate(!data.noBirthDate);
                    }}
                    value="true" color="primary"/>
                }
                label={"Ne souhaite pas la communiquer"}
                sx={{
                  '& .MuiFormControlLabel-label': {
                    fontFamily: 'Open sans',
                    fontSize: '0.8rem',
                    color: '#7C909B',
                  }
                }}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <TextFieldValidator
              fullWidth
              id="email"
              type="email"
              name="email"
              label={t("email")}
              disabled={!updateData}
              value={data.email || ""}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextFieldValidator
              id={"phone"}
              fullWidth
              required
              name="phone"
              type="tel"
              label={t("phone")}
              disabled={!updateData}
              value={data?.phone || ""}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextFieldValidator
              fullWidth
              name="company"
              label={t("company")}
              disabled={!updateData}
              value={data?.company || ""}
              onChange={handleChange}
            />
          </Grid>
          {!updateData && (
            <Grid item xs={12}>
              <Typography
                sx={{
                  fontSize: '0.8rem',
                  fontStyle: 'italic',
                  padding: '4px',
                  borderRadius: '8px',
                  color: 'rgb(34, 154, 22)',
                  textAlign: 'center',
                }}
              >
              Si vous êtes assujetti, veuillez nous contacter pour nous communiquer votre numéro de TVA
              </Typography>
            </Grid>
          )}

          {updateData && (
            <Grid item xs={12}>
              <ButtonWithLoader
                type="submit"
                isLoading={loading}
                variant="contained"
                color={"primary"}
              >
                Enregistrer les informations
              </ButtonWithLoader>
            </Grid>
          )}
          {!updateData && (
            <Grid item xs={12}>
              <Button
                onClick={() => setUpdateData(true)}
                variant="contained"
                color={"primary"}
                sx={{
                  borderRadius: 30,
                  fontSize: '0.875rem',
                  height: 46,
                  padding: '8px 20px',
                  color: '#fff',
                  fontFamily: 'Montserrat',
                  textTransform: 'capitalize',
                  '&MuiButton-contained.Mui-disabled': {
                    color: 'rgba(255, 255, 255, 0.26)'
                  }
                }}
              >
                  Modifier les informations
              </Button>
            </Grid>
          )}
        </Grid>
      </FormValidator>
    </React.Fragment>

  );
};
export default Account;
