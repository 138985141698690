import React, {useEffect} from 'react';
import ReactGA from "react-ga4";
import {useLocation} from "react-router-dom";
import { ThemeProvider } from '@mui/material/styles';

import './helpers/i18n';

import theme from "./assets/styles/theme/theme";
import Router from "./routes/Router";

function App() {

  const location = useLocation();
  ReactGA.initialize("GTM-WFDVP8K");

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: document.title});
  }, [location]);

  return (
    <ThemeProvider theme={theme}>
      <Router/>
    </ThemeProvider>
  );
}

export default App;
