import React from "react";
import {Trans, useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import LinkIcon from '@mui/icons-material/Link';
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {makeStyles} from '@mui/styles';

import {getLangPath} from "../../../../../helpers/multilingual";
import {SnackbarContent, SnackbarFormattedError, SnackbarInfo} from "../../../../../helpers/snackbar";
import {isPro, userSelector} from "../../../../Authentication/store/selectors";
import {shopService} from "../../store/services";

const useStyles = makeStyles(theme => ({
  snackMessage: {
    padding: 20,
    '& h4': {
      fontSize: 20
    },
    '& .action button': {
      backgroundColor: theme.palette.common.black
    },
  }
}));

const ShareCartButton = ({order, className, variant, color, children}) => {

  const classes = useStyles();
  const {t} = useTranslation();
  const user = useSelector(userSelector);
  const userIsPro = useSelector(isPro);

  const handleCopyToClipboard = () => {
    const elem = document.createElement('textarea');
    elem.value = `${process.env.REACT_APP_WEB_URL}${getLangPath()}/${user.directLink}/shop/recommendation/${order.id}`;
    document.body.appendChild(elem);
    elem.select();
    document.execCommand('copy');
    document.body.removeChild(elem);
    SnackbarInfo(t('linkCopiedInClipboard'));
  };

  const handleShowLink = () => {

    try {
      if(order.status === 'draft') {
        shopService.saveOrder(order?.id);
      }
      SnackbarContent(
        <Grid container spacing={2} className={classes.snackMessage}>
          <Grid item>
            <Typography variant={"h4"} align={"left"}>
              <Trans i18nKey={"shareLink"}>Share link</Trans>:
            </Typography>
          </Grid>
          <Grid item>
            <Typography align={"left"}>
              {`${process.env.REACT_APP_WEB_URL}${getLangPath()}/${user.directLink}/shop/recommendation/${order.id}`}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className={"action"} align={"right"}>
              <Button variant={"contained"} size={"small"} color={"primary"} onClick={handleCopyToClipboard}>
                {t("copyInClipboard")}
              </Button>
            </Typography>
          </Grid>
        </Grid>,
        () => {}, 'large');
    }
    catch (e) {
      SnackbarFormattedError(e.error);
    }
  };

  if(!userIsPro || !order || !user.directLink) {
    return null;
  }

  return (
    <Button startIcon={<LinkIcon/>} className={className} variant={variant} color={color} onClick={handleShowLink}>
      {children}
    </Button>
  );
};
export default ShareCartButton;