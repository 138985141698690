import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";

import {shopActions} from "../containers/Customer/Shop/store/actions";
import {shopService} from "../containers/Customer/Shop/store/services";

import {SnackbarFormattedError} from "./snackbar";

const useCheckOrderPayment = (orderId, withoutRestriction) => {

  let attempts = 0;
  const [payment, setPayment] = useState({status: "checking"});
  const dispatch = useDispatch();

  useEffect(() => {

    checkOrder();
  }, []);

  const checkOrder = () => {

    ++attempts;
    shopService.getOrderStatus(orderId, withoutRestriction).then((data) => {

      switch (data.status) {
        case "requiresPaymentMethod":
        case "succeeded":
        case "canceled":
        case "failed":
          setPayment({status: data.status, userId: data.userId});
          break;
        case "requiresAction":
        case "processing":
          if(attempts > 6) {
            setPayment({...data});
          }
          else {
            setTimeout(function() {
              checkOrder();
            }, 2000);
          }
          break;
        default:
          setPayment({status: data.status, userId: data.userId});
      }
    })
      .catch((e) => {
        console.log(e.error);
        if(e?.error?.type === "not-found-error") {
          dispatch(shopActions.SetDrawersOpen("checkout", true));
        } else {
          SnackbarFormattedError(e.error);
        }
      });

  };

  return payment;
};

export default useCheckOrderPayment;
