import React, {useState} from "react";
import {Trans} from "react-i18next";
import {Rating} from "@mui/lab";
import Button from "@mui/material/Button";
import { green } from "@mui/material/colors";
import Typography from "@mui/material/Typography";
import {makeStyles} from '@mui/styles';
import i18n from "i18next";

import useMoment from "../../../../../helpers/useMoment";

const useStyles = makeStyles(theme => ({
  root: {
    '& .author': {
      fontSize: 14,
      margin: theme.spacing(1),
    },
    '& .review': {
      display: 'inline-block',
      minWidth: '40vw',
      borderRadius: 8,
      whiteSpace: 'pre-wrap',
      margin: theme.spacing(1),
    },
    '& .answer': {
      color: green[800],
      display: 'inline-block',
      minWidth: '40vw',
      borderRadius: 8,
      whiteSpace: 'pre-wrap',
      margin: theme.spacing(1),
    },
  }
}));

const Review = ({review}) => {

  const classes = useStyles();
  const moment = useMoment();

  const [translation, setTranslation] = useState(false);

  const handleShowTranslation = () => {
    setTranslation(true);
  };

  const handleHideTranslation = () => {
    setTranslation(false);
  };

  return (
    <div className={classes.root}>
      <Typography className={'author'}>
        {review.author} - {moment(review.createdAt).format('LL')}
      </Typography>
      <div>
        <Rating name="disabled" value={parseFloat(review.rating)} precision={0.5} disabled />
      </div>
      <Typography className={`review`} gutterBottom>
        {!translation ? review.review : review.translation}
      </Typography>
      {review.answer && (
        <Typography className={"answer"}>
          <Typography>
                        AGS -
          </Typography>
          {!translation ? review.answer.review : review.answer.translation}
        </Typography>
      )}
      {review.translation && review.lang !== i18n.language && !translation && (
        <div>
          <Button variant={"outlined"} size={"small"} onClick={handleShowTranslation}>
            <Trans i18nKey={"translate"}>Translate</Trans>
          </Button>
        </div>
      )}
      {review.review && review.lang !== i18n.language && translation && (
        <div>
          <Button variant={"outlined"} size={"small"} onClick={handleHideTranslation}>
            <Trans i18nKey={"showOriginal"}>Show original</Trans>
          </Button>
        </div>
      )}
    </div>
  );
};
export default Review;