import produce from "immer";

import * as actions from './constants';
export const initialState = {
  list : [],
  customer : null,
  anamnesis: null,
  redirections: null,
  loading: false,
};

export const customersReducerKey = 'customers';

export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case actions.GET_CUSTOMERS_REQUEST:
        break;
      case actions.GET_CUSTOMERS_SUCCESS:
        draft.list = action.customers;
        break;
      case actions.GET_CUSTOMERS_ERROR:
        draft.error = action.error;
        break;
      case actions.GET_CUSTOMER_REQUEST:
        draft.customer = initialState.customer;
        break;
      case actions.GET_CUSTOMER_SUCCESS:
        draft.customer = action.customer;
        break;
      case actions.GET_CUSTOMER_ERROR:
        draft.error = action.error;
        draft.customer = initialState.customer;
        break;
      case actions.GET_CUSTOMER_ORDERS_REQUEST:
        break;
      case actions.GET_CUSTOMER_ORDERS_SUCCESS:
        if(draft.customer) {
          draft.customer.orders = action.customerOrders;
        }
        break;
      case actions.GET_CUSTOMER_ORDERS_ERROR:
        draft.error = action.error;
        break;
      case actions.GET_CUSTOMER_ANAMNESES_REQUEST:
        break;
      case actions.GET_CUSTOMER_ANAMNESES_SUCCESS:
        if(draft.customer) {
          draft.customer.anamneses = action.anamneses;
        }
        break;
      case actions.GET_CUSTOMER_ANAMNESES_ERROR:
        draft.error = action.error;
        break;
      case actions.ADD_CUSTOMER_REQUEST:
        draft.customer = initialState.customer;
        draft.loading = true;
        break;
      case actions.ADD_CUSTOMER_SUCCESS:
        draft.customer = action.customer;
        draft.loading = false;
        break;
      case actions.ADD_CUSTOMER_ERROR:
        draft.error = action.error;
        draft.loading = false;
        draft.customer = initialState.customer;
        break;
      case actions.ADD_CUSTOMER_ORDER_REQUEST:
        draft.loading = true;
        break;
      case actions.ADD_CUSTOMER_ORDER_SUCCESS:
        draft.loading = false;
        if(draft.customer && draft.customer.orders) {
          draft.customer.orders = [
            action.customerOrder,
            ...draft.customer.orders
          ];
        }
        break;
      case actions.ADD_CUSTOMER_ORDER_ERROR:
        draft.loading = false;
        draft.error = action.error;
        break;
      case actions.UPDATE_CUSTOMER_REQUEST:
        draft.loading = true;
        break;
      case actions.UPDATE_CUSTOMER_SUCCESS:
        draft.loading = false;
        draft.customer = action.customer;
        break;
      case actions.UPDATE_CUSTOMER_ERROR:
        draft.loading = false;
        draft.error = action.error;
        break;
      case actions.UPDATE_CUSTOMER_SHIPPING_ADDRESS_REQUEST:
        draft.loading = true;
        break;
      case actions.UPDATE_CUSTOMER_SHIPPING_ADDRESS_SUCCESS:
        draft.loading = false;
        draft.customer.shippingAddress = action.shippingAddress;
        break;
      case actions.UPDATE_CUSTOMER_SHIPPING_ADDRESS_ERROR:
        draft.loading = false;
        draft.error = action.error;
        break;
      case actions.GET_CUSTOMER_ANAMNESIS_REQUEST:
        break;
      case actions.GET_CUSTOMER_ANAMNESIS_SUCCESS:
        draft.anamnesis = action.anamnesis;
        break;
      case actions.GET_CUSTOMER_ANAMNESIS_ERROR:
        draft.error = action.error;
        break;
      case actions.ADD_CUSTOMER_ANAMNESIS_NOTE_REQUEST:
        draft.loading = true;
        break;
      case actions.ADD_CUSTOMER_ANAMNESIS_NOTE_SUCCESS:
        draft.loading = false;
        draft.anamnesis.notes = [...draft.anamnesis.notes, action.note];
        break;
      case actions.ADD_CUSTOMER_ANAMNESIS_NOTE_ERROR:
        draft.loading = false;
        draft.error = action.error;
        break;
      case actions.DELETE_CUSTOMER_ANAMNESIS_NOTE_REQUEST:
        draft.loading = true;
        break;
      case actions.DELETE_CUSTOMER_ANAMNESIS_NOTE_SUCCESS:
        draft.loading = false;
        draft.anamnesis.notes = draft.anamnesis.notes.filter(note => note.id !== action.note.id);
        break;
      case actions.DELETE_CUSTOMER_ANAMNESIS_NOTE_ERROR:
        draft.loading = false;
        draft.error = action.error;
        break;
      case actions.REQUEST_SUPPORT_REQUEST:
        break;
      case actions.REQUEST_SUPPORT_SUCCESS:
        draft.anamnesis.support = true;
        break;
      case actions.REQUEST_SUPPORT_ERROR:
        draft.error = action.error;
        break;
      case actions.GET_CUSTOMER_REDIRECTIONS_REQUEST:
        break;
      case actions.GET_CUSTOMER_REDIRECTIONS_SUCCESS:
        draft.redirections = action.customers;
        break;
      case actions.GET_CUSTOMER_REDIRECTIONS_ERROR:
        draft.error = action.error;
        break;
      default:
    }
  }
  );
