import React, { useEffect, useState } from "react";
import { Trans } from "react-i18next";
import { useDispatch } from "react-redux";
import CheckCircleTwoToneIcon from "@mui/icons-material/CheckCircleTwoTone";
import EventNoteOutlinedIcon from "@mui/icons-material/EventNoteOutlined";
import LocalShippingTwoToneIcon from "@mui/icons-material/LocalShippingTwoTone";
import ReplayTwoToneIcon from "@mui/icons-material/ReplayTwoTone";
import StoreTwoToneIcon from "@mui/icons-material/StoreTwoTone";
import TimerTwoToneIcon from "@mui/icons-material/TimerTwoTone";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { green, grey, orange, red } from "@mui/material/colors";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {makeStyles} from '@mui/styles';

import useMoment from "../../../../helpers/useMoment";
import OrderStatus from "../../../Customer/Account/components/Orders/components/OrderStatus";
import { customersActions } from "../store/actions";

import AddCustomerOrderModal from "./AddCustomerOrder/AddCustomerOrderModal";
import CustomerOrderDetails from "./CustomerOrderDetails";
import CustomerOrderRecommendationDetails from "./CustomerOrderRecommendationDetails";

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiAccordionSummary-root': {
      padding: theme.spacing(1),
      '&:hover': {
        backgroundColor: grey[100]
      }
    },
    '& .MuiSvgIcon-root': {
      fontSize: '1.8em'
    },
    '& .iconGrid': {
      display: 'inline-grid'
    },
    '& .MuiAccordion-root.Mui-expanded:before': {
      opacity: 1
    },
    '& .MuiAccordionSummary-content.Mui-expanded': {
      margin: 0
    },
    '& .MuiAccordion-root.Mui-expanded': {
      margin: 0
    }
  },
}));

const CustomerOrdersCard = ({customer}) => {

  const classes = useStyles();
  const dispatch = useDispatch();
  const moment = useMoment();
  const [expanded, setExpanded] = useState('');
  const [isOrderModalOpen, setOrderModalOpen] = useState(false);

  useEffect(() => {
    dispatch(customersActions.getCustomerOrdersRequest(customer.id));
  }, [customer.id]);

  const handleReloadOrders = () => {
    dispatch(customersActions.getCustomerOrdersRequest(customer.id));
  };

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleOrderModalOpen = () => {
    setOrderModalOpen(true);
  };

  const handleOrderModalClose = () => {
    setOrderModalOpen(false);
  };

  const getIcon = (order) => {

    let icon;
    let color = green[700];
    if(moment() > moment(order.sent).add('4', 'days')) {
      color = orange[700];
    }
    if(moment() > moment(order.sent).add('7', 'days')) {
      color = red[700];
    }

    switch (order.shippingStatus) {
      case 'delivered':
        color = green[600];
        icon = <CheckCircleTwoToneIcon style={{fill: color}}/>;
        break;
      case 'inTransit':
        icon = <LocalShippingTwoToneIcon style={{fill: color}}/>;
        break;
      case 'waiting':
      case 'processing':
        icon = <TimerTwoToneIcon style={{fill: color}}/>;
        break;
      case 'inPickupPoint':
        icon = <StoreTwoToneIcon style={{fill: color}}/>;
        break;
      case 'backToSender':
        color = red[600];
        if(order.status === 'gotBack') {
          color = green[600];
        }
        icon = <ReplayTwoToneIcon style={{fill: color}}/>;
        break;
      default:
        if(order.status !== 'recommendation' && order.shippingStatus === 'noShipping') {
          color = green[600];
          icon = <CheckCircleTwoToneIcon style={{fill: color}}/>;
        }
        else {
          color = grey[600];
          icon = <EventNoteOutlinedIcon style={{fill: color}}/>;
        }

    }
    return icon;
  };

  return (
    <Card className={classes.root}>
      <CardContent>
        <Grid container justify="center" spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Grid container justify={"space-between"}>
              <Grid item xs={6}>
                <Typography component="h3" variant={"h6"}>
                  <Trans i18nKey={"orders"}>Orders</Trans> :
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography align={"right"}>
                  <Button variant="outlined" onClick={handleOrderModalOpen} size={"large"} color={"primary"} disableElevation>
                    <Trans i18nKey={"createOrder"}>Create order</Trans>
                  </Button>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            {!customer.orders || customer.orders.length === 0 && (
              <Typography><Trans i18nKey={"noOrder"}>No orders</Trans></Typography>
            )}
            {customer.orders && customer.orders.map(order => (
              <Accordion key={order.id} square expanded={expanded === order.id} onChange={handleChange(order.id)} elevation={0}>
                <AccordionSummary>
                  <Grid container alignItems={"center"}>
                    <Grid className={"iconGrid"} item xs={2} md={2} lg={1}>
                      <Typography align={"center"}>
                        {getIcon(order)}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography><b>{moment(order.createdAt).format('LL')} {order.status !== 'recommendation' && (` - ${Math.round((parseFloat(order.totalPrice) + parseFloat(order.taxes))*100)/100} ${order.currency}`)}</b> - <OrderStatus order={order}/></Typography>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  {order.status === 'published' && (
                    <CustomerOrderDetails order={order} onChange={handleReloadOrders}/>
                  )}
                  {order.status === 'gotBack' && (
                    <CustomerOrderRecommendationDetails order={order} onChange={handleReloadOrders}/>
                  )}
                  {order.status === 'recommendation' && (
                    <CustomerOrderRecommendationDetails order={order} onChange={handleReloadOrders}/>
                  )}
                  {order.status === 'free' && (
                    <CustomerOrderRecommendationDetails order={order} onChange={handleReloadOrders}/>
                  )}
                  {order.status === 'deferred' && (
                    <CustomerOrderRecommendationDetails order={order} onChange={handleReloadOrders}/>
                  )}
                </AccordionDetails>
              </Accordion>
            ))}
          </Grid>
        </Grid>
      </CardContent>
      <AddCustomerOrderModal handleClose={handleOrderModalClose} open={isOrderModalOpen}/>
    </Card>
  );
};
export default CustomerOrdersCard;
