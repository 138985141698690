import axios  from '../../../../helpers/axios';

export const homeService = {
  getTreesCount,
  getTopbar,
  getSliders,
  getDisplayedSections,
  subscribeToNewsletter
};

function getTreesCount() {

  return new Promise((resolve, reject) => {

    axios.get(`/shop/trees/count`)
      .then(({trees}) => {
        resolve(trees);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getTopbar(lang) {

  return new Promise((resolve, reject) => {

    axios.get(`/admin/home/topbar/${lang}`)
      .then(({topbar}) => {
        resolve({topbar});
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getSliders(lang) {

  return new Promise((resolve, reject) => {
    axios.get(`/admin/home/sliders/${lang}`)
      .then(({sliders}) => {
        resolve(sliders);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getDisplayedSections(lang) {

  return new Promise((resolve, reject) => {

    axios.get(`/admin/home/sections/${lang}`)
      .then(({sections}) => {
        resolve(sections);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function subscribeToNewsletter(email) {

  return new Promise((resolve, reject) => {

    axios.post(`/user/newsletter/subscribe`, {email})
      .then(({data}) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}