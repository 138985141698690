import React from "react";
import {useTranslation} from "react-i18next";
import {Stack, Typography} from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import theme from "../../../assets/styles/theme/theme";

const HelpBox = () => {

  const {t} = useTranslation();

  return (
    <Card sx={{
      mt: 4,
      boxShadow: 'none',
      background : '#F8F9FA',
    }}>
      <CardContent>
        <Stack gap={1}>
          <Typography variant="h5" sx={{
            color: theme.palette.primary.main,
            fontFamily: 'Open sans',
            fontSize: '1.2rem',
          }}>
            {t("helpBoxTitle")}
          </Typography>
          <Typography variant="body1" sx={{
            fontFamily: 'Open sans',
            fontSize: '0.8rem',
            color: '#7C909B',
          }}>
            {t("helpBoxText")}
          </Typography>
          <Typography component={"a"} href={"mailto:info@nutri-logics.com"} sx={{
            color: theme.palette.primary.main,
          }}>
              info@nutri-logics.com
          </Typography>
          <Typography variant="body1" sx={{
            fontFamily: 'Open sans',
            fontSize: '0.9rem',
            color: '#7C909B',
          }}>
              +352 28 33 98 98
          </Typography>
          <Typography variant="body1" sx={{
            fontFamily: 'Open sans',
            fontSize: '0.9rem',
            color: '#7C909B',
          }}>
              +32 (0) 78 70 90 20
          </Typography>
          <Typography variant="body1" sx={{
            fontFamily: 'Open sans',
            fontSize: '0.9rem',
            color: '#7C909B',
          }}>
              +33 (0) 9 70 44 16 45
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default HelpBox;