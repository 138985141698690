import React, {useState} from "react";
import {Trans, useTranslation} from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {grey} from "@mui/material/colors";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import {useTheme} from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import {makeStyles} from '@mui/styles';

import {SnackbarConfirm, SnackbarFormattedError, SnackbarSuccess} from "../../../../helpers/snackbar";
import useMoment from "../../../../helpers/useMoment";
import {customersService} from "../store/services";

import SearchCustomerAutocomplete from "./SearchCustomerAutocomplete";

const useStyle = makeStyles((theme) => ({

  root: {
    overflow: 'auto',
    minWidth: 400,
    '& .MuiContainer-root': {
      marginTop: 20,
      marginBottom: 20
    },
    '& img': {
      maxWidth: '100%',
      maxHeight: 80
    }
  },
  closeButton: {
    top: 10,
    right: 15,
    cursor: 'pointer',
    position: 'absolute',
    fontSize: 36,
    color: grey[500],
    [theme.breakpoints.down('xs')]: {
      top: 10,
      right: 20,
      fontSize: 40,
    },
  },
  addProductButton: {
    marginBottom: 30
  }
}));

const CustomerGiftCardModal = ({giftCard, onSubmit, setOpen, open}) => {

  const classes = useStyle();
  const theme = useTheme();
  const {t} = useTranslation();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const moment = useMoment();
  const [userSelected, setUserSelected] = useState(null);

  const handleSendGiftCard = () => {

    customersService.sendCustomerGiftCard(userSelected.id, giftCard.id).then(() => {
      SnackbarSuccess(t('giftCardSent'));
      setOpen(false);
      onSubmit();
    })
      .catch(e => {
        SnackbarFormattedError(e.error);
      });
  };

  const handleResendGiftCard = () => {

    customersService.sendCustomerGiftCard(giftCard.customer.id, giftCard.id).then(() => {
      SnackbarSuccess(t('giftCardSent'));
      setOpen(false);
      onSubmit();
    })
      .catch(e => {
        SnackbarFormattedError(e.error);
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSelectUser = (e, user) => {
    setUserSelected(user);
  };

  const dissociateGiftCard = async (id) => {
    await SnackbarConfirm("Voulez-vous vraiment dissocier la carte de l'utilisateur ?", async () => {
      await customersService.dissociateGiftCard(id);
      window.location.reload();
    });
  };

  const deleteGiftCard = async (id) => {
    await SnackbarConfirm("Voulez-vous vraiment supprimer la carte cadeau ?", async () => {
      await customersService.deleteGiftCard(id);
      window.location.reload();
    });
  };

  if (!giftCard) {
    return null;
  }

  const isAvailable = !giftCard.usedAt && (!giftCard.sentAt || moment(giftCard.endAt) < moment());

  return (
    <Dialog onClose={handleClose} open={open} fullScreen={fullScreen}>
      <CloseIcon className={classes.closeButton} onClick={handleClose}/>
      {giftCard?.type === "gift"
        ? <DialogTitle id="responsive-dialog-title"><Trans i18nKey="giftCard">Gift card</Trans></DialogTitle>
        : <DialogTitle id="responsive-dialog-title"><Trans i18nKey="coupon">Coupon</Trans></DialogTitle>
      }
      <DialogContent>
        <Card className={classes.root} elevation={0}>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container alignItems={"center"}>
                  <Grid item xs={12} sm={3}>
                    <Typography component={"b"}><Trans
                      i18nKey={"amount"}>Amount</Trans></Typography> :
                  </Grid>
                  <Grid item xs={12} sm={9}>
                    <Typography component={"span"}
                      variant={"h6"}>{giftCard.amount} {giftCard.currency}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container alignItems={"center"}>
                  <Grid item xs={12} sm={3}>
                    <Typography component={"b"}><Trans i18nKey={"code"}>Code</Trans></Typography> :
                  </Grid>
                  <Grid item xs={12} sm={9}>
                    <Typography component={"span"}
                      variant={"h6"}>{giftCard.id}-{giftCard.code}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container alignItems={"center"}>
                  <Grid item xs={12} sm={3}>
                    <Typography component={"b"}><Trans i18nKey={"createdAt"}>Created
                                            at</Trans></Typography> :
                  </Grid>
                  <Grid item xs={12} sm={9}>
                    <Typography
                      component={"i"}>{moment(giftCard.createdAt).format('LLL')}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container alignItems={"center"}>
                  <Grid item xs={12} sm={3}>
                    <Typography component={"b"}><Trans i18nKey={"name"}>Name</Trans></Typography> :
                  </Grid>
                  <Grid item xs={12} sm={9}>
                    {giftCard.customer && (
                      <Typography
                        component={"i"}>{giftCard.customer.firstName} {giftCard.customer.lastName}</Typography>
                    )}
                    {!giftCard.customer && (
                      <Typography component={"i"}><Trans i18nKey={"cardWasNotGivenToACustomer"}>The
                                                card was not given to a customer</Trans></Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container alignItems={"center"}>
                  <Grid item xs={12} sm={3}>
                    <Typography component={"b"}><Trans i18nKey={"sentAt"}>Sent
                                            at</Trans></Typography> :
                  </Grid>
                  <Grid item xs={12} sm={9}>
                    {giftCard.sentAt && (
                      <Typography
                        component={"i"}>{moment(giftCard.sentAt).format('LLL')}</Typography>
                    )}
                    {!giftCard.sentAt && (
                      <Typography component={"i"}><Trans i18nKey={"cardHasNotBeenSent"}>The card
                                                has not been sent</Trans></Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container alignItems={"center"}>
                  <Grid item xs={12} sm={3}>
                    <Typography component={"b"}><Trans i18nKey={"usedAt"}>Used
                                            at</Trans></Typography> :
                  </Grid>
                  <Grid item xs={12} sm={9}>
                    {giftCard.usedAt && (
                      <Typography
                        component={"i"}>{moment(giftCard.usedAt).format('LLL')}</Typography>
                    )}
                    {!giftCard.usedAt && (
                      <>
                        <Typography color={!isAvailable ? 'error' : 'primary'}
                          component={"i"}><Trans i18nKey={"cardHasNotBeenUsed"}>The
                                                    card has not been used</Trans></Typography>
                        {!isAvailable && (
                          <Typography>{t('giftCardRecoveryDescription', {date: moment(giftCard.endAt).format('LLL')})}</Typography>
                        )}
                      </>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Divider/>
              </Grid>
              {isAvailable && (
                <Grid item xs={12}>
                  <Grid container alignItems={"center"} spacing={2}>
                    <Grid item xs={12} sm={3}>
                      <Typography component={"b"}><Trans i18nKey={"sendTheGiftCard"}>Send the gift
                                                card</Trans></Typography> :
                    </Grid>
                    <Grid item xs={12} sm={9}>
                      <SearchCustomerAutocomplete onChange={handleSelectUser}/>
                    </Grid>
                    {userSelected && (
                      <>
                        <Grid item xs={12} sm={3}></Grid>
                        <Grid item xs={12} sm={9}>
                          <Button color={"primary"} variant={"contained"}
                            onClick={handleSendGiftCard}>
                            <Trans i18nKey={"sendTheGiftCard"}>Send the gift card</Trans>
                          </Button>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Grid>
              )}
              {/*!isAvailable && !giftCard.usedAt && (
                                <Grid item xs={12}>
                                    <Grid container alignItems={"center"} spacing={2}>
                                        <Grid item xs={12} sm={3}>
                                            <Typography component={"b"}><Trans i18nKey={"sendTheGiftCard"}>Send the gift card</Trans></Typography> :
                                        </Grid>
                                        <Grid item xs={12} sm={9}>
                                            <Button color={"primary"} variant={"contained"} onClick={handleResendGiftCard}>
                                                <Trans i18nKey={"resendTheGiftCard"}>Resend the gift card</Trans>
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )*/}
              <Grid item xs={6}>
                <Button color={"primary"} variant={"contained"} onClick={() => deleteGiftCard(giftCard.id)}>
                        Supprimer la carte
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </DialogContent>
    </Dialog>
  );
};
export default CustomerGiftCardModal;
