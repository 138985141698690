import React  from "react";
import {useLocation} from "react-router-dom";
import List from "@mui/material/List";

export const MenuContext = React.createContext({
  handleDrawerClose: () => {},
});

const Menu = ({className, sx, onItemSelected, children}) => {

  const {pathname} = useLocation();

  const contextValue = {
    pathname,
    onItemSelected
  };
  return (
    <List className={className} sx={{...sx}}>
      <MenuContext.Provider value={contextValue}>
        {children}
      </MenuContext.Provider>
    </List>
  );
};
export default Menu;
