import React, {useEffect, useState} from "react";
import {Trans, useTranslation} from "react-i18next";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import { green } from "@mui/material/colors";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import {makeStyles} from '@mui/styles';
import classnames from "classnames";

import {SnackbarConfirm, SnackbarFormattedError} from "../../../../helpers/snackbar";
import {customersService} from "../store/services";

import AddShippingAddressModal from "./AddShippingAddressModal";
import UpdateShippingAddressModal from "./UpdateShippingAddressModal";

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(2)
  },
  addressCard: {
    height: '100%',
    '& p.address-name': {
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    '& h2': {
      fontSize: 20,
      minHeight: 80,
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    '& .MuiCardHeader-subheader': {
      minHeight: 48
    },
    '& .MuiCardHeader-action': {
      '& .MuiButtonBase-root' : {
        height: 40
      },
    }
  },
  add: {
    height: '100%',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#F8F8F8'
    }
  },
  labelSelected: {
    background: green[900],
    color: theme.palette.common.white,
    padding: '0 10px',
    lineHeight: '30px'
  },
}));

const CustomerShippingAddresses = ({customer}) => {

  const classes = useStyles();
  const {t} = useTranslation();
  const [shippingAddresses, setShippingAddresses] = useState([]);
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [updateShippingAddressSelected, setUpdateShippingAddressSelected] = useState(false);
  const [updateDialogOpen, setUpdateDialogOpen] = useState(false);

  useEffect(() => {
    customersService.getCustomerShippingAddresses(customer.id).then(shippingAddresses => setShippingAddresses(shippingAddresses));
  }, [customer]);

  const handleReloadShippingAddresses = () => {
    customersService.getCustomerShippingAddresses(customer.id).then(shippingAddresses => setShippingAddresses(shippingAddresses));
  };

  const handleRemoveAddress = (address) => () => {
    SnackbarConfirm(<>{t('removeAddress')} :<br/> {address.address}</>, () => {
      customersService.removeCustomerShippingAddress(customer.id, address.id)
        .then(() => {
          handleReloadShippingAddresses();
        })
        .catch(e => {
          SnackbarFormattedError(e.error);
        });
    });
  };

  const handleOpenUpdateDialog = (address) => () => {
    setUpdateShippingAddressSelected(address);
    setUpdateDialogOpen(true);
  };

  return (
    <Grid container spacing={2}>
      {Array.isArray(shippingAddresses) && shippingAddresses.map((address, key) => (
        <Grid key={key} item lg={4} md={6} xs={12}>
          <Card className={classes.addressCard}>
            <CardHeader
              action={
                <>
                  <IconButton aria-label="settings" onClick={handleOpenUpdateDialog(address)}>
                    <EditOutlinedIcon/>
                  </IconButton>
                  {!address.byDefault && (
                    <IconButton aria-label="settings" onClick={handleRemoveAddress(address)}>
                      <DeleteOutlineIcon/>
                    </IconButton>
                  )}
                </>
              }
              title={address.name ? address.name : t('address')}
            />
            <CardContent>
              <Typography className={'address-name'}>{address.firstName} {address.lastName}</Typography>
              <Typography className={'address-text'}>{address.address} {address.additionalInfo && (`(${address.additionalInfo})`)}</Typography>
            </CardContent>
            <CardActions>
              {address.byDefault && (
                <Typography className={classes.labelSelected} >
                  <Trans i18nKey={"defaultShippingAddress"}>
                                        Default address
                  </Trans>
                </Typography>
              )}
            </CardActions>
          </Card>
        </Grid>
      ))}
      <Grid item lg={4} md={6} xs={12}>
        <Card
          className={classnames(classes.addressCard, classes.add)}
        >
          <CardHeader
            onClick={() => setAddDialogOpen(true)}
            action={
              <IconButton aria-label="settings">
                <AddCircleOutlineOutlinedIcon/>
              </IconButton>
            }
            title={<Trans i18nKey="newAddress">New address</Trans>}
            subheader={<Trans i18nKey="addNewShippingAddress">Add new shipping address</Trans>}
          />
        </Card>
      </Grid>
      {addDialogOpen && (
        <AddShippingAddressModal onChange={handleReloadShippingAddresses} user={customer} dialogOpen={addDialogOpen} setDialogOpen={setAddDialogOpen}/>
      )}
      {updateDialogOpen && updateShippingAddressSelected && (
        <UpdateShippingAddressModal onChange={handleReloadShippingAddresses} shippingAddress={updateShippingAddressSelected} dialogOpen={updateDialogOpen} setDialogOpen={setUpdateDialogOpen}/>
      )}
    </Grid>
  );
};
export default CustomerShippingAddresses;
