import React, {useState} from "react";
import {Stack} from "@mui/material";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      style={{width: '100%'}}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            p: 3,
            color: '#7C909B'
          }}
        >
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
    value: index
  };
}

const EventTabPanelContent = ({product}) => {

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (

    <Box
      sx={{
        flexGrow: 1,
        flexDirection: {xs: 'column', sm: 'column', md: 'column', lg: 'column', xl: 'column'},
        bgcolor: 'background.paper',
        display: 'flex',
        fontWeight: '300',
        whiteSpace: 'pre-wrap',
        tabSize: '4',
        mt: 2,
        '& strong': {
          fontWeight: 'bold',
          color: '#3C515E'
        },
        '& .MuiTypography-root p': {
          m: 0,
          tabSize: '4',
        },
        '& .MuiTypography-root ul': {
          mt: 0,
        },
        '& .MuiTypography-body1': {
          fontFamily: 'Open sans',
        },
        '& .MuiTypography-body1 p, .MuiTypography-body1 span': {
          color: '#7C909B !important',
        },
        '& .ql-indent-1': {
          marginLeft: '1.5em !important',
        },
        '& .ql-indent-2': {
          marginLeft: '3em !important',
        },
        '& .ql-indent-3': {
          marginLeft: '4.5em !important',
        },
        '& .ql-indent-4': {
          marginLeft: '6em !important',
        },
        '& .ql-indent-5': {
          marginLeft: '7.5em !important',
        }
      }}>
      <Tabs
        orientation={"horizontal"}
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Product menu"
        sx={{
          borderBottom: '1px dashed #7C909B',
          '& .MuiChip-root': {
            m: {sm: '0 0 0 8px', md: '0 0 0 8px', lg: '0 0 0 8px', xl: '0 0 0 8px', xxl: '0 0 0 8px'},
          },
          '& .MuiChip-root > span': {
            fontSize: '1rem',
            pr: '6px',
            pl: '6px',
          },
          '& .MuiTabs-indicator': {
            display: 'none'
          }
        }}
      >
        <Chip onClick={() => setValue(0)} label={'Description'}{...a11yProps(0)} variant={value === 0 ? 'outlined' : 'default'} sx={{color: value === 0 ? '#8CBD23' : '#3C515E'}}/>
        <Chip onClick={() => setValue(1)} label={'Infos pratiques'}{...a11yProps(1)} variant={value === 1 ? 'outlined' : 'default'} sx={{color: value === 1 ? '#8CBD23' : '#3C515E'}}/>
        <Chip onClick={() => setValue(2)} label={'Intervenants'}{...a11yProps(2)} variant={value === 2 ? 'outlined' : 'default'} sx={{color: value === 2 ? '#8CBD23' : '#3C515E'}}/>
        <Chip onClick={() => setValue(3)} label={'Vidéos prérequis'}{...a11yProps(3)} variant={value === 3 ? 'outlined' : 'default'} sx={{color: value === 3 ? '#8CBD23' : '#3C515E'}}/>
        <Chip onClick={() => setValue(4)} label={'Conditions d’annulation'}{...a11yProps(4)} variant={value === 4 ? 'outlined' : 'default'} sx={{color: value === 4 ? '#8CBD23' : '#3C515E'}}/>
      </Tabs>
      <TabPanel value={value} index={0}>
        <Typography component={"div"} dangerouslySetInnerHTML={{ __html: product.detail.description }}/>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Typography component={"div"} dangerouslySetInnerHTML={{ __html: product.detail.indications }}/>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Typography component={"div"} dangerouslySetInnerHTML={{ __html: product.detail.proInfo }} sx={{mb: 2}}/>
        <Box component={"img"} src={product.speakers} alt={product.reference} sx={{width: 'auto', height: 'auto'}}/>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <Typography component={"div"}>
          <Stack>
            <Typography component={"a"} href={product?.videoLink} target={'_blank'}>
              {product?.videoLink}
            </Typography>
            <Typography component={"a"} href={product?.videoLink2} target={'_blank'}>
              {product?.videoLink2}
            </Typography>
            <Typography component={"a"} href={product?.videoLink3} target={'_blank'}>
              {product?.videoLink3}
            </Typography>
          </Stack>
        </Typography>
      </TabPanel>
      <TabPanel value={value} index={4}>
        <Typography component={"div"} dangerouslySetInnerHTML={{ __html: product.detail.precautionsForUse }}/>
      </TabPanel>
    </Box>
  );
};

export default EventTabPanelContent;
