import * as types from "./constants";

export const proStatsActions = {
  getDirectSalesRequest,
  getDirectSalesSuccess,
  getDirectSalesError,
  getDirectRecurringSalesRequest,
  getDirectRecurringSalesSuccess,
  getDirectRecurringSalesError,
  getNetworkSalesRequest,
  getNetworkSalesSuccess,
  getNetworkSalesError,
  getNetworkRecurringSalesRequest,
  getNetworkRecurringSalesSuccess,
  getNetworkRecurringSalesError,
  getDirectRecruitmentRequest,
  getDirectRecruitmentSuccess,
  getDirectRecruitmentError,
  getNetworkRecruitmentRequest,
  getNetworkRecruitmentSuccess,
  getNetworkRecruitmentError,
  getDirectBestSellersAmountRequest,
  getDirectBestSellersAmountSuccess,
  getDirectBestSellersAmountError,
  getDirectBestRecruitersAmountRequest,
  getDirectBestRecruitersAmountSuccess,
  getDirectBestRecruitersAmountError,
  getNetworkBestSellersAmountRequest,
  getNetworkBestSellersAmountSuccess,
  getNetworkBestSellersAmountError,
  getNetworkBestRecruitersAmountRequest,
  getNetworkBestRecruitersAmountSuccess,
  getNetworkBestRecruitersAmountError,
  getDirectPacksAmountRequest,
  getDirectPacksAmountSuccess,
  getDirectPacksAmountError,
  getNetworkPacksAmountRequest,
  getNetworkPacksAmountSuccess,
  getNetworkPacksAmountError,
  getDirectProductsAmountRequest,
  getDirectProductsAmountSuccess,
  getDirectProductsAmountError,
  getNetworkProductsAmountRequest,
  getNetworkProductsAmountSuccess,
  getNetworkProductsAmountError,
  getNetworkTurnoverByCareerRequest,
  getNetworkTurnoverByCareerSuccess,
  getNetworkTurnoverByCareerError,
  getNetworkActiveProsCountByMonthRequest,
  getNetworkActiveProsCountByMonthSuccess,
  getNetworkActiveProsCountByMonthError,
};

function getDirectSalesRequest(userId) {
  return {
    type: types.GET_DIRECT_SALES_REQUEST,
    userId
  };
}

function getDirectSalesSuccess(userId, data) {
  return {
    type: types.GET_DIRECT_SALES_SUCCESS,
    userId, data
  };
}

function getDirectSalesError(error) {
  return {
    type: types.GET_DIRECT_SALES_ERROR,
    error
  };
}

function getDirectRecurringSalesRequest(userId) {
  return {
    type: types.GET_DIRECT_RECURRING_SALES_REQUEST,
    userId
  };
}

function getDirectRecurringSalesSuccess(userId, data) {
  return {
    type: types.GET_DIRECT_RECURRING_SALES_SUCCESS,
    userId, data
  };
}

function getDirectRecurringSalesError(error) {
  return {
    type: types.GET_DIRECT_RECURRING_SALES_ERROR,
    error
  };
}
function getNetworkSalesRequest(userId) {
  return {
    type: types.GET_NETWORK_SALES_REQUEST,
    userId
  };
}

function getNetworkSalesSuccess(userId,  data) {
  return {
    type: types.GET_NETWORK_SALES_SUCCESS,
    userId, data
  };
}

function getNetworkSalesError(error) {
  return {
    type: types.GET_NETWORK_SALES_ERROR,
    error
  };
}
function getNetworkRecurringSalesRequest(userId) {
  return {
    type: types.GET_NETWORK_RECURRING_SALES_REQUEST,
    userId
  };
}

function getNetworkRecurringSalesSuccess(userId,  data) {
  return {
    type: types.GET_NETWORK_RECURRING_SALES_SUCCESS,
    userId, data
  };
}

function getNetworkRecurringSalesError(error) {
  return {
    type: types.GET_NETWORK_RECURRING_SALES_ERROR,
    error
  };
}

function getDirectRecruitmentRequest(userId) {
  return {
    type: types.GET_DIRECT_RECRUITMENT_REQUEST,
    userId
  };
}

function getDirectRecruitmentSuccess(userId, data) {
  return {
    type: types.GET_DIRECT_RECRUITMENT_SUCCESS,
    userId, data
  };
}

function getDirectRecruitmentError(error) {
  return {
    type: types.GET_DIRECT_RECRUITMENT_ERROR,
    error
  };
}

function getNetworkRecruitmentRequest(userId) {
  return {
    type: types.GET_NETWORK_RECRUITMENT_REQUEST,
    userId
  };
}

function getNetworkRecruitmentSuccess(userId, data) {
  return {
    type: types.GET_NETWORK_RECRUITMENT_SUCCESS,
    userId, data
  };
}

function getNetworkRecruitmentError(error) {
  return {
    type: types.GET_NETWORK_RECRUITMENT_ERROR,
    error
  };
}

function getDirectBestSellersAmountRequest(userId, year, month) {
  return {
    type: types.GET_DIRECT_BESTSELLERS_AMOUNT_REQUEST,
    userId, year, month
  };
}

function getDirectBestSellersAmountSuccess(userId, year, month, data) {
  return {
    type: types.GET_DIRECT_BESTSELLERS_AMOUNT_SUCCESS,
    userId, year, month, data
  };
}

function getDirectBestSellersAmountError(error) {
  return {
    type: types.GET_DIRECT_BESTSELLERS_AMOUNT_ERROR,
    error
  };
}

function getDirectBestRecruitersAmountRequest(userId, year, month) {
  return {
    type: types.GET_DIRECT_BEST_RECRUITERS_AMOUNT_REQUEST,
    userId, year, month
  };
}

function getDirectBestRecruitersAmountSuccess(userId, year, month, data) {
  return {
    type: types.GET_DIRECT_BEST_RECRUITERS_AMOUNT_SUCCESS,
    userId, year, month, data
  };
}

function getDirectBestRecruitersAmountError(error) {
  return {
    type: types.GET_DIRECT_BEST_RECRUITERS_AMOUNT_ERROR,
    error
  };
}

function getNetworkBestSellersAmountRequest(userId, year, month) {
  return {
    type: types.GET_NETWORK_BESTSELLERS_AMOUNT_REQUEST,
    userId, year, month
  };
}

function getNetworkBestSellersAmountSuccess(userId, year, month, data) {
  return {
    type: types.GET_NETWORK_BESTSELLERS_AMOUNT_SUCCESS,
    userId, year, month, data
  };
}

function getNetworkBestSellersAmountError(error) {
  return {
    type: types.GET_NETWORK_BESTSELLERS_AMOUNT_ERROR,
    error
  };
}

function getNetworkBestRecruitersAmountRequest(userId, year, month) {
  return {
    type: types.GET_NETWORK_BEST_RECRUITERS_AMOUNT_REQUEST,
    userId, year, month
  };
}

function getNetworkBestRecruitersAmountSuccess(userId, year, month, data) {
  return {
    type: types.GET_NETWORK_BEST_RECRUITERS_AMOUNT_SUCCESS,
    userId, year, month, data
  };
}

function getNetworkBestRecruitersAmountError(error) {
  return {
    type: types.GET_NETWORK_BEST_RECRUITERS_AMOUNT_ERROR,
    error
  };
}

function getDirectPacksAmountRequest(userId, year, month) {
  return {
    type: types.GET_DIRECT_PACKS_AMOUNT_REQUEST,
    userId, year, month
  };
}

function getDirectPacksAmountSuccess(userId, year, month, data) {
  return {
    type: types.GET_DIRECT_PACKS_AMOUNT_SUCCESS,
    userId, year, month, data
  };
}

function getDirectPacksAmountError(error) {
  return {
    type: types.GET_DIRECT_PACKS_AMOUNT_ERROR,
    error
  };
}

function getNetworkPacksAmountRequest(userId, year, month) {
  return {
    type: types.GET_NETWORK_PACKS_AMOUNT_REQUEST,
    userId, year, month
  };
}

function getNetworkPacksAmountSuccess(userId, year, month, data) {
  return {
    type: types.GET_NETWORK_PACKS_AMOUNT_SUCCESS,
    userId, year, month, data
  };
}

function getNetworkPacksAmountError(error) {
  return {
    type: types.GET_NETWORK_PACKS_AMOUNT_ERROR,
    error
  };
}

function getDirectProductsAmountRequest(userId, year, month) {
  return {
    type: types.GET_DIRECT_PRODUCTS_AMOUNT_REQUEST,
    userId, year, month
  };
}

function getDirectProductsAmountSuccess(userId, year, month, data) {
  return {
    type: types.GET_DIRECT_PRODUCTS_AMOUNT_SUCCESS,
    userId, year, month, data
  };
}

function getDirectProductsAmountError(error) {
  return {
    type: types.GET_DIRECT_PRODUCTS_AMOUNT_ERROR,
    error
  };
}

function getNetworkProductsAmountRequest(userId, year, month) {
  return {
    type: types.GET_NETWORK_PRODUCTS_AMOUNT_REQUEST,
    userId, year, month
  };
}

function getNetworkProductsAmountSuccess(userId, year, month, data) {
  return {
    type: types.GET_NETWORK_PRODUCTS_AMOUNT_SUCCESS,
    userId, year, month, data
  };
}

function getNetworkProductsAmountError(error) {
  return {
    type: types.GET_NETWORK_PRODUCTS_AMOUNT_ERROR,
    error
  };
}

function getNetworkTurnoverByCareerRequest(userId, year, month) {
  return {
    type: types.GET_NETWORK_TURNOVER_BY_CAREER_REQUEST,
    userId, year, month
  };
}
function getNetworkTurnoverByCareerSuccess(userId, year, month, data) {
  return {
    type: types.GET_NETWORK_TURNOVER_BY_CAREER_SUCCESS,
    userId, year, month, data
  };
}
function getNetworkTurnoverByCareerError(error) {
  return {
    type: types.GET_NETWORK_TURNOVER_BY_CAREER_ERROR,
    error
  };
}

function getNetworkActiveProsCountByMonthRequest(userId) {
  return {
    type: types.GET_NETWORK_ACTIVE_PROS_COUNT_BY_MONTH_REQUEST,
    userId
  };
}
function getNetworkActiveProsCountByMonthSuccess(userId, data) {
  return {
    type: types.GET_NETWORK_ACTIVE_PROS_COUNT_BY_MONTH_SUCCESS,
    userId, data
  };
}
function getNetworkActiveProsCountByMonthError(error) {
  return {
    type: types.GET_NETWORK_ACTIVE_PROS_COUNT_BY_MONTH_ERROR,
    error
  };
}