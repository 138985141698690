import React, {useEffect, useState} from "react";
import {Link, useHistory} from "react-router-dom";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { grey } from "@mui/material/colors";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {makeStyles} from '@mui/styles';

import DeliveryList from "../../../Logistic/Order/components/DeliveryList";
import {userService} from "../store/services";

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiPaper-elevation1': {
      boxShadow: 'none',
      borderBottom: `1px solid ${grey[300]}`,
      borderRadius: 0
    },
    '& .item': {
      padding: '0px 4px',
    },
    '& .order-options': {
      '& .MuiButton-root': {
        marginRight: 8
      }
    }
  },
}));

const UserOrdersCard = ({user}) => {

  const classes = useStyles();
  const history = useHistory();
  const [isOrderModalOpen, setOrderModalOpen] = useState(false);
  const [isFreeOrderModalOpen, setFreeOrderModalOpen] = useState(false);
  const [maximized, setMaximized] = useState(false);
  const [orders, setOrders] = useState([]);

  useEffect(() => {

    loadOrders();
  }, [user]);

  const loadOrders = () => {
    userService.getUserOrders(user.id).then(orders => {
      setOrders(orders);
    });
  };

  const handleOrderModalOpen = () => {
    setOrderModalOpen(true);
  };

  const handleOrderModalClose = () => {
    setOrderModalOpen(false);
  };

  const handleFreeOrderModalOpen = () => {
    setFreeOrderModalOpen(true);
  };

  const handleFreeOrderModalClose = () => {
    setFreeOrderModalOpen(false);
  };

  const handleClick = (order) => {
    history.push(`/admin/accounting/order/${order.id}`);
  };

  const handleMaximize = () => {
    setMaximized(true);
  };

  return (
    <Card className={classes.root}>
      <CardContent>
        <Grid container justify="center" spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Grid container justify={"space-between"} alignItems={"center"} spacing={2}>
              <Grid item xs={12}>
                <Grid container justify={"space-between"} spacing={2}>
                  <Grid item xs={6}>
                    <Typography component="h3" variant={"h6"} gutterBottom>
                                            Commandes :
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography align={"right"} className={"order-options"}>
                      <Button variant="outlined" component={Link} to={`/admin/users/order/add/${user.id}`} size={"large"} color={"primary"} disableElevation>
                                                Créer une commande
                      </Button>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} className={"orders"}>
                <DeliveryList
                  orders={orders.slice(0, maximized ? orders.length : 10)}
                  withoutDetails
                  onClick={handleClick}
                />
              </Grid>
              {orders.length > 10 && !maximized && (
                <Grid item xs={12}>
                  <Typography align={"center"}>
                    <Button size={"small"} variant={"outlined"} onClick={handleMaximize}>
                                            Afficher toutes les commandes
                    </Button>
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
export default UserOrdersCard;
