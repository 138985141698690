import i18next from "i18next";
import { call, put, takeEvery } from 'redux-saga/effects';

import { getLangPath } from "../../../../helpers/multilingual";
import {SnackbarFormattedError, SnackbarSuccess} from "../../../../helpers/snackbar";
import { history } from "../../../../helpers/store";

import { networkActions } from "./actions";
import * as actionsType from './constants';
import { networkService } from "./services";

export const networkSagaKey = 'network';

function* getProfilingTests() {

  try {
    let requests = yield call(networkService.getRequests);
    yield put(networkActions.getProfilingTestsSuccess(requests));
  }
  catch (e) {
    yield put(networkActions.getProfilingTestsError());
  }
}

function* getProfilingTest(action) {

  try {
    let request = yield call(networkService.getRequest, action.requestId);
    yield put(networkActions.getProfilingTestSuccess(request));
  }
  catch (e) {
    yield put(networkActions.getProfilingTestError());
  }
}

function* acceptProfilingTest(action) {

  try {
    yield call(networkService.acceptRequest, action.requestId);
    yield put(networkActions.acceptProfilingTestSuccess());
  }
  catch (e) {
    yield put(networkActions.acceptProfilingTestError());
  }
}

function* declineProfilingTest(action) {

  try {
    yield call(networkService.declineRequest, action.requestId);
    yield put(networkActions.declineProfilingTestSuccess());
  }
  catch (e) {
    yield put(networkActions.declineProfilingTestError());
  }
}

function* getEvolutions(action) {

  try {
    let pros = yield call(networkService.getEvolutions, action.role);
    yield put(networkActions.getEvolutionsSuccess(action.role, pros));
  }
  catch (e) {
    yield put(networkActions.getEvolutionsError(e.error));
  }
}

function* getConsultants() {

  try {
    let consultants = yield call(networkService.getConsultants);
    yield put(networkActions.getConsultantsSuccess(consultants));
  }
  catch (e) {
    yield put(networkActions.getConsultantsError());
  }
}

function* getPartners() {

  try {
    let partners = yield call(networkService.getPartners);
    yield put(networkActions.getPartnersSuccess(partners));
  }
  catch (e) {
    yield put(networkActions.getPartnersError());
  }
}

function* addPartner(action) {

  try {
    yield call(networkService.addPartner, action.partner);
    yield put(networkActions.getPartnersRequest());
    history.push(`${getLangPath()}/pro/network/partners`);
  }
  catch (e) {
    yield call(SnackbarFormattedError, e.error);
    yield put(networkActions.addPartnerError(e.error));
  }
}

function* getConsultant(action) {

  try {
    let consultant = yield call(networkService.getConsultant, action.consultantId);
    yield put(networkActions.getConsultantSuccess(consultant));
  }
  catch (e) {
    yield put(networkActions.getConsultantError());
  }
}

function* updatePartner(action) {

  try {
    let partner = yield call(networkService.updatePartner, action.partner, action.data);
    yield put(networkActions.updatePartnerSuccess(partner));
    yield call(SnackbarSuccess, i18next.t("dataUpdated"));
    history.push(`${getLangPath()}/pro/network/${partner.id}`);
  }
  catch (e) {
    yield call(SnackbarFormattedError, e.error);
    yield put(networkActions.updatePartnerError());
  }
}

function* updatePartnerShippingAddress(action) {

  try {
    let shippingAddress = yield call(networkService.updatePartnerShippingAddress, action.partner, action.data);
    yield put(networkActions.updatePartnerShippingAddressSuccess(shippingAddress));
    yield call(SnackbarSuccess, i18next.t("dataUpdated"));
    history.push(`${getLangPath()}/pro/network/${action.partner.id}`);
  }
  catch (e) {
    yield call(SnackbarFormattedError, e.error);
    yield put(networkActions.updatePartnerShippingAddressError());
  }
}

export default function* watchNetwork() {

  yield takeEvery(actionsType.GET_PROFILING_TESTS_REQUEST, getProfilingTests);
  yield takeEvery(actionsType.GET_PROFILING_TEST_REQUEST, getProfilingTest);
  yield takeEvery(actionsType.ACCEPT_PROFILING_TEST_REQUEST, acceptProfilingTest);
  yield takeEvery(actionsType.DECLINE_PROFILING_TEST_REQUEST, declineProfilingTest);
  yield takeEvery(actionsType.GET_EVOLUTIONS_REQUEST, getEvolutions);
  yield takeEvery(actionsType.GET_CONSULTANTS_REQUEST, getConsultants);
  yield takeEvery(actionsType.GET_CONSULTANT_REQUEST, getConsultant);
  yield takeEvery(actionsType.GET_PARTNERS_REQUEST, getPartners);
  yield takeEvery(actionsType.ADD_PARTNER_REQUEST, addPartner);
  yield takeEvery(actionsType.UPDATE_PARTNER_REQUEST, updatePartner);
  yield takeEvery(actionsType.UPDATE_PARTNER_SHIPPING_ADDRESS_REQUEST, updatePartnerShippingAddress);
}
