import React, {useEffect} from 'react';
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import {Grid, Slide, Stack, Typography} from "@mui/material";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from "@mui/material/Container";
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import {makeStyles} from '@mui/styles';
import moment from "moment";

import {homeActions} from "../store/actions";
import {slidersSelector} from "../store/selectors";

function TabPanel(props) {
  const {children, value, index, sx, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-prevent-tabpanel-${index}`}
      aria-labelledby={`scrollable-prevent-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          p={3}
          sx={{
            width: '100%',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            overflowX: 'hidden',
            ...sx
          }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `scrollable-prevent-tab-${index}`,
    'aria-controls': `scrollable-prevent-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    '& .MuiBox-root': {
      padding: 0,
    },
    '& .MuiAppBar-colorPrimary': {
      backgroundColor: 'transparent',
      marginTop: '-50px',
      boxShadow: 'none',
    },
    '& .MuiTab-root': {
      maxWidth: '30px',
      minWidth: '30px',
      color: '#fff',
    },
  },
  tab1: {
    backgroundColor: theme.palette.secondary.main,
  },
  tab2: {
    backgroundColor: '#f44336',
  },
  tab3: {
    backgroundColor: '#1e28e9',
  }
}));

export default function TabsSlider() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {i18n} = useTranslation();
  const [value, setValue] = React.useState(0);
  const sliders = useSelector(slidersSelector);
  const containerRef = React.useRef(null);

  useEffect(() => {
    dispatch(homeActions.getSlidersRequest(i18n.language));
  }, [i18n.language]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    let maxLength = sliders.length;
    const timeout = setTimeout(() => setValue((value + 1 + maxLength) % maxLength), 5000);

    return () => clearTimeout(timeout);
  }, [sliders.length, value]);

  return (
    <div className={classes.root}>
      {sliders && sliders.sort((a, b) => moment(b.updatedAt).unix() - moment(a.updatedAt).unix()).map((slider, index) => (
        <TabPanel
          key={index}
          value={value}
          index={index}
          sx={{
            background: `url(${slider.backgroundImage[1000]})`,
            backgroundSize: 'cover !important',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}>
          <Container
            maxWidth={'xxl'}
            sx={{
              height: {xs: 'calc(100vh - 110px)', sm: 'calc(100vh - 52px)', md: '100%'},
              pt: '5%',
              position: 'relative',
            }}>

            <Grid container spacing={2} justifyContent={"space-between"} flexDirection={slider.textPosition === 'left' ? "row" : "row-reverse"} sx={{ml: '-8px !important'}}>
              <Grid item xs={12} sm={6} sx={{pl: '24px', pr: '24px'}}>
                <Stack gap={2} sx={{pt: '6vw'}} alignItems={slider.textPosition === 'left' ? 'flex-start' : 'flex-end'} ref={containerRef}>
                  <Slide direction="down" in={value === index} container={containerRef.current}>
                    <Typography dangerouslySetInnerHTML={{ __html: slider.title }}
                      sx={{
                        fontFamily: 'Montserrat',
                        maxWidth: '515px',
                        backgroundColor: '#fff',
                        padding: '8px 8px',
                        fontSize: '1.5rem',
                        overflow: 'hidden',
                        textAlign: {xs: 'center', sm: 'left'},
                        zIndex: 1,
                        '& p': {
                          margin: 0,
                        }
                      }}/>
                  </Slide>
                  <Slide
                    direction="left"
                    in={value === index}
                    container={containerRef.current}
                  >
                    <Typography
                      dangerouslySetInnerHTML={{ __html: slider.text }}
                      sx={{
                        fontSize: {xs: '2.8rem', sm: '3.4rem', md: '4rem'},
                        fontFamily: 'Raleway',
                        fontWeight: 300,
                        maxWidth: {xs: '100%', sm: '90%'},
                        lineHeight: '1.1',
                        overflow: 'hidden',
                        textAlign: slider.textPosition === 'left' ? 'left' : 'right',
                        zIndex: 1,
                        '& p': {
                          margin: 0,
                        }
                      }}/>
                  </Slide>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box component={'img'} src={slider.image[1000]} alt={slider.title}
                  sx={{
                    maxHeight: {xs: '70%', sm: '80%', md: '480px'},
                    width: {xs: 'auto', sm: 'auto'},
                    maxWidth: {xs: '100%', sm: '100%'},
                    position: {xs: 'absolute', sm: 'absolute', md: 'unset'},
                    bottom: {xs: '0', sm: '-50px', md: 'unset'},
                    right: {xs: slider.textPosition === 'left' ? '0' : 'unset', sm: slider.textPosition === 'left' ? '0' : 'unset', md: 'unset'},
                    left: {xs: slider.textPosition === 'left' ? 'unset' : '0', sm: slider.textPosition === 'left' ? 'unset' : '0', md: 'unset'},
                    display: 'block',
                    margin: 'auto'
                  }}/>
              </Grid>
            </Grid>
          </Container>
        </TabPanel>
      ))}
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          centered
        >
          {sliders && sliders.map((slider, index) => (
            <Tab key={slider.id} icon={<FiberManualRecordIcon/>} aria-label="phone" {...a11yProps(index)} />
          ))}
        </Tabs>
      </AppBar>
    </div>
  );
}
