import React from 'react';
import FacebookIcon from '@mui/icons-material/Facebook';
import {Grid, Stack, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";

const section = {
  "name": "AboutSection1",
  "type": "banner",
  "bannerTitle1": "Formations",
  "bannerTitle2": "Conférences",
  "bannerTitle3": "Tables rondes",
  "bannerText1": "« Le savoir est fait pour être partagé »",
  "bannerImage": `${process.env.REACT_APP_API_RESOURCES_URL}/images/Illu_FormationsConferences.png`
};
export default function ProsSection2() {

  return (
    <Box
      sx={{
        width: '100%',
        margin: '40px 0 60px',
        padding: '1px 0',
        overflowX: 'clip',
        backgroundColor: '#F7F3F0'
      }}>
      <Container
        maxWidth={'xxl'}>
        <Grid container spacing={2} justifyContent={"space-between"} flexDirection={"row"} sx={{
          ml: '-8px !important',
          position: 'relative',
          py: '4vw',
        }}>
          <Grid item xs={12} sm={6} sx={{pl: '24px', pr: '24px'}}>
            <Stack gap={2} alignItems={'flex-start'}>
              <Typography
                sx={{
                  fontFamily: 'Raleway',
                  fontWeight: '600',
                  padding: '8px 8px',
                  fontSize: {xs: '2.5rem', sm: '2rem', md: '2.5rem', lg: '3.5rem', xl: '3.5rem', xxl: '4.5rem'},
                  lineHeight: '1',
                  overflow: 'hidden',
                  color: 'green',
                  textAlign: {xs: 'left', sm: 'left'},
                  zIndex: 1,
                  '& p': {
                    margin: 0,
                  },
                  '& span': {
                    lineHeight: '1',
                    fontFamily: 'Raleway',
                    fontWeight: '600',
                    fontSize: {xs: '2.5rem', sm: '2rem', md: '2.5rem', lg: '3.5rem', xl: '3.5rem', xxl: '4.5rem'},
                  }
                }}>
                {section.bannerTitle1} <br/>
                <Typography component={"span"} sx={{color: 'primary.main'}}>{section.bannerTitle2}</Typography> <br/>
                <Typography component={"span"} sx={{color: '#006400'}}>{section.bannerTitle3}</Typography> <br/>
              </Typography>

              <Typography
                sx={{
                  fontSize: {xs: '1rem', sm: '0.9rem', md: '1rem', lg: '1.1rem', xl: '1.2rem', xxl: '1.3rem'},
                  padding: '0 8px 8px 8px',
                  fontFamily: 'Open Sans',
                  maxWidth: {xs: '100%', sm: '100%'},
                  lineHeight: '1.4',
                  overflow: 'hidden',
                  textAlign: 'left',
                  color: 'primary.main',
                  zIndex: 1,
                  '& p': {
                    margin: 0,
                  }
                }}>
                {section.bannerText1}
              </Typography>
              <Typography
                sx={{
                  fontSize: {xs: '1rem', sm: '0.9rem', md: '1rem', lg: '1.1rem', xl: '1.2rem', xxl: '1.3rem'},
                  padding: '0 8px 8px 8px',
                  fontFamily: 'Open Sans',
                  maxWidth: {xs: '100%', sm: '100%'},
                  lineHeight: '1.4',
                  overflow: 'hidden',
                  textAlign: 'left',
                  color: 'primary.light',
                  zIndex: 1,
                  '& p': {
                    margin: 0,
                  }
                }}>
                  Vous êtes professionnel(le) de la santé ? Vous souhaitez apprendre, échanger et partager vos connaissances en nutrition pour optimiser le capital santé de vos patients ? <br/>
                  Nutri-Logics vous offre l’occasion de rencontrer des experts en médecine fonctionnelle et nutritionnelle en Belgique et en France ! <br/>
                  Découvrez les dates et lieux de nos prochaines tables rondes sur notre page <FacebookIcon/> Nutri-Logics ou en vous inscrivant à notre newsletter !
              </Typography>
            </Stack>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box component={'img'} src={section.bannerImage} alt={section.bannerTitle}
              sx={{
                width: {xs: 'auto', sm: '47vw', md: '47vw', lg: '47vw', xl: '47vw', xxl: '900px'},
                maxWidth: {xs: '100%', sm: '90%'},
                position: {xs: 'inherit', sm: 'absolute', md: 'absolute'},
                bottom: {xs: '0px', sm: '0px', md: '0px'},
                top: {xs: '0px', sm: '0px', md: '0px'},
                right: {xs: 'unset', sm: 'unset', md: 'unset'},
                left: {xs: 'unset', sm: 'unset', md: 'unset'},
                display: 'block',
                margin: 'auto'
              }}/>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
