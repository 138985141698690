import React from "react";
import { Route, Switch } from 'react-router-dom';

import {
  RouteAccounting,
  RouteAdmin,
  RouteEditor,
  RouteLogistic, RoutePro,
} from "../containers/Authentication/store/helper";
import ScrollToTop from "../helpers/ScrollToTop";
import AccountingLayout from "../layouts/AccountingLayout";
import AdminLayout from "../layouts/AdminLayout";
import CustomerLayout from "../layouts/CustomerLayout";
import LogisticLayout from "../layouts/LogisticLayout";
import ManagerLayout from "../layouts/ManagerLayout";
import ProLayout from "../layouts/ProLayout";
import StatisticsLayout from "../layouts/StatisticsLayout";

function Router() {

  ScrollToTop();

  return (
    <Switch>
      <RouteAdmin path="/statistics" component={StatisticsLayout}/>
      <RouteAdmin path="/admin" component={AdminLayout}/>
      <RouteLogistic path="/logistic" component={LogisticLayout}/>
      <RouteEditor path="/manager" component={ManagerLayout}/>
      <RouteAccounting path="/accounting" component={AccountingLayout}/>
      <RoutePro path="/:lang(en|fr|nl)?/pro" component={ProLayout}/>
      <Route exact path="/:lang(en|fr|nl)?" component={CustomerLayout}/>
      <Route exact path="/:lang(en|fr|nl)?/*" component={CustomerLayout}/>
    </Switch>
  );
}

export default Router;
