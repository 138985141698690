import { call, put, select,takeEvery } from 'redux-saga/effects';

import { proStatsActions } from "./actions";
import * as actionsType from './constants';
import { proStatsService } from "./services";

export const proStatsSagaKey = 'proStats';

function* getDirectSales(action) {

  try {
    let data = yield call(proStatsService.getDirectSales, action.userId);
    yield put(proStatsActions.getDirectSalesSuccess(action.userId, data));
  }
  catch (e) {
    yield put(proStatsActions.getDirectSalesError());
  }
}

function* getNetworkSales(action) {

  try {
    let data = yield call(proStatsService.getNetworkSales, action.userId);
    yield put(proStatsActions.getNetworkSalesSuccess(action.userId, data));
  }
  catch (e) {
    yield put(proStatsActions.getNetworkSalesError());
  }
}

function* getDirectRecurringSales(action) {

  try {
    let data = yield call(proStatsService.getDirectRecurringSales, action.userId);
    yield put(proStatsActions.getDirectRecurringSalesSuccess(action.userId, data));
  }
  catch (e) {
    yield put(proStatsActions.getDirectRecurringSalesError());
  }
}

function* getNetworkRecurringSales(action) {

  try {
    let data = yield call(proStatsService.getNetworkRecurringSales, action.userId);
    yield put(proStatsActions.getNetworkRecurringSalesSuccess(action.userId, data));
  }
  catch (e) {
    yield put(proStatsActions.getNetworkRecurringSalesError());
  }
}

function* getDirectRecruitment(action) {

  try {
    let data = yield call(proStatsService.getDirectRecruitment, action.userId);
    yield put(proStatsActions.getDirectRecruitmentSuccess(action.userId, data));
  }
  catch (e) {
    yield put(proStatsActions.getDirectRecruitmentError());
  }
}

function* getNetworkRecruitment(action) {

  try {
    let data = yield call(proStatsService.getNetworkRecruitment, action.userId);
    yield put(proStatsActions.getNetworkRecruitmentSuccess(action.userId, data));
  }
  catch (e) {
    yield put(proStatsActions.getNetworkRecruitmentError());
  }
}

function* getDirectBestSellersAmount(action) {

  try {
    let data = yield call(proStatsService.getDirectBestSellersAmount, action.userId, action.year, action.month);
    yield put(proStatsActions.getDirectBestSellersAmountSuccess(action.userId, action.year, action.month, data));
  }
  catch (e) {
    yield put(proStatsActions.getDirectBestSellersAmountError());
  }
}

function* getDirectBestRecruitersAmount(action) {

  try {
    let data = yield call(proStatsService.getDirectBestRecruitersAmount, action.userId, action.year, action.month);
    yield put(proStatsActions.getDirectBestRecruitersAmountSuccess(action.userId, action.year, action.month, data));
  }
  catch (e) {
    yield put(proStatsActions.getDirectBestRecruitersAmountError());
  }
}

function* getNetworkBestSellersAmount(action) {

  try {
    let data = yield call(proStatsService.getNetworkBestSellersAmount, action.userId, action.year, action.month);
    yield put(proStatsActions.getNetworkBestSellersAmountSuccess(action.userId, action.year, action.month, data));
  }
  catch (e) {
    yield put(proStatsActions.getNetworkBestSellersAmountError());
  }
}

function* getNetworkBestRecruitersAmount(action) {

  try {
    let data = yield call(proStatsService.getNetworkBestRecruitersAmount, action.userId, action.year, action.month);
    yield put(proStatsActions.getNetworkBestRecruitersAmountSuccess(action.userId, action.year, action.month, data));
  }
  catch (e) {
    yield put(proStatsActions.getNetworkBestRecruitersAmountError());
  }
}

function* getNetworkPacksAmount(action) {

  try {
    let data = yield call(proStatsService.getNetworkPacksAmount, action.userId, action.year, action.month);
    yield put(proStatsActions.getNetworkPacksAmountSuccess(action.userId, action.year, action.month, data));
  }
  catch (e) {
    yield put(proStatsActions.getNetworkPacksAmountError());
  }
}

function* getNetworkProductsAmount(action) {

  try {
    let data = yield call(proStatsService.getNetworkProductsAmount, action.userId, action.year, action.month);
    yield put(proStatsActions.getNetworkProductsAmountSuccess(action.userId, action.year, action.month, data));
  }
  catch (e) {
    yield put(proStatsActions.getNetworkProductsAmountError());
  }
}

function* getTurnoverByCareer(action) {

  try {
    let data = yield call(proStatsService.getNetworkTurnoverByCareer, action.userId, action.year, action.month);
    yield put(proStatsActions.getNetworkTurnoverByCareerSuccess(action.userId, action.year, action.month, data));
  }
  catch (e) {
    yield put(proStatsActions.getNetworkTurnoverByCareerError());
  }
}

function* getNetworkActiveProsCountByMonth(action) {

  try {
    let data = yield call(proStatsService.getNetworkActiveProsCountByMonth, action.userId);
    yield put(proStatsActions.getNetworkActiveProsCountByMonthSuccess(action.userId, data));
  }
  catch (e) {
    yield put(proStatsActions.getNetworkActiveProsCountByMonthError());
  }
}

function* getDirectPacksAmount(action) {

  try {
    let data = yield call(proStatsService.getDirectPacksAmount, action.userId, action.year, action.month);
    yield put(proStatsActions.getDirectPacksAmountSuccess(action.userId, action.year, action.month, data));
  }
  catch (e) {
    yield put(proStatsActions.getDirectPacksAmountError());
  }
}

function* getDirectProductsAmount(action) {

  try {
    let data = yield call(proStatsService.getDirectProductsAmount, action.userId, action.year, action.month);
    yield put(proStatsActions.getDirectProductsAmountSuccess(action.userId, action.year, action.month, data));
  }
  catch (e) {
    yield put(proStatsActions.getDirectProductsAmountError());
  }
}

export default function* watchproStats() {

  yield takeEvery(actionsType.GET_DIRECT_SALES_REQUEST, getDirectSales);
  yield takeEvery(actionsType.GET_DIRECT_RECURRING_SALES_REQUEST, getDirectRecurringSales);
  yield takeEvery(actionsType.GET_NETWORK_SALES_REQUEST, getNetworkSales);
  yield takeEvery(actionsType.GET_NETWORK_RECURRING_SALES_REQUEST, getNetworkRecurringSales);
  yield takeEvery(actionsType.GET_DIRECT_RECRUITMENT_REQUEST, getDirectRecruitment);
  yield takeEvery(actionsType.GET_NETWORK_RECRUITMENT_REQUEST, getNetworkRecruitment);
  yield takeEvery(actionsType.GET_DIRECT_BESTSELLERS_AMOUNT_REQUEST, getDirectBestSellersAmount);
  yield takeEvery(actionsType.GET_DIRECT_BEST_RECRUITERS_AMOUNT_REQUEST, getDirectBestRecruitersAmount);
  yield takeEvery(actionsType.GET_NETWORK_BESTSELLERS_AMOUNT_REQUEST, getNetworkBestSellersAmount);
  yield takeEvery(actionsType.GET_NETWORK_BEST_RECRUITERS_AMOUNT_REQUEST, getNetworkBestRecruitersAmount);
  yield takeEvery(actionsType.GET_DIRECT_PACKS_AMOUNT_REQUEST, getDirectPacksAmount);
  yield takeEvery(actionsType.GET_DIRECT_PRODUCTS_AMOUNT_REQUEST, getDirectProductsAmount);
  yield takeEvery(actionsType.GET_NETWORK_PACKS_AMOUNT_REQUEST, getNetworkPacksAmount);
  yield takeEvery(actionsType.GET_NETWORK_PRODUCTS_AMOUNT_REQUEST, getNetworkProductsAmount);
  yield takeEvery(actionsType.GET_NETWORK_TURNOVER_BY_CAREER_REQUEST, getTurnoverByCareer);
  yield takeEvery(actionsType.GET_NETWORK_ACTIVE_PROS_COUNT_BY_MONTH_REQUEST, getNetworkActiveProsCountByMonth);
}
