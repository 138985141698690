import produce from "immer";

import * as actions from './constants';

export const initialState = {
  isCheckoutOpen : false,
  isCheckoutResponseOpen : false,
  loading: false,
  checkout: null,
  items : [],
  hardReload : false
};

export const proShopReducerKey = 'proShop';

export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case actions.OPEN_CHECKOUT_DRAWER:
        draft.isCheckoutOpen = true;
        draft.loading = true;
        draft.items = action.items;
        draft.redirect = action.redirect;
        draft.hardReload = action.hardReload;
        draft.checkout = null;
        break;
      case actions.OPEN_CHECKOUT_RESPONSE_DRAWER:
        draft.isCheckoutResponseOpen = true;
        break;
      case actions.CLOSE_CHECKOUT_DRAWER:
        draft.isCheckoutOpen = false;
        break;
      case actions.CLOSE_CHECKOUT_RESPONSE_DRAWER:
        draft.isCheckoutResponseOpen = false;
        break;
      case actions.SET_ITEM_QUANTITY:
        draft.loading = true;
        // eslint-disable-next-line no-case-declarations
        const itemKey = state.items.findIndex(item => item.id === action.item.id);
        draft.items[itemKey].quantity = action.quantity;
        break;
      case actions.GET_CHECKOUT_REQUEST:
        draft.loading = true;
        break;
      case actions.GET_CHECKOUT_SUCCESS:
        draft.checkout = action.checkout;
        draft.loading = false;
        break;
      case actions.GET_CHECKOUT_FAIL:
      case actions.CLEAN_CHECKOUT:
        draft.checkout = initialState.checkout;
        draft.items = initialState.items;
        break;

      default:
    }
  }
  );
