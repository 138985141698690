import * as actionsType from './constants';

export const proShopActions = {
  openCheckout,
  openCheckoutResponse,
  closeCheckout,
  closeCheckoutResponse,
  setCartItemQuantity,
  getCheckoutRequest,
  getCheckoutSuccess,
  getCheckoutFail,
  cleanCheckout
};

function openCheckout({items, redirect, hardReload = false}) {
  return {
    type: actionsType.OPEN_CHECKOUT_DRAWER,
    items,
    redirect,
    hardReload
  };
}

function openCheckoutResponse() {
  return {
    type: actionsType.OPEN_CHECKOUT_RESPONSE_DRAWER
  };
}

function closeCheckout() {
  return {
    type: actionsType.CLOSE_CHECKOUT_DRAWER,
  };
}

function closeCheckoutResponse() {
  return {
    type: actionsType.CLOSE_CHECKOUT_RESPONSE_DRAWER,
  };
}

function setCartItemQuantity(item, quantity) {

  return {
    type: actionsType.SET_ITEM_QUANTITY,
    item,
    quantity
  };
}

function getCheckoutRequest(lang, items, useWallet) {

  return {
    type: actionsType.GET_CHECKOUT_REQUEST,
    lang,
    items,
    useWallet,
  };
}

function getCheckoutSuccess(checkout) {

  return {
    type: actionsType.GET_CHECKOUT_SUCCESS,
    checkout
  };
}

function getCheckoutFail() {

  return {
    type: actionsType.GET_CHECKOUT_FAIL,
  };
}

function cleanCheckout() {

  return {
    type: actionsType.CLEAN_CHECKOUT,
  };
}