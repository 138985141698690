import React, {useState} from "react";
import { Trans, useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import { grey } from "@mui/material/colors";
import Container from "@mui/material/Container";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {makeStyles} from '@mui/styles';

import ButtonWithLoader from "../../../../components/ButtonWithLoader";
import AutocompletePlaces from "../../../../components/GoogleAutocomplete/AutocompletePlaces";
import FormValidator from "../../../../components/Validators/FormValidator";
import TextFieldValidator from "../../../../components/Validators/TextFieldValidator";
import {SnackbarError, SnackbarFormattedError} from "../../../../helpers/snackbar";
import {customersService} from "../store/services";

const useStyle = makeStyles((theme) => ({

  root: {
    '& .MuiContainer-root': {
      marginTop: 20,
      marginBottom: 20
    }
  },
  cancel: {
    borderRadius: 8,
    fontSize: 16,
    height: 46,
    padding: '8px 20px',
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
    },
  },
  closeButton: {
    top: 10,
    right: 15,
    cursor: 'pointer',
    position: 'absolute',
    fontSize: 36,
    color: grey[500],
    [theme.breakpoints.down('xs')]: {
      top: 10,
      right: 20,
      fontSize: 40,
    },
  },
}));

const UpdateShippingAddressModal = ({shippingAddress, dialogOpen, setDialogOpen, onChange}) => {

  const classes = useStyle();
  const {t} = useTranslation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [data, setData] = useState(shippingAddress);
  const [loading, setLoading] = useState(false);

  function handleSubmit () {

    setFormSubmitted(true);
    if (data.shippingAddress === null) {
      SnackbarError(t('errorMessageRequiredFields'));
      return false;
    }
    setLoading(true);
    customersService.updateCustomerShippingAddress(shippingAddress.userId, shippingAddress.id, data)
      .then(() => {
        onChange();
        setDialogOpen(false);
        setLoading(false);
      })
      .catch(e => {
        SnackbarFormattedError(e.error);
        setLoading(false);
      });
  }

  const handleChange = (event) => {

    setData({
      ...data,
      [event.target.name]: event.target.value
    });
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  const handleShippingAddressChanged = (place) => {

    setData({
      ...data,
      shippingAddress: place
    });
  };

  return (
    <Dialog disableScrollLock onClose={handleClose} open={dialogOpen || false} fullScreen={fullScreen}>
      <CloseIcon className={classes.closeButton} onClick={handleClose}/>
      <DialogTitle id="responsive-dialog-title"><Trans i18nKey="updateShippingAddress">Update shipping address</Trans></DialogTitle>
      <DialogContent>
        <Card elevation={0} className={classes.root}>
          <Container>
            <Grid container spacing={2}>
              <Grid item lg={12}>
                <FormValidator
                  className={classes.form}
                  onSubmit={handleSubmit}
                  onError={errors => console.log(errors)}
                  noValidate
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextFieldValidator
                        fullWidth
                        name="name"
                        placeholder={t('home')}
                        label={t("giveNameToYourAddress")}
                        autoComplete="shippingAddressName"
                        value={data.name}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextFieldValidator
                        fullWidth
                        name="firstName"
                        label={t("firstName")}
                        autoComplete="firstName"
                        value={data.firstName}
                        onChange={handleChange}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextFieldValidator
                        fullWidth
                        name="lastName"
                        label={t("lastName")}
                        autoComplete="lastName"
                        value={data.lastName}
                        onChange={handleChange}
                        required
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <AutocompletePlaces
                        fullWidth
                        variant="outlined"
                        name="address"
                        label={t("shippingAddress")}
                        initialValue={data.address}
                        onPlaceChanged={handleShippingAddressChanged}
                        error={formSubmitted && !data.address}
                        helperText={formSubmitted && !data.address && (t("validatorFieldRequired"))}
                        placeholder=""
                        preview
                        customFieldsEnabled
                        requiredFields={['streetNumber', 'streetName', 'zip', 'city', 'country']}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextFieldValidator
                        fullWidth
                        name="additionalInfo"
                        label={t("additionalInfo")}
                        value={data.additionalInfo}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item>
                          <Button
                            type="button"
                            fullWidth
                            variant="outlined"
                            color="primary"
                            className={classes.cancel}
                            onClick={handleClose}
                          >
                            <Trans i18nKey={"cancelButton"}>
                                                            Cancel
                            </Trans>
                          </Button>
                        </Grid>
                        <Grid item>
                          <ButtonWithLoader
                            type="submit"
                            isLoading={loading}
                            variant="contained"
                            color={"secondary"}
                          >
                            <Trans i18nKey={"updateShippingAddressButton"}>
                                                            Update shipping address
                            </Trans>
                          </ButtonWithLoader>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </FormValidator>
              </Grid>
            </Grid>
          </Container>
        </Card>
      </DialogContent>
    </Dialog>
  );
};
export default UpdateShippingAddressModal;
