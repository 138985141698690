import React from 'react';

import CustomSectionBanner from "./CustomSectionBanner";
import CustomSectionColumn3 from "./CustomSectionColumn3";
import CustomSectionColumn4 from "./CustomSectionColumn4";

export default function CustomSection({section}) {

  if(!section?.name) {
    return null;
  }

  if(section.type === 'banner') return <CustomSectionBanner section={section}/>;
  if(section.type === 'column3') return <CustomSectionColumn3 section={section}/>;
  if(section.type === 'column4') return <CustomSectionColumn4 section={section}/>;
}
