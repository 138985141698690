import axios from '../../../../helpers/axios';

export const shopService = {
  getProduct,
  getProducts,
  getProductsMinified,
  getEventsMinified,
  addProduct,
  updateProduct,
  setProductStatus,
  getProductDetails,
  addProductPdf,
  getAdminProductPdf,
  getProductPdf,
  addOrUpdateProductDetails,
  getEvent,
  addEvent,
  updateEvent,
  getCategories,
  getCategory,
  addCategory,
  updateCategory,
  setCategoryStatus,
  setCategoryPromoted,
  getKeywords,
  getKeyword,
  addKeyword,
  updateKeyword,
  setKeywordStatus,
  setKeywordPromoted,
  getBrands,
  getBrand,
  addBrand,
  updateBrand,
  getThemes,
  getTheme,
  addTheme,
  updateTheme,
  setThemeStatus,
  setThemePromoted,
  getPackagings,
  getPackaging,
  addPackaging,
  updatePackaging,
  setBrandStatus,
  setBrandPromoted,
  addStockProduct,
  getStockProducts,
  getProductStocks,
  addProductStock,
  removeProductStock,
  updateStockProduct,
  deleteStockProduct,
  getStockProductsConsumedByDates,
  getStockHistoriesByDates,
  addStockProductQuantity,
  findCommissionCategories,
  getCommissionCategory,
  addCommissionCategory,
  updateCommissionCategory,
  getDiscounts,
  getDiscount,
  addDiscount,
  updateDiscount,
  getVATs,
  getVAT,
  addVAT,
  updateVAT,
  deleteVAT,
  setDiscountStatus,
  setPostalBagStatus,
  findShippingMethods,
  getShippingMethod,
  addShippingMethod,
  updateShippingMethod,
  deleteShippingMethod,
  setShippingMethodStatus,
  findPromotions,
  findPromotionsByUserId,
  getPromotion,
  addPromotion,
  updatePromotion,
  deletePromotion,
  getBackgrounds,
  getPostalBags,
  getPostalBag,
  addPostalBag,
  updatePostalBag,
  getOrderById
};

function getProduct(productId) {

  return new Promise((resolve, reject) => {

    axios.get(`/shop/admin/product/${productId}`)
      .then(({product}) => {
        resolve(product);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getProducts() {

  return new Promise((resolve, reject) => {

    axios.get('/shop/admin/products')
      .then(({products}) => {
        resolve(products);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}
function getProductsMinified() {

  return new Promise((resolve, reject) => {

    axios.get('/shop/admin/products/minified')
      .then(({products}) => {
        resolve(products);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getEventsMinified() {

  return new Promise((resolve, reject) => {

    axios.get('/shop/admin/events/minified')
      .then(({products}) => {
        resolve(products);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function addProduct(product) {

  return new Promise((resolve, reject) => {

    axios.post('/shop/admin/product', product)
      .then(({product}) => {
        resolve(product);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function updateProduct(productId, product) {

  return new Promise((resolve, reject) => {

    axios.patch(`/shop/admin/product/${productId}`, product)
      .then(({product}) => {
        resolve(product);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function addProductPdf(productId, data) {

  return new Promise((resolve, reject) => {

    axios.post(`/shop/admin/product/${productId}/pdf`, data)
      .then(({productPdfs}) => {
        resolve(productPdfs);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getAdminProductPdf(productId) {

  return new Promise((resolve, reject) => {

    axios.get(`/shop/admin/product/${productId}/pdf`)
      .then(({productPdf}) => {
        resolve(productPdf);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getProductPdf(productId) {

  return new Promise((resolve, reject) => {

    axios.get(`/shop/product/${productId}/pdf`)
      .then(({productPdf}) => {
        resolve(productPdf);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getEvent(productId) {

  return new Promise((resolve, reject) => {

    axios.get(`/shop/admin/event/${productId}`)
      .then(({product}) => {
        resolve(product);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function addEvent(event) {

  return new Promise((resolve, reject) => {

    axios.post('/shop/admin/event', event)
      .then(({product}) => {
        resolve(product);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function updateEvent(eventId, event) {

  return new Promise((resolve, reject) => {

    axios.patch(`/shop/admin/event/${eventId}`, event)
      .then(({event}) => {
        resolve(event);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function setProductStatus(productId, status) {

  return new Promise((resolve, reject) => {

    axios.patch(`/shop/admin/product/${productId}/status`, status)
      .then(({product}) => {
        resolve(product);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getProductDetails(productId, productLang) {

  return new Promise((resolve, reject) => {

    axios.get(`/shop/admin/product/${productId}/details/${productLang}`)
      .then(({productDetails}) => {
        resolve(productDetails);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function addOrUpdateProductDetails(productId, productLang, productDetails) {

  return new Promise((resolve, reject) => {
    axios.put(`/shop/admin/product/${productId}/details/${productLang}`, productDetails)
      .then(({product}) => {
        resolve(product);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getCategories() {

  return new Promise((resolve, reject) => {

    axios.get('/shop/admin/categories')
      .then(({categories}) => {
        resolve(categories);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function addCategory(category) {

  return new Promise((resolve, reject) => {

    axios.post('/shop/admin/category', category)
      .then(({category}) => {
        resolve(category);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getCategory(categoryId) {

  return new Promise((resolve, reject) => {

    axios.get(`/shop/admin/category/${categoryId}`)
      .then(({category}) => {
        resolve(category);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function updateCategory(categoryId, category) {

  return new Promise((resolve, reject) => {

    axios.patch(`/shop/admin/category/${categoryId}`, category)
      .then(({category}) => {
        resolve(category);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function setCategoryStatus(categoryId, status) {

  return new Promise((resolve, reject) => {

    axios.patch(`/shop/admin/category/${categoryId}/status`, status)
      .then(({category}) => {
        resolve(category);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function setCategoryPromoted(categoryId, promoted) {

  return new Promise((resolve, reject) => {

    axios.patch(`/shop/admin/category/${categoryId}/promoted`, promoted)
      .then(({category}) => {
        resolve(category);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getKeywords() {

  return new Promise((resolve, reject) => {

    axios.get('/shop/admin/keywords')
      .then(({keywords}) => {
        resolve(keywords);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function addKeyword(keyword) {

  return new Promise((resolve, reject) => {

    axios.post('/shop/admin/keyword', keyword)
      .then(({keyword}) => {
        resolve(keyword);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getKeyword(keywordId) {

  return new Promise((resolve, reject) => {

    axios.get(`/shop/admin/keyword/${keywordId}`)
      .then(({keyword}) => {
        resolve(keyword);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function updateKeyword(keywordId, keyword) {

  return new Promise((resolve, reject) => {

    axios.patch(`/shop/admin/keyword/${keywordId}`, keyword)
      .then(({keyword}) => {
        resolve(keyword);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function setKeywordStatus(keywordId, status) {

  return new Promise((resolve, reject) => {

    axios.patch(`/shop/admin/keyword/${keywordId}/status`, status)
      .then(({keyword}) => {
        resolve(keyword);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function setKeywordPromoted(keywordId, promoted) {

  return new Promise((resolve, reject) => {

    axios.patch(`/shop/admin/keyword/${keywordId}/promoted`, promoted)
      .then(({keyword}) => {
        resolve(keyword);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getBrands() {

  return new Promise((resolve, reject) => {

    axios.get('/shop/admin/brands')
      .then(({brands}) => {
        resolve(brands);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function addBrand(brand) {

  return new Promise((resolve, reject) => {

    axios.post('/shop/admin/brand', brand)
      .then(({brand}) => {
        resolve(brand);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getBrand(brandId) {

  return new Promise((resolve, reject) => {

    axios.get(`/shop/admin/brand/${brandId}`)
      .then(({brand}) => {
        resolve(brand);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function updateBrand(brandId, brand) {

  return new Promise((resolve, reject) => {

    axios.patch(`/shop/admin/brand/${brandId}`, brand)
      .then(({brand}) => {
        resolve(brand);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getThemes() {

  return new Promise((resolve, reject) => {

    axios.get('/shop/admin/themes')
      .then(({themes}) => {
        resolve(themes);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function addTheme(theme) {

  return new Promise((resolve, reject) => {

    axios.post('/shop/admin/theme', theme)
      .then(({theme}) => {
        resolve(theme);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getTheme(themeId) {

  return new Promise((resolve, reject) => {

    axios.get(`/shop/admin/theme/${themeId}`)
      .then(({theme}) => {
        resolve(theme);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function updateTheme(themeId, theme) {

  return new Promise((resolve, reject) => {

    axios.patch(`/shop/admin/theme/${themeId}`, theme)
      .then(({theme}) => {
        resolve(theme);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function setThemeStatus(themeId, status) {

  return new Promise((resolve, reject) => {

    axios.patch(`/shop/admin/theme/${themeId}/status`, status)
      .then(({theme}) => {
        resolve(theme);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function setThemePromoted(themeId, promoted) {

  return new Promise((resolve, reject) => {

    axios.patch(`/shop/admin/theme/${themeId}/promoted`, promoted)
      .then(({theme}) => {
        resolve(theme);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getPackagings() {

  return new Promise((resolve, reject) => {

    axios.get('/shop/admin/packagings')
      .then(({packagings}) => {
        resolve(packagings);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function addPackaging(packaging) {

  return new Promise((resolve, reject) => {

    axios.post('/shop/admin/packaging', packaging)
      .then(({packaging}) => {
        resolve(packaging);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getPackaging(packagingId) {

  return new Promise((resolve, reject) => {

    axios.get(`/shop/admin/packaging/${packagingId}`)
      .then(({packaging}) => {
        resolve(packaging);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function updatePackaging(packagingId, packaging) {

  return new Promise((resolve, reject) => {

    axios.patch(`/shop/admin/packaging/${packagingId}`, packaging)
      .then(({packaging}) => {
        resolve(packaging);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function setBrandStatus(brandId, status) {

  return new Promise((resolve, reject) => {

    axios.patch(`/shop/admin/brand/${brandId}/status`, status)
      .then(({brand}) => {
        resolve(brand);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function setBrandPromoted(brandId, promoted) {

  return new Promise((resolve, reject) => {

    axios.patch(`/shop/admin/brand/${brandId}/promoted`, promoted)
      .then(({brand}) => {
        resolve(brand);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function addStockProduct(product) {

  return new Promise((resolve, reject) => {

    axios.post('/shop/admin/stocks/product', product)
      .then(({product}) => {
        resolve(product);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function updateStockProduct(productId, data) {

  return new Promise((resolve, reject) => {

    axios.patch(`/shop/admin/stocks/product/${productId}`, data)
      .then(() => {
        resolve();
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function deleteStockProduct(productId) {

  return new Promise((resolve, reject) => {

    axios.delete(`/shop/admin/stocks/product/${productId}`)
      .then(() => {
        resolve();
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getStockProducts() {

  return new Promise((resolve, reject) => {

    axios.get('/shop/admin/stocks/products')
      .then(({products}) => {
        resolve(products);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getStockProductsConsumedByDates(start, end) {

  return new Promise((resolve, reject) => {

    axios.get(`/shop/admin/stocks/products/consumed/${start}/${end}`)
      .then(({products}) => {
        resolve(products);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getStockHistoriesByDates(id = null, start, end) {

  return new Promise((resolve, reject) => {

    axios.get(`/shop/admin/stocks${id ? `/${id}` : ''}/histories/${start}/${end}`)
      .then(({histories}) => {
        resolve(histories);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getProductStocks(productId) {

  return new Promise((resolve, reject) => {

    axios.get(`/shop/admin/product/${productId}/stocks/products`)
      .then(({products}) => {
        resolve(products);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function addProductStock(productId, stock) {

  return new Promise((resolve, reject) => {

    axios.post(`/shop/admin/product/${productId}/stock/`, stock)
      .then(() => {
        resolve();
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function removeProductStock(productId, stockId) {

  return new Promise((resolve, reject) => {

    axios.delete(`/shop/admin/product/${productId}/stock/${stockId}`)
      .then(() => {
        resolve();
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function addStockProductQuantity(productId, quantity, note) {

  return new Promise((resolve, reject) => {

    axios.patch(`/shop/admin/stocks/product/${productId}/quantity`, {quantity, note})
      .then(() => {
        resolve();
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function findCommissionCategories() {

  return new Promise((resolve, reject) => {

    axios.get('/shop/admin/pro/commissions')
      .then(({commissionCategories}) => {
        resolve(commissionCategories);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function addCommissionCategory(commissionCategory, brandDetails) {

  return new Promise((resolve, reject) => {

    axios.post('/shop/admin/pro/commission', {
      commissionCategory,
      brandDetails
    })
      .then(({commissionCategory}) => {
        resolve(commissionCategory);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getCommissionCategory(commissionCategoryId) {

  return new Promise((resolve, reject) => {
    axios.get(`/shop/admin/pro/commission/${commissionCategoryId}`)
      .then(({commissionCategory}) => {
        resolve(commissionCategory);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function updateCommissionCategory(commissionCategoryId, commissionCategory, brandDetails) {

  return new Promise((resolve, reject) => {

    axios.patch(`/shop/admin/pro/commission/${commissionCategoryId}`, {
      commissionCategory,
      brandDetails
    })
      .then(({commissionCategory}) => {
        resolve(commissionCategory);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getDiscounts() {

  return new Promise((resolve, reject) => {

    axios.get('/shop/admin/discounts')
      .then(({discounts}) => {
        resolve(discounts);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function addDiscount(discount) {

  return new Promise((resolve, reject) => {

    axios.post('/shop/admin/discount', discount)
      .then(({discount}) => {
        resolve(discount);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getDiscount(discountId) {

  return new Promise((resolve, reject) => {

    axios.get(`/shop/admin/discount/${discountId}`)
      .then(({discount}) => {
        resolve(discount);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function updateDiscount(discountId, discount) {

  return new Promise((resolve, reject) => {

    axios.patch(`/shop/admin/discount/${discountId}`, discount)
      .then(({discount}) => {
        resolve(discount);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getVATs() {

  return new Promise((resolve, reject) => {

    axios.get('/shop/vats')
      .then(({VATs}) => {
        resolve(VATs);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function addVAT(data) {

  return new Promise((resolve, reject) => {

    axios.post('/shop/admin/vat', data)
      .then(({VAT}) => {
        resolve(VAT);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getVAT(VATId) {

  return new Promise((resolve, reject) => {

    axios.get(`/shop/vat/${VATId}`)
      .then(({VAT}) => {
        resolve(VAT);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function updateVAT(VATId, VAT) {

  return new Promise((resolve, reject) => {

    axios.patch(`/shop/admin/vat/${VATId}`, VAT)
      .then(({VAT}) => {
        resolve(VAT);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function deleteVAT(VATId) {

  return new Promise((resolve, reject) => {

    axios.delete(`/shop/admin/vat/${VATId}`)
      .then(({message}) => {
        resolve(message);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function setDiscountStatus(discountId, status) {

  return new Promise((resolve, reject) => {

    axios.patch(`/shop/admin/discount/${discountId}/status`, status)
      .then(({discount}) => {
        resolve(discount);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}
function setPostalBagStatus(postalBagId, status) {

  return new Promise((resolve, reject) => {

    axios.patch(`/shop/admin/postalbag/${postalBagId}/status`, status)
      .then(({postalBag}) => {
        resolve(postalBag);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function findShippingMethods() {

  return new Promise((resolve, reject) => {

    axios.get('/shop/admin/shippings')
      .then(({shippingMethods}) => {
        resolve(shippingMethods);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function addShippingMethod(shippingMethod) {

  return new Promise((resolve, reject) => {

    axios.post('/shop/admin/shipping', shippingMethod)
      .then(({discount}) => {
        resolve(discount);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getShippingMethod(shippingMethodId) {

  return new Promise((resolve, reject) => {

    axios.get(`/shop/admin/shipping/${shippingMethodId}`)
      .then(({shippingMethod}) => {
        resolve(shippingMethod);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function updateShippingMethod(shippingMethodId, shippingMethod) {

  return new Promise((resolve, reject) => {

    axios.patch(`/shop/admin/shipping/${shippingMethodId}`, shippingMethod)
      .then(({shippingMethod}) => {
        resolve(shippingMethod);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function deleteShippingMethod(shippingMethodId) {

  return new Promise((resolve, reject) => {

    axios.delete(`/shop/admin/shipping/${shippingMethodId}`)
      .then(() => {
        resolve();
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function findPromotions() {

  return new Promise((resolve, reject) => {

    axios.get('/shop/admin/promotions')
      .then(({promotions}) => {
        resolve(promotions);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function findPromotionsByUserId(userId) {

  return new Promise((resolve, reject) => {

    axios.get(`/admin/user/promotions/${userId}`)
      .then(({promotions}) => {
        resolve(promotions);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function addPromotion(promotion) {

  return new Promise((resolve, reject) => {

    axios.post('/shop/admin/promotion', promotion)
      .then(({promotion}) => {
        resolve(promotion);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getPromotion(promotionId) {

  return new Promise((resolve, reject) => {

    axios.get(`/shop/admin/promotion/${promotionId}`)
      .then(({promotion}) => {
        resolve(promotion);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function updatePromotion(promotionId, promotion) {

  return new Promise((resolve, reject) => {

    axios.patch(`/shop/admin/promotion/${promotionId}`, promotion)
      .then(({promotion}) => {
        resolve(promotion);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function deletePromotion(promotionId) {

  return new Promise((resolve, reject) => {

    axios.delete(`/shop/admin/promotion/${promotionId}`)
      .then(() => {
        resolve();
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function setShippingMethodStatus(shippingMethodId, status) {

  return new Promise((resolve, reject) => {

    axios.patch(`/shop/admin/shipping/${shippingMethodId}/status`, status)
      .then(({discount}) => {
        resolve(discount);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getBackgrounds() {

  return new Promise((resolve, reject) => {

    axios.get('/shop/admin/product/backgrounds')
      .then(({backgrounds}) => {
        resolve(backgrounds);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getPostalBags() {

  return new Promise((resolve, reject) => {

    axios.get('/shop/admin/postalbags')
      .then(({postalBags}) => {
        resolve(postalBags);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function addPostalBag(postalBag) {

  return new Promise((resolve, reject) => {

    axios.post('/shop/admin/postalbag', postalBag)
      .then(({postalBag}) => {
        resolve(postalBag);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getPostalBag(postalBagId) {

  return new Promise((resolve, reject) => {

    axios.get(`/shop/admin/postalbag/${postalBagId}`)
      .then(({postalBag}) => {
        resolve(postalBag);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function updatePostalBag(postalBagId, postalBag) {

  return new Promise((resolve, reject) => {

    axios.patch(`/shop/admin/postalbag/${postalBagId}`, postalBag)
      .then(({postalBag}) => {
        resolve(postalBag);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getOrderById(orderId) {

  return new Promise((resolve, reject) => {
    axios.get(`/admin/order/response/${orderId}`)
      .then(({order}) => {
        resolve(order);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}
