import produce from "immer";

import * as actionsType from './constants';

const initialState = {
  topbar: null,
  sliders: [],
  sections: [],
};

export const homeReducerKey = 'home';

export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {

      case actionsType.GET_TREES_COUNT_SUCCESS:
        draft.topbar = action.topbar;
        break;

      case actionsType.GET_TOPBAR_SUCCESS:
        draft.topbar = action.topbar;
        break;

      case actionsType.GET_SLIDERS_SUCCESS:
        draft.sliders = action.sliders;
        break;

      case actionsType.GET_SECTIONS_SUCCESS:
        draft.sections = action.sections;
        break;

      default:

    }
  });
