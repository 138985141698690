import React, {useEffect, useState} from "react";
import { Trans, useTranslation } from "react-i18next";
import { red } from "@mui/material/colors";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Typography from "@mui/material/Typography";
import {makeStyles} from '@mui/styles';

import PageLoader from "../../../../../components/PageLoader";
import {SnackbarFormattedError} from "../../../../../helpers/snackbar";
import {shopService} from "../../../../Customer/Shop/store/services";

import SelectPickupPointModal from "./SelectPickupPointModal";

const useStyle = makeStyles(theme => ({

  root: {
    '& .MuiFormControl-root': {
      width: '100%'
    },
    '& label': {
      width: '100%',
      border: '1px solid #c4c4c4',
      margin: theme.spacing(0, 0.8, 1, 0),
      borderRadius: '5px',
      padding: '5px 0',
      '& .MuiFormControlLabel-label': {
        marginRight: 10,
        '& img': {
          height: 40,
        }
      }
    }
  },
  methodImg: {
    width: 80,
    textAlign: 'center'
  },
  methodDetails: {
    '& .MuiTypography-body1': {
      lineHeight: '18px',
      minWidth: 200
    }
  },
  pickupPoint: {
    lineHeight: '23px !important',
    fontWeight: 'bold'
  },
  pickupPointSelected: {
    lineHeight: '23px !important',
    color: red[500],
    fontWeight: 'bold'
  }
}));

const SelectShippingMethod = ({customer, freeShipping, weight, methodSelected, shippingAddress, shippingPickupPoint, onMethodChange, onPickupPointChange}) => {

  const classes = useStyle();
  const {t} = useTranslation();
  const [pickupPointModalOpen, setPickupPointModalOpen] = useState(false);
  const [pickupPointIdSelection, setPickupPointIdSelection] = useState(null);
  const [shippingMethods, setShippingMethods] = useState([]);
  const totalWeight = null;

  useEffect(() => {

    shopService.getShippingMethods(shippingAddress.country, weight).then(shippingMethods => {
      setShippingMethods(shippingMethods);
      onMethodChange(shippingMethods[0]);
    })
      .catch(e => SnackbarFormattedError(e.error));
  }, [totalWeight, shippingAddress]);

  const handleRadioChange = (e) => {

    const shippingMethod = shippingMethods.find(method => method.id === parseInt(e.target.value));
    if(shippingMethod.pickupPoint) {
      setPickupPointIdSelection(shippingMethod);
      setPickupPointModalOpen(true);
    }
    else {
      onMethodChange(shippingMethod);
    }
  };

  const handleSelectPickupPoint = (location) => {

    onPickupPointChange(location);
    onMethodChange(pickupPointIdSelection);
    setPickupPointIdSelection(null);
    setPickupPointModalOpen(false);
  };

  if(shippingMethods.length === 0) {
    return (
      <Grid container justify={"center"} spacing={4}>
        <PageLoader height={150}/>
      </Grid>
    );
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography component="h3" variant={"h6"}>
          <Trans i18nKey="deliveryMethod">Delivery method</Trans> :
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <form className={classes.root}>
          <FormControl component="fieldset">
            <RadioGroup name="shippingMethod" value={parseInt(methodSelected?.id)} onChange={handleRadioChange}>
              {shippingMethods.map(shippingMethod => (
                <FormControlLabel key={shippingMethod.id} value={shippingMethod.id} control={<Radio />} label={
                  <Grid container spacing={2} alignItems={"center"}>
                    {shippingMethod.image && (
                      <Grid item className={classes.methodImg}>
                        <Grid container alignItems={"center"}>
                          <img src={shippingMethod.image} alt=""/>
                        </Grid>
                      </Grid>
                    )}
                    <Grid item className={classes.methodDetails}>
                      {shippingMethod.pickupPoint === 1 && (
                        <Typography className={classes.pickupPoint}><Trans i18nKey={"pickupPoint"}>Pickup point</Trans></Typography>
                      )}
                      <Typography><b>{((freeShipping && shippingMethod.allowFree) || shippingMethod.price === 0) ? t("free") : `${shippingMethod.price.toFixed(2)} ${shippingMethod.currency}`} - {t(shippingMethod.name)}</b></Typography>
                      {shippingMethod.name === "withoutDelivery" && (
                        <Typography>{t("collectTheOrderAtTheWarehouse")}</Typography>
                      )}
                      {shippingMethod.deliveryDays !== "0" && (
                        <Typography><Trans i18nKey="workingDays" values={{days:shippingMethod.deliveryDays}}>{shippingMethod.deliveryDays} working days</Trans></Typography>
                      )}
                      {shippingPickupPoint && parseInt(methodSelected?.id) === shippingMethod.id && (
                        <Typography className={classes.pickupPointSelected}>
                          {shippingPickupPoint.name}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                } />
              ))}
            </RadioGroup>
          </FormControl>
        </form>
        {pickupPointModalOpen && (
          <SelectPickupPointModal customer={customer} shippingAddress={shippingAddress} modalOpen={pickupPointModalOpen} setModalOpen={setPickupPointModalOpen} onSelect={handleSelectPickupPoint}/>
        )}
      </Grid>
    </Grid>
  );
};
export default SelectShippingMethod;
