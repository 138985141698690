import React from 'react';
import {Trans} from "react-i18next";
import { useHistory } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { grey } from "@mui/material/colors";
import Divider from "@mui/material/Divider";
import Drawer from '@mui/material/Drawer';
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {makeStyles} from '@mui/styles';

import SearchUserAutocomplete from "../../../containers/Admin/Users/components/SearchUserAutocomplete";
import ProductsAutocomplete from "../../../containers/Customer/Shop/components/Search/ProductsAutocomplete";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '60vw',
    maxWidth: 500,
    padding: theme.spacing(3, 3, 0),
    [theme.breakpoints.down('xs')]: {
      width: '100vw',
      maxWidth: 'unset'
    },
  },
  closeButton: {
    top: 10,
    right: 15,
    cursor: 'pointer',
    position: 'absolute',
    fontSize: 36,
    color: grey[500],
    [theme.breakpoints.down('xs')]: {
      top: 10,
      right: 20,
      fontSize: 40,
    },
  }
}));

const SearchDrawer = ({searchDrawerOpen, setSearchDrawerOpen}) => {
  const classes = useStyles();
  const history = useHistory();

  const handleClose = () => {
    setSearchDrawerOpen(false);
  };

  const handleSelectUser = (e, value) => {
    if(value.id) {
      history.push(`/admin/users/${value.id}`);
      setSearchDrawerOpen(false);
    }
  };

  const handleSelectProduct = (value) => {

    if(value.id) {
      history.push(`/admin/shop/product/${value.id}`);
      setSearchDrawerOpen(false);
    }
  };

  return (
    <div>
      <React.Fragment>
        <Drawer anchor={'right'} open={searchDrawerOpen} onClose={() => setSearchDrawerOpen(false)}>
          <CloseIcon className={classes.closeButton} onClick={handleClose}/>
          <div
            className={classes.root}
            role="presentation"
          >
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Typography component="h1" variant="h4">
                  <Trans i18nKey={"search"}>Search</Trans>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <SearchUserAutocomplete autoFocus target={"all"} onChange={handleSelectUser}/>
              </Grid>
              <Grid item>
                <Divider/>
              </Grid>
              <Grid item xs={12}>
                <ProductsAutocomplete label={"Produits"} onChange={handleSelectProduct} variant={"outlined"} onSelect={handleSelectProduct} withoutRestriction/>
              </Grid>
            </Grid>
          </div>

        </Drawer>
      </React.Fragment>
    </div>
  );
};
export default SearchDrawer;
