import React from "react";
import {Helmet} from "react-helmet-async";
import {useTranslation} from "react-i18next";
import {Box} from "@mui/material";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";

import theme from "../assets/styles/theme/theme";
import CookieConsent from "../components/Layout/Customer/CookieConsent/CookieConsent";
import Header from "../components/Layout/Customer/Header/Header";
import Footer from "../components/Layout/Footer";
import AccountDrawer from "../containers/Customer/Account/AccountDrawer";
import CheckoutDrawer from "../containers/Customer/Shop/Checkout/CheckoutDrawer";
import CheckoutResponseDrawer from "../containers/Customer/Shop/Checkout/CheckoutResponseDrawer";
import {initLanguage} from "../helpers/multilingual";
import CustomerRoutes from "../routes/CustomerRoutes";

function CustomerLayout(props) {

  const {i18n} = useTranslation();

  initLanguage(props.match.params.lang || 'fr');

  return (
    <>
      <Helmet>
        <html lang={i18n.language}/>
      </Helmet>
      <Header/>
      <Box component={"main"} sx={{
        backgroundColor: theme.palette.common.white,
        minHeight: '70vh',
        //mt: {xs: '16px', sm: 0}
      }}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <CustomerRoutes {...props}/>
          <AccountDrawer />
        </LocalizationProvider>
      </Box>
      <Box>
        <CheckoutDrawer/>
        <CheckoutResponseDrawer/>
      </Box>
      <Footer/>
      <CookieConsent/>
    </>
  );

}

export default CustomerLayout;
