import {useEffect, useRef} from "react";

function useDebouncedEffect(effect, deps, delay) {
  const savedEffect = useRef();

  useEffect(() => {
    savedEffect.current = effect;
  }, [effect]);

  useEffect(() => {
    const handler = setTimeout(() => savedEffect.current(), delay);
    return () => clearTimeout(handler);
  }, [...deps, delay]);

}

export default useDebouncedEffect;
