import React from "react";
//import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
//import AccountBalanceTwoToneIcon from '@mui/icons-material/AccountBalanceTwoTone';
//import AccountBalanceWalletTwoToneIcon from '@mui/icons-material/AccountBalanceWalletTwoTone';
//import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
//import ExitToAppIcon from '@mui/icons-material/ExitToApp';
//import FingerprintTwoToneIcon from '@mui/icons-material/FingerprintTwoTone';
//import GroupTwoToneIcon from '@mui/icons-material/GroupTwoTone';
import MonetizationOnTwoToneIcon from '@mui/icons-material/MonetizationOnTwoTone';
//import NewReleasesTwoToneIcon from '@mui/icons-material/NewReleasesTwoTone';
//import PersonOutlineTwoToneIcon from '@mui/icons-material/PersonOutlineTwoTone';
//import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
//import SpeedTwoToneIcon from '@mui/icons-material/SpeedTwoTone';
//import StorefrontTwoToneIcon from '@mui/icons-material/StorefrontTwoTone';
//import SyncAltTwoToneIcon from '@mui/icons-material/SyncAltTwoTone';
//import TuneTwoToneIcon from '@mui/icons-material/TuneTwoTone';
import { blue, grey } from "@mui/material/colors";
import Drawer from "@mui/material/Drawer";
import Hidden from "@mui/material/Hidden";
import Toolbar from "@mui/material/Toolbar";
import {makeStyles} from '@mui/styles';

import {isProNoDelegate} from "../../../containers/Authentication/store/selectors";
//import {userSelector} from "../../../containers/Authentication/store/selectors";
//import {customerRedirectionsCountSelector} from "../../../containers/Pro/Customers/store/selectors";
import Menu from "../Menu/Menu";
import MenuItem from "../Menu/MenuItem";
import MenuSubItem from "../Menu/MenuSubItem";
//import MenuSubItem from "../Menu/MenuSubItem";
import {menuActions} from "../Menu/store/actions";
import {menuIsOpenSelector} from "../Menu/store/selectors";

const drawerWidth = 240;

const useStylesDrawerPro = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: drawerWidth,
    '& .MuiToolbar-root': {
      '& img': {
        marginTop: 8,
        width: 160,
        maxWidth: '100%'
      }
    }
  },
  drawerContainer: {
    overflow: 'auto',
    '& a': {
      color: 'inherit',
      textDecoration: 'none',
    },
    '& ul': {
      '& .active': {
        backgroundColor: grey[200]
      },
    },
    '& .MuiCollapse-container': {
      '& .MuiListItem-button': {
        height: 50,
        paddingLeft: 50
      }
    },
    '& .MuiListItem-button': {
      height: '80px',
      '& .MuiSvgIcon-root': {
        fontSize: '2em',
        fill: blue[900]
      }
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

const MenuDrawer = () => {

  //const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStylesDrawerPro();
  //const user = useSelector(userSelector);
  //const customerRedirectionsCount = useSelector(customerRedirectionsCountSelector());
  const menuIsOpen = useSelector(menuIsOpenSelector);
  const isDoctorOrTherap = useSelector(isProNoDelegate);

  const handleDrawerToggle = () => {
    dispatch(menuActions.toggleMenu());
  };

  let drawerList = (
    <div className={classes.drawerContainer}>
      <Menu>
        <MenuItem
          title={"Commandes patients"}
          to={"/pro/commissions/sales"}
          icon={<MonetizationOnTwoToneIcon />}
        />
        <MenuItem
          title={"Mes patients"}
          expandTo={'/pro/customer'}
          icon={<MonetizationOnTwoToneIcon />}
          hidden={!isDoctorOrTherap}
          //notificationsCount={customerRedirectionsCount}
        >
          <MenuSubItem
            title={"Liste des patients"}
            to={"/pro/customers"}
            icon={<MonetizationOnTwoToneIcon />}
          />
          {/*<MenuSubItem
            title={"Ajouter un patient"}
            to={"/pro/customer/add"}
            icon={<MonetizationOnTwoToneIcon />}
            hidden
          />*/}
        </MenuItem>
      </Menu>
    </div>
  );
  return (
    <nav className={classes.drawer}>
      <Hidden mdUp implementation={'css'}>
        <Drawer
          className={classes.drawer}
          variant="temporary"
          open={menuIsOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <Toolbar>
            <img src={`${process.env.REACT_APP_API_RESOURCES_URL}/images/logo/logo-pro.svg`} alt=""/>
          </Toolbar>
          {drawerList}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation={'css'}>
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open
        >
          <Toolbar/>
          {drawerList}
        </Drawer>
      </Hidden>
    </nav>
  );
};

export default MenuDrawer;




/*const drawerList = (
  <div className={classes.drawerContainer}>
    <Menu>
      <MenuItem
        title={t("dashboard")}
        to={"/pro"}
        icon={<SpeedTwoToneIcon />}
      />
      <MenuItem
        title={t("commissions")}
        expandTo={"/pro/commissions"}
        icon={<AccountBalanceTwoToneIcon />}
      >
        <MenuSubItem
          title={t("bank")}
          to={"/pro/commissions/bank"}
          icon={<AccountBalanceWalletTwoToneIcon />}
          disabled
        />
        <MenuSubItem
          title={t("sales")}
          to={"/pro/commissions/sales"}
          icon={<MonetizationOnTwoToneIcon />}
        />
        <MenuSubItem
          title={t("transfers")}
          to={"/pro/commissions/transfers"}
          icon={<SyncAltTwoToneIcon />}
          disabled
        />
      </MenuItem>
      {/!* <MenuItem
                  title={t("networkAdmin")}
                  expandTo={'/pro/network'}
                  hidden={user.career === PARTNER_CAREER || user.career === NUTRITIONIST_CAREER}
                  icon={<AccountTreeTwoToneIcon />}
                  notificationsCount={networkRequestsCount}
              >
                  <MenuSubItem
                      title={t("treeStructure")}
                      to={'/pro/network/tree'}
                      icon={<AccountTreeTwoToneIcon />}
                  />
                  <MenuSubItem
                      title={t("listStructure")}
                      to={"/pro/network/table"}
                      icon={<TableChartTwoToneIcon />}
                  />
                  <MenuSubItem
                      title={t("mapStructure")}
                      to={"/pro/network/map"}
                      icon={<MapTwoToneIcon />}
                  />
                  <MenuSubItem
                      title={t("partners")}
                      expandTo={'/pro/network/partner'}
                      to={'/pro/network/partners'}
                      icon={<ContactsTwoToneIcon />}
                  />
                  <MenuSubItem
                      hidden={!userHasAuthConsultant}
                      title={t("evolution")}
                      to={'/pro/network/evolution'}
                      icon={<TimelineTwoToneIcon />}
                  />
                  <MenuSubItem
                      title={t("requests")}
                      to={'/pro/network/requests'}
                      icon={<NewReleasesTwoToneIcon />}
                      notificationsCount={networkRequestsCount}
                  />
              </MenuItem>*!/}
      <MenuItem
        title={t("customersAdmin")}
        expandTo={'/pro/customer'}
        icon={<GroupTwoToneIcon />}
        notificationsCount={customerRedirectionsCount}
      >
        <MenuSubItem
          title={t("customers")}
          expandTo={"/pro/customer/"}
          to={"/pro/customers"}
          icon={<GroupTwoToneIcon />}
          disabled
        />
        <MenuSubItem
          title={t("giftCards")}
          to={"/pro/customers/gifts"}
          icon={<CardGiftcardIcon />}
          disabled
        />
        <MenuSubItem
          title={t("requests")}
          to={'/pro/customers/requests'}
          icon={<NewReleasesTwoToneIcon />}
          hidden={!user.certified}
          notificationsCount={customerRedirectionsCount}
          disabled
        />
      </MenuItem>
      {/!*<MenuItem
                  title={t("documentsAndProtocols")}
                  expandTo={['/pro/documents', '/pro/protocols', '/pro/businessCard']}
                  icon={<DescriptionTwoToneIcon />}
                  hidden={user.career === PARTNER_CAREER}
              >
                  <MenuSubItem
                      title={"Documents"}
                      to={'/pro/documents'}
                      expandTo={'/pro/documents'}
                      icon={<DescriptionTwoToneIcon />}
                  />
                  <MenuSubItem
                      title={t("protocols")}
                      to={'/pro/protocols'}
                      expandTo={'/pro/protocols'}
                      icon={<StorageTwoToneIcon />}
                  />
                  <MenuSubItem
                      title={t("businessCard")}
                      to={'/pro/businessCard'}
                      expandTo={'/pro/businessCard'}
                      icon={<ContactMailIcon />}
                      hidden={!userHasAuthConsultant}
                  />
              </MenuItem>
              <MenuItem
                  title={t("meetings")}
                  to={"/pro/meetings"}
                  expandTo={"/pro/meetings"}
                  icon={<OndemandVideoTwoToneIcon/>}
              />
              <MenuItem
                  title={t("faqNutrition")}
                  expandTo={'/pro/faq'}
                  icon={<LiveHelpTwoToneIcon />}
                  hidden={user.career === PARTNER_CAREER}
              >
                  <MenuSubItem
                      title={t("search")}
                      expandTo={'/pro/faq/question'}
                      to={'/pro/faq'}
                      icon={<FindInPageTwoToneIcon />}
                  />
                  <MenuSubItem
                      title={t("myQuestions")}
                      to={'/pro/faq/my-questions'}
                      icon={<LiveHelpTwoToneIcon />}
                  />
              </MenuItem>*!/}
      {/!*<MenuItem
                  title={t("office")}
                  to={"/pro/office"}
                  expandTo={"/pro/office"}
                  icon={<BusinessCenterIcon />}
              />*!/}
      <MenuItem
        title={t("settings")}
        expandTo={'/pro/settings'}
        icon={<TuneTwoToneIcon />}
      >
        <MenuSubItem
          title={t("shop")}
          to={"/pro/settings/shop"}
          icon={<StorefrontTwoToneIcon />}
          disabled
        />
        <MenuSubItem
          title={t("account")}
          to={"/pro/settings/account"}
          icon={<PersonOutlineTwoToneIcon />}
          disabled
        />
        <MenuSubItem
          title={t("identifier")}
          to={"/pro/settings/identifier"}
          icon={<FingerprintTwoToneIcon />}
          disabled
        />
        <MenuSubItem
          title={t("general")}
          to={"/pro/settings/general"}
          icon={<SettingsOutlinedIcon />}
          disabled
        />
      </MenuItem>
      <Hidden implementation={"css"} smUp>
        <MenuItem
          title={t("backToTheSiteButton")}
          to={"/"}
          icon={<ExitToAppIcon />}
        />
      </Hidden>
    </Menu>
  </div>
);*/