export const GET_STATISTICS_DATA_BY_MONTH_REQUEST   = 'ADMIN_STATS/GET_STATISTICS_DATA_BY_MONTH_REQUEST';
export const GET_STATISTICS_DATA_BY_MONTH_ERROR     = 'ADMIN_STATS/GET_STATISTICS_DATA_BY_MONTH_ERROR';
export const GET_STATISTICS_DATA_BY_MONTH_SUCCESS   = 'ADMIN_STATS/GET_STATISTICS_DATA_BY_MONTH_SUCCESS';
export const GET_STATISTICS_DATA_BY_INTERVAL_REQUEST   = 'ADMIN_STATS/GET_STATISTICS_DATA_BY_INTERVAL_REQUEST';
export const GET_STATISTICS_DATA_BY_INTERVAL_ERROR     = 'ADMIN_STATS/GET_STATISTICS_DATA_BY_INTERVAL_ERROR';
export const GET_STATISTICS_DATA_BY_INTERVAL_SUCCESS   = 'ADMIN_STATS/GET_STATISTICS_DATA_BY_INTERVAL_SUCCESS';

export const SET_STATS_DISPLAYED   = 'ADMIN_STATS/SET_STATS_DISPLAYED';
export const SET_STATS_COMPARED   = 'ADMIN_STATS/SET_STATS_COMPARED';
export const SET_STATS_SECTION     = 'ADMIN_STATS/SET_STATS_SECTION';

export const SET_ADD_CARD_OPEN     = 'ADMIN_STATS/SET_ADD_CARD_OPEN';
export const SET_PRO_NUMBER_DISPLAYED = 'ADMIN_STATS/SET_PRO_NUMBER_DISPLAYED';
export const DELETE_CARD            = 'ADMIN_STATS/DELETE_CARD';

export const GET_TURNOVER_BY_MONTH_REQUEST   = 'ADMIN_STATS/GET_TURNOVER_BY_MONTH_REQUEST';
export const GET_TURNOVER_BY_MONTH_ERROR     = 'ADMIN_STATS/GET_TURNOVER_BY_MONTH_ERROR';
export const GET_TURNOVER_BY_MONTH_SUCCESS   = 'ADMIN_STATS/GET_TURNOVER_BY_MONTH_SUCCESS';

export const GET_RECURRING_TURNOVER_BY_MONTH_REQUEST   = 'ADMIN_STATS/GET_RECURRING_TURNOVER_BY_MONTH_REQUEST';
export const GET_RECURRING_TURNOVER_BY_MONTH_ERROR     = 'ADMIN_STATS/GET_RECURRING_TURNOVER_BY_MONTH_ERROR';
export const GET_RECURRING_TURNOVER_BY_MONTH_SUCCESS   = 'ADMIN_STATS/GET_RECURRING_TURNOVER_BY_MONTH_SUCCESS';

export const GET_RECRUITMENT_BY_MONTH_REQUEST    = 'ADMIN_STATS/GET_RECRUITMENT_BY_MONTH_REQUEST';
export const GET_RECRUITMENT_BY_MONTH_SUCCESS    = 'ADMIN_STATS/GET_RECRUITMENT_BY_MONTH_SUCCESS';
export const GET_RECRUITMENT_BY_MONTH_ERROR      = 'ADMIN_STATS/GET_RECRUITMENT_BY_MONTH_ERROR';

export const GET_PACKS_AMOUNT_REQUEST   = 'ADMIN_STATS/GET_PACKS_AMOUNT_REQUEST';
export const GET_PACKS_AMOUNT_SUCCESS   = 'ADMIN_STATS/GET_PACKS_AMOUNT_SUCCESS';
export const GET_PACKS_AMOUNT_ERROR     = 'ADMIN_STATS/GET_PACKS_AMOUNT_ERROR';

export const GET_PRODUCTS_AMOUNT_REQUEST    = 'ADMIN_STATS/GET_PRODUCTS_AMOUNT_REQUEST';
export const GET_PRODUCTS_AMOUNT_SUCCESS    = 'ADMIN_STATS/GET_PRODUCTS_AMOUNT_SUCCESS';
export const GET_PRODUCTS_AMOUNT_ERROR      = 'ADMIN_STATS/GET_PRODUCTS_AMOUNT_ERROR';

export const GET_PACKS_AMOUNT_BY_MONTH_REQUEST   = 'ADMIN_STATS/GET_PACKS_AMOUNT_BY_MONTH_REQUEST';
export const GET_PACKS_AMOUNT_BY_MONTH_SUCCESS   = 'ADMIN_STATS/GET_PACKS_AMOUNT_BY_MONTH_SUCCESS';
export const GET_PACKS_AMOUNT_BY_MONTH_ERROR     = 'ADMIN_STATS/GET_PACKS_AMOUNT_BY_MONTH_ERROR';

export const GET_BESTSELLERS_AMOUNT_REQUEST   = 'ADMIN_STATS/GET_BESTSELLERS_AMOUNT_REQUEST';
export const GET_BESTSELLERS_AMOUNT_SUCCESS   = 'ADMIN_STATS/GET_BESTSELLERS_AMOUNT_SUCCESS';
export const GET_BESTSELLERS_AMOUNT_ERROR     = 'ADMIN_STATS/GET_BESTSELLERS_AMOUNT_ERROR';

export const GET_BEST_RECRUITERS_AMOUNT_REQUEST   = 'ADMIN_STATS/GET_BEST_RECRUITERS_AMOUNT_REQUEST';
export const GET_BEST_RECRUITERS_AMOUNT_SUCCESS   = 'ADMIN_STATS/GET_BEST_RECRUITERS_AMOUNT_SUCCESS';
export const GET_BEST_RECRUITERS_AMOUNT_ERROR     = 'ADMIN_STATS/GET_BEST_RECRUITERS_AMOUNT_ERROR';

export const GET_ORDERS_COUNT_BY_MONTH_REQUEST   = 'ADMIN_STATS/GET_ORDERS_COUNT_BY_MONTH_REQUEST';
export const GET_ORDERS_COUNT_BY_MONTH_SUCCESS   = 'ADMIN_STATS/GET_ORDERS_COUNT_BY_MONTH_SUCCESS';
export const GET_ORDERS_COUNT_BY_MONTH_ERROR     = 'ADMIN_STATS/GET_ORDERS_COUNT_BY_MONTH_ERROR';

export const GET_PROS_COUNT_BY_MONTH_REQUEST  = 'ADMIN_STATS/GET_PROS_COUNT_BY_MONTH_REQUEST';
export const GET_PROS_COUNT_BY_MONTH_SUCCESS  = 'ADMIN_STATS/GET_PROS_COUNT_BY_MONTH_SUCCESS';
export const GET_PROS_COUNT_BY_MONTH_ERROR    = 'ADMIN_STATS/GET_PROS_COUNT_BY_MONTH_ERROR';

export const GET_ACTIVE_PROS_COUNT_BY_MONTH_REQUEST  = 'ADMIN_STATS/GET_ACTIVE_PROS_COUNT_BY_MONTH_REQUEST';
export const GET_ACTIVE_PROS_COUNT_BY_MONTH_SUCCESS  = 'ADMIN_STATS/GET_ACTIVE_PROS_COUNT_BY_MONTH_SUCCESS';
export const GET_ACTIVE_PROS_COUNT_BY_MONTH_ERROR    = 'ADMIN_STATS/GET_ACTIVE_PROS_COUNT_BY_MONTH_ERROR';

export const GET_NETWORK_ACTIVE_PROS_COUNT_BY_MONTH_REQUEST  = 'ADMIN_STATS/GET_NETWORK_ACTIVE_PROS_COUNT_BY_MONTH_REQUEST';
export const GET_NETWORK_ACTIVE_PROS_COUNT_BY_MONTH_SUCCESS  = 'ADMIN_STATS/GET_NETWORK_ACTIVE_PROS_COUNT_BY_MONTH_SUCCESS';
export const GET_NETWORK_ACTIVE_PROS_COUNT_BY_MONTH_ERROR    = 'ADMIN_STATS/GET_NETWORK_ACTIVE_PROS_COUNT_BY_MONTH_ERROR';

export const GET_TURNOVER_BY_CAREER_REQUEST   = 'ADMIN_STATS/GET_TURNOVER_BY_CAREER_REQUEST';
export const GET_TURNOVER_BY_CAREER_SUCCESS   = 'ADMIN_STATS/GET_TURNOVER_BY_CAREER_SUCCESS';
export const GET_TURNOVER_BY_CAREER_ERROR     = 'ADMIN_STATS/GET_TURNOVER_BY_CAREER_ERROR';

export const GET_DIRECT_SALES_REQUEST           = 'ADMIN_STATS/GET_DIRECT_SALES_REQUEST';
export const GET_DIRECT_SALES_ERROR             = 'ADMIN_STATS/GET_DIRECT_SALES_ERROR';
export const GET_DIRECT_SALES_SUCCESS           = 'ADMIN_STATS/GET_DIRECT_SALES_SUCCESS';
export const GET_NETWORK_SALES_REQUEST          = 'ADMIN_STATS/GET_NETWORK_SALES_REQUEST';
export const GET_NETWORK_SALES_ERROR            = 'ADMIN_STATS/GET_NETWORK_SALES_ERROR';
export const GET_NETWORK_SALES_SUCCESS          = 'ADMIN_STATS/GET_NETWORK_SALES_SUCCESS';

export const GET_DIRECT_RECURRING_SALES_REQUEST     = 'ADMIN_STATS/GET_DIRECT_RECURRING_SALES_REQUEST';
export const GET_DIRECT_RECURRING_SALES_ERROR       = 'ADMIN_STATS/GET_DIRECT_RECURRING_SALES_ERROR';
export const GET_DIRECT_RECURRING_SALES_SUCCESS     = 'ADMIN_STATS/GET_DIRECT_RECURRING_SALES_SUCCESS';
export const GET_NETWORK_RECURRING_SALES_REQUEST    = 'ADMIN_STATS/GET_NETWORK_RECURRING_SALES_REQUEST';
export const GET_NETWORK_RECURRING_SALES_ERROR      = 'ADMIN_STATS/GET_NETWORK_RECURRING_SALES_ERROR';
export const GET_NETWORK_RECURRING_SALES_SUCCESS    = 'ADMIN_STATS/GET_NETWORK_RECURRING_SALES_SUCCESS';

export const GET_DIRECT_RECRUITMENT_REQUEST     = 'ADMIN_STATS/GET_DIRECT_RECRUITMENT_REQUEST';
export const GET_DIRECT_RECRUITMENT_SUCCESS     = 'ADMIN_STATS/GET_DIRECT_RECRUITMENT_SUCCESS';
export const GET_DIRECT_RECRUITMENT_ERROR       = 'ADMIN_STATS/GET_DIRECT_RECRUITMENT_ERROR';
export const GET_NETWORK_RECRUITMENT_REQUEST    = 'ADMIN_STATS/GET_NETWORK_RECRUITMENT_REQUEST';
export const GET_NETWORK_RECRUITMENT_SUCCESS    = 'ADMIN_STATS/GET_NETWORK_RECRUITMENT_SUCCESS';
export const GET_NETWORK_RECRUITMENT_ERROR      = 'ADMIN_STATS/GET_NETWORK_RECRUITMENT_ERROR';

export const GET_DIRECT_BESTSELLERS_AMOUNT_REQUEST   = 'ADMIN_STATS/GET_DIRECT_BESTSELLERS_AMOUNT_REQUEST';
export const GET_DIRECT_BESTSELLERS_AMOUNT_SUCCESS   = 'ADMIN_STATS/GET_DIRECT_BESTSELLERS_AMOUNT_SUCCESS';
export const GET_DIRECT_BESTSELLERS_AMOUNT_ERROR     = 'ADMIN_STATS/GET_DIRECT_BESTSELLERS_AMOUNT_ERROR';

export const GET_DIRECT_BEST_RECRUITERS_AMOUNT_REQUEST   = 'ADMIN_STATS/GET_DIRECT_BEST_RECRUITERS_AMOUNT_REQUEST';
export const GET_DIRECT_BEST_RECRUITERS_AMOUNT_SUCCESS   = 'ADMIN_STATS/GET_DIRECT_BEST_RECRUITERS_AMOUNT_SUCCESS';
export const GET_DIRECT_BEST_RECRUITERS_AMOUNT_ERROR     = 'ADMIN_STATS/GET_DIRECT_BEST_RECRUITERS_AMOUNT_ERROR';

export const GET_NETWORK_BESTSELLERS_AMOUNT_REQUEST   = 'ADMIN_STATS/GET_NETWORK_BESTSELLERS_AMOUNT_REQUEST';
export const GET_NETWORK_BESTSELLERS_AMOUNT_SUCCESS   = 'ADMIN_STATS/GET_NETWORK_BESTSELLERS_AMOUNT_SUCCESS';
export const GET_NETWORK_BESTSELLERS_AMOUNT_ERROR     = 'ADMIN_STATS/GET_NETWORK_BESTSELLERS_AMOUNT_ERROR';

export const GET_NETWORK_BEST_RECRUITERS_AMOUNT_REQUEST   = 'ADMIN_STATS/GET_NETWORK_BEST_RECRUITERS_AMOUNT_REQUEST';
export const GET_NETWORK_BEST_RECRUITERS_AMOUNT_SUCCESS   = 'ADMIN_STATS/GET_NETWORK_BEST_RECRUITERS_AMOUNT_SUCCESS';
export const GET_NETWORK_BEST_RECRUITERS_AMOUNT_ERROR     = 'ADMIN_STATS/GET_NETWORK_BEST_RECRUITERS_AMOUNT_ERROR';

export const GET_DIRECT_PACKS_AMOUNT_REQUEST        = 'ADMIN_STATS/GET_DIRECT_PACKS_AMOUNT_REQUEST';
export const GET_DIRECT_PACKS_AMOUNT_SUCCESS        = 'ADMIN_STATS/GET_DIRECT_PACKS_AMOUNT_SUCCESS';
export const GET_DIRECT_PACKS_AMOUNT_ERROR          = 'ADMIN_STATS/GET_DIRECT_PACKS_AMOUNT_ERROR';

export const GET_NETWORK_PACKS_AMOUNT_REQUEST       = 'ADMIN_STATS/GET_NETWORK_PACKS_AMOUNT_REQUEST';
export const GET_NETWORK_PACKS_AMOUNT_SUCCESS       = 'ADMIN_STATS/GET_NETWORK_PACKS_AMOUNT_SUCCESS';
export const GET_NETWORK_PACKS_AMOUNT_ERROR         = 'ADMIN_STATS/GET_NETWORK_PACKS_AMOUNT_ERROR';

export const GET_DIRECT_PRODUCTS_AMOUNT_REQUEST     = 'ADMIN_STATS/GET_DIRECT_PRODUCTS_AMOUNT_REQUEST';
export const GET_DIRECT_PRODUCTS_AMOUNT_SUCCESS     = 'ADMIN_STATS/GET_DIRECT_PRODUCTS_AMOUNT_SUCCESS';
export const GET_DIRECT_PRODUCTS_AMOUNT_ERROR       = 'ADMIN_STATS/GET_DIRECT_PRODUCTS_AMOUNT_ERROR';

export const GET_NETWORK_PRODUCTS_AMOUNT_REQUEST    = 'ADMIN_STATS/GET_NETWORK_PRODUCTS_AMOUNT_REQUEST';
export const GET_NETWORK_PRODUCTS_AMOUNT_SUCCESS    = 'ADMIN_STATS/GET_NETWORK_PRODUCTS_AMOUNT_SUCCESS';
export const GET_NETWORK_PRODUCTS_AMOUNT_ERROR      = 'ADMIN_STATS/GET_NETWORK_PRODUCTS_AMOUNT_ERROR';

export const GET_NETWORK_TURNOVER_BY_CAREER_REQUEST = 'ADMIN_STATS/GET_NETWORK_TURNOVER_BY_CAREER_REQUEST';
export const GET_NETWORK_TURNOVER_BY_CAREER_SUCCESS = 'ADMIN_STATS/GET_NETWORK_TURNOVER_BY_CAREER_SUCCESS';
export const GET_NETWORK_TURNOVER_BY_CAREER_ERROR   = 'ADMIN_STATS/GET_NETWORK_TURNOVER_BY_CAREER_ERROR';

