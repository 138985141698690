import React, {useEffect} from "react";
import {Helmet} from "react-helmet-async";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Box, Stack} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {makeStyles} from '@mui/styles';
import moment from "moment";

import {getLangPath} from "../../../helpers/multilingual";
import {sessionSelector} from "../Shop/store/selectors";

import {magActions} from "./store/actions";
import {getArticleSelector} from "./store/selectors";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: 40
  },
  pageTitle: {
    paddingTop: 50,
    paddingBottom: 30,
    '& h1': {
      fontWeight: 'bold',
      marginBottom: 5
    },
    '& .MuiIconButton-root': {
      padding: 11
    },
    [theme.breakpoints.down('sm')]: {
      //paddingTop: 0,
    },
  },
  content: {
    minHeight: '60vh',
    marginTop: 30,
    //fontSize: 16,
    '& img': {
      maxWidth: '100%'
    },
    '& table': {
      float: 'none',
      [theme.breakpoints.down('sm')]: {
        width: '100% !important',
        '& th': {},
        '& td': {
          '& p': {
            maxWidth: '100% !important',
            wordBreak: 'break-word'
          }
        }
      },
    },
    '& div': {
      [theme.breakpoints.down('xs')]: {
        padding: '0 !important',
        display: 'block !important',
        maxWidth: '100% !important',
        width: '100% !important',
      },
    }
  },
  errorMessage: {
    marginTop: 40
  },
  socialNetworksButtons: {},
  contactButton: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',
      '& p': {
        display: 'none'
      }
    }
  }
}));
const Article = (props) => {

  const classes = useStyles();
  const {i18n} = useTranslation();
  const dispatch = useDispatch();
  const session = useSelector(sessionSelector);
  const article = useSelector(getArticleSelector);

  useEffect(() => {

    if (!article.url || props.match.params.url !== article.url || i18n.language !== article.lang) {
      dispatch(magActions.getArticleRequest(props.match.params.url, i18n.language));
    }
  }, [i18n.language, props.match.params.lang, session]);

  if (!article.url) {
    return null;
  }

  return (
    <Box>
      <Helmet>
        <title>{article.title}</title>
        <meta property="og:title" content={article.title}/>
        <meta property="og:type" content="article"/>
        <meta property="og:url"
          content={`${process.env.REACT_APP_WEB_URL}${getLangPath()}/mag/${article.url}`}/>
        <meta property="og:image" content={article.cover[1000]}/>
        <meta property="og:description" content={article.metaDescription}/>
        <meta name="description" content={article.metaDescription}/>
      </Helmet>
      <Box sx={{
        background: `url(${article.cover[1000]})`,
        backgroundSize: 'cover !important',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        height: 500,
      }}>
        <Container maxWidth="xxl" sx={{
          height: {xs: 'calc(100vh - 110px)', sm: 'calc(100vh - 52px)', md: '100%'},
          pt: '5%',
        }}>
          <Stack gap={2} sx={{pt: '6vw'}} alignItems={'flex-start'}>
            <Typography
              sx={{
                fontFamily: 'Raleway',
                maxWidth: '515px',
                backgroundColor: '#fff',
                padding: '8px 8px',
                fontSize: '3rem',
                fontWeight: '300',
                overflow: 'hidden',
                textAlign: {xs: 'center', sm: 'left'},
                color: 'primary.main',
                zIndex: 1,
                '& p': {
                  margin: 0,
                }
              }}>
              {article.title}
            </Typography>
          </Stack>
        </Container>
      </Box>

      <Container maxWidth="xxl">
        <Grid container spacing={2} className={classes.content}>
          <Grid item xs={12} sm={8}>
            <Typography component={"div"} dangerouslySetInnerHTML={{__html: article.content || ''}}
              sx={{
                fontFamily: 'Open sans',
                color: 'primary.light',
                '& .ql-size-large': {
                  fontSize: '1.5rem',
                },
                '& .ql-size-small': {
                  fontSize: '0.8rem',
                }
              }}/>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Stack gap={1} justifyContent={"center"} alignItems={"center"} alignContent={"center"} sx={{
              flexWrap: 'wrap'
            }}>
              <Avatar alt="Emilie" src={`${process.env.REACT_APP_API_RESOURCES_URL}/images/19Avatar_Emilie.png`}
                sx={{
                  width: {xs: 80, sm: 100, md: 120, lg: 140, xl: 160, xxl: 180},
                  height: {xs: 80, sm: 100, md: 120, lg: 140, xl: 160, xxl: 180}
                }}/>
              <Typography sx={{
                fontSize: '1.5rem',
                fontWeight: 'bold',
                color: 'primary.main'
              }}>
                Emilie Dal Mas
              </Typography>
              <Typography sx={{color: 'primary.light'}}>
                {moment(article.createdAt).format('DD/MM/YYYY')}
              </Typography>
            </Stack>
          </Grid>
        </Grid>

      </Container>
    </Box>
  );
};
export default Article;
