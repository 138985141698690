import { createSelector } from 'reselect';

import { initialState, networkReducerKey } from "./reducer";

const networkSelector = (state) => state[networkReducerKey] || initialState;

export const profilingTestsSelector = () => createSelector(
  [networkSelector],
  (network) => {
    if(!network.profilingTests) {
      return [];
    }
    return network.profilingTests.map(request => ({
      id: request.id,
      userId: request.userId,
      approved: request.approved,
      test: request.test,
      name: `${request.user.firstName} ${request.user.lastName}`,
      phone: request.user.phone,
      email: request.user.email,
      zip: request.user.zip,
      city: request.user.city,
      parent: `${request.parent.firstName} ${request.parent.lastName}`,
      createdAt: request.createdAt,
      removedAt: request.removedAt,
    }));
  });

export const profilingTestsCountSelector = () => createSelector(
  [networkSelector],
  (network) => {
    if(!Array.isArray(network.profilingTests)) {
      return null;
    }
    return network.profilingTests.length;
  });

export const profilingTestSelector = () => createSelector(
  [networkSelector],
  (network) => network.profilingTest);

export const evolutionsSelector = (role) => createSelector(
  [networkSelector],
  (network) => network.evolutions[role]);

export const consultantsSelector = () => createSelector(
  [networkSelector],
  (network) => {
    if(!Array.isArray(network.consultants)) {
      return null;
    }
    return network.consultants;
  });

export const consultantSelector = () => createSelector(
  [networkSelector],
  (network) => {
    if(!network.consultant) {
      return 0;
    }
    return network.consultant;
  });

export const partnersSelector = () => createSelector(
  [networkSelector],
  (network) => {
    if(!network.partners) {
      return [];
    }
    return network.partners;
  });
