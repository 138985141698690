import { useEffect } from 'react';
import { useDispatch } from "react-redux";
import { useLoadScript } from '@react-google-maps/api';

import * as googleMapActions from "./store/action";

const librariesProps = ["places"];
const useLoadGoogleMapApi = () => {

  const dispatch = useDispatch();
  const {isLoaded, loadError} = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries: librariesProps
    // ...otherOptions
  });

  useEffect(() => {
    if(isLoaded) {
      dispatch(googleMapActions.loadGoogleMapAPISuccess());
    }
    if(loadError) {
      dispatch(googleMapActions.loadGoogleMapAPIError(loadError));
    }
  }, [isLoaded, loadError]);
};

export default useLoadGoogleMapApi;
