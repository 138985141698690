import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Trans, useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {makeStyles} from '@mui/styles';

import AutocompletePlaces from "../../../components/GoogleAutocomplete/AutocompletePlaces";
import Breadcrumb from "../../../components/Layout/Pro/Breadcrumb";
import PageLoader from "../../../components/PageLoader";
import FormValidator from "../../../components/Validators/FormValidator";
import TextFieldValidator from "../../../components/Validators/TextFieldValidator";
import { getLangPath } from "../../../helpers/multilingual";
import { SnackbarFormattedError, SnackbarSuccess } from "../../../helpers/snackbar";
import { history } from "../../../helpers/store";

import ConsultantReturnActionsCard from "./components/ConsultantReturnActionsCard";
import { networkService } from "./store/services";

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(2)
  },
}));

const PartnerOfficeUpdate = (props) => {

  const classes = useStyles();
  const {t} = useTranslation();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [countServices, setCountServices] = useState(4);
  const [formValues, setFormValues] = useState(null);
  const [user, setUser] = useState(null);

  useEffect(() => {
    networkService.getConsultant(props.match.params.consultantId).then(user => setUser(user));
  }, [props.match.params.consultantId]);

  useEffect(() => {
    if(user) {
      networkService.getOffice(user.id)
        .then(office => {
          setFormValues({
            ...office,
            address: {
              address:  office.address,
              coordinates: {
                lat: office.latitude,
                lng: office.longitude,
              }
            },
            servicesName: office.services ? office.services.map(service => service.name) : [],
            servicesPrice: office.services ? office.services.map(service => service.price) : [],
          });
        })
        .catch(e => {
          SnackbarFormattedError(e.error);
        });
    }
  }, [user]);

  const handleAddressChanged = (place) => {

    setFormValues({
      ...formValues,
      address: place
    });
  };

  const handleChangeFormValue = (e) => {

    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value
    });
  };

  const handleAddNewService = () => {

    setCountServices(countServices+1);
  };

  const handleAddServiceName = (e, serviceId) => {

    setFormValues({
      ...formValues,
      servicesName: {
        ...formValues.servicesName,
        [serviceId]: e.target.value
      }
    });
  };

  const handleAddServicePrice = (e, serviceId) => {

    setFormValues({
      ...formValues,
      servicesPrice: {
        ...formValues.servicesPrice,
        [serviceId]: e.target.value
      }
    });
  };

  const getServicesFromValues = () => {

    if(!formValues.servicesName || Object.keys(formValues.servicesName).length === 0) {
      return [];
    }
    return Object.keys(formValues.servicesName).map(key => ({
      name: formValues.servicesName[key],
      price: formValues.servicesPrice[key],
    }));
  };

  const handleSubmit = () => {

    setFormSubmitted(true);
    networkService.saveOffice(user.id, {...formValues, services: getServicesFromValues()}).then(() => {
      SnackbarSuccess(t("saved"));
      history.push(`${getLangPath()}/pro/network/${user.id}/office`);
    })
      .catch(e => {
        setFormSubmitted(false);
        SnackbarFormattedError(e.error);
      });
  };

  const services = () => {
    let rows = [];
    for (let i = 0; i < countServices; i++) {
      rows.push(
        <Grid key={i} item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={8}>
              <TextFieldValidator
                fullWidth
                label={t("serviceName")}
                value={(!!formValues.servicesName && !!formValues.servicesName[i]) ? formValues.servicesName[i] : ''}
                onChange={(e) => handleAddServiceName(e, i)}
              />
            </Grid>
            <Grid item xs={4}>
              <TextFieldValidator
                fullWidth
                label={t("priceByCurrency", {currency: formValues.currency})}
                value={!!formValues.servicesPrice && !!formValues.servicesPrice[i] ? formValues.servicesPrice[i] : ''}
                onChange={(e) => handleAddServicePrice(e, i)}
              />
            </Grid>
          </Grid>
        </Grid>
      );
    }
    return rows;
  };

  if(!formValues || !user) {
    return (
      <PageLoader/>
    );
  }
  return (
    <div>
      <Helmet>
        <title>{user.firstName} {user.lastName}</title>
      </Helmet>
      <Container fixed maxWidth="xl">
        <div className={classes.title}>
          <Breadcrumb parents={[
            {name: t("consultants"), uri: '/network/tree'},
            {name: `${user.firstName} ${user.lastName}`, uri: `/network/${user.id}`},
            {name: t("statistics")}
          ]}/>
          <Typography component="h1" variant="h4">
            {user.firstName} {user.lastName}
          </Typography>
        </div>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
            <ConsultantReturnActionsCard consultant={user}/>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
            <Card>
              <CardHeader title={t('officeUpdateText')}/>
              <CardContent>
                <FormValidator
                  noValidate
                  onSubmit={handleSubmit}
                  onError={console.log}
                  action=""
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <TextFieldValidator
                            fullWidth
                            name="name"
                            label={t("name")}
                            value={formValues.name || ''}
                            onChange={handleChangeFormValue}
                            required
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextFieldValidator
                            fullWidth
                            multiline
                            rows={2}
                            name="specialities"
                            label={t("specialities")}
                            value={formValues.specialities || ''}
                            onChange={handleChangeFormValue}
                            required
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextFieldValidator
                            fullWidth
                            name="phone"
                            label={t("phone")}
                            value={formValues.phone || ''}
                            onChange={handleChangeFormValue}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextFieldValidator
                            fullWidth
                            name="email"
                            label={t("email")}
                            value={formValues.email || ''}
                            onChange={handleChangeFormValue}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <AutocompletePlaces
                            fullWidth
                            variant="outlined"
                            name="address"
                            label={t("address")}
                            initialValue={!!formValues.address && formValues.address.address}
                            onPlaceChanged={handleAddressChanged}
                            error={formSubmitted && !formValues.address}
                            helperText={formSubmitted && !formValues.address && (t("validatorFieldRequired"))}
                            placeholder=""
                            preview
                            required={!formValues.latitude || !formValues.longitude}
                            customFieldsEnabled={false}
                            requiredFields={['city', 'country']}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextFieldValidator
                            fullWidth
                            multiline
                            rows={6}
                            name="description"
                            label={t("description")}
                            value={formValues.description || ''}
                            onChange={handleChangeFormValue}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <TextFieldValidator
                            fullWidth
                            name="website"
                            label={t("website")}
                            value={formValues.website || ''}
                            onChange={handleChangeFormValue}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextFieldValidator
                            fullWidth
                            multiline
                            rows={6}
                            name="openingTime"
                            label={t("openingTime")}
                            value={formValues.openingTime || ''}
                            onChange={handleChangeFormValue}
                            required
                          />
                        </Grid>
                        {services()}
                        <Grid item xs={12}>
                          <Button fullWidth color={"primary"} variant={"contained"} size={"small"} onClick={handleAddNewService}>
                            <Trans i18nKey={"addMoreService"}>Add more service</Trans>
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                      >
                        <Trans i18nKey={"saveOfficeInfo"}>
                                                    Save office information
                        </Trans>
                      </Button>
                    </Grid>
                  </Grid>
                </FormValidator>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};
export default PartnerOfficeUpdate;
