import React, { useEffect, useRef, useState } from 'react';
import {Box} from "@mui/material";

import useIntersectionObserver from "./useIntersectionObserver";

const _loaded = {};

const LazyLoadImage = ({ className, width, height, src, alt, skeleton = null, sx, ...rest}) => {
  const elementRef = useRef(null);
  const [inView] = useIntersectionObserver(elementRef);
  const [loaded, setLoaded] = useState(_loaded[src]);

  useEffect(() => {
    if (inView) {
      if (_loaded[src]) {
        setLoaded(true);

        return;
      }

      const img = new Image();
      img.src = src;
      img.onload = () => {
        _loaded[src] = true;
        setLoaded(true);
      };
    }
  }, [inView]);

  return (
    <Box
      ref={elementRef}
      component={"div"}
      className={className ? `${className}-wrapper` : null}
      sx={{...sx}}
    >
      {!loaded ? (
        skeleton
      ) : (
        <img
          {...rest}
          width={width}
          height={height}
          src={src}
          className={className}
          alt={alt}
        />
      )}
    </Box>
  );
};

export default LazyLoadImage;