export const GET_DIRECT_SALES_REQUEST           = 'PRO_STATS/GET_DIRECT_SALES_REQUEST';
export const GET_DIRECT_SALES_ERROR             = 'PRO_STATS/GET_DIRECT_SALES_ERROR';
export const GET_DIRECT_SALES_SUCCESS           = 'PRO_STATS/GET_DIRECT_SALES_SUCCESS';
export const GET_NETWORK_SALES_REQUEST          = 'PRO_STATS/GET_NETWORK_SALES_REQUEST';
export const GET_NETWORK_SALES_ERROR            = 'PRO_STATS/GET_NETWORK_SALES_ERROR';
export const GET_NETWORK_SALES_SUCCESS          = 'PRO_STATS/GET_NETWORK_SALES_SUCCESS';

export const GET_DIRECT_RECURRING_SALES_REQUEST     = 'PRO_STATS/GET_DIRECT_RECURRING_SALES_REQUEST';
export const GET_DIRECT_RECURRING_SALES_ERROR       = 'PRO_STATS/GET_DIRECT_RECURRING_SALES_ERROR';
export const GET_DIRECT_RECURRING_SALES_SUCCESS     = 'PRO_STATS/GET_DIRECT_RECURRING_SALES_SUCCESS';
export const GET_NETWORK_RECURRING_SALES_REQUEST    = 'PRO_STATS/GET_NETWORK_RECURRING_SALES_REQUEST';
export const GET_NETWORK_RECURRING_SALES_ERROR      = 'PRO_STATS/GET_NETWORK_RECURRING_SALES_ERROR';
export const GET_NETWORK_RECURRING_SALES_SUCCESS    = 'PRO_STATS/GET_NETWORK_RECURRING_SALES_SUCCESS';

export const GET_DIRECT_RECRUITMENT_REQUEST     = 'PRO_STATS/GET_DIRECT_RECRUITMENT_REQUEST';
export const GET_DIRECT_RECRUITMENT_SUCCESS     = 'PRO_STATS/GET_DIRECT_RECRUITMENT_SUCCESS';
export const GET_DIRECT_RECRUITMENT_ERROR       = 'PRO_STATS/GET_DIRECT_RECRUITMENT_ERROR';
export const GET_NETWORK_RECRUITMENT_REQUEST    = 'PRO_STATS/GET_NETWORK_RECRUITMENT_REQUEST';
export const GET_NETWORK_RECRUITMENT_SUCCESS    = 'PRO_STATS/GET_NETWORK_RECRUITMENT_SUCCESS';
export const GET_NETWORK_RECRUITMENT_ERROR      = 'PRO_STATS/GET_NETWORK_RECRUITMENT_ERROR';

export const GET_DIRECT_BESTSELLERS_AMOUNT_REQUEST   = 'PRO_STATS/GET_DIRECT_BESTSELLERS_AMOUNT_REQUEST';
export const GET_DIRECT_BESTSELLERS_AMOUNT_SUCCESS   = 'PRO_STATS/GET_DIRECT_BESTSELLERS_AMOUNT_SUCCESS';
export const GET_DIRECT_BESTSELLERS_AMOUNT_ERROR     = 'PRO_STATS/GET_DIRECT_BESTSELLERS_AMOUNT_ERROR';

export const GET_DIRECT_BEST_RECRUITERS_AMOUNT_REQUEST   = 'PRO_STATS/GET_DIRECT_BEST_RECRUITERS_AMOUNT_REQUEST';
export const GET_DIRECT_BEST_RECRUITERS_AMOUNT_SUCCESS   = 'PRO_STATS/GET_DIRECT_BEST_RECRUITERS_AMOUNT_SUCCESS';
export const GET_DIRECT_BEST_RECRUITERS_AMOUNT_ERROR     = 'PRO_STATS/GET_DIRECT_BEST_RECRUITERS_AMOUNT_ERROR';

export const GET_NETWORK_BESTSELLERS_AMOUNT_REQUEST   = 'PRO_STATS/GET_NETWORK_BESTSELLERS_AMOUNT_REQUEST';
export const GET_NETWORK_BESTSELLERS_AMOUNT_SUCCESS   = 'PRO_STATS/GET_NETWORK_BESTSELLERS_AMOUNT_SUCCESS';
export const GET_NETWORK_BESTSELLERS_AMOUNT_ERROR     = 'PRO_STATS/GET_NETWORK_BESTSELLERS_AMOUNT_ERROR';

export const GET_NETWORK_BEST_RECRUITERS_AMOUNT_REQUEST   = 'PRO_STATS/GET_NETWORK_BEST_RECRUITERS_AMOUNT_REQUEST';
export const GET_NETWORK_BEST_RECRUITERS_AMOUNT_SUCCESS   = 'PRO_STATS/GET_NETWORK_BEST_RECRUITERS_AMOUNT_SUCCESS';
export const GET_NETWORK_BEST_RECRUITERS_AMOUNT_ERROR     = 'PRO_STATS/GET_NETWORK_BEST_RECRUITERS_AMOUNT_ERROR';

export const GET_DIRECT_PACKS_AMOUNT_REQUEST        = 'PRO_STATS/GET_DIRECT_PACKS_AMOUNT_REQUEST';
export const GET_DIRECT_PACKS_AMOUNT_SUCCESS        = 'PRO_STATS/GET_DIRECT_PACKS_AMOUNT_SUCCESS';
export const GET_DIRECT_PACKS_AMOUNT_ERROR          = 'PRO_STATS/GET_DIRECT_PACKS_AMOUNT_ERROR';

export const GET_NETWORK_PACKS_AMOUNT_REQUEST       = 'PRO_STATS/GET_NETWORK_PACKS_AMOUNT_REQUEST';
export const GET_NETWORK_PACKS_AMOUNT_SUCCESS       = 'PRO_STATS/GET_NETWORK_PACKS_AMOUNT_SUCCESS';
export const GET_NETWORK_PACKS_AMOUNT_ERROR         = 'PRO_STATS/GET_NETWORK_PACKS_AMOUNT_ERROR';

export const GET_DIRECT_PRODUCTS_AMOUNT_REQUEST     = 'PRO_STATS/GET_DIRECT_PRODUCTS_AMOUNT_REQUEST';
export const GET_DIRECT_PRODUCTS_AMOUNT_SUCCESS     = 'PRO_STATS/GET_DIRECT_PRODUCTS_AMOUNT_SUCCESS';
export const GET_DIRECT_PRODUCTS_AMOUNT_ERROR       = 'PRO_STATS/GET_DIRECT_PRODUCTS_AMOUNT_ERROR';

export const GET_NETWORK_PRODUCTS_AMOUNT_REQUEST    = 'PRO_STATS/GET_NETWORK_PRODUCTS_AMOUNT_REQUEST';
export const GET_NETWORK_PRODUCTS_AMOUNT_SUCCESS    = 'PRO_STATS/GET_NETWORK_PRODUCTS_AMOUNT_SUCCESS';
export const GET_NETWORK_PRODUCTS_AMOUNT_ERROR      = 'PRO_STATS/GET_NETWORK_PRODUCTS_AMOUNT_ERROR';

export const GET_NETWORK_TURNOVER_BY_CAREER_REQUEST = 'PRO_STATS/GET_NETWORK_TURNOVER_BY_CAREER_REQUEST';
export const GET_NETWORK_TURNOVER_BY_CAREER_SUCCESS = 'PRO_STATS/GET_NETWORK_TURNOVER_BY_CAREER_SUCCESS';
export const GET_NETWORK_TURNOVER_BY_CAREER_ERROR   = 'PRO_STATS/GET_NETWORK_TURNOVER_BY_CAREER_ERROR';

export const GET_NETWORK_ACTIVE_PROS_COUNT_BY_MONTH_REQUEST  = 'PRO_STATS/GET_NETWORK_ACTIVE_PROS_COUNT_BY_MONTH_REQUEST';
export const GET_NETWORK_ACTIVE_PROS_COUNT_BY_MONTH_SUCCESS  = 'PRO_STATS/GET_NETWORK_ACTIVE_PROS_COUNT_BY_MONTH_SUCCESS';
export const GET_NETWORK_ACTIVE_PROS_COUNT_BY_MONTH_ERROR    = 'PRO_STATS/GET_NETWORK_ACTIVE_PROS_COUNT_BY_MONTH_ERROR';
