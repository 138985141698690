import React from "react";
import {Trans} from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { grey } from "@mui/material/colors";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { useTheme } from '@mui/material/styles';
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import {makeStyles} from '@mui/styles';

import DotsLoader from "../../../../../components/DotsLoader";
import useMoment from "../../../../../helpers/useMoment";

import SaleDetailsCartItem from "./SaleDetailsCartItem";

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiDialog-paper': {
      [theme.breakpoints.up('sm')]: {
        width: '80vw',
        minWidth: 760,
        '& .MuiDivider-root': {
          margin: -1
        }
      },
      [theme.breakpoints.down('xs')]: {
        '& .MuiCard-root': {
          overflow: 'auto'
        }
      },
      '& .MuiTypography-subtitle1': {
        maxHeight: 42,
        lineHeight: '20px',
        overflow: 'hidden'
      },
      '& h4': {
        marginBottom: 20
      }
    },
    '& i': {
      fontSize: 14
    }
  },
  closeButton: {
    top: 10,
    right: 15,
    cursor: 'pointer',
    position: 'absolute',
    fontSize: 36,
    color: grey[500],
    [theme.breakpoints.down('xs')]: {
      top: 10,
      right: 20,
      fontSize: 40,
    },
  },
}));

const SaleDetailsModal = ({order, isModalOpen, setIsModalOpen}) => {
  const theme = useTheme();
  const classes = useStyles();
  const moment = useMoment();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const handleClose = () => {
    setIsModalOpen(false);
  };

  return (
    <Dialog onClose={handleClose} open={isModalOpen} fullScreen={fullScreen} className={classes.root}>
      <CloseIcon className={classes.closeButton} onClick={handleClose}/>
      <DialogTitle id="responsive-dialog-title"><Trans i18nKey="saleDetails">Sale details</Trans></DialogTitle>
      <DialogContent>
        <Card elevation={0}>
          <CardContent>
            {!order && (
              <DotsLoader />
            )}
            {!!order && (
              <Grid container spacing={4}>
                <Grid item xs={12} sm={12}>
                  <List>
                    <ListItem>
                      <Typography>{moment(order.createdAt).format('LLL')}</Typography>
                    </ListItem>
                    {order.directSale && (
                      <ListItem>
                        <Typography>Client : {order.client.firstName} {order.client.lastName}</Typography>
                      </ListItem>
                    )}
                  </List>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Divider/>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <List>
                    {order.products.map(product => (
                      <SaleDetailsCartItem item={product} key={product?.id}/>
                    ))}
                    <ListItem
                      sx={{
                        borderRadius: '12px',
                        '&:hover': {
                          backgroundColor: '#F5F5F5',
                        }
                      }}
                    >
                      <Grid container justify={"center"} alignItems={"center"}>
                        <Grid item xs={2} sm={2}>
                          <Grid container justify={"center"} alignItems={"center"}>

                          </Grid>
                        </Grid>
                        <Grid item xs={10} sm={10}>
                          <Grid container justify={"center"} alignItems={"center"}>
                            <Grid item xs={10} sx={{pl: '0px !important'}}>
                              <Typography
                                sx={{
                                  fontSize: '0.875rem',
                                  fontWeight: 'bold',
                                  fontFamily: 'Montserrat',
                                  color: '#7C909B',
                                }}>
                                Frais de port
                              </Typography>
                            </Grid>
                            <Grid item xs={2}>
                            </Grid>
                          </Grid>
                          <Grid container justifyContent={"space-between"} alignItems={"center"} spacing={2}>
                            <Grid item sx={{pl: '12px !important'}}>
                            </Grid>
                            <Grid item>
                              <Typography sx={{
                                textAlign: 'right',
                                '& > span': {
                                  fontSize: '16px',
                                }
                              }}>
                                <>
                                  <Typography
                                    component={"span"}
                                    sx={{
                                      color: '#7C909B',
                                      fontFamily: 'Open sans',
                                      fontWeight: 'bold',
                                      fontSize: '19px',
                                      textAlign: 'right',
                                    }}
                                  >
                                    {(order.shippingPrice - order.shippingTaxes).toFixed(2) + ' €'}
                                  </Typography>
                                </>
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
            )}
          </CardContent>
        </Card>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          <Typography>
            <Trans i18nKey="back">Back</Trans>
          </Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SaleDetailsModal;

