import React from "react";
import {Helmet} from "react-helmet-async";
import { useLocation } from 'react-router-dom';
import {Box} from "@mui/material";

import ContactModal from "./components/ContactModal";
import ProsSection1 from "./components/ProsSection1";
import ProsSection2 from "./components/ProsSection2";
import ProsSection3 from "./components/ProsSection3";
import ProsSection4 from "./components/ProsSection4";
import ProsSection5 from "./components/ProsSection5";

const Pros = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const formOpen = searchParams.get('form');
  const [contactModalOpen, setContactModalOpen] = React.useState(formOpen);

  return (
    <Box sx={{overflow: 'hidden'}}>
      <Helmet>
        <title itemProp={"name"}>PROFESSIONNEL DE LA SANTÉ | Nutri-logics</title>
        <meta property="og:title" content={"PROFESSIONNEL DE LA SANTÉ | Nutri-logics"}/>
        <meta name="description" content={"PROFESSIONNEL DE LA SANTÉ | Nutri-logics"}/>
        <link rel="alternate" href={`${process.env.REACT_APP_WEB_URL}`} hrefLang="fr" key="fr"/>
        <link rel="alternate" href={`${process.env.REACT_APP_WEB_URL}/en`} hrefLang="en" key="en"/>
        <link rel="alternate" href={`${process.env.REACT_APP_WEB_URL}`} hrefLang="x-default"/>
      </Helmet>

      <ProsSection1 setContactModalOpen={setContactModalOpen}/>
      <ProsSection2/>
      <ProsSection3/>
      <ProsSection4/>
      <ProsSection5/>
      <ContactModal open={contactModalOpen} setOpen={() => setContactModalOpen()} />
    </Box>
  );
};

export default Pros;