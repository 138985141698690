import {createSelector} from "reselect";

import { initialState } from "./initialState";
import {magReducerKey} from "./reducer";

const magSelector = globalState => globalState[magReducerKey] || initialState.mag;

export const getArticleSelector = createSelector(
  [magSelector],
  (mag) => mag && mag.article ? mag.article : {});

export const getArticlesSelector = createSelector(
  [magSelector],
  (mag) => mag && mag.articles ? mag.articles : []);
