import React from "react";
import { Trans } from "react-i18next";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {makeStyles} from '@mui/styles';

import { Link } from "../../../../helpers/multilingual";
import {PARTNER_CAREER} from "../../../Authentication/store/constants";

const useStyles = makeStyles(() => ({
  root: {
    minHeight: 300,
    '& .MuiDivider-root': {
      marginTop: 15,
      marginBottom: 15,
    }
  }
}));

const ConsultantCard = ({consultant}) => {

  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardContent>
        <Grid container justify="center" spacing={2}>
          <Grid item xs={12}>
            <Grid container justify={"space-between"} alignItems={"center"}>
              <Grid item xs={12}>
                <Typography component="h3" variant={"h6"} gutterBottom>
                  <Trans i18nKey={"generalInformation"}>General information</Trans> :
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography component={"p"} variant="body1">
                  <Typography component={"a"} color={"inherit"} href={`tel:${consultant.phone}`}>{consultant.phone}</Typography>
                </Typography>
                <Typography component={"p"} variant="body1">
                  <Typography component={"a"} color={"inherit"} href={`mailto:${consultant.email}`}>{consultant.email}</Typography>
                </Typography>
                <Typography component={"p"} variant="body1">
                  <Typography component={"a"} color={"inherit"} target={"_blank"} href={`https://www.google.fr/maps/place/${consultant.address}`}>{consultant.address} {consultant.additionalInfo && (`(${consultant.additionalInfo})`)}</Typography>
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          {consultant.career === PARTNER_CAREER && consultant.shippingAddress && (
            <Grid item xs={12}>
              <Grid container justify={"space-between"} alignItems={"center"}>
                <Grid item xs={12}>
                  <Typography component="h4" variant={"h6"} gutterBottom>
                    <Trans i18nKey="shippingAddress">Shipping address</Trans> :
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography component={"p"}>
                    {consultant.shippingAddress.firstName} {consultant.shippingAddress.lastName}
                  </Typography>
                  <Typography component={"p"}>
                    <Typography component={"a"} color={"inherit"} target={"_blank"} href={`https://www.google.fr/maps/place/${consultant.shippingAddress.address}`}>{consultant.shippingAddress.address} {consultant.shippingAddress.additionalInfo && (`(${consultant.shippingAddress.additionalInfo})`)}</Typography>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          )}
          {consultant.parent && (
            <Grid item xs={12}>
              <Grid container justify={"space-between"} alignItems={"center"}>
                <Grid item xs={12}>
                  <Typography component="h4" variant={"h6"} gutterBottom>
                    <Trans i18nKey={"belongsToNetwork"}>Belongs to the network</Trans> :
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography component={"p"} variant="body1">
                    <Typography component={Link} to={`/pro/network/${consultant.parent.id}`} color={"inherit"}>{consultant.parent.firstName} {consultant.parent.lastName}</Typography>
                  </Typography>
                  <Typography component={"p"} variant="body1">
                    <Typography component={"a"} color={"inherit"} href={`tel:${consultant.parent.phone}`}>{consultant.parent.phone}</Typography>
                  </Typography>
                  <Typography component={"p"} variant="body1">
                    <Typography component={"a"} color={"inherit"} href={`mailto:${consultant.parent.email}`}>{consultant.parent.email}</Typography>
                  </Typography>
                  <Typography component={"p"} variant="body1" gutterBottom>
                    <Typography component={"a"} color={"inherit"} target={"_blank"} href={`https://www.google.fr/maps/place/${consultant.parent.address}`}>{consultant.parent.address} {consultant.parent.additionalInfo && (`(${consultant.parent.additionalInfo})`)}</Typography>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};
export default ConsultantCard;
