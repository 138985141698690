import React, {useEffect, useState} from "react";
import Grid from "@mui/material/Grid";

import TextFieldValidator from "../../../../components/Validators/TextFieldValidator";
import {shopService} from "../../Shop/store/services";

const DelegateCommissionsBrandsFrom = ({brandDetails, setBrandDetails}) => {

  const [brands, setBrands] = useState([]);

  useEffect(() => {
    shopService.getBrands()
      .then(brands => {
        setBrands(brands);
      });
  }, []);

  const handleChangeBrandPercentage = (brandId, e) => {
    setBrandDetails({
      ...brandDetails,
      [brandId]: parseInt(e.target.value)
    });
  };

  if(!brands.length) {
    return false;
  }

  return (
    <Grid container spacing={2}>
      {brands.map(brand => (
        <Grid key={brand.id} item xs={12}>
          <TextFieldValidator
            fullWidth
            name={brand.name}
            label={`${brand.name} pourcentage`}
            type={'number'}
            defaultValue={0}
            value={brandDetails?.[brand.id]}
            onChange={(e) => handleChangeBrandPercentage(brand.id, e)}
            required
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default DelegateCommissionsBrandsFrom;