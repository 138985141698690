import React from 'react';
import {Grid, Stack, Typography} from "@mui/material";
import Avatar from "@mui/material/Avatar";
//import Button from "@mui/material/Button";
//import Card from "@mui/material/Card";
//import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import useMediaQuery from "@mui/material/useMediaQuery";

const section = {
  "name": "Professionnels de la santé",
  "type": "banner",
  "bannerTitle1": "Professionnels",
  "bannerTitle2": "de la santé",
  "bannerText": "Vous souhaitez en savoir plus sur Nutri-Logics ?",
  "bannerText2": "Zakaria et Cédric seront ravis de vous faire découvrir notre entreprise, nos projets et nos solutions santé.",
  "bannerLink": "/",
  "bannerBackgroundImage": `${process.env.REACT_APP_API_RESOURCES_URL}/sections/backgrounds/Background_Pro.png`
};
export default function CustomSectionProBanner() {

  const isUpSm = useMediaQuery(theme => theme.breakpoints.up('sm'));
  //const [anchorEl, setAnchorEl] = React.useState({
  //  phone1: null,
  //  phone2: null,
  //  email1: null,
  //  email2: null,
  //});

  //const handleClick = (event, key) => {
  //  if(event.currentTarget === anchorEl[key]) {
  //    setAnchorEl({
  //      ...anchorEl,
  //      [key]: null
  //    });
  //  } else {
  //    setAnchorEl({
  //      ...anchorEl,
  //      [key]: event.currentTarget
  //    });
  //  }
  //};

  return (
    <Container maxWidth={'xxl'}
      sx={{
        pl: '0 !important',
        pr: '0 !important',
      }}
    >
      <Grid
        container
        spacing={2}
        justifyContent={"space-between"}
        flexDirection={"row"}
        alignItems={"center"}
        alignContent={"center"}
        sx={{
          width: '100%',
          background: `url(${section.bannerBackgroundImage})`,
          backgroundPosition: 'left bottom',
          backgroundSize: {xs: '500px', sm: '50%'},
          backgroundRepeat: 'no-repeat',
          margin: '40px 0 60px',
          padding: '1px 0',
          height: {xs: 'auto', sm: '32vw', md: '32vw', lg: '32vw', xl: '32vw', xxl: '610px'},
          overflowX: 'clip',

        }}>
        <Grid item xs={12} sm={6} sx={{
          order: {xs: 2, sm: 1, md: 1, lg: 1, xl: 1, xxl: 1},
        }}>
          <Stack gap={isUpSm ? 2 : 0} sx={{
            margin: 'auto',
            padding: {sm: '66px 90px 0 8px', md: '66px 90px 0 8px', lg: '150px 140px 0 68px',xl: '150px 140px 0 68px',  xxl: '150px 140px 0 68px'},
          }}>
            <Typography
              sx={{
                fontFamily: 'Raleway',
                fontWeight: '600',
                padding: '8px 8px',
                fontSize: {xs: '2.5rem', sm: '2rem', md: '2.5rem', lg: '3.5rem', xl: '3.5rem', xxl: '4.5rem'},
                lineHeight: '0.7',
                overflow: 'hidden',
                color: '#ffffff',
                textAlign: {xs: 'left', sm: 'left'},
                zIndex: 1,
                '& p': {
                  margin: 0,
                },
                '& span': {
                  fontFamily: 'Raleway',
                  fontWeight: '600',
                  fontSize: {xs: '2.5rem', sm: '2rem', md: '2.5rem', lg: '3.5rem', xl: '3.5rem', xxl: '4.5rem'},
                }
              }}>
              {section.bannerTitle1} <br/> <Typography component={"span"} sx={{color: '#ffffff'}}>{section.bannerTitle2}</Typography>
            </Typography>
            <Typography
              sx={{
                fontSize: {xs: '1rem', sm: '0.9rem', md: '1rem', lg: '1.1rem', xl: '1.2rem', xxl: '1.3rem'},
                padding: '0 8px 8px 8px',
                fontFamily: 'Open Sans',
                maxWidth: {xs: '100%', sm: '100%'},
                lineHeight: '1.4',
                overflow: 'hidden',
                textAlign: 'left',
                color: '#ffffff',
                zIndex: 1,
                '& p': {
                  margin: 0,
                }
              }}>
              {section.bannerText} <br/> {section.bannerText2}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={6} sx={{
          pl: '24px', pr: '24px',
          order: {xs: 1, sm: 2, md: 2, lg: 2, xl: 2, xxl: 2},
          mb: {xs: '140px', sm: 0, md: 0, lg: 0, xl: 0, xxl: 0}
        }}>
          <Grid container spacing={2} alignItems={'center'} justifyContent={'center'}>
            <Grid item xs={12} sm={4}>
              <Stack gap={1} alignItems={"center"} alignContent={"center"} justifyContent={"center"}>
                <Avatar
                  src={`${process.env.REACT_APP_API_RESOURCES_URL}/sections/images/Zakaria_Hero.png`}
                  sx={{
                    width: {xs: '150px', sm: '150px', md: '170px', lg: '220px', xl: '220px', xxl: '240px'},
                    height: {xs: '160px', sm: '160px', md: '180px', lg: '240px', xl: '240px', xxl: '240px'},
                    order: {xs: 4, sm: 1, md: 1, lg: 1, xl: 1, xxl: 1},
                  }}/>
                <Typography sx={{
                  color: 'primary.light',
                  fontFamily: 'Montserrat',
                  fontWeight: 'bold',
                  fontSize: {xs: '0.8rem', sm: '0.8rem', md: '0.8rem', lg: '0.8rem', xl: '1rem', xxl: '1rem'},
                  order: {xs: 1, sm: 2, md: 2, lg: 2, xl: 2, xxl: 2},
                }}>
                  POUR LA FRANCE :
                </Typography>
                <Typography sx={{
                  color: 'primary.light',
                  fontFamily: 'Raleway',
                  fontWeight: '300',
                  fontSize: {xs: '1rem', sm: '1.2rem', md: '1.3rem', lg: '1.5rem', xl: '1.5rem', xxl: '1.5rem'},
                  order: {xs: 2, sm: 3, md: 3, lg: 3, xl: 3, xxl: 3},
                }}>
                  Zakaria ALAMI
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Stack gap={1} alignItems={"center"} alignContent={"center"} justifyContent={"center"}>
                <Avatar
                  src={`${process.env.REACT_APP_API_RESOURCES_URL}/sections/images/Perrine_Hero.png`}
                  sx={{
                    width: {xs: '150px', sm: '150px', md: '170px', lg: '220px', xl: '220px', xxl: '240px'},
                    height: {xs: '160px', sm: '160px', md: '180px', lg: '240px', xl: '240px', xxl: '240px'},
                    order: {xs: 4, sm: 1, md: 1, lg: 1, xl: 1, xxl: 1},
                  }}/>
                <Typography sx={{
                  color: 'primary.light',
                  fontFamily: 'Montserrat',
                  fontWeight: 'bold',
                  fontSize: {xs: '0.8rem', sm: '0.8rem', md: '0.8rem', lg: '0.8rem', xl: '1rem', xxl: '1rem'},
                  order: {xs: 1, sm: 2, md: 2, lg: 2, xl: 2, xxl: 2},
                }}>
                  POUR LA FRANCE (SUD) :
                </Typography>
                <Typography sx={{
                  color: 'primary.light',
                  fontFamily: 'Raleway',
                  fontWeight: '300',
                  fontSize: {xs: '1rem', sm: '1.2rem', md: '1.3rem', lg: '1.5rem', xl: '1.5rem', xxl: '1.5rem'},
                  order: {xs: 2, sm: 3, md: 3, lg: 3, xl: 3, xxl: 3},
                }}>
                  Perrine Anquier
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Stack gap={1} alignItems={"center"} alignContent={"center"} justifyContent={"center"}>
                <Avatar
                  src={`${process.env.REACT_APP_API_RESOURCES_URL}/sections/images/Cedric_Hero.png`}
                  sx={{
                    width: {xs: '150px', sm: '150px', md: '170px', lg: '220px', xl: '220px', xxl: '240px'},
                    height: {xs: '160px', sm: '160px', md: '180px', lg: '240px', xl: '240px', xxl: '240px'},
                    order: {xs: 4, sm: 1, md: 1, lg: 1, xl: 1, xxl: 1},
                  }}/>
                <Typography sx={{
                  color: 'primary.light',
                  fontFamily: 'Montserrat',
                  fontWeight: 'bold',
                  fontSize: {xs: '0.8rem', sm: '0.8rem', md: '0.8rem', lg: '0.8rem', xl: '1rem', xxl: '1rem'},
                  order: {xs: 1, sm: 2, md: 2, lg: 2, xl: 2, xxl: 2},
                }}>
                  POUR LA BELGIQUE :
                </Typography>
                <Typography sx={{
                  color: 'primary.light',
                  fontFamily: 'Raleway',
                  fontWeight: '300',
                  fontSize: {xs: '1rem', sm: '1.2rem', md: '1.3rem', lg: '1.5rem', xl: '1.5rem', xxl: '1.5rem'},
                  order: {xs: 2, sm: 3, md: 3, lg: 3, xl: 3, xxl: 3},
                }}>
                  Cédric LAPAUW
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
