import * as types from "./constants";

export const statsActions = {
  getStatisticsDataByMonthRequest,
  getStatisticsDataByMonthSuccess,
  getStatisticsDataByMonthError,
  getStatisticsDataByIntervalRequest,
  getStatisticsDataByIntervalSuccess,
  getStatisticsDataByIntervalError,
  setStatsDisplayed,
  setAddCardOpen,
  setStatsCompared,
  setProNumberDisplayed,
  deleteCard,
  setStatsSection
};

function getStatisticsDataByMonthRequest(year, month) {
  return {
    type: types.GET_STATISTICS_DATA_BY_MONTH_REQUEST,
    year,
    month
  };
}

function getStatisticsDataByMonthSuccess(data, key) {
  return {
    type: types.GET_STATISTICS_DATA_BY_MONTH_SUCCESS,
    data,
    key
  };
}

function getStatisticsDataByMonthError() {
  return {
    type: types.GET_STATISTICS_DATA_BY_MONTH_ERROR
  };
}

function getStatisticsDataByIntervalRequest(start, end) {
  return {
    type: types.GET_STATISTICS_DATA_BY_INTERVAL_REQUEST,
    start,
    end
  };
}

function getStatisticsDataByIntervalSuccess(data, key) {
  return {
    type: types.GET_STATISTICS_DATA_BY_INTERVAL_SUCCESS,
    data,
    key
  };
}

function getStatisticsDataByIntervalError() {
  return {
    type: types.GET_STATISTICS_DATA_BY_INTERVAL_ERROR
  };
}

function setStatsDisplayed(section, key, value) {
  return {
    type: types.SET_STATS_DISPLAYED,
    section, key, value
  };
}

function setStatsCompared(section, key) {
  return {
    type: types.SET_STATS_COMPARED,
    section, key
  };
}

function setStatsSection(section) {
  return {
    type: types.SET_STATS_SECTION,
    section
  };
}

function setAddCardOpen(value) {
  return {
    type: types.SET_ADD_CARD_OPEN,
    value
  };
}

function setProNumberDisplayed() {
  return {
    type: types.SET_PRO_NUMBER_DISPLAYED,
  };
}

function deleteCard(section,key) {
  return {
    type: types.DELETE_CARD,
    section,
    key
  };
}

/*
export const statsActions = {
  getStatisticsDataByMonth,

  getTurnoverByMonthRequest,
  getTurnoverByMonthSuccess,
  getTurnoverByMonthError,
  getRecurringTurnoverByMonthRequest,
  getRecurringTurnoverByMonthSuccess,
  getRecurringTurnoverByMonthError,
  getRecruitmentRequest,
  getRecruitmentSuccess,
  getRecruitmentError,
  getPacksAmountRequest,
  getPacksAmountSuccess,
  getPacksAmountError,
  getProductsAmountRequest,
  getProductsAmountSuccess,
  getProductsAmountError,
  getPacksAmountByMonthRequest,
  getPacksAmountByMonthSuccess,
  getPacksAmountByMonthError,
  getBestSellersAmountRequest,
  getBestSellersAmountSuccess,
  getBestSellersAmountError,
  getBestRecruitersAmountRequest,
  getBestRecruitersAmountSuccess,
  getBestRecruitersAmountError,
  getOrdersCountByMonthRequest,
  getOrdersCountByMonthSuccess,
  getOrdersCountByMonthError,
  getProsCountByMonthRequest,
  getProsCountByMonthSuccess,
  getProsCountByMonthError,
  getActiveProsCountByMonthRequest,
  getActiveProsCountByMonthSuccess,
  getActiveProsCountByMonthError,
  getTurnoverByCareerRequest,
  getTurnoverByCareerSuccess,
  getTurnoverByCareerError,

  getDirectSalesRequest,
  getDirectSalesSuccess,
  getDirectSalesError,
  getDirectRecurringSalesRequest,
  getDirectRecurringSalesSuccess,
  getDirectRecurringSalesError,
  getNetworkSalesRequest,
  getNetworkSalesSuccess,
  getNetworkSalesError,
  getNetworkRecurringSalesRequest,
  getNetworkRecurringSalesSuccess,
  getNetworkRecurringSalesError,
  getDirectRecruitmentRequest,
  getDirectRecruitmentSuccess,
  getDirectRecruitmentError,
  getNetworkRecruitmentRequest,
  getNetworkRecruitmentSuccess,
  getNetworkRecruitmentError,
  getDirectBestSellersAmountRequest,
  getDirectBestSellersAmountSuccess,
  getDirectBestSellersAmountError,
  getDirectBestRecruitersAmountRequest,
  getDirectBestRecruitersAmountSuccess,
  getDirectBestRecruitersAmountError,
  getNetworkBestSellersAmountRequest,
  getNetworkBestSellersAmountSuccess,
  getNetworkBestSellersAmountError,
  getNetworkBestRecruitersAmountRequest,
  getNetworkBestRecruitersAmountSuccess,
  getNetworkBestRecruitersAmountError,
  getDirectPacksAmountRequest,
  getDirectPacksAmountSuccess,
  getDirectPacksAmountError,
  getNetworkPacksAmountRequest,
  getNetworkPacksAmountSuccess,
  getNetworkPacksAmountError,
  getDirectProductsAmountRequest,
  getDirectProductsAmountSuccess,
  getDirectProductsAmountError,
  getNetworkProductsAmountRequest,
  getNetworkProductsAmountSuccess,
  getNetworkProductsAmountError,
  getNetworkTurnoverByCareerRequest,
  getNetworkTurnoverByCareerSuccess,
  getNetworkTurnoverByCareerError,
  getNetworkActiveProsCountByMonthRequest,
  getNetworkActiveProsCountByMonthSuccess,
  getNetworkActiveProsCountByMonthError,
};

function getStatisticsDataByMonth(year, month) {
  return {
    type: types.GET_STATISTICS_DATA_BY_MONTH_REQUEST,
    year,
    month
  };
}

function getTurnoverByMonthRequest() {
  return {
    type: types.GET_TURNOVER_BY_MONTH_REQUEST
  };
}

function getTurnoverByMonthSuccess(data) {
  return {
    type: types.GET_TURNOVER_BY_MONTH_SUCCESS,
    data
  };
}

function getTurnoverByMonthError(error) {
  return {
    type: types.GET_TURNOVER_BY_MONTH_ERROR,
    error
  };
}

function getRecurringTurnoverByMonthRequest() {
  return {
    type: types.GET_RECURRING_TURNOVER_BY_MONTH_REQUEST
  };
}

function getRecurringTurnoverByMonthSuccess(data) {
  return {
    type: types.GET_RECURRING_TURNOVER_BY_MONTH_SUCCESS,
    data
  };
}

function getRecurringTurnoverByMonthError(error) {
  return {
    type: types.GET_RECURRING_TURNOVER_BY_MONTH_ERROR,
    error
  };
}

function getRecruitmentRequest() {
  return {
    type: types.GET_RECRUITMENT_BY_MONTH_REQUEST,
  };
}

function getRecruitmentSuccess(data) {
  return {
    type: types.GET_RECRUITMENT_BY_MONTH_SUCCESS,
    data
  };
}

function getRecruitmentError(error) {
  return {
    type: types.GET_RECRUITMENT_BY_MONTH_ERROR,
    error
  };
}

function getPacksAmountRequest(year, month) {
  return {
    type: types.GET_PACKS_AMOUNT_REQUEST,
    year, month
  };
}

function getPacksAmountSuccess(data) {
  return {
    type: types.GET_PACKS_AMOUNT_SUCCESS,
    data
  };
}

function getPacksAmountError(error) {
  return {
    type: types.GET_PACKS_AMOUNT_ERROR,
    error
  };
}


function getProductsAmountRequest(year, month) {
  return {
    type: types.GET_PRODUCTS_AMOUNT_REQUEST,
    year, month
  };
}

function getProductsAmountSuccess(data) {
  return {
    type: types.GET_PRODUCTS_AMOUNT_SUCCESS,
    data
  };
}

function getProductsAmountError(error) {
  return {
    type: types.GET_PRODUCTS_AMOUNT_ERROR,
    error
  };
}

function getPacksAmountByMonthRequest(year) {
  return {
    type: types.GET_PACKS_AMOUNT_BY_MONTH_REQUEST,
    year
  };
}

function getPacksAmountByMonthSuccess(data) {
  return {
    type: types.GET_PACKS_AMOUNT_BY_MONTH_SUCCESS,
    data
  };
}

function getPacksAmountByMonthError(error) {
  return {
    type: types.GET_PACKS_AMOUNT_BY_MONTH_ERROR,
    error
  };
}

function getBestSellersAmountRequest(year, month) {
  return {
    type: types.GET_BESTSELLERS_AMOUNT_REQUEST,
    year, month
  };
}

function getBestSellersAmountSuccess(data) {
  return {
    type: types.GET_BESTSELLERS_AMOUNT_SUCCESS,
    data
  };
}

function getBestSellersAmountError(error) {
  return {
    type: types.GET_BESTSELLERS_AMOUNT_ERROR,
    error
  };
}

function getBestRecruitersAmountRequest(year, month) {
  return {
    type: types.GET_BEST_RECRUITERS_AMOUNT_REQUEST,
    year, month
  };
}

function getBestRecruitersAmountSuccess(data) {
  return {
    type: types.GET_BEST_RECRUITERS_AMOUNT_SUCCESS,
    data
  };
}

function getBestRecruitersAmountError(error) {
  return {
    type: types.GET_BEST_RECRUITERS_AMOUNT_ERROR,
    error
  };
}

function getOrdersCountByMonthRequest() {
  return {
    type: types.GET_ORDERS_COUNT_BY_MONTH_REQUEST
  };
}
function getOrdersCountByMonthSuccess(data) {
  return {
    type: types.GET_ORDERS_COUNT_BY_MONTH_SUCCESS,
    data
  };
}
function getOrdersCountByMonthError(error) {
  return {
    type: types.GET_ORDERS_COUNT_BY_MONTH_ERROR,
    error
  };
}

function getProsCountByMonthRequest() {
  return {
    type: types.GET_PROS_COUNT_BY_MONTH_REQUEST
  };
}
function getProsCountByMonthSuccess(data) {
  return {
    type: types.GET_PROS_COUNT_BY_MONTH_SUCCESS,
    data
  };
}
function getProsCountByMonthError(error) {
  return {
    type: types.GET_PROS_COUNT_BY_MONTH_ERROR,
    error
  };
}

function getActiveProsCountByMonthRequest() {
  return {
    type: types.GET_ACTIVE_PROS_COUNT_BY_MONTH_REQUEST
  };
}
function getActiveProsCountByMonthSuccess(data) {
  return {
    type: types.GET_ACTIVE_PROS_COUNT_BY_MONTH_SUCCESS,
    data
  };
}
function getActiveProsCountByMonthError(error) {
  return {
    type: types.GET_ACTIVE_PROS_COUNT_BY_MONTH_ERROR,
    error
  };
}

function getNetworkActiveProsCountByMonthRequest(userId) {
  return {
    type: types.GET_NETWORK_ACTIVE_PROS_COUNT_BY_MONTH_REQUEST,
    userId
  };
}
function getNetworkActiveProsCountByMonthSuccess(userId, data) {
  return {
    type: types.GET_NETWORK_ACTIVE_PROS_COUNT_BY_MONTH_SUCCESS,
    userId, data
  };
}
function getNetworkActiveProsCountByMonthError(error) {
  return {
    type: types.GET_NETWORK_ACTIVE_PROS_COUNT_BY_MONTH_ERROR,
    error
  };
}

function getTurnoverByCareerRequest(year, month) {
  return {
    type: types.GET_TURNOVER_BY_CAREER_REQUEST,
    year, month
  };
}
function getTurnoverByCareerSuccess(data) {
  return {
    type: types.GET_TURNOVER_BY_CAREER_SUCCESS,
    data
  };
}
function getTurnoverByCareerError(error) {
  return {
    type: types.GET_TURNOVER_BY_CAREER_ERROR,
    error
  };
}

function getDirectSalesRequest(userId) {
  return {
    type: types.GET_DIRECT_SALES_REQUEST,
    userId
  };
}

function getDirectSalesSuccess(userId, data) {
  return {
    type: types.GET_DIRECT_SALES_SUCCESS,
    userId, data
  };
}

function getDirectSalesError(error) {
  return {
    type: types.GET_DIRECT_SALES_ERROR,
    error
  };
}

function getDirectRecurringSalesRequest(userId) {
  return {
    type: types.GET_DIRECT_RECURRING_SALES_REQUEST,
    userId
  };
}

function getDirectRecurringSalesSuccess(userId, data) {
  return {
    type: types.GET_DIRECT_RECURRING_SALES_SUCCESS,
    userId, data
  };
}

function getDirectRecurringSalesError(error) {
  return {
    type: types.GET_DIRECT_RECURRING_SALES_ERROR,
    error
  };
}
function getNetworkSalesRequest(userId) {
  return {
    type: types.GET_NETWORK_SALES_REQUEST,
    userId
  };
}

function getNetworkSalesSuccess(userId,  data) {
  return {
    type: types.GET_NETWORK_SALES_SUCCESS,
    userId, data
  };
}

function getNetworkSalesError(error) {
  return {
    type: types.GET_NETWORK_SALES_ERROR,
    error
  };
}
function getNetworkRecurringSalesRequest(userId) {
  return {
    type: types.GET_NETWORK_RECURRING_SALES_REQUEST,
    userId
  };
}

function getNetworkRecurringSalesSuccess(userId,  data) {
  return {
    type: types.GET_NETWORK_RECURRING_SALES_SUCCESS,
    userId, data
  };
}

function getNetworkRecurringSalesError(error) {
  return {
    type: types.GET_NETWORK_RECURRING_SALES_ERROR,
    error
  };
}

function getDirectRecruitmentRequest(userId) {
  return {
    type: types.GET_DIRECT_RECRUITMENT_REQUEST,
    userId
  };
}

function getDirectRecruitmentSuccess(userId, data) {
  return {
    type: types.GET_DIRECT_RECRUITMENT_SUCCESS,
    userId, data
  };
}

function getDirectRecruitmentError(error) {
  return {
    type: types.GET_DIRECT_RECRUITMENT_ERROR,
    error
  };
}

function getNetworkRecruitmentRequest(userId) {
  return {
    type: types.GET_NETWORK_RECRUITMENT_REQUEST,
    userId
  };
}

function getNetworkRecruitmentSuccess(userId, data) {
  return {
    type: types.GET_NETWORK_RECRUITMENT_SUCCESS,
    userId, data
  };
}

function getNetworkRecruitmentError(error) {
  return {
    type: types.GET_NETWORK_RECRUITMENT_ERROR,
    error
  };
}

function getDirectBestSellersAmountRequest(userId, year, month) {
  return {
    type: types.GET_DIRECT_BESTSELLERS_AMOUNT_REQUEST,
    userId, year, month
  };
}

function getDirectBestSellersAmountSuccess(userId, year, month, data) {
  return {
    type: types.GET_DIRECT_BESTSELLERS_AMOUNT_SUCCESS,
    userId, year, month, data
  };
}

function getDirectBestSellersAmountError(error) {
  return {
    type: types.GET_DIRECT_BESTSELLERS_AMOUNT_ERROR,
    error
  };
}

function getDirectBestRecruitersAmountRequest(userId, year, month) {
  return {
    type: types.GET_DIRECT_BEST_RECRUITERS_AMOUNT_REQUEST,
    userId, year, month
  };
}

function getDirectBestRecruitersAmountSuccess(userId, year, month, data) {
  return {
    type: types.GET_DIRECT_BEST_RECRUITERS_AMOUNT_SUCCESS,
    userId, year, month, data
  };
}

function getDirectBestRecruitersAmountError(error) {
  return {
    type: types.GET_DIRECT_BEST_RECRUITERS_AMOUNT_ERROR,
    error
  };
}

function getNetworkBestSellersAmountRequest(userId, year, month) {
  return {
    type: types.GET_NETWORK_BESTSELLERS_AMOUNT_REQUEST,
    userId, year, month
  };
}

function getNetworkBestSellersAmountSuccess(userId, year, month, data) {
  return {
    type: types.GET_NETWORK_BESTSELLERS_AMOUNT_SUCCESS,
    userId, year, month, data
  };
}

function getNetworkBestSellersAmountError(error) {
  return {
    type: types.GET_NETWORK_BESTSELLERS_AMOUNT_ERROR,
    error
  };
}

function getNetworkBestRecruitersAmountRequest(userId, year, month) {
  return {
    type: types.GET_NETWORK_BEST_RECRUITERS_AMOUNT_REQUEST,
    userId, year, month
  };
}

function getNetworkBestRecruitersAmountSuccess(userId, year, month, data) {
  return {
    type: types.GET_NETWORK_BEST_RECRUITERS_AMOUNT_SUCCESS,
    userId, year, month, data
  };
}

function getNetworkBestRecruitersAmountError(error) {
  return {
    type: types.GET_NETWORK_BEST_RECRUITERS_AMOUNT_ERROR,
    error
  };
}

function getDirectPacksAmountRequest(userId, year, month) {
  return {
    type: types.GET_DIRECT_PACKS_AMOUNT_REQUEST,
    userId, year, month
  };
}

function getDirectPacksAmountSuccess(userId, year, month, data) {
  return {
    type: types.GET_DIRECT_PACKS_AMOUNT_SUCCESS,
    userId, year, month, data
  };
}

function getDirectPacksAmountError(error) {
  return {
    type: types.GET_DIRECT_PACKS_AMOUNT_ERROR,
    error
  };
}

function getNetworkPacksAmountRequest(userId, year, month) {
  return {
    type: types.GET_NETWORK_PACKS_AMOUNT_REQUEST,
    userId, year, month
  };
}

function getNetworkPacksAmountSuccess(userId, year, month, data) {
  return {
    type: types.GET_NETWORK_PACKS_AMOUNT_SUCCESS,
    userId, year, month, data
  };
}

function getNetworkPacksAmountError(error) {
  return {
    type: types.GET_NETWORK_PACKS_AMOUNT_ERROR,
    error
  };
}

function getDirectProductsAmountRequest(userId, year, month) {
  return {
    type: types.GET_DIRECT_PRODUCTS_AMOUNT_REQUEST,
    userId, year, month
  };
}

function getDirectProductsAmountSuccess(userId, year, month, data) {
  return {
    type: types.GET_DIRECT_PRODUCTS_AMOUNT_SUCCESS,
    userId, year, month, data
  };
}

function getDirectProductsAmountError(error) {
  return {
    type: types.GET_DIRECT_PRODUCTS_AMOUNT_ERROR,
    error
  };
}

function getNetworkProductsAmountRequest(userId, year, month) {
  return {
    type: types.GET_NETWORK_PRODUCTS_AMOUNT_REQUEST,
    userId, year, month
  };
}

function getNetworkProductsAmountSuccess(userId, year, month, data) {
  return {
    type: types.GET_NETWORK_PRODUCTS_AMOUNT_SUCCESS,
    userId, year, month, data
  };
}

function getNetworkProductsAmountError(error) {
  return {
    type: types.GET_NETWORK_PRODUCTS_AMOUNT_ERROR,
    error
  };
}

function getNetworkTurnoverByCareerRequest(userId, year, month) {
  return {
    type: types.GET_NETWORK_TURNOVER_BY_CAREER_REQUEST,
    userId, year, month
  };
}
function getNetworkTurnoverByCareerSuccess(userId, year, month, data) {
  return {
    type: types.GET_NETWORK_TURNOVER_BY_CAREER_SUCCESS,
    userId, year, month, data
  };
}
function getNetworkTurnoverByCareerError(error) {
  return {
    type: types.GET_NETWORK_TURNOVER_BY_CAREER_ERROR,
    error
  };
}
*/