import { call, put, takeEvery } from 'redux-saga/effects';

import {proShopActions} from "./actions";
import * as actionsType from './constants';
import {proShopService} from "./services";

export const proShopSagaKey = 'proShop';

function* getCheckout(action) {

  try {
    const checkout = yield call(proShopService.getCheckout, action.lang, action.items, action.useWallet);
    yield put(proShopActions.getCheckoutSuccess(checkout));
  }
  catch (e) {
    yield put(proShopActions.getCheckoutFail());
  }
}

export default function* watchDashboard() {

  yield takeEvery(actionsType.GET_CHECKOUT_REQUEST, getCheckout);
}
