import React, { forwardRef, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import {makeStyles} from '@mui/styles';
import classnames from "classnames";

import { FormValidatorContext } from "./FormValidator";

const useStyle = makeStyles(() => ({
  root: {
  },
}));

const TextFieldValidator =
    forwardRef(({
      fullWidth, autoFocus, className, variant, color, size, type,
      id, name, label, value, onChange, required, defaultValue, disabled,
      FormHelperTextProps, InputLabelProps, inputProps, InputProps, inputRef,
      margin, multiline, placeholder, rows, select, SelectProps, ...rest
    },
    ref
    ) => {

      const {t} = useTranslation();
      const classes = useStyle();
      const { submitted, setValidationFields } = useContext(FormValidatorContext);
      const [error, setError] = useState(null);
      const [errorMessage, setErrorMessage] = useState(t("validatorFieldRequired"));

      useEffect(() => {
        validate(value);
      }, [value]);

      useEffect(() => {

        setValidationFields(fields => ({...fields, [name]:error}));
      }, [error]);

      useEffect(() =>

        //COMPONENT WILL UNMOUNT
        () => {
          setValidationFields(fields => {
            delete fields[name];
            return fields;
          });
        }
      , []);

      const validateEmail = (email) => (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email));

      const validate = (targetValue) => {

        let hasError = false;
        if(required) {
          hasError = !targetValue;
          setError(hasError);
          setErrorMessage(t("validatorFieldRequired"));
        }
        if(!hasError) {
          switch (id) {
            case 'email':
              if(!validateEmail(targetValue)) {
                setError(true);
                setErrorMessage(t("validatorInvalidEmail"));
              }
              break;
            case 'phone':
              if(targetValue.length < 7) {
                setError(true);
                setErrorMessage("Le numéro de téléphone n'est pas valide");
              }
          }
        }
      };

      const handleChange = (e) => {

        if(id === 'phone') {
          e.target.value = e.target.value.replace(/[^0-9]/g, '');
        }

        validate(e.target.value);
        {typeof onChange === "function" && onChange(e)}
      };

      return (
        <TextField
          ref={ref}
          color={color}
          defaultValue={defaultValue}
          disabled={disabled}
          FormHelperTextProps={FormHelperTextProps}
          InputLabelProps={InputLabelProps}
          inputProps={inputProps}
          InputProps={InputProps}
          inputRef={inputRef}
          margin={margin}
          multiline={multiline}
          placeholder={placeholder}
          rows={rows}
          select={select}
          SelectProps={SelectProps}
          size={size}
          type={type ? type : 'text'}
          autoFocus={autoFocus}
          fullWidth={fullWidth}
          variant={variant ? variant : "outlined"}
          id={id}
          className={classnames(classes.root, className)}
          name={name}
          label={label}
          value={value}
          onChange={handleChange}
          required={required}
          error={submitted && error}
          helperText={submitted && error && errorMessage}
          {...rest}
        />
      );
    });

export default TextFieldValidator;
