import React, {useEffect} from "react";
import ReactGA from "react-ga4";
import {useDispatch, useSelector} from "react-redux";

import {SnackbarInfo} from "../../../../../helpers/snackbar";
import useCheckOrderPayment from "../../../../../helpers/useCheckOrderPayment";
import {getProductPriceWithTaxes} from "../../../../../helpers/utils";
import {userSelector} from "../../../../Authentication/store/selectors";
import {walletActions} from "../../../Wallet/store/actions";
import {shopActions} from "../../store/actions";
import {shopService} from "../../store/services";

import PaymentResponseInfo from "./PaymentResponseInfo";

function getCategoriesObject(product) {
  const categories = {};
  product.categories.map((c, i) => {
    categories[`item_category${i ? i + 1 : ''}`] = c.name;
  });
  return categories;
}
const PaymentResponse = ({orderId}) => {

  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const {status, url} = useCheckOrderPayment(orderId);
  console.log(status);
  useEffect(() => {
    switch (status) {
      case "succeeded":
        handlePaymentSuccess();
        break;
      case "canceled":
        handlePaymentCanceled();
        break;
      case "checking":
        handlePaymentChecking();
        break;
      case "initialized":
        handlePaymentInitialized();
        break;
      case "failed":
      case "void":
      case "uncleared":
      case "requiresPaymentMethod":
        handlePaymentError();
        break;
    }

  }, [status]);

  useEffect(() => {
    shopService.setCartLog("Le client arrive dans le drawer d'après validation de paiement",{from:"paymentResponse"}).then().catch();
  }, []);

  const handlePaymentSuccess = () => {
    // SnackbarSuccess(t('congratulationNewOrder'));
    dispatch(shopActions.cleanCheckout());
    dispatch(walletActions.getWalletRequest());

    shopService.getOrderById(orderId).then((order) => {
      ReactGA.event("purchase", {
        operatorId: user?.controlId ?? null,
        proId: (user?.role === "prospect" || user?.role === "customer") && order?.proId ? order?.proId : null,
        userType: user?.role,
        userId: user?.id,
        transaction_id: order?.id,
        currency: "EUR",
        value: Math.max(0, parseFloat((((order?.totalPrice + order?.taxes - order?.walletAmount - order?.giftCardAmount) * 100) / 100).toFixed(2))),
        user_email: user?.email,
        client_type: user?.role === "prospect" ? 1 : 0,
        tax: (order?.taxes).toFixed(2),
        shipping: (order?.shippingPrice).toFixed(2),
        items: order?.products?.map(item =>
          ({
            item_id: item?.originalProduct?.id,
            item_name: item?.originalProduct?.reference,
            item_brand: item?.originalProduct?.brand?.name,
            ...getCategoriesObject(item?.originalProduct),
            price: getProductPriceWithTaxes(item?.originalProduct, order?.client, order?.shippingAddress, 1, true),
            quantity: item.quantity
          })
        )
      });
    });

    setTimeout(function() {
      window.location.href = "/?account=orders";
    }, 3000);
  };

  const handlePaymentCanceled = () => {
    // SnackbarError(t('paymentCanceled'));
    setTimeout(function() {
      //shopService.deleteMultisafepayOrder(orderId).then(() => {});
      dispatch(shopActions.setCheckoutProcessing(false));
      dispatch(shopActions.SetDrawersOpen("checkout", true));
    }, 3000);
  };

  const handlePaymentInitialized = () => {
    SnackbarInfo("Il semble que vous ayez quitté la page de paiement, votre commande a été annulée.");
    setTimeout(function() {
      //shopService.deleteMultisafepayOrder(orderId).then(() => {});
      dispatch(shopActions.setCheckoutProcessing(false));
      dispatch(shopActions.SetDrawersOpen("checkout", true));
    }, 3000);
  };

  const handlePaymentChecking = () => {
    setTimeout(function() {
      //shopService.deleteMultisafepayOrder(orderId).then(() => {});
    }, 3000);
  };

  const handlePaymentError = () => {
    // SnackbarError(t('paymentError'));
    setTimeout(function() {
      //shopService.deleteMultisafepayOrder(orderId).then(() => {});
      dispatch(shopActions.setCheckoutProcessing(false));
      dispatch(shopActions.SetDrawersOpen("checkout", true));
    }, 3000);
  };

  return (
    <PaymentResponseInfo status={status} url={url} onCancel={handlePaymentCanceled}/>
  );
};
export default PaymentResponse;
