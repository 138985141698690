import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import {Box, Table, TableBody, TableContainer, TableHead, TableRow, TableSortLabel} from "@mui/material";
import { grey } from "@mui/material/colors";
import Container from "@mui/material/Container";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Paper from "@mui/material/Paper";
import {useTheme} from '@mui/material/styles';
import Tab from "@mui/material/Tab";
import TableCell from "@mui/material/TableCell";
import Tabs from "@mui/material/Tabs";
import {makeStyles} from '@mui/styles';
import moment from 'moment';

import DotsLoader from "../../../../components/DotsLoader";
import {userService} from "../store/services";


const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiDialog-paper': {
      [theme.breakpoints.up('sm')]: {
        width: '80vw',
        minWidth: 760,
        '& .MuiListItem-root': {
          borderRight: '1px solid #DDD'
        },
        '& .MuiDivider-root': {
          margin: -1
        }
      },
      [theme.breakpoints.down('xs')]: {
        '& .MuiCard-root': {
          overflow: 'auto'
        }
      },
      '& .MuiTypography-subtitle1': {
        maxHeight: 42,
        lineHeight: '20px',
        overflow: 'hidden'
      },
      '& h4': {
        marginBottom: 20
      },
      '& .MuiInputBase-input': {
        backgroundColor: theme.palette.common.white
      }
    },
    '& img': {
      maxWidth: '100%',
      maxHeight: 80
    }
  },
  form: {
    '& .enterBtn': {
      marginTop: 42,
    },
    '& .secondaryLink': {
      marginTop: 15,
      '& .MuiButton-label': {
        lineHeight: 1.5,
        fontSize: '1rem'
      }
    },
  },
  closeButton: {
    top: 10,
    right: 15,
    cursor: 'pointer',
    position: 'absolute',
    fontSize: 36,
    color: grey[500],
    [theme.breakpoints.down('xs')]: {
      top: 10,
      right: 20,
      fontSize: 40,
    },
  },
}));

function a11yProps(index) {
  return {
    id: `points-details-vertical-tab-${index}`,
    'aria-controls': `points-details-vertical-tabpanel-${index}`,
  };
}

const DelegateProsModal = ({open, setOpen}) => {
  const theme = useTheme();
  const classes = useStyles();
  const [data, setData] = useState([]);
  const months = [
    {name:"Janvier", value: 1},
    {name:"Février", value: 2},
    {name:"Mars", value: 3},
    {name:"Avril", value: 4},
    {name:"Mai", value: 5},
    {name:"Juin", value: 6},
    {name:"Juillet", value: 7},
    {name:"Août", value: 8},
    {name:"Septembre", value: 9},
    {name:"Octobre", value: 10},
    {name:"Novembre", value: 11},
    {name:"Décembre", value: 12},
  ];
  const [month, setMonth] = useState({name:months[moment().month()].name,value:months[moment().month()].value});
  const years = [
    {name:"2023", value: 0},
    {name:"2024", value: 1},
  ];
  const [year, setYear] = useState({name:years[1].name,value:years[1].value});
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const {userId} = useParams();
  const [openRows, setOpenRows] = useState([]);
  const [totals, setTotals] = useState({ca_htva: 0, ca_tvac: 0, orderCount: 0, patientCount: 0});

  useEffect(() => {
    (async() => {
      setIsLoading(true);
      let fetchedData = await userService.getProsDetails(userId, year.name, month.value);
      if(fetchedData.length > 0) {
        let transformedData = await transformData(fetchedData);
        let total_ca_htva = transformedData.reduce((acc, row) => acc + row.CA_HTVA, 0);
        let total_ca_tvac = transformedData.reduce((acc, row) => acc + row.CA_TVAC, 0);
        let total_orderCount = transformedData.reduce((acc, row) => acc + row.orderCount, 0);
        let total_patientCount = transformedData.reduce((acc, row) => acc + row.patientCount, 0);

        setTotals({
          ca_htva: total_ca_htva,
          ca_tvac: total_ca_tvac,
          orderCount: total_orderCount,
          patientCount: total_patientCount
        });
        setRows(transformedData);
        setData(transformedData.sort((a, b) => b.CA_HTVA - a.CA_HTVA));
      }

      setIsLoading(false);
    })();

  }, [month, year]);

  function round(number, decimalPlaces = 0) {
    let multiplier = Math.pow(10, decimalPlaces);
    return Math.round(number * multiplier) / multiplier;
  }

  const transformData = async (data) => data.map((row) => {
    let uniqueUserIds = new Set();

    // Iterate through proOrders of the current row to collect unique userIds
    row.proOrders.forEach(proOrder => {
      uniqueUserIds.add(proOrder.userId);
    });

    // Count the unique userIds for the current row
    let patientCount = uniqueUserIds.size;

    return {
      id: row.id,
      fullName: row.lastName + " " + row.firstName,
      registerDate: moment(row.createdAt),
      CA_HTVA: round(row.proOrders?.reduce((acc, proOrder) => acc + proOrder.products.reduce((acc, product) =>
        acc + (((parseFloat(product.price) / product.quantity) - (parseFloat(product.discount) / product.quantity)) * (product.quantity - product.refundedQuantity))
      , 0), 0)),
      CA_TVAC: round(row.proOrders?.reduce((acc, proOrder) => acc + proOrder.products.reduce((acc, product) =>
        acc + ((parseFloat(product.price) / product.quantity) - (parseFloat(product.discount) / product.quantity)) * (product.quantity - product.refundedQuantity) * (1 + (parseFloat(product.taxesRate) / 100))
      , 0), 0)),
      orderCount: row.proOrders.length,
      patientCount: patientCount, // Assign the count of unique userIds
      products: (() => {
      // Create an object to hold unique references and their counts
        let referenceCounts = {};

        // Iterate through proOrders to count references
        row.proOrders.forEach(proOrder => {
          proOrder.products.forEach(product => {
          // Extract product reference
            let {reference} = product.originalProduct;

            // If the reference already exists, increment the count by product quantity
            if (referenceCounts[reference]) {
              referenceCounts[reference] += product.quantity;
            } else {
            // Otherwise, initialize the count with product quantity
              referenceCounts[reference] = product.quantity;
            }
          });
        });

        // Construct an array of objects containing unique references and their counts
        return Object.keys(referenceCounts).map(reference => ({
          reference: reference,
          count: referenceCounts[reference]
        })).sort((a, b) => b.count - a.count);
      })()
    };
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeMonth = (e, value) => {
    setMonth({...months[value]});
  };

  const handleChangeYear = (e, value) => {
    setYear({...years[value]});
  };

  const handleRowClick = (rowId) => {
    setOpenRows((prevOpenRows) =>
      prevOpenRows.includes(rowId)
        ? prevOpenRows.filter((id) => id !== rowId)
        : [...prevOpenRows, rowId]
    );
  };

  const headCells = [
    {
      id: "registerDate",
      label: "Inscription"
    },{
      id: "Thérapeute",
      label: "Nom",
    },{
      id: "CA_HTVA",
      label: "CA HTVA",
      numeric: true
    },{
      id: "CA_TVAC",
      label: "CA TVAC",
      numeric: true
    },{
      id: "orderCount",
      label: "Nombre commandes",
      numeric: true
    },{
      id: "patientCount",
      label: "Nombre patients",
      numeric: true
    }];

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('CA_HTVA');
  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    setData(data.sort((a, b) => {
      if (property === 'registerDate') {
        if (isAsc) {
          return moment(a[property]).isAfter(moment(b[property])) ? 1 : -1;
        } else {
          return moment(a[property]).isBefore(moment(b[property])) ? 1 : -1;
        }
      } else {
        if (isAsc) {
          return a[property] > b[property] ? 1 : -1;
        } else {
          return a[property] < b[property] ? 1 : -1;
        }
      }

    }));
  };

  return (
    <Dialog onClose={handleClose} open={open} className={classes.root} fullScreen={true}>
      <CloseIcon sx={{
        top: 10,
        right: 15,
        cursor: 'pointer',
        position: 'absolute',
        fontSize: 36,
        color: grey[500],
        [theme.breakpoints.down('xs')]: {
          top: 10,
          right: 20,
          fontSize: 40,
        },
      }} onClick={handleClose}/>
      <DialogTitle id="therap-dialog-title">Détail des thérapeutes</DialogTitle>

      <Tabs
        orientation="horizontal"
        variant="scrollable"
        value={month.value -1}
        onChange={handleChangeMonth}
        aria-label="therap-details-vertical-tabs"
        sx={{
          backgroundColor: '#EEF1F2'
        }}
      >
        {months.map((month, index) => (
          <Tab
            key={index}
            label={month.name}
            sx={{
              color: '#7C909B',
              fontSize: "10px",
              fontFamily: "Montserrat"
            }}
            {...a11yProps(index)} />
        ))}
      </Tabs>

      <DialogContent>
        <Container
          maxWidth="xl"
          sx={{
            height: "100%",
            paddingLeft: '0px !important',
            paddingRight: '0px !important',
            overflowX: 'hidden',
          }}>
          <Box sx={{width: '100%', display: 'flex'}}>
            <Box
              sx={{
                background: '#EEF1F2',
                height: '100%',
                pt: '0 !important',
                width: '90px',
                '& .MuiButtonBase-root.MuiTab-root.MuiTab-labelIcon': {
                  width: '90px',
                  padding: 0
                }
              }}>
              <Tabs
                orientation="vertical"
                variant="scrollable"
                value={year.value}
                onChange={handleChangeYear}
                aria-label="therap-details-vertical-tabs"
                sx={{
                  backgroundColor: '#EEF1F2'
                }}
              >
                <Tab
                  key={0}
                  label={"2023"}
                  sx={{
                    color: '#7C909B',
                    fontSize: "10px",
                    fontFamily: "Montserrat"
                  }}
                  {...a11yProps(0)} />
                <Tab
                  key={1}
                  label={"2024"}
                  sx={{
                    color: '#7C909B',
                    fontSize: "10px",
                    fontFamily: "Montserrat"
                  }}
                  {...a11yProps(0)} />
              </Tabs>
            </Box>
            {isLoading && (
              <Box sx={{justifyContent:"center",width: '100%'}}>
                <DotsLoader />
              </Box>
            )}
            {!isLoading && (
              <Box
                sx={{
                  background: '#fff',
                  maxWidth: {mw: '100%'},
                  width: '100%',
                  pl: 1,
                  minHeight: '100vh',
                  overflowY: 'auto',
                }}>
                <Box sx={{width:'100%'}}>
                  <TableContainer component={Paper}>
                    <Table size="small" aria-label="a dense table">
                      <TableHead>
                        <TableRow>
                          {headCells.map((headCell) => (
                            <TableCell
                              key={headCell.id}
                              align={headCell.numeric ? 'right' : 'left'}
                              padding={headCell.disablePadding ? 'none' : 'normal'}
                              sortDirection={orderBy === headCell.id ? order : false}
                            >
                              <TableSortLabel
                                direction={orderBy === headCell.id ? order : 'asc'}
                                active={orderBy === headCell.id}
                                onClick={createSortHandler(headCell.id)}
                              >
                                {headCell.label}
                              </TableSortLabel>
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow
                          key={'totals'}
                          sx={{
                            cursor: 'pointer',
                            backgroundColor: '#e1e1e1',
                            '&:last-child td, &:last-child th': { border: 0 },
                            '&:hover': {
                              backgroundColor: '#f5f5f5'
                            }
                          }}
                        >
                          <TableCell></TableCell>
                          <TableCell>{'TOTAL'}</TableCell>
                          <TableCell align="right">{totals.ca_htva}</TableCell>
                          <TableCell align="right">{totals.ca_tvac}</TableCell>
                          <TableCell align="right">{totals.orderCount}</TableCell>
                          <TableCell align="right">{totals.patientCount}</TableCell>
                        </TableRow>
                        {rows.map((row) => (
                          <React.Fragment key={row.id}>
                            <TableRow
                              key={row.id}
                              sx={{
                                cursor: 'pointer',
                                '&:last-child td, &:last-child th': { border: 0 },
                                '&:hover': {
                                  backgroundColor: '#f5f5f5'
                                }
                              }}
                              onClick={() => handleRowClick(row.id)}
                            >
                              <TableCell>{moment(row.registerDate).format('LL')}</TableCell>
                              <TableCell>{row.fullName}</TableCell>
                              <TableCell align="right">{row.CA_HTVA}</TableCell>
                              <TableCell align="right">{row.CA_TVAC}</TableCell>
                              <TableCell align="right">{row.orderCount}</TableCell>
                              <TableCell align="right">{row.patientCount}</TableCell>
                            </TableRow>
                            {openRows.includes(row.id) && (
                              <TableRow>
                                <Table size="small" aria-label="a dense table">
                                  <TableBody>
                                    {row.products.map((product, index) => (
                                      <TableRow key={index}>
                                        <TableCell>{product.reference}</TableCell>
                                        <TableCell>{product.count}</TableCell>
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </TableRow>
                            )}
                          </React.Fragment>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {/* <DataGrid
                    autoHeight
                    data={data}
                    columns={columns}
                    disableSelectionOnClick
                    onRowClick={handleRowClick}
                    initialPageSize={100}
                    toolbarColumns
                    toolbarFilter
                    toolbarDensity
                    toolbarExportCSV
                    toolbarQuickSearch
                    experimentalFeatures={{ newEditingApi: true }}
                    toolbarExportCSVOptions={{fileName: `${user?.lastName}-points-${month.name}-${year.name}`}}
                    sx={{'& .MuiDataGrid-row': {cursor: 'pointer'}}}
                  /> */}
                </Box>
              </Box>
            )}
          </Box>
        </Container>
      </DialogContent>
    </Dialog>
  );
};

export default DelegateProsModal;
