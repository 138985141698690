import React, {useEffect, useState} from "react";
import {Trans} from 'react-i18next';
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import PageLoader from "../../../../../components/PageLoader";
import {SnackbarFormattedError} from "../../../../../helpers/snackbar";
import useInfiniteScroll from "../../../../../helpers/useInfiniteScroll";
import {shopService} from "../../store/services";

import Review from "./Review";

const Reviews = ({product}) => {

  const [loader, setLoader] = useState(false);
  const [endOfList, setEndOfList] = useState(false);
  const [reviews, setReviews] = useState(null);

  useEffect(() => {
    setReviews(null);
    shopService.getProductReviews(product.id)
      .then(reviews => {
        if(reviews.length < 10) {
          setEndOfList(true);
        }
        setReviews(reviews);
      });
  }, [product]);

  useEffect(() => {
    if(loader || endOfList) return;
    setIsFetchingQuestions(false);
  }, [loader]);

  const loadReviews = () => {
    if(endOfList) return;
    setLoader(true);
    shopService.getProductReviews(product.id, reviews.length)
      .then(data => {
        if(data.length < 10) {
          setEndOfList(true);
        }
        setReviews([...reviews, ...data]);
        setLoader(false);
      })
      .catch(e => {
        SnackbarFormattedError(e.error);
        setLoader(false);
      });
  };

  const [isFetchingQuestions, setIsFetchingQuestions] = useInfiniteScroll(loadReviews);

  if(!reviews) {
    return (
      <PageLoader height={100}/>
    );
  }

  return (
    <div>
      {reviews.length === 0 && (
        <Typography>
          <Trans i18nKey={"noReviewsYet"}>There are no reviews yet for this product</Trans>
        </Typography>
      )}
      {reviews.length > 0 && (
        <>
          {reviews.map(review => (
            <>
              <Review review={review}/>
              <Divider/>
            </>
          ))}
          {(!loader && !!reviews && !endOfList) && (
            <Grid container>
              <Button variant={"outlined"} onClick={loadReviews}>
                <Trans i18nKey={"moreReviewsButton"}>Show more reviews</Trans>
              </Button>
            </Grid>
          )}
          {(loader) && (
            <PageLoader height={100}/>
          )}
        </>
      )}
    </div>
  );
};
export default Reviews;