import React, { useEffect, useRef, useState } from 'react';
import CardMedia from "@mui/material/CardMedia";

import useIntersectionObserver from "./useIntersectionObserver";

const _loaded = {};

const LazyLoadCardMedia = ({ className, component, alt, width, height, image, title, skeletonSrc, ...rest }) => {
  const elementRef = useRef(null);
  const [inView] = useIntersectionObserver(elementRef);
  const [loaded, setLoaded] = useState(_loaded[image]);

  useEffect(() => {
    if (inView) {
      if (_loaded[image]) {
        setLoaded(true);

        return;
      }

      const img = new Image();
      img.src = image;
      img.onload = () => {
        _loaded[image] = true;
        setLoaded(true);
      };
    }
  }, [inView]);

  return (
    <div ref={elementRef} className={className ? `${className}-wrapper` : null}>
      {!loaded ? (
        <CardMedia
          {...rest}
          alt={alt}
          component={component}
          width={width}
          height={height}
          image={skeletonSrc}
          title={title}
        />
      ) : (
        <CardMedia
          {...rest}
          alt={alt}
          component={component}
          width={width}
          height={height}
          image={image}
          title={title}
        />
      )}
    </div>
  );
};

export default LazyLoadCardMedia;