import axios  from '../../../../helpers/axios';

export const networkService = {
  getParent,
  getChildren,
  getRequests,
  getRequest,
  acceptRequest,
  declineRequest,
  getConsultants,
  getEvolutions,
  getPartners,
  addPartner,
  updatePartner,
  updatePartnerShippingAddress,
  sendPartnerRequest,
  removePartnerRequest,
  validatePartnership,
  getConsultant,
  getConsultantsBySearch,
  getOffice,
  uploadCoverImage,
  uploadProfileImage,
  saveOffice,
  setDisplayedOnMap,
};

function getParent(userId, d) {

  return new Promise((resolve, reject) => {

    axios.get(`/user/${userId}/parent`)
      .then(({parent}) => {
        resolve({
          id: parent.id,
          person: {
            id: parent.id,
            avatar: `${process.env.REACT_APP_API_RESOURCES_URL}/images/avatar.svg`,
            department: '',
            name: `${parent.firstName} ${parent.lastName}`,
            title: parent.role,
            totalReports: 0,
            reportsText: 'consultant'
          },
          hasChild: false,
          hasParent: typeof parent.parentId === 'string',
          children: [d],
        });
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getChildren(userId) {

  return new Promise((resolve, reject) => {

    axios.get(`/user/${userId}/tree/children`)
      .then(({children}) => {
        resolve(children);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getRequests() {

  return new Promise((resolve, reject) => {

    axios.get(`/pro/profiling/requests`)
      .then(({requests}) => {
        resolve(requests);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getRequest(requestId) {

  return new Promise((resolve, reject) => {

    axios.get(`/pro/profiling/request/${requestId}`)
      .then(({request}) => {
        resolve(request);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function acceptRequest(requestId) {

  return new Promise((resolve, reject) => {

    axios.patch(`/pro/profiling/request/${requestId}/accept`)
      .then(() => {
        resolve();
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function declineRequest(requestId) {

  return new Promise((resolve, reject) => {

    axios.patch(`/pro/profiling/request/${requestId}/decline`)
      .then(() => {
        resolve();
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getConsultants() {

  return new Promise((resolve, reject) => {

    axios.get(`/user/consultants`)
      .then(({consultants}) => {
        resolve(consultants);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getEvolutions(role) {

  return new Promise((resolve, reject) => {

    axios.get(`/pro/network/${role}/evolutions`)
      .then(({pros}) => {
        resolve(pros);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getPartners() {

  return new Promise((resolve, reject) => {

    axios.get(`/user/partners`)
      .then(({partners}) => {
        resolve(partners);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function addPartner(partner) {

  return new Promise((resolve, reject) => {

    axios.post(`/user/partner`, {partner})
      .then(({partner}) => {
        resolve(partner);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function updatePartner(partner, data) {

  return new Promise((resolve, reject) => {

    axios.patch(`/user/partner/${partner.id}`, {data})
      .then(({partner}) => {
        resolve(partner);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function updatePartnerShippingAddress(partner, data) {

  return new Promise((resolve, reject) => {

    axios.patch(`/user/partner/${partner.id}/shippingAddress`, {data})
      .then(({shippingAddress}) => {
        resolve(shippingAddress);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function sendPartnerRequest(partner) {

  return new Promise((resolve, reject) => {

    axios.post(`/user/partner/${partner.id}/request`)
      .then(() => {
        resolve();
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function removePartnerRequest(partner) {

  return new Promise((resolve, reject) => {

    axios.delete(`/user/partner/${partner.id}/request`)
      .then(() => {
        resolve();
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function validatePartnership() {

  return new Promise((resolve, reject) => {

    axios.post(`/user/partner/request/validate`)
      .then(() => {
        resolve();
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getConsultant(consultantId) {

  return new Promise((resolve, reject) => {

    axios.get(`/user/consultant/${consultantId}`)
      .then(({consultant}) => {
        resolve(consultant);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getConsultantsBySearch(search) {

  return new Promise((resolve, reject) => {

    axios.get(`/user/consultants/search/${search}`)
      .then(({users}) => {
        resolve(users);
      })
      .catch((e) => {
        reject(e);
      });
  });
}
function uploadCoverImage(userId, image) {

  return new Promise((resolve, reject) => {

    axios.post(`/pro/partner/${userId}/office/image/cover/upload`, {image})
      .then(({image}) => {
        resolve(image);
      })
      .catch(e => {
        reject(e.response.data);
      });
  });
}

function uploadProfileImage(userId, image) {

  return new Promise((resolve, reject) => {

    axios.post(`/pro/partner/${userId}/office/image/profile/upload`, {image})
      .then(({image}) => {
        resolve(image);
      })
      .catch(e => {
        reject(e.response.data);
      });
  });
}

function getOffice(userId) {

  return new Promise((resolve, reject) => {

    axios.get(`/pro/partner/${userId}/office`)
      .then(({office}) => {
        resolve(office);
      })
      .catch(e => {
        reject(e.response.data);
      });
  });
}

function saveOffice(userId, office) {

  return new Promise((resolve, reject) => {

    axios.put(`/pro/partner/${userId}/office`, office)
      .then(({office}) => {
        resolve(office);
      })
      .catch(e => {
        reject(e.response.data);
      });
  });
}

function setDisplayedOnMap(userId, isDisplayedOnMap) {

  return new Promise((resolve, reject) => {

    axios.patch(`/pro/partner/${userId}/office`, {isDisplayedOnMap})
      .then(({user}) => {
        resolve(user.displayedOnMap);
      })
      .catch(e => {
        reject(e.response.data);
      });
  });
}