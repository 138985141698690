import produce from "immer";

import * as actionsType from './constants';
const initialState = {
  map: {
    isLoaded : false,
    error: null
  }
};

export default (state = initialState.map, action) =>
  produce(state, draft => {
    switch (action.type) {

      case actionsType.GOOGLE_MAP_LOADED_SUCCESS:
        draft.isLoaded = true;
        break;

      case actionsType.GOOGLE_MAP_LOADED_ERROR:
        draft.isLoaded = false;
        draft.error = action.error;
        break;

      default:
    }
  });
