import React, { useEffect, useState } from "react";
import { Trans } from "react-i18next";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {makeStyles} from '@mui/styles';
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from '@stripe/stripe-js/pure';
import I18n from "i18next";

import PageLoader from "../../../../../components/PageLoader";

import CheckoutForm from "./CheckoutForm";


const useStyles = makeStyles((theme) => ({

  link: {
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  payment: {
    marginTop: 10,
    '& .input-element': {
      padding: '18.5px 14px',
      border: '1px solid rgba(0, 0, 0, 0.23)',
      borderRadius: 5
    },
    '& .select-element': {
      padding: '7.5px 0',
      border: '1px solid rgba(0, 0, 0, 0.23)',
      borderRadius: 5
    },
    '& button': {
      borderRadius: 8,
      fontSize: 16,
      height: 46,
      padding: '8px 20px',
      margin: theme.spacing(3, 0, 2),
    },
    '& buttonMuiButton-contained.Mui-disabled': {
      color: theme.palette.common.white
    }
  },
}));

const Payment = ({checkout}) => {

  const classes = useStyles();

  const [stripePromise, setStripePromise] = useState(null);
  const [options, setOptions] = useState(null);

  useEffect(() => {
    if(checkout && checkout.paymentIntent) {
      setOptions({
        clientSecret: checkout.paymentIntent.client_secret,
        locale: I18n.language,
        appearance: {
          theme: 'stripe',

          variables: {
            colorPrimary: '#007675',
            colorText: '#000000',
            colorDanger: '#df1b41',
            fontFamily: 'Ideal Sans, system-ui, sans-serif',
            spacingUnit: '5px 10px',
            borderRadius: "5px",
          },
          rules: {
            '.Tab': {
              border: '1px solid #c4c4c4',
            },
            '.Tab:hover': {
              boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 1px 1px rgba(18, 42, 66, 0.02), 0 0 0 1px rgba(0, 0, 0, 0.1)'
            },
            '.Tab:focus': {
              boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02), 0 0 0 2px var(--colorPrimary)'
            },
            '.Tab--selected:hover': {
              boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02), 0 0 0 2px var(--colorPrimary)'
            },
            '.Tab--selected:focus': {
              boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02), 0 0 0 2px var(--colorPrimary)'
            },
            '.Input': {
              border: '1px solid #c4c4c4',
            },
            '.Input:focus': {
              border: '1px solid #c4c4c4',
              boxShadow: 'none'
            }
          }
        }
      });
    }
  }, [checkout]);

  useEffect(() => {
    setStripePromise(loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY));
  }, []);

  if(!checkout || !checkout.paymentIntent) {
    return (
      <Grid container justify={"center"} spacing={4}>
        <PageLoader height={150}/>
      </Grid>
    );
  }

  return (
    <>
      <Grid container justify={"space-between"} alignItems={"center"}>
        <Grid item>
          <Typography component="h4" variant={"h6"}>
            <Trans i18nKey="payment">Payment</Trans> :
          </Typography>
        </Grid>
      </Grid>

      <Elements stripe={stripePromise} options={options}>
        <CheckoutForm className={classes.payment} order={checkout.order} redirect={`/pro/customer/${checkout.order.userId}/orders`} hardReload={true}/>
      </Elements>
    </>
  );
};
export default Payment;
