import React from "react";
import {useSelector} from "react-redux";
import Grid from "@mui/material/Grid";

import {PARTNER_CAREER} from "../../Authentication/store/constants";
import BestLvl1Charts from "../Stats/components/BestLvl1Charts";
import BestLvl2AndMoreCharts from "../Stats/components/BestLvl2AndMoreCharts";
import DirectCharts from "../Stats/components/DirectCharts";
import DirectProductsCharts from "../Stats/components/DirectProductsCharts";
import NetworkAverageTurnoversByActiveProsByMonthChart
  from "../Stats/components/NetworkAverageTurnoversByActiveProsByMonthChart";
import NetworkCharts from "../Stats/components/NetworkCharts";
import NetworkPerformancesDataTable from "../Stats/components/NetworkPerformancesDataTable";
import NetworkProductsCharts from "../Stats/components/NetworkProductsCharts";
import NetworkTurnoverByCareerChart from "../Stats/components/NetworkTurnoverByCareerChart";

import {consultantSelector} from "./store/selectors";

const ConsultantStats = () => {

  const user = useSelector(consultantSelector());

  return (
    <>
      {user.career !== PARTNER_CAREER && (
        <>
          <Grid container alignItems={"center"} spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={12} xl={6}>
              <DirectCharts user={user}/>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={12} xl={6}>
              <NetworkCharts user={user}/>
            </Grid>
          </Grid>
          <Grid container alignItems={"center"} spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={12} xl={6}>
              <BestLvl1Charts user={user}/>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={12} xl={6}>
              <BestLvl2AndMoreCharts user={user}/>
            </Grid>
          </Grid>
          <Grid container alignItems={"center"} spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={12} xl={6}>
              <DirectProductsCharts user={user}/>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={12} xl={6}>
              <NetworkProductsCharts user={user}/>
            </Grid>
          </Grid>
          <Grid container alignItems={"center"} spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={12} xl={6}>
              <NetworkTurnoverByCareerChart user={user}/>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={12} xl={6}>
              <NetworkAverageTurnoversByActiveProsByMonthChart user={user}/>
            </Grid>
          </Grid>
          <Grid container alignItems={"center"} spacing={2}>
            <Grid item xs={12}>
              <NetworkPerformancesDataTable user={user}/>
            </Grid>
          </Grid>
        </>
      )}
      {user.career === PARTNER_CAREER && (
        <>
          <Grid container alignItems={"center"} spacing={2}>
            <Grid item xs={12} sm={12}>
              <DirectCharts user={user}/>
            </Grid>
          </Grid>
          <Grid container alignItems={"center"} spacing={2}>
            <Grid item xs={12} sm={12}>
              <DirectProductsCharts user={user}/>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};
export default ConsultantStats;
