import React from "react";
import { Trans } from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import { grey } from "@mui/material/colors";
import { green } from "@mui/material/colors";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useTheme } from '@mui/material/styles';
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import {makeStyles} from '@mui/styles';

import { Link } from "../../../../../helpers/multilingual";
import { shopActions } from "../../store/actions";
import {
  cartDetailsSelector,
  isCartModalOpenedSelector,
  sessionSelector} from "../../store/selectors";

import CartContent from "./CartContent";

const useStylesCart = makeStyles((theme) => ({
  root: {
    '& .MuiDialog-paper': {
      '& .MuiDialogContent-root': {
        paddingBottom: 0,
        flex: 'initial'
      },
      '& .MuiDialogActions-root': {
        padding: theme.spacing(2, 2, 2, 2),
        borderTop: '1px solid rgba(0, 0, 0, 0.12)',
      },
      [theme.breakpoints.up('sm')]: {
        width: '80vw',
        minWidth: 760,
        '& .MuiListItem-root': {
        },
        '& .MuiDivider-root': {
          margin: -1
        }
      },
      [theme.breakpoints.down('xs')]: {
        '& .MuiDivider-root': {
          display: 'none'
        },
        '& .MuiCard-root': {
          overflow: 'auto'
        }
      },
      '& .MuiTypography-subtitle1': {
        maxHeight: 42,
        lineHeight: '20px',
        overflow: 'hidden'
      },
      '& .highlight': {
        color: green[800]
      }
    },
    '& img': {
      maxWidth: '100%',
      maxHeight: 80
    }
  },
  closeButton: {
    top: 10,
    right: 15,
    cursor: 'pointer',
    position: 'absolute',
    fontSize: 36,
    color: grey[500],
    [theme.breakpoints.down('xs')]: {
      top: 10,
      right: 20,
      fontSize: 40,
    },
  }
}));

const CartModal = () => {
  const isOpen = useSelector(isCartModalOpenedSelector);
  const theme = useTheme();
  const classes = useStylesCart();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const session = useSelector(sessionSelector);
  const cart = useSelector(cartDetailsSelector);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(shopActions.closeCartModal());
  };

  return (
    <Dialog onClose={handleClose} open={isOpen || false} fullScreen={fullScreen} className={classes.root}>
      <CloseIcon className={classes.closeButton} onClick={handleClose}/>
      <DialogTitle id="responsive-dialog-title"><Trans i18nKey="shoppingCart">Shopping cart</Trans></DialogTitle>
      <DialogContent>
        <CartContent session={session} cart={cart}/>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          <Typography>
            <Trans i18nKey="cartContinueShoppingButton">Continue shopping</Trans>
          </Typography>
        </Button>
        <Button variant={"contained"} component={Link} to="/shop/checkout" color="secondary" autoFocus>
          <Typography>
            <b><Trans i18nKey="cartOrderButton">Order</Trans></b>
          </Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CartModal;
