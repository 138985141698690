import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Box} from "@mui/material";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import DotsLoader from "../../../components/DotsLoader";

import EventTitle from "./components/EventTitle";
import ItemEvent from "./components/ItemEvent";
import {shopActions} from "./store/actions";
import {
  currentPageSelector,
  endOfProductsSelector,
  sessionSelector, shopProductsLoaderSelector, shopProductsSelector
} from "./store/selectors";

const EventsPage = (props) => {

  const dispatch = useDispatch();
  const {i18n} = useTranslation();
  const section = props.match.params.section ? props.match.params.section : 'category';
  const sectionId = props.match.params.sectionId ? props.match.params.sectionId : 'all';
  const [filter, setFilter] = useState('nameAsc');
  const [view, setView] = useState('grid');
  const session = useSelector(sessionSelector);
  const loader = useSelector(shopProductsLoaderSelector);
  const products = useSelector(shopProductsSelector('event', section, sectionId, (session && session.country) || 'LU', i18n.language));
  const endOfProducts = useSelector(endOfProductsSelector('event', section, sectionId, (session && session.country) || 'LU', i18n.language));
  const currentPage = useSelector(currentPageSelector('event', section, sectionId, (session && session.country) || 'LU', i18n.language));
  const hasMore = !endOfProducts;
  const observer = useRef(null);

  useEffect(() => {
    (async () => {
      dispatch(shopActions.getCategoriesRequest(i18n.language));
    })();
  }, [i18n.language]);

  useEffect(() => {
    getEvents();
  }, [i18n.language, section, sectionId, currentPage]);

  const getEvents = () => {
    dispatch(
      shopActions.getEventsRequest(
        i18n.language,
            session?.country || "LU",
            section,
            sectionId,
            currentPage,
            20
      )
    );
  };

  const lastProductElementRef = useCallback(
    (node) => {
      if (loader) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting && hasMore) {
            dispatch(shopActions.setCurrentPage(
              'event',
              i18n.language,
                session?.country || "LU",
                section,
                sectionId,
                currentPage + 1
            ));
          }
        },
        { threshold: 0.6 }
      );
      if (node) observer.current.observe(node);
    },
    [products]
  );

  const productFiltered = useMemo(() => {
    if(products?.length > 0) switch (filter) {
      case 'nameAsc': return products?.sort((a, b) => a.reference.localeCompare(b.reference));
      case 'priceAsc': return products?.sort((a, b) => a.price - b.price);
      case 'priceDesc': return products?.sort((a, b) => b.price - a.price);
      default: return products;
    }
  }, [products, filter]);

  if(!productFiltered) {
    return (
      <Box sx={{
        paddingBottom: 40,
        paddingTop: {xs: '0', sm: '12vw'},
      }}
      >
        <EventTitle/>

        <Container fixed maxWidth={'xxl'}>
          <Grid container spacing={2}>
            <Grid item sm={12}>
              <Grid container spacing={2} justifyContent={'center'}>
                <Typography variant={'h6'} align={'center'} sx={{color: '#7C909B'}}>
                  Il n'y a pas d'événements pour le moment.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
    );
  }
  return (
    <Box sx={{
      paddingBottom: 40,
      paddingTop: {xs: '0', sm: '11vw'},
    }}
    >
      <EventTitle/>

      <Container fixed maxWidth={'xxl'}>
        <Grid container spacing={2}>
          <Grid item sm={12}>
            <Grid container spacing={2}>
              {products && productFiltered && productFiltered?.length > 0 && (
                productFiltered?.map((product, index) => {
                  if(index === product.length - 1) {
                    return (<Grid
                      item xs={12}
                      sm={view === 'grid' ? 6 : 12}
                      md={view === 'grid' ? 6 : 12}
                      lg={view === 'grid' ? 3 : 12}
                      xl={view === 'grid' ? 3 : 12}
                      xxl={view === 'grid' ? 3 : 12}
                      key={product.id}
                    >
                      <ItemEvent product={product} session={session}/>
                    </Grid>);
                  } else {
                    return (<Grid
                      item xs={12}
                      sm={view === 'grid' ? 6 : 12}
                      md={view === 'grid' ? 6 : 12}
                      lg={view === 'grid' ? 3 : 12}
                      xl={view === 'grid' ? 3 : 12}
                      xxl={view === 'grid' ? 3 : 12}
                      key={product.id}
                      ref={lastProductElementRef}
                    >
                      <ItemEvent product={product} session={session}/>
                    </Grid>);
                  }
                })
              )}
              <Grid item xs={12}>
                {loader  && <DotsLoader/>}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>

    </Box>
  );
};

export default EventsPage;