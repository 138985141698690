import {green, grey} from "@mui/material/colors";
import { createTheme } from '@mui/material/styles';

import '../../../components/GoogleAutocomplete/autocompleteResults.css';
import '../../../components/GoogleMap/mapMarker.css';

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      mw: 519,
      sm: 768,
      md: 1024,
      lg: 1280,
      xl: 1440,
      xxl: 1920,
    },
  },
  palette: {
    primary: {
      light: '#7C909B',
      main: '#8CBD23',
      dark: '#1e1e1f',
    },
    secondary: {
      light: '#fff5f8',
      main: '#005850',
      dark: '#004640',
    },
    warning: {
      main: '#ffc071',
      dark: '#ffb25e',
    },
    error: {
      xLight: '#ffebee',
      main: '#f44336',
      dark: '#d32f2f',
    },
    success: {
      xLight: green[50],
      main: green[500],
      dark: green[700],
    },
    logistic: {
      light: '#7C909B',
      main: '#4a148c',
      dark: '#1e1e1f',
    }
  },
  typography: {
    fontFamily: [
      'Roboto',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    fontSize: 14,
    fontWeightLight: 300, // Roboto Light
    fontWeightRegular: 400, // Roboto Regular
    fontWeightMedium: 700, // Roboto Extra Bold
  },
  overrides: {
    MuiAppBar: {
      colorPrimary: {
        color: grey[900]
      }
    },
    MuiButton: {
      root: {
        fontWeight: 400,
        textTransform: "inherit",
      },
      containedSecondary: {
        background: 'linear-gradient(45deg, #077675 30%, #075454 90%)'
      }
    },
    MuiInputLabel: {
      root: {
        width: '90%'
      }
    },
    MuiFormControl: {
      root: {
        MuiFormLabel: {
          root: {
            left: 10
          }
        }
      }
    },
  },
  components: {
    MuiCalendarPicker: {
      styleOverrides: {
        root: {
          color: '#333',
          borderRadius: 2,
          borderWidth: 1,
          borderColor: '#8CBD23',
          border: '1px solid',
          backgroundColor: '#8CBD231B',
          '& .Mui-selected': {
            backgroundColor: '#8CBD23 !important',
            color: '#fff !important',
          }
        },
      }
    }
  }
});


export default theme;
