import React, {useState} from "react";
import {Trans, useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import ComputerIcon from '@mui/icons-material/Computer';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import TabletMacIcon from '@mui/icons-material/TabletMac';
import {ListItem} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { green, red } from "@mui/material/colors";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import {makeStyles} from '@mui/styles';

import {Link} from "../../../../helpers/multilingual";
import useMoment from "../../../../helpers/useMoment";
import {
  CUSTOMER_ROLE,
  DELEGATE_ROLE,
  DOCTOR_ROLE,
  PROSPECT_ROLE,
  THERAPIST_ROLE
} from "../../../Authentication/store/constants";
import {isAdmin} from "../../../Authentication/store/selectors";

import DelegateProsModal from "./DelegateProsModal";
import UserPointsModal from "./UserPointsModal";

const useStyles = makeStyles(() => ({
  root: {
    minHeight: 300,
    '& a': {
      textDecoration: 'underline'
    },
    '& .greenColor': {
      color: green[700]
    },
    '& .redColor': {
      color: red[700]
    }
  },
}));

const UserCard = ({user}) => {

  const classes = useStyles();
  const {t} = useTranslation();
  const moment = useMoment();
  const admin = useSelector(isAdmin);
  const [pointsModalOpen, setPointsModalOpen] = useState(false);
  const [prosModalOpen, setProsModalOpen] = useState(false);

  return (
    <Card className={classes.root}>
      <CardContent>
        <Grid container justify="center" spacing={2}>
          <Grid item xs={12}>
            <Grid container justify={"space-between"} alignItems={"flex-start"} spacing={2}>
              <Grid item xs={12} sm={6}>
                <Typography component="h3" variant={"h6"} gutterBottom>
                  <Trans i18nKey={"generalInformation"}>General information</Trans> :
                </Typography>
                <Typography component={"p"} variant="body1">
                  <Typography component={"a"} color={"inherit"}
                    href={`tel:${user.phone}`}>{user.phone}</Typography>
                </Typography>
                <Typography component={"p"} variant="body1">
                  <Typography component={"a"} color={"inherit"}
                    href={`mailto:${user.email}`}>{user.email}</Typography>
                </Typography>
                <Typography component={"p"} variant="body1">
                  {user.address}
                </Typography>
                <Typography component={"p"} variant="body1" gutterBottom>
                  Bob : {user.bobId}
                </Typography>
                {(user.role === CUSTOMER_ROLE || user.role === PROSPECT_ROLE) && (
                  <Typography component={"p"} variant="body1" gutterBottom>
                    Remise accordée: <b>{user?.userDiscount?.name}</b>
                  </Typography>
                )}
              </Grid>
              {user.shippingAddress && (
                <Grid item xs={12} sm={6}>
                  <Typography component="h4" variant={"h6"} gutterBottom>
                    <Trans i18nKey="shippingAddress">Shipping address</Trans> :
                  </Typography>
                  <Typography component={"p"}>
                    {user.shippingAddress.firstName} {user.shippingAddress.lastName}
                  </Typography>
                  <Typography component={"p"}>
                    {user.shippingAddress.postalBox}
                  </Typography>
                  <Typography component={"p"}>
                    <Typography component={"a"} color={"inherit"} target={"_blank"}
                      href={`https://www.google.fr/maps/place/${user.shippingAddress.address}`}>{user.shippingAddress.address} {user.shippingAddress.additionalInfo ? `(${user.shippingAddress.additionalInfo})` : ''}</Typography>
                  </Typography>
                </Grid>
              )}

            </Grid>
          </Grid>
          {(user.role === THERAPIST_ROLE || user.role === DOCTOR_ROLE) && (
            <Grid item xs={12}>
              <Grid container justify={"space-between"} alignItems={"flex-start"} spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography component="h3" variant={"h6"} gutterBottom>
                    {t(user.role)} :
                  </Typography>
                  <Typography component="p" variant={"body1"} gutterBottom>
                    Code inscription: <b>{user?.proCode}</b>
                  </Typography>
                  <Typography component="p" variant={"body1"} gutterBottom>
                                        Type de commission: <b>{user?.commissionCategory?.name}</b>
                  </Typography>
                  <Typography component={"p"} variant="body1" gutterBottom>
                                        Remise accordée aux patients: <b>{user?.proDiscountToClient?.name}</b>
                  </Typography>
                  <Typography component={"p"} variant="body1" gutterBottom>
                                        Remise accordée: <b>{user?.userDiscount?.name}</b>
                  </Typography>
                  <Typography component={"p"} variant="body1">
                                        Commission si référent: <b>{user.referentCommissionPercentage}%</b>
                  </Typography>
                </Grid>
                {user.delegates.length && (
                  <Grid item xs={12} sm={6}>
                    <Typography component="h3" variant={"h6"} gutterBottom>
                                            Délégués en charge :
                    </Typography>
                    {user.delegates.map(delegate => (
                      <Box key={delegate.id}>
                        <Typography component={Link} to={`/admin/users/${delegate.id}`}
                          variant={"body1"} gutterBottom>
                          {delegate.lastName} {delegate.firstName}
                        </Typography>
                        <Typography variant={"body1"}>
                                                    Commissionné à partir de
                                                    : {delegate.pro_delegate.commissionStartFrom}€
                        </Typography>
                      </Box>
                    ))}
                  </Grid>
                )}
              </Grid>
            </Grid>
          )}

          {!!user.pro && (
            <Grid item xs={12}>
              <Grid container justify={"space-between"} alignItems={"flex-start"} spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography component="h3" variant={"h6"} gutterBottom>
                                        Professionnel en charge :
                  </Typography>
                  <Typography component={"p"} variant="body1">
                    <Typography component={Link}
                      to={`/admin/users/${user.pro.id}`}>{user.pro.firstName} {user.pro.lastName}</Typography> [{t(user.pro.role)}]
                  </Typography>
                  <Typography component={"p"} variant="body1">
                    <Typography component={"a"} color={"inherit"}
                      href={`tel:${user.pro.phone}`}>{user.pro.phone}</Typography>
                  </Typography>
                  <Typography component={"p"} variant="body1">
                    <Typography component={"a"} color={"inherit"}
                      href={`mailto:${user.pro.email}`}>{user.pro.email}</Typography>
                  </Typography>
                  <Typography component={"p"} variant="body1">
                    {user.pro.address}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          )}
          {user.role === DELEGATE_ROLE && (
            <Grid item xs={12}>
              <Grid container justify={"space-between"} alignItems={"flex-start"} spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography component="h3" variant={"h6"} gutterBottom>
                                        Thérapeutes :
                  </Typography>
                  {user.pros && (user.pros.length > 0 ) && user.pros.map(therapist => (
                    <Box key={therapist.id}>
                      <Typography component={"p"} variant="body1">
                        <Typography component={Link}
                          to={`/admin/users/${therapist.id}`}>{therapist.firstName} {therapist.lastName}</Typography> [{t(therapist.role)}]
                      </Typography>
                      <Typography component={"p"} variant="body1">
                        <Typography component={"a"} color={"inherit"}
                          href={`tel:${therapist.phone}`}>{therapist.phone}</Typography>
                      </Typography>
                      <Typography component={"p"} variant="body1">
                        <Typography component={"a"} color={"inherit"}
                          href={`mailto:${therapist.email}`}>{therapist.email}</Typography>
                      </Typography>
                    </Box>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          )}
          {user.wallet && admin && (
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography component="h3" variant={"h6"} gutterBottom>
                    Solde actuel :
                  </Typography>
                  <Typography component={"p"} variant="h5"
                    className={user.wallet.amount > 0 ? "greenColor" : "redColor"}>
                    {user.wallet.amount} Points
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setPointsModalOpen(true)}
                        sx={{color:"#FFFFFF"}}
                      >
                      Détail des points
                      </Button>
                    </Grid>
                    {user.role === DELEGATE_ROLE && (
                      <Grid item xs={6}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => setProsModalOpen(true)}
                          sx={{color:"#FFFFFF"}}
                        >
                          Détail des pros
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography component="h3" variant={"h6"} gutterBottom>
              Dernières connexions :
            </Typography>
            <List>
              {user.refreshTokens.map(refreshToken => (
                <ListItem key={refreshToken.id}>
                  <ListItemIcon>
                    {refreshToken.deviceType === 'mobile' && (
                      <PhoneIphoneIcon/>
                    )}
                    {refreshToken.deviceType === 'tablet' && (
                      <TabletMacIcon/>
                    )}
                    {refreshToken.deviceType === 'desktop' && (
                      <ComputerIcon/>
                    )}
                  </ListItemIcon>
                  <ListItemText
                    primary={<>{moment(refreshToken.updatedAt).format('LL')} ({moment(refreshToken.updatedAt).fromNow()})
                                            - <Typography component={"span"}
                      className={refreshToken.status === 'active' ? "greenColor" : "redColor"}>{refreshToken.status}</Typography></>}
                    secondary={`Créé le ${moment(refreshToken.createdAt).format('LL')} - ${refreshToken.platform} - ${refreshToken.osName} - ${refreshToken.browserName}`}
                  />
                </ListItem>
              ))}
            </List>
          </Grid>
        </Grid>
        {pointsModalOpen && (
          <UserPointsModal user={user} open={pointsModalOpen} setOpen={setPointsModalOpen}/>
        )}
        {prosModalOpen && (
          <DelegateProsModal user={user} open={prosModalOpen} setOpen={setProsModalOpen}/>
        )}
      </CardContent>
    </Card>
  );
};
export default UserCard;
