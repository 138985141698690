import React, {useState} from "react";
import {Trans, useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import {Box, Stack} from "@mui/material";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";

import theme from "../../../assets/styles/theme/theme";
import FormValidator from "../../../components/Validators/FormValidator";
import TextFieldValidator from "../../../components/Validators/TextFieldValidator";
import {shopActions} from "../../Customer/Shop/store/actions";
import {authenticationActions} from "../store/actions";

import HelpBox from "./HelpBox";

const SignInForm = ({setSection}) => {

  const {t} = useTranslation();
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(true);

  const handleCloseDrawer = () => {
    dispatch(shopActions.SetDrawersOpen("account", false));
  };
  const handleSubmit = (event) => {
    if (event.type === 'submit') {
      dispatch(authenticationActions.signInCredentialsRequest(email, password, rememberMe));
    }
  };

  const handleRememberMeChange = (event) => {
    setRememberMe(event.target.checked);
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{
        background: '#fff',
        minHeight: '100vh',
        p: '32px 16px',
        '& .MuiInputBase-root': {
          fontFamily: 'Open sans',
          fontSize: '0.8rem',
          color: '#7C909B',
          height: '48px',
          borderRadius: '30px'
        },
        '& .MuiFormLabel-root': {
          fontFamily: 'Open sans',
          fontSize: '0.8rem',
          color: '#7C909B',
          fontStyle: 'italic'
        }
      }}>
      <Box component={'div'} sx={{
        position: 'relative',
      }}>
        <CloseIcon onClick={handleCloseDrawer} sx={{
          top: 0,
          right: 16,
          fontSize: 26,
          cursor: 'pointer',
          position: 'absolute',
          color: '#7C909B',
          background: '#ffffff',
          borderRadius: '50%',
          boxShadow: '2px 2px 8px #7C909B',
          padding: '4px',
        }}/>
      </Box>
      <Typography component="h1" variant="h5" sx={{
        color: theme.palette.primary.main,
        fontFamily: 'Open sans',
        mb: 2
      }}>
        <Trans i18nKey={"signIn"}>
            Sign in
        </Trans>
      </Typography>
      <Box sx={{
        p: '8px',
        borderRadius: '8px',
        backgroundColor: '#f8000026',
        marginBottom: '24px',
      }}>
        <Typography sx={{
          fontFamily: 'Montserrat',
          fontSize: '1rem',
          color: 'red',
          marginBottom: '8px',
          textDecoration: 'underline',
        }}>Bienvenue dans votre nouvel espace santé !</Typography>
        <Typography sx={{
          fontFamily: 'Montserrat',
          fontSize: '0.9rem',
          color: 'red',
        }}>
            Vos nouveaux accès : Si vous avez déjà un compte client chez Nutri-logics,
            réinitialisez votre mot de passe en cliquant sur « Mot de passe oublié ? » et en utilisant votre adresse mail
        </Typography>
      </Box>
      <FormValidator
        onSubmit={handleSubmit}
        onError={errors => console.log(errors)}
        noValidate
      >
        <Stack gap={2}>
          <TextFieldValidator
            required
            fullWidth
            type="email"
            name="email"
            label={t("email")}
            autoComplete="email"
            value={email}
            onChange={event => setEmail(event.target.value)}
          />

          <TextFieldValidator
            required
            fullWidth
            type="password"
            name="password"
            label={t("password")}
            value={password}
            onChange={event => setPassword(event.target.value)}
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={rememberMe}
                onChange={handleRememberMeChange}
                value="true" color="primary"/>
            }
            label={t("rememberMe")}
            sx={{
              fontFamily: 'Open sans',
              fontSize: '0.8rem',
              color: '#7C909B',
            }}
          />
          <Button
            type="submit"
            variant="contained"
            color={"primary"}
            sx={{
              fontSize: '1rem',
              textTransform: 'capitalize',
              borderRadius: '30px',
              color: '#fff',
              lineHeight: '1.3',
              padding: '8px 8px',
              minWidth: '200px',
              margin: 'auto'
            }}>
            <Trans i18nKey={"signIn"}>Sign In</Trans>
          </Button>
          <Typography
            onClick={() => setSection("forgotPassword")}
            variant="body2"
            sx={{
              textAlign: 'center',
              fontFamily: 'Open sans',
              fontSize: '0.8rem',
              color: '#7C909B',
              textDecoration: 'underline',
              cursor: 'pointer'
            }}>
            <Trans i18nKey={"forgotPassword"}>Forgot password</Trans> ?
          </Typography>
        </Stack>
        <Divider sx={{mt: 2, mb: 2}}/>
        <Stack gap={2}>
          <Typography component="h1" variant="h5" sx={{
            color: theme.palette.primary.main,
            fontFamily: 'Open sans',
          }}>
              Tous vos compléments alimentaires en quelques clicks :
          </Typography>
          <Typography sx={{
            fontFamily: 'Open sans',
            fontSize: '0.8rem',
            color: '#7C909B',
          }}>
              Inscrivez-vous sur notre site pour recevoir chez vous les meilleurs produits du marché.
          </Typography>
          <Button
            variant="contained"
            color={"primary"}
            onClick={() => {setSection("signUp")}}
            sx={{
              fontSize: '1rem',
              textTransform: 'capitalize',
              borderRadius: '30px',
              color: '#fff',
              lineHeight: '1.3',
              padding: '8px 8px',
              minWidth: '200px',
              margin: 'auto'
            }}>
              Inscription
          </Button>
        </Stack>
        <HelpBox/>
      </FormValidator>
    </Container>
  );
};

export default SignInForm;