import React  from "react";
import { useSelector } from "react-redux";

import CustomerOrdersCard from "./components(old)/CustomerOrdersCard";
import { customerSelector } from "./store/selectors";

const CustomerOrders = () => {

  const customer = useSelector(customerSelector());

  return (
    <CustomerOrdersCard customer={customer} />
  );
};
export default CustomerOrders;
