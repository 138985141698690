import React from "react";
import {useTranslation} from "react-i18next";
import Grid from "@mui/material/Grid";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";

const PageLoader = ({text, height = '50vh'}) => {

  const {t} = useTranslation();

  return (
    <Grid style={{minHeight: height}} container alignItems={"center"} justify={"center"}>
      <Grid item xs={6} sm={4}>
        <Typography align={"center"}>{text || t('loading')} ...</Typography>
        <LinearProgress color="secondary" />
      </Grid>
    </Grid>
  );
};
export default PageLoader;
