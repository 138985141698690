import axios  from '../../../../helpers/axios';

export const proShopService = {
  getCheckout,
  checkoutPayment,
  checkoutValidate
};

function getCheckout(lang, items, useWallet) {

  return new Promise((resolve, reject) => {

    axios.post(`/shop/checkoutWithoutShipping`, {
      lang,
      items,
      useWallet
    })
      .then(({checkout}) => {
        resolve(checkout);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function checkoutPayment(payment) {

  return new Promise((resolve, reject) => {

    axios.post(`/shop/stripe/success`, {
      payment
    })
      .then(({invoice}) => {
        resolve(invoice);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function checkoutValidate(order) {

  return new Promise((resolve, reject) => {

    axios.post(`/shop/order/validate`, {
      id: order.id
    })
      .then(({invoice}) => {
        resolve(invoice);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}
