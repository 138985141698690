export const COLORS = {
  white: '#FFFFFF',
  black: '#000000',
  grey: '#a3a3a3',
  red: '#c70707',
  redDarker:'#a20606',
  redDarkerBorder:'#960505',
  greyBackground: '#e4e5e6',
  greyText: '#23282c',
  greyLighter: '#c8ced3',
  greyLightest: '#f0f3f5',
  greyDarker: '#7e7e7e',
  error: '#d32f2f',
  errorDarker: '#b71c1c',
  primary: '#3a4248',
  primaryDarker: '#070710',
  primaryLighter: '#339FAB',
  greyPrimaryLighter: '#d6d6d6',
  secondary: '#61cd8d',
  secondaryDarker: '#52A46E',
  greenLight: '#107548',
  greenDark: '#11442d',
  greenDarker: '#00331b',
};

export const BUTTON = {
  borderRadiusButton: 25,
  borderRadiusMedium: 10,
  borderRadiusButtonLight: 8,
  boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.18)',
};
