import React from "react";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { grey } from "@mui/material/colors";
import {makeStyles} from '@mui/styles';
import classnames from "classnames";

import { COLORS } from "../assets/styles/theme/variables";

const useStyle = makeStyles(() => ({

  wrapper: {
    display: 'inline',
    position: 'relative',
    '& .MuiCircularProgress-svg': {
      color: grey[100]
    }
  },
  button: {
    borderRadius: 30,
    fontSize: '0.875rem',
    height: 46,
    padding: '8px 20px',
    color: '#fff',
    fontFamily: 'Montserrat',
    textTransform: 'capitalize',
    '&MuiButton-contained.Mui-disabled': {
      color: 'rgba(255, 255, 255, 0.26)'
    }
  },
  buttonProgress: {
    color: COLORS.greenDark,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const ButtonWithLoader = ({type, fullWidth, variant, className, isLoading, onClick, children, color, sx}) => {

  const classes = useStyle();

  return (
    <div className={classnames(classes.wrapper, className)}>
      <Button
        type={type}
        disabled={isLoading}
        fullWidth={fullWidth}
        variant={variant}
        onClick={onClick}
        color={color}
        className={classes.button}
        sx={{...sx}}
      >
        {children}
      </Button>
      {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
    </div>
  );
};
export default ButtonWithLoader;
